import { VStack, HStack } from '@chakra-ui/react';
import { Tag } from 'Atoms';
import { Typography } from 'Tokens';
import { TimePeriods, TimePeriodsEnums } from '../Requirement';

export const MetricsTableFilters = ({
  isOverview,
  setIsOverview,
  selectedQuarter,
  setSelectedQuarter,
  areMetricsYearly,
  areMetricsQuarterly,
}: {
  isOverview: boolean;
  setIsOverview: (p: boolean) => void;
  selectedQuarter: TimePeriods;
  setSelectedQuarter: (p: TimePeriods) => void;
  areMetricsYearly: boolean;
  areMetricsQuarterly: boolean;
}) => {
  return (
    <VStack gap="8px" width="100%">
      <HStack
        padding="8px"
        borderRadius="8px"
        bgColor="bg.muted"
        width="100%"
        justifyContent="space-between"
      >
        <HStack>
          <Typography variant="bodyStrong">Select view:</Typography>
          <Tag
            title="Data input"
            variant={!isOverview ? 'selected' : 'outlined'}
            clickable={!isOverview ? false : true}
            onClick={() => setIsOverview(false)}
            isRounded={false}
          />
          <Tag
            title="Overview"
            variant={isOverview ? 'selected' : 'outlined'}
            clickable={isOverview ? false : true}
            onClick={() => setIsOverview(true)}
            isRounded={false}
          />
        </HStack>
        {!isOverview && !areMetricsYearly && (
          <HStack>
            <Typography variant="bodyStrong">Select period:</Typography>
            {Object.values(TimePeriodsEnums)
              .filter((p) => (areMetricsQuarterly ? p !== 'Year' : true))
              .map((quarter) => (
                <Tag
                  title={quarter}
                  variant={selectedQuarter === quarter ? 'selected' : 'outlined'}
                  clickable={selectedQuarter === quarter ? false : true}
                  onClick={() => setSelectedQuarter(quarter)}
                  isRounded={false}
                />
              ))}
          </HStack>
        )}
      </HStack>
    </VStack>
  );
};
