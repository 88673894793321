import { VStack, Box, HStack } from '@chakra-ui/react';
import { NaceCodeSelector, SearchInput } from 'Molecules';
import { useTranslation } from 'react-i18next';

export const ActivitiesScrollViewWithSearchHeader = ({
  children,
  searchValue,
  setSearchValue,
  selectedCodes,
  setSelectedCodes,
  naceCodeList,
}: {
  children: React.ReactNode;
  searchValue: string;
  setSearchValue: React.Dispatch<string>;
  selectedCodes: string[];
  setSelectedCodes: React.Dispatch<string[]>;
  naceCodeList: string[];
}) => {
  const { t } = useTranslation('bUnits');
  const HEADERS_AND_FOOTERS_PX = '280px';
  return (
    <VStack width="100%" spacing="0px" padding="16px">
      <HStack width="100%" justifyContent="space-between">
        <Box flexGrow={1}>
          <SearchInput
            search={searchValue}
            setSearch={setSearchValue}
            placeholder={t('bUnits:activities.searchInputPlaceholder')}
            width="100%"
          />
        </Box>
        <Box width="250px">
          <NaceCodeSelector
            width="100%"
            onChange={(codes: any) => {
              setSelectedCodes(codes ?? []);
            }}
            values={selectedCodes ?? []}
            options={naceCodeList}
            placeholder={t('bUnits:activities.filterByNaceCode')}
            size="md"
          />
        </Box>
      </HStack>
      <VStack
        width="100%"
        height={`calc(100vh - ${HEADERS_AND_FOOTERS_PX})`}
        alignItems="flex-start"
        overflowY="scroll"
        paddingTop="16px"
      >
        {children}
      </VStack>
    </VStack>
  );
};
