import { ErrorElement } from 'App';
import { Route, Routes } from 'react-router-dom';
import { GroupAssessment, GroupAssessmentList } from './pieces';

export const GroupAssessments = () => {
  return (
    <Routes>
      <Route index element={<GroupAssessmentList />} errorElement={<ErrorElement />} />
      <Route path=":gAssessmentId/*" element={<GroupAssessment />} errorElement={<ErrorElement />}>
        <Route
          path=":currentTab"
          element={<GroupAssessment />}
          errorElement={<ErrorElement />}
        ></Route>
      </Route>
    </Routes>
  );
};
