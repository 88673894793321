import { HStack, MenuButton, Menu, MenuList, MenuItem, VStack } from '@chakra-ui/react';
import { Menu as CelsiaMenu } from 'Molecules/Menu';
import { File, FileWidth } from 'Molecules/File';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'utils/translation';
import { Button, IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { Attachment, AttachmentBox } from 'models';
import { FileEditorContext, FileEditorContextProvider } from 'containers/Drive/pieces';
import { useRemoveAttachment } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { RefetchQueriesInclude } from '@apollo/client';
import { AddIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';

const AddAttachmentButton = ({
  numberOfAttachments,
  openAttachmentDrawer,
  isDisabled = false,
  isVertical = false,
}: {
  numberOfAttachments: number;
  openAttachmentDrawer: () => void;
  isDisabled?: boolean;
  isVertical?: boolean;
}) => {
  const { t } = useTranslation('files');

  if (numberOfAttachments > 0 && !isVertical) {
    return (
      <IconButton
        aria-label={t('files:attachFile')}
        icon={<AddIcon color="inherit" />}
        size="md"
        variant="ghost"
        onClick={() => {
          openAttachmentDrawer();
        }}
        isDisabled={isDisabled}
        tooltipLabel={t('files:attachFile')}
      />
    );
  }
  return (
    <Button
      variant="ghost"
      leftIcon={<AddIcon color="inherit" />}
      onClick={() => {
        openAttachmentDrawer();
      }}
      isDisabled={isDisabled}
    >
      <Typography variant="bodyStrong">{t('files:attachFile')}</Typography>
    </Button>
  );
};

const AttachmentsDropdown = ({
  attachments,
  removeAttachment,
  isLocked = false,
}: {
  attachments: Attachment[];
  removeAttachment: (attachmentId: string) => void;
  isLocked?: boolean;
}) => {
  const { t } = useTranslation('question');
  const Editor = useContext(FileEditorContext);
  const items = attachments.map((attachment) => {
    const file = attachment.file;
    const actions = Editor.actions(file);
    actions.pop();
    if (!isLocked) {
      actions.unshift({
        id: 'unassign',
        title: t('question:fileDetach'),
        leftElement: <RemoveIcon color="inherit" />,
        onClick: () => {
          removeAttachment(attachment.id);
        },
      });
    }
    if (isLocked) {
      actions.pop();
    }
    const iconButton = (
      <CelsiaMenu
        sections={[
          {
            actions: actions,
          },
        ]}
      />
    );
    return (
      <MenuItem key={attachment.id}>
        <HStack width="100%" justifyContent="space-between">
          <Typography>{file.title}</Typography>
          {iconButton}
        </HStack>
      </MenuItem>
    );
  });
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        height="54px"
        padding="8px"
        minWidth="60px"
        paddingLeft="16px"
        border="1px"
        borderColor="border.decorative"
        borderRadius="8px"
        _hover={{ bg: 'bg.hover' }}
        rightIcon={<ChevronDownIcon color="inherit" />}
      >
        <Typography variant="bodyStrong">+{attachments.length}</Typography>
      </MenuButton>
      <MenuList>{items}</MenuList>
    </Menu>
  );
};

export type AttachmentProps = {
  attachmentBox?: AttachmentBox;
  openAttachmentDrawer: () => void;
  refetchQueries?: RefetchQueriesInclude;
  isDisabled?: boolean;
  isVertical?: boolean;
  isLocked?: boolean;
  showSingleFile?: boolean;
  fileWidth?: FileWidth;
};

export const AttachmentContainer = ({
  attachmentBox,
  openAttachmentDrawer,
  refetchQueries,
  isDisabled = false,
  isVertical = false,
  isLocked = false,
  showSingleFile = false,
  fileWidth,
}: AttachmentProps) => {
  const { t } = useTranslation('question');
  const unassignAttachment = useRemoveAttachment();

  const removeAttachment = useCallback(
    (attachmentId: string) => {
      return unassignAttachment(attachmentId, refetchQueries);
    },
    [unassignAttachment]
  );

  const attachments: Attachment[] = attachmentBox?.attachments ?? [];

  const { attachmentsToShow, restOfAttachments } = useMemo(() => {
    if (attachments?.length <= 1) return { attachmentsToShow: attachments, restOfAttachments: [] };
    const [firstFile, secondFile, ...rest] = attachments;

    const modifiedAttachmentsToShow = showSingleFile ? [firstFile] : [firstFile, secondFile];
    const modifiedRestOfAttachments = showSingleFile ? [secondFile, ...rest] : rest;
    return {
      attachmentsToShow: modifiedAttachmentsToShow,
      restOfAttachments: modifiedRestOfAttachments,
    };
  }, [attachments, showSingleFile]);

  const Editor = useContext(FileEditorContext);

  const attachmentComponents = useMemo(
    () =>
      attachmentsToShow.map((attachment: Attachment) => {
        const file = attachment.file;
        const actions = Editor.actions(file);
        actions.pop();
        if (!isLocked) {
          actions.unshift({
            id: 'detach',
            title: t('question:fileDetach'),
            leftElement: <RemoveIcon color="inherit" />,
            onClick: () => {
              removeAttachment(attachment.id);
            },
            isDisabled: isDisabled,
          });
        }
        if (isLocked) {
          actions.pop();
        }
        return <File file={file} actions={actions} fileWidth={fileWidth} />;
      }),
    [attachmentsToShow, Editor, isDisabled, isLocked, removeAttachment, t]
  );

  return (
    <FileEditorContextProvider>
      {isVertical ? (
        <VStack alignItems="start">
          {attachmentComponents}
          {restOfAttachments?.length && (
            <AttachmentsDropdown
              attachments={restOfAttachments}
              removeAttachment={removeAttachment}
              isLocked={isLocked}
            />
          )}
          {!isLocked && (
            <AddAttachmentButton
              numberOfAttachments={attachments.length}
              openAttachmentDrawer={openAttachmentDrawer}
              isDisabled={isDisabled}
              isVertical={isVertical}
            />
          )}
        </VStack>
      ) : (
        <HStack>
          {attachmentComponents}
          {restOfAttachments?.length && (
            <AttachmentsDropdown
              attachments={restOfAttachments}
              removeAttachment={removeAttachment}
              isLocked={isLocked}
            />
          )}
          {!isLocked && (
            <AddAttachmentButton
              numberOfAttachments={attachments.length}
              openAttachmentDrawer={openAttachmentDrawer}
              isDisabled={isDisabled}
              isVertical={isVertical}
            />
          )}
        </HStack>
      )}
    </FileEditorContextProvider>
  );
};
