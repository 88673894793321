export const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isBusinessEmail =
  /^(?!.+@(gmail|yahoo|outlook|hotmail|msn|live|aol|test)\..+)(.+@.+\..+)$/;

export const breadcrumbWithEllipsis = (items: string[], maxLength: number) => {
  const ellipsis = '...';
  const breadcrumbArray = items.map((item) =>
    item.length > maxLength ? item.slice(0, maxLength) + ellipsis : item
  );

  return breadcrumbArray.join(' / ');
};

export const activitySort = (a: string, b: string): number => {
  const regex = /^[0-9,]+(\.[0-9]+)?/; // Match leading numbers, optionally followed by a decimal point and more numbers
  const aMatch = a.match(regex);
  const bMatch = b.match(regex);

  if (aMatch && bMatch) {
    // Both values have leading numbers, compare them as numbers
    const numA = parseFloat(aMatch[0].replace(',', '')); // Remove thousands separators if present
    const numB = parseFloat(bMatch[0].replace(',', '')); // Remove thousands separators if present

    if (numA !== numB) {
      return numA - numB;
    } else {
      // If the numbers are equal, compare the remaining strings
      const restA = a.substring(aMatch[0].length);
      const restB = b.substring(bMatch[0].length);
      return restA.localeCompare(restB);
    }
  } else if (aMatch) {
    // Only 'a' has a leading number, it should come first
    return -1;
  } else if (bMatch) {
    // Only 'b' has a leading number, it should come first
    return 1;
  } else {
    // Both values don't have leading numbers, compare them as strings
    return a.localeCompare(b);
  }
};
