import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuGroup,
  Portal,
  MenuProps,
  MenuList,
  MenuDivider,
  Box,
  Button,
} from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import React from 'react';
import { CopyIcon, DeleteIcon, EditIcon, MoreIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';

const DIVIDER_TITLE = '__divider';
export const DIVIDER = {
  name: DIVIDER_TITLE,
};
const KNOWN_ICONS = {
  duplicate: <CopyIcon color="inherit" />,
  edit: <EditIcon color="inherit" />,
  delete: <DeleteIcon color="inherit" />,
};
type KnownActions = keyof typeof KNOWN_ICONS;
export type Action = {
  name: KnownActions | string;
  icon?: KnownActions | MenuItemProps['icon'];
  actions?: Action[];
  element?: React.ReactNode;
} & Omit<MenuItemProps, 'icon'>;

const getIcon = (icon: Action['icon'], name?: Action['name']) =>
  typeof icon === 'string' || icon === undefined
    ? KNOWN_ICONS[icon ?? (name as KnownActions)]
    : icon;

export const ActionList = ({ actions }: { actions: Action[] }) => {
  const { t, i18n } = useTranslation();
  const getActions = (actionList: Action[]) =>
    (actionList ?? []).map(({ name, icon, element, actions: group, ...props }, i) => {
      if (element) {
        // return element;
        return <MenuItem key={name}>{element}</MenuItem>;
      }
      if (name === DIVIDER_TITLE) return <MenuDivider key={name + i} />;
      else if (group)
        return (
          <MenuGroup title={name} key={name}>
            {getActions(group)}
          </MenuGroup>
        );
      else
        return (
          <MenuItem
            key={name}
            icon={getIcon(icon, name)}
            fontSize="14px"
            fontWeight="600"
            lineHeight="20px"
            {...props}
          >
            {i18n.exists(['common:actions.' + name, name])
              ? t(['common:actions.' + name, name])
              : name}
          </MenuItem>
        );
    });
  return (
    <MenuList
      sx={{
        '.chakra-menu__icon-wrapper': { fontSize: '14px', fontWeight: '600', lineHeight: '20px' },
      }}
      onClick={(e) => e.stopPropagation()}
      width="156px"
    >
      {getActions(actions)}
    </MenuList>
  );
};

export const ActionMenuButton = ({
  text,
  icon,
  isDisabled,
}: {
  text?: string;
  icon?: keyof typeof KNOWN_ICONS | MenuItemProps['icon'];
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  if (text) {
    return (
      <MenuButton
        as={Button}
        leftIcon={getIcon(icon)}
        onClick={stopPropagation}
        isDisabled={isDisabled}
      >
        {text}
      </MenuButton>
    );
  }
  return (
    <MenuButton
      as={IconButton}
      aria-label={t('common:button.options')}
      icon={<MoreIcon />}
      variant="ghost"
      minWidth="24px"
      onClick={stopPropagation}
      isDisabled={isDisabled}
    />
  );
};
export const ActionMenu = ({
  actions,
  placement,
  usePortal,
  triggerText,
  triggerIcon,
}: {
  actions: Action[];
  placement?: MenuProps['placement'];
  usePortal?: boolean;
  triggerText?: string;
  triggerIcon?: keyof typeof KNOWN_ICONS | MenuItemProps['icon'];
}) => {
  const menu = <ActionList actions={actions} />;
  return (
    <Box>
      <Menu placement={placement}>
        <ActionMenuButton text={triggerText} icon={triggerIcon} isDisabled={actions.length == 0} />
        {usePortal ? (
          <Portal>
            <Box sx={{ '& > div': { zIndex: 'var(--chakra-zIndices-tooltip) !important' } }}>
              {menu}
            </Box>
          </Portal>
        ) : (
          menu
        )}
      </Menu>
    </Box>
  );
};
