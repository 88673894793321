import { Box, HStack, useDisclosure, VStack, useToast as useChakraToast } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  AttachmentBox,
  useGetPoliciesDocumentationByIdQuery,
  ShortUser,
  GetPoliciesDocumentationByIdDocument_,
  useGetPoliciesDrQuery,
  GetDisclosureRequirementGroupsDocument_,
  useGetEsrsMetricsDatapointsQuery,
  useUpsertDatapointMutation,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Esrs_Answer_Constraint_,
  Esrs_Answer_Update_Column_,
  GetEsrsMetricsDatapointsDocument_,
  useEsrsAssessmentQuery,
  GetEsrsMetricAnswerDocument_,
} from 'models';
import { LearnMoreDrawer, Loader, Modal, SearchInput } from 'Molecules';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Typography } from 'Tokens';
import { useCompanyType } from 'utils/hooks';
import { DisclosureRequirementTitle } from '../DisclosureRequirementsUtilsComponents';
import { PoliciesSubsidiaries } from './PoliciesSubsidiaries';
import { PolicyAISummarizer } from './PolicyAISummariser';
import { getNameExtension } from 'utils/files';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { Button, EmptyState } from 'Atoms';
import { useRemoveAttachment } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { RefetchQueriesInclude } from '@apollo/client';
import { FileEditorContext } from 'containers/Drive/pieces';
import { AddIcon, AIIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';
import { Menu } from 'Molecules/Menu';
import { useParams } from 'react-router-dom';
import { DocumentCheckmarkIcon } from 'Tokens/Icons/Data';
import { AggregatedMetrics, useGetAggregatedMetricsData } from '../Metrics';
import { TimePeriodsEnums } from '../Requirement';

export type GeneratedAnswer = {
  metricTitle: string;
  metricRef: string;
  answer: string;
};

const InteractionBlocker = () => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="transparent"
      zIndex={1000}
    />
  );
};

const AIProgressModal = ({
  isOpen,
  onClose,
  setIsGenerating,
  cancelRequest,
}: {
  isOpen: boolean;
  onClose: () => void;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  cancelRequest: () => void;
}) => {
  const chakraToast = useChakraToast();
  return (
    <Modal
      cancelText="Stay on the page"
      confirmText="Close & stop AI"
      title="AI is writing..."
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {
        cancelRequest();
        setIsGenerating(false);
        chakraToast.closeAll();
        onClose();
      }}
    >
      <Typography variant="body">
        Answers generation is in progress. If you close this window, you will lose the progress. Are
        you sure you want to close this page and open another?
      </Typography>
    </Modal>
  );
};

const PolicyFileList = ({
  attachments,
  onAttachmentDrawerOpen,
  refetchQueries,
}: {
  attachments: AttachmentBox['attachments'];
  onAttachmentDrawerOpen: () => void;
  refetchQueries: RefetchQueriesInclude;
}) => {
  const { t } = useTranslation('question');
  const [search, setSearch] = useState<string>('');

  const filteredAttachments = useMemo(() => {
    return attachments.filter((attachment) => attachment.file.title.toLowerCase().includes(search));
  }, [attachments, search]);

  const unassignAttachment = useRemoveAttachment();

  const removeAttachment = useCallback(
    (attachmentId: string) => {
      return unassignAttachment(attachmentId, refetchQueries);
    },
    [unassignAttachment]
  );

  const Editor = useContext(FileEditorContext);

  return (
    <VStack w="100%" spacing="16px" alignItems="stretch">
      <Typography variant="h4">Manage policies</Typography>
      <HStack w="100%">
        <SearchInput search={search} setSearch={setSearch} placeholder="Filter" />
        <Button variant="primary" onClick={onAttachmentDrawerOpen}>
          Add file
        </Button>
      </HStack>
      {filteredAttachments.length === 0 ? (
        <Box w="100%" h="100%">
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            component={true}
          />
        </Box>
      ) : (
        <VStack spacing="0px">
          {filteredAttachments.map((attachment) => {
            const file = attachment.file;
            const { extension } = getNameExtension(file.storageFile?.name ?? '');

            const actions = Editor.actions(file);
            actions.pop();
            actions.unshift({
              id: 'detach',
              title: t('question:fileDetach'),
              leftElement: <RemoveIcon color="inherit" />,
              onClick: () => {
                removeAttachment(attachment.id);
              },
            });

            return (
              <HStack
                h="48px"
                w="100%"
                justifyContent="center"
                alignItems="center"
                borderTop="1px solid"
                borderColor="border.decorative"
              >
                <Box w="40px" boxSize="24px">
                  <FileIcon
                    extension={extension}
                    {...defaultStyles[extension as keyof typeof defaultStyles]}
                    labelUppercase
                  />
                </Box>
                <Box w="90%" px="8px" alignItems="flex-start">
                  <Typography noOfLines={1} textOverflow="ellipsis" variant="bodyStrong">
                    {attachment.file.title}
                  </Typography>
                </Box>
                <Menu
                  sections={[
                    {
                      actions: actions,
                    },
                  ]}
                />
              </HStack>
            );
          })}
        </VStack>
      )}
    </VStack>
  );
};

export const Policies = () => {
  const { standardRef = '', esrsAssessmentId = '', disclosureRequirementRef = '' } = useParams();
  const user: ShortUser | null = useUserData();
  const { data } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const esrsAssessment = useMemo(() => data?.esrsAssessment, [data]);
  const { companyType, loading } = useCompanyType();
  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isAIProgressModalOpen,
    onClose: onAIProgressModalClose,
    onOpen: onAIProgressModalOpen,
  } = useDisclosure();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const { filteredMetrics, companyLevelReportingUnitId } = useGetAggregatedMetricsData();

  const [upsertDatapoint] = useUpsertDatapointMutation();

  const { data: metricDatapoints } = useGetEsrsMetricsDatapointsQuery({
    variables: {
      assessmentId: esrsAssessmentId,
      metricRefs: filteredMetrics.map((metric) => metric.reference),
      reportingUnitId: companyLevelReportingUnitId,
    },
  });

  const policyMetricsDatapoints = useMemo(() => {
    return metricDatapoints?.answers;
  }, [metricDatapoints]);

  const areAnswersGenerated = useMemo(() => {
    return policyMetricsDatapoints?.some((dp) => dp.datapoints?.[0]?.isAIGenerated);
  }, [policyMetricsDatapoints]);

  const { data: policiesData, loading: policiesLoading } = useGetPoliciesDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      esrsAssessmentId,
      standardRef,
    },
    skip: !disclosureRequirementRef || !esrsAssessmentId || !standardRef,
  });

  const [policyDR, policy] = useMemo(
    () => [
      policiesData?.DisclosureRequirement_by_pk,
      policiesData?.DisclosureRequirement_by_pk?.policies[0],
    ],
    [policiesData]
  );

  const { title = '', description = '' } = policyDR || {};

  const { data: documentationData, loading: documentationLoading } =
    useGetPoliciesDocumentationByIdQuery({
      variables: {
        policyId: policy?.id,
      },
      skip: !policy?.id,
    });

  const abortController = new AbortController();

  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);

  const populateAnswersWithAI = (generatedAnswers: GeneratedAnswer[]) => {
    upsertDatapoint({
      variables: {
        objects:
          policyMetricsDatapoints?.map((answer) => {
            const dp = answer.datapoints[0];
            return {
              id: dp?.id,
              value:
                generatedAnswers.find(
                  (generatedAnswer) => generatedAnswer.metricRef === answer.metricRef
                )?.answer ?? '',
              timeframe: TimePeriodsEnums.year,
              authorId: user?.id,
              ownerId: dp?.ownerId ?? esrsAssessment?.projectLeaderId,
              isAIGenerated: true,
              answer: {
                data: {
                  metricRef: answer.metricRef,
                  hasOptedOut: answer.hasOptedOut,
                  optOutReason: answer.optOutReason,
                  reportingUnitId: companyLevelReportingUnitId,
                  assessmentId: esrsAssessmentId,
                  attachmentBox: {
                    data: {},
                    on_conflict: {
                      constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                      update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                    },
                  },
                  optOutAuthorId: user?.id,
                },
                on_conflict: {
                  constraint:
                    Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                  update_columns: [
                    Esrs_Answer_Update_Column_.HasOptedOut_,
                    Esrs_Answer_Update_Column_.OptOutReason_,
                    Esrs_Answer_Update_Column_.OptOutAuthorId_,
                  ],
                },
              },
            };
          }) ?? [],
      },

      refetchQueries: [GetEsrsMetricAnswerDocument_, GetEsrsMetricsDatapointsDocument_],
    });
  };

  useEffect(() => {
    const handleClick = () => {
      if (isSendingRequest) {
        onAIProgressModalOpen();
      }
    };
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [isSendingRequest]);

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_Policy_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  if (loading || policiesLoading || documentationLoading) {
    return <Loader />;
  }

  if (attachmentBox?.attachments.length === 0 && !policyDR?.policies.length)
    return (
      <AttachmentDrawer
        isOpen={isAttachmentDrawerOpen}
        refetch={[GetPoliciesDocumentationByIdDocument_]}
        onClose={onAttachmentDrawerClose}
        attachmentBox={attachmentBox}
      >
        <VStack spacing="12px" alignItems="start" w="100%" height="100%">
          <DisclosureRequirementTitle
            title="Policies"
            reference={disclosureRequirementRef}
            onOpen={onOpen}
          />

          <LearnMoreDrawer
            header={title}
            isOpen={isOpen}
            onClose={onClose}
            description={description ?? ''}
          />
        </VStack>
      </AttachmentDrawer>
    );

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      refetch={[GetPoliciesDocumentationByIdDocument_]}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
    >
      <VStack spacing="20px" alignItems="start" w="100%">
        <DisclosureRequirementTitle
          title="Policies"
          reference={policyDR?.reference}
          onOpen={onOpen}
        />
        <VStack spacing="32px" w="100%" alignItems="stretch">
          {attachmentBox?.attachments.length === 0 || !policyDR?.policies.length ? (
            <EmptyState
              description="Upload policies adopted to manage material impacts, risks and opportunities related to the topic"
              icon={<DocumentCheckmarkIcon boxSize="20px" />}
              variant="white"
              component={true}
              callToAction={{
                text: 'Add policies',
                variant: 'ghost',
                leftIcon: <AddIcon />,
                onClick: () => {
                  onAttachmentDrawerOpen();
                },
              }}
            />
          ) : (
            <PolicyFileList
              attachments={attachmentBox?.attachments ?? []}
              onAttachmentDrawerOpen={onAttachmentDrawerOpen}
              refetchQueries={[
                GetPoliciesDocumentationByIdDocument_,
                GetDisclosureRequirementGroupsDocument_,
              ]}
            />
          )}

          {!isGroup && (
            <VStack alignItems="start">
              <AggregatedMetrics
                extraHeaderContent={
                  !!attachmentBox?.attachments.length ? (
                    <HStack
                      w="100%"
                      p="4px 4px 4px 8px"
                      border="1px solid"
                      borderColor="border.selected.accent"
                      borderRadius="6px"
                    >
                      <AIIcon color="text.info" />
                      <Typography variant="body" flexGrow={1}>
                        These answers could be generated by AI using your policy documents
                      </Typography>
                      <PolicyAISummarizer
                        attachments={attachmentBox?.attachments ?? []}
                        isSendingRequest={isSendingRequest}
                        setIsSendingRequest={setIsSendingRequest}
                        requestSignal={abortController.signal}
                        areAnswersGenerated={areAnswersGenerated ?? false}
                        populateAnswersWithAI={populateAnswersWithAI}
                        filteredMetrics={filteredMetrics}
                      />
                    </HStack>
                  ) : null
                }
                isGeneratingAnswers={isSendingRequest}
              />
            </VStack>
          )}
        </VStack>

        {isGroup && <PoliciesSubsidiaries />}

        <LearnMoreDrawer
          header={title}
          isOpen={isOpen}
          onClose={onClose}
          description={description ?? ''}
        />
        <AIProgressModal
          isOpen={isAIProgressModalOpen}
          onClose={onAIProgressModalClose}
          setIsGenerating={setIsSendingRequest}
          cancelRequest={() => {
            abortController.abort();
          }}
        />

        {isSendingRequest && <InteractionBlocker />}
      </VStack>
    </AttachmentDrawer>
  );
};
