import { PaiResults } from 'Features/PortfolioPai/PortfolioPai.hooks';
import { formatNum } from 'utils/numbers';

export enum PossibleErrors {
  marketValueMissing = 'MARKET_VALUE_MISSING',
}

type InvesteeMetricCalculationFn = (
  companyPaiResult: PaiResults[number]
) => string | number | null | boolean | { [key: string]: number };

type InvestorMetricCalculationFn = (
  calculationFn: InvesteeMetricCalculationFn,
  results: PaiResults
) => string | number | null;

const HELPER_FUNCTIONS = {
  addition: (values: number[]) => values.reduce((a, b) => a + b, 0),
  weighByInfluence: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const values = results.map(
      (res) =>
        Number(calculationFn(res) ?? 0) * (Number(res.allocation) / Number(res.marketValue)) ?? 0
    );
    return values;
  },
  nonZero: (value: number) => (value === 0 ? 1 : value),
};

const INVESTEE_METRIC_CALCULATION_FUNCTIONS = {
  simpleMetric: (investeeMetric: string) => (companyPaiResult: PaiResults[number]) => {
    return Number(companyPaiResult.answers[investeeMetric]) ?? null;
  },
  valueByInfluence: (investeeMetric: string) => (companyPaiResult: PaiResults[number]) => {
    const investeeValue = Number.parseFloat(String(companyPaiResult.answers[investeeMetric] ?? 0));
    return (
      investeeValue *
        (Number(companyPaiResult.allocation) / Number(companyPaiResult.marketValue)) ?? 0
    );
  },
  booleanMetric: (investeeMetric: string) => (companyPaiResult: PaiResults[number]) => {
    if (!companyPaiResult.answers[investeeMetric]) return null;
    return companyPaiResult.answers[investeeMetric] === 'YES' ? true : false;
  },
  divisionMetric:
    (numerator: string, denominator: string) => (companyPaiResult: PaiResults[number]) => {
      const numeratorValue = Number(companyPaiResult.answers[numerator]) ?? 0;
      const denominatorValue = Number(companyPaiResult.answers[denominator]) ?? 1;
      if (denominatorValue === 0) return null;
      return numeratorValue / denominatorValue;
    },
  divisionMetricPerMillionEuros:
    (numerator: string, denominator: string) => (companyPaiResult: PaiResults[number]) => {
      const numeratorValue = Number(companyPaiResult.answers[numerator]) ?? 0;
      const denominatorValue = Number(companyPaiResult.answers[denominator]) ?? 1;
      if (denominatorValue === 0) return null;
      return numeratorValue / (denominatorValue * 0.000001);
    },
  // Specific metric calculation functions
  totalGHG: (companyPaiResult: PaiResults[number]) => {
    const scope1 = Number(companyPaiResult.answers.scope1 ?? 0) ?? 0;
    const scope2 = Number(companyPaiResult.answers.scope2 ?? 0) ?? 0;
    const scope3 = Number(companyPaiResult.answers.scope3 ?? 0) ?? 0;
    return (
      (scope1 + scope2 + scope3) *
        (Number(companyPaiResult.allocation) / Number(companyPaiResult.marketValue)) ?? 0
    );
  },
  carboonFootprint: (companyPaiResult: PaiResults[number]) => {
    const scope1 = Number(companyPaiResult.answers.scope1 ?? 0) ?? 0;
    const scope2 = Number(companyPaiResult.answers.scope2 ?? 0) ?? 0;
    const scope3 = Number(companyPaiResult.answers.scope3 ?? 0) ?? 0;
    return (
      (scope1 + scope2 + scope3) *
        (Number(companyPaiResult.allocation) / Number(companyPaiResult.marketValue)) ?? 0
    );
  },

  energyConsumptionHighImpact: (companyPaiResult: PaiResults[number]) => {
    const numeratorValue = Number(companyPaiResult.answers.totalEnergyconsumptionHighImpact) ?? 0;
    const denominatorValue = Number(companyPaiResult.answers.totalRevenueHighImpact) ?? 1;
    if (denominatorValue === 0) return null;
    return (numeratorValue * 0.001) / (denominatorValue * 0.000001);
  },
  waterConsumed: (companyPaiResult: PaiResults[number]) => {
    const numeratorValue = Number(companyPaiResult.answers.waterConsumed) ?? 0;
    const denominatorValue = Number(companyPaiResult.answers.revenue) ?? 1;
    if (denominatorValue === 0) return null;
    return numeratorValue / (denominatorValue * 0.000001);
  },
  nAccidents_In: (companyPaiResult: PaiResults[number]) => {
    const rateOfAccidents = Number(companyPaiResult.answers.nAccidents) ?? 0;
    const numberOfEmployees = Number(companyPaiResult.answers.totalEmployees) ?? 1;
    const revenue = Number(companyPaiResult.answers.revenue) ?? 1;
    return rateOfAccidents / numberOfEmployees / (revenue * 0.000001);
  },
  ghgIntensity: (companyPaiResult: PaiResults[number]) => {
    const scope1 = Number(companyPaiResult.answers.scope1) ?? 0;
    const scope2 = Number(companyPaiResult.answers.scope2) ?? 0;
    const scope3 = Number(companyPaiResult.answers.scope3) ?? 0;
    const revenue = Number(companyPaiResult.answers.revenue) ?? 1;
    return (scope1 + scope2 + scope3) / (revenue * 0.000001);
  },
  waterRecycled: (companyPaiResult: PaiResults[number]) => {
    const waterConsumed = Number(companyPaiResult.answers.waterConsumed) ?? 1;
    const waterRecycled = Number(companyPaiResult.answers.waterRecycled) ?? 0;
    return waterRecycled / waterConsumed;
  },
  nonRenewableEnergyConsumption: (companyPaiResult: PaiResults[number]) => {
    // non renewable energy
    const coalConsumption = Number(companyPaiResult.answers.coalConsumption) ?? 0;
    const fuelConsumptionOil = Number(companyPaiResult.answers.fuelConsumptionOil) ?? 0;
    const fuelConsumptionGas = Number(companyPaiResult.answers.fuelConsumptionGas) ?? 0;
    const fuelConsumptionOtherNon = Number(companyPaiResult.answers.fuelConsumptionOtherNon) ?? 0;
    const fuelConsumptionNuclear = Number(companyPaiResult.answers.fuelConsumptionNuclear) ?? 0;
    const fuelConsumptionElectricityNon =
      Number(companyPaiResult.answers.fuelConsumptionElectricityNon) ?? 0;

    // Total non-renewable energy consumption
    const nonRenewableConsumption =
      coalConsumption +
      fuelConsumptionOil +
      fuelConsumptionGas +
      fuelConsumptionOtherNon +
      fuelConsumptionNuclear +
      fuelConsumptionElectricityNon;

    // Renewable energy consumption
    const fuelConsumptionBio = Number(companyPaiResult.answers.fuelConsumptionBio) ?? 0;
    const fuelConsumptionElectricityRenewable =
      Number(companyPaiResult.answers.fuelConsumptionElectricityRenewable) ?? 0;
    const fuelConsumptionSelf = Number(companyPaiResult.answers.fuelConsumptionSelf) ?? 0;

    // Total renewable energy consumption
    const renewableConsumption =
      fuelConsumptionBio + fuelConsumptionElectricityRenewable + fuelConsumptionSelf;

    // Weighted by ownership
    return {
      nonRenewableConsumptionWithAlloc:
        nonRenewableConsumption * Number(companyPaiResult.allocation) ?? 0,
      nonRenewableConsumption: nonRenewableConsumption,
      renewableConsumption: renewableConsumption,
    };
  },
  nonRenewableEnergyProduction: (companyPaiResult: PaiResults[number]) => {
    const nonRenewableEnergyProduction =
      Number(companyPaiResult.answers.nonRenewableEnergyProduction) ?? 0;
    const renewableEnergyProduction =
      Number(companyPaiResult.answers.renewableEnergyProduction) ?? 0;
    // Weighted by ownership
    return {
      nonRenewableEnergyProduction: nonRenewableEnergyProduction,
      nonRenewableEnergyProductionWithAlloc:
        nonRenewableEnergyProduction * Number(companyPaiResult.allocation) ?? 0,
      renewableEnergyProduction: renewableEnergyProduction,
    };
  },
  ['biodiversity-sites']: (companyPaiResult: PaiResults[number]) => {
    const sites = Number(companyPaiResult.answers['biodiversity-sites']) ?? 0;
    return sites * (Number(companyPaiResult.allocation) / Number(companyPaiResult.marketValue));
  },
  violationsOfUNGlobalCompactPrinciples: (companyPaiResult: PaiResults[number]) => {
    const oecd1 = companyPaiResult.answers['oecd-1'] === 'YES';
    const oecd2 = companyPaiResult.answers['oecd-2'] === 'YES';
    const oecd3 = companyPaiResult.answers['oecd-3'] === 'YES';

    if (oecd1 || oecd2 || oecd3) return true;
    return false;
  },
  lackOfProcesses: (companyPaiResult: PaiResults[number]) => {
    const commitmentOnSocial = companyPaiResult.answers.commitmentOnSocial === 'YES';
    const whistleblowerMechanism = companyPaiResult.answers.whistleblowerMechanism === 'YES';
    if (!commitmentOnSocial || !whistleblowerMechanism) return true;
    return false;
  },
  waterStressAreaBool_In: (companyPaiResult: PaiResults[number]) => {
    const waterStressIn = companyPaiResult.answers.waterStress_In === 'YES';
    const waterManagementBool = companyPaiResult.answers.waterManagementBool === 'YES';
    if (waterStressIn && !waterManagementBool === true) return true;
    return false;
  },
  ['esrs-waste-material-3']: (companyPaiResult: PaiResults[number]) => {
    const esrsWaste2 = Number.parseFloat(String(companyPaiResult.answers['esrs-waste2']));
    const esrsWastem1 = Number.parseFloat(String(companyPaiResult.answers['esrs-wastem1']));

    return esrsWaste2 / esrsWastem1;
  },
  ['esrs-waste-material-5']: (companyPaiResult: PaiResults[number]) => {
    const esrsWastem3 = Number.parseFloat(String(companyPaiResult.answers['esrs-wastem3'] ?? 0));
    const esrsWastem1 = Number.parseFloat(String(companyPaiResult.answers['esrs-wastem1'] ?? 1));

    return esrsWastem3 / esrsWastem1;
  },
  energyBreakdown: (energyMetric: string) => (companyPaiResult: PaiResults[number]) => {
    const energyMetricValue = Number(companyPaiResult.answers[energyMetric]) ?? 0;
    const totalEnergyConsumption = Number(companyPaiResult.answers.totalEnergy) ?? 0;
    return energyMetricValue / totalEnergyConsumption;
  },
};

const INVESTOR_METRIC_CALCULATION_FUNCTIONS = {
  sumAndWeighByInvestments: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    if (results.some((pCompany) => !pCompany.marketValue)) {
      return PossibleErrors.marketValueMissing;
    }
    const totalInvested =
      HELPER_FUNCTIONS.addition(results.map((res) => Number(res.allocation) ?? 0)) ?? 1;

    const values = results.map(
      (res) => (Number(calculationFn(res)) ?? 1) / ((Number(totalInvested) ?? 1) * 0.000001)
    );

    return HELPER_FUNCTIONS.addition(values);
  },
  formula3: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const totalValueOfInvestments =
      HELPER_FUNCTIONS.addition(results.map((res) => Number(res.allocation) ?? 0)) ?? 1;

    const values = results.map((res) => {
      const currResult = Number(calculationFn(res)) ?? 0;
      return currResult * (Number(res.allocation) / Number(totalValueOfInvestments)) ?? 0;
    });
    return HELPER_FUNCTIONS.addition(values);
  },
  averageByTotalInvested: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    if (results.some((pCompany) => !pCompany.marketValue)) {
      return PossibleErrors.marketValueMissing;
    }
    const values = HELPER_FUNCTIONS.weighByInfluence(calculationFn, results);

    return (
      (HELPER_FUNCTIONS.addition(values) /
        HELPER_FUNCTIONS.addition(
          results.map((res) => Number(res.allocation ?? 0) / Number(res.marketValue ?? 1))
        )) *
      100
    );
  },
  sumByInvestmentRatio: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    if (results.some((r) => calculationFn(r) === null)) {
      return null;
    }
    const totalInvested = HELPER_FUNCTIONS.addition(
      results.map((res) => Number(res.allocation) ?? 0)
    );
    const values = results.map((res) => {
      const currResult = Number(calculationFn(res)) ?? 0;
      return currResult * (Number(res.allocation) / Number(totalInvested));
    });
    return HELPER_FUNCTIONS.addition(values);
  },
  sumByInvestmentRatioPercentage: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults
  ) => {
    if (results.some((r) => calculationFn(r) === null)) {
      return null;
    }
    const totalInvested = HELPER_FUNCTIONS.addition(
      results.map((res) => Number(res.allocation) ?? 0)
    );
    const values = results.map((res) => {
      const currResult = Number(calculationFn(res)) ?? 0;
      return currResult * (Number(res.allocation) / Number(totalInvested));
    });
    return HELPER_FUNCTIONS.addition(values) * 100;
  },

  averageByTotalInvestedNotPercent: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults
  ) => {
    if (results.some((pCompany) => !pCompany.marketValue)) {
      return PossibleErrors.marketValueMissing;
    }
    const values = HELPER_FUNCTIONS.weighByInfluence(calculationFn, results);

    return (
      HELPER_FUNCTIONS.addition(values) /
      HELPER_FUNCTIONS.addition(
        results.map((res) => Number(res.allocation ?? 0) / Number(res.marketValue ?? 1))
      )
    );
  },

  carboonFootprint: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    if (results.some((pCompany) => !pCompany.marketValue)) {
      return PossibleErrors.marketValueMissing;
    }

    const value = HELPER_FUNCTIONS.addition(results.map((res) => Number(calculationFn(res)) ?? 0));

    const totalValueOfInvestments =
      HELPER_FUNCTIONS.addition(results.map((res) => Number(res.allocation) ?? 0)) ?? 1;
    return value / (totalValueOfInvestments * 0.000001);
  },
  nonRenewableEnergyConsumption: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults
  ) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.addition(
      results.map((res) => Number(res.allocation) ?? 0)
    );
    const investeeRes = (res: PaiResults[number]) => {
      return calculationFn(res) as {
        nonRenewableConsumption: number;
        renewableConsumption: number;
        nonRenewableConsumptionWithAlloc: number;
      };
    };
    const consumptionResult = HELPER_FUNCTIONS.addition(
      results.map(
        (res) =>
          investeeRes(res).nonRenewableConsumptionWithAlloc /
          (HELPER_FUNCTIONS.nonZero(
            investeeRes(res).nonRenewableConsumption + investeeRes(res).renewableConsumption
          ) *
            totalValueOfInvestments)
      )
    );
    return consumptionResult * 100;
  },

  nonRenewableEnergyProduction: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults
  ) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.addition(
      results.map((res) => Number(res.allocation) ?? 0)
    );
    const investeeRes = (res: PaiResults[number]) => {
      return calculationFn(res) as {
        nonRenewableEnergyProduction: number;
        renewableEnergyProduction: number;
        nonRenewableEnergyProductionWithAlloc: number;
      };
    };
    const productionResult = HELPER_FUNCTIONS.addition(
      results.map(
        (res) =>
          investeeRes(res).nonRenewableEnergyProductionWithAlloc /
          (HELPER_FUNCTIONS.nonZero(
            investeeRes(res).nonRenewableEnergyProduction +
              investeeRes(res).renewableEnergyProduction
          ) *
            totalValueOfInvestments)
      )
    );
    return productionResult * 100;
  },
  energyConsumptionHighImpact: (
    calculationFn: InvesteeMetricCalculationFn,
    results: PaiResults
  ) => {
    if (results.some((pCompany) => !pCompany.marketValue)) {
      return PossibleErrors.marketValueMissing;
    }
    const values = HELPER_FUNCTIONS.weighByInfluence(calculationFn, results);
    return HELPER_FUNCTIONS.addition(values);
  },
  nAccidents_In: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const totalValueOfInvestments = HELPER_FUNCTIONS.addition(
      results.map((res) => Number(res.allocation) ?? 0)
    );
    return HELPER_FUNCTIONS.addition(
      results.map((res) => {
        const value = Number(calculationFn(res)) ?? 0;
        return value * (Number(res.allocation) / Number(totalValueOfInvestments));
      })
    );
  },
  // General
  sumMetric: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const values = results.map((res) => Number(calculationFn(res)) ?? 0);
    return HELPER_FUNCTIONS.addition(values);
  },
  sumMetricPerMillionEuros: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    const values = results.map((res) => Number(calculationFn(res)) ?? 0);
    return HELPER_FUNCTIONS.addition(values) / 1000000;
  },
  averageTrueMetric: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    if (results.some((v) => calculationFn(v) === null)) {
      return null;
    }
    const values = HELPER_FUNCTIONS.addition(
      results.map((v) => (calculationFn(v) === true ? 1 * v.allocation : 0))
    );
    const totalInvested =
      HELPER_FUNCTIONS.addition(results.map((res) => Number(res.allocation) ?? 0)) ?? 1;
    return (values / totalInvested) * 100;
  },
  averageFalseMetric: (calculationFn: InvesteeMetricCalculationFn, results: PaiResults) => {
    if (results.some((v) => calculationFn(v) === null)) {
      return null;
    }
    const values = HELPER_FUNCTIONS.addition(
      results.map((v) => (calculationFn(v) === true ? 0 : 1 * v.allocation))
    );
    const totalInvested =
      HELPER_FUNCTIONS.addition(results.map((res) => Number(res.allocation) ?? 0)) ?? 1;
    return (values / totalInvested) * 100;
  },
};

const PAI_CALCULATIONS_PER_METRIC: {
  [key: string]: {
    investeeMetrics: string[];
    investorDataPointCalculation: InvestorMetricCalculationFn;
    investeeDataPointCalculation: InvesteeMetricCalculationFn;
  };
} = {
  scope1: {
    investeeMetrics: ['scope1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('scope1'),
  },
  scope2: {
    investeeMetrics: ['scope2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('scope2'),
  },
  scope3: {
    investeeMetrics: ['scope3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('scope3'),
  },
  totalGhgEmissions: {
    investeeMetrics: ['scope1', 'scope2', 'scope3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.totalGHG,
  },
  carbonFootprint: {
    investeeMetrics: ['scope1', 'scope2', 'scope3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.carboonFootprint,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.carboonFootprint,
  },
  ghgIntensity: {
    investeeMetrics: ['scope1', 'scope2', 'scope3', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.ghgIntensity,
  },
  fossilFuelExposure: {
    investeeMetrics: ['fossilFuelExposure'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('fossilFuelExposure'),
  },
  // nonRenewableEnergy: {
  //   investeeMetrics: [
  //     'coalConsumption',
  //     'fuelConsumptionOil',
  //     'fuelConsumptionGas',
  //     'fuelConsumptionOtherNon',
  //     'fuelConsumptionNuclear',
  //     'fuelConsumptionElectricityNon',
  //     'fuelConsumptionBio',
  //     'fuelConsumptionElectricityRenewable',
  //     'fuelConsumptionSelf',
  //     'nonRenewableEnergyProduction',
  //     'renewableEnergyProduction',
  //   ],
  //   investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergy,
  //   investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergy,
  // },
  nonRenewableEnergyConsumption: {
    investeeMetrics: [
      'coalConsumption',
      'fuelConsumptionOil',
      'fuelConsumptionGas',
      'fuelConsumptionOtherNon',
      'fuelConsumptionNuclear',
      'fuelConsumptionElectricityNon',
      'fuelConsumptionBio',
      'fuelConsumptionElectricityRenewable',
      'fuelConsumptionSelf',
    ],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyConsumption,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyConsumption,
  },
  nonRenewableEnergyProduction: {
    investeeMetrics: ['nonRenewableEnergyProduction', 'renewableEnergyProduction'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyProduction,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.nonRenewableEnergyProduction,
  },
  energyConsumptionIntensityHighImpact: {
    investeeMetrics: ['totalRevenueHighImpact', 'totalEnergyconsumptionHighImpact'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyConsumptionHighImpact,
  },
  isImpactingBiodiversity: {
    investeeMetrics: ['isImpactingBiodiversity'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('isImpactingBiodiversity'),
  },
  emissionsToWater: {
    investeeMetrics: ['totalEmissionsToWater'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('totalEmissionsToWater'),
  },
  emmissionOfInorganicPollutants: {
    investeeMetrics: ['emmissionOfInorganicPollutants'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence(
      'emmissionOfInorganicPollutants'
    ),
  },
  emmissionsOfAirPollutants: {
    investeeMetrics: ['emmissionsOfAirPollutants'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence(
      'emmissionsOfAirPollutants'
    ),
  },
  hazardousWaste: {
    investeeMetrics: ['hazardousWaste'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('hazardousWaste'),
  },
  unadjustedPayGap: {
    investeeMetrics: ['unadjustedPayGap'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('unadjustedPayGap'),
  },
  boardGenderDiversity: {
    investeeMetrics: ['boardGenderDiversity'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('boardGenderDiversity'),
  },
  controversialWeapons: {
    investeeMetrics: ['controversialWeapons'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('controversialWeapons'),
  },
  carbonEmissionReductionInitiatives: {
    investeeMetrics: ['carbonEmissionReductionInitiatives'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'carbonEmissionReductionInitiatives'
    ),
  },
  insufficientWhistleblowerProtection: {
    investeeMetrics: ['insufficientWhistleblowerProtection'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'insufficientWhistleblowerProtection'
    ),
  },
  violationsOfUNGlobalCompactPrinciples: {
    investeeMetrics: ['oecd-1', 'oecd-2', 'oecd-3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.violationsOfUNGlobalCompactPrinciples,
  },
  lackOfProcesses: {
    investeeMetrics: ['commitmentOnSocial', 'whistleblowerMechanism'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.lackOfProcesses,
  },
  waterConsumed: {
    investeeMetrics: ['waterConsumed', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.waterConsumed,
  },
  tonnesOzoneDepleting: {
    investeeMetrics: ['tonnesOzoneDepleting_In'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('tonnesOzoneDepleting_In'),
  },
  waterRecycled: {
    investeeMetrics: ['waterRecycled', 'waterConsumed'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.waterRecycled,
  },
  waterManagementBool_In: {
    investeeMetrics: ['waterManagementBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('waterManagementBool'),
  },
  waterStressAreaBool_In: {
    investeeMetrics: ['waterStress_In', 'waterManagementBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.waterStressAreaBool_In,
  },
  producingChemicalsBool_In: {
    investeeMetrics: ['producingChemicalsBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('producingChemicalsBool'),
  },
  soilProblems_In: {
    investeeMetrics: ['soilProblems'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('soilProblems'),
  },
  sustainableLandPolicyBool_In: {
    investeeMetrics: ['sustainableLandPolicy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('sustainableLandPolicy'),
  },
  sustainableOceanPolicyBool_in: {
    investeeMetrics: ['sustainableOceanPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'sustainableOceanPolicyBool'
    ),
  },
  tonnesNonRecycledWaste_In: {
    investeeMetrics: ['tonnesNonRecycledWaste'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumAndWeighByInvestments,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('tonnesNonRecycledWaste'),
  },
  naturalSpeciesAndProtectedAreas: {
    investeeMetrics: ['naturalSpeciesAndProtectedAreas'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'naturalSpeciesAndProtectedAreas'
    ),
  },
  biodiversityPolicyBool_In: {
    investeeMetrics: ['biodiversityPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('biodiversityPolicyBool'),
  },
  deforestation: {
    investeeMetrics: ['deforestation'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('deforestation'),
  },
  accidentPolicyBool_In: {
    investeeMetrics: ['accidentPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('accidentPolicyBool'),
  },
  nAccidents_In: {
    investeeMetrics: ['nAccidents', 'totalEmployees', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.nAccidents_In,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.nAccidents_In,
  },
  nWorkdayslost_In: {
    investeeMetrics: ['nWorkdaysLost'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nWorkdaysLost'),
  },
  SupplierCodeBool_In: {
    investeeMetrics: ['SupplierCodeBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('SupplierCodeBool'),
  },
  grievanceMechanismBool_In: {
    investeeMetrics: ['grievanceMechanismBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('grievanceMechanismBool'),
  },
  nDiscriminationIncidents_In: {
    investeeMetrics: ['nDiscriminationIncidents'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'nDiscriminationIncidents'
    ),
  },
  nDiscriminationSanctions_In: {
    investeeMetrics: ['nDiscriminationS'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nDiscriminationS'),
  },
  ratioCompensation: {
    investeeMetrics: ['ratioCompensation'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('ratioCompensation'),
  },
  riskChildLabourBool_In: {
    investeeMetrics: ['riskChildLabourBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('riskChildLabourBool'),
  },
  forcedLabourBool_In: {
    investeeMetrics: ['forcedLabourBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('forcedLabourBool'),
  },
  nHumanRightsIncidents_In: {
    investeeMetrics: ['nHumanRightsIncidents'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nHumanRightsIncidents'),
  },
  lackOfHumanRightsPolicy: {
    investeeMetrics: ['lackOfHumanRightsPolicy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('lackOfHumanRightsPolicy'),
  },
  DDPolicyBool_In: {
    investeeMetrics: ['DDPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('DDPolicyBool'),
  },
  traffickingPolicyBool_In: {
    investeeMetrics: ['traffickingPolicyBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('traffickingPolicyBool'),
  },
  anticorruptionPolicyBool_In: {
    investeeMetrics: ['anticorruptionBool'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('anticorruptionBool'),
  },
  actionsAnticorruption_In: {
    investeeMetrics: ['actionsAnticorruption'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('actionsAnticorruption'),
  },
  nConvictionsCorruption_In: {
    investeeMetrics: ['nConvictionsCorruption'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('nConvictionsCorruption'),
  },
  finescorruption_in: {
    investeeMetrics: ['finescorruption'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('finescorruption'),
  },
  ['ghg-removals-amount']: {
    investeeMetrics: ['ghg-removals-amount'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('ghg-removals-amount'),
  },
  ['ghg-carbon-purchase']: {
    investeeMetrics: ['ghg-carbon-purchase'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('ghg-carbon-purchase'),
  },
  ['esrs-waste']: {
    investeeMetrics: ['esrs-waste'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste'),
  },
  ['esrs-total-waste-1']: {
    investeeMetrics: ['esrs-waste-1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-1'),
  },
  ['esrs-total-waste-2']: {
    investeeMetrics: ['esrs-waste-2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-2'),
  },
  ['esrs-total-waste-3']: {
    investeeMetrics: ['esrs-waste-3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-3'),
  },
  ['esrs-total-waste-4']: {
    investeeMetrics: ['esrs-waste-4'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-4'),
  },
  ['esrs-total-waste-5']: {
    investeeMetrics: ['esrs-waste-5'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-5'),
  },
  ['esrs-total-waste-6']: {
    investeeMetrics: ['esrs-waste-6'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-6'),
  },
  ['esrs-total-waste-7']: {
    investeeMetrics: ['esrs-waste-7'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-7'),
  },
  ['esrs-total-waste-8']: {
    investeeMetrics: ['esrs-waste-8'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-8'),
  },
  ['esrs-total-waste-9']: {
    investeeMetrics: ['esrs-waste-9'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-9'),
  },
  ['esrs-total-waste-10']: {
    investeeMetrics: ['esrs-waste-10'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-10'),
  },
  ['esrs-total-waste-11']: {
    investeeMetrics: ['esrs-waste-11'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-11'),
  },
  ['esrs-waste-12']: {
    investeeMetrics: ['esrs-waste-12'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-12'),
  },
  ['esrs-total-waste-13']: {
    investeeMetrics: ['esrs-waste-13'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste-13'),
  },
  ['esrs-total-waste-14']: {
    investeeMetrics: ['esrs-waste-13', 'esrs-waste'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'esrs-waste-13',
      'esrs-waste'
    ),
  },
  ['esrs-waste-material']: {
    investeeMetrics: ['esrs-wastem1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-wastem1'),
  },
  ['esrs-waste-material-2']: {
    investeeMetrics: ['esrs-waste2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-waste2'),
  },
  ['esrs-waste-material-3']: {
    investeeMetrics: ['esrs-waste2', 'esrs-wastem1'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS['esrs-waste-material-3'],
  },
  ['esrs-waste-material-4']: {
    investeeMetrics: ['esrs-wastem3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('esrs-wastem3'),
  },
  ['esrs-waste-material-5']: {
    investeeMetrics: ['esrs-wastem3', 'esrs-wastem1'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS['esrs-waste-material-5'],
  },
  ['esrs-employee-turnover']: {
    investeeMetrics: ['esrs-employee-turnover', 'esrs-average-employees'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'esrs-employee-turnover',
      'esrs-average-employees'
    ),
  },
  ['custom-total-hires']: {
    investeeMetrics: ['custom-total-hires'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-total-hires'),
  },
  ['custom-relative-employees']: {
    investeeMetrics: ['custom-relative-employees', 'custom-total-hires'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetric(
      'custom-total-hires',
      'custom-relative-employees'
    ),
  },
  ['esrs-risk-policy']: {
    investeeMetrics: ['esrs-risk-policy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageFalseMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('esrs-risk-policy'),
  },
  ['cross-cutting1']: {
    investeeMetrics: ['cross-cutting1'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageByTotalInvestedNotPercent,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('cross-cutting1'),
  },
  ['cross-cutting2']: {
    investeeMetrics: ['cross-cutting2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('cross-cutting2'),
  },
  ['company-risk-1']: {
    investeeMetrics: ['company-risk-1'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-1'),
  },
  ['company-risk-2']: {
    investeeMetrics: ['company-risk-2'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-2'),
  },
  ['company-risk-3']: {
    investeeMetrics: ['company-risk-3'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-3'),
  },
  ['company-risk-4']: {
    investeeMetrics: ['company-risk-4'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-4'),
  },
  ['company-risk-5']: {
    investeeMetrics: ['company-risk-5'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-5'),
  },
  ['company-risk-6']: {
    investeeMetrics: ['company-risk-6'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-6'),
  },
  ['company-risk-7']: {
    investeeMetrics: ['company-risk-7'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-7'),
  },
  ['company-risk-8']: {
    investeeMetrics: ['company-risk-8'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-8'),
  },
  ['company-risk-9']: {
    investeeMetrics: ['company-risk-9'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('company-risk-9'),
  },
  ['biodiversity-sites']: {
    investeeMetrics: ['biodiversity-sites'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('biodiversity-sites'),
  },
  ['biodiversity-area']: {
    investeeMetrics: ['biodiversity-area'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('biodiversity-area'),
  },
  ['esrs-water']: {
    investeeMetrics: ['esrs-water', 'revenue'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.divisionMetricPerMillionEuros('esrs-water', 'revenue'),
  },
  ['custom-goverment-ownership']: {
    investeeMetrics: ['custom-goverment-ownership'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-goverment-ownership'
    ),
  },
  contributionmitigation: {
    investeeMetrics: ['custom-cross-cutting1a'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1a'),
  },
  contributionadaptation: {
    investeeMetrics: ['custom-cross-cutting1b'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1b'),
  },
  contributionmarine: {
    investeeMetrics: ['custom-cross-cutting1c'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1c'),
  },
  contributioncirculareconomy: {
    investeeMetrics: ['custom-cross-cutting1d'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1d'),
  },
  contributionpollution: {
    investeeMetrics: ['custom-cross-cutting1e'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1e'),
  },
  contributionbiodiversity: {
    investeeMetrics: ['custom-cross-cutting1f'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting1f'),
  },
  harmmitigation: {
    investeeMetrics: ['custom-cross-cutting2a'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2a'),
  },
  harmadaptation: {
    investeeMetrics: ['custom-cross-cutting2b'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2b'),
  },
  harmmarine: {
    investeeMetrics: ['custom-cross-cutting2c'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2c'),
  },
  harmcircular: {
    investeeMetrics: ['custom-cross-cutting2d'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2d'),
  },
  harmpollution: {
    investeeMetrics: ['custom-cross-cutting2e'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2e'),
  },
  harmbiodiversity: {
    investeeMetrics: ['custom-cross-cutting2f'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric('custom-cross-cutting2f'),
  },
  energycoal_in: {
    investeeMetrics: ['coalConsumption', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('coalConsumption'),
  },
  energyoil_in: {
    investeeMetrics: ['fuelConsumptionOil', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionOil'),
  },
  energynuclear_in: {
    investeeMetrics: ['fuelConsumptionNuclear', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionNuclear'),
  },
  energypurchased_in: {
    investeeMetrics: ['fuelConsumptionElectricityNon', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown(
      'fuelConsumptionElectricityNon'
    ),
  },
  energygas_in: {
    investeeMetrics: ['fuelConsumptionGas', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionGas'),
  },
  energyother_in: {
    investeeMetrics: ['fuelConsumptionOtherNon', 'totalEnergy'],
    investorDataPointCalculation:
      INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatioPercentage,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.energyBreakdown('fuelConsumptionOtherNon'),
  },
  totalenergy_in: {
    investeeMetrics: ['totalEnergy'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('totalEnergy'),
  },
  ['custom-nationality-representation']: {
    investeeMetrics: ['custom-nationality-representation'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-nationality-representation'
    ),
  },
  ['custom-gender-diversity']: {
    investeeMetrics: ['custom-gender-diversity'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumByInvestmentRatio,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-gender-diversity'),
  },
  ['custom-biofouling']: {
    investeeMetrics: ['custom-biofouling'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence('custom-biofouling'),
  },
  ['custom-GHG-emissions-reduction']: {
    investeeMetrics: ['custom-GHG-emissions-reductions'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.sumMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.valueByInfluence(
      'custom-GHG-emissions-reductions'
    ),
  },
  ['custom-number-fte']: {
    investeeMetrics: ['custom-number-fte'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-number-fte'),
  },
  ['custom-female-fte']: {
    investeeMetrics: ['custom-female-fte'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation:
      INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric('custom-female-fte'),
  },
  ['custom-number-management']: {
    investeeMetrics: ['custom-number-management'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-number-management'
    ),
  },
  ['custom-female-management']: {
    investeeMetrics: ['custom-female-management'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-female-management'
    ),
  },
  ['custom-number-board-members']: {
    investeeMetrics: ['custom-number-board-members'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-number-board-members'
    ),
  },
  ['custom-female-board-members']: {
    investeeMetrics: ['custom-female-board-members'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.formula3,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.simpleMetric(
      'custom-female-board-members'
    ),
  },
  ['custom-sustainability-agenda']: {
    investeeMetrics: ['custom-sustainability-agenda'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'custom-sustainability-agenda'
    ),
  },
  ['custom-sustainability-agenda-item']: {
    investeeMetrics: ['custom-sustainability-agenda-item'],
    investorDataPointCalculation: INVESTOR_METRIC_CALCULATION_FUNCTIONS.averageTrueMetric,
    investeeDataPointCalculation: INVESTEE_METRIC_CALCULATION_FUNCTIONS.booleanMetric(
      'custom-sustainability-agenda-item'
    ),
  },
};

export const noNAN = (v: any) => {
  if (v === undefined || v === null) return 'No data';
  if (isNaN(v)) return 0;
  return formatNum(v, 4);
};

export const calculateMetricResults = (metricRef: string, investeeResults: PaiResults) => {
  const metricCalculation = PAI_CALCULATIONS_PER_METRIC?.[metricRef];
  if (!metricCalculation) return 'No data';
  return noNAN(
    metricCalculation.investorDataPointCalculation(
      metricCalculation.investeeDataPointCalculation,
      investeeResults
    )
  );
};

export const getMetricProgress = (
  metricRef: string,
  investeeResults: PaiResults,
  companyCount: number
) => {
  const total = companyCount;
  const requiredMetrics = PAI_CALCULATIONS_PER_METRIC[metricRef]?.investeeMetrics ?? [];
  const answered = investeeResults.filter((res) =>
    requiredMetrics.every((metric) => !!res.answers[metric] && res.answers[metric] !== null)
  ).length;

  return (answered / total) * 100;
};
