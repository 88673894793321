import { ColumnDef } from '@tanstack/react-table';
import { Table } from 'Molecules';
import { HStack, MenuButton, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import {
  EsrsAssessmentTargetsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetTargetsDrDocument_,
  useDeleteTargetMutation,
} from 'models';
import { useNavigate } from 'react-router-dom';
import { Avatar, Tag, TruncatableText } from 'Atoms';
import { Menu } from 'Molecules/Menu';
import { DeleteIcon, EditIcon } from 'Tokens/Icons/Function';
import { ViewTabs } from './Targets';
import { useMemo } from 'react';
import { CombinedTargetsData } from './Targets.hooks';
const TargetTitle = ({ row }: { row: CombinedTargetsData }) => {
  const latestYear = row.latestMilestone;
  const earliestYear = row.earliestMilestone;
  const dates = useMemo(() => {
    if (latestYear || earliestYear) {
      if (latestYear === earliestYear) return latestYear;
      else return earliestYear + ' - ' + latestYear;
    }
    return '';
  }, [latestYear, earliestYear]);
  return (
    <VStack alignItems="start" spacing="2px">
      <TruncatableText variant="bodyStrong" text={row.title} />
      <Typography variant="micro">{dates}</Typography>
    </VStack>
  );
};
export const TargetsTable = ({
  data,
  currentTab,
  isSearch,
  setAction,
  openAction,
  isOverview,
}: {
  data: CombinedTargetsData[];
  currentTab: string;
  isSearch: boolean;
  setAction: (id: number) => void;
  openAction: () => void;
  isOverview?: boolean;
}) => {
  const navigate = useNavigate();
  const [deleteTarget] = useDeleteTargetMutation();

  const navigateToTargets = (row: CombinedTargetsData) => {
    isOverview
      ? navigate(
          `standard/${row.standard?.reference}/disclosure-requirement/target/${row.category?.reference}/target-form/${row.targetId}/edit`
        )
      : navigate(`target-form/${row.targetId}/edit`);
  };

  const columns: ColumnDef<CombinedTargetsData>[] = [
    {
      header: 'Title',
      accessorKey: 'title',
      meta: {
        width: '45%',
        padding: '0px',
      },
      cell: ({ row }) => <TargetTitle row={row.original} />,
    },
    {
      header: 'Target',
      accessorKey: 'target',
      meta: {
        width: '10%',
        padding: '0px',
      },
      cell: ({ row }) => {
        const val = row.original.targetValue;
        return (
          val && (
            <VStack alignItems="start" spacing="2px">
              <Typography variant="body">{val ? (val > 0 ? '+' : '') + val + '%' : ''}</Typography>
              <Typography variant="micro">by {row.original.latestMilestone}</Typography>
            </VStack>
          )
        );
      },
    },
    {
      header: 'Company',
      accessorKey: 'company',
      meta: {
        width: '15%',
        padding: '0px',
      },
      cell: ({ row }) => {
        return (
          <VStack alignItems="start">
            <Typography variant="body">{row.original.companyName}</Typography>
          </VStack>
        );
      },
    },
    {
      header: isOverview ? 'Related actions' : 'Actions',
      accessorKey: 'actions',
      meta: {
        width: '15%',
        padding: '0px',
      },
      cell: ({ row }) => {
        const sections = row.original.actions.map((action, key) => ({
          actions: [
            {
              id: String(key),
              title: action.title,
              isDisabled: row.original.isSubOrParent,
              onClick: () => {
                setAction(action.id);
                openAction();
              },
            },
          ],
        }));
        return (
          <Menu
            customMenuButton={
              <MenuButton
                onClick={(e) => e.stopPropagation()}
                _hover={{ cursor: row.original.numberOfActions ? 'pointer' : 'default' }}
              >
                <Tag
                  variant={row.original.numberOfActions ? 'info' : 'default'}
                  borderRadius="6px"
                  minH="20px"
                  padding="4px 8px"
                  minW="0px"
                  clickable={row.original.numberOfActions ? true : false}
                  title={String(row.original.numberOfActions)}
                />
              </MenuButton>
            }
            sections={sections}
            size="sm"
          />
        );
      },
    },
    {
      header: isOverview ? 'Owners' : 'Owner',
      accessorKey: 'owner',
      meta: {
        width: '10%',
        padding: '0px',
      },
      cell: ({ row }) => {
        return (
          <VStack alignItems="start">
            <Avatar name={row.original.owner} size="xs" boxSize="28px" />
          </VStack>
        );
      },
    },
    {
      header: '',
      id: 'settings',
      accessorKey: 'settings',
      meta: {
        width: '5%',
        padding: '0px',
      },
      cell: ({ row }) => {
        return (
          !row.original.isSubOrParent && (
            <HStack justifyContent="flex-end">
              <Menu
                sections={[
                  {
                    actions: [
                      {
                        id: 'edit',
                        title: 'Edit',
                        onClick: () => navigateToTargets(row.original),
                        leftElement: <EditIcon color="inherit" />,
                      },
                      {
                        id: 'delete',
                        title: 'Delete',
                        variant: 'destructive',
                        onClick: () => {
                          deleteTarget({
                            variables: {
                              targetId: row.original.targetId,
                            },
                            refetchQueries: [
                              GetTargetsDrDocument_,
                              GetDisclosureRequirementGroupsDocument_,
                              EsrsAssessmentTargetsDocument_,
                            ],
                          });
                        },
                        leftElement: <DeleteIcon color="inherit" />,
                      },
                    ],
                  },
                ]}
                size="sm"
              />
            </HStack>
          )
        );
      },
    },
  ];

  const emptyStateText = useMemo(() => {
    if (isSearch) {
      return 'No targets found';
    } else {
      let message = 'No targets added by ';

      if (currentTab === ViewTabs.subsidiaries) {
        message += 'subsidiaries';
      } else if (currentTab === ViewTabs.parentCompany) {
        message += 'parent company';
      } else {
        message += 'your company';
      }

      return message;
    }
  }, [currentTab]);

  return (
    <Table
      columns={columns}
      data={data}
      withBorder={true}
      headerPadding="8px"
      cellPadding="8px"
      pageSize={20}
      bottomRowBorder={false}
      emptyStateText={emptyStateText}
      onRowClick={(row) => {
        if (!row.isSubOrParent) {
          navigateToTargets(row);
        }
      }}
      conditionalRowProps={(row) => ({
        _hover: {
          cursor: row.isSubOrParent ? 'default' : 'pointer',
          bg: 'bg.hover',
        },
      })}
    />
  );
};
