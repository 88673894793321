import { useCurrentCompanyId } from 'utils/hooks';
import { uniq } from 'lodash';
import { useBusinessUnitsQuery, BusinessUnit, useSimpleBusinessUnitQuery } from 'models';
import { useMemo } from 'react';

export function useBusinessUnitLabels(buFilter: (bu: BusinessUnit) => boolean = () => true) {
  const { companyId } = useCurrentCompanyId();
  const { data } = useBusinessUnitsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const allLabels =
    (data?.businessunits ?? []).filter(buFilter).flatMap(({ labels }) => labels) ?? [];

  return useMemo(() => uniq(allLabels), [allLabels, buFilter]);
}

export function useSimpleBusinessUnit(businessUnitId: string | undefined) {
  const { data: unitData, loading: unitLoading } = useSimpleBusinessUnitQuery({
    variables: {
      id: businessUnitId,
    },
    skip: !businessUnitId,
  });
  return { unitData, unitLoading };
}
