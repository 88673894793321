import { HStack, VStack, Box, FormLabel } from '@chakra-ui/react';
import { FormField, IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { Modal, Select } from 'Molecules';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { BusinessUnitsIcon } from 'Tokens/Icons/Data';
import { Typography } from 'Tokens';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IconHelp, IconTrash } from '@tabler/icons-react';
import {
  EsrsAssessmentDocument_,
  Esrs_MaterialMetricTag_Insert_Input_,
  MaterialMetricsPerDisclosureDocument_,
  useDeleteMaterialMetricTagMutation,
  useUpsertMaterialMetricsMutation,
  useUpsertMaterialMetricTagsMutation,
} from 'models';
import { useToast } from 'utils/hooks';
import { TagSelectionMenu } from './TagSelectMenus';
import { uniqBy } from 'lodash';
import { components } from 'react-select';
import { MetricTagsList } from 'Molecules/MetricTagsList';
import { TableMetricData } from './DataCollection.d';

export enum TagStatus {
  required = 'Required by ESRS',
  added = 'Added',
  requested = 'Requested by parent company',
}

export const FREQUENCY_OPTIONS: { label: string; value: string }[] = [
  { label: 'Select frequency', value: 'instruction' },
  { label: 'Yearly', value: 'Yearly' },
  { label: 'Quarterly', value: 'Quarterly' },
];

export type MetricConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedMetricData?: TableMetricData;
};

export type MaterialMetricFields = {
  frequency: string;
  dataCollection?: string;
};

export type TagBreakdown = {
  type: string;
  values: string[];
  isFromESRS: boolean;
  isFromParent: boolean;
};

export const NoSelectedTags = ({
  checkedTags,
  setCheckedTags,
  tagOptions,
}: {
  checkedTags: TagBreakdown[];
  setCheckedTags: Dispatch<SetStateAction<TagBreakdown[]>>;
  tagOptions: TagBreakdown[];
}) => {
  return (
    <VStack
      width="100%"
      justifyContent="center"
      alignItems="center"
      border="1px dashed"
      borderRadius="8px"
      borderColor="border.default"
      pt="24px"
      pb="16px"
    >
      <BusinessUnitsIcon color="text.hint" />
      <Typography variant="body" color="text.muted" w="275px" align="center">
        You can break down this metric into more granular sub-metrics.
        <br />
        Select how you want to break it down:
      </Typography>
      <TagSelectionMenu
        options={tagOptions ?? []}
        checked={checkedTags}
        setChecked={setCheckedTags}
      />
    </VStack>
  );
};

export const MetricConfigModalParent = ({
  isOpen,
  onClose,
  selectedMetricData,
}: MetricConfigModalProps) => {
  const { control, reset, handleSubmit } = useForm<MaterialMetricFields>({
    defaultValues: {
      frequency: selectedMetricData?.materialByCompany[0]?.frequency ?? 'Yearly',
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const selectedMaterialMetricId = useMemo(
    () => selectedMetricData?.materialByCompany[0]?.id,
    [selectedMetricData]
  );

  const isEditingDisabled = useMemo(
    () =>
      selectedMetricData?.isChild &&
      selectedMetricData.parentMetric?.materialByCompany[0]?.isMaterial,
    [selectedMetricData]
  );

  const adminPanelTags = useMemo(() => {
    return selectedMetricData?.adminPanelTags;
  }, [selectedMetricData]);

  const tagsFromCompany = useMemo(() => {
    return selectedMetricData?.materialByCompany[0]?.materialMetricTags;
  }, [selectedMetricData]);

  const { existingTags, optionsToAdd } = useMemo(() => {
    const esrsMandatory =
      adminPanelTags
        ?.filter((x) => !x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: true,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    const esrsOptional =
      adminPanelTags
        ?.filter((x) => !!x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: false,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    const fromCompany =
      tagsFromCompany?.map((x) => ({
        type: x.tagType,
        isFromESRS: !!esrsMandatory.find((y) => y.type === x.tagType),
        isFromParent: false,
        values:
          adminPanelTags?.find((t) => t.type === x.tagType)?.values.tagValues.map((v) => v.value) ??
          [],
      })) ?? [];

    const existing: TagBreakdown[] = uniqBy([...esrsMandatory, ...fromCompany], 'type');
    const options: TagBreakdown[] = esrsOptional.filter(
      (x) => !existing.find((y) => y.type === x.type)
    );
    return { existingTags: existing, optionsToAdd: options };
  }, [adminPanelTags, tagsFromCompany]);

  const getStatus = (tag: TagBreakdown): TagStatus => {
    if (tag.isFromESRS) return TagStatus.required;
    return TagStatus.added;
  };

  const frequency = useMemo(
    () => selectedMetricData?.materialByCompany[0]?.frequency ?? 'Yearly',
    [selectedMetricData]
  );
  const getAllMaterialMetricChildren = (metric?: TableMetricData): any => {
    const childrenMetrics = metric?.childrenMetrics;

    if (!childrenMetrics) {
      return [];
    }

    const data = childrenMetrics.flatMap((childMetric) => {
      const childData = getAllMaterialMetricChildren(childMetric?.childMetric as TableMetricData);
      return [
        {
          id: childMetric.childMetric?.materialByCompany[0]?.id,
          metricRef: childMetric.childMetric?.reference,
        },
        ...childData,
      ];
    });

    return data;
  };

  const toast = useToast();

  const { isValid, errors } = useFormState({ control });
  const [availableTags, setAvailableTags] = useState<TagBreakdown[]>(existingTags);
  const [options, setOptions] = useState<TagBreakdown[]>(optionsToAdd);
  const [prevTags, setPrevTags] = useState<TagBreakdown[]>(availableTags);

  const [upsertMaterialMetricTag] = useUpsertMaterialMetricTagsMutation({
    refetchQueries: [MaterialMetricsPerDisclosureDocument_],
  });
  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation({
    refetchQueries: [MaterialMetricsPerDisclosureDocument_, EsrsAssessmentDocument_],
  });
  const [deleteMaterialMetricTag] = useDeleteMaterialMetricTagMutation({
    refetchQueries: [MaterialMetricsPerDisclosureDocument_],
  });

  const handleConfirm = async (data: MaterialMetricFields) => {
    const childrenMetrics = getAllMaterialMetricChildren(selectedMetricData);
    const metricAndChildren = [
      {
        id: selectedMaterialMetricId,
        metricRef: selectedMetricData?.reference,
      },
      childrenMetrics,
    ].flat();

    try {
      await upsertMaterialMetric({
        variables: {
          objects: metricAndChildren.map((materialMetric) => {
            return {
              frequency: data.frequency,
              id: materialMetric.id,
              materialStandardId: selectedMetricData?.materialByCompany[0]?.materialStandardId,
              metricRef: materialMetric?.metricRef,
            };
          }),
        },
      });
      toast({
        text: 'Configured metric data collection settings',
      });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric data collection settings',
        variant: 'danger',
      });
    }

    const configuredTags: Esrs_MaterialMetricTag_Insert_Input_[] = availableTags.flatMap((tag) => {
      return metricAndChildren.map((materialMetric) => {
        return {
          materialMetricId: materialMetric.id,
          tagType: tag.type,
        };
      });
    });

    const existingMaterialTags = selectedMetricData?.materialByCompany[0]?.materialMetricTags.map(
      (tag) => tag.tagType
    );

    const tagsToDelete = existingMaterialTags?.filter(
      (tag) => !availableTags?.map((x) => x.type).includes(tag)
    );

    try {
      await upsertMaterialMetricTag({
        variables: {
          objects: configuredTags,
        },
      });
      toast({
        text: 'Configured metric breakdown settings',
      });

      if (tagsToDelete?.length)
        metricAndChildren.forEach((materialMetric) => {
          return deleteMaterialMetricTag({
            variables: {
              materialMetricId: materialMetric.id,
              tagTypes: tagsToDelete ?? [],
            },
          });
        });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric breakdown settings',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (selectedMetricData?.materialByCompany[0]) {
      reset({
        frequency: frequency,
      });
    }
  }, [selectedMetricData]);

  const updatedOptions = useMemo(() => {
    if (!prevTags) return options;

    const removedTags = prevTags.filter(
      (tag) => !availableTags.some((newTag) => newTag.type === tag.type)
    );
    const addedTags = availableTags.filter(
      (newTag) => !prevTags.some((tag) => tag.type === newTag.type)
    );

    return options
      .filter((option) => !addedTags.some((tag) => tag.type === option.type))
      .concat(removedTags);
  }, [options, availableTags, prevTags]);

  useEffect(() => {
    setAvailableTags(existingTags);
    setOptions(optionsToAdd);
    setPrevTags(existingTags);
  }, [existingTags, optionsToAdd]);

  const hasBreakdown = useMemo(() => {
    return !!availableTags?.length || !!updatedOptions.length;
  }, [availableTags, updatedOptions]);

  const { title, shortTitle } = useMemo(() => {
    return { title: selectedMetricData?.title, shortTitle: selectedMetricData?.shortTitle };
  }, [selectedMetricData]);

  const Option = (props: any) => {
    const isInstructions = props.value === 'instruction';
    return isInstructions ? (
      <Box p="10px 16px 8px">
        <Typography variant="detail" color="text.muted">
          {props.label}
        </Typography>
      </Box>
    ) : (
      <components.Option {...props}>
        <HStack px="4px" justifyContent="space-between">
          <Typography variant="bodyStrong" color={props.isSelected ? 'white' : 'text.muted'}>
            {props.label}
          </Typography>
        </HStack>
      </components.Option>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <Typography variant="h2" mr="40px" justifyContent="center">
          {shortTitle ?? title ?? ''}
          {!!adminPanelTags?.length && (
            <>
              {' by '}
              <MetricTagsList tags={adminPanelTags.map((tag) => tag.type)} isHStack={false} />
            </>
          )}
        </Typography>
      }
      subtitle="Metric settings"
      size={hasBreakdown ? 'lg' : 'md'}
      confirmButtonProps={{
        type: 'submit',
        form: 'metric-config-form',
        isDisabled: !isValid,
      }}
      onConfirm={onClose}
    >
      <VStack width="100%" alignItems="stretch" spacing="16px">
        <form id="metric-config-form" onSubmit={handleSubmit(handleConfirm)}>
          <VStack alignItems="start" spacing="16px">
            <Controller
              name="frequency"
              control={control}
              render={({ field: { onChange, value: fieldValue } }) => (
                <FormField
                  label="Frequency"
                  id="frequency"
                  isRequired
                  isInvalid={!!errors.frequency}
                  error="Required"
                >
                  <VStack width="324px" spacing="6px" alignItems="start">
                    <Select<{ value: string; label: string }>
                      width="100%"
                      placeholder="Select"
                      components={{ Option: Option }}
                      isDisabled={isEditingDisabled}
                      value={{ label: fieldValue, value: fieldValue }}
                      options={FREQUENCY_OPTIONS}
                      onChange={(freq) => {
                        onChange(freq?.value ?? undefined);
                      }}
                    />
                    {isEditingDisabled && (
                      <Typography variant="detail">
                        {`It is not possible to change frequency because the parent metric is collected ${frequency.toLocaleLowerCase()}`}
                      </Typography>
                    )}
                  </VStack>
                </FormField>
              )}
            />
          </VStack>
        </form>

        {hasBreakdown && (
          <VStack alignItems="start" width="100%" spacing="6px">
            <FormLabel m="0px">Breakdown</FormLabel>
            {availableTags.length > 0 ? (
              <VStack spacing="6px" w="100%">
                {availableTags.map((tag, index) => {
                  return (
                    <HStack
                      key={`${tag.type}_${index}`}
                      bgColor="bg.interactive"
                      h="48px"
                      borderRadius="6px"
                      w="100%"
                      justifyContent="space-between"
                    >
                      <HStack paddingInline="8px" spacing="16px" w="50%">
                        {index > 0 && (
                          <Tag w="47px" variant="info" borderRadius="6px" title="AND" />
                        )}
                        <TruncatableText variant="bodyStrong" text={`by ${tag.type}`} />
                      </HStack>
                      <HStack w="50%" justifyContent="space-between">
                        <Box>
                          <TruncatableText
                            text={getStatus(tag)}
                            variant="body"
                            paddingInline="8px"
                          />
                        </Box>
                        {!tag.isFromESRS ? (
                          <IconButton
                            aria-label="delete"
                            variant="ghost"
                            icon={<IconTrash size="16px" />}
                            onClick={() => {
                              setAvailableTags((prev) => prev.filter((i) => i.type !== tag.type));
                            }}
                          />
                        ) : (
                          <Tooltip
                            label="'This breakdown is required by the ESRS. You can not delete this breakdown'"
                            placement="bottom"
                          >
                            <IconButton
                              aria-label="help"
                              variant="ghost"
                              icon={<IconHelp size="16px" />}
                            />
                          </Tooltip>
                        )}
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              <NoSelectedTags
                checkedTags={availableTags}
                setCheckedTags={setAvailableTags}
                tagOptions={updatedOptions ?? []}
              />
            )}

            {!!updatedOptions.length && !!availableTags.length && (
              <TagSelectionMenu
                options={updatedOptions}
                checked={availableTags}
                setChecked={setAvailableTags}
              />
            )}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
