import { NhostClient } from '@nhost/react';
import axios from 'axios';

export const nhost = new NhostClient({
  region: import.meta.env?.REACT_APP_NHOST_REGION,
  subdomain: import.meta.env?.REACT_APP_NHOST_SUBDOMAIN,
});

export const setUpNhostSessionManagement = () => {
  console.log('Setting up Nhost session management.');

  nhost.auth.onTokenChanged(async (session) => {
    console.log('Hello', session);

    let refreshTokenId = session?.refreshTokenId;

    if (!refreshTokenId) {
      const subdomain = import.meta.env?.REACT_APP_NHOST_SUBDOMAIN;
      const region = import.meta.env?.REACT_APP_NHOST_REGION;

      const tokenRes = await axios.post(
        `https://${subdomain}.auth.${region ? `${region}.` : ''}nhost.run/v1/token`,
        {
          refreshToken: session?.refreshToken,
        }
      );

      refreshTokenId = tokenRes.data.refreshTokenId;
    }

    console.log('refreshTokenId', refreshTokenId);

    await nhost.functions.call('validate-session', {
      tokenId: refreshTokenId,
    }); // Todo: Handle errors
  });
};
