import { ActionFieldsFragment_ } from 'models';
import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { Button, EmptyState, Infobox, Tag } from 'Atoms';
import { useMemo, useState } from 'react';
import { AddActionModal } from './AddActionModal';
import { DisclosureRequirementTitle } from '../DisclosureRequirementsUtilsComponents';
import { useCompanyType } from 'utils/hooks';
import { LearnMoreDrawer, Loader, SearchInput } from 'Molecules';
import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { useParams } from 'react-router-dom';
import { ViewTabs } from '../Targets/Targets';
import { ActionsTable } from './ActionsTable';
import { ListIcon, TargetIcon } from 'Tokens/Icons/Data';
import { useUserSetting } from 'containers/Navigation';
import { useGetActionsData } from './Actions.hooks';
import { AddIcon } from 'Tokens/Icons/Function';
import { Typography } from 'Tokens';
import { AggregatedMetrics } from '../Metrics';

export const Actions = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onOpen: onDrawerOpen, isOpen: isDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const [actionData, setActionData] = useState<ActionFieldsFragment_ | undefined>();
  const [hasClosedInfo, setHasClosedInfo] = useUserSetting('esrs-has-closed-actions-info', false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedView, setSelectedView] = useState('All');
  const { companyType, loading: companyLoading } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const { esrsAssessmentId = '', standardRef = '' } = useParams();

  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { reportingUnitAssessments: reportingUnits } = useAssessmentReportingUnits(
    esrsAssessmentId ?? ''
  );
  const [showAll, setShowAll] = useState(false);

  const { actionDR, combinedData, loading } = useGetActionsData();

  const { title = '', reference = '', description = '' } = actionDR || {};

  const [filteredData, shouldShowButton] = useMemo(() => {
    const actionsData = combinedData
      .filter((data) => {
        if (selectedView === 'All') return true;
        else if (selectedView === 'Your company') return !data.isSubOrParent;
        return data.isSubOrParent;
      })
      .filter((data) =>
        searchValue ? data.title.toLocaleLowerCase().search(searchValue) !== -1 : true
      );
    const shouldShow = (actionsData?.length ?? 0) > 3;
    return [showAll ? actionsData : actionsData.slice(0, 3), shouldShow];
  }, [searchValue, selectedView, combinedData, showAll]);

  const showInfobox = useMemo(
    () => !hasClosedInfo && !isGroup && filteredData.some((act) => act.isSubOrParent),
    [hasClosedInfo, isGroup, filteredData]
  );

  if (loading || companyLoading) {
    return <Loader />;
  }

  return (
    <VStack spacing="20px" alignItems="start" w="100%">
      <DisclosureRequirementTitle
        title="Action plan"
        reference={actionDR?.reference}
        onOpen={onDrawerOpen}
      />
      <VStack spacing="40px" alignItems="start" w="100%">
        <VStack spacing="6px" alignItems="start" w="100%">
          <Typography variant="h3">Manage actions</Typography>
          {!combinedData.length ? (
            <EmptyState
              description="Transform your ambitions into tangible results by creating an action plan with defined owners, and impact on your targets"
              icon={<ListIcon boxSize="20px" color="text.hint" />}
              variant="white"
              component={true}
              callToAction={{
                text: 'Add action',
                variant: 'ghost',
                leftIcon: <AddIcon />,
                onClick: () => {
                  onOpen();
                },
              }}
            />
          ) : (
            <VStack alignItems="start" spacing="16px" w="100%">
              <VStack alignItems="start" spacing="12px" w="100%">
                {showInfobox && (
                  <Box w="100%">
                    <Infobox
                      status="info"
                      titleProps={{ variant: 'h3' }}
                      icon={<TargetIcon color="text.info" />}
                      title="Group company actions"
                      onClose={() => setHasClosedInfo(true)}
                      description="Review actions set by the parent company. You can also add your own actions. "
                    />
                  </Box>
                )}
                <HStack justifyContent="space-between" w="100%">
                  <HStack>
                    <SearchInput
                      search={searchValue}
                      setSearch={setSearchValue}
                      placeholder="Filter"
                      withLeftIcon={false}
                      minW="200px"
                    />
                    {Object.values(ViewTabs)
                      .filter((tab) =>
                        isGroup ? tab !== ViewTabs.parentCompany : tab !== ViewTabs.subsidiaries
                      )
                      .map((tab) => (
                        <Tag
                          variant={selectedView === tab ? 'info' : 'button'}
                          size="md"
                          height="36px"
                          title={tab}
                          borderRadius="8px"
                          p="16px"
                          clickable={true}
                          onClick={() => setSelectedView(tab)}
                        />
                      ))}
                  </HStack>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setActionData(undefined);
                      onOpen();
                    }}
                  >
                    Add action
                  </Button>
                </HStack>
              </VStack>
              <VStack alignItems="start" w="100%">
                <ActionsTable
                  data={filteredData}
                  currentTab={selectedView}
                  isSearch={!!searchValue}
                  setAction={setActionData}
                  openAction={onOpen}
                />
                {shouldShowButton && (
                  <Button variant="ghost" onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Hide' : 'Show all...'}
                  </Button>
                )}
              </VStack>
            </VStack>
          )}
        </VStack>
        <AddActionModal
          isOpen={isOpen}
          onClose={onClose}
          actionToEdit={actionData}
          disclosureRequirementRef={reference}
          reportingUnits={reportingUnits}
          isGroup={isGroup}
          materialityAssessmentId={companyAssessmentId}
        />
        {!isGroup && (
          <VStack alignItems="start">
            <AggregatedMetrics />
          </VStack>
        )}
      </VStack>
      <LearnMoreDrawer
        header={title}
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        description={description ?? ''}
      />
    </VStack>
  );
};
