import { Divider, HStack, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { PortfolioCompany } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { CompanySelector } from './CompanySelector';
import { DataCollection, DataCollectionOption } from './PaiIndicatorsModal';

export const PaiDataCollectionSelector = ({
  selectedOption,
  setSelectedOption,
  portfolioCompanies,
}: {
  selectedOption: DataCollection | undefined;
  setSelectedOption: (selectedOption: DataCollection) => void;
  portfolioCompanies: PortfolioCompany[];
}) => {
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);

  const displayedSelectedCompanies = useMemo(() => {
    return selectedCompanies.map((companyId) => {
      return portfolioCompanies.find((company) => company?.id === companyId)?.company?.name;
    });
  }, [selectedCompanies]);

  useEffect(() => {
    if (selectedCompanies.length > 0) {
      setSelectedOption({
        option: DataCollectionOption.selectCompanies,
        selectedPortfolioCompanyIds: selectedCompanies,
      });
    }
  }, [selectedCompanies]);

  return (
    <Menu gutter={2} size="lg">
      <MenuButton
        as={Button}
        width="100%"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        color="text.default"
        isDisabled={portfolioCompanies.length === 0}
      >
        <Typography
          variant="body"
          noOfLines={1}
          color={portfolioCompanies.length === 0 ? 'text.disabled' : 'text.default'}
        >
          {selectedOption?.option === DataCollectionOption.selectCompanies
            ? displayedSelectedCompanies.join(', ') || 'Select Companies'
            : selectedOption?.option}
        </Typography>
      </MenuButton>
      <MenuList p="8px">
        <MenuGroup p="8px">
          <MenuItem
            p="8px"
            m="0px"
            w="100%"
            onClick={() =>
              setSelectedOption({
                option: DataCollectionOption.allCompanies,
                selectedPortfolioCompanyIds: [],
              })
            }
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              color={
                selectedOption?.option === DataCollectionOption.allCompanies ? 'text.selected' : ''
              }
            >
              <HStack>
                <Typography color={'inherit'} variant="bodyStrong">
                  All companies
                </Typography>
              </HStack>
              {selectedOption?.option === DataCollectionOption.allCompanies && (
                <CheckIcon color="inherit" />
              )}
            </HStack>
          </MenuItem>

          <MenuItem
            p="0px"
            m="0px"
            w="100%"
            onClick={() =>
              setSelectedOption({
                option: DataCollectionOption.selectCompanies,
                selectedPortfolioCompanyIds: selectedCompanies,
              })
            }
          >
            <CompanySelector
              checked={selectedCompanies}
              setChecked={setSelectedCompanies}
              companies={portfolioCompanies}
              isSelected={selectedOption?.option === DataCollectionOption.selectCompanies}
            />
          </MenuItem>
        </MenuGroup>
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <MenuGroup p="8px">
          <MenuItem
            p="8px"
            m="0px"
            w="100%"
            onClick={() =>
              setSelectedOption({
                option: DataCollectionOption.decidePerIndicator,
                selectedPortfolioCompanyIds: [],
              })
            }
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              color={
                selectedOption?.option === DataCollectionOption.decidePerIndicator
                  ? 'text.selected'
                  : ''
              }
            >
              <HStack>
                <Typography color={'inherit'} variant="bodyStrong">
                  Decide per indicator
                </Typography>
              </HStack>
              {selectedOption?.option === DataCollectionOption.decidePerIndicator && (
                <CheckIcon color="inherit" />
              )}
            </HStack>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
