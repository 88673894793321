import {
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
} from 'models';
import {
  getAggregatedMetricAnswers,
  getFullMetricWithChildren,
  getMetricAnswers,
  MetricsTableData,
} from '../DisclosureRequirements';
import { AssessableMetrics } from '../DisclosureRequirements/Metrics/Metrics';
import { uniq } from 'lodash';

export const getMetricResult = (
  metric: AssessableMetrics[number],
  nestedMetric: MetricsTableData,
  reportingUnits?: GetAnswersForMetricsOnCompanyLevelQuery_,
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_,
  isGroup?: boolean,
  standardRef?: string
) => {
  const allMaterialMetrics = getFullMetricWithChildren(metric)?.map((m) => m.materialMetrics?.[0]);
  const metricAndChildrenRefs = uniq(allMaterialMetrics?.map((m) => m?.metric.reference));

  const answers = isGroup
    ? groupAnswers?.EsrsAssessment_by_pk?.subsidiaryAssessments.flatMap((sub) => {
        const allCurrentMaterialMetrics = sub.materialStandards
          .find((standard) => standard.standard.reference === standardRef)
          ?.materialMetrics.filter((m) => metricAndChildrenRefs.includes(m?.metric.reference));
        return sub.reportingUnits.flatMap((ru) =>
          getMetricAnswers(
            ru,
            true,
            getFullMetricWithChildren(metric),
            metric?.materialMetrics[0],
            allCurrentMaterialMetrics,
            sub,
            standardRef
          )
        );
      })
    : reportingUnits?.EsrsAssessment_by_pk?.reportingUnits.flatMap((ru) =>
        getMetricAnswers(
          ru,
          false,
          getFullMetricWithChildren(metric),
          metric?.materialMetrics[0],
          allMaterialMetrics
        )
      );

  const result = getAggregatedMetricAnswers(nestedMetric, answers);

  return result;
};
