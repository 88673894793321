import { VStack, Center, HStack, Text } from '@chakra-ui/react';
import { useToast } from 'utils/hooks/useToast';
import { Invitation, User } from 'models';
import { CompanyAvatar } from 'Organisms';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'utils/translation';
import { Modal } from 'Molecules';

import {
  useRemoveInvitationId,
  useAcceptInvitation,
  useUpsertCompanyInvitation,
  useValidateInvitation,
} from './CompanyInvitations.hooks';

export function CompanyInvitationsModal({
  user,
  invitationId,
  onClose,
}: {
  user?: User;
  invitationId: Invitation['id'];
  onClose: () => void;
}) {
  const toast = useToast();
  const [updateUserInvite] = useUpsertCompanyInvitation();
  const { invitation, loading: invitationLoading } = useValidateInvitation(
    invitationId ?? undefined
  );

  const loading = invitationLoading || !user;
  const { t } = useTranslation('common');
  const [queryInvitationId, removeInvitationId] = useRemoveInvitationId();
  const invalidInvitation = () => {
    removeInvitationId();
    toast({
      text: `${t('common:toast.invitationFailed.title')} 
      ${t('common:toast.invitationFailed.description')}`,
      variant: 'danger',
    });
    onClose();
  };
  useEffect(() => {
    if (invitationId && !invitationLoading && !invitation) invalidInvitation();
  }, [invitationId, invitationLoading, invitation]);

  const onRejectInvite = () => {
    if (invitation)
      updateUserInvite({ status: 'rejected' }, invitation).then(() => {
        toast({
          text: `${t('common:toast.invitationRejected.title')} 
          ${t('common:toast.invitationRejected.description')}`,
        });
        onClose();
      });
  };
  const acceptInvitation = useAcceptInvitation(t);
  const autoAccept = invitationId === queryInvitationId;
  const autoAcceptMode = useMemo(() => autoAccept, [invitationId, autoAccept]);
  const handleJoinCompany = () => {
    if (invitation && user) acceptInvitation(invitation, user).finally(onClose);
  };
  useEffect(() => {
    if (autoAccept && invitation && user)
      if (invitation.userEmail !== user.email) return invalidInvitation();
      else {
        removeInvitationId();
        handleJoinCompany();
      }
  }, [invitation, autoAccept, user]);
  return (
    <Modal
      isOpen={!!invitationId}
      size="sm"
      title={t('common:invitations.header')}
      onClose={onClose}
      confirmVariant="primary"
      confirmText={t('common:invitations.acceptButton')}
      onConfirm={handleJoinCompany}
      confirmButtonProps={{ isDisabled: loading, isLoading: autoAcceptMode }}
      cancelVariant="destructive"
      cancelText={t('common:invitations.rejectButton')}
      onCancel={onRejectInvite}
      cancelButtonProps={{ isDisabled: loading || autoAcceptMode }}
    >
      <VStack spacing="md">
        <Center width="100%" flexDir="column" gap="20px">
          <VStack spacing="md">
            <HStack>
              <Text fontSize="lg">{t('common:invitations.acceptFormHeader')}</Text>
            </HStack>
            <HStack>
              <CompanyAvatar company={invitation?.company} />
            </HStack>
          </VStack>
        </Center>
      </VStack>
    </Modal>
  );
}
