import { Center, Box, keyframes, VisuallyHidden, SpinnerProps } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'utils/translation';

export type LoaderProps = React.PropsWithChildren<{
  height?: string | number;
  width?: string | number;
  label?: string;
  debugName?: string;
  isLoaded?: boolean;
}>;
const rotate = keyframes(`  
  to {transform:rotate(-15deg) translateX(-250%);}
`);
export function CelsiaSpinner({ label, speed = '1s', boxSize = '55px', ...props }: SpinnerProps) {
  return (
    <Box display="inline-block" position="relative" boxSize={boxSize} {...props}>
      <Box
        borderRadius="full"
        bg="text.brand"
        width="31%"
        height="31%"
        position="absolute"
        left="71%"
        top="51%"
        transformOrigin="top left"
        transform="rotate(45deg) translateX(-250%);"
        animation={`${rotate} infinite ${speed} ease-in-out alternate`}
        className="celsia-spinner"
      />
      <svg width="100%" height="100%" viewBox="0 0 51 51">
        <path
          d="M17.1406 25.474C17.1406 15.3154 25.3821 8.07263 36.4037 8.07263C42.56 8.07263 47.624 10.142 51 13.9985L45.7374 18.6075C43.3543 15.9738 40.3755 14.6569 36.8009 14.6569C30.1482 14.6569 25.4814 19.0778 25.4814 25.38C25.4814 31.6821 30.1482 36.103 36.8009 36.103C40.3755 36.103 43.3543 34.7861 45.7374 32.1524L51 36.7614C47.624 40.618 42.56 42.7814 36.4037 42.7814C25.3821 42.8754 17.1406 35.6327 17.1406 25.474Z"
          fill="black"
        />
      </svg>
      <VisuallyHidden>{label}</VisuallyHidden>
    </Box>
  );
}

export const Loader = React.forwardRef(function Loader(
  { height = '100%', width = '100%', label, debugName, children, isLoaded = false }: LoaderProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { t, ready } = useTranslation(undefined, { useSuspense: false });
  if (import.meta.env?.NODE_ENV == 'development')
    useEffect(() => {
      if (debugName) {
        console.debug('Loader ', debugName, 'loaded');
        return () => console.debug('Loader', debugName, 'destroyed');
      }
    }, [debugName]);
  return isLoaded && ready ? (
    <>{children}</>
  ) : (
    <Center height={height} width={width} ref={ref}>
      {!!height && (
        <CelsiaSpinner boxSize="55px" label={label ?? ready ? t('loading') : 'Loading...'} />
      )}
    </Center>
  );
});
