import { Box, HStack } from '@chakra-ui/react';
import { Tooltip } from 'Atoms';
import React from 'react';
import { Typography } from 'Tokens';
import { ArrowBarToLeftIcon, ArrowBarToRightIcon } from 'Tokens/Icons/Direction';
import { useTranslation } from 'utils/translation';
import { SideMenuItem, SideMenuSection } from '.';
import { SideMenuBlock } from './SideMenu';

export const CollapseButton = ({ isOpen, toggle }: { isOpen: boolean; toggle: () => void }) => {
  const { t } = useTranslation('common');
  return (
    <Box
      textAlign="center"
      color="white"
      width="100%"
      height="35px"
      borderRadius="8px"
      cursor="pointer"
      transition="all 0.4s"
      _pressed={{ bg: 'bg.pressed' }}
      _hover={{
        bg: 'bg.hover',
      }}
      onClick={toggle}
    >
      <Tooltip placement="right" label={!isOpen && t(`common:module.expand`)} mt="0.5px">
        <HStack as="span" spacing="8px" alignItems="left" p="8px 12px">
          {isOpen ? (
            <ArrowBarToLeftIcon boxSize="20px" color="inherit" />
          ) : (
            <ArrowBarToRightIcon boxSize="20px" color="inherit" />
          )}
          <Typography
            color="inherit"
            variant="bodyStrong"
            transition="all 0.4s"
            opacity={isOpen ? 1 : 0}
          >
            {t(`common:module.hide`)}
          </Typography>
        </HStack>
      </Tooltip>
    </Box>
  );
};

export const SideMenuItems = ({
  isOpen,
  items,
  section,
}: {
  isOpen: boolean;
  items: SideMenuItem[];
  section: SideMenuSection;
}) => {
  const filterBySection = (currentSection: SideMenuSection) => {
    return (item: SideMenuItem) => item.section === currentSection;
  };

  return (
    <>
      {items.filter(filterBySection(section)).map((item) => (
        <SideMenuBlock item={item} key={item.path} isOpen={isOpen} />
      ))}
    </>
  );
};
