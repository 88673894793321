import { Box, Table as ChakraTable, Td, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import { DateInput, NumberInput, TruncatableText } from 'Atoms';
import { GetReportingUnitsQuery_ } from 'models';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { KeyResultEnums } from '../Requirement';
import { getBaselineTotal, SubsidiariesType } from './Targets.hooks';

export const BaseLineModal = ({
  isOpen,
  onClose,
  isGroup,
  subsidiaries,
  reportingUnits,
  localAnswers,
  setLocalAnswers,
  isReadOnly,
  unit,
}: {
  isOpen: boolean;
  onClose: () => void;
  isGroup: boolean;
  subsidiaries: SubsidiariesType;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  localAnswers: any;
  setLocalAnswers: any;
  isReadOnly: boolean;
  unit: string;
}) => {
  const onConfirm = () => {
    setLocalAnswers({
      ...localAnswers,
      baseline: isGroup
        ? getBaselineTotal(subsidiaries ?? [], localAnswers)
        : getBaselineTotal(reportingUnits ?? [], localAnswers),
    });
    onClose();
  };

  const total = isGroup
    ? getBaselineTotal(subsidiaries ?? [], localAnswers ?? 0)
    : getBaselineTotal(reportingUnits ?? [], localAnswers ?? 0);

  return (
    <Modal title="Add baseline" isOpen={isOpen} onClose={onClose} onConfirm={onConfirm}>
      <VStack spacing="16px" alignItems="start" w="100%">
        <VStack spacing="6px" alignItems="start" w="100%">
          <Typography variant="bodyStrong">Year</Typography>
          <Box zIndex="1">
            <DateInput
              width="456px"
              value={localAnswers.baseYear ?? new Date()}
              setValue={(val) => setLocalAnswers({ ...localAnswers, baseYear: val ?? new Date() })}
              showYearPicker
              dateFormat="yyyy"
              disabled={isReadOnly}
            />
          </Box>
        </VStack>
        <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
          <ChakraTable sx={{ tableLayout: 'fixed' }}>
            <Thead>
              <Th px="8px" textTransform="none" letterSpacing="normal">
                <Typography variant="bodyStrong" as="span">
                  Department
                </Typography>
              </Th>
              <Th px="8px" textTransform="none" letterSpacing="normal">
                <Typography variant="bodyStrong" as="span">
                  Baseline
                </Typography>
                <Typography variant="detail">{unit}</Typography>
              </Th>
            </Thead>
            <Tr>
              <Td padding="8px" textAlign="left">
                <Typography variant="bodyStrong" as="span">
                  Company
                </Typography>
              </Td>
              <Td padding="8px" textAlign="left">
                <Typography variant="bodyStrong" as="span">
                  {Number.isNaN(total) ? 0 : total}
                </Typography>
              </Td>
            </Tr>
            {isGroup
              ? subsidiaries?.map((s, index) => (
                  <Tr>
                    <Td
                      padding="8px"
                      textAlign="right"
                      border={index === subsidiaries.length - 1 ? 'none' : ''}
                    >
                      <TruncatableText text={s.company.name} variant="body" />
                    </Td>
                    <Td
                      padding="8px"
                      textAlign="right"
                      key={`${KeyResultEnums.baseline}_${s.id}`}
                      border={index === subsidiaries.length - 1 ? 'none' : ''}
                    >
                      <NumberInput
                        clampValueOnBlur
                        value={
                          Number.isNaN(localAnswers[`${KeyResultEnums.baseline}_${s.id}`])
                            ? 0
                            : localAnswers[`${KeyResultEnums.baseline}_${s.id}`]
                        }
                        onChange={(val) =>
                          setLocalAnswers({
                            ...localAnswers,
                            [`${KeyResultEnums.baseline}_${s.id}`]: val,
                          })
                        }
                        isBorderless={true}
                        textAlign="left"
                        isDisabled={isGroup}
                      />
                    </Td>
                  </Tr>
                ))
              : reportingUnits?.map((ru, index) => (
                  <Tr>
                    <Td
                      padding="8px"
                      textAlign="right"
                      border={index === reportingUnits.length - 1 ? 'none' : ''}
                    >
                      <TruncatableText text={ru.name ?? ''} variant="body" />
                    </Td>
                    <Td
                      padding="8px"
                      textAlign="right"
                      key={`${KeyResultEnums.baseline}${ru.id}`}
                      border={index === reportingUnits.length - 1 ? 'none' : ''}
                    >
                      <NumberInput
                        clampValueOnBlur
                        value={
                          Number.isNaN(localAnswers[`${KeyResultEnums.baseline}_${ru.id}`])
                            ? ''
                            : localAnswers[`${KeyResultEnums.baseline}_${ru.id}`]
                        }
                        onChange={(val) =>
                          setLocalAnswers({
                            ...localAnswers,
                            [`${KeyResultEnums.baseline}_${ru.id}`]: val,
                          })
                        }
                        isBorderless={true}
                        textAlign="left"
                        isDisabled={isGroup}
                      />
                    </Td>
                  </Tr>
                ))}
          </ChakraTable>
        </Box>
      </VStack>
    </Modal>
  );
};
