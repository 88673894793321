import { Tooltip } from '@chakra-ui/react';
import { colors } from 'Tokens';
import { formatNum } from 'utils/numbers';

export const ProgressBar = ({
  bg = colors.bg.progress,
  completed,
  noTooltip = false,
  isGroup,
}: {
  bg?: string;
  completed: number;
  noTooltip?: boolean;
  isGroup?: boolean;
}) => {
  const containerStyles = {
    height: '3px',
    width: '100%',
    backgroundColor: '#CACED6',
    borderRadius: 50,
    marginLeft: isGroup ? '32px' : '0px',
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bg,
    borderRadius: 'inherit',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  if (noTooltip) {
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles} />
        </div>
      </div>
    );
  }
  return (
    <Tooltip label={`Completed: ${formatNum(completed, 0)}%`}>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles} />
        </div>
      </div>
    </Tooltip>
  );
};
