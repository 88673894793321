import { MinusIcon } from '@chakra-ui/icons';
import { VStack, HStack, IconButton, Collapse, Box, useDisclosure } from '@chakra-ui/react';
import { Alert, Button, EmptyState } from 'Atoms';
import { Header, BodyText } from 'Tokens';
import { ObjectiveWithQuestions, ResolvedQuestion } from 'models';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import { QUESTION_FILTERS } from './BusinessUnitAssessment';
import { QuestionsList } from './QuestionsList';
import { AddIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { useActivityVersionComparison } from 'containers/Assessments/VersioningOfQuestions.hooks';
import { RemovedQuestionsModal } from 'containers/Assessments/pieces/RemovedQuestionsModal';
import { ObjectiveId } from 'utils/objectives/objectivesOrder';

export const ObjectiveListItem = ({
  objective,
  index,
  total,
  activityAssessmentId,
  openUpdatedQuestionModal,
  showVersionAlert,
  isLocked,
}: {
  objective: ObjectiveWithQuestions;
  index: number;
  total: number;
  activityAssessmentId: string;
  openUpdatedQuestionModal: (q: ResolvedQuestion) => void;
  showVersionAlert?: boolean;
  isLocked: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFilters = useMemo(() => {
    const filters = searchParams.get('filter');
    return filters ? filters.split('_') : [];
  }, [searchParams]);

  const filteredQuestions = useMemo(() => {
    if (!selectedFilters.length) return objective.questions;
    return objective.questions.filter((q) =>
      selectedFilters.some((f) => QUESTION_FILTERS[f as keyof typeof QUESTION_FILTERS].filter(q))
    );
  }, [objective, selectedFilters]);

  const {
    removedQuestionsPerObjective,
    markRemovedQuestionsAsReviewed,
    updateActivityVersionLoading,
    activityVersionConfig,
  } = useActivityVersionComparison(activityAssessmentId);
  const removedQuestions = useMemo(
    () => removedQuestionsPerObjective[objective.key as ObjectiveId],
    [removedQuestionsPerObjective, objective]
  );

  const {
    isOpen: isOpenRemovedQuestionsModal,
    onOpen: onOpenRemovedQuestionsModal,
    onClose: onCloseRemovedQuestionsModal,
  } = useDisclosure();

  const { t } = useTranslation('common');
  const [collapsed, setCollapsed] = useState(true);
  return (
    <HStack width="100%" justifyContent="stretch">
      <VStack
        key={objective.key}
        spacing="8px"
        borderTopColor="border.decorative"
        borderTopWidth="1px"
        borderTopStyle="solid"
        paddingY="16px"
        alignItems="stretch"
        width="100%"
      >
        <HStack width="100%" justifyContent="space-between">
          <HStack spacing="8px">
            <IconButton
              aria-label="expand"
              icon={collapsed ? <MinusIcon color="inherit" /> : <AddIcon color="inherit" />}
              onClick={() => setCollapsed((prev) => !prev)}
              variant="ghost"
              color="black"
              border="2px solid black"
              size="sm"
              height="24px"
            />
            <Header fontSize="h3">{objective.title}</Header>
          </HStack>
          <BodyText
            color="text.muted"
            textTransform="uppercase"
            fontWeight="semibold"
            fontSize="sm"
          >
            {t('common:objective.count', { count: index + 1, total })}
          </BodyText>
        </HStack>
        <HStack as={Collapse} in={collapsed} width="100%" justifyContent="stretch">
          {removedQuestions?.length &&
            showVersionAlert &&
            !activityVersionConfig?.dismissedRemovedQuestionsObjectives?.includes(
              objective.key
            ) && (
              <Alert status="warning" title="Some questions were removed" closable={false}>
                <Button variant="ghost" size="sm" onClick={onOpenRemovedQuestionsModal}>
                  Review
                </Button>
              </Alert>
            )}
          {filteredQuestions?.length ? (
            <QuestionsList
              activityAssessmentId={activityAssessmentId}
              questions={filteredQuestions}
              prefix={`${index + 1}`}
              openUpdatedQuestionModal={openUpdatedQuestionModal}
              showVersionAlert={showVersionAlert}
              isLocked={isLocked}
            />
          ) : (
            <Box w="100%" h="332px" py="16px">
              <EmptyState
                title={t('common:search.filter.emptyTitle')}
                description={t('common:search.filter.emptyDescription')}
                callToAction={{
                  text: t('common:search.filter.emptyBtn'),
                  variant: 'secondary',
                  onClick: () => {
                    setSearchParams({
                      filter: '',
                    });
                  },
                  leftIcon: <RefreshIcon color="inherit" />,
                }}
                component={true}
              />
            </Box>
          )}
        </HStack>
      </VStack>
      <RemovedQuestionsModal
        isOpen={isOpenRemovedQuestionsModal}
        onClose={onCloseRemovedQuestionsModal}
        onConfirm={() => {
          markRemovedQuestionsAsReviewed(objective.key).then(onCloseRemovedQuestionsModal);
        }}
        removedQuestions={removedQuestions}
        confirmButtonProps={{ isLoading: updateActivityVersionLoading }}
      />
    </HStack>
  );
};

export const ObjectiveList = ({
  objectives,
  activityAssessmentId,
  openUpdatedQuestionModal,
  showVersionAlert,
  isLocked,
}: {
  objectives: ObjectiveWithQuestions[];
  activityAssessmentId: string;
  openUpdatedQuestionModal: (q: ResolvedQuestion) => void;
  showVersionAlert?: boolean;
  isLocked: boolean;
}) => {
  return (
    <VStack width="100%" alignItems="stretch" spacing="16px" maxH="calc(100vh -80px)">
      {objectives.map((objective, index) => (
        <ObjectiveListItem
          key={`${objective.key}-${activityAssessmentId}`}
          objective={objective}
          index={index}
          total={objectives.length}
          activityAssessmentId={activityAssessmentId}
          openUpdatedQuestionModal={openUpdatedQuestionModal}
          showVersionAlert={showVersionAlert}
          isLocked={isLocked}
        />
      ))}
    </VStack>
  );
};
