import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import {
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetMetricsDrQuery_,
  MaterialMetricsFieldsFragment_,
  useCompanyLevelMetricsPerDisclosureQuery,
  useGetAnswersForMetricsOnCompanyLevelQuery,
  useGetAnswersForMetricsOnGroupLevelQuery,
  useGetEsrsAssessmentMetadataQuery,
  useGetMetricsDrQuery,
} from 'models';
import { TableData } from 'Molecules/NestedTable';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import { getMetricWithChildrenRefs } from '../../../Assessment';
import {
  COMPANY,
  FrequencyEnums,
  QuartersObject,
  QUARTERS_FIELDS,
  TimePeriodsEnums,
  YearObject,
} from '../../Requirement';
import { getNestedRows } from '../MetricAnswers.hooks';
import { AssessableMetrics } from '../Metrics';
import {
  combinedFiltersForNoTags,
  combinedFiltersForTags,
  resolveMetricCalculation,
} from './calculations';

type MetricType = NonNullable<GetMetricsDrQuery_['DisclosureRequirement_by_pk']>['metrics'][number];

type AggregatedMetricsExtraData = {
  metric: MetricType;
  tags?: {
    tagType: string;
    tagValue: string;
  }[];
  tagName?: string;
  tagType?: string;
  isChild?: boolean;
  result?: YearObject;
  reportingUnitId?: string;
};

export type AggregatedMetricsTableData = TableData<AggregatedMetricsExtraData>;

type AnswerMetricsQueryType = NonNullable<
  GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
>;
type SubsidiaryAssessmentsType = AnswerMetricsQueryType['subsidiaryAssessments'][number];
type ReportingUnitType =
  AnswerMetricsQueryType['subsidiaryAssessments'][number]['reportingUnits'][number];
export type MetricAnswer =
  AnswerMetricsQueryType['subsidiaryAssessments'][number]['reportingUnits'][number]['answers'][number] & {
    frequency?: string;
    hasTags?: boolean;
  };

export type DataPoint = MetricAnswer['datapoints'][number];

export const getTotal = (answers: QuartersObject) => {
  const sum = Object.values(answers)?.reduce((a, b) => Number(a) + Number(b), 0);
  return sum;
};

export const useGetAggregatedMetricsData = () => {
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const {
    companyAssessmentId,
    parentAssessmentId,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: metricsData, loading: metricsLoading } = useGetMetricsDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      materialStandardId: companyAssessmentId,
      parentStandardId: parentAssessmentId || companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId,
  });

  const [metricDR, metrics] = useMemo(
    () => [
      metricsData?.DisclosureRequirement_by_pk,
      metricsData?.DisclosureRequirement_by_pk?.metrics,
    ],
    [metricsData]
  );

  const { reportingUnitAssessments } = useAssessmentReportingUnits(esrsAssessmentId);
  const companyLevelReportingUnitId = useMemo(
    () => reportingUnitAssessments.find((ru) => ru.isCompanyLevel)?.id ?? '',
    [reportingUnitAssessments]
  );

  const { data: companyData, loading: companyLoading } = useCompanyLevelMetricsPerDisclosureQuery({
    variables: {
      disclosureRequirementRef,
      companyAssessmentId,
      parentAssessmentId: parentAssessmentId || companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId,
  });

  const materialOnlyMetrics: AssessableMetrics = useMemo(
    () =>
      companyData?.assessableMetrics.filter((metric) =>
        metric.materialMetrics.some((mm) => mm.isMaterial === true)
      ) ?? [],
    [metrics, companyData]
  );

  const filteredMetrics: AssessableMetrics = useMemo(
    () =>
      materialOnlyMetrics.filter((metric) => {
        const materialMetric = metric.materialMetrics.find(
          (mm) => mm.materialStandardId === companyAssessmentId
        );
        return (
          materialMetric &&
          !(
            materialMetric?.isMaterial &&
            metric.parentMetrics.some((parentMetric) =>
              materialOnlyMetrics.some((m) => m.reference === parentMetric.parentMetricRef)
            )
          )
        );
      }) ?? [],
    [materialOnlyMetrics]
  );

  const { data: metaData, loading: metaDataLoading } = useGetEsrsAssessmentMetadataQuery({
    variables: {
      id: esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const metadata = useMemo(() => metaData?.EsrsAssessment_by_pk?.metadata, [metaData]);
  const isCompanyLevel = useMemo(() => !!companyData?.assessableMetrics.length, [companyData]);
  return {
    metricDR,
    metrics,
    filteredMetrics,
    companyLevelReportingUnitId,
    metaData,
    metadata,
    isCompanyLevel,
    companyAssessmentId,
    parentAssessmentId,
    metaDataLoading,
    dataLoading: metricsLoading || materialLoading || companyLoading || metaDataLoading,
  };
};

export const getFullMetricWithChildren = (metric: MetricType) => {
  const childrenMetricRefs = [metric];

  const recurseChildren = (childrenMetrics: MetricType['childrenMetrics']) => {
    if (!!childrenMetrics?.length) {
      childrenMetrics.forEach((child) => {
        if (!!child.childMetric) {
          childrenMetricRefs.push(child.childMetric);
          recurseChildren(child?.childMetric.childrenMetrics);
        }
      });
    }
  };

  if (metric?.childrenMetrics) {
    recurseChildren(metric?.childrenMetrics);
  }
  const filteredMetrics = childrenMetricRefs.filter((m) => m !== undefined) ?? [];
  return filteredMetrics;
};

const shouldIncludeMetricAnswer = (
  mAnswers: MetricAnswer,
  isGroup: boolean,
  materialMetric?: MaterialMetricsFieldsFragment_,
  subsidiary?: SubsidiaryAssessmentsType,
  standardRef?: string,
  childMaterialMetric?: MaterialMetricsFieldsFragment_
) => {
  const subsidiaryMaterialityAssessment = subsidiary?.materialStandards.find(
    (assessment) => assessment.standard.reference === standardRef
  );

  const subsidiaryMaterialMetric = isGroup
    ? childMaterialMetric ??
      subsidiaryMaterialityAssessment?.materialMetrics.find(
        (mm) => mm.metric.reference === materialMetric?.metric?.reference
      )
    : childMaterialMetric ?? materialMetric;

  const materialDataCollection = subsidiaryMaterialMetric?.dataCollection;

  const shouldInclude =
    materialDataCollection === 'company'
      ? mAnswers.reportingUnit.isCompanyLevel
      : !mAnswers.reportingUnit.isCompanyLevel;

  const frequency = subsidiaryMaterialMetric?.frequency;
  const hasTags = !!subsidiaryMaterialMetric?.materialMetricTags?.length;
  return { shouldInclude, frequency, hasTags };
};

export const getMetricAnswers = (
  reportingUnit: ReportingUnitType,
  isGroup: boolean,
  allMetricRefs: MetricType[],
  materialMetric?: MaterialMetricsFieldsFragment_,
  allMaterialMetrics?: MaterialMetricsFieldsFragment_[],
  subsidiary?: SubsidiaryAssessmentsType,
  standardRef?: string
) => {
  const allAnswersWithFrequency = allMetricRefs.flatMap((metric) => {
    const childMaterialMetric = allMaterialMetrics?.find(
      (mm) => mm?.metric.reference === metric.reference
    );
    return reportingUnit.answers.map((answer) => {
      const { shouldInclude, frequency, hasTags } = shouldIncludeMetricAnswer(
        answer,
        isGroup,
        materialMetric,
        subsidiary,
        standardRef,
        childMaterialMetric
      );
      if (answer.metricRef === metric.reference && shouldInclude) {
        return { ...answer, frequency: frequency, hasTags: hasTags };
      }
      return undefined;
    });
  });

  return allAnswersWithFrequency.filter((a) => a !== undefined) as MetricAnswer[];
};

const getRequiredAnswers = (
  metric: AggregatedMetricsTableData['metric'],
  answers: MetricAnswer[],
  isChild = false,
  tags?: {
    tagType: string;
    tagValue: string;
  }[][],
  childrenMetrics?: string[]
) => {
  const isParentAndChild = metric.childrenMetrics?.length && metric.parentMetrics?.length;
  const hasTags = tags?.length ?? 0 > 0;

  if (isParentAndChild) {
    return answers.filter((answer) => childrenMetrics?.includes(answer.metricRef));
  }
  if (isChild || hasTags) {
    return answers.filter((answer) => answer.metricRef === metric.reference);
  }
  return answers;
};

export const getAggregatedDatapoints = ({
  metric,
  answers,
  isChild = false,
  tags,
  childrenMetrics,
  filterAnswers = true,
}: {
  metric: AggregatedMetricsTableData['metric'];
  answers: MetricAnswer[];
  isChild?: boolean;
  tags?: {
    tagType: string;
    tagValue: string;
  }[][];
  childrenMetrics?: string[];
  filterAnswers?: boolean;
}): YearObject => {
  if (answers) {
    const chosenAnswers = filterAnswers
      ? getRequiredAnswers(metric, answers, isChild, tags, childrenMetrics)
      : answers;

    const datapoints = chosenAnswers?.flatMap((answer) => {
      const isAnswerYearly = answer?.frequency === FrequencyEnums.yearly;
      if (answer?.hasTags)
        return answer?.datapoints
          ?.filter((datapoint) => combinedFiltersForTags(datapoint, isAnswerYearly, tags ?? []))
          .map((dp) => ({ ...dp, metricRef: answer?.metricRef ?? '' }));

      return answer?.datapoints
        ?.filter(
          (datapoint) =>
            combinedFiltersForNoTags(datapoint, isAnswerYearly) &&
            !answer.metric.childrenMetrics?.length
        )
        .map((dp) => ({ ...dp, metricRef: answer?.metricRef ?? '' }));
    });

    const isMetricYearly = metric.materialMetrics?.[0]?.frequency === FrequencyEnums.yearly;

    if (isMetricYearly) {
      const datapointsPerYear = datapoints.map((dp) => ({
        value: Number(dp.value ?? 0),
        metricRef: dp.metricRef,
        tagValues: dp.datapointTags.map((tag) => tag.tagValue),
      }));
      const total = resolveMetricCalculation(datapointsPerYear, metric.calculation);

      return { Q1: 0, Q2: 0, Q3: 0, Q4: 0, Year: total };
    }

    // Quarterly case
    const totalPerTimeframe = QUARTERS_FIELDS.map((field) => {
      const datapointsPerTimeframe = datapoints
        ?.filter(
          (datapoint) =>
            datapoint?.timeframe === field || datapoint?.timeframe === TimePeriodsEnums.year
        )
        .map((dp) => ({
          value: Number(dp.value ?? 0),
          metricRef: dp.metricRef,
          tagValues: dp.datapointTags.map((tag) => tag.tagValue),
        }));
      const total = resolveMetricCalculation(datapointsPerTimeframe, metric.calculation);

      return { [field]: total };
    });

    const quarters: QuartersObject = Object.assign({}, ...totalPerTimeframe);
    return { Year: getTotal(quarters), ...quarters };
  }
  // No datapoints case
  return { Q1: 0, Q2: 0, Q3: 0, Q4: 0, Year: 0 };
};
const getNestedData = (
  metricRow: AggregatedMetricsTableData,
  answers: MetricAnswer[]
): AggregatedMetricsTableData => {
  if (!!metricRow?.subRows?.length) {
    return {
      ...metricRow,
      subRows: metricRow.subRows.map((subRow) => {
        if (subRow.tags?.length) {
          return {
            ...subRow,
            result: getAggregatedDatapoints({
              metric: subRow.metric,
              answers,
              isChild: subRow?.isChild,
              tags: [subRow.tags],
            }),
          };
        } else if (!!subRow?.tagType) {
          return getNestedData(
            {
              ...subRow,
              result: getAggregatedDatapoints({
                metric: subRow.metric,
                answers,
                isChild: subRow?.isChild,
                tags: subRow.subRows?.map((row) => row?.tags ?? []),
              }),
            },
            answers
          );
        } else {
          return getNestedData(
            {
              ...subRow,
              result: getAggregatedDatapoints({
                metric: subRow.metric,
                answers,
                isChild: true,
                tags: subRow?.subRows?.map((row) => row?.tags ?? []),
                childrenMetrics: subRow.metric?.childrenMetrics?.length
                  ? getMetricWithChildrenRefs(subRow.metric)
                  : [],
              }),
            },
            answers
          );
        }
      }),
      result:
        metricRow?.result ??
        getAggregatedDatapoints({ metric: metricRow.metric, answers, isChild: metricRow.isChild }),
    };
  } else {
    return {
      ...metricRow,
      result:
        metricRow.result ??
        getAggregatedDatapoints({
          metric: metricRow.metric,
          answers,
          isChild: metricRow.isChild,
          tags: metricRow?.tags?.length ? [metricRow?.tags] : undefined,
        }),
    };
  }
};
export const getAggregatedMetricAnswers = (
  metric: AggregatedMetricsTableData,
  answers?: MetricAnswer[]
) => {
  if (answers) {
    return getNestedData(metric, answers);
  }
  return metric;
};

export const useGetAggregatedMetrics = (
  esrsAssessmentId: string,
  metrics: NonNullable<GetMetricsDrQuery_['DisclosureRequirement_by_pk']>['metrics'],
  standardRef: string,
  companyStandardId: string
) => {
  const { companyType, loading: loadingType } = useCompanyType();
  const isGroupCompany = useMemo(() => companyType === 'group-company', [companyType]);

  const { data: companyMetricAnswersData, loading: loadingCompany } =
    useGetAnswersForMetricsOnCompanyLevelQuery({
      variables: { esrsAssessmentId },
    });
  const { data: groupMetricAnswersData, loading: loadingGroup } =
    useGetAnswersForMetricsOnGroupLevelQuery({
      variables: { esrsAssessmentId },
    });

  const filteredMetrics = useMemo(
    () =>
      metrics.filter(
        (metric) =>
          !(
            metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
              ?.isMaterial &&
            metric.parentMetrics.some((p) => metrics.some((m) => m.reference === p.parentMetricRef))
          )
      ),
    [metrics]
  );
  const nestedMetrics = useMemo(
    () =>
      filteredMetrics.map((metric) => {
        return getNestedRows(metric, companyStandardId);
      }),
    [filteredMetrics]
  );

  const aggregatedMetrics = useMemo(() => {
    if (isGroupCompany) {
      const groupMetricAnswers = nestedMetrics?.map(({ metric }) => {
        const metricAndChildrenRefs = getMetricWithChildrenRefs(metric);
        return {
          [metric.reference]:
            groupMetricAnswersData?.EsrsAssessment_by_pk?.subsidiaryAssessments.flatMap(
              (subsidiary) => {
                const allMaterialMetrics = subsidiary.materialStandards
                  .find((standard) => standard.standard.reference === standardRef)
                  ?.materialMetrics.filter((m) =>
                    metricAndChildrenRefs.includes(m.metric.reference)
                  );
                return subsidiary.reportingUnits?.flatMap((ru) => {
                  return getMetricAnswers(
                    ru,
                    true,
                    getFullMetricWithChildren(metric),
                    metric.materialMetrics.find(
                      (mm) => mm.materialStandardId === companyStandardId
                    ),
                    allMaterialMetrics,
                    subsidiary,
                    standardRef
                  );
                });
              }
            ),
        };
      });

      const results = nestedMetrics.map((m) =>
        getAggregatedMetricAnswers(
          m,
          groupMetricAnswers.find((answer) => !!answer?.[m.metric.reference])?.[m.metric.reference]
        )
      );
      return results;
    } else {
      const companyMetricAnswers = nestedMetrics?.map(({ metric }) => {
        const isCompanyLevel =
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
            ?.dataCollection === COMPANY;
        const reportingUnits =
          companyMetricAnswersData?.EsrsAssessment_by_pk?.reportingUnits.filter((ru) =>
            isCompanyLevel ? ru.isCompanyLevel : !ru.isCompanyLevel
          ) ?? [];
        const allMaterialMetrics = getFullMetricWithChildren(metric)
          .map((m) => m.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId))
          .filter((mm): mm is MaterialMetricsFieldsFragment_ => mm !== undefined);
        return {
          [metric.reference]: reportingUnits.flatMap((ru) =>
            getMetricAnswers(
              ru,
              false,
              getFullMetricWithChildren(metric),
              metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
              allMaterialMetrics
            )
          ),
        };
      });

      const results = nestedMetrics.map((m) =>
        getAggregatedMetricAnswers(
          m,
          companyMetricAnswers.find((answer) => !!answer?.[m.metric.reference])?.[
            m.metric.reference
          ]
        )
      );
      return results;
    }
  }, [companyMetricAnswersData, groupMetricAnswersData, isGroupCompany, nestedMetrics]);
  return {
    aggregatedMetrics,
    loading: loadingCompany || loadingGroup || loadingType,
  };
};

export const getGraphObject = (object: AggregatedMetricsTableData) => {
  const isYearly = object.metric.materialMetrics[0]?.frequency === FrequencyEnums.yearly;
  if (isYearly) {
    return object.result ?? ({} as QuartersObject);
  } else {
    const result: QuartersObject & { Year?: number } = object.result ?? ({} as QuartersObject);
    delete result.Year;
    return result;
  }
};

export const getNestedMetrics = (metrics: AggregatedMetricsTableData[]) => {
  const allMetrics: AggregatedMetricsTableData[] = metrics;

  const recurseChildren = (metric: AggregatedMetricsTableData) => {
    metric?.subRows?.forEach((subRow) => {
      if (!subRow.tagName && !subRow.tags) {
        allMetrics.push(subRow);
        recurseChildren(subRow);
      }
    });
  };

  allMetrics.forEach((metric) => recurseChildren(metric));

  return allMetrics;
};
