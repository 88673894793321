import {
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  WidthType,
  VerticalAlign,
  TextRun,
  LevelFormat,
  AlignmentType,
  convertInchesToTwip,
} from 'docx';
import { AggregatedMetricsTableData } from '../../DisclosureRequirements';
import {
  DocumentInput,
  EsrsReportCategory,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
} from '../Report.types';
import { styles, tableBorders, TextStyle } from './reportThemes';

export class EsrsDocumentCreator {
  public async create(input: DocumentInput): Promise<Document> {
    const document = new Document({
      creator: 'Celsia.io',
      revision: 1,
      subject: 'Esrs report',
      title: `${input.data.companyName} - ESRS report ${input.data.reportingYear}`,
      styles: styles,
      numbering: {
        config: [
          {
            reference: 'number-system',
            levels: [
              {
                level: 0,
                format: LevelFormat.UPPER_ROMAN,
                text: '%1.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.25), hanging: convertInchesToTwip(0.25) },
                  },
                },
              },
            ],
          },
        ],
      },
      sections: [
        {
          children: this.createSections({
            categories: input.data.categories,
            companyName: input.data.companyName,
            reportingYear: input.data.reportingYear,
          }),
        },
      ],
    });

    return document;
  }

  public createSections({
    companyName,
    reportingYear,
    categories,
  }: {
    companyName: string;
    reportingYear: number;
    categories: EsrsReportCategory[];
  }) {
    return [
      new Paragraph({
        text: `ESRS sustainability statement for ${companyName} ${reportingYear}`,
        style: TextStyle.d3,
        spacing: {
          after: 400,
        },
      }),
      this.createCategories(categories ?? []),
    ].flat();
  }

  public createCategories(categories: EsrsReportCategory[]) {
    const visibleCategories = categories.filter((category) =>
      category.standards.some((standard) =>
        standard.disclosureRequirements.some(
          (dr) => !dr.isHidden && dr.metrics.some((metric) => metric.completed)
        )
      )
    );
    const displayedCategories = visibleCategories.flatMap((category) => {
      return [
        new Paragraph({
          text: category.title,
          style: TextStyle.h1,
          spacing: {
            after: 300,
          },
          numbering: {
            level: 0,
            reference: 'number-system',
          },
        }),
        this.createStandards(category.standards),
      ];
    });
    return displayedCategories.flat();
  }

  public createStandards(standards: EsrsReportStandard[]) {
    const visibleStandards = standards.filter(
      (standard) =>
        !standard.disclosureRequirements.every(
          (dr) =>
            dr.isHidden || dr.metrics.every((metric) => !metric.completed || metric.metric.isHidden)
        )
    );
    const displayedStandards = visibleStandards.flatMap((standard) => {
      return [
        new Paragraph({
          text: `${standard.reference} ${standard.title}`,
          style: TextStyle.h2,
          spacing: {
            after: 200,
          },
        }),
        this.createDisclosures(standard.disclosureRequirements),
      ];
    });
    return displayedStandards.flat();
  }

  public createDisclosures(disclosureRequirements: EsrsReportDisclosureRequirement[]) {
    const visibleDisclosureRequirements = disclosureRequirements.filter(
      (dr) => !dr.metrics.every((metric) => !metric.completed || metric.metric.isHidden)
    );
    const displayedDisclosureRequirements = visibleDisclosureRequirements.flatMap((dr) => {
      return [
        new Paragraph({
          text: `Disclosure Requirement ${dr.reference} – ${dr.title}`,
          style: TextStyle.h3,
          spacing: {
            after: 150,
          },
        }),
        this.createMetrics(dr.metrics),
      ];
    });
    return displayedDisclosureRequirements.flat();
  }

  public createMetrics(metrics: EsrsReportMetric[]) {
    const visibleMetrics = metrics.filter((metric) => !metric.metric.isHidden && metric.completed);
    const displayedMetrics = visibleMetrics.flatMap((metric) => {
      const metricInfo = metric.metric.metric;
      return [
        new Paragraph({
          text: metricInfo.shortTitle ?? metricInfo.title,
          style: TextStyle.bodyLarge,
          spacing: {
            after: 100,
          },
        }),
        this.createMetricTable(metric),
        new Paragraph({
          text: '',
          spacing: {
            after: 250,
          },
        }),
      ];
    });
    return displayedMetrics.flat();
  }

  public createMetricTable(metric: EsrsReportMetric) {
    const qualitativeMetricTextAnswer = new Paragraph({
      text: metric.textAnswer ?? '',
      style: TextStyle.body,
      spacing: {
        afterAutoSpacing: true,
      },
    });

    const quantitativeMetricTable = new Table({
      columnWidths: [4000, 2000, 2000],
      margins: {
        top: 60,
        bottom: 60,
        right: 60,
        left: 60,
      },
      width: {
        size: '100%',
        type: WidthType.PERCENTAGE,
      },
      borders: tableBorders,
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '60%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Metric',
                  style: TextStyle.bodyStrong,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '20%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Unit',
                  style: TextStyle.bodyStrong,
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: '20%',
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: 'Total',
                  style: TextStyle.bodyStrong,
                }),
              ],
            }),
          ],
        }),
        this.createDataPointRows(metric),
      ].flat(),
    });

    if (metric.metric.metric.metricType === 'LONG_TEXT') return qualitativeMetricTextAnswer;

    return quantitativeMetricTable;
  }

  public createDataPointRows(metric: EsrsReportMetric) {
    const metricResults = metric.tableData;
    const metricInfo = metric.metric.metric;

    const displayedRows = [
      new TableRow({
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: metricInfo.shortTitle ?? metricInfo.title,
                style: TextStyle.body,
                children: [
                  new TextRun({
                    text: metricResults.tagType ? ` by ${metricResults.tagType}` : '',
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text: metricInfo.unitOfMeasurement ?? 'NA',
                style: TextStyle.body,
              }),
            ],
          }),
          new TableCell({
            verticalAlign: VerticalAlign.TOP,
            children: [
              new Paragraph({
                text:
                  String(isNaN(metricResults.result?.Year ?? 0) ? 0 : metricResults.result?.Year) ??
                  '--',
                style: TextStyle.body,
              }),
            ],
          }),
        ],
      }),
      this.createNestedDataPointsRows(metricResults),
    ];
    return displayedRows.flat();
  }

  public createNestedDataPointsRows(metricResults: AggregatedMetricsTableData, depth = 1) {
    const subRows = metricResults.subRows;

    if (!subRows?.length) return [];

    const nestedRows: TableRow[] =
      subRows?.flatMap((row) => {
        const isTag = !!row.tags?.length;

        return [
          new TableRow({
            children: [
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    indent: {
                      start: depth * 220,
                    },
                    text: isTag
                      ? row.tags?.[0]?.tagValue
                      : `${row.metric.shortTitle ?? row.metric.title} by ${row.tagType}`,
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: row.metric.unitOfMeasurement ?? 'NA',
                    style: TextStyle.body,
                  }),
                ],
              }),
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  new Paragraph({
                    text: String(row.result?.Year ?? 0) ?? '--',
                    style: TextStyle.body,
                  }),
                ],
              }),
            ],
          }),
          ...(this.createNestedDataPointsRows(row, depth + 1) ?? []),
        ];
      }) ?? [];

    return nestedRows.flat();
  }
}
