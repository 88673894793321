import { ContentLayout, Table } from 'Molecules';
import { Typography } from 'Tokens';
import { GroupsQuery_, useGroupsQuery } from 'models';
import { useCompanyType, useCurrentCompanyId } from 'utils/hooks';
import { useMemo } from 'react';
import { HStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'utils/translation';
import { PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { ActionsCell, ShareAssessmentCell } from './GroupsListCells';

export const GroupsList = () => {
  const { companyId } = useCurrentCompanyId();
  const { t } = useTranslation('common');
  const { companyType } = useCompanyType();

  const { data, loading } = useGroupsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const groups = useMemo(() => data?.groups ?? [], [data]);

  const columns: ColumnDef<GroupsQuery_['groups'][number]>[] = useMemo(() => {
    return [
      {
        header: t('fields.userName.label'),
        id: 'group',
        accessorFn: (group) => group?.ownerGroupAssessment.company?.name ?? t('common:unknown'),
      },
      {
        header: t('group.groupName'),
        id: 'assessment',
        accessorFn: (group) => group?.ownerGroupAssessment.name ?? 'N/A',
      },
      {
        header: t('investors.yourAssessment'),
        id: 'sharedAssessment',
        cell: ({ row }) => (
          <ShareAssessmentCell
            key={'AssessmentCell-' + row.original.id}
            group={row.original}
            companyType={companyType}
          />
        ),
      },
      {
        header: t('assessment.sharing.shared'),
        id: 'updatedAt',
        cell: ({ row }) => {
          const isShared = row.original.sharedCompanyAssessmentId;
          return (
            <HStack color="text.muted">
              {isShared ? <PublicIcon color="inherit" /> : <PrivateIcon color="inherit" />}
              <Typography variant="bodyStrong" color="text.muted">
                {isShared ? t('assessment.sharing.shared') : t('assessment.sharing.notShared')}
              </Typography>
            </HStack>
          );
        },
      },
      {
        header: '',
        id: 'actions',
        cell: ({ row }) => (
          <ActionsCell
            key={'ActionCell-' + row.original.id}
            group={row.original}
            companyType={companyType}
          />
        ),
      },
    ];
  }, [groups, companyType]);

  return (
    <ContentLayout isLoading={loading} header={t('group.groups')}>
      <Table<GroupsQuery_['groups'][number]>
        data={groups}
        columns={columns}
        rowProps={{
          _hover: {
            bg: 'bg.hover',
          },
        }}
      />
    </ContentLayout>
  );
};
