import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Button, Checkbox, TruncatableText } from 'Atoms';
import { SearchInput } from 'Molecules';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { TagBreakdown } from './MetricConfigModalParent';

type ConfiguredTag = {
  tagType: string;
  tagValue: string;
};

type TagValuesSelectorProps = {
  options: ConfiguredTag[];
  configured: ConfiguredTag[];
  type: string;
  setConfigured: (newConfigured: ConfiguredTag[]) => void;
};

export const TagValuesSelector: React.FC<TagValuesSelectorProps> = ({
  options,
  configured,
  setConfigured,
  type,
}) => {
  const [tempChecked, setTempChecked] = useState<ConfiguredTag[]>(configured);
  const [menuOpen, setMenuOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [search, setSearch] = useState<string>('');
  const filteredOptions = useMemo(() => {
    return options.filter((option) => option.tagValue.toLowerCase().includes(search.toLowerCase()));
  }, [search]);

  useEffect(() => {
    if (menuOpen) {
      setTempChecked(configured);
    }
  }, [menuOpen, configured]);

  useEffect(() => {
    if (
      tempChecked.filter((tag) => filteredOptions.some((option) => option.tagType === tag.tagType))
        .length === filteredOptions.length
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [tempChecked, filteredOptions]);

  const handleSelectTag = (tag: ConfiguredTag) => {
    setTempChecked((prevChecked) => {
      const exists = prevChecked.some(
        (item) => item.tagType === tag.tagType && item.tagValue === tag.tagValue
      );
      return exists
        ? prevChecked.filter(
            (item) => !(item.tagType === tag.tagType && item.tagValue === tag.tagValue)
          )
        : [...prevChecked, tag];
    });
  };

  const handleConfirm = () => {
    setConfigured(tempChecked);
    setMenuOpen(false);
  };

  const selectAll = () => {
    if (allSelected) {
      setTempChecked([]);
      setAllSelected(false);
    } else {
      const allTags = filteredOptions.filter((tag) => {
        return !tempChecked.some(
          (item) => item.tagType === tag.tagType && item.tagValue === tag.tagValue
        );
      });
      setTempChecked([...tempChecked, ...allTags]);
      setAllSelected(true);
    }
  };

  return (
    <Menu
      size="lg"
      gutter={2}
      isOpen={menuOpen}
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
    >
      <MenuButton
        as={Button}
        width="150px"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        overflow="hidden"
        color="text.default"
      >
        <TruncatableText
          variant="body"
          text={
            tempChecked.filter((tag) => tag.tagType === type).length > 0
              ? tempChecked
                  .filter((tag) => tag.tagType === type)
                  .map((tag, index) =>
                    index ===
                    tempChecked.filter((checkedTag) => checkedTag.tagType === type).length - 1
                      ? tag.tagValue
                      : tag.tagValue + ', '
                  )
              : 'Select value'
          }
        />
      </MenuButton>
      <MenuList p="8px">
        <SearchInput
          width="100%"
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          marginBottom="4px"
        />
        <MenuItem p="8px" m="0px" w="100%" closeOnSelect={false} onClick={selectAll}>
          <HStack w="100%" key={`menu-selectall`} spacing="8px">
            <Checkbox pointerEvents="none" isChecked={allSelected} />
            <Typography variant="bodyStrong">Select all</Typography>
          </HStack>
        </MenuItem>
        <MenuDivider margin="5px 0px" />
        <Box maxH="200px" overflowY="auto">
          {filteredOptions.map((tag) => (
            <MenuItem
              p="8px"
              m="0px"
              w="100%"
              closeOnSelect={false}
              onClick={() => handleSelectTag(tag)}
            >
              <HStack w="100%" key={`menu-${tag.tagType}`} spacing="8px">
                <Checkbox
                  onChange={() => handleSelectTag(tag)}
                  isChecked={tempChecked.some(
                    (item) => item.tagType === tag.tagType && item.tagValue === tag.tagValue
                  )}
                />
                <Typography variant="bodyStrong">{tag.tagValue}</Typography>
              </HStack>
            </MenuItem>
          ))}
        </Box>
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <Box w="100%">
          <Button variant="primary" w="100%" onClick={handleConfirm}>
            Confirm
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};

export const TagSelectionMenu = ({
  options,
  checked,
  setChecked,
}: {
  options: TagBreakdown[];
  checked: TagBreakdown[];
  setChecked: Dispatch<SetStateAction<TagBreakdown[]>>;
}) => {
  const [tempChecked, setTempChecked] = useState<TagBreakdown[]>([...checked]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelectTag = (tag: TagBreakdown) => {
    setTempChecked((prev) =>
      prev.includes(tag) ? prev.filter((tagType) => tagType.type !== tag.type) : [...prev, tag]
    );
  };

  const handleConfirm = () => {
    setChecked(tempChecked);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    setTempChecked([...checked]);
  }, [checked]);

  return (
    <Menu
      size="lg"
      gutter={2}
      isOpen={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
    >
      <MenuButton
        as={Button}
        variant="ghost"
        leftIcon={<AddIcon color="inherit" />}
        color="text.default"
      >
        Add breakdown
      </MenuButton>
      <MenuList p="8px">
        <Typography p="8px" variant="detail">
          Add breakdown by
        </Typography>
        {options.map((tag) => {
          return (
            <MenuItem
              p="8px"
              m="0px"
              w="100%"
              closeOnSelect={false}
              onClick={() => handleSelectTag(tag)}
            >
              <HStack w="100%" key={`menu-${tag.type}`} spacing="8px" paddingX="8px">
                <Checkbox
                  onChange={() => handleSelectTag(tag)}
                  isChecked={tempChecked.includes(tag)}
                />
                <Typography variant="bodyStrong">{tag.type}</Typography>
              </HStack>
            </MenuItem>
          );
        })}
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <Box w="100%">
          <Button variant="primary" w="100%" onClick={handleConfirm}>
            Confirm
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};
