import { MaterialMetricsPerDisclosureQuery_ } from 'models';

export type MaterialMetricPerDisclosure =
  MaterialMetricsPerDisclosureQuery_['assessableMetrics'][number];
export type TableMetricData = MaterialMetricPerDisclosure & {
  isExpanded?: boolean;
  isChild?: boolean;
  subRows?: TableMetricData[];
  type: 'metric' | 'tag';
  parentMetric?: MaterialMetricPerDisclosure;
};

export type MaterialMetricTag = {
  tagType: string;
  isOptional: boolean;
  id: any;
  materialTagValues: {
    tagType: string;
    tagValue: string;
  }[];
};

export type MetricTag = {
  type: string;
  value: string;
  subRows?: TableMetricData[];
  subTags: MaterialMetricTag[];
  parentMetric?: MaterialMetricPerDisclosure;
};

export type TagStatus = {
  state: BreakdownStatus;
  number: number;
};

export enum BreakdownStatus {
  required = 'required',
  added = 'added',
  available = 'available',
  requested = 'requested',
  notApplicable = 'notApplicable',
}
