import { useEffect, useMemo, useState } from 'react';
import { ChevronDownIcon, CheckIcon } from '@chakra-ui/icons';
import { Center, Divider, HStack, Tag, VStack, Image } from '@chakra-ui/react';
import { Loader, Modal } from 'Molecules';
import { Menu } from 'Molecules/Menu';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { GroupsQuery_, InvestorsQuery_, useGroupAssessmentQuery } from 'models';
import { Button } from 'Atoms';
import { useShareCompanyAssessment, useUnshareCompanyAssessment } from 'containers/Investors';
import {
  useShareCompanyAssessmentWithGroup,
  useUnshareCompanyAssessmentWithGroup,
} from 'containers/Groups';
import { useToast } from 'utils/hooks';
import { groupOwnerContext } from 'containers/GroupAssessment/GroupAssessment.hooks';

interface AccessStatusType {
  relationId: string;
  requesterId: string;
  assessmentId: string | null;
}

const AccessRow = ({
  companyName,
  logo,
  type,
  name,
  setStatus,
  status,
  isShared,
  investor,
  group,
  consolidatedCompanyAssessmentId,
}: {
  companyName: string;
  logo?: string;
  type: 'investor' | 'group';
  name: string;
  setStatus: (param: AccessStatusType[]) => void;
  status: AccessStatusType[];
  isShared?: string | null;
  investor?: InvestorsQuery_['investors'][number];
  group?: GroupsQuery_['groups'][number];
  consolidatedCompanyAssessmentId?: string;
}) => {
  const { t } = useTranslation('common');

  return (
    <HStack justifyContent="space-between" width="100%" pr="4px">
      <HStack width="50%" justifyContent="space-between">
        <HStack>
          {logo ? (
            <Image src={logo} w="28px" h="28px" borderRadius="6px" />
          ) : (
            <Center w="28px" h="28px" borderRadius="6px" bg="bg.accent">
              <CompanyIcon boxSize="16px" color="white" />
            </Center>
          )}
          <VStack spacing="0px" alignItems="flex-start">
            <Typography variant="bodyStrong">{companyName ?? t('common:unknown')}</Typography>
            <Typography variant="detail">
              {type === 'investor' ? t('common:investors.investor') : 'Parent company'}
            </Typography>
          </VStack>
        </HStack>
        <HStack width="45%">
          <Tag borderRadius="6px" variant="outlined">
            {name ?? 'N/A'}
          </Tag>
        </HStack>
      </HStack>
      <HStack spacing="0px">
        <Menu
          size="lg"
          menuButton={
            <Button
              variant="ghost"
              rightIcon={<ChevronDownIcon color="inherit" />}
              _hover={{ bg: 'pink' }}
            >
              {isShared ? 'Can view live updates' : 'No access'}
            </Button>
          }
          sections={[
            {
              actions: [
                {
                  id: 'no access',
                  title: 'No access',
                  leftElement: <PrivateIcon color="text.muted" />,
                  description:
                    type === 'investor'
                      ? 'Portfolio owner can not see results of this report'
                      : 'Group owner can not see results of this report',
                  rightElement: isShared ? <></> : <CheckIcon color="text.muted" />,
                  onClick: () => {
                    const requester = investor
                      ? investor.portfolioId
                      : group?.ownerGroupAssessmentId;
                    setStatus([
                      ...status.filter((c) => c.requesterId !== requester),
                      {
                        relationId: investor ? investor.id : group?.id,
                        requesterId: requester,
                        assessmentId: null,
                      },
                    ]);
                  },
                },
                {
                  id: 'access',
                  title: 'Can view live updates',
                  leftElement: <PublicIcon color="text.muted" />,
                  description:
                    type === 'investor'
                      ? 'All updates will be shared with a portfolio automatically'
                      : 'All updates will be shared with a group automatically',
                  rightElement: isShared ? <CheckIcon color="text.muted" /> : <></>,
                  onClick: () => {
                    const requester = investor
                      ? investor.portfolioId
                      : group?.ownerGroupAssessmentId;
                    if (consolidatedCompanyAssessmentId) {
                      setStatus([
                        ...status.filter((c) => c.requesterId !== requester),
                        {
                          relationId: investor ? investor.id : group?.id,
                          requesterId: requester,
                          assessmentId: consolidatedCompanyAssessmentId,
                        },
                      ]);
                    }
                  },
                },
              ],
            },
          ]}
        />
      </HStack>
    </HStack>
  );
};

export const ShareGroupAssessmentModal = ({
  isOpen,
  onClose,
  investorsData,
  groupsData,
}: {
  isOpen: boolean;
  onClose: () => void;
  investorsData: InvestorsQuery_ | undefined;
  groupsData: GroupsQuery_ | undefined;
}) => {
  const toast = useToast();
  const { gAssessmentId } = useParams();
  const [portfolioStatus, setPortfolioStatus] = useState<AccessStatusType[]>([]);
  const [groupStatus, setGroupStatus] = useState<AccessStatusType[]>([]);

  const { shareCompanyAssessment: shareAssessmentWithPortfolio } = useShareCompanyAssessment();
  const { unshareCompanyAssessment: unshareAssessmentWithPortfolio } =
    useUnshareCompanyAssessment();
  const { shareCompanyAssessment: shareAssessmentWithGroup } = useShareCompanyAssessmentWithGroup();
  const { unshareCompanyAssessment: unshareAssessmentWithGroup } =
    useUnshareCompanyAssessmentWithGroup();

  const { data: gAssessmentData, loading: gAssessmentLoading } = useGroupAssessmentQuery({
    variables: {
      assessmentId: gAssessmentId,
    },
    skip: !gAssessmentId,
    context: groupOwnerContext,
  });

  const consolidatedCompanyAssessmentId: string | undefined = useMemo(() => {
    return gAssessmentData?.assessment?.consolidatedCompanyAssessmentId ?? undefined;
  }, [gAssessmentData]);

  const filterCompaniesByAssessmentId = (companies: any) =>
    companies?.filter(
      (company: any) =>
        company.sharedCompanyAssessmentId === consolidatedCompanyAssessmentId ||
        company.sharedCompanyAssessmentId === null
    ) ?? [];

  const groups: GroupsQuery_['groups'] = useMemo(
    () => filterCompaniesByAssessmentId(groupsData?.groups),
    [groupsData]
  );
  const investors: InvestorsQuery_['investors'] = useMemo(
    () => filterCompaniesByAssessmentId(investorsData?.investors),
    [investorsData]
  );

  useEffect(() => {
    setPortfolioStatus(
      investors.map(({ id, portfolioId, sharedCompanyAssessmentId }) => ({
        relationId: id,
        requesterId: portfolioId,
        assessmentId: sharedCompanyAssessmentId,
      }))
    );
    setGroupStatus(
      groups.map(({ id, ownerGroupAssessmentId, sharedCompanyAssessmentId }) => ({
        relationId: id,
        requesterId: ownerGroupAssessmentId,
        assessmentId: sharedCompanyAssessmentId,
      }))
    );
  }, []);

  const handleSaveChanges = async (
    updatedPortfolioList: AccessStatusType[],
    updatedGroupList: AccessStatusType[]
  ) => {
    const changed = updatedPortfolioList.filter(
      ({ requesterId, relationId, assessmentId }) =>
        !investors.find(
          (investor) =>
            investor.portfolioId === requesterId &&
            investor.id === relationId &&
            investor.sharedCompanyAssessmentId === assessmentId
        )
    );

    const changedGroup = updatedGroupList.filter(
      ({ requesterId, relationId, assessmentId }) =>
        !groups.find(
          (group) =>
            group.ownerGroupAssessmentId === requesterId &&
            group.id === relationId &&
            group.sharedCompanyAssessmentId === assessmentId
        )
    );

    const pShare = changed.filter(({ assessmentId }) => assessmentId !== null);
    const pUnshare = changed.filter(({ assessmentId }) => assessmentId === null);
    const gShare = changedGroup.filter(({ assessmentId }) => assessmentId !== null);
    const gUnshare = changedGroup.filter(({ assessmentId }) => assessmentId === null);

    const pShared = await Promise.all(
      pShare.map(({ requesterId, relationId, assessmentId }) =>
        shareAssessmentWithPortfolio(assessmentId ?? '', relationId, requesterId)
          .then((res) => {
            return !!res;
          })
          .catch(() => {
            return false;
          })
      )
    );
    const pUnshared = await Promise.all(
      pUnshare.map(({ requesterId, relationId }) =>
        unshareAssessmentWithPortfolio(relationId, requesterId)
          .then((res) => {
            return !!res;
          })
          .catch(() => {
            return false;
          })
      )
    );
    const gShared = await Promise.all(
      gShare.map(({ requesterId, relationId, assessmentId }) =>
        shareAssessmentWithGroup(relationId, requesterId, assessmentId ?? '')
          .then((res) => {
            return !!res;
          })
          .catch(() => {
            return false;
          })
      )
    );
    const gUnshared = await Promise.all(
      gUnshare.map(({ requesterId, relationId }) =>
        unshareAssessmentWithGroup(relationId, requesterId)
          .then((res) => {
            return !!res;
          })
          .catch(() => {
            return false;
          })
      )
    ).then((res) => {
      onClose();
      return res;
    });
    const results = [...pShared, ...pUnshared, ...gShared, ...gUnshared];
    const errors = results.filter((r) => r === false);
    if (errors.length) {
      toast({
        text: `${errors.length} out of ${results.length} change${
          errors.length > 1 ? 's' : ''
        } failed`,
        variant: 'danger',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => handleSaveChanges(portfolioStatus, groupStatus)}
      size="md"
      title="Share access"
    >
      {gAssessmentLoading ? (
        <Loader />
      ) : (
        <VStack>
          {investors.map((investor, index) => {
            const isShared = portfolioStatus.find(
              (portfolio) => portfolio.requesterId === investor.portfolioId
            )?.assessmentId;
            return (
              <VStack w="100%" h="100%" key={index}>
                <AccessRow
                  companyName={investor?.portfolio?.ownerCompany?.name}
                  logo={investor?.portfolio?.ownerCompany?.logoUrl ?? ''}
                  name={investor?.portfolio?.name}
                  type="investor"
                  isShared={isShared}
                  status={portfolioStatus}
                  setStatus={setPortfolioStatus}
                  investor={investor}
                  consolidatedCompanyAssessmentId={consolidatedCompanyAssessmentId}
                />
                {(index !== investors.length - 1 || groups.length > 0) && (
                  <Divider border="1px" borderColor="border.decorative" orientation="horizontal" />
                )}
              </VStack>
            );
          })}
          {groups.map((group, index) => {
            const isShared = groupStatus.find(
              (company) => company.requesterId === group.ownerGroupAssessmentId
            )?.assessmentId;
            return (
              <VStack w="100%" h="100%" key={index}>
                <AccessRow
                  companyName={group.ownerGroupAssessment.company?.name ?? ''}
                  logo={group.ownerGroupAssessment.company?.logoUrl ?? ''}
                  name={group.ownerGroupAssessment.name}
                  type="group"
                  isShared={isShared}
                  status={groupStatus}
                  setStatus={setGroupStatus}
                  group={group}
                  consolidatedCompanyAssessmentId={consolidatedCompanyAssessmentId}
                />
                {index !== groups.length - 1 && (
                  <Divider border="1px" borderColor="border.decorative" orientation="horizontal" />
                )}
              </VStack>
            );
          })}
        </VStack>
      )}
    </Modal>
  );
};
