import { useTranslation } from 'react-i18next';
import {
  EsrsReportCategory,
  EsrsReportData,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
} from './Report.types';
import { useParams } from 'react-router-dom';
import { useEsrsAssessmentQuery } from 'models';
import { Box, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { toRomanNumeral } from './ReportUtils';
import { useCurrentCompany } from 'utils/hooks';
import { ReportMetricTable } from './ReportMetricTable';
import { useMemo } from 'react';
import { QuestionType } from 'utils/scores/questions';

const MetricBody = ({ metric, standardId }: { metric: EsrsReportMetric; standardId: string }) => {
  const metricDetails = metric.metric;
  const company = useCurrentCompany();
  return (
    <VStack alignItems="start" w="100%" spacing="4px" pb="8px">
      <Typography variant="h4">{metricDetails.metric.title}</Typography>
      {metric.textAnswer ||
      (metric.tableData.result?.Year &&
        metric.metric.metric.metricType !== QuestionType.LongText_) ? (
        metric.textAnswer ? (
          <Typography variant="body">{metric.textAnswer}</Typography>
        ) : (
          <ReportMetricTable
            metrics={[metric.tableData]}
            currency={company.company?.currency ?? ''}
            standardId={standardId}
          />
        )
      ) : (
        <Typography variant="body" color="text.hint">
          No answer has been provided yet
        </Typography>
      )}
    </VStack>
  );
};

const DrBody = ({
  disclosure,
  standardId,
}: {
  disclosure: EsrsReportDisclosureRequirement;
  standardId: string;
}) => {
  const filteredMetrics = useMemo(() => {
    return disclosure.metrics.filter((metric: EsrsReportMetric) => !metric.metric.isHidden);
  }, [disclosure]);

  return (
    <VStack alignItems="start" w="100%" gap="20px" id={`${disclosure.reference}-Preview`}>
      <Typography variant="h3">{disclosure.title}</Typography>
      {filteredMetrics.map((metric: EsrsReportMetric) => (
        <MetricBody metric={metric} standardId={standardId} />
      ))}
    </VStack>
  );
};

const StandardBody = ({ standard }: { standard: EsrsReportStandard }) => {
  const filteredDrs = useMemo(() => {
    return standard.disclosureRequirements.filter(
      (dr) => !dr.metrics.every((metric: EsrsReportMetric) => metric.metric.isHidden)
    );
  }, [standard]);

  return (
    <VStack alignItems="start" w="100%" gap="20px" id={`${standard.reference}-Preview`}>
      <Typography variant="h2">{standard.title}</Typography>
      {filteredDrs.map((dr) => (
        <DrBody disclosure={dr} standardId={standard.id} />
      ))}
    </VStack>
  );
};

const CategoryBody = ({ category, index }: { category: EsrsReportCategory; index: number }) => {
  const filteredStandards = useMemo(() => {
    return category.standards.filter(
      (std) =>
        !std.disclosureRequirements.every(
          (dr) =>
            dr.isHidden || dr.metrics.every((metric: EsrsReportMetric) => metric.metric.isHidden)
        )
    );
  }, [category]);
  return (
    <VStack alignItems="start" w="100%" gap="24px" id={`${category.reference}-Preview`}>
      <Typography variant="h1">{toRomanNumeral(index + 1) + '. ' + category.title}</Typography>
      {filteredStandards.map((std) => (
        <StandardBody standard={std} />
      ))}
    </VStack>
  );
};

export const ReportPreview = ({ data }: { data: EsrsReportData }) => {
  const { t } = useTranslation('esrs');
  const { esrsAssessmentId } = useParams();
  const { data: assessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const filteredCategories = useMemo(() => {
    return data.categories.filter((cat) =>
      cat.standards.some((std) => std.disclosureRequirements.some((dr) => !dr.isHidden))
    );
  }, [data]);

  return (
    <Box
      overflowY="scroll"
      css={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
        scrollbarWidth: 'none',
      }}
      padding="2px 10px"
      height="calc(100vh - 128px)"
    >
      <VStack
        maxW="1088px"
        p="72px 48px"
        border="1px solid"
        borderColor="border.decorative"
        boxShadow="0px 4px 6px 5px rgba(0, 0, 0, 0.05)"
        alignItems="start"
        borderRadius="12px"
      >
        <VStack maxW="800px" gap="32px" alignItems="start">
          <Typography variant="d3" whiteSpace="normal">
            {t('assessment.report.reportTitle') +
              ' for ' +
              assessment?.esrsAssessment?.company.name +
              ' ' +
              assessment?.esrsAssessment?.reportingYear}
          </Typography>

          <VStack alignItems="start" w="100%" gap="32px">
            {filteredCategories.map((cat, index) => (
              <CategoryBody category={cat} index={index} />
            ))}
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
