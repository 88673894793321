export type ObjectiveType = string;
export type ObjectiveId =
  | 'adaptation'
  | 'biodiversity'
  | 'circular'
  | 'general'
  | 'mitigation'
  | 'pollution'
  | 'social'
  | 'water';

export const OBJECTIVE_INDEX_MAP: Record<ObjectiveId | ObjectiveType, number> = {
  general: 0,
  social: 1,
  mitigation: 2,
  adaptation: 3,
  water: 4,
  circular: 5,
  pollution: 6,
  biodiversity: 7,
};

export const getObjectiveIndex = (key: ObjectiveType) => OBJECTIVE_INDEX_MAP[key] ?? 100;

type ObjectiveWithKey = { key: ObjectiveId | string };

export const sortObjectives = (a: ObjectiveWithKey, b: ObjectiveWithKey) =>
  getObjectiveIndex(a.key) - getObjectiveIndex(b.key);
