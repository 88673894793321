export const colors: { [key: string]: { [key: string]: string } } = {
  text: {
    default: '#000000E5',
    muted: '#000000B2',
    hint: '#00000066',
    onAccent: '#FFFFFF',
    selected: '#0D2DDF',
    disabled: '#A6A6A6',
    success: '#096731',
    warning: '#924200',
    critical: '#AA2424',
    brand: '#0D2DDF',
    compliant: '#0B6645',
    notCompliant: '#B50021',
    info: '#3D3DCD',
    action: '#2424FF',
    'info.accent': '#8787FF',
    'status.critical': '#A8361C',
  },
  bg: {
    default: '#FFFFFF',
    muted: '#F8F8F8',
    hover: '#E6E6ED66',
    pressed: '#E6E6ED99',
    selected: '#B1D0FF33',
    'selected.hover': '#B1D0FF4D',
    'selected.pressed': '#B1D0FF80',
    'selected.accent': '#0D2DDF',
    'selected.muted': '#F3F3F3',
    disabled: '#F5F5F5',
    'disabled.accent': '#DEDEDE',
    accent: '#1C1C57',
    base: '#E9E9E9',
    'base.progress': '#5D5D7E',
    backdrop: '#212121A3',
    'brand.accent': '#3856FC',
    'brand.accent.hover': '#0D2DDF',
    'brand.accent.pressed': '#0A28D0',
    drop: '#B1D0FF99',
    success: '#1483431A',
    'success.accent': '#148343',
    warning: '#FFF3E0',
    'warning.accent': '#EF6C00',
    critical: '#D32F2F14',
    'critical.accent': '#D32F2F',
    unknown: '#0000000F',
    'unknown.accent': '#AAAAAA',
    'unknown.muted': '#00000008',
    compliant: '#16CF8D33',
    'compliant.accent': '#16CF8D',
    notCompliant: '#FFDDE3',
    'notCompliant.accent': '#FF002E',
    'decorative.brandState2': '#5D5D7E',
    'decorative.brandState3': '#9292AF',
    interactive: '#E6E6ED66',
    'interactive.accent': '#12122B0F',
    progress: '#5D5D7E',
    info: '#8787FF1F',
    'info.muted': '#8787FF14',
  },
  border: {
    default: '#B9BCBF',
    hover: '#A7ADBA',
    'selected.accent': '#0D2DDF',
    disabled: '#F5F5F5',
    'critical.accent': '#D32F2F',
    decorative: '#E1E1E6',
    onAccent: '#FFFFFF33',
    drop: '#B1D0FF',
  },
};
