import { Button, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { ContentLayout, Select } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { InviteMembersModal } from './pieces';
import { useMemo, useState } from 'react';
import { useTeamMembers, TeamMembersFilterOption } from './TeamMembers.hooks';
import { TeamMembersTable } from './pieces/TeamMembersTable';
import { Typography } from 'Tokens';
import { useCurrentCompany } from 'utils/hooks';

const TeamMembersHeader = ({
  filterBy,
  setFilterBy,
  openInviteMembersModal,
}: {
  filterBy: TeamMembersFilterOption;
  setFilterBy: (filterOption: TeamMembersFilterOption) => void;
  openInviteMembersModal: () => void;
}) => {
  const { t } = useTranslation('teamMembers');

  const filterOptions = useMemo(
    () =>
      Object.values(TeamMembersFilterOption).map((option) => ({
        value: option,
        label: t(`filterOptions.${option}`),
      })),
    []
  );

  return (
    <HStack width="100%" justifyContent="space-between" paddingY="16px">
      <HStack width="200px">
        <Select<{ value: TeamMembersFilterOption; label: string }>
          options={filterOptions}
          value={filterOptions.find((option) => option.value === filterBy)}
          onChange={(option) => setFilterBy(option?.value ?? TeamMembersFilterOption.All)}
        />
      </HStack>
      <Button variant="primary" onClick={openInviteMembersModal}>
        {t('inviteMembersButton')}
      </Button>
    </HStack>
  );
};

export const TeamMembers = () => {
  const { t } = useTranslation('common');
  const { company } = useCurrentCompany();

  const [filterBy, setFilterBy] = useState<TeamMembersFilterOption>(TeamMembersFilterOption.All);
  const { members, loading } = useTeamMembers(filterBy);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ContentLayout
      header={
        <VStack
          alignItems="start"
          spacing="0"
          paddingTop="12px"
          paddingBottom="10px"
          paddingInline="16px"
        >
          <Typography variant="h1">{t('common:menu.teamMembers')}</Typography>
          <Typography variant="body" color="text.muted">
            Manage users of {company?.name ?? 'Group company'}
          </Typography>
        </VStack>
      }
      isLoading={loading}
    >
      <TeamMembersHeader
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        openInviteMembersModal={onOpen}
      />
      <TeamMembersTable members={members} setFilterBy={setFilterBy} />
      <InviteMembersModal isOpen={isOpen} onClose={onClose} />
    </ContentLayout>
  );
};
