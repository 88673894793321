import { Box, Grid, GridItem, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { LearnMoreDrawer, Loader, SearchInput } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import { useGetFilteredMetrics, useGetTargetsFormData } from './Targets.hooks';
import { DisclosureRequirementTitle } from '../DisclosureRequirementsUtilsComponents';
import { Button, EmptyState, Infobox, Tag } from 'Atoms';
import { SELECTED_MILESTONES_ID } from '../Requirement';
import { AggregatedMetrics, TableViewSwitcher } from '../Metrics';
import { TargetsTable } from './TargetsTable';
import { AddActionModal } from '../Actions/AddActionModal';
import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { TargetsGraphCard } from './TargetsGraphCard';
import { TargetIcon } from 'Tokens/Icons/Data';
import { useUserSetting } from 'containers/Navigation';
import { useLocalStorage } from 'usehooks-ts';
import { AddIcon } from 'Tokens/Icons/Function';
import { Typography } from 'Tokens';

export enum ViewTabs {
  all = 'All',
  currentCompany = 'Your company',
  parentCompany = 'Parent company',
  subsidiaries = 'Subsidiaries',
}

export const Targets = () => {
  const navigate = useNavigate();
  const { companyType, loading: typeLoading } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);
  const [hasClosedInfo, setHasClosedInfo] = useUserSetting('esrs-has-closed-targets-info', false);
  const {
    onOpen: onOpenActionModal,
    isOpen: isOpenActionModal,
    onClose: onCloseActionModal,
  } = useDisclosure();
  const [searchValue, setSearchValue] = useState('');
  const [selectedView, setSelectedView] = useState('All');
  const [isChartsView, setIsChartsView] = useLocalStorage('isChartView', false);
  const { reportingUnitAssessments: reportingUnits } = useAssessmentReportingUnits(
    esrsAssessmentId ?? ''
  );
  const [selectedActionId, setAction] = useState<number>();
  const [showAll, setShowAll] = useState(false);

  const { targetDR, combinedData, actions, loading } = useGetTargetsFormData();

  const filteredMetrics = useGetFilteredMetrics(disclosureRequirementRef, esrsAssessmentId);

  const [filteredData, shouldShowButton] = useMemo(() => {
    const targetData = combinedData
      ?.filter((data) => {
        if (selectedView === 'All') return true;
        else if (selectedView === 'Your company') return !data.isSubOrParent;
        return data.isSubOrParent;
      })
      .filter((data) =>
        searchValue ? data.title.toLocaleLowerCase().search(searchValue) !== -1 : true
      );
    const shouldShow = (targetData?.length ?? 0) > 3;
    return [showAll ? targetData : targetData.slice(0, 3), shouldShow];
  }, [searchValue, selectedView, combinedData, showAll]);

  const { title = '', reference = '', description = '' } = targetDR || {};

  useEffect(() => localStorage.removeItem(SELECTED_MILESTONES_ID), []);

  const showInfobox = useMemo(
    () => !hasClosedInfo && !isGroup && filteredData?.some((targ) => targ.isSubOrParent),
    [hasClosedInfo, isGroup, filteredData]
  );

  if (loading || typeLoading) {
    return <Loader />;
  }

  return (
    <VStack spacing="20px" alignItems="start" w="100%">
      <DisclosureRequirementTitle title="Targets" reference={targetDR?.reference} onOpen={onOpen} />
      <VStack spacing="40px" alignItems="start" w="100%">
        <VStack alignItems="start" spacing="6px" w="100%">
          <Typography variant="h3">Manage targets</Typography>
          {!combinedData.length ? (
            <EmptyState
              description="Empower your sustainability journey by setting targets with defined owners and linked action plans"
              icon={<TargetIcon boxSize="20px" color="text.hint" />}
              variant="white"
              component={true}
              callToAction={{
                text: 'Add target',
                variant: 'ghost',
                leftIcon: <AddIcon />,
                onClick: () => {
                  navigate(`target-form`);
                },
              }}
            />
          ) : (
            <VStack spacing="16px" alignItems="start" w="100%">
              <VStack spacing="12px" alignItems="start" w="100%">
                {showInfobox && (
                  <Box w="100%">
                    <Infobox
                      status="info"
                      titleProps={{ variant: 'h3' }}
                      icon={<TargetIcon color="text.info" />}
                      title="Group company targets"
                      onClose={() => setHasClosedInfo(true)}
                      description="Review targets set by the parent company. You can also add your own targets. "
                    />
                  </Box>
                )}
                <HStack justifyContent="space-between" w="100%">
                  <HStack>
                    <SearchInput
                      search={searchValue}
                      setSearch={setSearchValue}
                      placeholder="Filter"
                      withLeftIcon={false}
                      minW="200px"
                    />
                    {Object.values(ViewTabs)
                      .filter((tab) =>
                        isGroup ? tab !== ViewTabs.parentCompany : tab !== ViewTabs.subsidiaries
                      )
                      .map((tab) => (
                        <Tag
                          variant={selectedView === tab ? 'info' : 'button'}
                          size="md"
                          height="36px"
                          title={tab}
                          borderRadius="8px"
                          p="16px"
                          clickable={true}
                          onClick={() => setSelectedView(tab)}
                        />
                      ))}
                  </HStack>
                  <HStack>
                    <TableViewSwitcher
                      isChartsView={isChartsView}
                      setIsChartsView={setIsChartsView}
                    />
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate(`target-form`);
                      }}
                      isDisabled={!filteredMetrics?.length}
                    >
                      Add target
                    </Button>
                  </HStack>
                </HStack>
              </VStack>
              <VStack alignItems="start" w="100%">
                {isChartsView ? (
                  <Grid templateColumns="repeat(3, 1fr)" gap="8px" w="100%">
                    {filteredData.map((target, index) => (
                      <GridItem w="100%" key={index}>
                        <TargetsGraphCard
                          target={target}
                          setAction={setAction}
                          openAction={onOpenActionModal}
                        />
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  <TargetsTable
                    data={filteredData}
                    currentTab={selectedView}
                    isSearch={!!searchValue}
                    setAction={setAction}
                    openAction={onOpenActionModal}
                  />
                )}
                {shouldShowButton && (
                  <Button variant="ghost" onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Hide' : 'Show all...'}
                  </Button>
                )}
              </VStack>
            </VStack>
          )}
        </VStack>
        <AddActionModal
          isOpen={isOpenActionModal}
          onClose={onCloseActionModal}
          actionToEdit={actions?.find((action) => action.id === selectedActionId)}
          disclosureRequirementRef={reference}
          reportingUnits={reportingUnits}
          isGroup={isGroup}
          materialityAssessmentId={companyAssessmentId}
        />
        {!isGroup && (
          <VStack alignItems="start">
            <AggregatedMetrics />
          </VStack>
        )}
      </VStack>
      <LearnMoreDrawer
        header={title}
        isOpen={isOpen}
        onClose={onClose}
        description={description ?? ''}
      />
    </VStack>
  );
};
