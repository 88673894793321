import { DocumentationFile } from 'models';

export const getNameExtension = (path: string) => {
  const fileName = decodeURI(path).split(/[#?]/)[0].split('/').pop() ?? '';
  const extension = (fileName.split('.').pop() ?? '').toLowerCase();
  const name = fileName.split('.').slice(0, -1).join('.').replace(/[-_]/g, ' ');
  return { name, extension };
};

export const ALL_FILES_FILTER = () => true;

export function getFileFilter(searchTerm: string) {
  searchTerm = searchTerm.toLocaleLowerCase().trim();
  if (searchTerm.length < 3) return ALL_FILES_FILTER;
  return (f: DocumentationFile) =>
    f.title.toLocaleLowerCase().includes(searchTerm) ||
    f.description.toLocaleLowerCase().includes(searchTerm) ||
    f.tags.join('$').toLocaleLowerCase().includes(searchTerm);
}
