import {
  Box,
  ChakraTheme,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
  TabsProps,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Typography } from 'Tokens';

export const TabsTheme: ChakraTheme['components']['Tabs'] = {
  sizes: {
    md: {
      tab: {
        px: '16px',
        py: '18px',
      },
    },
  },
  variants: {
    line: {
      tablist: {
        borderBottom: '1px',
        paddingBottom: '1px',
      },
      tab: {
        _hover: {
          bg: 'bg.hover',
          borderBottomColor: 'bg.hover',
          _selected: {
            borderBottom: '2px',
            borderBottomColor: 'accent.dark',
          },
        },
        _selected: {
          borderBottom: '2px',
          bg: 'transparent',
          borderBottomColor: 'text.selected',
          color: 'text.selected',
          _hover: {
            bg: 'bg.hover',
            cursor: 'auto',
          },
        },
      },
    },
  },
};

export const TABS_VARIANTS = Object.keys(TabsTheme.variants ?? {});
export type TabsVariant = (typeof TABS_VARIANTS)[number];

export type TabItemProps = {
  title: string | React.ReactNode;
  id: string;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  content: React.ReactNode;
  isDisabled?: boolean;
  isHidden?: boolean;
  disabledTooltip?: string;
};
export const Tabs = ({
  items,
  currentTab,
  onChange,
  padList,
  tabsGap,
  tabPadding,
  ...props
}: Omit<TabsProps, 'variant' | 'children'> & {
  variant?: TabsVariant;
  items: TabItemProps[];
  currentTab?: string;
  onChange?: (id: string) => void;
  padList?: TabsProps['padding'];
  tabsGap?: TabsProps['gap'];
  tabPadding?: TabsProps['padding'];
}) => {
  const [internalCurrentTab, setInternalCurrentTab] = useState<number>(0);
  return (
    <ChakraTabs
      index={currentTab ? items.findIndex((x) => x.id === currentTab) ?? 0 : internalCurrentTab}
      onChange={onChange ? (num) => onChange?.(items[num].id) : (num) => setInternalCurrentTab(num)}
      isLazy
      width="100%"
      lazyBehavior="unmount"
      {...props}
    >
      <TabList paddingX={padList ?? '0px'} gap={tabsGap}>
        {items
          .filter((item) => !item.isHidden)
          .map((item) => (
            <Tab key={item.id} isDisabled={item.isDisabled} padding={tabPadding}>
              {item?.leftElement && (
                <Box paddingRight="4px" fontSize="16px">
                  {item.leftElement}
                </Box>
              )}
              <Typography variant="bodyStrong" color="currentColor">
                {item.title}
              </Typography>
              {item?.rightElement && <Box paddingLeft="4px">{item.rightElement}</Box>}
            </Tab>
          ))}
      </TabList>
      <TabPanels width="100%">
        {items
          .filter((item) => !item.isHidden)
          .map((item) => (
            <TabPanel key={item.id} padding="0px" width="100%">
              {item.content}
            </TabPanel>
          ))}
      </TabPanels>
    </ChakraTabs>
  );
};
