import { InternalRefetchQueriesInclude, OnQueryUpdated } from '@apollo/client';
import { useUserData } from '@nhost/react';
import {
  useAddNoteMutation,
  Note_Insert_Input_,
  Attachment_Insert_Input_,
  useRemoveAttachmentMutation,
  DocumentationFile,
  AttachmentBox,
  useUpsertAttachmentsMutation,
} from 'models';
import { useCallback } from 'react';

export function useSaveNote() {
  const [addNote] = useAddNoteMutation();

  const user = useUserData();
  return useCallback(
    async (
      note: string | undefined,
      noteHistoryId?: string,
      refetchQueries?: InternalRefetchQueriesInclude,
      isAIGenerated?: boolean
    ) => {
      if (noteHistoryId) {
        const noteData: Note_Insert_Input_ = {
          body: note,
          noteHistoryId: noteHistoryId,
          authorId: user?.id,
          isAIGenerated: isAIGenerated,
        };

        try {
          await addNote({
            variables: {
              noteInput: noteData,
            },
            refetchQueries: refetchQueries,
            awaitRefetchQueries: true,
          });
        } catch (e) {
          console.error('FAILED', e);
        }
      }
    },
    [addNote, user]
  );
}

export function useSaveAttachments() {
  const [upsertAttachments] = useUpsertAttachmentsMutation();
  const [removeAttachment] = useRemoveAttachmentMutation();
  const user = useUserData();

  return useCallback(
    async (
      filesToAttach: DocumentationFile[],
      attachmentBox: AttachmentBox,
      refetchQueries?: InternalRefetchQueriesInclude,
      onQueryUpdated?: OnQueryUpdated<any> | undefined
    ) => {
      const currentAttachments = attachmentBox.attachments;
      const attachmentBoxId = attachmentBox.id;

      let refetch: InternalRefetchQueriesInclude | undefined;

      const deSelectedFiles = currentAttachments?.filter(
        (attachment) => !filesToAttach.find((attachFile) => attachFile.id === attachment.file.id)
      );

      if (!!deSelectedFiles) {
        refetch = refetchQueries;
      }

      const fileData: Attachment_Insert_Input_[] = filesToAttach.map((file: DocumentationFile) => {
        return {
          attachmentBoxId: attachmentBoxId,
          fileId: file.id,
        };
      });

      if (fileData.length > 0) {
        try {
          await upsertAttachments({
            variables: {
              attachmentInputs: fileData,
            },
            refetchQueries: refetch,
            onQueryUpdated: onQueryUpdated,
            awaitRefetchQueries: true,
          });
        } catch (e) {
          console.error('FAILED', e);
        }
      }

      deSelectedFiles?.forEach((attachment, index) => {
        if (index === deSelectedFiles.length - 1) {
          refetch = refetchQueries;
        }
        try {
          removeAttachment({
            variables: {
              attachmentId: attachment.id,
            },
            refetchQueries: refetch,
            onQueryUpdated: onQueryUpdated,
            awaitRefetchQueries: true,
          });
        } catch (e) {
          console.error('FAILED', e);
        }
      });
    },
    [upsertAttachments, removeAttachment, user]
  );
}

export function useRemoveAttachment() {
  const [removeAttachment] = useRemoveAttachmentMutation();

  return useCallback(
    async (
      attachmentId: string,
      refetchQueries?: InternalRefetchQueriesInclude,
      onQueryUpdated?: OnQueryUpdated<any> | undefined
    ) => {
      try {
        await removeAttachment({
          variables: {
            attachmentId: attachmentId,
          },
          refetchQueries: refetchQueries,
          onQueryUpdated: onQueryUpdated,
          awaitRefetchQueries: true,
        });
      } catch (e) {
        console.error('FAILED', e);
      }
    },
    [removeAttachment]
  );
}
