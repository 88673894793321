import { Box, HStack, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import {
  useGetReportingUnitQuery,
  useGetDisclosureRequirementMetricsQuery,
  useGetShortEsrsStandardQuery,
  GetDisclosureRequirementMetricsQuery_,
} from 'models';
import { ContentLayout, Loader } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import { MetricViewEnums, UNKONWN_ROUTE } from '..';
import { Structure } from '../../Assessment/Structure';
import { MetricDataCollection } from '../../MaterialityAssessment';
import { Metrics } from './Metrics';

type DisclosureRequirementWithMetrics =
  GetDisclosureRequirementMetricsQuery_['requirements'][number];

const isMetricMaterial = (
  materialMetrics: DisclosureRequirementWithMetrics['metrics'][number]['materialMetrics'],
  companyStandardId: any,
  dataCollectionLevel: any,
  isMaterialForParent: boolean
) => {
  return materialMetrics.some((materialMetric) => {
    const existsInCompany =
      materialMetric.materialStandardId === companyStandardId &&
      materialMetric.dataCollection === dataCollectionLevel;

    const isMaterial = materialMetric.isMaterial || isMaterialForParent;

    return existsInCompany && isMaterial;
  });
};

const requirementHasMetrics = (
  disclosureRequirement: DisclosureRequirementWithMetrics,
  companyStandardId: any,
  parentStandardId: any,
  dataCollectionLevel: MetricDataCollection
): boolean => {
  const drMetrics = disclosureRequirement.metrics;

  return drMetrics.some((metric) => {
    const materialMetrics = metric.materialMetrics;
    const isMaterialForParent =
      materialMetrics.find(
        (materialMetric) => materialMetric.materialStandardId === parentStandardId
      )?.isMaterial ?? false;

    return isMetricMaterial(
      materialMetrics,
      companyStandardId,
      dataCollectionLevel,
      isMaterialForParent
    );
  });
};

export const MetricsDisclosureRequirements = () => {
  const {
    companyId = '',
    standardRef = '',
    esrsAssessmentId = '',
    reportingUnitId = '',
    disclosureRequirementRef,
    view,
  } = useParams();
  const navigate = useNavigate();
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>('');

  const { data: standardData, loading: standardLoading } = useGetShortEsrsStandardQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });
  const { data: ruData, loading: ruLoading } = useGetReportingUnitQuery({
    variables: { reportingUnitId },
    skip: !reportingUnitId,
  });

  const { companyAssessmentId: companyStandardId, parentAssessmentId: parentStandardId } =
    useMaterialStandardId(standardRef, esrsAssessmentId);

  const isCompanyLevel = useMemo(() => ruData?.bu?.isCompanyLevel, [ruData]);
  const dataCollectionLevel = useMemo(() => {
    return isCompanyLevel
      ? MetricDataCollection.companyLevel
      : MetricDataCollection.reportingUnitLevel;
  }, [isCompanyLevel]);

  const { data, loading: metricLoading } = useGetDisclosureRequirementMetricsQuery({
    variables: {
      standardRef,
      companyStandardId: companyStandardId,
      parentStandardId: parentStandardId || companyStandardId,
      dataCollection: dataCollectionLevel,
    },
  });

  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);
  const disclosureRequirements = useMemo(() => data?.requirements ?? [], [data]);

  const filteredRequirements = useMemo(() => {
    return disclosureRequirements.filter((req) =>
      requirementHasMetrics(req, companyStandardId, parentStandardId, dataCollectionLevel)
    );
  }, [disclosureRequirements]);

  const handleNodeSelect = (nodeKey: string) => {
    navigate(
      `/${companyId}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${reportingUnitId}/disclosure-requirement/${nodeKey}/${
        view ?? MetricViewEnums.dataInput
      }`
    );
    setSelectedNodeKey(nodeKey);
  };

  useEffect(() => {
    if (!disclosureRequirementRef || disclosureRequirementRef === UNKONWN_ROUTE) {
      setSelectedNodeKey(filteredRequirements[0]?.reference);
    } else {
      setSelectedNodeKey(disclosureRequirementRef);
    }
  }, [filteredRequirements, disclosureRequirementRef]);

  if (standardLoading || metricLoading || ruLoading) {
    return <Loader />;
  }

  return (
    <Box minH={`calc(100vh - 64px)`} width="100%" alignItems="flex-start">
      <HStack borderTopColor="border.decorative" height="100%">
        <VStack
          height="100%"
          borderRight="1px solid"
          borderRightColor="border.decorative"
          overflow="hidden auto"
        >
          <Structure
            width="300px"
            header={
              <VStack p="10px 16px 8px" spacing="24px" alignItems="start">
                <Button
                  variant="ghost"
                  onClick={() => navigate(`/${companyId}/esrs/${esrsAssessmentId}`)}
                  leftIcon={<ArrowLeftIcon />}
                >
                  Back
                </Button>
                <VStack spacing="4px" alignItems="start" pb="8px !important">
                  <Typography variant="h2">{standard?.title}</Typography>
                  <Typography variant="body" color="text.muted">
                    {standard?.description}
                  </Typography>
                </VStack>
                <Typography variant="overline" color="text.muted" textTransform="uppercase">
                  {'Disclosure requirements'}
                </Typography>
              </VStack>
            }
            nodes={[
              ...filteredRequirements.map((dr) => ({
                title: dr.title,
                key: dr.reference,
                reference: dr.reference,
              })),
            ]}
            selectedNodeKey={selectedNodeKey}
            onSelectNode={handleNodeSelect}
            size="sm"
          />
        </VStack>
        <VStack height="100%" width="calc(100% - 300px)" p="12px 16px 16px" overflow="hidden auto">
          <ContentLayout header={false} padChildren={false}>
            <Metrics
              disclosureRequirementRef={selectedNodeKey}
              disclosureRequirement={
                filteredRequirements?.find((dr) => dr.reference === selectedNodeKey) ?? undefined
              }
            />
          </ContentLayout>
        </VStack>
      </HStack>
    </Box>
  );
};
