import { VStack } from '@chakra-ui/react';
import { DateInput, Input } from 'Atoms';
import { GroupAssessmentsDocument_, useUpsertGroupAssessmentMutation } from 'models';
import { Modal } from 'Molecules';
import { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Typography } from 'Tokens';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { GroupAssessmentEditFields } from './GroupAssessmentList';

type AssessmentFormFields = {
  name: string;
  period: Date;
};

export const GroupAssessmentModal = ({
  isOpen,
  onClose,
  editFields,
}: {
  isOpen: boolean;
  onClose: () => void;
  editFields?: GroupAssessmentEditFields;
}) => {
  const { t } = useTranslation(['common', 'group']);
  const toast = useToast();
  const { companyId } = useCurrentCompanyId();
  const [upsertGroupAssessment] = useUpsertGroupAssessmentMutation();
  const { control, handleSubmit, reset } = useForm<AssessmentFormFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const { isValid, isDirty } = useFormState({ control });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    reset({
      name: editFields ? editFields.name : '',
      period: editFields ? new Date(editFields.period) : new Date(),
    });
  }, [isOpen]);

  const onSubmit = (values: AssessmentFormFields) => {
    setLoading(true);
    upsertGroupAssessment({
      variables: {
        assessmentInputs: {
          id: editFields?.id,
          name: values.name,
          period: values.period,
          companyId: companyId,
          consolidatedCompanyAssessment: {
            data: {
              startDate: new Date(values.period) ?? new Date(),
              endDate: new Date(values.period) ?? new Date(),
              companyId: companyId,
              aggregate: {
                data: {
                  title: values.name,
                  interval: 'YEAR',
                  startDate: new Date(values.period) ?? new Date(),
                  companyId: companyId,
                },
              },
            },
          },
        },
      },
      onCompleted: () => {
        setLoading(false);
        onClose();
      },
      onError: () => {
        setLoading(false);
        toast({
          text: 'An error occurred while creating the assessment.',
          variant: 'danger',
        });
      },
      refetchQueries: [GroupAssessmentsDocument_],
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('common:assessment.createAssessment')}
      confirmButtonProps={{
        type: 'submit',
        form: 'group-assessment-form',
        isDisabled: !isValid || !isDirty,
        isLoading: loading,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="group-assessment-form">
        <VStack spacing="16px">
          <Controller
            name="name"
            rules={{
              required: true,
              validate: (value) => value.trim().length !== 0,
            }}
            control={control}
            render={({ field }) => (
              <VStack alignItems="stretch" width="100%">
                <Typography variant="bodyStrong">{t('group:assessment.form.name')}</Typography>
                <Input
                  width="100%"
                  value={field.value}
                  onChange={field.onChange}
                  ref={field.ref}
                  onBlur={field.onBlur}
                  placeholder={t('group:assessment.placeholder')}
                />
              </VStack>
            )}
          />
          <VStack alignItems="stretch" width="100%">
            <Typography variant="bodyStrong">{t('group:assessment.form.period')}</Typography>
            <Controller
              name="period"
              control={control}
              defaultValue={new Date()}
              render={({ field }) => (
                <DateInput
                  width="100%"
                  value={field.value}
                  setValue={(date) => field.onChange(date ?? new Date())}
                  attachToId="group-assessment-form"
                  showYearPicker
                  dateFormat="yyyy"
                />
              )}
            />
          </VStack>
        </VStack>
      </form>
    </Modal>
  );
};
