import { InvestorOwnedCompany } from 'models';

export const NO_FILTER = () => true;
const ALL_PORTFOLIOS_FILTER = 'All portfolios';

export function getFilter(searchTerm: string, selectedPortfolio: string) {
  searchTerm = searchTerm.toLocaleLowerCase().trim();
  if (searchTerm.length === 0 && selectedPortfolio === ALL_PORTFOLIOS_FILTER) return NO_FILTER;
  return (ac: InvestorOwnedCompany) =>
    (searchTerm.length && ac.name.toLocaleLowerCase().includes(searchTerm)) ||
    ac.portfolioMemberships.some(
      (membership: { portfolio: { id: string } }) => membership.portfolio.id === selectedPortfolio
    );
}
