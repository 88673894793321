import { Box, HStack, VStack } from '@chakra-ui/react';
import { IconButton, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { DocumentationFile } from 'models';
import { getNameExtension } from 'utils/files';
import { useTranslation } from 'utils/translation';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Menu, MenuAction } from 'Molecules/Menu';
import { CheckIcon, LoaderIcon, WarningIcon } from 'Tokens/Icons/Status';
import { RefreshIcon, RemoveIcon } from 'Tokens/Icons/Function';

export type FileStatus = 'default' | 'uploading' | 'uploaded' | 'failed';
export type FileWidth = {
  boxWidth?: string;
  titleMinWidth?: string;
  titleMaxWidth?: string;
};

export type FileProps = {
  file: DocumentationFile;
  status?: FileStatus;
  actions?: MenuAction[];
  onCancelClick?: () => void;
  hideActionsButton?: boolean;
  fileWidth?: FileWidth;
};

const FILE_STATUS_FIELDS = {
  uploading: {
    translationKey: 'uploading_elipsis',
    icon: <LoaderIcon display=" !important" width="100%" height="100%" color="text.muted" />,
    withRetryButton: false,
  },
  uploaded: {
    translationKey: 'uploaded',
    icon: (
      <CheckIcon
        display=" !important"
        width="100%"
        height="100%"
        color="text.muted"
        opacity="70%"
      />
    ),
    withRetryButton: false,
  },
  failed: {
    translationKey: 'uploadFailedTryAgain',
    icon: <WarningIcon display=" !important" width="100%" height="100%" color="text.muted" />,
    withRetryButton: true,
  },
};

const FileStatusText = ({
  status,
  uploadedAt,
  uploadedBy,
}: {
  status: FileStatus;
  uploadedAt?: string;
  uploadedBy?: string;
}) => {
  const { t } = useTranslation(['files', 'common']);
  return (
    <HStack spacing="4px">
      {status !== 'default' && <Box boxSize="12px">{FILE_STATUS_FIELDS[status].icon}</Box>}
      <Typography noOfLines={1} variant="detail" color="text.muted">
        {status === 'default'
          ? uploadedAt
          : t(`files:${FILE_STATUS_FIELDS[status].translationKey}`)}
        {uploadedBy && status === 'default' && ', ' + uploadedBy}
      </Typography>
      {status !== 'default' && FILE_STATUS_FIELDS[status].withRetryButton && (
        <IconButton
          aria-label="retry"
          variant="ghost"
          size="xxs"
          icon={<RefreshIcon color="inherit" />}
          tooltipLabel={t('common:actions.retry')}
        />
      )}
    </HStack>
  );
};

export function File({ file, status = 'default', actions, onCancelClick, fileWidth }: FileProps) {
  const { extension } = getNameExtension(file.storageFile?.name ?? '');
  const { t } = useTranslation('common');

  let iconButton;
  const dateAttached = new Date(file.updatedAt)
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '.');
  if (status === 'default') {
    iconButton = (
      <Menu
        size="md"
        padding="8px"
        description={`Attached by ${
          file.uploadedBy?.displayName ?? t('common:user.deletedUserName')
        } ${dateAttached}`}
        sections={[
          {
            actions: actions ?? [],
          },
        ]}
      />
    );
  } else {
    iconButton = (
      <IconButton
        aria-label={t('common:actions.delete')}
        size="md"
        variant="ghost"
        icon={<RemoveIcon color="inherit" />}
        onClick={onCancelClick}
        tooltipLabel={t('common:actions.delete')}
      />
    );
  }

  return (
    <Box
      width={fileWidth?.boxWidth ?? '100%'}
      height="54px"
      padding="8px"
      border="1px"
      borderColor="border.decorative"
      borderRadius="8px"
      bgColor="#FFFFFF"
    >
      <HStack justifyContent="space-between">
        <HStack gap="8px">
          <Box width="32px" padding="4px">
            <FileIcon
              extension={extension}
              {...defaultStyles[extension as keyof typeof defaultStyles]}
              labelUppercase
            />
          </Box>
          <VStack
            minW={fileWidth?.titleMinWidth ?? '120px'}
            maxW={fileWidth?.titleMaxWidth ?? '140px'}
            alignItems="start"
            gap="2px"
          >
            <Typography noOfLines={1} variant="bodyStrong" textOverflow="ellipsis">
              {file.title}
            </Typography>
            <TruncatableText
              variant="detail"
              color="text.muted"
              text={file.storageFile?.mimeType?.split('/')[1].toLocaleUpperCase() ?? ''}
            />
          </VStack>
        </HStack>
        {iconButton}
      </HStack>
    </Box>
  );
}
