import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { IconUserPlus } from '@tabler/icons-react';
import { SearchInput } from 'Molecules';
import { Typography, colors } from 'Tokens';
import {
  InviteMembersModal,
  TeamMember,
  TeamMembersFilterOption,
  useTeamMembers,
} from 'containers/TeamMembers';
import { User } from 'models';
import { useMemo, useState } from 'react';
import { Avatar, AvatarSize } from 'Atoms';

export const ProjectLeaderSelect = ({
  defaultProjectLeader,
  isDisabled = false,
  onChange,
  rounded,
  avatarText,
  small,
  isBorderless = false,
  size,
}: {
  defaultProjectLeader?: Partial<User>;
  isDisabled?: boolean;
  onChange: (member: TeamMember) => void;
  rounded?: boolean;
  avatarText?: string;
  noText?: boolean;
  small?: boolean;
  isBorderless?: boolean;
  size?: AvatarSize;
}) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState<string>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const user = useUserData();
  const { members } = useTeamMembers(TeamMembersFilterOption.Active);
  const [selectedProjectLeader, setSelectedProjectLeader] = useState<TeamMember>();

  const selectedValue = useMemo(() => {
    if (selectedProjectLeader) {
      return `${selectedProjectLeader?.name ?? selectedProjectLeader?.email} ${
        selectedProjectLeader?.email === user?.email ? ' (You)' : ''
      }`;
    }

    if (invitedEmail) {
      return invitedEmail;
    }

    if (defaultProjectLeader) {
      if (small && defaultProjectLeader?.displayName) {
        const name = defaultProjectLeader?.displayName?.split(' ');
        return `${name[0]} ${name[1][0]}.`;
      }
      return `${defaultProjectLeader?.displayName ?? defaultProjectLeader?.email} ${
        defaultProjectLeader?.email === user?.email ? ' (You)' : ''
      }`;
    }
  }, [invitedEmail, selectedProjectLeader, defaultProjectLeader]);

  const handleSearchMembers = (member: TeamMember) => {
    return (
      member.name?.toLowerCase().includes(search.toLowerCase()) ||
      member.email?.toLowerCase().includes(search.toLowerCase())
    );
  };

  const handleSelectProjectLeader = (member: TeamMember) => {
    setSelectedProjectLeader(member);
    setIsMenuOpen(false);
    onChange(member);
  };

  const onCloseInviteModal = () => {
    setIsInviteModalOpen(false);
  };

  const onOpenInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  return (
    <Menu
      size="lg"
      gutter={2}
      isOpen={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      closeOnSelect
    >
      <MenuButton
        isDisabled={isDisabled}
        as={Button}
        width={small ? 'fit-content' : '100%'}
        h={isBorderless ? 'fit-content' : '38px'}
        bg="bg.default"
        borderWidth={isBorderless ? '0px' : '1px'}
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover', svg: { color: 'border.hover' } }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
          svg: { color: 'inherit' },
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius={rounded ? '32px' : '8px'}
        p={isBorderless ? '0px' : small ? '4px' : '8px'}
        rightIcon={<ChevronDownIcon fontSize={20} color="border.default" />}
        textAlign="left"
        overflow="hidden"
        color="text.default"
      >
        <HStack spacing={0}>
          {avatarText && (
            <Avatar
              name={avatarText}
              color={isDisabled ? 'text.default' : 'text.onAccent'}
              backgroundColor={isDisabled ? 'bg.disabled.accent' : 'bg.accent'}
              size={size ?? 'sm'}
            />
          )}
          {!avatarText && small && (
            <Avatar
              bgColor="bg.unknown"
              icon={<IconUserPlus color={colors.text.muted} size={'16px'} />}
              size={size ?? 'sm'}
            />
          )}
          {!small && (
            <Typography color={selectedValue ? 'inherit' : 'text.hint'} variant="body">
              {selectedValue ? selectedValue : 'Select or invite a user'}
            </Typography>
          )}
        </HStack>
      </MenuButton>
      <MenuList p="8px" width="100%">
        <SearchInput
          width="100%"
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          marginBottom="4px"
        />
        <Box maxH="200px" overflowY="auto">
          {members.filter(handleSearchMembers).map((member) => (
            <MenuItem
              key={member.id}
              p="8px"
              m="0px"
              height="20px"
              w="100%"
              closeOnSelect={false}
              onClick={() => handleSelectProjectLeader(member)}
            >
              <HStack w="100%" spacing="8px">
                <Avatar name={member.name ?? member.email} size="sm" />
                <Typography variant="bodyStrong">{`${member.name ?? member.email} ${
                  user?.email === member.email ? '(You)' : ''
                }`}</Typography>
              </HStack>
            </MenuItem>
          ))}
        </Box>
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <Box w="100%">
          <Button
            variant="ghost"
            w="100%"
            leftIcon={<IconUserPlus size={'16px'} />}
            onClick={onOpenInviteModal}
            isDisabled
          >
            <Typography variant="bodyStrong" color="text.disabled">
              Invite new user
            </Typography>
          </Button>
        </Box>
      </MenuList>
      <InviteMembersModal
        isOpen={isInviteModalOpen}
        onClose={onCloseInviteModal}
        onInvitationsSent={(invitations) => setInvitedEmail(invitations[0])}
      />
    </Menu>
  );
};
