import { Box, HStack, Link, VStack } from '@chakra-ui/react';
import { Button, Infobox } from 'Atoms';
import { useEsrsAssessmentQuery, useUpdateAssessmentOnboardingMutation } from 'models';
import { Loader } from 'Molecules';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import { EsrsAssessmentConfig } from '../Assessment';
import { AddMaterialityAssessment, MaterialityAssessment } from '../MaterialityAssessment';
import { AboutEsrs } from './AboutEsrs';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { IconDownload, IconHelpCircle } from '@tabler/icons-react';
import { DataCollectionSetup } from '../MaterialityAssessment/DataCollectionSetup';
import { useCurrentCompanyId } from 'utils/hooks';

export const GUIDE_DOCUMENT_ID = '1NwuUz3RxG26BmcN3CPF1-26F7VH01bbFbgDWxPa5a6E';
export const SHEET_NUMBER = '0';

const AssessmentPath = ({
  step,
  setStep,
}: {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}) => {
  const [updateOnboardingStep] = useUpdateAssessmentOnboardingMutation();
  const { standardRef, esrsAssessmentId } = useParams();

  const goToStep = (step: number) => {
    if (step <= 1) return;
    updateOnboardingStep({
      variables: {
        id: esrsAssessmentId,
        onboardingStep: step,
      },
    });
  };

  return (
    <VStack width="20%" p="20px 12px" h="100%" alignItems="flex-start">
      <Box position="relative">
        <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            bgColor="bg.muted"
            title="Learn about ESRS"
            active={step === 1}
            done={step > 1}
            goBack={() => goToStep(1)}
          />
        </Box>
        <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            title="Set company structure"
            active={step === 2}
            bgColor="bg.muted"
            done={step > 2}
            goBack={() => goToStep(2)}
          />
        </Box>
        <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            title="Materiality assessment"
            active={step === 3}
            bgColor="bg.muted"
            done={step > 3}
            goBack={() => goToStep(3)}
          />
        </Box>
        <Box pl="32px" pb="24px" ml="14px">
          <ActiveStepLabels
            left="0"
            bgColor="bg.muted"
            title="Data collection setup"
            active={step === 4}
            done={step > 4}
            goBack={() => goToStep(4)}
          />
        </Box>
      </Box>
    </VStack>
  );
};

const MaterialityAssessmentStep = ({
  setStep,
  isOnboarding = false,
}: {
  setStep: (step: number) => void;
  isOnboarding?: boolean;
}) => (
  <VStack w="100%" alignItems="start" spacing="32px">
    <Typography variant="h1">Materiality assessment</Typography>
    <VStack alignItems="start">
      <VStack gap="2px" alignItems="start">
        <Typography variant="h2">Step 1: Perform assessment</Typography>
        <Typography
          variant="body"
          color="text.muted"
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
        >
          Understand and perform materiality assessment
        </Typography>
      </VStack>
      <Infobox
        title="Materiality assessment template"
        description={
          <>
            Template will guide your on all relevant discussion points. If you’re not sure where to
            start, read our{' '}
            <Link href="#" color="text.brand">
              double materiality assessment guide
            </Link>
          </>
        }
        status="info"
        titleProps={{ variant: 'h3' }}
        closable={false}
        withIcon={false}
        backgroundColor="inherit"
        border="1px solid"
        borderRadius="8px"
        borderColor="#8787FF"
        extra={
          <>
            <Button
              fontWeight={500}
              leftIcon={<IconDownload size="16px" />}
              onClick={() =>
                (location.href = `https://docs.google.com/spreadsheets/d/${GUIDE_DOCUMENT_ID}/export?format=xlsx&gid=${SHEET_NUMBER}`)
              }
            >
              Download
            </Button>
            <Button variant="ghost" leftIcon={<IconHelpCircle size="16px" />} fontWeight={500}>
              Learn more
            </Button>
          </>
        }
      />
    </VStack>
    <MaterialityAssessment setStep={setStep} isOnboarding={isOnboarding} />
  </VStack>
);

export const Onboarding = () => {
  const navigate = useNavigate();
  const { esrsAssessmentId = '' } = useParams();
  const [step, setStep] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [lastSavedStep, setLastSavedStep] = useState(0);
  const [updateOnboardingStep] = useUpdateAssessmentOnboardingMutation();
  const { companyId } = useCurrentCompanyId();
  const { data, loading } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const assessmentStep = data?.esrsAssessment?.onboardingStep;

  useEffect(() => {
    if (assessmentStep) {
      setStep(assessmentStep);
      setLastSavedStep(assessmentStep);
    }
  }, [assessmentStep]);

  if (loading) {
    return <Loader />;
  }

  const handleNextStep = () => {
    if (nextDisabled) {
      setShowAlertMessage(true);
    } else {
      if (step + 1 > lastSavedStep) {
        updateOnboardingStep({
          variables: {
            id: esrsAssessmentId,
            onboardingStep: step + 1,
          },
        }).then((res) =>
          setLastSavedStep(res.data?.update_EsrsAssessment_by_pk?.onboardingStep ?? 0)
        );
      }
      setShowAlertMessage(false);
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    if (step <= 1) return;
    updateOnboardingStep({
      variables: {
        id: esrsAssessmentId,
        onboardingStep: step - 1,
      },
    }).then((res) => setLastSavedStep(res.data?.update_EsrsAssessment_by_pk?.onboardingStep ?? 0));

    setStep(step - 1);
    setNextDisabled(false);
    setShowAlertMessage(false);
  };

  return (
    <VStack bg="bg.muted" h="fit-content" alignItems="start" p="8px" justifyContent="start">
      <Button
        variant="ghost"
        onClick={() => {
          if (history.length > 1) {
            setStep((prev) => --prev);
            navigate(-1);
          } else {
            const currentUrl = window.location.href;
            window.location.href = currentUrl.replace(/\/[^/]+\/[^/]+\/?$/, '');
          }
        }}
        leftIcon={<ArrowLeftIcon />}
      >
        Back
      </Button>

      <HStack minH={`calc(100vh - 124px)`} w="100%" alignItems="flex-start">
        {step < 5 ? <AssessmentPath step={step} setStep={setStep} /> : <Box w="20%" />}
        <VStack
          width="60%"
          bg="bg.default"
          border="1px solid"
          borderColor="border.decorative"
          borderRadius="8px"
          alignItems="start"
          justifyContent="space-between"
          p="24px"
          pb="0"
          height="100%"
          position="relative"
        >
          {step === 1 && <AboutEsrs />}
          {step === 2 && (
            <EsrsAssessmentConfig
              isOnboarding={true}
              nextDisabled={nextDisabled}
              setNextDisabled={setNextDisabled}
              showAlertMessage={showAlertMessage}
            />
          )}
          {step === 3 && <MaterialityAssessmentStep setStep={setStep} isOnboarding={true} />}
          {step === 4 && <DataCollectionSetup setStep={setStep} isOnboarding={true} />}
          {step === 5 && <AddMaterialityAssessment isOnboarding={true} />}
          {step < 5 && (
            <VStack
              position="sticky"
              bottom="0"
              left="0"
              right="0"
              w="calc(100% + 48px)"
              backgroundColor="inherit"
              borderTop="1px solid"
              borderColor="border.decorative"
              borderBottomRadius="8px"
              ml="-24px"
            >
              <HStack justifyContent="flex-end" p="16px" alignSelf="flex-end">
                {step > 1 && (
                  <Button variant="ghost" onClick={() => handlePrevStep()}>
                    Prev. step
                  </Button>
                )}
                {step < 4 && (
                  <Button variant="primary" onClick={handleNextStep}>
                    Next step
                  </Button>
                )}
                {step === 4 && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      updateOnboardingStep({
                        variables: {
                          id: esrsAssessmentId,
                          onboardingStep: 0,
                        },
                      }).then(() => {
                        if (history.length > 1) {
                          navigate(`/${companyId}/esrs/${esrsAssessmentId}`);
                        } else {
                          const currentUrl = window.location.href;
                          window.location.href = currentUrl.replace(/\/[^/]+\/?$/, '');
                        }
                      });
                    }}
                  >
                    Done
                  </Button>
                )}
              </HStack>
            </VStack>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};
