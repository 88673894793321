import { Button, Tag, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { Investor, useCompanyAssessmentQuery } from 'models';
import { Menu } from 'Molecules/Menu';
import { Modal, ModalProps, Select } from 'Molecules';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HStack, VStack, Divider, MenuButton, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { SquareIconWrapper } from 'Tokens/Icons/SquareIconWrapper';
import { AccessStatusType } from './Assessment';
import { uniqBy } from 'lodash';

export const ShareAssessmentModal = ({
  isOpen,
  onClose,
  investors,
  cAssessmentId,
  handleSaveChanges,
}: {
  investors: Investor[];
  cAssessmentId?: string;
  handleSaveChanges: (param: AccessStatusType[]) => void;
} & Pick<ModalProps, 'isOpen' | 'onClose'>) => {
  const { t } = useTranslation(['assessment', 'common']);

  const [status, setStatus] = useState<AccessStatusType[]>([]);
  const [selectedYears, setSelectedYears] = useState<{ [portfolioId: string]: number }>({});
  const [currInvestor, setCurrInvestor] = useState(investors[0]);

  const { isOpen: replaceOpen, onClose: replaceClose, onOpen: openReplaceModal } = useDisclosure();

  const { data } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId,
    },
    skip: !cAssessmentId,
  });
  const cAssessment = useMemo(() => {
    return data?.companyAssessment;
  }, [data]);

  useEffect(() => {
    const allPortfolioCompanies = investors.map((investor) => ({
      portfolioCompanyId: investor.id,
      assessmentId: investor.sharedCompanyAssessmentId,
      year:
        investor?.year ??
        Math.max(
          ...(investors
            .filter((i) => i.portfolioId === investor.portfolioId && !!i?.year)
            .map((i) => i.year ?? 2022) ?? [])
        ),
      portfolioId: investor.portfolioId,
    }));
    const initialSelectedYears = investors.reduce((acc, investor) => {
      acc[investor.portfolioId] = Math.max(
        ...(investors
          .filter((i) => i.portfolioId === investor.portfolioId && !!i?.year)
          .map((i) => i?.year ?? 2022) ?? [])
      );
      return acc;
    }, {} as { [portfolioId: string]: number });
    setStatus(allPortfolioCompanies);
    setSelectedYears(initialSelectedYears);
  }, [isOpen, investors]);

  const handleChangeAccessStatus = useCallback(
    (assessmentToAdd: string | null, portfolioId?: string) => {
      const portfolioIdToChange = portfolioId ?? currInvestor.portfolioId;
      return setStatus((curStatuses) => [
        ...curStatuses.map((cur) => {
          if (
            cur.portfolioId === portfolioIdToChange &&
            cur.year === selectedYears[portfolioIdToChange]
          ) {
            return {
              ...cur,
              assessmentId: assessmentToAdd,
            };
          }
          return cur;
        }),
      ]);
    },
    [cAssessmentId, setStatus, status, selectedYears, currInvestor]
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => handleSaveChanges(status)}
        size="md"
        title="Share access"
      >
        <VStack>
          {uniqBy(investors, 'portfolioId').map((investor, index) => {
            return (
              <>
                <HStack justifyContent="space-between" width="100%" pr="4px">
                  <HStack width="65%" justifyContent="space-between">
                    <HStack>
                      <SquareIconWrapper icon={CompanyIcon} size="22px" color="white" />
                      <Typography variant="bodyStrong">
                        {investor?.portfolio?.ownerCompany?.name ?? t('common:unknown')}
                      </Typography>
                    </HStack>
                    <HStack width="45%">
                      <Tag borderRadius="6px" variant="outlined">
                        <TruncatableText
                          variant="bodyStrong"
                          text={investor?.portfolio?.name ?? 'N/A'}
                        />
                      </Tag>
                      <Select<any>
                        options={investors
                          .filter(
                            (curInvestor) =>
                              curInvestor.portfolioId === investor?.portfolioId &&
                              !!curInvestor?.year
                          )
                          .map((curInvestor) => curInvestor?.year ?? 2022)
                          .sort((a, b) => a - b)
                          .map((year) => ({ label: String(year), value: year }))}
                        value={{
                          value: selectedYears[investor.portfolioId] ?? 2022,
                          label: selectedYears[investor.portfolioId] ?? 2022,
                        }}
                        onChange={(value) =>
                          setSelectedYears((cur) => ({
                            ...cur,
                            [investor.portfolioId]: value.value,
                          }))
                        }
                      />
                    </HStack>
                  </HStack>
                  <Menu
                    size="lg"
                    menuButton={
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon color="inherit" />}
                        size="sm"
                        variant="ghost"
                        aria-label="share"
                      >
                        <Typography variant="bodyStrong" color="text.muted">
                          {status.find(
                            (s) =>
                              s.portfolioId === investor.portfolioId &&
                              s.year === selectedYears[investor.portfolioId]
                          )?.assessmentId === cAssessmentId
                            ? t('assessment:sharedModal.access')
                            : t('assessment:sharedModal.noAccess')}
                        </Typography>
                      </MenuButton>
                    }
                    sections={[
                      {
                        actions: [
                          {
                            id: 'no access',
                            title: t('assessment:sharedModal.noAccess'),
                            variant:
                              status.find(
                                (s) =>
                                  s.portfolioId === investor.portfolioId &&
                                  s.year === selectedYears[investor.portfolioId]
                              )?.assessmentId === cAssessmentId
                                ? 'ghost'
                                : 'selected',
                            leftElement: <PrivateIcon color="inherit" />,
                            description: t('assessment:sharedModal.noAccessDescription'),
                            rightElement:
                              status.find(
                                (s) =>
                                  s.portfolioId === investor.portfolioId &&
                                  s.year === selectedYears[investor.portfolioId]
                              )?.assessmentId === cAssessmentId ? (
                                <></>
                              ) : (
                                <CheckIcon color="text.selected" />
                              ),
                            onClick: () => {
                              setCurrInvestor(investor);
                              handleChangeAccessStatus(null, investor.portfolioId);
                            },
                          },
                          {
                            id: 'access',
                            title: t('assessment:sharedModal.access'),
                            variant:
                              status.find(
                                (s) =>
                                  s.portfolioId === investor.portfolioId &&
                                  s.year === selectedYears[investor.portfolioId]
                              )?.assessmentId === cAssessmentId
                                ? 'selected'
                                : 'ghost',
                            leftElement: <PublicIcon color="inherit" />,
                            description: t('assessment:sharedModal.accessDescription'),
                            rightElement:
                              status.find(
                                (s) =>
                                  s.portfolioId === investor.portfolioId &&
                                  s.year === selectedYears[investor.portfolioId]
                              )?.assessmentId === cAssessmentId ? (
                                <CheckIcon color="text.selected" />
                              ) : (
                                <></>
                              ),
                            onClick: () => {
                              if (cAssessmentId) {
                                setCurrInvestor(investor);
                                const anyShared = status.find(
                                  (inv) =>
                                    inv.portfolioId === investor?.portfolioId &&
                                    inv.year === selectedYears[investor.portfolioId]
                                );
                                if (
                                  anyShared?.assessmentId !== cAssessmentId &&
                                  anyShared?.assessmentId !== null
                                ) {
                                  openReplaceModal();
                                } else {
                                  handleChangeAccessStatus(cAssessmentId, investor.portfolioId);
                                }
                              }
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </HStack>
                {index !== investors.length - 1 && (
                  <Divider border="1px" borderColor="border.decorative" orientation="horizontal" />
                )}
              </>
            );
          })}
        </VStack>
      </Modal>
      <Modal
        isOpen={replaceOpen}
        onClose={replaceClose}
        onConfirm={() => {
          handleChangeAccessStatus(cAssessmentId ?? null);
          replaceClose();
        }}
        size="sm"
        title="Conflict access"
        confirmText="Replace"
      >
        <VStack>
          <>
            <Typography>
              You already shared "{currInvestor?.sharedAssessment?.aggregate.title}" assessment with
              this investor. Do you want to replace "
              {currInvestor?.sharedAssessment?.aggregate.title}" assessment with this "
              {cAssessment?.aggregate.title}" - current assessment instead?
            </Typography>
          </>
        </VStack>
      </Modal>
    </>
  );
};
