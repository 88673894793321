import { ReactNode } from 'react';

export enum SideMenuSection {
  primary = 'primary',
  secondary = 'secondary',
  general = 'general',
  esg = 'esg',
}

export type SideMenuItem = {
  Icon: ComponentWithAs<'svg', IconProps>;
  path: string;
  section: SideMenuSection;
  title: string;
  component: ReactNode;
  key: string;
  disabledTooltip?: string;
};

export type SideMenuProps = {
  items: SideMenuItem[];
};
