import { memo, useMemo } from 'react';
import { useActivityVersionComparison } from '../VersioningOfQuestions.hooks';
import { Tag, TagProps } from 'Atoms';
import { ResolvedQuestion } from 'models';

export const IsNewTag = (props: TagProps) => (
  <Tag
    title="New"
    variant="info"
    minWidth="fit-content"
    maxWidth="fit-content"
    height="fit-content"
    {...props}
  />
);

const NonQuestionMemoIsNewTag = ({
  question,
  ...rest
}: {
  question: Pick<ResolvedQuestion, 'id' | 'uniqueId'> & { activityAssessmentId: string };
} & TagProps) => {
  const { checkIfIsNewQuestion } = useActivityVersionComparison(question.activityAssessmentId);
  const isNew = useMemo(() => checkIfIsNewQuestion(question), [question, checkIfIsNewQuestion]);

  if (isNew) return <IsNewTag {...rest} />;
  return null;
};

export const QuestionIsNewTag = memo(
  NonQuestionMemoIsNewTag,
  (a, b) =>
    a.question.id === b.question.id &&
    a.question.activityAssessmentId === b.question.activityAssessmentId
);
