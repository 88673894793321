import { useTranslation } from 'utils/translation';
import { ContentHeader, ContentLayout, SearchInput } from 'Molecules';
import { Box, Button, HStack, useMediaQuery, VStack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { Portfolio } from 'models';
import usePortfolios, { usePortfoliosByYear } from './Portfolios.hooks';
import { PortfoliosList, PortfolioSummary } from './pieces/PortfoliosList';
import { Route, Routes } from 'react-router-dom';
import { PortfolioView, useCurrentReportPeriod } from 'Features/PortfolioView';
import { PortfolioEditorModal } from './pieces/PortfolioEditor';
import { NothingFoundIllustration, PortfolioIllustration, Typography } from 'Tokens';
import { uniqBy } from 'lodash';
import { formatDigits } from 'utils/numbers';
import { useCurrentCompany } from 'utils/hooks';
import { EmptyState } from 'Atoms';
import { ErrorElement } from 'App';
import { RefreshIcon } from 'Tokens/Icons/Function';
import PortfolioSettings from './PortfolioSettings';

const SUMMARY_HEIGHT_MAP = {
  max: '246px',
  large: '230px',
  medium: '198px',
  small: '182px',
  default: '172px',
};

export const PortfoliosPage = () => {
  const { t } = useTranslation(['portfolio', 'common']);
  const { portfolios, loading } = usePortfolios();
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const [portfolioToEdit, setPortfolioToEdit] = useState<Portfolio | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const { company: currentCompany } = useCurrentCompany();
  const { reportPeriod } = useCurrentReportPeriod();

  const getPortfolioByYear = usePortfoliosByYear();

  const latestPortfolioYear = useMemo(() => {
    const allYears = [
      ...new Set(portfolios.flatMap((ps) => ps.portfolioCompanies).map((c) => c?.year)),
    ].sort((a, b) => (a ?? 0) - (b ?? 0));
    return allYears[allYears.length - 1];
  }, [portfolios]);

  const portfolioCompaniesForCurrentYear: Portfolio['portfolioCompanies'] =
    getPortfolioByYear(latestPortfolioYear ?? new Date().getFullYear()).flatMap(
      (object) => object.portfolioCompanies
    ) ?? [];

  const filteredPortfolios = useMemo(() => {
    if (!searchValue) return portfolios;
    return portfolios.filter((portfolio) =>
      portfolio.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, portfolios]);

  const portfolioCompanies = useMemo(() => {
    return portfolios.flatMap((object) => object.portfolioCompanies);
  }, [portfolios]);

  const companies = useMemo(() => {
    return uniqBy(portfolioCompanies, 'company.id');
  }, [portfolioCompanies]);

  const allocation = useMemo(() => {
    return portfolioCompanies.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.amount;
    }, 0);
  }, [portfolioCompanies]);

  const [isMax, isLarge, isMedium, isSmall] = useMediaQuery([
    '(width <= 1462px)',
    '(width <= 1452px)',
    '(width <= 1215px)',
    '(width <= 1132px)',
  ]);

  const summaryHeight = useMemo(() => {
    if (isSmall) {
      return SUMMARY_HEIGHT_MAP.max;
    }
    if (isMedium) return SUMMARY_HEIGHT_MAP.large;
    if (isLarge) {
      return SUMMARY_HEIGHT_MAP.medium;
    }
    if (isMax) {
      return SUMMARY_HEIGHT_MAP.small;
    }
    return SUMMARY_HEIGHT_MAP.default;
  }, [isMax, isLarge, isMedium, isSmall]);

  return (
    <ContentLayout
      isLoading={loading}
      header={<ContentHeader title={t('portfolio:portfolio_plural')} />}
    >
      {filteredPortfolios.length || searchValue ? (
        <>
          <HStack justifyContent="space-evenly" spacing="12px" height="100%" w="100%">
            <VStack
              width="100%"
              alignItems="stretch"
              justifyContent="flex-start"
              bg="bg.muted"
              borderRadius="8px"
              flex="1"
              height={summaryHeight}
              minHeight="174px"
            >
              <HStack pt="16px" pl="16px" width="100%">
                <Typography variant="h3">{t('portfolio:overview')}</Typography>
              </HStack>
              <VStack px="16px" mt="12px" alignItems="flex-start">
                <HStack mb="20px" spacing="85px">
                  <VStack alignItems="flex-start" spacing="0px">
                    <Typography variant="bodyLarge">{portfolios.length}</Typography>
                    <Typography variant="detail">{t('portfolio:portfolio_plural')}</Typography>
                  </VStack>
                  <VStack alignItems="flex-start" spacing="0px">
                    <Typography variant="bodyLarge">{companies.length}</Typography>
                    <Typography variant="detail">{t('portfolio:companies')}</Typography>
                  </VStack>
                </HStack>
                <VStack alignItems="flex-start" spacing="0px">
                  <Typography variant="bodyLarge">{formatDigits(allocation)}</Typography>
                  <Typography variant="detail">
                    {currentCompany?.currency} {t('portfolio:invested')}
                  </Typography>
                </VStack>
              </VStack>
            </VStack>
            <Box flex="3" height={summaryHeight}>
              <PortfolioSummary
                portfolioCompanies={portfolioCompaniesForCurrentYear}
                reportPeriod={reportPeriod}
              />
            </Box>
          </HStack>

          <HStack mt="42px" mb="2px" w="100%">
            <SearchInput
              placeholder={t('common:words.filter')}
              search={searchValue}
              setSearch={setSearchValue}
              minW="200px"
            />
            <Button variant="primary" onClick={() => setIsCreatingPortfolio(true)}>
              {t('portfolio:createButton')}
            </Button>
          </HStack>

          {filteredPortfolios.length ? (
            <PortfoliosList portfolios={filteredPortfolios} onEdit={(p) => setPortfolioToEdit(p)} />
          ) : (
            <Box
              w="100%"
              h={`calc(100vh - 248px - ${summaryHeight})`}
              alignItems="stretch"
              mt="16px"
            >
              <EmptyState
                title={t('common:search.filter.emptyTitle')}
                description={t('common:search.filter.emptyDescription')}
                callToAction={{
                  text: t('common:search.filter.emptyBtn'),
                  variant: 'secondary',
                  onClick: () => {
                    setSearchValue('');
                  },
                  leftIcon: <RefreshIcon color="inherit" />,
                }}
                icon={<NothingFoundIllustration boxSize="120px" />}
              />
            </Box>
          )}
        </>
      ) : (
        <Box w="100%" h={`calc(100vh - 152px)`}>
          <EmptyState
            title={t('portfolio:noPortfolio')}
            description={t('portfolio:noPortfolioDescription')}
            callToAction={{
              text: t('portfolio:createButton'),
              variant: 'primary',
              onClick: () => setIsCreatingPortfolio(true),
            }}
            icon={<PortfolioIllustration boxSize="120px" />}
          />
        </Box>
      )}
      <PortfolioEditorModal
        isOpen={isCreatingPortfolio || !!portfolioToEdit}
        onClose={() => {
          setIsCreatingPortfolio(false);
          setPortfolioToEdit(undefined);
        }}
        portfolio={portfolioToEdit}
      />
    </ContentLayout>
  );
};

export const Portfolios = () => {
  return (
    <Routes>
      <Route index element={<PortfoliosPage />} errorElement={<ErrorElement />} />
      <Route
        path=":portfolioId/:reportingYear/create"
        element={<PortfolioSettings isEditing={false} />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":portfolioId/:reportingYear/edit"
        element={<PortfolioSettings isEditing={true} />}
        errorElement={<ErrorElement />}
      />
      <Route path=":portfolioId/*" element={<PortfolioView />} errorElement={<ErrorElement />}>
        <Route path=":reportingYear/*" element={<PortfolioView />} errorElement={<ErrorElement />}>
          <Route path=":currentTab" element={<PortfolioView />} errorElement={<ErrorElement />} />
        </Route>
      </Route>
    </Routes>
  );
};
