import { Circle, HStack, VStack } from '@chakra-ui/react';
import { Structure } from '../Assessment/Structure';
import { EsrsReportData } from './Report.types';
import { ReportStructure } from './ReportStructure';
import { Infobox } from 'Atoms';
import { PieChart } from 'react-minimal-pie-chart';
import { Typography, colors } from 'Tokens';
import { useMemo } from 'react';
import { structureReportData } from './Report.hooks';

const CompletionProgressBox = ({
  numberOfCompletedDrs,
  numberOfDrs,
}: {
  numberOfCompletedDrs: number;
  numberOfDrs: number;
}) => {
  const completedPercentage = useMemo(
    () => Math.floor((numberOfCompletedDrs / numberOfDrs) * 100),
    [numberOfCompletedDrs, numberOfDrs]
  );
  return (
    <HStack
      gap="16px"
      bgColor="bg.info"
      border="1px solid"
      borderRadius="8px"
      borderColor="bg.info"
      padding="16px"
    >
      <Circle size="32px" alignItems="center">
        <PieChart
          lineWidth={16}
          data={[
            {
              title: 'Collected',
              value: completedPercentage,
              color: colors.bg.progress,
            },
            {
              title: 'Missing',
              value: 100 - completedPercentage,
              color: colors.bg.unknown,
            },
          ]}
        />
      </Circle>
      <VStack alignItems="stretch" gap="2px">
        <Typography variant="bodyStrong">Completion progress</Typography>
        <Typography variant="detail">
          {completedPercentage}%, {numberOfCompletedDrs}/{numberOfDrs} disclosures
        </Typography>
      </VStack>
    </HStack>
  );
};

export const ReportSideMenu = ({
  data,
  currentView,
}: {
  data: EsrsReportData;
  currentView: string;
}) => {
  const disclosures = useMemo(
    () =>
      data.categories.flatMap((cat) =>
        cat.standards.flatMap((std) => std.disclosureRequirements.map((dr) => dr))
      ),
    [data]
  );
  const completedDrs = useMemo(() => {
    return disclosures.filter((dr) => dr.metrics.every((metric) => metric.completed))?.length;
  }, [disclosures]);

  const nodes = structureReportData(data);
  return (
    <VStack
      alignItems="stretch"
      w="21%"
      gap="16px"
      overflowY="scroll"
      height="calc(100vh - 128px)"
      paddingTop="2px"
      css={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
        scrollbarWidth: 'none',
      }}
    >
      <CompletionProgressBox numberOfCompletedDrs={completedDrs} numberOfDrs={disclosures.length} />
      <Typography variant="overline">CONTENT</Typography>
      <ReportStructure nodes={nodes} currentView={currentView} />
    </VStack>
  );
};
