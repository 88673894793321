import { omit } from 'lodash';

export const Tuple = <T, K>(a: T, b: K) => [a, b] as [T, K];

export const upsertOmitMeta = (obj: any) => omit(obj, '__typename');

export const omitMetaOther = (obj: any, ...other: string[]) => omit(obj, '__typename', ...other);

export const omitMeta = (obj: any) => omit(obj, 'id', '__typename');

export const isValidBool = (val?: boolean | null) => {
  return val === true || val === false;
};
