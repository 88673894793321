import { HStack, Skeleton, Spinner, VStack } from '@chakra-ui/react';
import { TimePeriodsEnums } from '../Requirement';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { MetricsTableData } from './MetricAnswers.hooks';
import { useGetDatapointValues } from './InputTable/QuarterInput';
import { useDebounce } from 'usehooks-ts';
import { AutoResizeTextarea } from 'Atoms';
import { Typography } from 'Tokens';
import { WarningIcon } from 'Tokens/Icons/Status';
import { useLocation } from 'react-router-dom';

type TextMetricProps = {
  metric: MetricsTableData['metric'];
  companyReportingUnit?: string;
  autoExpandHeight?: boolean;
  isGeneratingAnswers?: boolean;
  setRowData: (param: MetricsTableData | undefined) => void;
};

const AIInfoWarning = () => {
  return (
    <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
      <WarningIcon color="text.warning" boxSize="18px"></WarningIcon>
      <Typography variant="body" color="text.warning">
        AI couldn’t find and add information about this and that to fulfill ESRS requirements.
        Please, try generating text again or add this information manually.
      </Typography>
    </HStack>
  );
};

export const LongTextMetricInput: FC<TextMetricProps> = ({
  metric,
  companyReportingUnit,
  autoExpandHeight = true,
  isGeneratingAnswers = false,
  setRowData,
}) => {
  const { hash } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { onDatapointChange, dataPointPerYear, answer, loading } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnit
  );
  const [localValue, setLocalValue] = useState(dataPointPerYear?.value);

  const debouncedValue = useDebounce(localValue, 500);

  const isIrrelevantResponse = useMemo(() => {
    return dataPointPerYear?.isAIGenerated && dataPointPerYear.value === '-';
  }, [dataPointPerYear]);

  useEffect(() => {
    if (debouncedValue !== dataPointPerYear?.value)
      onDatapointChange(
        debouncedValue ?? null,
        answer?.hasOptedOut ?? false,
        answer?.optOutReason ?? '',
        dataPointPerYear ?? { timeframe: TimePeriodsEnums.year }
      );
  }, [debouncedValue]);

  useEffect(() => {
    if (localValue !== dataPointPerYear?.value) setLocalValue(dataPointPerYear?.value);
  }, [dataPointPerYear?.value]);

  useEffect(() => {
    if (dataPointPerYear?.id === hash.slice(1) && openDrawer) {
      setRowData?.({ metric: metric });
    }
  }, [hash, dataPointPerYear, openDrawer]);

  // useEffect(() => {
  //   if (textareaRef.current && autoExpandHeight) {
  //     const tdHeight = textareaRef.current.parentElement?.parentElement?.clientHeight;
  //     textareaRef.current.style.height = tdHeight ? `${tdHeight - 10}px` : 'unset';
  //     textareaRef.current.style.minHeight = tdHeight ? `${tdHeight - 10}px` : 'unset';
  //   }
  // }, [textareaRef.current, loading]);

  const note = useMemo(
    () => ({
      id: dataPointPerYear?.id,
      notes: [
        {
          body: dataPointPerYear?.value,
          updatedAt: dataPointPerYear?.updatedAt,
          isAIGenerated: false,
          author: {
            id: dataPointPerYear?.authorId,
            email: dataPointPerYear?.author?.email,
            avatarUrl: dataPointPerYear?.author?.avatarUrl ?? '',
            displayName: dataPointPerYear?.author?.displayName ?? '',
          },
        },
      ],
    }),
    [dataPointPerYear, answer]
  );

  if (loading) {
    return <Skeleton height="20px" width="100%" />;
  }

  if (isGeneratingAnswers) {
    return (
      <HStack my="10px">
        <Spinner boxSize="16px" color="bg.brand.accent" />
        <Typography variant="body">AI is writing...</Typography>
      </HStack>
    );
  }

  return (
    <VStack w="100%" alignItems="start" spacing="8px">
      <AutoResizeTextarea
        variant="ghost"
        ref={textareaRef}
        borderRadius="6px"
        lineHeight="20px"
        size="md"
        placeholder="Write your answer"
        value={localValue ?? dataPointPerYear?.value ?? ''}
        opacity={answer?.hasOptedOut ? 0.4 : 1}
        onChange={({ target }) => {
          setLocalValue(target.value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
      {isIrrelevantResponse && <AIInfoWarning />}
    </VStack>
  );
};

export const MultipleLongTextInput = ({
  metric,
  companyReportingUnit,
  setRowData,
}: {
  metric: MetricsTableData['metric'];
  companyReportingUnit?: string;
  setRowData: (param: MetricsTableData | undefined) => void;
}) => {
  const childrenMetrics = useMemo(
    () => metric.childrenMetrics.filter((m) => m.childMetric?.metricType === 'LONG_TEXT'),
    [metric]
  );
  return (
    <VStack mt="14px" mb="24px" spacing="16px" alignItems="stretch">
      {childrenMetrics.map((qualMetric) => {
        if (!qualMetric.childMetric) return <Typography>--</Typography>;
        return (
          <VStack w="100%" spacing={0} key={qualMetric.childMetric.reference} alignItems="start">
            <LongTextMetricInput
              metric={qualMetric.childMetric}
              companyReportingUnit={companyReportingUnit}
              autoExpandHeight={false}
              setRowData={setRowData}
            />
          </VStack>
        );
      })}
    </VStack>
  );
};
