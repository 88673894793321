import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Collapse, HStack, VStack } from '@chakra-ui/react';
import { Button, IconButton, Tag } from 'Atoms';
import { Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { LinkIcon, PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { QuestionMarkIcon } from 'Tokens/Icons/Status';
import { useState } from 'react';
import {
  AggregatedMetricsTableData,
  COMPANY,
  MetricsTableData,
  MetricViewEnums,
} from '../DisclosureRequirements';
import { GetReportMaterialStandardsDocument_, useUpsertMaterialMetricsMutation } from 'models';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentCompany } from 'utils/hooks';
import { LastEditedBy } from '../DisclosureRequirements/Metrics/InputTable/LastEditedBy';

export enum CompletionStatus {
  complete = 'Completed',
  hidden = 'Hidden',
  incomplete = 'Incomplete',
}

export const COMPLETION_STATES = {
  [CompletionStatus.complete]: {
    bg: 'bg.success',
    icon: <CheckIcon color="text.success" boxSize="12px" />,
    text: 'Completed (all data points provided)',
  },
  [CompletionStatus.hidden]: {
    bg: 'bg.warning',
    icon: <PrivateIcon color="text.warning" />,
    text: 'Completed, but hidden from the report',
    textIncomplete: 'Incomplete and hidden from the report',
  },
  [CompletionStatus.incomplete]: {
    bg: 'bg.unknown',
    icon: <QuestionMarkIcon color="text.muted" />,
    text: 'Incomplete (not all data points provided)',
  },
};

export const toRomanNumeral = (num: number) => {
  const numbers = ['0', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  return numbers[num];
};

export const ReportStatusIcon = ({ status }: { status: CompletionStatus }) => {
  return (
    <VStack
      bg={COMPLETION_STATES[status].bg}
      borderRadius="4px"
      boxSize="20px"
      textAlign="center"
      boxSizing="border-box"
      justifyContent="center"
    >
      {COMPLETION_STATES[status].icon}
    </VStack>
  );
};

export const ReportStatusBar = ({
  status,
  metric,
  standardId,
  standardRef,
  standardName,
  completed,
  drRef,
  reportingUnitId,
  dataCollection,
  isMetricHidden,
  setHidden,
}: {
  status: CompletionStatus;
  metric: AggregatedMetricsTableData;
  standardId: string;
  standardRef: string;
  completed: boolean;
  standardName: string;
  isMetricHidden: boolean;
  drRef: string;
  dataCollection: string | null;
  reportingUnitId?: string;
  setHidden: (val: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { esrsAssessmentId = '' } = useParams();
  const [upsertMetric, { loading }] = useUpsertMaterialMetricsMutation();
  const company = useCurrentCompany();

  const hideShowMetric = () => {
    setHidden(!isMetricHidden);
    upsertMetric({
      variables: {
        objects: {
          id: metric.metric.materialMetrics[0].id,
          metricRef: metric.metric.reference,
          materialStandardId: standardId,
          isHidden: !isMetricHidden,
        },
      },
      refetchQueries: [GetReportMaterialStandardsDocument_],
    });
  };

  const editAnswer = () => {
    window.open(
      `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}#${metric.metric.reference}`
    );
  };

  return (
    <VStack w="100%" alignItems="start" gap="0px" userSelect="none">
      <HStack justifyContent="space-between" w="100%">
        <HStack cursor="pointer" onClick={() => setIsOpen(!isOpen)}>
          <ReportStatusIcon status={status} />
          <HStack gap="2px">
            <Typography variant="bodyStrong">{status}</Typography>
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </HStack>
        </HStack>
        <HStack gap="2px">
          <IconButton
            isLoading={loading}
            aria-label="hide"
            variant="ghost"
            size="sm"
            icon={isMetricHidden ? <PrivateIcon /> : <PublicIcon />}
            onClick={hideShowMetric}
          />
          <IconButton
            aria-label="edit"
            size="sm"
            variant="ghost"
            icon={<ArrowUpRightIcon />}
            onClick={editAnswer}
            tooltipMaxWidth="200px"
            tooltipLabel={
              dataCollection === COMPANY ? 'Open the source and edit' : 'Open the source'
            }
          />
        </HStack>
      </HStack>

      <Box p="0px">
        <Collapse in={isOpen} animateOpacity>
          <VStack alignItems="start" w="100%" p="8px 0px">
            <HStack gap="8px">
              <Typography variant="body" w="132px">
                Status:
              </Typography>
              <Typography variant="body">
                {status === CompletionStatus.hidden
                  ? completed
                    ? COMPLETION_STATES[status].text
                    : COMPLETION_STATES[status].textIncomplete
                  : COMPLETION_STATES[status].text}
              </Typography>
            </HStack>
            <HStack gap="8px">
              <Typography variant="body" w="132px">
                Source:
              </Typography>
              <Tag
                variant="ghost"
                bgColor="bg.interactive"
                borderRadius="4px"
                size="sm"
                cursor="pointer"
                padding="0px 4px"
                onClick={editAnswer}
              >
                <HStack gap="4px">
                  <LinkIcon />
                  <Typography variant="body">{standardName}</Typography>
                </HStack>
              </Tag>
            </HStack>
            <HStack gap="8px">
              <Typography variant="body" w="132px">
                Last edited:
              </Typography>
              <LastEditedBy
                row={metric}
                companyStandardId={standardId}
                selectedQuarter="Year"
                metricLevel={true}
                reportingUnitId={reportingUnitId ?? ''}
              />
            </HStack>
          </VStack>
        </Collapse>
      </Box>
    </VStack>
  );
};
