import { AdditionalTypeTag, Tag } from 'Atoms';
import { HStack, MenuButton } from '@chakra-ui/react';
import { HelpTooltip } from 'Molecules/HelpTooltip';
import { Menu } from 'Molecules/Menu';
import { useMemo } from 'react';
import { Typography } from 'Tokens';

interface Props {
  tags: string[];
  isHStack?: boolean;
  maxWidthInPixels?: number;
}

const Wrapper = ({ isHStack, children }: { isHStack: boolean; children: React.ReactNode }) => {
  if (isHStack) {
    return <HStack spacing="0px">{children}</HStack>;
  }
  return <span>{children}</span>;
};

export const MetricTagsList = ({ tags, isHStack = true, maxWidthInPixels }: Props) => {
  return (
    <Wrapper isHStack={isHStack}>
      {tags.slice(0, 2).map((tag, index) => (
        <Tag
          key={index}
          title={tag}
          variant="outlined"
          isRounded={false}
          mr="4px"
          p="2px 6px 2px 4px"
          w="max-content"
          mt="2px"
          truncatable={true}
          maxWidthInPixels={maxWidthInPixels}
        />
      ))}
      {tags?.length > 2 && (
        <Menu
          sections={[{ actions: tags.slice(2).map((tag) => ({ id: tag, title: tag })), title: '' }]}
          customMenuButton={
            <MenuButton
              as={Tag}
              clickable={true}
              key={2}
              variant="outlined"
              title={`+${tags.length - 2}`}
              isRounded={false}
              mt="2px"
              mr="4px"
              p="2px 6px 2px 4px"
              w="max-content"
            />
          }
        />
      )}
    </Wrapper>
  );
};

export const AdditionalTypesGroup = ({
  tagsWithHelp,
}: {
  tagsWithHelp: { reference: string; title: string; helpText: string }[];
}) => {
  const firstTag = useMemo(() => tagsWithHelp?.[0], [tagsWithHelp]);
  if (!tagsWithHelp.length) return <Typography>—</Typography>;
  return (
    <HStack w="fit-content" lineHeight="sm" spacing="4px">
      <AdditionalTypeTag key={1} label={firstTag.title} helpText={firstTag.helpText} />
      {tagsWithHelp.length > 1 && (
        <Menu
          sections={[
            {
              actions: tagsWithHelp.slice(1).map((tag) => ({
                id: tag.reference,
                title: tag.title ?? tag.reference,
                rightElement: <HelpTooltip label={tag.helpText} />,
              })),
            },
          ]}
          customMenuButton={
            <MenuButton
              as={Tag}
              clickable={true}
              key={2}
              px="10px"
              py="4px"
              h="28px"
              variant="outlined"
              borderRadius="4px"
            >
              <Typography variant="bodyStrong" w="fit-content">
                +{tagsWithHelp.length - 1}
              </Typography>
            </MenuButton>
          }
        />
      )}
    </HStack>
  );
};
