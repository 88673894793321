import { Box, VStack, Center, HStack, Skeleton, useDisclosure } from '@chakra-ui/react';
import { Button, ProgressBar, VisibleIf } from 'Atoms';
import { Typography } from 'Tokens';
import { Activity, ActivityAssessment, BAssessment, ResolvedQuestion } from 'models';
import { Loader } from 'Molecules';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'utils/translation';
import {
  GENERAL_ACTIVITY_REF,
  useActivityAssessmentWithAnswers,
  useActivityResult,
} from '../../containers/Assessments/Assessments.hooks';
import { ObjectiveList } from './ObjectiveList';
import { AlignmentStatus, AlignmentType } from 'Organisms/AlignmentStatus';
import { useActivityVersionComparison } from 'containers/Assessments/VersioningOfQuestions.hooks';
import { IsNewTag } from 'containers/Assessments/pieces/IsNewTag';
import { UpdatedQuestionModal } from 'containers/Assessments/pieces/UpdatedQuestionModal';
import { useCallback, useState } from 'react';

export const ACTIVITY_STATUSES = {
  todo: {
    color: 'text.muted',
    background: 'bg.unknown',
  },
  soFarCompliant: {
    color: 'text.success',
    background: 'bg.success',
  },
  compliant: {
    color: 'text.compliant',
    background: 'bg.compliant',
  },
  notCompliant: {
    color: 'text.notCompliant',
    background: 'bg.notCompliant',
  },
  noSubstantialContribution: {
    color: 'text.onAccent',
    background: 'bg.notCompliant.accent',
  },
  onlySecondarySubstantialContribution: {
    color: 'text.default',
    background: 'bg.unknown.accent',
  },
};

export const ActivitiesListItem = ({
  activity,
  activityAssessmentId,
}: {
  activity: Pick<Activity, 'name' | 'reference'>;
  activityAssessmentId: string;
}) => {
  const { businessUnitId, activityRef } = useParams();
  const navigate = useNavigate();
  const isSelected = activityRef === activity.reference;

  const { data, loading } = useActivityResult(activityAssessmentId);
  const { hasNewChanges } = useActivityVersionComparison(activityAssessmentId);

  return (
    <Box
      borderWidth="1px"
      borderColor={isSelected ? 'border.selected.accent' : 'border.default'}
      background={isSelected ? 'bg.selected' : 'bg.default'}
      padding="16px"
      borderRadius="4px"
      width="200px"
      cursor="pointer"
      _hover={{
        borderColor: isSelected ? 'border.selected.accent' : 'border.hover',
        background: isSelected ? 'bg.selected.hover' : 'bg.hover',
      }}
      onClick={() => navigate(`screening/${businessUnitId}/${activity.reference}`)}
    >
      <VStack alignItems="flex-start" spacing="16px">
        <HStack spacing="8px" alignItems="stretch" justifyContent="space-between" width="100%">
          <Typography variant="bodyStrong" maxWidth="fit-content" noOfLines={Infinity}>
            {activity.name}
          </Typography>
          {hasNewChanges && <IsNewTag marginTop="-8px" marginRight="-8px" />}
        </HStack>
        <Skeleton isLoaded={!loading} width="100%" justifyContent="stretch" height="36px">
          <VStack spacing="8px" width="100%" alignItems="flex-start">
            <HStack width="100%" justifyContent="stretch">
              <ProgressBar completed={data?.progress.screening || 0} />
            </HStack>
            {data?.status && (
              <AlignmentStatus
                variant="tag"
                type={
                  activity.reference === GENERAL_ACTIVITY_REF
                    ? AlignmentType.mss
                    : AlignmentType.activities
                }
                status={data.status}
              />
            )}
          </VStack>
        </Skeleton>
      </VStack>
    </Box>
  );
};

export const ActivitiesList = ({
  activities,
  isEditable = true,
  onClickManageActivities,
}: {
  activities: BAssessment['activityReports'];
  isEditable: boolean;
  onClickManageActivities: () => void;
}) => {
  const { t } = useTranslation('bUnits');
  return (
    <VStack alignItems="flex-start">
      <VisibleIf condition={isEditable}>
        <Button width="100%" onClick={onClickManageActivities}>
          {t('bUnits:activities.editActivities')}
        </Button>
      </VisibleIf>
      {activities.map((activityReport) => (
        <ActivitiesListItem
          activityAssessmentId={activityReport.id}
          key={activityReport.activity.reference}
          activity={activityReport.activity}
        />
      ))}
    </VStack>
  );
};

export const ActivityAssessmentQuestions = ({
  activityAssessmentId,
  isLocked,
}: {
  activityAssessmentId: ActivityAssessment['id'];
  isLocked: boolean;
}) => {
  const { data, loading } = useActivityAssessmentWithAnswers(activityAssessmentId);

  const {
    isOpen: isOpenUpdatedQuestionModal,
    onOpen: onOpenUpdatedQuestionModal,
    onClose: onCloseUpdatedQuestionModal,
  } = useDisclosure();

  const [updatedQuestion, setUpdatedQuestion] = useState<ResolvedQuestion>();

  const { hasNewChanges: hasNewActivityVersionChanges } =
    useActivityVersionComparison(activityAssessmentId);

  const openUpdatedQuestionModal = useCallback(
    (q: ResolvedQuestion) => {
      setUpdatedQuestion(q);
      onOpenUpdatedQuestionModal();
    },
    [setUpdatedQuestion]
  );

  return (
    <VStack width="100%" alignItems="stretch">
      {loading || !data.objectives ? (
        <Center width="60vw" height="50vh">
          <Loader />
        </Center>
      ) : (
        <ObjectiveList
          objectives={data.objectives}
          activityAssessmentId={activityAssessmentId}
          openUpdatedQuestionModal={openUpdatedQuestionModal}
          showVersionAlert={hasNewActivityVersionChanges}
          isLocked={isLocked}
        />
      )}
      <UpdatedQuestionModal
        isOpen={isOpenUpdatedQuestionModal}
        onClose={onCloseUpdatedQuestionModal}
        updatedQuestion={updatedQuestion}
        activityAssessmentId={activityAssessmentId}
      />
    </VStack>
  );
};
