import { VStack, HStack, useDisclosure, Box } from '@chakra-ui/react';
import { Button, EmptyState } from 'Atoms';
import { GroupAssessmentQuery_, GroupAssessmentSubsidiariesFieldsFragment_ } from 'models';
import { SearchInput } from 'Molecules';
import { useMemo, useState } from 'react';
import { NothingFoundIllustration } from 'Tokens';
import { CommentIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';
import { GroupCompaniesTable } from './GroupCompaniesTable';
import { SelectCompaniesModal } from './SelectCompaniesModal';

export const GroupCompanies = ({
  assessment,
}: {
  assessment: GroupAssessmentQuery_['assessment'];
}) => {
  const { t } = useTranslation(['group', 'common']);
  const [search, setSearch] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [showNudger, setShowNudger] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GroupAssessmentSubsidiariesFieldsFragment_[]>(
    []
  );

  const subsidiaries = useMemo(() => {
    return assessment?.subsidiaries;
  }, [assessment]);

  const filteredSubsidiaries = useMemo(() => {
    if (!search) return subsidiaries;
    return subsidiaries?.filter((subsidiary) =>
      subsidiary?.company?.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, subsidiaries]);

  return (
    <VStack p="16px">
      <HStack width="100%" justifyContent="space-between">
        <SearchInput search={search} setSearch={setSearch} placeholder={t('assessment.filter')} />
        <HStack>
          <Button
            variant="ghost"
            leftIcon={<CommentIcon color="inherit" />}
            onClick={() => {
              setSelectedRows(filteredSubsidiaries ?? []);
              setShowNudger(true);
            }}
          >
            {t('group:companies.reminder')}
          </Button>
          <Button variant="secondary" onClick={onOpen}>
            {t('group:companies.addSubsidiary')}
          </Button>
        </HStack>
      </HStack>
      {filteredSubsidiaries?.length ? (
        <GroupCompaniesTable
          subsidiaries={filteredSubsidiaries}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          showNudger={showNudger}
          setShowNudger={setShowNudger}
        />
      ) : (
        <Box w="100%" h={`calc(100vh - 255px)`}>
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            callToAction={{
              text: t('common:search.filter.emptyBtn'),
              variant: 'secondary',
              onClick: () => {
                setSearch('');
              },
              leftIcon: <RefreshIcon color="inherit" />,
            }}
            icon={<NothingFoundIllustration boxSize="120px" />}
          />
        </Box>
      )}
      <SelectCompaniesModal isOpen={isOpen} onClose={onClose} assessment={assessment} />
    </VStack>
  );
};
