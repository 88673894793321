import { Route, Routes } from 'react-router-dom';
import { ErrorElement } from 'App';
import { GroupsList } from './pieces';

export const Groups = () => {
  return (
    <Routes>
      <Route index element={<GroupsList />} errorElement={<ErrorElement />} />
    </Routes>
  );
};
