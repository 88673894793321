import { Tabs, IconButton, Tag, EmptyState, Alert, Link, VisibleIf } from 'Atoms';
import { NotFoundIllustration, Typography } from 'Tokens';
import {
  useUpdateAssessmentLockMutation,
  useCompanyAssessmentQuery,
  useInvestorsQuery,
} from 'models';
import { BackButton, ContentHeader, ContentLayout, Loader, AvatarGroup } from 'Molecules';
import { useMemo, useEffect, useCallback, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Screening } from 'Features/Screening';
import { Financials } from 'Features/Financials';
import { Results } from 'Features/Results';
import { Box, Button, Divider, HStack, useDisclosure } from '@chakra-ui/react';
import { stringToDate } from 'utils/date';
import { useTranslation } from 'utils/translation';
import { useCurrentCompanyId } from 'utils/hooks';
import { EditIcon, PrivateIcon } from 'Tokens/Icons/Function';
import { StatusSelector } from './StatusSelector';
import { uniq } from 'lodash';
import { ShareAssessmentModal } from './ShareAssessmentModal';
import { useShareCompanyAssessment, useUnshareCompanyAssessment } from 'containers/Investors';
import { NewVersionModal } from './NewVersionModal';
import { useActivityChangelog } from '../VersioningOfQuestions.hooks';

export type AccessStatusType = {
  assessmentId: string | null;
  portfolioCompanyId: string;
  portfolioId: string;
  year: number;
};

export const Assessment = () => {
  const { cAssessmentId } = useParams();
  const navigate = useNavigate();
  const { currentTab } = useParams();
  const { t } = useTranslation(['assessment', 'common', 'pai']);
  const { companyId } = useCurrentCompanyId();
  const [
    updateAssessmentLock,
    { loading: updateAssessmentLockLoading, error: updateAssessmentLockError },
  ] = useUpdateAssessmentLockMutation();

  const {
    isOpen: isOpenVersionModal,
    onClose: onCloseVersionModal,
    onOpen: onOpenVersionModal,
  } = useDisclosure();
  const {
    isOpen: isOpenShareModal,
    onClose: onCloseShareModal,
    onOpen: onOpenShareModal,
  } = useDisclosure();
  const { shareCompanyAssessment } = useShareCompanyAssessment();
  const { unshareCompanyAssessment } = useUnshareCompanyAssessment();
  const { data: investorsData } = useInvestorsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const investors = useMemo(() => {
    return investorsData?.investors ?? [];
  }, [investorsData]);

  const handleSaveChanges = async (updatedList: AccessStatusType[]) => {
    const changed = updatedList.filter(
      ({ portfolioCompanyId, portfolioId, assessmentId }) =>
        !investors.find(
          (investor) =>
            investor.portfolioId === portfolioId &&
            investor.id === portfolioCompanyId &&
            investor.sharedCompanyAssessmentId === assessmentId
        )
    );

    const added = changed.filter(({ assessmentId }) => assessmentId !== null);
    const removed = changed.filter(({ assessmentId }) => assessmentId === null);

    await Promise.all([
      ...added.map(({ portfolioCompanyId, portfolioId, assessmentId }) =>
        shareCompanyAssessment(assessmentId ?? '', portfolioCompanyId, portfolioId)
      ),
      ...removed.map(({ portfolioCompanyId, portfolioId }) =>
        unshareCompanyAssessment(portfolioCompanyId, portfolioId)
      ),
    ]).then(onCloseShareModal);
  };

  useEffect(() => {
    if (!currentTab) {
      navigate('screening', { replace: true });
    }
  }, [currentTab]);

  const {
    outdatedActivities,
    updatedActivities,
    skipActivityVersion,
    upgradeActivityVersion,
    // updateActivityVersionError,
    updateActivityVersionLoading,
    showActivityChangeModal,
    showActivityChangeBanner,
    isVersioningMigrationInProgress,
  } = useActivityChangelog(cAssessmentId);

  const handleCloseNewVersionModal = useCallback(() => {
    if (showActivityChangeModal) {
      skipActivityVersion()?.then(onCloseVersionModal);
    } else {
      onCloseVersionModal();
    }
  }, [onCloseVersionModal, skipActivityVersion]);
  const handleConfirmNewVersionModal = useCallback(() => {
    upgradeActivityVersion()?.then(onCloseVersionModal);
  }, [onCloseVersionModal, upgradeActivityVersion]);

  useEffect(() => {
    if (showActivityChangeModal) {
      onOpenVersionModal();
    }
  }, [showActivityChangeModal]);

  const { data, loading } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId,
    },
    skip: !cAssessmentId,
  });

  const cAssessment = useMemo(() => {
    return data?.companyAssessment;
  }, [data]);

  const unlockAssessment = () => {
    updateAssessmentLock({
      variables: { companyAssessmentId: cAssessment?.id, isLocked: false },
    });
  };

  const lockAssessment = () => {
    updateAssessmentLock({
      variables: { companyAssessmentId: cAssessment?.id, isLocked: true },
    });
  };

  if (loading) {
    return <Loader />;
  } else if (!cAssessment || !!cAssessment.deletedAt) {
    return (
      <Box w="100%" h={`calc(100vh - 64px)`} p="16px">
        <EmptyState
          title={t('common:notFound.title')}
          description={t('common:notFound.description')}
          callToAction={{
            text: t('common:notFound.button'),
            variant: 'primary',
            onClick: () => {
              navigate('/');
            },
          }}
          icon={<NotFoundIllustration boxSize="120px" />}
        />
      </Box>
    );
  }

  return (
    <ContentLayout
      header={
        <ContentHeader
          title={cAssessment.aggregate.title}
          size="md"
          backButton={<BackButton onClick={() => navigate(`/${companyId}/assessments`)} />}
          titleExtra={
            <HStack spacing="8px">
              <Tag variant="default">{stringToDate(cAssessment.startDate).getFullYear()}</Tag>
              <VisibleIf condition={!cAssessment.isLocked}>
                <IconButton
                  size="md"
                  variant="ghost"
                  icon={<EditIcon color="inherit" />}
                  aria-label={t('assessment:form.editAssessment')}
                  onClick={() => navigate('edit')}
                  tooltipLabel={t('assessment:form.editAssessment')}
                />
              </VisibleIf>
            </HStack>
          }
          actions={[
            <StatusSelector
              key="status-selector"
              companyAssessment={cAssessment}
              lockAssessment={lockAssessment}
              lockLoading={updateAssessmentLockLoading}
              lockError={updateAssessmentLockError}
              unlockAssessment={unlockAssessment}
            />,
            <Divider
              key="divider"
              orientation="vertical"
              width="2px"
              height="20px"
              borderRadius="3px"
              background="border.decorative"
              mr="4px !important"
            />,
            <Fragment key="sharing">
              {cAssessment.portfoliosWithAccess.length ? (
                <AvatarGroup
                  key="investor-svatars"
                  names={uniq(
                    investors
                      .filter((company) => company.sharedAssessment?.id === cAssessmentId)
                      .map((investor) => investor?.portfolio?.ownerCompany?.name)
                  )}
                />
              ) : (
                <IconButton
                  key="share-status"
                  size="md"
                  variant="ghost"
                  icon={<PrivateIcon />}
                  aria-label={t('assessment:notShared')}
                  backgroundColor="bg.unknown"
                  tooltipLabel={t('assessment:shared.tooltip')}
                  tooltipPlacement={'top-start'}
                />
              )}
            </Fragment>,
            <Button key="share-button" variant="primary" onClick={onOpenShareModal}>
              {t('pai:companyReport.shareResults')}
            </Button>,
            <ShareAssessmentModal
              key="share-assessment-modal"
              isOpen={isOpenShareModal}
              onClose={onCloseShareModal}
              investors={investors}
              cAssessmentId={cAssessmentId}
              handleSaveChanges={handleSaveChanges}
            />,
          ]}
        />
      }
      padChildren={false}
      isLoading={loading}
    >
      <VisibleIf condition={showActivityChangeBanner ?? false}>
        <HStack p="0px 16px 16px 16px">
          <Alert status="info" closable={false}>
            <HStack justifyContent="space-between" width="100%">
              <HStack>
                <Typography variant="body">
                  {isVersioningMigrationInProgress
                    ? 'New versions of questions have been added.'
                    : 'New versions of questions available.'}
                </Typography>
                <Typography
                  variant="bodyStrong"
                  color="text.info"
                  as={Link}
                  onClick={onOpenVersionModal}
                >
                  {isVersioningMigrationInProgress
                    ? 'See what has changed'
                    : 'Update version to stay compliant with the latest EU requirements'}
                </Typography>
              </HStack>
              <Button variant="ghost" onClick={onOpenVersionModal}>
                Learn more
              </Button>
            </HStack>
          </Alert>
        </HStack>
      </VisibleIf>
      <Tabs
        defaultIndex={0}
        currentTab={currentTab}
        onChange={(newTab) => navigate(`${newTab}`)}
        padList="16px"
        items={[
          {
            title: t('common:assessment.screening'),
            id: 'screening',
            content: <Screening cAssessment={cAssessment} />,
          },
          {
            title: t('common:assessment.financials'),
            id: 'financials',
            content: <Financials cAssessment={cAssessment} />,
          },
          {
            title: t('common:assessment.reportResults'),
            id: 'results',
            content: <Results cAssessment={cAssessment} />,
          },
        ]}
      />
      <NewVersionModal
        isOpen={isOpenVersionModal}
        onClose={handleCloseNewVersionModal}
        updatedActivities={updatedActivities}
        outdatedActivities={outdatedActivities}
        onConfirm={handleConfirmNewVersionModal}
        confirmButtonProps={{ isLoading: updateActivityVersionLoading }}
        cancelButtonProps={{ isLoading: updateActivityVersionLoading }}
      />
    </ContentLayout>
  );
};
