import { tablerIcon } from './Icons';
import {
  IconAdjustmentsHorizontal,
  IconArrowBackUp,
  IconCopy,
  IconDotsVertical,
  IconDownload,
  IconEye,
  IconBellRinging2,
  IconBroadcast,
  IconDoorExit,
  IconEyeOff,
  IconFilter,
  IconGripVertical,
  IconLock,
  IconMail,
  IconMaximize,
  IconMessage,
  IconMinimize,
  IconNote,
  IconPaperclip,
  IconPencil,
  IconPlus,
  IconRefresh,
  IconRotateRectangle,
  IconSearch,
  IconSettings,
  IconSortAscending,
  IconSortDescending,
  IconTrash,
  IconUpload,
  IconX,
  IconSparkles,
  IconPlayerPlay,
  IconMinus,
  IconLink,
} from '@tabler/icons-react';

export const MoreIcon = tablerIcon(IconDotsVertical);
export const SearchIcon = tablerIcon(IconSearch);
export const MaximizeIcon = tablerIcon(IconMaximize);
export const MinimizeIcon = tablerIcon(IconMinimize);

export const NotificationIcon = tablerIcon(IconBellRinging2);
export const CommentIcon = tablerIcon(IconMessage);
export const CopyIcon = tablerIcon(IconCopy);
export const MailIcon = tablerIcon(IconMail);

export const AddIcon = tablerIcon(IconPlus);
export const MinusIcon = tablerIcon(IconMinus);
export const EditIcon = tablerIcon(IconPencil);
export const SortIcon = tablerIcon(IconSortDescending);
export const SortAscendingIcon = tablerIcon(IconSortAscending);

export const AttachIcon = tablerIcon(IconPaperclip);
export const LinkIcon = tablerIcon(IconLink);
export const NoteIcon = tablerIcon(IconNote);
export const FilterIcon = tablerIcon(IconFilter);
export const DragIcon = tablerIcon(IconGripVertical);

export const SettingsIcon = tablerIcon(IconSettings);
export const AdjustIcon = tablerIcon(IconAdjustmentsHorizontal);
export const PublicIcon = tablerIcon(IconEye);
export const PrivateIcon = tablerIcon(IconEyeOff);

export const DownloadIcon = tablerIcon(IconDownload);
export const UploadIcon = tablerIcon(IconUpload);
export const LiveIcon = tablerIcon(IconBroadcast);
export const LockedIcon = tablerIcon(IconLock);

export const DeleteIcon = tablerIcon(IconTrash);
export const RemoveIcon = tablerIcon(IconX);
export const CancelIcon = tablerIcon(IconArrowBackUp);
export const RotateIcon = tablerIcon(IconRotateRectangle);

export const SignOutIcon = tablerIcon(IconDoorExit);
export const RefreshIcon = tablerIcon(IconRefresh);
export const PlayIcon = tablerIcon(IconPlayerPlay);

export const AIIcon = tablerIcon(IconSparkles);
