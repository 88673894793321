import { useUserData } from '@nhost/react';
import {
  useNotificationsSubscription,
  Notification,
  useReadUserNotificationsMutation,
} from 'models';
import { useEffect, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  getActivityQuestionLink,
  getBUDisclosureRequirementLink,
  getDisclosureRequirementLink,
} from 'utils/links';

const cachedNotifications = {
  default: { notifications: [] },
} as { [userId: string]: { notifications: Notification[] } };

export function useNotifications(): {
  notifications: Notification[];
  unreadNotificationsCount: number;
  totalNotificationsCount: number;
  readNotifications: (notificationIds: string[]) => void;
} {
  const userId = useUserData()?.id;
  const { data, loading } = useNotificationsSubscription();
  const [readNotificationsMutation] = useReadUserNotificationsMutation();
  // In case we already have notifications for that user (ie from previous mount of that component)
  // use them until new data arrives
  const notificationsResult: { notifications: Notification[] } =
    data ?? cachedNotifications[userId ?? ''] ?? cachedNotifications.default;

  useEffect(() => {
    if (!loading && userId && data) cachedNotifications[userId ?? ''] = data;
  }, [data, loading, userId]);

  const readNotifications = useCallback(
    (notificationIds: string[]) =>
      readNotificationsMutation({
        variables: {
          ids: notificationIds,
          isRead: true,
        },
      }),
    [readNotificationsMutation]
  );

  const notifications = useMemo(
    () =>
      notificationsResult.notifications
        .slice()
        .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt)),
    [notificationsResult]
  );

  const unreadNotificationsCount = useMemo(
    () => notificationsResult.notifications.filter(({ isRead }) => !isRead).length,
    [notificationsResult]
  );
  const totalNotificationsCount = useMemo(
    () => notificationsResult.notifications.length,
    [notificationsResult]
  );

  return {
    notifications,
    unreadNotificationsCount,
    totalNotificationsCount,
    readNotifications,
  };
}

export const getNotificationLink = ({
  isEsrs,
  companyId,
  assessmentId,
  businessUnitId,
  activityReference,
  questionId,
  standardRef,
  disclosureRequirement,
  esrsDatapointId,
  isCompanyLevel,
}: {
  isEsrs: boolean;
  companyId: string;
  assessmentId: string;
  businessUnitId: string;
  activityReference?: string;
  questionId?: string;
  standardRef?: string;
  disclosureRequirement?: {
    reference: string;
    type: string;
    group: {
      standardRef: string;
    };
  };
  esrsDatapointId: string;
  isCompanyLevel?: boolean;
}) => {
  const taxonomyLink = getActivityQuestionLink({
    companyId,
    assessmentId,
    businessUnitId,
    activityReference,
    questionId,
  });

  const esrsCompanyLevelLink = `${getDisclosureRequirementLink({
    companyId,
    assessmentId,
    standardRef,
    disclosureRequirement,
  })}/data-input?openDrawer=true#${esrsDatapointId}`;

  const esrsBULevelLink = `${getBUDisclosureRequirementLink({
    companyId,
    assessmentId,
    businessUnitId,
    standardRef,
    disclosureRequirement,
  })}/data-input?openDrawer=true#${esrsDatapointId}`;

  if (isEsrs) {
    if (isCompanyLevel) return esrsCompanyLevelLink;
    else return esrsBULevelLink;
  } else return taxonomyLink;
};
