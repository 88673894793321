import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Circle, Collapse, HStack, Tooltip, VStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { MouseEventHandler, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { CompletionStatus, ReportStatusIcon, toRomanNumeral } from './ReportUtils';

type StructureNode = {
  key: string;
  title: string;
  description?: string | null;
  numberOfAnswers: number;
  totalAnswers: number;
  isHidden?: boolean;
};
const ParentNode = ({
  node,
  isOpen,
  isVisible = true,
  setIsOpen,
  index,
  paddingLeft,
  currentView,
}: {
  node: StructureNode[];
  isOpen: boolean;
  isVisible?: boolean;
  setIsOpen: (param: boolean) => void;
  index?: number;
  currentView: string;
  paddingLeft?: string;
}) => {
  const scrollIntoView = () => {
    const element = document.getElementById(`${node[0].key}-${currentView}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  return (
    <Box width="100%">
      <Collapse in={isVisible} animateOpacity>
        <HStack
          onClick={scrollIntoView}
          cursor="pointer"
          paddingTop="8px"
          userSelect="none"
          paddingBottom="8px"
          width="100%"
          spacing="8px"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={paddingLeft}
        >
          {node[0].isHidden ? (
            <ReportStatusIcon status={CompletionStatus.hidden} />
          ) : node[0].numberOfAnswers === node[0].totalAnswers && node[0].totalAnswers !== 0 ? (
            <ReportStatusIcon status={CompletionStatus.complete} />
          ) : (
            <Tooltip label={`${node[0].numberOfAnswers}/${node[0].totalAnswers}`}>
              <Circle size="16px" alignItems="center">
                <PieChart
                  lineWidth={25}
                  data={[
                    {
                      title: 'Collected',
                      value: (node[0].numberOfAnswers / node[0].totalAnswers) * 100,
                      color: colors.bg.progress,
                    },
                    {
                      title: 'Missing',
                      value: 100 - (node[0].numberOfAnswers / node[0].totalAnswers) * 100,
                      color: colors.bg.unknown,
                    },
                  ]}
                />
              </Circle>
            </Tooltip>
          )}
          <VStack alignItems="stretch" gap="2px" w="100%" opacity={node[0].isHidden ? 0.3 : 1}>
            <Typography variant="bodyStrong" noOfLines={1}>
              {index !== undefined && `${toRomanNumeral(index + 1)}. `}
              {node[0].title}
            </Typography>
          </VStack>
          <IconButton
            variant="ghost"
            aria-label="OpenButton"
            size="xs"
            icon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
            onClick={() => setIsOpen(!isOpen)}
          />
        </HStack>
      </Collapse>
    </Box>
  );
};

const StructureItem = ({
  node,
  isVisible,
  paddingLeft,
  currentView,
}: {
  node: StructureNode;
  isVisible?: boolean;
  paddingLeft?: string;
  currentView: string;
}) => {
  const scrollIntoView = () => {
    const element = document.getElementById(`${node.key}-${currentView}`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  return (
    <Box width="100%">
      <Collapse in={isVisible} animateOpacity>
        <HStack
          paddingTop="8px"
          cursor="pointer"
          width="100%"
          paddingBottom="8px"
          spacing="8px"
          userSelect="none"
          alignItems="start"
          onClick={scrollIntoView}
          justifyContent="space-between"
          paddingLeft={paddingLeft}
        >
          {node.isHidden ? (
            <ReportStatusIcon status={CompletionStatus.hidden} />
          ) : node.numberOfAnswers === node.totalAnswers && node.totalAnswers !== 0 ? (
            <ReportStatusIcon status={CompletionStatus.complete} />
          ) : (
            <Tooltip label={`${node.numberOfAnswers}/${node.totalAnswers}`}>
              <Circle size="16px" alignItems="center">
                <PieChart
                  lineWidth={25}
                  data={[
                    {
                      title: 'Collected',
                      value: (node.numberOfAnswers / node.totalAnswers) * 100,
                      color: colors.bg.progress,
                    },
                    {
                      title: 'Missing',
                      value: 100 - (node.numberOfAnswers / node.totalAnswers) * 100,
                      color: colors.bg.unknown,
                    },
                  ]}
                />
              </Circle>
            </Tooltip>
          )}
          <VStack alignItems="stretch" gap="2px" w="100%" opacity={node.isHidden ? 0.3 : 1}>
            <Typography variant="bodyStrong" noOfLines={1}>
              {node.title}
            </Typography>
          </VStack>
        </HStack>
      </Collapse>
    </Box>
  );
};

const StandardNode = ({
  node,
  isCategoryOpen,
  currentView,
}: {
  node: StructureNode[];
  isCategoryOpen: boolean;
  currentView: string;
}) => {
  const [isStandardOpen, setIsStandardOpen] = useState(false);

  return (
    <>
      <ParentNode
        node={node}
        isVisible={isCategoryOpen}
        isOpen={isStandardOpen}
        setIsOpen={setIsStandardOpen}
        paddingLeft="24px"
        currentView={currentView}
      />
      {node.slice(1).map((drNode) => (
        <>
          <StructureItem
            node={drNode}
            isVisible={isStandardOpen && isCategoryOpen}
            paddingLeft="48px"
            currentView={currentView}
          />
        </>
      ))}
    </>
  );
};

const CategoryNode = ({
  node,
  index,
  currentView,
}: {
  node: StructureNode[][];
  index: number;
  currentView: string;
}) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);
  return (
    <>
      <ParentNode
        index={index}
        node={node[0]}
        isOpen={isCategoryOpen}
        setIsOpen={setIsCategoryOpen}
        currentView={currentView}
      />
      {node.slice(1).map((n) => (
        <StandardNode node={n} isCategoryOpen={isCategoryOpen} currentView={currentView} />
      ))}
    </>
  );
};

export const ReportStructure = ({
  nodes,
  currentView,
}: {
  nodes: StructureNode[][][];
  currentView: string;
}) => {
  return (
    <VStack alignItems="stretch" display="flex" p="0px" spacing="0px">
      {nodes.map((node, index) => (
        <CategoryNode index={index} node={node} currentView={currentView} />
      ))}
    </VStack>
  );
};
