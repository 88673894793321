import { Radio as ChakraRadio, ChakraTheme, RadioProps } from '@chakra-ui/react';

export const RADIO_STATES = ['default', 'defaultChecked', 'isDisabled', 'isChecked isDisabled'];
export type RadioState = (typeof RADIO_STATES)[number];

export const RadioTheme: ChakraTheme['components']['Radio'] = {
  baseStyle: {
    control: {
      bg: 'bg.default',
      borderRadius: '50%',
      borderWidth: '2px',
      borderColor: 'border.default',
      _hover: {
        bg: 'bg.default',
        borderColor: 'border.hover',
      },
      _checked: {
        borderColor: 'border.selected.accent',
        bg: 'bg.default',
        _before: {
          w: '8px',
          h: '8px',
          bg: 'bg.selected.accent',
        },
        _hover: {
          bg: 'bg.default',
          borderColor: 'border.selected.accent',
        },
      },
      _disabled: {
        border: 'none',
        bg: 'bg.disabled.accent',
        cursor: 'not-allowed',
        _hover: {
          bg: 'bg.disabled.accent',
        },
        _checked: {
          border: '2px',
          borderColor: 'bg.disabled.accent',
          bg: 'bg.default',
          _before: {
            w: '8px',
            h: '8px',
            bg: 'bg.disabled.accent',
          },
          _hover: {
            bg: 'bg.default',
            borderColor: 'bg.disabled.accent',
          },
        },
      },
    },
    label: {
      _disabled: {
        opacity: 1,
      },
    },
  },
  sizes: {
    md: {
      control: {
        h: '16px',
        w: '16px',
      },
    },
  },
};

export const Radio = (props: RadioProps) => {
  return <ChakraRadio {...props} />;
};
