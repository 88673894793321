import mixpanel from 'mixpanel-browser';
import {
  ActivityAssessmentAnswersDocument_,
  CompanyFilesDocument_,
  useRemoveFileMutation,
} from 'models';
import { useCallback } from 'react';
import { useCurrentCompanyId } from 'utils/hooks';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { nhost } from 'utils/nhost';

export function useDeleteFile() {
  const { companyId } = useCurrentCompanyId();
  const [deleteDocumentationFile] = useRemoveFileMutation({
    refetchQueries: [CompanyFilesDocument_, ActivityAssessmentAnswersDocument_],
    awaitRefetchQueries: true,
  });

  return useCallback(
    async ({ id, storageFileId }: { id: string; storageFileId: string }) => {
      mixpanel.track(TRACKING_EVENTS.DRIVE.DELETE, {
        companyId,
        fileId: id,
      });
      await nhost.storage.delete({ fileId: storageFileId });

      return deleteDocumentationFile({
        variables: { id },
      }).then(({ data }) => data?.file);
    },
    [deleteDocumentationFile]
  );
}
