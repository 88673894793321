import {
  GetDashboardMetricAnswersOnCompanyLevelQuery_,
  GetDashboardMetricAnswersOnGroupLevelQuery_,
  MaterialMetricWithChildrenFieldsFragment_,
} from 'models';
import { useMemo } from 'react';
import {
  CompanyDashboardDataType,
  EsrsAssessmentListProps,
  GroupDashboardDataType,
} from '../EsrsAssessmentList';
import { useMetricMateriality } from '../EsrsAssessment.hooks';
import {
  FrequencyEnums,
  QuartersObject,
  QUARTERS_FIELDS,
  TimePeriodsEnums,
  YearObject,
} from '../../DisclosureRequirements/Requirement';
import { addUpValues } from '../../DisclosureRequirements';

export type MaterialMetricType = MaterialMetricWithChildrenFieldsFragment_;
export type CompanyMetricAnswersDataType =
  GetDashboardMetricAnswersOnCompanyLevelQuery_['EsrsAssessment'][number]['reportingUnits'];
export type GroupMetricAnswersDataType =
  GetDashboardMetricAnswersOnGroupLevelQuery_['EsrsAssessment'][number]['subsidiaryAssessments'];
type MetricAnswer =
  GetDashboardMetricAnswersOnCompanyLevelQuery_['EsrsAssessment'][number]['reportingUnits'][number]['answers'][number] & {
    frequency?: string;
    hasTags?: boolean;
  };

const shouldIncludeMetricAnswer = (
  mAnswers: CompanyMetricAnswersDataType[number]['answers'][number],
  isGroup: boolean,
  materialMetric?: Partial<MaterialMetricType>,
  subsidiary?: GroupMetricAnswersDataType[number],
  standardRef?: string,
  childMaterialMetric?: Partial<MaterialMetricType>
) => {
  const subsidiaryMaterialityAssessment = subsidiary?.materialStandards.find(
    (assessment) => assessment.standard.reference === standardRef
  );

  const subsidiaryMaterialMetric = isGroup
    ? childMaterialMetric ??
      subsidiaryMaterialityAssessment?.materialMetrics.find(
        (mm) => mm.metric.reference === materialMetric?.metric?.reference
      )
    : childMaterialMetric ?? materialMetric;

  const materialDataCollection = subsidiaryMaterialMetric?.dataCollection;

  const shouldInclude =
    materialDataCollection === 'company'
      ? mAnswers.reportingUnit.isCompanyLevel
      : !mAnswers.reportingUnit.isCompanyLevel;

  const frequency = subsidiaryMaterialMetric?.frequency;
  const hasTags = !!subsidiaryMaterialMetric?.materialMetricTags?.length;
  return { shouldInclude, frequency, hasTags };
};

export const getAnswers = (
  reportingUnits: CompanyMetricAnswersDataType[number],
  isGroup: boolean,
  allMetricRefs: string[],
  materialMetric?: MaterialMetricType,
  allMaterialMetrics?: MaterialMetricType[],
  subsidiary?: GroupMetricAnswersDataType[number],
  standardRef?: string
) => {
  const allAnswersWithFrequency = allMetricRefs.flatMap((metricRef) => {
    const childMaterialMetric = allMaterialMetrics?.find((mm) => mm.metric.reference === metricRef);
    return reportingUnits.answers.map((mAnswers) => {
      const { shouldInclude, frequency, hasTags } = shouldIncludeMetricAnswer(
        mAnswers,
        isGroup,
        materialMetric,
        subsidiary,
        standardRef,
        childMaterialMetric
      );
      if (mAnswers.metricRef === metricRef && shouldInclude) {
        return { ...mAnswers, frequency: frequency, hasTags: hasTags };
      }
      return undefined;
    });
  });
  return allAnswersWithFrequency.filter((a) => a !== undefined) as MetricAnswer[];
};

export const useAggregatedMetricAnswers = (
  materialMetric?: MaterialMetricType,
  answers?: MetricAnswer[]
): QuartersObject | YearObject | undefined => {
  if (!!answers?.length) {
    const datapoints = answers.flatMap((answer) => {
      const isYearly = answer.frequency === FrequencyEnums.yearly;
      const getIsTimeframeYearly = (datapoint: MetricAnswer['datapoints'][number]) =>
        isYearly
          ? datapoint.timeframe === TimePeriodsEnums.year
          : datapoint.timeframe !== TimePeriodsEnums.year;

      if (answer.hasTags)
        return answer.datapoints.filter(
          (datapoint) => !!datapoint.datapointTags.length && getIsTimeframeYearly(datapoint)
        );
      else {
        return answer.datapoints.filter(
          (datapoint) => !datapoint.datapointTags.length && getIsTimeframeYearly(datapoint)
        );
      }
    });
    const isMetricYearly = materialMetric?.frequency === FrequencyEnums.yearly;

    if (isMetricYearly) {
      const datapointsPerYear = datapoints.map((dp) => Number(dp.value ?? 0));
      const total = addUpValues(datapointsPerYear);
      return { Q1: 0, Q2: 0, Q3: 0, Q4: 0, Year: total };
    }

    const totalPerTimeframe = QUARTERS_FIELDS.map((field) => {
      const datapointsPerTimeframe = datapoints
        ?.filter(
          (datapoint) =>
            datapoint.timeframe === field || datapoint.timeframe === TimePeriodsEnums.year
        )
        .map((dp) => Number(dp.value ?? 0));
      const total = addUpValues(datapointsPerTimeframe);
      return { [field]: total };
    });
    return Object.assign({}, ...totalPerTimeframe);
  }
  return undefined;
};

export const getMetricWithChildrenRefs = (metric: MaterialMetricType['metric'] | undefined) => {
  const childrenMetricRefs: string[] = [metric?.reference ?? ''];

  const recurseChildren = (childrenMetrics: MaterialMetricType['metric']['childrenMetrics']) => {
    if (!!childrenMetrics.length) {
      childrenMetrics.forEach((childMetric) => {
        childrenMetricRefs.push(childMetric.childMetric?.reference ?? '');
        recurseChildren(childMetric.childMetric?.childrenMetrics ?? []);
      });
    }
  };

  if (metric?.childrenMetrics) {
    recurseChildren(metric?.childrenMetrics);
  }
  const filteredMetrics = (childrenMetricRefs.filter((m) => m !== undefined) as string[]) ?? [];
  return filteredMetrics;
};

export const useAggregatedMetrics = (
  isGroup: boolean,
  materialMetric?: MaterialMetricType,
  companyMetricAnswersData?: CompanyMetricAnswersDataType,
  groupMetricAnswersData?: GroupMetricAnswersDataType,
  standardRef?: string,
  allMaterialMetrics?: MaterialMetricType[]
) => {
  const aggregatedMetrics = useMemo(() => {
    const metricAnswers = isGroup
      ? groupMetricAnswersData?.flatMap((subsidiary) =>
          subsidiary.reportingUnits?.flatMap((ru) => {
            const metricAndChildrenRefs = getMetricWithChildrenRefs(materialMetric?.metric);
            const childrenMaterialMetrics = allMaterialMetrics?.filter((m) =>
              metricAndChildrenRefs.includes(m.metric.reference)
            );
            return getAnswers(
              ru,
              isGroup,
              metricAndChildrenRefs,
              materialMetric,
              childrenMaterialMetrics,
              subsidiary,
              standardRef
            );
          })
        )
      : companyMetricAnswersData?.flatMap((ru) => {
          const metricAndChildrenRefs = getMetricWithChildrenRefs(materialMetric?.metric);
          const childrenMaterialMetrics = allMaterialMetrics?.filter((m) =>
            metricAndChildrenRefs.includes(m.metric.reference)
          );
          return getAnswers(
            ru,
            isGroup,
            metricAndChildrenRefs,
            materialMetric,
            childrenMaterialMetrics
          );
        });

    const aggregatedAnswers: QuartersObject | YearObject | undefined = useAggregatedMetricAnswers(
      materialMetric,
      metricAnswers
    );
    return aggregatedAnswers;
  }, [groupMetricAnswersData, companyMetricAnswersData, isGroup, standardRef]);

  return {
    aggregatedMetrics,
  };
};

export const useDashboardMetricsByStandards = (
  companyDashboardData: CompanyDashboardDataType,
  groupDashboardData: GroupDashboardDataType,
  isGroup: boolean
) => {
  const { groupStandards, companyStandards } = useMetricMateriality(
    groupDashboardData,
    companyDashboardData
  );

  const dashboardDataMap = useMemo(() => {
    const assessmentsMap = new Map<string, Map<string, EsrsAssessmentListProps[]>>();

    if (!isGroup) {
      companyDashboardData.forEach((assessment) => {
        if (!assessmentsMap.has(assessment.id))
          assessmentsMap.set(assessment.id, new Map<string, EsrsAssessmentListProps[]>());
        const map = new Map<string, EsrsAssessmentListProps[]>(assessmentsMap.get(assessment.id));
        assessment.materialStandards.forEach((ms) => {
          if (!map.has(ms.standard.title)) map.set(ms.standard.title, []);
          ms.materialMetrics.forEach((metric) => {
            map.set(ms.standard.title, [
              ...(map.get(ms.standard.title) ?? []),
              {
                name: metric.metric.title,
                materialMetric: ms.materialMetrics.find(
                  (m) => m.metric.reference === metric.metric.reference
                ),
                companyMetricAnswersData: assessment.reportingUnits,
                groupMetricAnswersData: undefined,
                standardRef: ms.standard.reference,
                isMaterialForSubsidiary: companyStandards.companyOnly.includes(
                  metric.metric.reference
                ),
                isMaterialForGroup: companyStandards.parentCompanyOnly.includes(
                  metric.metric.reference
                ),
                assessmentId: assessment.id,
                allMaterialMetrics: ms.materialMetrics,
              },
            ]);
          });
        });
        assessmentsMap.set(assessment.id, map);
      });
    } else {
      groupDashboardData.forEach((assessment) => {
        if (!assessmentsMap.has(assessment.id))
          assessmentsMap.set(assessment.id, new Map<string, EsrsAssessmentListProps[]>());
        const map = new Map<string, EsrsAssessmentListProps[]>(
          assessmentsMap.get(assessment.id) ?? []
        );
        assessment.materialStandards.forEach((ms) => {
          if (!map.has(ms.standard.title)) map.set(ms.standard.title, []);
          ms.materialMetrics.forEach((metric) => {
            map.set(ms.standard.title, [
              ...(map.get(ms.standard.title) ?? []),
              {
                name: metric.metric.title,
                materialMetric: ms.materialMetrics.find(
                  (m) => m.metric.reference === metric.metric.reference
                ),
                groupMetricAnswersData: assessment.subsidiaryAssessments,
                standardRef: ms.standard.reference,
                companyMetricAnswersData: undefined,
                isMaterialForGroup: groupStandards.groupOnly.includes(metric.metric.reference),
                isMaterialForSubsidiary: groupStandards.subsidiariesOnly.includes(
                  metric.metric.reference
                ),
                assessmentId: assessment.id,
                allMaterialMetrics: ms.materialMetrics,
              },
            ]);
          });
        });
        assessmentsMap.set(assessment.id, map);
      });

      groupDashboardData.forEach((assessment) => {
        if (!assessmentsMap.has(assessment.id))
          assessmentsMap.set(assessment.id, new Map<string, EsrsAssessmentListProps[]>());
        const map = new Map<string, EsrsAssessmentListProps[]>(
          assessmentsMap.get(assessment.id) ?? []
        );
        assessment.subsidiaryAssessments.forEach((sa) => {
          sa.materialStandards.forEach((sma) => {
            if (!map.has(sma.standard.title)) map.set(sma.standard.title, []);
            sma.materialMetrics.forEach((metric) => {
              if (groupStandards.groupOnly.includes(metric.metric.reference)) return;
              map.set(sma.standard.title, [
                ...(map.get(sma.standard.title) ?? []),
                {
                  name: metric.metric.title,
                  materialMetric: sma.materialMetrics.find(
                    (m) => m.metric.reference === metric.metric.reference
                  ),
                  groupMetricAnswersData: assessment.subsidiaryAssessments,
                  standardRef: sma.standard.reference,
                  companyMetricAnswersData: undefined,
                  isMaterialForGroup: false,
                  isMaterialForSubsidiary: groupStandards.subsidiariesOnly.includes(
                    metric.metric.reference
                  ),
                  assessmentId: assessment.id,
                  allMaterialMetrics: sma.materialMetrics,
                },
              ]);
            });
          });
        });
        assessmentsMap.set(assessment.id, map);
      });
    }

    return assessmentsMap;
  }, [groupDashboardData, companyDashboardData, isGroup, groupStandards, companyStandards]);

  return dashboardDataMap;
};
