import { Box } from '@chakra-ui/react';
import { EmptyState } from 'Atoms';
import { GroupAssessmentQuery_, useCompanyAssessmentQuery } from 'models/__generated__/graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EUTaxonomyIllustration } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { Loader } from 'Molecules';
import { Results } from 'Features/Results';

export const ConsolidatedResults = ({
  assessment,
}: {
  assessment: GroupAssessmentQuery_['assessment'];
}) => {
  const { t } = useTranslation('group');
  const navigate = useNavigate();

  const { data: companyAssessment, loading } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId: assessment?.consolidatedCompanyAssessmentId ?? '',
    },
    skip: !assessment?.consolidatedCompanyAssessmentId,
  });

  const cAssessment = useMemo(() => {
    return companyAssessment?.companyAssessment;
  }, [companyAssessment, assessment]);

  if (loading) return <Loader isLoaded={!loading} />;

  if (!!cAssessment?.cachedResultId) {
    return <Results cAssessment={cAssessment} isGroup={true} />;
  }

  return (
    <Box w="100%" h={`calc(100vh - 178px)`} p="16px">
      <EmptyState
        title={t('results.empty.title')}
        description={t('results.empty.description')}
        callToAction={{
          text: t('results.empty.action'),
          variant: 'secondary',
          onClick: () => {
            navigate('financials');
          },
        }}
        icon={<EUTaxonomyIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};
