import { Alert as ChakraAlert, AlertTitle, ChakraTheme, IconProps, HStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms/Buttons';
import { InfoboxVariant } from 'Atoms/Infobox';
import { Typography } from 'Tokens';
import React from 'react';
import { CheckIcon, DangerIcon, InfoIcon, WarningIcon } from 'Tokens/Icons/Status';
import { RemoveIcon } from 'Tokens/Icons/Function';

export type AlertStatus = 'critical' | 'success' | 'warning' | 'neutral' | 'info' | 'highlight';
export const ALERT_COLORS: { [key: string]: { [key: string]: string } } = {
  critical: {
    bg: 'bg.critical',
    color: 'text.default',
    border: 'bg.critical',
  },
  success: {
    bg: 'bg.success',
    color: 'text.default',
    border: 'bg.success',
  },
  warning: {
    bg: 'bg.warning',
    color: 'text.default',
    border: 'bg.warning',
  },
  neutral: {
    bg: 'bg.unknown.muted',
    color: 'text.default',
    border: 'bg.unknown',
  },
  info: {
    bg: 'bg.info',
    color: 'text.default',
    border: 'bg.info',
  },
  highlight: {
    bg: 'bg.default',
    color: 'text.default',
    border: 'border.selected.accent',
  },
};

const AlertIcon = ({
  status,
  withIcon = true,
  ...props
}: IconProps & {
  status: AlertStatus;
  withIcon?: boolean;
}) => {
  if (!withIcon) return null;
  if (status === 'critical') {
    return <DangerIcon {...props} color="text.critical" />;
  }
  if (status === 'warning') {
    return <WarningIcon {...props} color="text.warning" />;
  }
  if (status === 'success') {
    return <CheckIcon {...props} color="text.success" />;
  }
  if (status === 'neutral') {
    return <InfoIcon {...props} color="text.muted" />;
  }
  if (status === 'info' || status === 'highlight') {
    return <InfoIcon {...props} color="text.info" />;
  }
  return null;
};

export const AlertTheme: ChakraTheme['components']['Alert'] = {
  baseStyle: (props) => {
    return {
      container: {
        backgroundColor: ALERT_COLORS[props.colorScheme]?.bg,
        h: '36px',
        minW: '120px',
        px: '8px',
        py: '8px',
        border: '1px',
        borderColor: ALERT_COLORS[props.colorScheme]?.border,
        borderRadius: '8px',
      },
      title: {
        color: ALERT_COLORS[props.colorScheme]?.color,
      },
    };
  },
  variants: InfoboxVariant,
};

export type AlertProps = {
  status: AlertStatus;
  title?: string;
  withIcon?: boolean;
  customIcon?: React.ReactNode;
  description?: string;
  closable?: boolean;
  textColor?: string;
  width?: string;
  onCloseClick?: () => void;
  children?: React.ReactNode[] | React.ReactNode | string;
};
export const Alert = ({
  status,
  customIcon,
  withIcon,
  textColor,
  closable = true,
  width,
  onCloseClick,
  children,
  ...props
}: AlertProps) => {
  return (
    <ChakraAlert
      display="flex"
      justifyContent="space-between"
      colorScheme={status}
      status="success"
      width={width}
      height="full"
      {...props}
    >
      <HStack spacing={props.title ? '8px' : '0px'}>
        {customIcon ?? <AlertIcon status={status} withIcon={withIcon} display="flex" />}
        <AlertTitle>
          <Typography variant="body" color={textColor ?? 'currentColor'} wordBreak="break-word">
            {props.title}
          </Typography>
        </AlertTitle>
      </HStack>
      {children}
      {closable && (
        <IconButton
          variant="ghost"
          aria-label="CloseButton"
          size="sm"
          icon={<RemoveIcon color="inherit" />}
          onClick={onCloseClick}
        />
      )}
    </ChakraAlert>
  );
};
