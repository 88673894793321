import { Box, CircularProgress, HStack, VStack, Wrap } from '@chakra-ui/react';
import { EmptyState, Tag, Tooltip } from 'Atoms';
import {
  ContentHeader,
  ContentLayout,
  Loader,
  Select,
  TOP_MENU_HEIGHT_PLUS_PADDING,
} from 'Molecules';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivitiesIllustration, Typography } from 'Tokens';
import { CompanyBothIcon, CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { getSubsidiaryStandardsOverview } from './AssessmentOverview.hooks';

export enum MaterialTopicFilter {
  ALL = 'all',
  MATERIAL = 'material',
  NOT_MATERIAL = 'not-material',
}

export const MATERIAL_TOPIC_FILTER_OPTIONS = [
  {
    label: 'All available data',
    description: 'Topics that were assessed as material by a parent company and by subsidiaries',
    icon: <CompanyBothIcon />,
    value: MaterialTopicFilter.ALL,
  },
  {
    label: 'Only material topics',
    description: 'Topics that were assessed as material by a parent company (this company)',
    icon: <CompanyTopDownIcon />,
    value: MaterialTopicFilter.MATERIAL,
  },
  {
    label: 'Only not material topics',
    description: 'Topics that were assessed as material by subsidiaries',
    icon: <CompanyBottomUpIcon />,
    value: MaterialTopicFilter.NOT_MATERIAL,
  },
];

export const EsrsAssessmentOverview = ({
  esrsAssessmentId,
  companyName,
}: {
  esrsAssessmentId: string;
  companyName: string;
}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('all');

  const { categories, loading } = getSubsidiaryStandardsOverview(filter, esrsAssessmentId);
  const { categories: allCategories, loading: allCategoriesLoading } =
    getSubsidiaryStandardsOverview('all', esrsAssessmentId);

  const isMaterialityMatching = useMemo(() => {
    const allStandards = allCategories.flatMap((category) => category.materialStandards);
    return allStandards.every(
      (standard) => standard.isMaterialForGroup === standard.isMaterialForSubsidiary
    );
  }, [allCategories]);

  const noStandardsToShow = useMemo(
    () => categories.filter((c) => c.materialStandards.length).length === 0,
    [categories]
  );

  if (loading || allCategoriesLoading) return <Loader />;

  return (
    <>
      {noStandardsToShow ? (
        <Box w="100%" h="100%" p="16px">
          <EmptyState
            title="Start with materiality assessment"
            description="Add material standards, disclosure requirements and metrics to get started"
            icon={<ActivitiesIllustration boxSize="120px" />}
            callToAction={{
              text: 'Begin assessment',
              variant: 'primary',
              onClick: () => navigate('settings'),
            }}
          />
        </Box>
      ) : (
        <ContentLayout
          isLoading={loading}
          header={<ContentHeader size="md" title={`${companyName} overview`} />}
          contentProps={{ minH: `calc(100vh - ${TOP_MENU_HEIGHT_PLUS_PADDING + 64})` }}
        >
          <VStack alignItems="start" spacing="26px">
            {/* {!isMaterialityMatching && (
          <Box width="280px">
            <Select<{ value: string; label: string }>
              value={{
                label: MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === filter)?.label ?? '',
                value: filter,
              }}
              onChange={(value) => setFilter(value?.value ? value.value : 'all')}
              options={MATERIAL_TOPIC_FILTER_OPTIONS.map((currOption) => {
                return {
                  label: currOption?.label ?? '',
                  value: currOption.value,
                };
              })}
              size="md"
            />
          </Box>
        )} */}

            {categories.map((category, categoryIndex) => (
              <>
                {category.materialStandards.length > 0 && (
                  <VStack key={category.categoryRef} alignItems="start">
                    <Typography variant="overline">{category.categoryName}</Typography>
                    <Wrap overflow="visible" spacing="16px">
                      {category.materialStandards.map((standard, standardIndex, standardsArr) => (
                        <>
                          <VStack
                            w="300px"
                            shadow="md"
                            borderRadius="10px"
                            p="16px"
                            gap="8px"
                            alignItems="start"
                            minHeight="128px"
                            _hover={{ bg: 'bg.hover' }}
                            cursor="pointer"
                            key={standard.standardRef}
                            onClick={() => {
                              navigate(`standard/${standard.standardRef}`);
                            }}
                            justifyContent="space-between"
                            {...(categoryIndex === 0 &&
                            (standardsArr.length > 1 ? standardIndex === 1 : standardIndex === 0)
                              ? { className: 'walktour-standard-overview' }
                              : {})}
                          >
                            <VStack spacing="2px" alignItems="start">
                              <Typography variant="h4">{standard.standardName}</Typography>
                            </VStack>
                            <HStack spacing="10px">
                              {standard.isMaterialForGroup && !standard.isMaterialForSubsidiary && (
                                <Tag variant="default" borderRadius="4px">
                                  <Tooltip label="Assessed as material by the parent company">
                                    <CompanyTopDownIcon boxSize="16px" />
                                  </Tooltip>
                                </Tag>
                              )}
                              <HStack spacing="6px">
                                <CircularProgress size="24px" color="text.info" value={0} />
                                <Typography variant="body">{`0%`}</Typography>
                              </HStack>
                            </HStack>
                          </VStack>
                        </>
                      ))}
                    </Wrap>
                  </VStack>
                )}
              </>
            ))}
          </VStack>
        </ContentLayout>
      )}
    </>
  );
};
