import { Box, HStack, VStack } from '@chakra-ui/react';
import { Checkbox } from 'Atoms';
import _ from 'lodash';
import { GetActionsQuery_ } from 'models';
import { useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  Legend,
  Line,
  ReferenceLine,
} from 'recharts';
import { colors, Typography } from 'Tokens';
import { LocalMilestoneFields } from '../Requirement';
import { getCalculatedCompanyValues } from './Milestones/Milestones.hooks';

const CheckboxCard = ({
  label,
  color,
  isChecked,
  setIsChecked,
}: {
  label: string;
  color: string;
  isChecked: boolean;
  setIsChecked: (param: boolean) => void;
}) => (
  <HStack py="12px" borderBottom="1px solid" borderColor="border.decorative" w="100%">
    <Checkbox
      isChecked={isChecked}
      onChange={(e) => setIsChecked(e.target.checked)}
      variant={color}
    />
    <Typography variant="body">{label}</Typography>
  </HStack>
);

const customTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  payload: { [key: string]: any }[];
  label: string;
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Box
        bg="white"
        border="1px solid #ccc"
        borderColor="bg.muted"
        p="10px"
        boxShadow="2px 2px 5px rgba(0, 0, 0, 0.1)"
        fontSize="14px"
      >
        {data?.target && (
          <Typography variant="body" color="text.muted">
            target : {data.target}
          </Typography>
        )}
        {!data?.target && !!data?.interpolated && (
          <Typography variant="body" color="text.muted">
            interpolation : {data.interpolated}
          </Typography>
        )}
        {data?.action && (
          <Typography variant="body" color="text.muted">
            action : {data.action}
          </Typography>
        )}
        <Typography variant="body" color="text.muted">
          year : {label}
        </Typography>
      </Box>
    );
  }
  return null;
};

const calculateTarget = (companyBaseline: number, percentage: number) => {
  const percentageModifier = percentage > 0 ? 1 : -1;
  return companyBaseline + (companyBaseline * percentageModifier * Math.abs(percentage)) / 100;
};

const getAggregatedEstimatedActions = (
  targetId: string,
  actions?: GetActionsQuery_['actions'],
  selectedActionsIds?: string[],
  parentActions?: GetActionsQuery_['actions'],
  parentTargetId?: string,
  baseline?: number,
  baselineYear?: number
) => {
  const selectedActions = selectedActionsIds?.map((id) =>
    actions?.find((action) => action.id === id)
  );

  const actionEstimateWithYears =
    selectedActions?.map((action) => ({
      start: new Date(action?.startDate).getFullYear(),
      end: new Date(action?.deadline).getFullYear(),
      estimate: action?.actionTargets.find((at) => at.target.id === targetId)?.estimate,
    })) ?? [];

  const parentActionEstimateWithYears =
    parentActions?.map((action) => ({
      start: new Date(action?.startDate).getFullYear(),
      end: new Date(action?.deadline).getFullYear(),
      estimate: action?.actionTargets.find((at) => at.target.id === parentTargetId)?.estimate,
    })) ?? [];

  const estimateWithYears = [...actionEstimateWithYears, ...parentActionEstimateWithYears];

  const uniqueYears = [
    ...new Set(estimateWithYears?.flatMap((action) => [action.start, action.end])),
  ]
    ?.filter((year) => year !== undefined && year !== null)
    .sort((a, b) => a - b);

  const allYears: number[] = [];
  for (let y = uniqueYears[0]; y <= uniqueYears[uniqueYears.length - 1]; y++) {
    allYears.push(y);
  }

  const aggregatedActions = allYears.map((year) => {
    const concernedActions = estimateWithYears.filter((action) => action.start <= year);
    const yearTotal = concernedActions.reduce((acc, action) => {
      const actionYears = allYears.slice(
        allYears.indexOf(action.start),
        allYears.indexOf(action.end) + 1
      );
      const yearIndex = action.end < year ? actionYears.length : actionYears.indexOf(year) + 1;

      const currEstimate = (yearIndex / (action.end - action.start + 1)) * action.estimate;
      return acc + currEstimate;
    }, baseline ?? 0);
    return {
      year,
      action: yearTotal,
    };
  });

  const aggregatedActionsWithBaselineYear =
    aggregatedActions.some((data) => data.year === baselineYear) || !baselineYear
      ? aggregatedActions
      : [
          {
            year: baselineYear,
            action: baseline,
          },
          ...aggregatedActions,
        ];

  return aggregatedActionsWithBaselineYear;
};

export const TargetGraph = ({
  localAnswers,
  baseline,
  isGroup,
  showControls = true,
  chartHeight,
  marginBottom,
  actions,
  parentActions,
  targetId,
  parentTargetId,
}: {
  localAnswers: any;
  baseline: number;
  showControls?: boolean;
  chartHeight?: number;
  marginBottom?: number;
  isGroup: boolean;
  actions?: GetActionsQuery_['actions'];
  parentActions?: GetActionsQuery_['actions'];
  targetId: string;
  parentTargetId?: string;
}) => {
  const [showMilestones, setShowMilestones] = useState(true);
  const [showBaseline, setShowBaseline] = useState(true);
  const [showInterpolated, setShowInterpolated] = useState(true);
  const [showActions, setShowActions] = useState(true);

  const sortedMilestones = useMemo(
    () =>
      _.sortBy(
        localAnswers.milestones?.map((m: LocalMilestoneFields) => ({
          year: m.year,
          value: m.value ?? 0,
          subsidiaries: m.subsidiaries,
        })),
        'year'
      ) ?? [],
    [baseline, localAnswers]
  );

  const initialGraphArray: { year?: number; target?: number }[] = useMemo(
    () =>
      [
        baseline
          ? {
              year: new Date(localAnswers?.baseYear).getFullYear(),
              target: baseline,
              action: baseline,
            }
          : {},
        ...sortedMilestones?.map((m) => {
          return {
            year: m.year,
            target: calculateTarget(
              baseline,
              isGroup
                ? getCalculatedCompanyValues(true, baseline, m.value, m.subsidiaries, localAnswers)
                    .percentage
                : m.value
            ),
          };
        }),
      ] ?? [],
    [baseline, localAnswers]
  );

  function interpolateMissingYears(data: { year?: number; target?: number; action?: number }[]) {
    const filteredData = data?.filter((d) => Object.keys(d).length !== 0);
    const interpolatedData: {
      year?: number;
      target?: number;
      interpolated?: number;
      action?: number;
    }[] = [];

    filteredData.forEach((currentData, index, arr) => {
      const currentYear = currentData?.year ?? 0;
      const currentValue = currentData?.target ?? 0;

      interpolatedData.push({
        year: currentYear,
        target: currentValue,
        interpolated: currentValue,
        ...(currentData?.action && { action: currentData.action }),
      });

      if (index < arr.length - 1) {
        const nextData = arr[index + 1];
        const nextYear = nextData?.year ?? 0;
        const nextValue = nextData?.target ?? 0;

        const yearDifference = nextYear - currentYear;
        const valueDifference = nextValue - currentValue;

        for (let year = currentYear + 1; year < nextYear; year++) {
          const yearFraction = (year - currentYear) / yearDifference;
          const interpolatedValue = currentValue + yearFraction * valueDifference;

          interpolatedData.push({
            year: year,
            interpolated: interpolatedValue,
          });
        }
      }
    });
    return interpolatedData;
  }

  const estimatedActions = useMemo(
    () =>
      getAggregatedEstimatedActions(
        targetId,
        actions,
        localAnswers.actions?.filter((action: string | undefined) => action !== undefined),
        parentActions,
        parentTargetId,
        baseline,
        new Date(localAnswers?.baseYear).getFullYear()
      ),
    [actions, localAnswers, targetId]
  );

  const targetGraphData = useMemo(
    () => interpolateMissingYears(initialGraphArray),
    [initialGraphArray]
  );

  const graphData = useMemo(() => {
    const targetAndActionData = targetGraphData.map((data) => {
      const action = estimatedActions.find((a) => a.year === data.year);
      if (action)
        return { ...data, action: action.action, year: `\`${String(data.year)?.slice(-2)}` };
      return { ...data, year: `\`${String(data.year)?.slice(-2)}` };
    });

    const actionOnlyData = estimatedActions
      ?.filter((a) => !targetGraphData.some((t) => t.year === a.year))
      .map((action) => ({
        action: action.action,
        year: action.year ? `\`${String(action.year)?.slice(-2)}` : '',
      }));

    return _.sortBy([...targetAndActionData, ...actionOnlyData], 'year');
  }, [estimatedActions, targetGraphData]);

  return (
    <VStack alignItems="start" w="100%" h="100%" pt="8px">
      <ResponsiveContainer width="100%" height={chartHeight ?? 300}>
        <LineChart
          width={400}
          height={300}
          data={graphData}
          margin={{
            top: 8,
            right: 10,
            left: 15,
            bottom: marginBottom ?? 8,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" style={{ fontSize: '14px', color: colors.border.decorative }} />
          <Tooltip content={customTooltip} />
          <Legend />
          {showInterpolated && (
            <Line
              type="linear"
              dataKey="interpolated"
              stroke={colors.text['info.accent']}
              fill={colors.text['info.accent']}
              dot={{ r: 3 }}
              legendType="none"
              isAnimationActive={false}
              connectNulls={true}
            />
          )}
          {showMilestones && (
            <Line
              type="linear"
              dataKey="target"
              stroke={colors.text['info.accent']}
              fill={colors.text.info}
              dot={{ r: 4, stroke: colors.text.info }}
              activeDot={{ r: 7 }}
              legendType="none"
              isAnimationActive={false}
              connectNulls={true}
            />
          )}
          {showActions && (
            <Line
              type="linear"
              dataKey="action"
              stroke={colors.bg['compliant.accent']}
              fill={colors.bg['compliant.accent']}
              activeDot={{ r: 4 }}
              legendType="none"
              isAnimationActive={false}
              connectNulls={true}
            />
          )}
          {showBaseline && (
            <ReferenceLine
              y={baseline}
              label="Baseline"
              stroke={colors.bg['base.progress']}
              strokeDasharray="4 4"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      {showControls && (
        <VStack spacing="0px" width="100%" alignItems="start">
          <CheckboxCard
            label="Milestones"
            color="text.info"
            isChecked={showMilestones}
            setIsChecked={setShowMilestones}
          />
          <CheckboxCard
            label="Interpolated values"
            color="text.info.accent"
            isChecked={showInterpolated}
            setIsChecked={setShowInterpolated}
          />
          <CheckboxCard
            label="Estimated impact from actions"
            color="bg.compliant.accent"
            isChecked={showActions}
            setIsChecked={setShowActions}
          />
          <CheckboxCard
            label="Baseline"
            color="bg.base.progress"
            isChecked={showBaseline}
            setIsChecked={setShowBaseline}
          />
        </VStack>
      )}
    </VStack>
  );
};
