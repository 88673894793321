import { Wrap } from '@chakra-ui/react';
import { Tag } from 'Atoms';
import { sortBy } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'utils/translation';

const TAG_WITH_MARGINS_HEIGHT = 32;

export function TruncatedTagList({ tags, lines }: { tags: string[]; lines: number }) {
  const [trunc, setTrunc] = useState(0);
  const { t } = useTranslation('common');
  useEffect(() => {
    setTrunc(0);
  }, [tags.length]);
  const allTags = sortBy(tags, 'length');
  const truncated = trunc ? allTags.slice(-trunc).join('; ') : '';
  if (trunc) allTags.splice(-trunc, trunc, t('common:tags.countMore', { trunc }));

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if ((ref.current?.offsetHeight ?? 0) > lines * TAG_WITH_MARGINS_HEIGHT) {
      setTrunc((tr) => tr + 1);
    }
  }, [ref.current?.lastChild?.textContent]);

  return (
    <Wrap ref={ref}>
      {allTags.map((s: string) => (
        <Tag key={s} title={s.startsWith('+') ? truncated : s} size="sm">
          {s}
        </Tag>
      ))}
    </Wrap>
  );
}
