import React from 'react';
import {
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  ModalHeader,
  HStack,
  Box,
} from '@chakra-ui/react';
import { IconButton, Button, ButtonProps } from 'Atoms';
import { Typography } from 'Tokens';
import { RemoveIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';

type ModalSize = 'xs' | 'sm' | 'md' | 'mdPlus' | 'lg' | 'xl';

export const ModalTheme = {
  baseStyle: {
    header: {
      width: '100%',
      justifyContent: 'space-between',
      paddingX: '16px',
      paddingTop: '16px',
      paddingBottom: '0px',
    },
    footer: {
      padding: '16px',
      justifyContent: 'space-between',
    },
    body: {
      padding: '16px',
    },
    overlay: {
      backgroundColor: 'bg.backdrop',
    },
  },
  sizes: {
    xs: {
      dialog: {
        maxW: '288px',
      },
    },
    sm: {
      dialog: {
        maxW: '488px',
      },
    },
    md: {
      dialog: {
        maxW: '624px',
      },
    },
    mdPlus: {
      dialog: {
        maxW: '645px',
      },
    },
    lg: {
      dialog: {
        maxW: '840px',
      },
    },
    xl: {
      dialog: {
        maxW: '1040px',
      },
    },
  },
};

export type ModalProps = {
  title?: string;
  titleIcon?: JSX.Element;
  customHeader?: JSX.Element;
  hasFooter?: boolean;
  hasCancelButton?: boolean;
  hasOverlay?: boolean;
  size?: ModalSize;
  helpIcon?: boolean;
  onHelpClick?: () => void;
  iconChoice?: JSX.Element;
  iconButtonProps?: ButtonProps;
  subtitle?: string;
  overline?: string;
  tag?: JSX.Element;
  confirmVariant?: any;
  confirmText?: string;
  onConfirm?: () => void;
  confirmButtonProps?: ButtonProps;
  cancelVariant?: any;
  cancelText?: string;
  onCancel?: () => void;
  cancelButtonProps?: ButtonProps;
  actions?: React.ReactNode[];
  footerBorder?: boolean;
  hasHeader?: boolean;
  illustration?: JSX.Element;
  loading?: boolean;
} & Pick<ChakraModalProps, 'onClose' | 'children' | 'isOpen' | 'trapFocus'>;

const EMPTY = () => {};

export function Modal({
  title,
  titleIcon,
  customHeader,
  isOpen,
  onClose,
  children,
  hasFooter = true,
  hasCancelButton = true,
  hasOverlay = true,
  hasHeader = true,
  subtitle,
  overline,
  helpIcon,
  onHelpClick,
  iconChoice = <HelpIcon color="inherit" />,
  iconButtonProps,
  tag,
  confirmVariant = 'primary',
  confirmText = 'Confirm',
  onConfirm,
  cancelVariant = 'ghost',
  cancelText = 'Cancel',
  onCancel,
  confirmButtonProps,
  cancelButtonProps,
  actions,
  size = 'sm',
  trapFocus = true,
  footerBorder = false,
  illustration,
  loading,
  ...props
}: ModalProps) {
  const isClosable = !!onClose;
  const { t } = useTranslation('common');
  return (
    <ChakraModal
      onClose={onClose ?? EMPTY}
      isOpen={isOpen}
      closeOnEsc={isClosable}
      closeOnOverlayClick={isClosable}
      size={size}
      trapFocus={trapFocus}
      {...props}
    >
      {hasOverlay && <ModalOverlay />}

      <ModalContent>
        {/* Header */}
        {hasHeader && (
          <ModalHeader as={HStack}>
            {customHeader ? (
              customHeader
            ) : (
              <Box width="100%">
                {/* Illustration */}
                {illustration && <Box marginBottom="24px">{illustration}</Box>}
                {/* Overline */}
                {overline && (
                  <Typography variant="detail" noOfLines={1} marginBottom="2px">
                    {overline}
                  </Typography>
                )}
                <HStack alignItems="center" spacing="6px">
                  {titleIcon}
                  <Typography variant="h2">
                    <span style={{ marginRight: '8px' }}>{title}</span>
                    {/* Icon */}
                    {helpIcon && (
                      <IconButton
                        size="xs"
                        variant="ghost"
                        icon={iconChoice}
                        onClick={onHelpClick}
                        aria-label="help icon"
                        {...iconButtonProps}
                        marginTop="-4px"
                        tooltipLabel={t('common:actions.help')}
                      />
                    )}
                    {/* Tag */}
                    {tag && tag}
                  </Typography>
                </HStack>

                {/* Subtitle */}
                {subtitle && (
                  <Typography variant="detail" noOfLines={1} marginTop="2px">
                    {subtitle}
                  </Typography>
                )}
              </Box>
            )}

            {isClosable && (
              <IconButton
                size="md"
                variant="ghost"
                onClick={onClose}
                aria-label="close modal"
                icon={<RemoveIcon color="inherit" />}
                alignSelf="start"
                position="absolute"
                right="sm"
              />
            )}
          </ModalHeader>
        )}

        {/* Body */}
        <ModalBody paddingBottom={hasFooter ? '16px' : '32px !important'}>{children}</ModalBody>

        {/* Footer */}
        {hasFooter && (
          <ModalFooter borderTop={footerBorder ? '1px solid' : ''} borderColor="border.decorative">
            <HStack spacing="8px">{actions?.map((action) => action)}</HStack>
            <HStack spacing="8px">
              {hasCancelButton && (
                <Button
                  variant={cancelVariant}
                  onClick={onCancel ?? onClose}
                  {...cancelButtonProps}
                >
                  {cancelText}
                </Button>
              )}
              <Button
                variant={confirmVariant}
                onClick={loading ? (e) => e.preventDefault() : onConfirm}
                isLoading={loading}
                {...confirmButtonProps}
              >
                {confirmText}
              </Button>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
}
