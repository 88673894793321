import { TableData } from 'Molecules/NestedTable';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { useLastEdited } from './MetricsUtils';
import { Avatar, HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';

export const LastEditedBy = ({
  row,
  companyStandardId,
  selectedQuarter,
  metricLevel,
  reportingUnitId,
}: {
  row: TableData<MetricsTableData>;
  companyStandardId: string;
  selectedQuarter: string;
  metricLevel?: boolean;
  reportingUnitId?: string;
}) => {
  const lastEdited = useLastEdited(
    row,
    companyStandardId,
    selectedQuarter,
    metricLevel,
    reportingUnitId
  );
  return lastEdited.name ? (
    <HStack spacing="4px">
      <Avatar name={lastEdited.name} size="xs" />
      <Typography variant="body">{lastEdited.date}</Typography>
    </HStack>
  ) : (
    <Typography variant="body">—</Typography>
  );
};
