import { Box, useDisclosure, VStack } from '@chakra-ui/react';
import {
  ActionFieldsFragment_,
  GetActionsQuery_,
  GetReportingUnitsQuery_,
  GetTargetsQuery_,
} from 'models';
import { CreatableSelect } from 'Molecules';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ActionFilters } from '../Actions/ActionFilters';
import { ActionsList } from '../Actions/ActionsList';
import { AddActionModal } from '../Actions/AddActionModal';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';

export const REMOVE_DELETED = 'remove_deleted_action';

export const TargetActionsSection = ({
  actions,
  parentActions,
  onChange,
  watchedActions,
  actionRef,
  reportingUnits,
  targetId,
  parentTargetId,
  isGroup,
  isReadOnly,
  allSubsidiariesIds,
  unit,
}: {
  actions: GetActionsQuery_['actions'];
  parentActions: GetActionsQuery_['actions'];
  onChange: (param: any) => void;
  watchedActions: string[];
  actionRef: string;
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'];
  targetId?: string;
  parentTargetId?: string;
  isGroup: boolean;
  isReadOnly: boolean;
  allSubsidiariesIds: string[];
  unit: string;
}) => {
  const [actionName, setActionName] = useState('');
  const { esrsAssessmentId = '', standardRef = '' } = useParams();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);
  const [newActionId, setNewActionId] = useState<{ value: string; label: string }>();
  const [selectedAction, setSelectedAction] = useState<ActionFieldsFragment_ | undefined>();
  const [filteredActions, setFilteredActions] = useState<GetActionsQuery_['actions']>([]);

  const [filteredParentActions, setFilteredParentActions] = useState<GetActionsQuery_['actions']>(
    []
  );
  const { isOpen, onClose, onOpen } = useDisclosure();
  const selectRef = useRef<any>(null);

  const actionsOptions = useMemo(
    () => actions.map((action) => ({ value: action.id, label: action.title })),
    [actions]
  );

  const relatedParentActions = useMemo(
    () =>
      parentActions?.filter((action) =>
        action.actionSubsidiaries?.some(
          (subsidiary) => subsidiary.subsidiaryAssessment.id === esrsAssessmentId
        )
      ) ?? [],
    [parentActions, esrsAssessmentId]
  );

  const allRUIds = useMemo(
    () => (reportingUnits.map((ru) => ru.id).filter((ru) => ru !== undefined) as string[]) ?? [],
    [reportingUnits]
  );

  const hideActionsList = useMemo(
    () =>
      !watchedActions?.length || (watchedActions?.length === 1 && watchedActions[0] === undefined),
    [watchedActions]
  );

  const watchedActionsData = useMemo(
    () =>
      actions.filter((a) =>
        watchedActions?.filter((a1) => a1 !== undefined).some((a2) => a2 === a.id)
      ),
    [actions]
  );

  useEffect(() => {
    const isActionDeleted = newActionId?.label === REMOVE_DELETED;
    if (isActionDeleted) {
      const selectedActions = selectRef?.current?.getValue();
      selectRef?.current?.setValue([
        ...selectedActions.filter(
          (action: { value: string; label: string }) => action.value !== newActionId.value
        ),
      ]);
      setSelectedAction(undefined);
    }
    const existingId = watchedActions?.includes(newActionId?.value ?? '');
    if (!existingId) {
      selectRef?.current?.setValue([...selectRef?.current?.getValue(), newActionId]);
      setSelectedAction(undefined);
    }
  }, [newActionId]);

  return (
    <VStack alignItems="start" w="100%" spacing={'16px'}>
      <VStack alignItems="start" w="100%" spacing="16px">
        <Typography variant="h4" pb="-8px">
          Company actions
        </Typography>
        <Box w="450px">
          <CreatableSelect
            value={actionsOptions.filter((action) => watchedActions?.includes(action.value))}
            onChange={(action) => {
              const lastIndex = action[action.length - 1];
              if (action.length !== 0 && !!lastIndex) {
                const isNew = lastIndex.value === undefined;
                if (isNew) {
                  setSelectedAction(undefined);
                  setActionName(lastIndex.label);
                  onOpen();
                } else {
                  onChange(action?.map((v) => v?.value));
                }
              } else onChange(action?.map((v) => v?.value));
            }}
            options={actionsOptions}
            ref={selectRef}
            createOptionPosition="first"
            getOptionValue={(action) => action.value ?? ''}
            getOptionLabel={(action) => action.label}
            getNewOptionData={(_input, label) => ({
              value: undefined,
              label: label as string,
            })}
            formatCreateLabel={(input) => `Create new action: ${input}`}
            isMulti
            isClearable={true}
            placeholder="Select existing or create a new target"
          />
        </Box>
        <VStack w="100%" alignItems="start" spacing="12px">
          {(!hideActionsList || !!relatedParentActions?.length) && (
            <ActionFilters
              actions={watchedActionsData}
              setFilteredActions={setFilteredActions}
              parentActions={relatedParentActions}
              setFilteredParentActions={setFilteredParentActions}
              isGroup={isGroup}
            />
          )}
          {!hideActionsList && (
            <ActionsList
              targetId={targetId}
              actions={filteredActions}
              isGroup={isGroup}
              onOpen={onOpen}
              setSelectedAction={setSelectedAction}
              selectRef={selectRef}
              allSubsidiariesIds={allSubsidiariesIds}
              allRUIds={allRUIds}
              unit={unit}
            />
          )}
        </VStack>
      </VStack>
      {isReadOnly && !!relatedParentActions?.length && (
        <VStack alignItems="start" w="100%">
          <Typography variant="h4">Parent actions</Typography>
          <ActionsList
            targetId={parentTargetId ?? ''}
            actions={filteredParentActions}
            isGroup={true}
            onOpen={onOpen}
            setSelectedAction={setSelectedAction}
            selectRef={selectRef}
            isReadOnly={true}
            allSubsidiariesIds={allSubsidiariesIds}
            allRUIds={allRUIds}
            unit={unit}
          />
        </VStack>
      )}
      <AddActionModal
        onClose={onClose}
        isOpen={isOpen}
        actionName={actionName}
        actionToEdit={selectedAction}
        disclosureRequirementRef={actionRef}
        setNewActionId={setNewActionId}
        reportingUnits={reportingUnits}
        targetId={targetId}
        isTargetView={true}
        isGroup={isGroup}
        materialityAssessmentId={companyAssessmentId}
        isReadOnly={parentActions?.some((a) => a.id === selectedAction?.id) ? isReadOnly : false}
      />
    </VStack>
  );
};
