import { ColumnDefResolved } from '@tanstack/react-table';
import { AggregatedMetricsTableData, MetricsTableData } from '../DisclosureRequirements';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { IconButton, Tag, TruncatableText } from 'Atoms';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { Typography } from 'Tokens';

export const ReportMetricTable = ({
  metrics,
  currency,
  standardId,
}: {
  metrics: AggregatedMetricsTableData[];
  currency: string;
  standardId: string;
}) => {
  const nestedColumns: ColumnDefResolved<TableData<AggregatedMetricsTableData>, any>[] | undefined =
    useMemo(
      () => [
        {
          header: 'Metric',
          meta: {
            width: '800px',
          },
          accessorKey: 'metric',
          cell: ({ row }) => (
            <HStack pl={`${row.depth * 24}px`} spacing="8px" w="100%">
              {row.getCanExpand() ? (
                <IconButton
                  variant={'ghost'}
                  size="xs"
                  onClick={row.getToggleExpandedHandler()}
                  aria-label="expand"
                  icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                />
              ) : (
                <MetricTypeIcon type="number" />
              )}
              <HStack width="100%">
                <Box
                  w={row.original.tagType ? '' : '100%'}
                  maxWidth={row.original.tagType ? '70%' : '100%'}
                >
                  <TruncatableText
                    w="100%"
                    variant="body"
                    tooltipMaxWidth="400px"
                    text={
                      `${
                        !!row.original.tagName
                          ? row.original.tagName
                          : row.original.metric.shortTitle ?? row.original.metric.title
                      }` + `${row.original.tagType ? ' by ' : ''}`
                    }
                  />
                </Box>
                {row.original.tagType && (
                  <Tag
                    variant="outlined"
                    truncatable={true}
                    title={row.original.tagType}
                    size="xs"
                    borderRadius="4px"
                    px="4px"
                  />
                )}
              </HStack>
            </HStack>
          ),
        },
        {
          header: 'Unit',
          meta: {
            width: '18%',
          },
          accessorKey: 'unit',
          cell: ({ row }) => (
            <TruncatableText
              variant="body"
              text={mapUnitToCompanyCurrency(
                row.original.metric.unitOfMeasurement ?? 'NA',
                currency
              )}
            />
          ),
        },
        {
          header: 'Total',
          meta: {
            width: '18%',
          },
          accessorKey: 'yearly',
          cell: ({ row }) => (
            <Typography variant="body">{Math.floor(row.original.result?.Year ?? 0)}</Typography>
          ),
        },
      ],
      [metrics, currency, standardId]
    );
  return (
    <NestedTable<MetricsTableData>
      columns={nestedColumns}
      data={metrics}
      expanded={true}
      withBorder={true}
      borderRadius="0px"
      rowHeight="36px"
      defaultExpanded={true}
    />
  );
};
