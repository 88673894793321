import { HStack } from '@chakra-ui/react';
import { IconDownload, IconHelpCircle } from '@tabler/icons-react';
import { Button } from 'Atoms';
import { Typography } from 'Tokens';
import { FC } from 'react';
import { GUIDE_DOCUMENT_ID, SHEET_NUMBER } from '../Onboarding';

type MaterialityAssessmentHeaderProps = {
  title: string;
  noDownload?: boolean;
};

export const MaterialityAssessmentHeader: FC<MaterialityAssessmentHeaderProps> = ({
  title,
  noDownload = false,
}) => {
  return (
    <HStack justifyContent="space-between" w="100%">
      <Typography variant="h2">{title}</Typography>
      <HStack>
        {!noDownload && (
          <Button
            fontWeight={500}
            variant="ghost"
            leftIcon={<IconDownload size="16px" />}
            onClick={() =>
              (location.href = `https://docs.google.com/spreadsheets/d/${GUIDE_DOCUMENT_ID}/export?format=xlsx&gid=${SHEET_NUMBER}`)
            }
          >
            Download template
          </Button>
        )}
        <Button variant="ghost" leftIcon={<IconHelpCircle size="16px" />} fontWeight={500}>
          Learn more
        </Button>
      </HStack>
    </HStack>
  );
};
