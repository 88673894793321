import { BAssessment, ReportingGroup } from 'models';
import { useMemo } from 'react';
import { NodeType, NodeItem } from './TreeNode';

export const useBuildTree = (
  reportingGroups: ReportingGroup[],
  bAssessments:
    | BAssessment[]
    | {
        id: string;
        businessUnit: {
          id: string;
          name: string;
        };
        reportingGroup: { id: string | null };
        orderIndex: string;
      }[],
  companyBusinessUnitId: string,
  query: string,
  sortAlphabetically: boolean,
  groupsFirst: boolean
) => {
  const simpleSearch = (text: string) => {
    return text.toLowerCase().includes(query.toLowerCase());
  };
  const filterByQuery = (node: NodeItem, fullTree: NodeItem[]) => {
    if (!query || query === '') {
      return true;
    }
    // If node has a child/grandchild matching recursively
    const children = fullTree.filter((n) => n.parent === node.id);
    const hasMatchingChild = children.some((child) => filterByQuery(child, fullTree));
    if (hasMatchingChild) return true;
    // If node matches
    return simpleSearch(node.text);
  };
  const sorting = useMemo(() => {
    const defaultSort = (a: NodeItem, b: NodeItem) =>
      a.data?.original?.orderIndex - b.data?.original?.orderIndex;
    const alphabeticalSort = (a: NodeItem, b: NodeItem) => a.text.localeCompare(b.text);
    const groupFirstSort = (a: NodeItem, b: NodeItem) => {
      if (a.data?.type === NodeType.reportingGroup && b.data?.type === NodeType.businessUnit) {
        return -1;
      }
      if (a.data?.type === NodeType.businessUnit && b.data?.type === NodeType.reportingGroup) {
        return 1;
      }
      return 0;
    };

    const alphabeticalAndGroupFirstSort = (a: NodeItem, b: NodeItem) => {
      const groupFirst = groupFirstSort(a, b);
      if (groupFirst === 0) {
        return alphabeticalSort(a, b);
      }
      return groupFirst;
    };
    if (sortAlphabetically && groupsFirst) {
      return alphabeticalAndGroupFirstSort;
    }
    if (sortAlphabetically) {
      return alphabeticalSort;
    }
    if (groupsFirst) {
      return groupFirstSort;
    }
    return defaultSort;
  }, [sortAlphabetically, groupsFirst]);
  const treeData = useMemo(() => {
    const groupNodes = reportingGroups.map((group) => ({
      id: group.id,
      text: group.name ?? '',
      droppable: true,
      parent:
        group.id === group.parentId
          ? companyBusinessUnitId
          : group.parentId ?? companyBusinessUnitId,
      data: {
        type: NodeType.reportingGroup,
        original: group,
      },
    }));

    const businessUnitNodes = bAssessments.map((bAssessment) => ({
      id: bAssessment.businessUnit?.id,
      text: bAssessment.businessUnit?.name ?? '',
      droppable: true,
      parent: bAssessment.reportingGroup?.id ?? companyBusinessUnitId,
      data: {
        type: NodeType.businessUnit,
        original: bAssessment as BAssessment,
      },
    }));
    const fullTree = [...groupNodes, ...businessUnitNodes];
    return [...groupNodes, ...businessUnitNodes]
      .filter((node) => filterByQuery(node, fullTree))
      .sort(sorting);
  }, [
    reportingGroups,
    bAssessments,
    companyBusinessUnitId,
    query,
    sortAlphabetically,
    groupsFirst,
  ]);

  return treeData;
};
