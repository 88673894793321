import { uniq, find } from 'lodash';
import { Question, ResolvedQuestion, MultipleCriteriaQuestion, QuestionType_Enum_ } from 'models';
import { getQuestionsProgress, QuestionType } from 'utils/scores/questions';
import { parseExpression } from './resolveCondition';

export function isMultipleCriteria<T extends Pick<Question, 'type'>>(q: T) {
  return q.type == QuestionType.MultipleCriteria_;
}

function sortDependencies(
  dependencies: MultipleCriteriaQuestion['dependencies'],
  choices: Question['choices']
) {
  const sortedDependencies: MultipleCriteriaQuestion['dependencies'] = {
    questionSets: choices
      .filter((ch) => dependencies.questionSets.includes(ch.value))
      .map((ch) => ch.value),
    table: {},
  };
  choices.forEach((ch) => {
    if (dependencies.table[ch.value])
      sortedDependencies.table[ch.displayName] = sortedDependencies.questionSets.filter((qs) =>
        dependencies.table[ch.value].includes(qs)
      );
  });
  return sortedDependencies;
}

function extractDependencies(q: Question) {
  if (q.type == QuestionType_Enum_.MultipleCriteria_) {
    const expr = parseExpression(q.isAligned || 'TRUE');
    const deps = {} as { [option: string]: string[] };
    const unknown = expr.variables();
    const vars = Object.fromEntries(unknown.map((name) => [name, ''])) as any;
    vars.allQuestionSetsAligned = (questionsets: string[], option: string) => {
      deps[option] = questionsets;
      return false;
    };
    expr.evaluate(vars);
    return deps;
  }
  return {};
}
function withDependencies(q: Question) {
  const table = extractDependencies(q);
  return { ...q, dependencies: { table, questionSets: uniq(Object.values(table).flat()) } };
}

export function transformMultipleCriteriaQuestion(
  q: Question,
  question: ResolvedQuestion,
  questions: ResolvedQuestion[]
) {
  const multipleCriteriaQuestion = withDependencies(q);

  const dependencies = sortDependencies(
    multipleCriteriaQuestion.dependencies,
    multipleCriteriaQuestion.choices
  );
  const subQuestions = dependencies.questionSets.map((qsRef) => {
    const subquestions = questions.filter((ques) => ques.questionSetRef == qsRef);
    const progress = getQuestionsProgress(subquestions);
    subquestions.forEach((sq) => {
      sq.objective = question.objective;
      if (question.isAligned) sq.isRequired = false;
    });
    const title = find(question.choices, { value: qsRef })?.displayName || '';
    return { questions: subquestions, title, questionSet: qsRef, progress };
  });
  if (question.isAligned === false) {
    const allSubquestionsAnswered = subQuestions.every(
      (sq) => sq.progress.notAligned || sq.progress.progress == 1.0
    );
    if (!allSubquestionsAnswered) {
      question.isAligned = null;
      question.isAnswered = false;
    }
  }
  return {
    ...question,
    dependencies,
    subQuestions,
  };
}

export function removeSubquestions(questions: ResolvedQuestion[]) {
  const toRemove = questions
    .filter((q): q is MultipleCriteriaQuestion => !!q.subQuestions)
    .flatMap((q) => q.subQuestions.flatMap((sQ) => sQ.questions.map((qq) => qq.id)));
  return questions.filter((q) => !toRemove.includes(q.id));
}

export function getSubquestionProgress(questions: ResolvedQuestion[]) {
  const result = { total: 0, notAligned: 0, answered: 0 };
  questions
    .filter((q): q is MultipleCriteriaQuestion => !!q.subQuestions)
    .forEach((q) => {
      q.subQuestions.forEach(({ questions: subQuestions }) => {
        // we cannot use q.subQuestions.progress because it is calculated before making
        // questions not required after criteria
        const progress = getQuestionsProgress(subQuestions);
        result.total += progress.total;
        result.answered += progress.answered;
        if (!q.isAligned) {
          result.notAligned += progress.notAligned * progress.total;
        }
      });
    });
  return result;
}

export function sortedWithDependencies(questions: Question[]) {
  return questions
    .map(withDependencies)
    .sort((q1, q2) => {
      return (
        (q1.dependencies?.questionSets.length ?? 0) - (q2.dependencies?.questionSets.length ?? 0)
      );
    })
    .map((q) => q) as Array<Question & { dependencies: MultipleCriteriaQuestion['dependencies'] }>;
}

export const filterSubquestions =
  (filter: (q: ResolvedQuestion) => boolean) => (q: ResolvedQuestion) => {
    return !!q.subQuestions?.length
      ? q.subQuestions.some(({ questions }) => questions.some(filter))
      : filter(q);
  };
