import { saveAs } from 'file-saver';
import { DocumentFileIcon } from 'Tokens/Icons/FileIcons';
import { HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { Packer } from 'docx';
import { EsrsDocumentCreator } from './esrs-report-generator';
import { EsrsReportData } from '../Report.types';
import { useTranslation } from 'utils/translation';

export const EsrsReportDocsGenerator = ({ reportData }: { reportData: EsrsReportData }) => {
  const { t } = useTranslation('esrs');
  const generateReport = async () => {
    const esrsDocumentCreator = new EsrsDocumentCreator();

    const doc = await esrsDocumentCreator.create({
      data: reportData,
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `esrs-report.docx`);
    });
  };

  return (
    <HStack w="100%" spacing="10.33px" onClick={generateReport}>
      <DocumentFileIcon />
      <VStack alignItems="start" spacing="0px">
        <Typography variant="bodyStrong">{t('esrs:assessment.report.name')}</Typography>
        <Typography variant="detail">{t('esrs:assessment.report.wordDoc')}</Typography>
      </VStack>
    </HStack>
  );
};
