import { isArray, isPlainObject } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { useLatest } from 'react-use';

// Returns object like newObj but made from parts of oldObj
export default function smartMerge<T>(newObj: T, oldObj: any): T {
  if (oldObj === newObj) return oldObj;
  if (isArray(oldObj) && isArray(newObj)) {
    let itemChanged = false;
    const newObjectsArray = newObj.map((value, i) => {
      const oldVal = oldObj[i];
      const newVal = smartMerge(value, oldVal);
      if (newVal !== oldVal) itemChanged = true;
      return newVal;
    }) as unknown as T;
    if (itemChanged || oldObj.length !== newObj.length) return newObjectsArray;
    return oldObj as unknown as T;
  }
  if (isPlainObject(oldObj) && isPlainObject(newObj)) {
    let itemChanged = false;
    const newEntries: [string, any][] = Object.entries(newObj ?? {}).map(([key, value]) => {
      const oldVal = oldObj[key];
      const newVal = smartMerge(value, oldVal);
      if (newVal !== oldVal) itemChanged = true;
      return [key, newVal];
    });
    if (itemChanged || newEntries.length !== Object.keys(oldObj).length)
      return Object.fromEntries(newEntries) as T;
    return oldObj;
  }

  return newObj;
}

export function mergeDeepPlainObjects<T, S>(target: T, source: S): T & S {
  if (target == null) return source as T & S;
  if (isPlainObject(target) && isPlainObject(source)) {
    const output = { ...target } as { [x: string]: any };
    Object.entries(source ?? {}).forEach(([key, value]) => {
      output[key] = mergeDeepPlainObjects(output[key] as { string: any }, value);
    });
    return output as T & S;
  }
  return source as T & S;
}

export function useSmartMemo<T>(creator: () => T, deps: any[]) {
  const prev = useRef<T | undefined>();
  return useMemo(() => (prev.current = smartMerge(creator(), prev.current)), deps);
}

export function useSmartCallback<T extends any[], R>(callback: (...args: T) => R) {
  const ref = useLatest(callback);
  return useCallback((...args: T) => ref.current(...args), []);
}
