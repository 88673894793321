import { Box, HStack, VStack } from '@chakra-ui/react';
import { IconButton, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { HelpIcon } from 'Tokens/Icons/Status';

export const DisclosureRequirementTitle = ({
  title,
  onOpen,
  reference,
}: {
  title: string;
  onOpen: () => void;
  reference?: string;
}) => (
  <HStack>
    {reference && (
      <Typography variant="bodyLarge" color="text.hint">
        {reference}
      </Typography>
    )}
    <Typography variant="h3">{title}</Typography>
    <IconButton
      aria-label="learn more"
      variant="ghost"
      icon={<HelpIcon />}
      size="sm"
      onClick={() => onOpen()}
    />
  </HStack>
);

export const ListItem = ({
  key,
  text,
  related,
  company,
  extra,
  onClick,
}: {
  key: number;
  text: string;
  related: { text: string; color?: string };
  company: string;
  extra?: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <VStack alignItems="start" w="100%" key={key} onClick={onClick} cursor="pointer">
      <HStack
        h="44px"
        borderRadius="8px"
        p="12px 16px"
        w="100%"
        bg="bg.interactive"
        justifyContent="space-between"
      >
        <Box>
          <TruncatableText variant="bodyStrong" text={text} />
        </Box>
        <HStack spacing="12px">
          <Typography variant="body" color="text.default">
            {company}
          </Typography>
          <Typography variant="body" color={related.color ?? 'text.default'}>
            {related.text}
          </Typography>
          {extra}
        </HStack>
      </HStack>
    </VStack>
  );
};
