import { extendTheme } from '@chakra-ui/react';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/300.css';
import {
  CardTheme,
  InputTheme,
  NumberInputTheme,
  RadioTheme,
  TagTheme,
  CheckboxTheme,
  TabsTheme,
  ButtonTheme,
  AvatarTheme,
  AlertTheme,
  AccordionTheme,
  TextareaTheme,
} from 'Atoms';
import { NoteTheme, ModalTheme } from 'Molecules';
import { TypographyTheme, colors, fonts, fontSizes, lineHeights } from 'Tokens';
import { MenuTheme } from 'Molecules/Menu';
import { TooltipTheme } from 'Atoms/Tooltip';
import { RadioCardGroupTheme } from 'Molecules/RadioCardGroup';
import { InputCardTheme } from 'Molecules/InputCard';

export const spacing: { [key: string]: string } = {
  xxs: '4px',
  xs: '8px',
  sm: '16px',
  md: '24px',
  lg: '48px',
  xl: '96px',
};

export const shadows: { [key: string]: string } = {
  xs: '0px 0px 0px 1px #0000000D',
  sm: '0px 1px 2px 0px #0000000F, 0px 0px 3px 0px #0000001A ',
  md: '0px 2px 4px -1px #0000000F, 0px 0px 6px -1px #0000001A',
  lg: '0px 4px 6px -2px #0000000D, 0px 0px 15px -3px #0000001A',
  outline: '0 0 0 2px #8787FF',
};

export const theme = extendTheme({
  colors: colors,
  space: spacing,
  lineHeights: lineHeights,
  fonts: fonts,
  fontSizes: fontSizes,
  shadows: shadows,
  components: {
    Alert: AlertTheme,
    Text: TypographyTheme,
    Button: ButtonTheme,
    Checkbox: CheckboxTheme,
    Radio: { ...RadioTheme, variants: { ...RadioCardGroupTheme.variants } },
    Tag: TagTheme,
    Input: InputTheme,
    NumberInput: NumberInputTheme,
    Card: CardTheme,
    Tabs: TabsTheme,
    Tags: TagTheme,
    Avatar: AvatarTheme,
    Modal: ModalTheme,
    Note: NoteTheme,
    Menu: MenuTheme,
    Accordion: AccordionTheme,
    Tooltip: TooltipTheme,
    InputCard: InputCardTheme,
    Textarea: TextareaTheme,
  },
});
