import { Menu, MenuButton, MenuGroup, MenuList, MenuItem, HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useEffect } from 'react';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { ReportingFrequency } from './PaiIndicatorsModal';

export const IndicatorFrequencySelector = ({
  selectedFrequency,
  setSelectedFrequency,
  overallFrequencyOption,
}: {
  selectedFrequency: ReportingFrequency;
  setSelectedFrequency: (selectedFrequency: ReportingFrequency) => void;
  overallFrequencyOption: ReportingFrequency;
}) => {
  const isDisabled = overallFrequencyOption !== ReportingFrequency.customPerIndicator;

  useEffect(() => {
    if (isDisabled) setSelectedFrequency(overallFrequencyOption);
  }, [overallFrequencyOption]);

  return (
    <Menu gutter={2} size="md">
      <MenuButton
        as={Button}
        width="100%"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        color="text.default"
      >
        <Typography variant="body"> {selectedFrequency}</Typography>
      </MenuButton>
      <MenuList p="8px">
        <MenuGroup p="8px">
          <MenuItem
            p="8px"
            m="0px"
            w="100%"
            onClick={() => setSelectedFrequency(ReportingFrequency.yearly)}
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              color={selectedFrequency === ReportingFrequency.yearly ? 'text.selected' : ''}
            >
              <HStack>
                <Typography color="inherit" variant="bodyStrong">
                  {ReportingFrequency.yearly}
                </Typography>
              </HStack>
              {selectedFrequency === ReportingFrequency.yearly && <CheckIcon color="inherit" />}
            </HStack>
          </MenuItem>
          <MenuItem
            p="8px"
            m="0px"
            w="100%"
            onClick={() => setSelectedFrequency(ReportingFrequency.quarterly)}
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              color={selectedFrequency === ReportingFrequency.quarterly ? 'text.selected' : ''}
            >
              <HStack>
                <Typography color="inherit" variant="bodyStrong">
                  {ReportingFrequency.quarterly}
                </Typography>
              </HStack>
              {selectedFrequency === ReportingFrequency.quarterly && <CheckIcon color="inherit" />}
            </HStack>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
