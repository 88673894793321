import {
  Document,
  Paragraph,
  Table,
  BorderStyle,
  Header,
  AlignmentType,
  TableRow,
  TableCell,
  WidthType,
  HeightRule,
  VerticalAlign,
  Footer,
  ImageRun,
  TextRun,
  PageNumber,
  SectionType,
  LevelFormat,
  convertInchesToTwip,
  ShadingType,
} from 'docx';
import {
  ActivityTagStatus,
  FinancialResult,
} from 'Features/TaxonomyResultsTable/TaxonomyResultsTableTypes';
import { TFunction } from 'i18next';
import { ScoreSectionsEnum, TaxonomyScore } from 'models';
import { SCORE_SECTIONS } from 'utils/financials';
import { formatNum } from 'utils/numbers';

const INTRO_TEXT =
  'The EU Taxonomy Regulation (Regulation 2020/852) entered into force on 12 July 2020. Since then, the EU has implemented Delegated Acts to further expand on the taxonomy framework. The Delegated Acts currently in force include the Climate Delegated Act (Regulation 2021/2139), the Disclosures Delegated Act (Regulation 2021/2178), and the Complementary Climate Delegated Act (Regulation 2022/1214). In addition, another delegated act, the Environmental Delegated Act, and amendments to the Climate Delegated Act were adopted in June 2023 and are expected to enter into force on the 1st of January 2024. As of now, large, public-interest undertakings are required to report under the EU Taxonomy Regulation.';

const COMPANY_LEVEL_SCORES_TITLE =
  'Aggregated EU Taxonomy key performance indicators, company level:';

const ABOUT_EU_TAXONOMY = 'About the EU Taxonomy';
const ABOUT_TAXONOMY_DESCRIPTION_1 =
  'The EU taxonomy is a classification system that sets out a list of environmentally sustainable economic activities. It forms part of the EU’s plan to scale up sustainable investment and implement the European Green Deal.';
const ABOUT_TAXONOMY_DESCRIPTION_2 =
  'The taxonomy was developed in order to provide well-defined, harmonised criteria for when economic activities can be considered to be sustainable. It sets out robust, science-based technical screening criteria that activities need to comply with to be seen as green. By providing this harmonised standard, the taxonomy aims to increase transparency, create security for investors, prevent greenwashing, help companies become more climate-friendly, mitigate market fragmentation, and help investors compare investments across Member States. By directing investments towards sustainable projects and activities across the EU, the taxonomy should help to meet the EU’s 2030 and 2050 climate and energy targets.';
const ABOUT_TAXONOMY_DESCRIPTION_3 =
  'The Climate Delegated Act, the Complementary Climate Delegated Act, and the Environmental Delegated Act set out a list of eligible activities along with technical screening criteria for when the activities can be considered sustainable. A taxonomy-eligible economic activity is an economic activity that is described and has technical screening criteria set out in the taxonomy. So far only the Climate Delegated Act and Complementary Climate Delegated Act are in force and cover just over 100 taxonomy eligible economic activities.';

const STEPS_ALIGNMENT =
  'For an eligible activity to be considered aligned, it has to satisfy the following conditions: ';
const STEP1 =
  'The activity must make a substantial contribution to one or more of the climate and environmental objectives relevant to that activity.';
const STEP2 = 'The activity should not do significant harm to the other remaining objectives.';
const STEP3 =
  'The company should fulfill the minimum social safeguard standards based on OECD and UN guidelines.';

const GENERAL_COMMENTS_1 =
  'This taxonomy assessment is completed with best intention, focused on transparency, and providing explanation for choices made when interpreting the criteria. The interpretation of the criteria is based on both the explicit information available at the time of the assessment and the understanding of the purpose of the requirement. ';
const GENERAL_COMMENTS_2 =
  'The taxonomy regulation is still in a phase of early adoption and {{company}} is closely following any clarifications from the EU Commission or any changes in industry best-practice when it comes to interpreting the activity descriptions or technical screening criteria. ';

const REPORTING_REQUIREMENTS = 'Reporting requirements for {{company}}';

const METHODOLOGY_TITLE = 'Taxonomy assessment methodology';
const METHODOLOGY_DESCRIPTION =
  '{{company}} has performed the taxonomy assessment using Celsia Taxonomy software solution. The methodology of taxonomy assessment has included the following steps: ';

const REQUIREMENT1 = 'Defining scope of assessment';
const REQUIREMENT1_DESCRIPTION =
  '{{company}} has performed a taxonomy assessment for all activities of the company. This has been done from a bottom-up approach, assessing the lowest level of reporting units and aggregated to the top company level, enabling a taxonomy assessment for the company total, per activity and per business division.';
const REQUIREMENT2 = 'Defining eligibility and relevant activities';
const REQUIREMENT2_DESCRIPTION =
  'A taxonomy-eligible activity means an economic activity that is included in the taxonomy regulation. All {{company}}’s activities have been mapped out according to the activities defined in the Climate Delegated Act and categorized as either eligible or non-eligible following the description stated in the regulation. The eligible and non-eligible activities are listed in the table below:';

const REQUIREMENT3 = 'Defining relevant reporting units';
const REQUIREMENT3_DESCRIPTION =
  'In order to conduct the assessment as accurately as possible, {{company}} ’s operations were split into reporting units corresponding to the above-mentioned scope (see point 1).';

const REQUIREMENT4 = 'Assessment of criteria and defining alignment';
const REQUIREMENT4_DESCRIPTION1 =
  'Each of the activities under each of {{company}} ’s defined reporting units have been assessed against the technical screening criteria for the respective activities defined in the Climate Delegated Act. As the taxonomy regulation is still in an early phase of adoption, the focus has been on transparency, best intention, and providing explanation for choices made when interpreting the criteria. The interpretation of the criteria is based on both the explicit information available and the understanding of the purpose of the requirement.';
const REQUIREMENT4_DESCRIPTION2 =
  'The taxonomy regulation has not yet adopted explicit criteria for the minimum social safeguards beyond the references to OECD guidelines and UN Guiding Principles. Still, Celsia’s understanding is that defined requirements on minimum social safeguards need to be placed on the company and the activities in question in order to assess activity-alignment. {{company}} has therefore based compliance with minimum social safeguards on an assessment of several requirements derived from the process of due diligence on responsible business conduct as described in OECD’s Guidelines for Multinational Companies and the UN Guiding Principles for Business and Human Rights. Please see Appendix 1 for the actual criteria.';

const REQUIREMENT5 = 'Adding financial data and calculating the three KPIs';
const REQUIREMENT5_DESCRIPTION =
  'Finally, by adding financial data to each activity in the reporting unit, the proportion of {{company}}’s taxonomy-eligible and taxonomy-aligned activities were calculated. This is done by calculating the three key performance indicators (KPIs): turnover, capital expenditures (CapEx), and operational expenditures (OpEx). The results were calculated for each reporting unit and activity and then aggregated for the company level.';

const REQUIREMENT6 = 'Second-party opinion';
const REQUIREMENT6_DESCRIPTION =
  'Second-party opinion of {{company}}’s taxonomy assessment has been carried out by ';

const ACCOUNTING_PRINCIPLES = 'Accounting principles and Calculation of KPIs';
const ACCOUNTING_PRINCIPLES_DESCRIPTION1 =
  'The definitions of the turnover, CapEx, and OpEx KPIs are set out in Annex I to the Disclosures Delegated Act. The proportion of taxonomy-eligible and taxonomy-aligned turnover, CapEx, and OpEx are calculated by dividing a numerator by a denominator. The following sections provide further information on how the denominators and numerators were derived for each KPI.';

const APPENDIX_1 = 'Appendix 1: Criteria related to minimum social safeguards';
const MINIMUM_SAFEGUARDS_DESCRIPTION =
  'Please note: The taxonomy regulation has not yet adopted explicit criteria for the minimum social safeguards beyond the references to OECD guidelines and UN Guiding Principles. Still, Celsia’s understanding is that defined requirements on minimum social safeguards need to be placed on the company and the activities in question in order to assess activity-alignment. Celsia has therefore based compliance with minimum social safeguards on an assessment of several requirements derived from the process of due diligence on responsible business conduct as described in OECD’s Guidelines for Multinational Companies and the UN Guiding Principles for Business and Human Rights. Please see below for the actual criteria.';
const ALL_ACTIVITIES = 'All activities';
const CRITERIA = 'Criteria:';
const SAFEGUARDS_CRITERIA_1 =
  'Does your company have a policy commitment on social responsibility including human rights, labour rights and anti-corruption - either as a stand-alone policy or integrated into other policies?';
const SAFEGUARDS_CRITERIA_2 =
  'Does your policy or code of conduct contain social responsibility requirements and/or expectations towards suppliers and other key business partners?';
const SAFEGUARDS_CRITERIA_3 =
  'Does your company have written procedures ensuring that you have an iterative process in line with OECD’s due diligence process including mapping of risks for adverse negative impact on people, implementation of ceasing or preventive measures, tracking and reporting?';
const SAFEGUARDS_CRITERIA_4 =
  'Have you identified and assessed the salient risks, potential or factual, related to your taxonomy activity/activities, covering your value chain? Include also an explanation of why these are your prioritized risks.';
const SAFEGUARDS_CRITERIA_5 =
  'Can you provide information and documentation on the overall measures you have implemented to reduce, cease or prevent the risks identified?';
const SAFEGUARDS_CRITERIA_6 =
  'Do you have a whistle blower mechanism or similar in place, that is known and accessible for internal and external stakeholders?';
const SAFEGUARDS_CRITERIA_7 =
  'Do you track whether your policies and identified risks are properly managed through optimal implementation in your day-to-day business?';
const SAFEGUARDS_CRITERIA_8 =
  'Does your company report on how it addresses adverse impact on human rights, labor rights and anti-corruption - where such risks exist - and the results of its actions taken?';
const SAFEGUARDS_CRITERIA_9 =
  'Is the board and top-management kept informed about risks, and progress and results reached in the management of these?';
const SAFEGUARDS_CRITERIA_10 =
  'Are the above-mentioned steps of policy commitment, risk assessment, implementation, tracking and reporting performed on a regular and iterative basis in order to cover changes in risk exposure that can trigger more in-depth assessment and enhanced mitigation?';

enum TextStyle {
  firstPageHeader = 'firstPageHeader',
  preTitle = 'preTitle',
  title = 'title',
  introText = 'introText',
  tableTitle = 'tableTitle',
  columnTitle = 'columnTitle',
  columnRow = 'columnRow',
  description1 = 'description1',
  pageTitle = 'pageTitle',
  documentTitle = 'documentTitle',
  smallTitle = 'smallTitle',
  about = 'about',
  stepsTitle = 'stepsTitle',
  steps = 'steps',
  heading2 = 'heading2',
  paragraph = 'paragraph',
  smallParagraph = 'smallParagraph',
  subTitle = 'subTitle',
  section = 'section',
  footnotes = 'footnotes',
  greenHighlight = 'greenHighlight',
  columnCell = 'columnCell',
  biggerGreenHighlight = 'biggerGreenHighlight',
  celsiaTitle = 'celsiaTitle',
}

type DocumentResult = {
  name: string;
  score?: { capex: TaxonomyScore; opex: TaxonomyScore; revenue: TaxonomyScore };
  financials?: { capex: TaxonomyScore; opex: TaxonomyScore; revenue: TaxonomyScore };
};

type DocumentInput = {
  period: string;
  t: TFunction;
  company: DocumentResult & { currency?: string; numberOfReportingUnits?: number };
  activities: Array<{
    rowKey: string;
    name: string;
    financials?: FinancialResult;
    alignment?: FinancialResult;
    tag?: ActivityTagStatus;
  }>;
};

export class DocumentCreator {
  public async create(input: DocumentInput): Promise<Document> {
    const celsiaLogo = await fetch('/assets/Logo.png').then(function (response) {
      return response.arrayBuffer();
    });
    const celsiaGreyLogo = await fetch('/assets/grey-logo.png').then(function (response) {
      return response.arrayBuffer();
    });

    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };

    const document = new Document({
      creator: 'Celsia.io',
      revision: 1,
      subject: 'Eu taxonomy report',
      title: `${input.company?.name} - EU Taxonomy report ${input.period}`,
      features: {
        updateFields: true,
      },
      numbering: {
        config: [
          {
            reference: 'number-system',
            levels: [
              {
                level: 0,
                format: LevelFormat.UPPER_ROMAN,
                text: '%1',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                },
              },
              {
                level: 1,
                format: LevelFormat.DECIMAL,
                text: '%2.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                  },
                },
              },
              {
                level: 2,
                format: LevelFormat.LOWER_LETTER,
                text: '%3)',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(1.18) },
                  },
                },
              },
              {
                level: 3,
                format: LevelFormat.UPPER_LETTER,
                text: '%4)',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: 2880, hanging: 2420 },
                  },
                },
              },
              {
                level: 4,
                format: LevelFormat.DECIMAL,
                text: '%5.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                    bold: true,
                    color: '#1C1C57',
                  },
                },
              },
              {
                level: 5,
                format: LevelFormat.ORDINAL_TEXT,
                text: '',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                  },
                },
              },
              {
                level: 6,
                format: LevelFormat.DECIMAL,
                text: '%7.',
                alignment: AlignmentType.START,
                style: {
                  paragraph: {
                    indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.18) },
                  },
                  run: {
                    size: 24,
                    font: 'Manrope',
                  },
                },
              },
            ],
          },
        ],
      },
      background: {
        color: '#FFFFFF',
      },
      sections: [
        // First page
        {
          properties: {
            titlePage: true,
          },
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new ImageRun({
                      data: celsiaGreyLogo,
                      transformation: {
                        width: 61,
                        height: 22,
                      },
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            first: this.createLogoFooter(celsiaLogo),
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      children: [PageNumber.CURRENT],
                      font: 'Manrope',
                    }),
                  ],
                }),
              ],
            }),
          },
          children: [
            new Paragraph({
              text: 'Celsia’s EU Taxonomy report for',
              style: TextStyle.smallTitle,
              spacing: {
                before: 100,
                after: 150,
                line: 150,
              },
            }),
            new Paragraph({
              text: input.company.name,
              style: TextStyle.documentTitle,
              spacing: {
                after: 300,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Reporting period: ',
                  color: '#212121',
                  size: 22,
                  font: 'Manrope',
                }),
                new TextRun({
                  text: input.period,
                  color: '#212121',
                  size: 22,
                  font: 'Manrope',
                }),
              ],
              spacing: {
                line: 300,
                after: 300,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(INTRO_TEXT, 'company', input.company.name),
              style: TextStyle.introText,
              spacing: {
                line: 300,
              },
              border: {
                bottom: {
                  color: '#E1E1E6',
                  style: BorderStyle.SINGLE,
                  size: 6,
                  space: 32,
                },
              },
            }),
            new Paragraph({
              text: COMPANY_LEVEL_SCORES_TITLE,
              style: TextStyle.tableTitle,
              spacing: {
                after: 150,
                before: 150,
              },
            }),
            new Paragraph({
              text: 'Please note that all relative numbers in the table below refer to the company total.',
              style: TextStyle.introText,
              spacing: {
                after: 120,
                before: 150,
              },
            }),
            new Table({
              columnWidths: [2200, 2200, 2200, 2200],
              rows: this.getScoresTable(
                input.company?.score,
                input.company?.financials,
                input.company?.currency ?? 'NOK'
              ),
              width: {
                size: '100%',
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: {
                  style: BorderStyle.DASH_DOT_STROKED,
                  size: 1,
                  color: 'e1e1e6',
                },
                left: {
                  style: BorderStyle.DASH_DOT_STROKED,
                  size: 1,
                  color: 'e1e1e6',
                },
                right: {
                  style: BorderStyle.DASH_DOT_STROKED,
                  size: 1,
                  color: 'e1e1e6',
                },
                bottom: {
                  style: BorderStyle.THICK_THIN_MEDIUM_GAP,
                  size: 5,
                  color: 'e1e1e6',
                },
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Important: ',
                  font: 'Manrope',
                  color: '#212121',
                  bold: true,
                  size: 24,
                }),
                new TextRun({
                  text: 'This document is an automatically generated report downloaded from the Celsia tool. The Celsia tool is based on self-assessments and Celsia has no responsibility for the results. The additional data needed from the companies ',
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: 'are marked in green in the document.',
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
              ],
              style: TextStyle.introText,
              shading: {
                type: ShadingType.HORIZONTAL_CROSS,
                color: 'efefef',
                fill: 'efefef',
              },
              spacing: {
                after: 700,
                before: 700,
                line: 300,
              },
              border: {
                top: {
                  color: 'e1e1e6',
                  space: 1,
                  size: 6,
                  style: BorderStyle.SINGLE,
                },
                bottom: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: 'e1e1e6',
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
          ],
        },
        //Page containing the second table
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: 'Scores per activity',
              style: TextStyle.pageTitle,
              spacing: {
                after: 120,
                before: 480,
              },
            }),
            new Paragraph({
              text: 'Please note that the alignment ratio in each row applies only to their respective row. Company total can be seen in the “Total” row.',
              style: TextStyle.introText,
              spacing: {
                after: 120,
              },
            }),
            ...((SCORE_SECTIONS?.map((section) => [
              new Paragraph({
                text: input.t(`common:financials.${section.key}`),
                style: TextStyle.heading2,
                spacing: {
                  after: 120,
                  before: 480,
                },
              }),
              new Table({
                columnWidths: [2900, 2200, 2200, 2200],
                rows: this.makeActivitiesTable(
                  input.t,
                  input.activities,
                  input?.company?.currency ?? 'NOK',
                  section.key
                ),
                width: {
                  size: '100%',
                  type: WidthType.PERCENTAGE,
                },
              }),
            ]).flat() ?? []) as Array<Paragraph | Table>),
          ],
        },
        // About the taxonomy
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: ABOUT_EU_TAXONOMY,
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: ABOUT_TAXONOMY_DESCRIPTION_1,
              style: TextStyle.about,
              spacing: {
                after: 320,
                line: 300,
              },
            }),
            new Paragraph({
              text: ABOUT_TAXONOMY_DESCRIPTION_2,
              style: TextStyle.about,
              spacing: {
                after: 320,
                line: 300,
              },
            }),
            new Paragraph({
              text: ABOUT_TAXONOMY_DESCRIPTION_3,
              style: TextStyle.about,
              spacing: {
                after: 320,
                line: 300,
              },
            }),
          ],
        },
        // Taxonomy steps
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: STEPS_ALIGNMENT,
              style: TextStyle.stepsTitle,
              spacing: {
                after: 320,
              },
            }),
            new Paragraph({
              text: STEP1,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 1,
              },
            }),
            new Paragraph({
              text: STEP2,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 1,
              },
            }),
            new Paragraph({
              text: STEP3,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 1,
              },
            }),
          ],
        },
        // Reporting requirements
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: this.fillInVariable(REPORTING_REQUIREMENTS, 'company', input.company.name),
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: 'According to the Non-financial reporting directive (NFRD) article 19(a) and 29(a) non-financial undertakings which are public-interest entities (i.e. listed) with more than 500 employees, in the case of a group on a consolidated basis, are required to report on the taxonomy. As of 2023 the undertakings are required to report on the proportion of their taxonomy-eligible and taxonomy-aligned activities.',
              style: TextStyle.description1,
              spacing: {
                after: 320,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Alt 1 (covered by the regulation): ',
                  bold: true,
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
                new TextRun({
                  text: input.company.name,
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: ' is covered by the taxonomy regulation being a listed company with more than 500 employees.',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 320,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Alt 2 (not covered by the regulation): ',
                  bold: true,
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: input.company.name,
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: ' is not yet covered by the taxonomy regulation being a company with less than ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '500 employees/not being',
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: this.fillInVariable(
                    ' a listed company. This report therefore represents {{company}} voluntary taxonomy report',
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 320,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Alt 3 (Norwegian company):',
                  bold: true,
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
                new TextRun({
                  text: this.fillInVariable(
                    " Being a Norwegian company, {{company}} is not covered by the taxonomy regulation as the regulation has not yet come into effect in Norway. This report is therefore {{company}}'s voluntary taxonomy report.",
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 320,
                line: 300,
              },
            }),
          ],
        },
        // Taxonomy assessment methodology
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: METHODOLOGY_TITLE,
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(METHODOLOGY_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
            }),
            new Paragraph({
              text: REQUIREMENT1,
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT1_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: REQUIREMENT2,
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT2_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 300,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),

            new Table({
              columnWidths: [4000, 4000],
              rows: this.getActivitiesCommentsTable(input.activities),
              borders,
              width: {
                size: '100%',
                type: WidthType.PERCENTAGE,
              },
              indent: {
                size: 600,
                type: WidthType.AUTO,
              },
            }),
            new Paragraph({
              text: REQUIREMENT3,
              style: TextStyle.subTitle,
              spacing: {
                before: 480,
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT3_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: REQUIREMENT4,
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT4_DESCRIPTION1, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT4_DESCRIPTION2, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
                after: 480,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: REQUIREMENT5,
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(REQUIREMENT5_DESCRIPTION, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
            new Paragraph({
              text: REQUIREMENT6,
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
              numbering: {
                reference: 'number-system',
                level: 4,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: this.fillInVariable(
                    REQUIREMENT6_DESCRIPTION,
                    'company',
                    input.company.name
                  ),
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '[add name of verifying body or delete this section if no second-party verification has been performed].',
                  size: 24,
                  font: 'Manrope',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 5,
              },
            }),
          ],
        },
        // Accounting principles
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: ACCOUNTING_PRINCIPLES,
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: ACCOUNTING_PRINCIPLES_DESCRIPTION1,
              style: TextStyle.smallParagraph,
              spacing: {
                after: 480,
                line: 300,
              },
            }),
            // Turnover KPI
            new Paragraph({
              text: 'Turnover KPI',
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Denominator: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: 'The turnover KPI is calculated as ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '[Add additional explanation about what you have included/not included in the denominator, accounting standard used and how it relates to the financial reporting notes] ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Numerator for eligibility calculations: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: 'The numerator for calculating the proportion of turnover that is taxonomy-eligible was derived from the part of the turnover defined in the denominator that is associated with taxonomy-eligible activities. ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Numerator for alignment calculations: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: 'The numerator used for calculating the proportion of taxonomy-aligned turnover is taken as the part of the turnover denominator that is derived from taxonomy-aligned activities.',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '[Add additional explanation about which projects are considered aligned and not on a high-level] ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
              ],
              spacing: {
                after: 480,
                line: 300,
              },
            }),
            // CapEx KPI
            new Paragraph({
              text: 'CapEx KPI',
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Denominator: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: 'The CapEx KPI is calculated as ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '[Add additional explanation about what you have included/not included in the denominator, accounting standard used and how it relates to the financial reporting notes] ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Numerator for eligibility calculations: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: '[Please note that this section should be adapted depending on if a CapEx plan has been used or not]. ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
                new TextRun({
                  text: 'The numerator used for calculating the proportion of taxonomy-eligible CapEx is taken as the part of the CapEx denominator that is related to assets and processes that are associated with taxonomy-eligible activities. In addition, the numerator includes CapEx which are a part of a CapEx plan as further specified below.',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 300,
                line: 300,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(
                '{{company}}‘s assessment is supported by a CapEx plan, involving investments and costs related to expanding a taxonomy-aligned activity or bringing a taxonomy-eligible activity to alignment within a time span of up to 5 years. ',
                'company',
                input.company.name
              ),
              style: TextStyle.smallParagraph,
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              text: 'The plan that accompanies both the CapEx and OpEx KPIs is disclosed at the economic activity aggregated level and aims to extend the scope of taxonomy-aligned economic activities. The plan is approved by the Board of Directors.',
              style: TextStyle.smallParagraph,
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'The numerator also includes purchase of output of taxonomy-eligible activities enabling the target activity to become low-carbon. ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '[Add additional explanation about which projects/assets are considered eligible and not on a high-level]',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Numerator for alignment calculations: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: '[Please note that this section should be adapted depending on if a CapEx plan has been used or not].',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
                new TextRun({
                  text: ' The numerator used for calculating the proportion of taxonomy-aligned CapEx is taken as the part of the CapEx eligible numerator as defined in the previous section that is associated with taxonomy-aligned activities.',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              text: '[Add additional explanation about which projects are considered aligned and not on a high-level]',
              style: TextStyle.biggerGreenHighlight,
              spacing: {
                after: 480,
                line: 300,
              },
            }),
            // OpEx KPI
            new Paragraph({
              text: 'OpEx KPI',
              style: TextStyle.subTitle,
              spacing: {
                after: 160,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Denominator: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: 'The OpEx KPI is calculated as ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
                new TextRun({
                  text: '[Add additional explanation about what you have included/not included in the denominator, accounting standard used and how it relates to the financial reporting notes] ',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                  shading: {
                    type: ShadingType.SOLID,
                    color: '#DAF7ED',
                  },
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Numerator for eligibility calculations: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: 'The numerator used for calculating the proportion of taxonomy-eligible OpEx is taken as the part of the OpEx denominator that is associated with taxonomy-eligible activities. In addition, the numerator includes OpEx which are a part of a CapEx plan as further specified in the previous section, as well as the purchase of output of taxonomy-eligible activities enabling the target activity to become low-carbon.',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Numerator for alignment calculations: ',
                  size: 24,
                  font: 'Manrope',
                  color: '#1C1C57',
                  bold: true,
                }),
                new TextRun({
                  text: 'The numerator used for calculating the proportion of taxonomy-aligned OpEx is taken as the part of the OpEx eligible numerator as defined in the previous section that is associated with taxonomy-aligned activities.',
                  size: 24,
                  font: 'Manrope',
                  color: '#212121',
                }),
              ],
              spacing: {
                after: 480,
                line: 300,
              },
            }),
          ],
        },
        // General comments
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: 'General comments',
              style: TextStyle.pageTitle,
              spacing: {
                after: 480,
              },
            }),
            new Paragraph({
              text: GENERAL_COMMENTS_1,
              style: TextStyle.smallParagraph,
              spacing: {
                after: 160,
                line: 300,
              },
            }),
            new Paragraph({
              text: this.fillInVariable(GENERAL_COMMENTS_2, 'company', input.company.name),
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
              },
            }),
          ],
        },
        // Appendix 1
        {
          properties: {
            type: SectionType.NEXT_PAGE,
          },
          children: [
            new Paragraph({
              text: APPENDIX_1,
              style: TextStyle.pageTitle,
              spacing: {
                line: 300,
                after: 360,
              },
            }),
            new Paragraph({
              text: MINIMUM_SAFEGUARDS_DESCRIPTION,
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
                after: 220,
              },
            }),
            new Paragraph({
              text: ALL_ACTIVITIES,
              style: TextStyle.section,
              spacing: {
                line: 300,
                after: 160,
              },
            }),
            new Paragraph({
              text: CRITERIA,
              style: TextStyle.smallParagraph,
              spacing: {
                line: 300,
                after: 160,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_1,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_2,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_3,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_4,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_5,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_6,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_7,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_8,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_9,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
            new Paragraph({
              text: SAFEGUARDS_CRITERIA_10,
              style: TextStyle.steps,
              spacing: {
                line: 300,
              },
              numbering: {
                reference: 'number-system',
                level: 6,
              },
            }),
          ],
        },
      ],
      styles: {
        paragraphStyles: [
          {
            id: TextStyle.greenHighlight,
            name: 'Green Highlight',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              size: 22,
              color: '000000',
              font: 'Manrope',
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            },
          },
          {
            id: TextStyle.columnCell,
            name: 'Column Cell',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              size: 22,
              color: '000000',
              font: 'Manrope',
            },
          },
          {
            id: TextStyle.biggerGreenHighlight,
            name: 'Bigger Green Highlight',
            basedOn: 'Normal',
            next: 'Normal',
            quickFormat: true,
            run: {
              size: 24,
              color: '#212121',
              font: 'Manrope',
              shading: {
                type: ShadingType.SOLID,
                color: '#DAF7ED',
              },
            },
          },
          {
            id: TextStyle.introText,
            name: 'Intro text',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 22,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.tableTitle,
            name: 'Table title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.columnTitle,
            name: 'Column Title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 22,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.columnRow,
            name: 'Column Title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 22,
              font: 'Manrope',
            },
          },
          {
            id: TextStyle.description1,
            name: 'Description 1',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.pageTitle,
            name: 'Page title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 36,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.smallTitle,
            name: 'Small title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 26,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.documentTitle,
            name: 'Document title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 52,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.about,
            name: 'About',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.stepsTitle,
            name: 'Steps title',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.steps,
            name: 'Steps',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.heading2,
            name: 'Heading 2',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1a1a1a',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.smallParagraph,
            name: 'Small Paragraph',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: false,
            },
          },
          {
            id: TextStyle.subTitle,
            name: 'Subtitle',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#1C1C57',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
          {
            id: TextStyle.section,
            name: 'Section',
            basedOn: 'Normal',
            next: 'Normal',
            run: {
              color: '#212121',
              size: 24,
              font: 'Manrope',
              bold: true,
            },
          },
        ],
      },
    });
    return document;
  }

  public getOneScoreRow(
    score: DocumentResult['score'],
    title: string,
    getValue: (section?: TaxonomyScore) => string,
    lowerCase = false
  ) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    return new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: title,
              style: lowerCase ? TextStyle.columnRow : TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({ text: `${getValue(score?.revenue)}`, style: TextStyle.columnRow }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({ text: `${getValue(score?.capex)}`, style: TextStyle.columnRow }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({ text: `${getValue(score?.opex)}`, style: TextStyle.columnRow }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });
  }

  public createLogoFooter(logoImage: ArrayBuffer) {
    return new Footer({
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new ImageRun({
              data: logoImage,
              transformation: {
                width: 61,
                height: 22,
              },
            }),
          ],
        }),
      ],
    });
  }

  public getActivitiesCommentsTable(activities: DocumentInput['activities']) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    const headersRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: 'Activity', style: TextStyle.columnTitle })],
          borders,
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'Comments', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 400,
        rule: HeightRule.AUTO,
      },
    });

    const activitiesRows = activities
      .filter((act) => act.name !== 'Taxonomy-eligible activities' && act.name !== 'Total')
      .map((activity) => {
        return new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: activity.name,
                  style: TextStyle.columnCell,
                }),
              ],
              borders,
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text:
                    activity.name === 'Taxonomy-non-eligible activities'
                      ? '[Explain which parts of the company’s activities are not eligible for the taxonomy]'
                      : '[Explain reasoning for choice of activity]',
                  style: TextStyle.greenHighlight,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              borders,
            }),
          ],
          height: {
            value: 400,
            rule: HeightRule.AUTO,
          },
        });
      });
    return [headersRow, ...activitiesRows];
  }

  public getScoresTable(
    score: DocumentResult['score'],
    financials: DocumentResult['financials'],
    currency: string
  ) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    const headersRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph('')],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'Turnover', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'CapEx', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'OpEx', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });

    const formatNumberToThousands = (x?: number) => {
      if (x === undefined) {
        return '-';
      } else if (x < 1000) {
        return x.toString();
      } else {
        const thousands = Math.round(x / 1000);
        return thousands.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k';
      }
    };

    const totalRow = this.getOneScoreRow(financials, 'Total (absolute value)', (section) =>
      section?.total !== null && !Number.isNaN(section?.total)
        ? `${formatNumberToThousands(section?.total)} ${currency}`
        : '-'
    );
    const eligibleRow = this.getOneScoreRow(score, 'Eligible', (section) =>
      section?.eligible !== null && !Number.isNaN(section?.eligible)
        ? `${formatNum(section?.eligible ?? 0, 1)}%`
        : '-'
    );
    const alignedRow = this.getOneScoreRow(
      score,
      'Eligible, aligned',
      (section) =>
        section?.aligned !== null && !Number.isNaN(section?.aligned)
          ? `${formatNum(section?.aligned, 1)}%`
          : '-',
      true
    );
    const notAlignedRow = this.getOneScoreRow(
      score,
      'Eligible, not aligned',
      (section) =>
        section?.aligned !== null &&
        !Number.isNaN(section?.aligned) &&
        !Number.isNaN(section?.eligible)
          ? `${formatNum(100 - ((section?.aligned ?? 0) + (100 - (section?.eligible ?? 0))))}%`
          : '-',
      true
    );
    const notEligibleRow = this.getOneScoreRow(score, 'Not eligible', (section) =>
      section?.eligible !== null && !Number.isNaN(section?.eligible)
        ? `${formatNum(100 - (section?.eligible ?? 0), 1)}%`
        : '-'
    );

    return [headersRow, totalRow, eligibleRow, alignedRow, notAlignedRow, notEligibleRow];
  }

  public fillInVariable(text: string, variableName: string, value: string): string {
    return text.replaceAll(`{{${variableName}}}`, value);
  }

  public makeActivitiesTable(
    t: DocumentInput['t'],
    activities: DocumentInput['activities'],
    currency: string,
    scoreSection: ScoreSectionsEnum
  ) {
    const borders = {
      top: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      left: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      right: {
        style: BorderStyle.THICK,
        size: 1,
        color: 'e1e1e6',
      },
      bottom: {
        style: BorderStyle.THICK,
        size: 5,
        color: 'e1e1e6',
      },
    };
    const headersRow = new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: 'Activities',
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: `${t(`common:financials.${scoreSection}`) ?? ''}, ${currency}`,
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [new Paragraph({ text: 'Alignment', style: TextStyle.columnTitle })],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: 'Transitional or enabling activity',
              style: TextStyle.columnTitle,
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
          borders,
        }),
      ],
      height: {
        value: 500,
        rule: HeightRule.AUTO,
      },
    });

    const activitiesRows = activities.map((activity) => {
      const checkAlignmentVal = (val: string) => {
        if (val === 'inProgress') {
          return 'Assessment not finished';
        } else if (val === 'undefined') {
          return '--';
        } else if (val === 'null') {
          return 'null';
        } else {
          const formattedVal = Number(val).toFixed(1);
          return `${formattedVal?.includes('.0') ? Math.floor(Number(val)) : formattedVal}%`;
        }
      };

      const formatNumberToThousands = (x?: number) => {
        if (x === undefined) {
          return '-';
        } else if (x < 1000) {
          return x.toString();
        } else {
          const thousands = Math.round(x / 1000);
          return thousands.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k';
        }
      };

      const checkActivityType = (val: string) => {
        return val === 'GREEN' ? 'Neither' : val === 'undefined' || val === 'null' ? '--' : val;
      };

      return new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: activity.name,
                style: ['eligible', 'nonEligible', 'total'].includes(activity.rowKey)
                  ? TextStyle.columnTitle
                  : TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${
                  formatNumberToThousands(Number(activity?.financials?.[scoreSection].result)) ?? 0
                }`,
                style: TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: checkAlignmentVal(`${activity?.alignment?.[scoreSection]?.result}`),
                style: TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: checkActivityType(`${activity?.tag?.toLocaleUpperCase()}`),
                style: TextStyle.columnRow,
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders,
          }),
        ],
        height: {
          value: 500,
          rule: HeightRule.AUTO,
        },
      });
    });
    return [headersRow, ...activitiesRows];
  }
}
