import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

export interface RowFormat {
  [key: string]: any;
  activityName?: string;
  code?: string;
  absolute: number;
  proportion: number;
  mitigationSC?: number;
  adaptationSC?: number;
  waterSC?: number;
  circularSC?: number;
  pollutionSC?: number;
  biodiversitySC?: number;
  mitigationDNSH?: boolean;
  adaptationDNSH?: boolean;
  waterDNSH?: boolean;
  circularDNSH?: boolean;
  pollutionDNSH?: boolean;
  biodiversityDNSH?: boolean;
  minimumSafeguards?: boolean;
  alignedN?: number;
  alignedN1?: number;
  enabling?: number | 'E';
  transitional?: number | 'T';
}

export interface SheetFormat {
  [key: string]: RowFormat | RowFormat[];
  totalEligibleAligned: RowFormat;
  totalEligibleNotAligned: RowFormat;
  totalEligible: RowFormat;
  nonEligible: RowFormat;
  total: RowFormat;
  eligibleAlignedActivities: RowFormat[];
  eligibleNotAlignedActivities: RowFormat[];
}

export interface TaxonomyTableData {
  companyName: string;
  reportingYear: number;
  currency: string;
  turnover: SheetFormat;
  capex: SheetFormat;
  opex: SheetFormat;
}

const parseRow = (rowData: RowFormat) => {
  const parsedRow = { ...rowData };

  Object.keys(rowData).forEach((key) => {
    const value = rowData[key as keyof RowFormat];

    if (typeof value === 'boolean') {
      (parsedRow[key as keyof RowFormat] as string) = value ? 'Y' : 'N';
    }
  });

  return parsedRow;
};

export const generateTaxonomyTable = async (data: TaxonomyTableData) => {
  try {
    const workbook = new ExcelJS.Workbook();
    const templateFile = await fetch('/assets/taxonomy_table/template.xlsx');
    const templateBuffer = await templateFile.arrayBuffer();

    await workbook.xlsx.load(templateBuffer);

    const COLUMN_HEADERS = [
      { key: 'activityName', letter: 'B' },
      { key: 'code', letter: 'C' },
      { key: 'absolute', letter: 'D' },
      { key: 'proportion', letter: 'E' },
      { key: 'mitigationSC', letter: 'F' },
      { key: 'adaptationSC', letter: 'G' },
      { key: 'waterSC', letter: 'H' },
      { key: 'circularSC', letter: 'I' },
      { key: 'pollutionSC', letter: 'J' },
      { key: 'biodiversitySC', letter: 'K' },
      { key: 'mitigationDNSH', letter: 'L' },
      { key: 'adaptationDNSH', letter: 'M' },
      { key: 'waterDNSH', letter: 'N' },
      { key: 'circularDNSH', letter: 'O' },
      { key: 'pollutionDNSH', letter: 'P' },
      { key: 'biodiversityDNSH', letter: 'Q' },
      { key: 'minimumSafeguards', letter: 'R' },
      { key: 'alignedN', letter: 'S' },
      { key: 'alignedN1', letter: 'T' },
      { key: 'enabling', letter: 'U' },
      { key: 'transitional', letter: 'V' },
    ];

    workbook.eachSheet((sheet) => {
      const s = sheet.name.toLowerCase();
      const sheetData: SheetFormat = (data as any)[s];

      // Set column header keys
      COLUMN_HEADERS.forEach((header) => {
        sheet.getColumn(header.letter).key = header.key;
      });

      // Adjust currency
      const absoluteCell = sheet.getRow(3).getCell('absolute').value as any;
      absoluteCell.richText[1].text = data.currency;

      // ''' Hardcoded rows ''' //

      // Total for Taxonomy-non-eligible activities (B)
      sheet.getRow(13).getCell('absolute').value = sheetData.nonEligible.absolute;
      sheet.getRow(13).getCell('proportion').value = sheetData.nonEligible.proportion;

      // Overall Total (A+B)
      sheet.getRow(14).getCell('absolute').value = sheetData.total.absolute;
      sheet.getRow(14).getCell('proportion').value = sheetData.total.proportion;

      // Total for eligible aligned activities (A.1))
      const totalEligibleAlignedActivitiesRow = sheet.getRow(7);
      Object.keys(sheetData.totalEligibleAligned).forEach((key) => {
        totalEligibleAlignedActivitiesRow.getCell(key).value = sheetData.totalEligibleAligned[key];
      });

      // Total for eligible 'not' aligned activities (A.2)
      sheet.getRow(10).getCell('absolute').value = sheetData.totalEligibleNotAligned.absolute;
      sheet.getRow(10).getCell('proportion').value = sheetData.totalEligibleNotAligned.proportion;

      // Total of eligible activities (A.1 + A.2)
      sheet.getRow(11).getCell('absolute').value = sheetData.totalEligible.absolute;
      sheet.getRow(11).getCell('proportion').value = sheetData.totalEligible.proportion;
      sheet.getRow(11).getCell('alignedN').value = sheetData.totalEligible.alignedN;
      sheet.getRow(11).getCell('enabling').value = sheetData.totalEligible.enabling;
      sheet.getRow(11).getCell('transitional').value = sheetData.totalEligible.transitional;

      // ''' Dynamic rows ''' //

      // Insert eligible activities rows
      let eligibleActivityRowIndex = 0;
      sheet.eachRow((row, rowIndex) => {
        if (row.getCell(2).value?.toString().startsWith('A.1')) {
          eligibleActivityRowIndex = rowIndex;
        }
        return false;
      });
      sheet.insertRows(
        eligibleActivityRowIndex + 2,
        sheetData.eligibleAlignedActivities.map((row) => parseRow(row)),
        'i'
      );
      // Delete the sample row
      // Note: This row is provided in the template to inherit styling to added rows below it.
      // It is then removed after the rows are inserted.
      sheet.spliceRows(6, 1);

      // Insert non-eligible activities rows
      let nonEligibleActivityRowIndex = 0;
      sheet.eachRow((row, rowIndex) => {
        if (row.getCell(2).value?.toString().startsWith('A.2')) {
          nonEligibleActivityRowIndex = rowIndex;
        }
        return false;
      });
      sheet.insertRows(
        nonEligibleActivityRowIndex + 2,
        sheetData.eligibleNotAlignedActivities.map((row) => parseRow(row)),
        'i'
      );
      // Delete the sample row
      sheet.spliceRows(nonEligibleActivityRowIndex + 1, 1);
    });

    // Export workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, `${data?.companyName}_${data.reportingYear}_Taxonomy_Table.xlsx`);
    });
  } catch (error) {
    console.error(error);
  }
};
