import {
  GetDashboardMetricAnswersOnCompanyLevelQuery_,
  GetDashboardMetricAnswersOnGroupLevelQuery_,
  useEsrsAssessmentsQuery,
  useGetDashboardMetricAnswersOnCompanyLevelQuery,
  useGetDashboardMetricAnswersOnGroupLevelQuery,
} from 'models';
import { useCompanyType, useCurrentCompanyId } from 'utils/hooks';
import { useMemo } from 'react';

type GroupEsrsData = {
  id: string;
  materialStandards: GetDashboardMetricAnswersOnGroupLevelQuery_['EsrsAssessment'][number]['materialStandards'];
  subsidiaryAssessments: GetDashboardMetricAnswersOnGroupLevelQuery_['EsrsAssessment'][number]['subsidiaryAssessments'];
};

type CompanyEsrsData = {
  id: string;
  materialStandards: GetDashboardMetricAnswersOnCompanyLevelQuery_['EsrsAssessment'][number]['materialStandards'];
  parentAssessment?: GetDashboardMetricAnswersOnCompanyLevelQuery_['EsrsAssessment'][number]['parentAssessment'];
  reportingUnits: GetDashboardMetricAnswersOnCompanyLevelQuery_['EsrsAssessment'][number]['reportingUnits'];
};

export const useGetEsrsAssessmentList = () => {
  const { companyId } = useCurrentCompanyId();
  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const { data, loading } = useEsrsAssessmentsQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const esrsAssessments = useMemo(() => data?.esrsAssessments ?? [], [data]);

  const { data: companyData, loading: companyDataLoading } =
    useGetDashboardMetricAnswersOnCompanyLevelQuery({
      variables: {
        companyId,
      },
      skip: isGroup,
    });
  const companyDashboardData: CompanyEsrsData[] = useMemo(
    () =>
      companyData?.EsrsAssessment.map((a) => ({
        ...a,
        materialStandards: a.materialStandards.filter((ms) => !!ms.materialMetrics.length),
      })) ?? [],
    [companyData]
  );

  const { data: groupData, loading: groupDataLoading } =
    useGetDashboardMetricAnswersOnGroupLevelQuery({
      variables: {
        companyId,
      },
      skip: !isGroup,
    });
  const groupDashboardData: GroupEsrsData[] = useMemo(
    () =>
      groupData?.EsrsAssessment.map((a) => ({
        ...a,
        materialStandards: a.materialStandards.filter((ms) => !!ms.materialMetrics.length),
      })) ?? [],
    [groupData]
  );

  return {
    esrsAssessments,
    companyDashboardData,
    groupDashboardData,
    isGroup,
    loading: loading || companyDataLoading || groupDataLoading,
  };
};

export type GroupMetricMaterialityResult = {
  groupOnly: string[];
  subsidiariesOnly: string[];
};

export type CompanyMetricMaterialityResult = {
  companyOnly: string[];
  parentCompanyOnly: string[];
};

export const useMetricMateriality = (
  groupDashboardData: GroupEsrsData[],
  companyDashboardData: CompanyEsrsData[]
) => {
  const groupStandards = useMemo<GroupMetricMaterialityResult>(() => {
    const groupOnly: string[] = groupDashboardData.flatMap((assessment) => {
      return assessment.materialStandards.flatMap((ms) => {
        return ms.materialMetrics.map((mm) => {
          return mm.metric.reference;
        });
      });
    });

    const subsidiariesOnly: string[] = groupDashboardData
      .flatMap((assessment) => {
        return assessment.subsidiaryAssessments.flatMap((sa) => {
          return sa.materialStandards.flatMap((sms) => {
            return sms.materialMetrics.map((mm) => {
              return mm.metric.reference;
            });
          });
        });
      })
      .filter((metric) => !groupOnly.includes(metric));

    return {
      groupOnly,
      subsidiariesOnly,
    };
  }, [groupDashboardData]);

  const companyStandards = useMemo<CompanyMetricMaterialityResult>(() => {
    const companyOnly: string[] = companyDashboardData.flatMap((assessment) => {
      return assessment.materialStandards.flatMap((ms) => {
        return ms.materialMetrics.map((mm) => {
          return mm.metric.reference;
        });
      });
    });

    const parentCompanyOnly: string[] = companyDashboardData.flatMap((assessment) => {
      return (
        assessment.parentAssessment?.materialStandards.flatMap((ms) => {
          return ms.materialMetrics.map((mm) => {
            return mm.metric.reference;
          });
        }) ?? []
      );
    });

    return {
      companyOnly,
      parentCompanyOnly,
    };
  }, [companyDashboardData]);

  return { groupStandards, companyStandards };
};
