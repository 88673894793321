import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { ColumnDefResolved } from '@tanstack/react-table';
import { IconButton } from 'Atoms';
import { useEsrsAssessmentQuery } from 'models';
import { Loader } from 'Molecules';
import { NestedTable } from 'Molecules/NestedTable';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowCornerDownRight, ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { SettingsIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { useCompanyType } from 'utils/hooks';
import { MetricConfigModal } from './MetricConfigModal';
import { MetricConfigModalParent } from './MetricConfigModalParent';
import { MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { uniq } from 'lodash';
import { useMaterialMetricsPerDisclosure } from './DataCollection.hooks';
import { MaterialMetricPerDisclosure, MetricTag, TableMetricData } from './DataCollection.d';
import { BreakDownTag, MetricNameWithTag } from './DataCollectionUtils';
import { useEsrsMetricData } from '../DisclosureRequirements/Metrics/Metrics.hooks';

export const DisclosureRequirementMetricsTable = ({
  disclosureRequirementRef,
  setSelectedMetric,
  onDrawerOpen,
  setShowAlert,
}: {
  disclosureRequirementRef: string;
  setSelectedMetric: (metric: SelectedMetric) => void;
  onDrawerOpen: () => void;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
}) => {
  const { esrsAssessmentId } = useParams();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const [selectedMetricData, setSelectedMetricData] = React.useState<TableMetricData>();
  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const { data: esrsAssessmentData, loading: assessmentDataLoading } = useEsrsAssessmentQuery({
    variables: {
      esrsAssessmentId: esrsAssessmentId ?? '',
    },
  });

  const parentAssessmentId = useMemo(
    () => esrsAssessmentData?.esrsAssessment?.parentAssessment?.id,
    [esrsAssessmentData]
  );

  const { disclosureMetricData, hasParentCompany, metricDataLoading } =
    useMaterialMetricsPerDisclosure(
      disclosureRequirementRef,
      esrsAssessmentId ?? '',
      parentAssessmentId
    );

  const metrics: MaterialMetricPerDisclosure[] = useMemo(() => {
    const materialMetrics = disclosureMetricData?.assessableMetrics.filter(
      (materialMetric) =>
        materialMetric.materialByCompany[0]?.isMaterial ||
        materialMetric.materialByParent[0]?.isMaterial
    );
    return (
      materialMetrics?.filter(
        (metric) =>
          !(
            metric.parentMetricsCount.aggregate?.count !== 0 &&
            metric.parentMetrics.some((parentMetric) =>
              (materialMetrics ?? []).find((mm) => mm.reference === parentMetric.parentMetricRef)
            )
          )
      ) ?? []
    );
  }, [disclosureMetricData]);

  const recurseMetricData = (
    metric: MaterialMetricPerDisclosure,
    tag?: MetricTag
  ): TableMetricData => {
    if (tag) {
      const { title, shortTitle, ...restOfMetric } = metric;
      const mappedSubRows =
        tag.subTags[0]?.materialTagValues.map((val) => ({
          parentMetric: metric,
          ...recurseMetricData(metric, {
            value: val.tagValue,
            type: 'tag',
            subTags: tag.subTags.slice(1),
            parentMetric: metric,
          }),
        })) ?? [];
      return {
        title: tag.value,
        shortTitle: tag.value,
        isChild: true,
        type: 'tag',
        subRows: mappedSubRows,
        ...restOfMetric,
      };
    }

    const tags = metric.materialByCompany[0]?.materialMetricTags;
    const mappedSubRows = tags?.length
      ? tags[0].materialTagValues.map((tagVal) => ({
          parentMetric: metric,
          ...recurseMetricData(metric, {
            value: tagVal.tagValue,
            type: 'tag',
            subTags: tags.slice(1),
            parentMetric: metric,
          }),
        }))
      : metric.childrenMetrics?.map((cMetric) => ({
          parentMetric: metric,
          ...recurseMetricData(cMetric.childMetric as unknown as MaterialMetricPerDisclosure),
        })) ?? [];

    return {
      ...metric,
      type: 'metric',
      isChild: (metric.parentMetricsCount?.aggregate?.count ?? 0) > 0,
      isExpanded: metric.childrenMetrics.some((cm) => !!cm.childMetric?.adminPanelTags.length),
      subRows: mappedSubRows,
    };
  };
  const tableRows = metrics.map((metric) => {
    return recurseMetricData(metric);
  });

  const columns: ColumnDefResolved<TableMetricData>[] = [
    {
      header: 'Metric',
      accessorKey: 'shortTitle',
      enableSorting: false,
      meta: {
        width: '60%',
      },
      cell: ({ row }) => {
        const apTags = row.original.adminPanelTags?.map((tag) => tag.type);
        const materialMetricTags =
          row.original.materialByCompany[0]?.materialMetricTags?.map((tag) => tag.tagType) ?? [];
        const combinedTags = uniq([...apTags, ...materialMetricTags]);
        const metricNameRef = useRef<HTMLDivElement>(null);

        return (
          <HStack pl={`${row.depth * 24}px`} spacing="8px" width="100%">
            {row.getCanExpand() ? (
              <IconButton
                variant={'ghost'}
                size="xs"
                onClick={row.getToggleExpandedHandler()}
                aria-label="expand"
                icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
              ></IconButton>
            ) : row.original.metricType === 'LONG_TEXT' ? (
              <MetricTypeIcon type="text" />
            ) : (
              <MetricTypeIcon type="number" />
            )}
            <Box ref={metricNameRef} width="100%">
              <MetricNameWithTag
                name={row.original.shortTitle ?? row.original.title}
                tags={row.original.type === 'metric' ? combinedTags : []}
                rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
              />
            </Box>
          </HStack>
        );
      },
    },
    {
      header: 'Ref.',
      accessorKey: 'reference',
      meta: {
        width: '8%',
      },
      cell: ({ row }) => {
        const { metricRefNumber } = useEsrsMetricData(row.original.reference ?? '');

        return <Typography variant="body">{metricRefNumber}</Typography>;
      },
    },
    {
      header: 'Frequency',
      accessorKey: 'frequency',
      cell: ({ row }) => {
        const isParentMetricMaterial = row.original.parentMetric?.materialByCompany[0]?.isMaterial;

        if (row.original.isChild && isParentMetricMaterial)
          return (
            <HStack color="text.hint">
              <ArrowCornerDownRight color="inherit" />
              <Typography variant="body" color="inherit">
                {row.original?.parentMetric?.materialByCompany?.[0]?.frequency}
              </Typography>
            </HStack>
          );

        return (
          <Typography variant="body">{row.original.materialByCompany?.[0]?.frequency}</Typography>
        );
      },
      meta: {
        width: '10%',
      },
    },
    {
      header: 'Data gathering',
      accessorKey: 'dataGathering',
      cell: ({ row }) => {
        const isParentMetricMaterial =
          row.original.parentMetric?.materialByCompany?.[0]?.isMaterial;
        if (row.original.isChild && isParentMetricMaterial)
          return (
            <HStack color="text.hint">
              <ArrowCornerDownRight color="inherit" />
              <Typography variant="body" color="inherit">
                {row.original?.parentMetric?.materialByCompany[0]?.dataCollection === 'company'
                  ? 'Company level'
                  : row.original?.parentMetric?.materialByCompany[0]?.dataCollection ===
                    'reportingUnits'
                  ? 'Business units'
                  : 'NA'}
              </Typography>
            </HStack>
          );

        return (
          <Typography variant="body">
            {row.original.materialByCompany[0]?.dataCollection === 'company'
              ? 'Company level'
              : row.original.materialByCompany?.[0]?.dataCollection === 'reportingUnits'
              ? 'Business units'
              : 'NA'}
          </Typography>
        );
      },
      meta: {
        width: '14%',
      },
    },
    {
      header: 'Breakdown',
      accessorKey: 'breakdown',
      cell: ({ row }) => {
        if (row.original.type === 'metric')
          return <BreakDownTag metric={row.original} isGroupCompany={isGroup} />;
      },
      meta: {
        width: '10%',
      },
    },
    {
      header: '',
      accessorKey: 'settings',
      meta: {
        width: '5%',
      },
      cell: ({ row }) => {
        const parentHasAdminPanelTags = !!row.original.parentMetric?.adminPanelTags.length;
        const parentHasRequestedTags =
          !!row.original.parentMetric?.materialByParent[0]?.materialMetricTags.length;
        const parentHasAddedTags =
          !!row.original.parentMetric?.materialByCompany[0]?.materialMetricTags.length;
        const parentHasTags =
          parentHasAdminPanelTags || parentHasRequestedTags || parentHasAddedTags;

        const isDisabled = row.original.isChild && parentHasTags;

        return (
          <HStack spacing="2px" justifyContent="end">
            {row.original.type === 'metric' && (
              <IconButton
                tooltipLabel={isDisabled ? 'Settings are available on the parent metric' : ''}
                isDisabled={isDisabled}
                variant="ghost"
                size="md"
                aria-label="settings"
                icon={<SettingsIcon />}
                onClick={() => {
                  setSelectedMetricData(row.original);
                  onOpen();
                }}
              />
            )}
            <IconButton
              aria-label="learn more"
              variant="ghost"
              icon={<HelpIcon />}
              size="md"
              onClick={() => {
                setSelectedMetric({
                  reference: row.original.reference,
                  description:
                    row.original.type === 'tag'
                      ? 'This metric was generated by a breakdown of a parent metric'
                      : row.original.description ?? '',
                  tags: row.original.adminPanelTags.map((tag) => ({ type: tag.type })),
                });
                onDrawerOpen();
              }}
            />
          </HStack>
        );
      },
    },
  ];

  const groupCompanyColumns = columns.filter((column) => column.accessorKey !== 'dataGathering');

  const defaultExpadedRows = useMemo(() => {
    const expandedRows: Record<string, boolean> = {};
    tableRows.forEach((row, index) => (expandedRows[index] = row.isExpanded ?? false));
    return expandedRows;
  }, [tableRows]);

  useEffect(() => {
    Object.values(defaultExpadedRows).includes(true) && setShowAlert(true);
  }, [defaultExpadedRows]);

  if (assessmentDataLoading || metricDataLoading) return <Loader />;
  return (
    <Box w="100%">
      <NestedTable
        columns={(isGroup ? groupCompanyColumns : columns) ?? []}
        data={tableRows ?? []}
        defaultExpanded={defaultExpadedRows}
      />
      {isGroup ? (
        <MetricConfigModalParent
          isOpen={isOpen}
          onClose={onClose}
          selectedMetricData={selectedMetricData}
        />
      ) : (
        <MetricConfigModal
          isOpen={isOpen}
          onClose={onClose}
          selectedMetricData={selectedMetricData}
          hasParentCompany={hasParentCompany}
        />
      )}
    </Box>
  );
};
