import { useAllActivities } from 'Features/Screening/Screening.hooks';
import { groupBy, mapValues, uniq } from 'lodash';
import {
  ActivityCachedResult,
  BusinessUnitAssessmentResults,
  CachedResultFieldsFragment_,
  CalculatedActivityResult,
  CompanyAssessmentResults,
  ObjectiveKeyEnum,
  ScoreSection,
  ScoreSectionsEnum,
  useFlagCompanyAssessmentAsDirtyMutation,
} from 'models';
import { useMemo, useState } from 'react';
import { numberOrZero, scoreSections } from 'utils/financials';
import { ObjectiveId } from 'utils/objectives/objectivesOrder';
import {
  allNonAdaptationObjectives,
  isNotAdaptationObjective,
  sumWithUndefined,
} from 'utils/scores/taxonomyScore';
import {
  ActivityTagStatus,
  ColumnDeclaration,
  Compliance,
  FilteredTaxonomyResultColumns,
  FilteredTaxonomyResultTableRows,
  FinancialResult,
  TaxonomyResultColumns,
  TaxonomyResultObject,
  TaxonomyResultRow,
  TaxonomyResultRowKey,
  TaxonomyResultTableData,
} from './TaxonomyResultsTableTypes';
import { GENERAL_ACTIVITY_REF } from 'containers/Assessments/Assessments.hooks';

const aggregateActivityMSSCompliance = (
  activities: Array<{
    activityRef: string;
    isMSSAligned: boolean | undefined | null;
    isMSSCompleted: boolean | undefined;
  }>
): Compliance => {
  if (activities.find((activity) => activity?.isMSSCompleted === false)) {
    return 'inProgress';
  }
  if (activities.every((activity) => activity?.isMSSAligned === true)) {
    return 'compliant';
  }
  if (activities.every((activity) => activity?.isMSSAligned === false)) {
    return 'notCompliant';
  }

  return 'partiallyCompliant';
};

const calculateTotalCompliance = (activitiesCompliances: Compliance[]): Compliance => {
  if (activitiesCompliances.find((compliance) => compliance === 'inProgress')) {
    return 'inProgress';
  }
  if (activitiesCompliances.every((compliance) => compliance === 'compliant')) {
    return 'compliant';
  }
  if (activitiesCompliances.every((compliance) => compliance === 'notCompliant')) {
    return 'notCompliant';
  }

  return 'partiallyCompliant';
};

const getActivitiesListWithMSSAlignment = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[],
  companyResults?: CompanyAssessmentResults
): Array<{
  activityResultsId: string;
  activityRef: string;
  isMSSAligned: boolean | undefined | null;
  isMSSCompleted: boolean | undefined;
}> => {
  const companyLevelMSSAlignment = companyResults?.generalAssessmentResult?.cachedResult?.isAligned;
  const companyLevelMSSCompleted =
    companyResults?.generalAssessmentResult?.cachedResult?.isCompleted;

  return filteredBusinessUnitResults.flatMap((businessUnitResult) => {
    let businessUnitLevelMSSAlignment = companyLevelMSSAlignment;
    let businessUnitLevelMSSCompleted = companyLevelMSSCompleted;
    if (businessUnitResult.hasGeneralAssessment) {
      businessUnitLevelMSSAlignment =
        businessUnitResult.generalAssessmentResult.cachedResult?.isAligned;
      businessUnitLevelMSSCompleted =
        businessUnitResult.generalAssessmentResult.cachedResult?.isCompleted;
    }
    return businessUnitResult.activityResults.map((activityResults) => {
      return {
        activityResultsId: activityResults.cachedResult?.id,
        activityRef: activityResults.activityRef,
        isMSSAligned: businessUnitLevelMSSAlignment,
        isMSSCompleted: businessUnitLevelMSSCompleted,
      };
    });
  });
};

const getMinimumSocialSafeguardsColumn = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[],
  companyResults?: CompanyAssessmentResults
): Record<TaxonomyResultRowKey, TaxonomyResultColumns['minimumSocialSafeguards']> => {
  const activities = getActivitiesListWithMSSAlignment(filteredBusinessUnitResults, companyResults);

  const activitiesByReference = groupBy(activities, 'activityRef');

  const activitiesCompliances =
    activities.length === 0
      ? null
      : mapValues(activitiesByReference, aggregateActivityMSSCompliance);

  const totalCompliance =
    activitiesCompliances === null
      ? null
      : calculateTotalCompliance(Object.values(activitiesCompliances));

  return {
    eligible: totalCompliance,
    nonEligible: null,
    total: totalCompliance,
    ...activitiesCompliances,
  };
};

const getDNSHColumn = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[]
): Record<TaxonomyResultRowKey, TaxonomyResultColumns['doNoSignificantHarm']> => {
  const activityResults = filteredBusinessUnitResults.flatMap((businessUnitResults) =>
    businessUnitResults.activityResults.map((activityResult) => activityResult.cachedResult)
  );
  const activitiesByReference = groupBy(activityResults, 'activityRef');

  const getActivityDNSHCompliance = (activityResult: any): string => {
    const inProgress = activityResult.find(
      (activity: { isCompleted: any }) => !activity?.isCompleted
    );
    if (inProgress) {
      return 'inProgress';
    }

    const doesSignificantHarm = activityResult.every(
      (activity: { objectivesState: { significantHarmObjectives: string | any[] } }) =>
        activity?.objectivesState?.significantHarmObjectives?.length > 0
    );
    if (doesSignificantHarm) {
      return 'notCompliant';
    }

    const doesNoSignificantHarm = activityResult.every(
      (activity: { objectivesState: { significantHarmObjectives: string | any[] } }) =>
        activity?.objectivesState?.significantHarmObjectives?.length === 0
    );
    if (doesNoSignificantHarm) {
      return 'compliant';
    }

    return 'partiallyCompliant';
  };

  // Activity Financials
  const activityDNSHCompliances =
    activityResults.length === 0
      ? null
      : mapValues(activitiesByReference, getActivityDNSHCompliance);

  let totalCompliance: Compliance | null;
  if (activityDNSHCompliances === null) {
    totalCompliance = null;
  } else if (
    Object.values(activityDNSHCompliances).find((compliance) => compliance === 'inProgress')
  ) {
    totalCompliance = 'inProgress';
  } else if (
    Object.values(activityDNSHCompliances).every((compliance) => compliance === 'compliant')
  ) {
    totalCompliance = 'compliant';
  } else if (
    Object.values(activityDNSHCompliances).every((compliance) => compliance === 'notCompliant')
  ) {
    totalCompliance = 'notCompliant';
  } else {
    totalCompliance = 'partiallyCompliant';
  }

  return {
    eligible: totalCompliance,
    nonEligible: null,
    total: totalCompliance,
    ...activityDNSHCompliances,
  };
};

const getAlignment = (
  activities: CachedResultFieldsFragment_[],
  scObjectiveKey?: ObjectiveId,
  totalOverride?: CachedResultFieldsFragment_['financials'],
  activitiesMSSAlignment?: {
    activityResultsId: string;
    activityRef: string;
    isMSSAligned: boolean | null | undefined;
    isMSSCompleted: boolean | undefined;
  }[],
  eligible = false
): FinancialResult => {
  if (activities.find((activity) => !activity?.isCompleted) && !totalOverride) {
    return {
      revenue: { result: 'inProgress' },
      capex: { result: 'inProgress' },
      opex: { result: 'inProgress' },
    };
  }
  return scoreSections.reduce(
    (scorePerSection, section) => {
      const { total, aligned } = activities.reduce(
        (agg, activityResult) => {
          const countAligned = scObjectiveKey
            ? activityResult?.objectivesState?.substantialContributionObjectives?.includes(
                scObjectiveKey
              )
            : true;
          const isMSSNotCompliant = activitiesMSSAlignment?.find(
            (compliance) =>
              compliance.activityResultsId === activityResult.id &&
              (compliance.isMSSAligned === false || compliance.isMSSCompleted === false)
          );
          if (!!isMSSNotCompliant) {
            return {
              total: agg.total + activityResult.financials[section].total,
              aligned: agg.aligned,
            };
          }
          const containsNonAdaptationObjective =
            activityResult?.objectivesState?.substantialContributionObjectives?.some((objective) =>
              allNonAdaptationObjectives.includes(objective)
            );
          if (
            isNotAdaptationObjective(scObjectiveKey) &&
            activityResult?.objectivesState?.substantialContributionObjectives?.includes(
              ObjectiveKeyEnum.adaptation
            ) &&
            (section === ScoreSectionsEnum.capex || section === ScoreSectionsEnum.opex)
          ) {
            return {
              total: agg.total + activityResult?.financials[section].total,
              aligned: countAligned
                ? agg.aligned +
                  (activityResult?.financials[section].aligned -
                    activityResult?.financials[section].adaptation)
                : agg.aligned,
            };
          }
          if (
            scObjectiveKey == ObjectiveKeyEnum.adaptation &&
            activityResult?.objectivesState?.substantialContributionObjectives?.includes(
              ObjectiveKeyEnum.adaptation
            ) &&
            containsNonAdaptationObjective
          ) {
            return {
              total: agg.total + activityResult?.financials[section].total,
              aligned: agg.aligned + activityResult?.financials[section].adaptation,
            };
          }
          return {
            total: agg.total + activityResult?.financials[section].total,
            aligned: countAligned
              ? agg.aligned + activityResult?.financials[section].aligned
              : agg.aligned,
          };
        },
        { total: 0, aligned: 0 }
      );
      return {
        ...scorePerSection,
        [section]: {
          result: numberOrZero(
            (aligned /
              (totalOverride?.[section]
                ? eligible
                  ? totalOverride?.[section]?.eligible
                  : totalOverride?.[section]?.total
                : total)) *
              100
          ),
        },
      };
    },
    { revenue: { result: 0 }, capex: { result: 0 }, opex: { result: 0 } }
  );
};

export const getSubstantialContributionColumn = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[],
  companyResults?: CompanyAssessmentResults,
  aggregatedFinancials?: CalculatedActivityResult['financials'],
  scKey?: ObjectiveId | undefined
) => {
  if (typeof scKey == undefined) {
    return {
      total: null,
      eligible: null,
      nonEligible: null,
    };
  }
  const activities = filteredBusinessUnitResults.flatMap((businessUnitResults) =>
    businessUnitResults.activityResults
      .map((activityResult) => activityResult.cachedResult)
      .filter((activityResult): activityResult is CachedResultFieldsFragment_ => !!activityResult)
  );

  if (activities.length === 0) {
    return {
      total: { revenue: { result: 0 }, capex: { result: 0 }, opex: { result: 0 } },
      eligible: { revenue: { result: 0 }, capex: { result: 0 }, opex: { result: 0 } },
      nonEligible: null,
    };
  }

  const activitiesByReference = groupBy(activities, 'activityRef');

  const mssAlignments = getActivitiesListWithMSSAlignment(
    filteredBusinessUnitResults,
    companyResults
  );

  const activityScScores = mapValues(activitiesByReference, (activityResult) =>
    getAlignment(activityResult, scKey, null, mssAlignments)
  );

  const eligibleScScore = getAlignment(
    activities,
    scKey,
    aggregatedFinancials,
    mssAlignments,
    true
  );

  const totalScScore = getAlignment(activities, scKey, aggregatedFinancials, mssAlignments);

  return {
    total: totalScScore,
    eligible: eligibleScScore,
    nonEligible: null,
    ...activityScScores,
  };
};

const getFinancialsColumn = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[],
  _companyResults?: CompanyAssessmentResults,
  aggregatedFinancials?: CalculatedActivityResult['financials']
): Record<TaxonomyResultRowKey, TaxonomyResultColumns['financials']> => {
  const activities = filteredBusinessUnitResults.flatMap((businessUnitResult) =>
    businessUnitResult.activityResults.map((activityResult) => activityResult.cachedResult)
  );

  const getTotalFromFinancials = (f: ActivityCachedResult['financials']): FinancialResult => ({
    capex: { result: f.capex.total },
    opex: { result: f.opex.total },
    revenue: { result: f.revenue.total },
  });

  const getEligibleFromFinancials = (f: ActivityCachedResult['financials']): FinancialResult => ({
    capex: { result: f.capex.eligible },
    opex: { result: f.opex.eligible },
    revenue: { result: f.revenue.eligible },
  });

  const getNonEligibleFromFinancials = (
    f: ActivityCachedResult['financials']
  ): FinancialResult => ({
    capex: { result: f.capex.notEligible },
    opex: { result: f.opex.notEligible },
    revenue: { result: f.revenue.notEligible },
  });

  //   Total financials
  const totalFinancials = getTotalFromFinancials(aggregatedFinancials);

  //   Non-eligible financials
  const eligibleFinancials = getEligibleFromFinancials(aggregatedFinancials);

  //   Non-eligible financials
  const nonEligibleFinancials = getNonEligibleFromFinancials(aggregatedFinancials);

  const activitiesByReference = groupBy(activities, 'activityRef');

  const isPureAdaptationActivity = (cachedResult: CachedResultFieldsFragment_) => {
    return (
      cachedResult?.objectivesState?.possibleSubstantialContributionObjectives?.length === 1 &&
      cachedResult?.objectivesState?.possibleSubstantialContributionObjectives?.includes(
        ObjectiveKeyEnum.adaptation
      ) &&
      cachedResult?.activityTag !== 'ENABLING'
    );
  };

  const getActivityFinancials = (
    activityResult: ActivityCachedResult['financials']
  ): FinancialResult => {
    return {
      capex: {
        result: activityResult.reduce(
          (acc: number, obj: ActivityCachedResult) => acc + obj?.financials.capex.total,
          0
        ),
        tooltip:
          activityResult.reduce((notEligible: number, obj: ActivityCachedResult) => {
            if (isPureAdaptationActivity(obj)) {
              return notEligible + obj.financials.capex.notEligible;
            }
          }, 0) > 0
            ? 'capOpExNonEligibleWarning'
            : undefined,
      },
      opex: {
        result: activityResult.reduce(
          (acc: number, obj: ActivityCachedResult) => acc + obj?.financials.opex.total,
          0
        ),
        tooltip:
          activityResult.reduce((notEligible: number, obj: ActivityCachedResult) => {
            if (isPureAdaptationActivity(obj)) {
              return notEligible + obj?.financials.opex.notEligible;
            }
          }, 0) > 0
            ? 'capOpExNonEligibleWarning'
            : undefined,
      },
      revenue: {
        result: activityResult.reduce(
          (acc: number, obj: ActivityCachedResult) => acc + obj?.financials.revenue.total,
          0
        ),
        tooltip:
          activityResult.reduce((notEligible: number, obj: ActivityCachedResult) => {
            if (isPureAdaptationActivity(obj)) {
              return notEligible + obj?.financials.revenue.total;
            }
          }, 0) > 0
            ? 'turnoverNonEligibleWarning'
            : undefined,
      },
    };
  };

  // Activity Financials
  const activityFinancials = mapValues(activitiesByReference, getActivityFinancials);

  return {
    eligible: eligibleFinancials,
    nonEligible: nonEligibleFinancials,
    total: totalFinancials,
    ...activityFinancials,
  };
};

const getAlignmentColumn = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[],
  companyResults?: CompanyAssessmentResults,
  aggregatedFinancials?: CalculatedActivityResult['financials']
): Record<TaxonomyResultRowKey, TaxonomyResultColumns['alignment']> => {
  const activities = filteredBusinessUnitResults.flatMap((businessUnitResults) =>
    businessUnitResults.activityResults
      .map((activityResult) => activityResult?.cachedResult)
      .filter((activityResult): activityResult is CachedResultFieldsFragment_ => !!activityResult)
  );

  const activitiesByReference = groupBy(activities, 'activityRef');

  const mssAlignments = getActivitiesListWithMSSAlignment(
    filteredBusinessUnitResults,
    companyResults
  );

  const activityScores = Object.entries(activitiesByReference).reduce(
    (agg, [ref, activityResult]) => ({
      ...agg,
      [ref]: getAlignment(activityResult, undefined, null, mssAlignments),
    }),
    {}
  );

  const eligibleScore = getAlignment(
    activities,
    undefined,
    aggregatedFinancials,
    mssAlignments,
    true
  );

  const totalScore = getAlignment(activities, undefined, aggregatedFinancials, mssAlignments);
  return {
    total: totalScore,
    eligible: eligibleScore,
    nonEligible: undefined,
    ...activityScores,
  };
};

const getActivityTagStatusColumn = (
  filteredBusinessUnitResults: BusinessUnitAssessmentResults[]
): Record<TaxonomyResultRowKey, TaxonomyResultColumns['activityTagStatus']> => {
  const allActivities = filteredBusinessUnitResults
    .flatMap((businessUnitResults) =>
      businessUnitResults.activityResults.map((activityResult) => activityResult.cachedResult)
    )
    .filter((activityResult): activityResult is CachedResultFieldsFragment_ => !!activityResult);
  const activitiesByReference = groupBy(allActivities, 'activityRef');

  const getActivityTags = (
    activityCachedResults: CachedResultFieldsFragment_[]
  ): ActivityTagStatus => {
    if (
      activityCachedResults.every(
        (activityResult: ActivityCachedResult) => activityResult?.activityTag === 'GREEN'
      )
    )
      return 'green';
    if (
      activityCachedResults.every(
        (activityResult: ActivityCachedResult) => activityResult?.activityTag === 'ENABLING'
      )
    )
      return 'enabling';
    if (
      activityCachedResults.every(
        (activityResult: ActivityCachedResult) => activityResult?.activityTag === 'TRANSITIONAL'
      )
    )
      return 'transitional';
    if (
      activityCachedResults.some(
        (activityResult: ActivityCachedResult) =>
          activityResult?.activityTag === 'ENABLING' ||
          activityResult?.activityTag === 'Partially ENABLING'
      )
    )
      return 'partiallyEnabling';
    if (
      activityCachedResults.some(
        (activityResult: ActivityCachedResult) =>
          activityResult?.activityTag === 'TRANSITIONAL' ||
          activityResult?.activityTag === 'Partially TRANSITIONAL'
      )
    )
      return 'partiallyTransitional';
    return 'green';
  };

  const activityTags = mapValues(activitiesByReference, getActivityTags);

  return {
    ...activityTags,
  };
};

const transformObjectToRows = (data: TaxonomyResultObject) => {
  const { eligible, nonEligible, total, ...activities } = data;
  return [eligible, ...Object.values(activities), nonEligible, total];
};

const buildInitialResultObject = (
  activityRefsWithNames: { ref: string; name: string }[]
): TaxonomyResultObject => ({
  eligible: {
    rowKey: 'eligible',
    rowName: 'Taxonomy-eligible activities',
    columnData: {},
  },
  nonEligible: {
    rowKey: 'nonEligible',
    rowName: 'Taxonomy-non-eligible activities',
    columnData: {},
  },
  total: {
    rowKey: 'total',
    rowName: 'Total',
    columnData: {},
  },
  ...activityRefsWithNames.reduce(
    (aggregate, { ref, name }) => ({
      ...aggregate,
      [ref]: {
        rowKey: ref,
        rowName: name ?? ref,
        columnData: {},
      },
    }),
    {} as {
      [key: string]: TaxonomyResultRow<Partial<TaxonomyResultColumns>>;
    }
  ),
});

const DEFAULT_RESULT_COLUMNS: Array<ColumnDeclaration> = [
  {
    key: 'financials',
    hasFinancialSections: true,
    calculation: getFinancialsColumn,
  },
  {
    key: 'doNoSignificantHarm',
    hasFinancialSections: false,
    calculation: getDNSHColumn,
  },
  {
    key: 'alignment',
    hasFinancialSections: true,
    calculation: getAlignmentColumn,
  },
  {
    key: 'activityTagStatus',
    hasFinancialSections: false,
    calculation: getActivityTagStatusColumn,
  },
  {
    key: 'minimumSocialSafeguards',
    hasFinancialSections: false,
    calculation: getMinimumSocialSafeguardsColumn,
  },
];

export const useTaxonomyResultsTableData = (
  cAssessmentResults: CompanyAssessmentResults | undefined,
  selectedBusinessUnitIds: string[]
): {
  data: TaxonomyResultTableData | undefined;
} => {
  const activities = useAllActivities();
  const [flagAssessment] = useFlagCompanyAssessmentAsDirtyMutation();

  if (cAssessmentResults) {
    const isDataDirty = cAssessmentResults?.businessUnitResults.find(
      (bu) =>
        !!bu.activityResults.find(
          (activityResult) =>
            activityResult.activityRef !== 'NULL' &&
            activityResult.activityRef !== GENERAL_ACTIVITY_REF &&
            activityResult?.cachedResult?.objectivesState &&
            Object.keys(activityResult.cachedResult?.objectivesState)?.length === 0
        )
    );
    if (isDataDirty) {
      flagAssessment({ variables: { cachedResultId: cAssessmentResults?.cachedResult?.id } });
    }
  }

  const filteredBusinessUnitResults = useMemo(() => {
    if (!cAssessmentResults) return undefined;
    if (selectedBusinessUnitIds.length === 0) return cAssessmentResults.businessUnitResults;
    return cAssessmentResults.businessUnitResults.filter((businessUnitResult) =>
      selectedBusinessUnitIds.includes(businessUnitResult.businessUnit.id)
    );
  }, [cAssessmentResults, selectedBusinessUnitIds]);

  const aggregatedFinancials: CalculatedActivityResult['financials'] | undefined = useMemo(() => {
    if (selectedBusinessUnitIds.length > 0 && filteredBusinessUnitResults) {
      return scoreSections.reduce(
        (agg, section) => ({
          ...agg,
          [section]: {
            total: sumWithUndefined(
              ...filteredBusinessUnitResults.map(
                (bu) => bu.cachedResult?.financials[section]?.total
              )
            ),
            inProgress: sumWithUndefined(
              ...filteredBusinessUnitResults.map(
                (bu) => bu.cachedResult?.financials[section]?.inProgress
              )
            ),
            aligned: sumWithUndefined(
              ...filteredBusinessUnitResults.map(
                (bu) => bu.cachedResult?.financials[section]?.aligned
              )
            ),
            notAligned: sumWithUndefined(
              ...filteredBusinessUnitResults.map(
                (bu) => bu.cachedResult?.financials[section]?.notAligned
              )
            ),
            eligible: sumWithUndefined(
              ...filteredBusinessUnitResults.map(
                (bu) => bu.cachedResult?.financials[section]?.eligible
              )
            ),
            notEligible: sumWithUndefined(
              ...filteredBusinessUnitResults.map(
                (bu) => bu.cachedResult?.financials[section]?.notEligible
              )
            ),
          },
        }),
        {} as CalculatedActivityResult['financials']
      );
    }
    if (cAssessmentResults) {
      return cAssessmentResults?.cachedResult?.financials;
    }
  }, [cAssessmentResults, filteredBusinessUnitResults, selectedBusinessUnitIds]);

  const activityNameMap = useMemo(
    () =>
      activities.reduce(
        (nameMap, activity) => ({ ...nameMap, [activity.reference]: activity.name }),
        {}
      ),
    [activities]
  );

  const activityRefsWithNames = useMemo(
    () =>
      uniq(
        filteredBusinessUnitResults
          ?.flatMap((businessUnitResults) =>
            businessUnitResults.activityResults.map(
              (activityResult) => activityResult.cachedResult?.activityRef
            )
          )
          .filter((ref): ref is string => !!ref)
          .map((ref) => ({ ref, name: activityNameMap[ref] })) ?? []
      ),
    [filteredBusinessUnitResults, activityNameMap]
  );

  const substantialContributionColumnsToShow: ObjectiveId[] = useMemo(() => {
    const scColumns = uniq(
      filteredBusinessUnitResults?.flatMap((businessUnitResults) =>
        businessUnitResults.activityResults
          .flatMap(
            (activityResult) =>
              activityResult.cachedResult?.objectivesState
                ?.possibleSubstantialContributionObjectives
          )
          .filter((scKeyList) => scKeyList?.length > 0)
      )
    );
    return scColumns.length === 0
      ? [ObjectiveKeyEnum.mitigation, ObjectiveKeyEnum.adaptation]
      : scColumns;
  }, [filteredBusinessUnitResults]);

  const initialResultsObject = useMemo(
    () => buildInitialResultObject(activityRefsWithNames),
    [activityRefsWithNames]
  );

  const resultsColumnsWithSubstantialContributions = useMemo(() => {
    const newResultsColumns = DEFAULT_RESULT_COLUMNS;
    substantialContributionColumnsToShow.forEach((sc) =>
      newResultsColumns.push({
        key: sc,
        hasFinancialSections: true,
        calculation: getSubstantialContributionColumn,
      })
    );
    return newResultsColumns;
  }, [substantialContributionColumnsToShow, DEFAULT_RESULT_COLUMNS]);

  const resultsObject = useMemo(() => {
    const newResultObject = initialResultsObject;
    if (filteredBusinessUnitResults && cAssessmentResults)
      DEFAULT_RESULT_COLUMNS.forEach((column) => {
        const possibleSCColumn = Object.values(ObjectiveKeyEnum).find((key) => key === column.key);
        let rowObject;
        if (possibleSCColumn != undefined) {
          rowObject = column.calculation(
            filteredBusinessUnitResults,
            cAssessmentResults,
            aggregatedFinancials,
            possibleSCColumn as ObjectiveId
          );
        } else {
          rowObject = column.calculation(
            filteredBusinessUnitResults,
            cAssessmentResults,
            aggregatedFinancials
          );
        }
        Object.entries(rowObject).forEach(([rowKey, columnValue]) => {
          if (newResultObject[rowKey]) {
            // @ts-ignore
            // Typescript cant infer if the column value is right for the variable column.key
            newResultObject[rowKey].columnData[column.key] = columnValue;
          }
        });
      });
    return newResultObject;
  }, [
    selectedBusinessUnitIds,
    filteredBusinessUnitResults,
    resultsColumnsWithSubstantialContributions,
    initialResultsObject,
  ]);

  const rows = useMemo(
    () => transformObjectToRows(resultsObject),
    [transformObjectToRows, resultsObject]
  );
  if (!cAssessmentResults) return { data: undefined };
  return { data: { rows, substantialContributionColumnsToShow } };
};

export const useTaxonomyResultsTableFilter = (): {
  scoreSection: ScoreSection;
  setScoreSection: (newScoreSection: ScoreSection) => void;
} => {
  const [scoreSection, setScoreSection] = useState<ScoreSection>(ScoreSectionsEnum.revenue);

  return {
    scoreSection,
    setScoreSection,
  };
};

export const useResultsTableWithFilter = (
  cAssessmentResults: CompanyAssessmentResults | undefined,
  selectedBusinessUnitIds: string[]
): {
  data: {
    rows: FilteredTaxonomyResultTableRows;
    substantialContributionColumnsToShow: ObjectiveId[];
  };
  scoreSection: ScoreSectionsEnum;
  setScoreSection: (newSection: ScoreSectionsEnum) => void;
} => {
  const tableData = useTaxonomyResultsTableData(cAssessmentResults, selectedBusinessUnitIds);
  const { scoreSection, setScoreSection } = useTaxonomyResultsTableFilter();

  return {
    ...tableData,
    data: {
      ...tableData.data,
      rows:
        tableData.data?.rows.map((row) => ({
          ...row,
          // @ts-ignore
          // Casting the normal result to filtered result is not caught by typescript
          // Should be moved to a properly typed function that solved it
          columnData: {
            ...row.columnData,
            ...DEFAULT_RESULT_COLUMNS.filter((c) => c.hasFinancialSections).reduce(
              (filteredValues, column) => ({
                ...filteredValues,
                // @ts-ignore
                // Typescript cant infer if the column value is right for the variable column.key
                [column.key]: row?.columnData?.[column.key]?.[scoreSection],
              }),
              {}
            ),
          } as FilteredTaxonomyResultColumns,
        })) ?? [],
      substantialContributionColumnsToShow:
        tableData.data?.substantialContributionColumnsToShow ?? [],
    },
    scoreSection,
    setScoreSection,
  };
};
