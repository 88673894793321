import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { AIIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';

export type AvatarSize = 'xs' | 'sm' | 'md';

export type AvatarProps = ChakraAvatarProps & {
  size?: AvatarSize;
};

export const AvatarTheme = {
  baseStyle: {
    container: {
      backgroundColor: 'bg.accent',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'bg.default',
      fontWeight: '600',
      color: 'text.onAccent',
    },
    color: 'text.onAccent',
  },
  sizes: {
    xs: {
      container: {
        width: '20px',
        height: '20px',
        fontSize: '8px',
      },
      label: {
        color: 'white',
        fontSize: '8px',
      },
    },
    sm: {
      container: {
        width: '32px',
        height: '32px',
        fontSize: '14px',
      },
      label: {
        color: 'white',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    md: {
      container: {
        width: '40px',
        height: '40px',
        fontSize: '12px',
      },
      label: {
        color: 'white',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
  variants: {
    icon: {
      container: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'text.brand',
      },
    },
  },
};
export const Avatar = React.forwardRef(
  (props: AvatarProps, ref: React.LegacyRef<HTMLSpanElement>) => {
    const { t } = useTranslation(['common']);
    const name = props?.name || t('common:user.deletedUserName');
    if (name === 'AI') {
      return (
        <Tooltip label={name}>
          <ChakraAvatar
            ref={ref}
            icon={<AIIcon color="inherit" boxSize="12px" />}
            size={props.size}
            css={{
              ':hover': {
                cursor: 'default',
              },
            }}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip label={name}>
        <ChakraAvatar
          ref={ref}
          {...props}
          name={name}
          css={{
            ':hover': {
              cursor: 'default',
            },
          }}
        />
      </Tooltip>
    );
  }
);
