import { HStack, VStack, Box, SkeletonCircle } from '@chakra-ui/react';
import { Button, Infobox } from 'Atoms';
import { ContentLayout, HelpTooltip } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { GlobalContext, useFeatureFlags, useUserSetting } from 'containers/Navigation';
import { useCurrentCompany } from 'utils/hooks';
import { useKnowledgeBase } from 'Features';
import './Home.css';
import { HomePageLogoDesignIllustration, Typography } from 'Tokens';
import { ReportsCard, ReportItem } from 'Molecules/ReportsCard';
import { GetReportsQuery_, User, useGetPaiRequestersQuery, useGetReportsQuery } from 'models';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePaiRequests } from 'containers/CompanyPai/CompanyPai.hooks';
import { uniq, uniqBy } from 'lodash';
import { LockedIcon } from 'Tokens/Icons/Function';
import { CheckIcon } from 'Tokens/Icons/Status';
import { hotjar } from 'react-hotjar';
import { useUserData } from '@nhost/react';
import { isCelsiaSupport } from 'utils/users';
import { UserAvatar } from 'Organisms';
import { TodoIcon } from 'Tokens/Icons/Custom';
import { getFirstName } from 'utils/userFirstName';

export const CELSIA_WELCOME_MODAL = 'celsia_welcome_modal_is_closed';
export const TAXONOMY_LEARN_MORE_MODAL = 'taxonomy_learn_more_modal_is_closed';
export const PAI_LEARN_MORE_MODAL = 'pai_learn_more_modal_is_closed';

const isPaiReportDone = (
  data: GetReportsQuery_,
  report: GetReportsQuery_['paiCompanyReports'][0]
) => {
  const reportingYear = report?.year;
  const requestsOfSameYear = data?.paiCompanyRequests.filter((request) => {
    const requestYear = request.year;
    return requestYear === reportingYear;
  });
  const allInvesteeMetrics = requestsOfSameYear
    ?.map((request) => {
      const allInvestorMetrics = request.selectedIndicators
        .map((indicator) => indicator.indicator.investorMetrics.map((m) => m.investorMetric))
        .flat();
      const investeeMetrics = allInvestorMetrics
        .map((metric) => metric.investeeMetrics.map((m) => m.investeeMetricReference))
        .flat();
      return investeeMetrics;
    })
    .flat();
  return allInvesteeMetrics.every((metric) =>
    report.answers.some((answer) => answer.investeeMetricReference === metric && !!answer.data)
  );
};

const DoneStatus = () => {
  const { t } = useTranslation('home');
  return (
    <HStack>
      <CheckIcon color="text.info" />
      <Typography variant="bodyStrong" color="text.info">
        {t('common:buttons.done')}
      </Typography>
    </HStack>
  );
};

const TodoStatus = () => {
  const { t } = useTranslation('home');
  return (
    <HStack>
      <TodoIcon color="text.muted" />
      <Typography variant="bodyStrong" color="text.muted">
        {t('common:buttons.todo')}
      </Typography>
    </HStack>
  );
};

export const Home = () => {
  const { t } = useTranslation('home');
  const { onOpen } = useKnowledgeBase();
  const { company } = useCurrentCompany();
  const navigate = useNavigate();
  const companyId = useMemo(() => company?.id, [company]);
  const { assessments: hasTaxonomyAccess, pai: hasPaiAccess } = useFeatureFlags({ company });
  const { data, loading } = useGetReportsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });
  const { showInfoBoxes, setShowInfoBoxes } = useContext(GlobalContext);

  const { data: paiRequesters, loading: isLoadingRequesters } = useGetPaiRequestersQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });
  const user: User | null = useUserData();
  hotjar.identify(null, {
    isCelsia: isCelsiaSupport(user?.email),
    isReal: company?.isReal,
    environment: import.meta.env.REACT_APP_NHOST_SUBDOMAIN,
  });
  const { requests, loading: isLoadingReports } = usePaiRequests(companyId ?? undefined);

  const { taxonomyReports, paiReports } = useMemo(() => {
    const taxonomy: ReportItem[] =
      data?.taxonomyReports?.map((report) => ({
        id: report.id,
        status:
          (report.cachedResult?.progress?.financials === 100 &&
            report.cachedResult?.progress?.screening === 100) ||
          report.isLocked
            ? 'done'
            : 'todo',
        title: report.aggregate.title,
      })) ?? [];

    const pai: Array<ReportItem & { period?: string }> =
      (data &&
        requests?.map((r) => ({
          id: r.report?.id,
          title: String(r.year),
          period: r.period,
          status: r.report && isPaiReportDone(data, r.report) ? 'done' : 'todo',
          count: uniq(
            r.requests
              .map((request) => request.selectedIndicators)
              .flat()
              .filter(
                (s, _, array) =>
                  s.isNew === true &&
                  !array.some(
                    (a) => a.indicator.reference === s.indicator.reference && a.isNew === false
                  )
              )
              .map((selected) =>
                selected.indicator.investorMetrics
                  .map((metric) =>
                    metric.investorMetric.investeeMetrics.map(
                      (investee) => investee.metric.reference
                    )
                  )
                  .flat()
              )
              .flat()
          ).filter(
            (m) => r.report && r.report?.answers.every((a) => a.investeeMetricReference !== m)
          ).length,
        }))) ??
      [];

    return { taxonomyReports: taxonomy, paiReports: pai };
  }, [data, requests]);
  const [isWelcomeModalClosed, onToggleWelcomeModal] = useUserSetting(CELSIA_WELCOME_MODAL, false);
  const [isTaxonomyLearnMoreClosed, onToggleTaxonomyModal] = useUserSetting(
    TAXONOMY_LEARN_MORE_MODAL,
    false
  );
  const [isPaiLearnMoreClosed, onTogglePaiModal] = useUserSetting(PAI_LEARN_MORE_MODAL, false);
  const [hasAcceptedCommunityTerms] = useUserSetting<string | null>(
    'has-accepted-community-terms',
    null
  );
  const { community: hasCommunityAccess } = useFeatureFlags({ company });
  const firstName = getFirstName(user);

  useEffect(() => {
    if (showInfoBoxes) {
      onToggleWelcomeModal(false);
      onToggleTaxonomyModal(false);
      onTogglePaiModal(false);
    }
  }, [showInfoBoxes]);

  useEffect(() => {
    if (isWelcomeModalClosed || isTaxonomyLearnMoreClosed || isPaiLearnMoreClosed)
      setShowInfoBoxes(false);
  }, [isWelcomeModalClosed, isTaxonomyLearnMoreClosed, isPaiLearnMoreClosed]);

  return (
    <ContentLayout header={''} isLoading={loading || isLoadingRequesters}>
      <HStack width="100%" justifyContent="center">
        <VStack width="896px" alignItems="stretch" spacing="0px" justifyContent="flex-start">
          <HStack mb="24px" spacing="6px">
            {user != null ? (
              <>
                <Typography variant="h2" color="text.default">
                  {t('home:welcomeModal.welcomeCelsia,')}
                </Typography>
                <SkeletonCircle isLoaded={user != null} boxSize="20px">
                  <UserAvatar size="xs" user={user} />
                </SkeletonCircle>
                <Typography variant="h2" color="text.default">
                  {firstName}
                </Typography>
              </>
            ) : (
              <Typography variant="h2" color="text.default">
                {t('home:welcomeModal.welcomeCelsia')}
              </Typography>
            )}
          </HStack>
          <Infobox
            withIcon={false}
            title={t('home:welcomeModal.title')}
            description={t('home:welcomeModal.description')}
            status="info"
            isVisible={!isWelcomeModalClosed}
            onClose={() => onToggleWelcomeModal(true)}
            marginBottom="32px"
            padding="24px 0px 24px 28px"
            height="186px"
            textWidth="586px"
            spacing="0px"
            rightElement={
              <Box position="absolute" right="-1px" top="0">
                <HomePageLogoDesignIllustration boxSize="186px" />
              </Box>
            }
            extra={
              hasAcceptedCommunityTerms && hasCommunityAccess ? <DoneStatus /> : <TodoStatus />
            }
          />
          <VStack width="896px" alignItems="stretch" spacing="12px" justifyContent="flex-start">
            <HStack spacing="16px" height="100%" alignItems="stretch">
              <ReportsCard
                title={t('home:taxonomy.title')}
                subTitle={t('home:emptyState.taxonomy.subTitle')}
                description={t('home:taxonomy.description')}
                type="taxonomy"
                buttonText={t('home:emptyState.taxonomy.infoButton')}
                onLearnMoreClick={() => onOpen('Taxonomy assessment in 5 steps with Celsia')}
                onDismissClick={() => onToggleTaxonomyModal(true)}
                isContentVisible={!isTaxonomyLearnMoreClosed}
                requestedBy={[]}
                reportsList={
                  hasTaxonomyAccess
                    ? taxonomyReports.map((report) => ({
                        ...report,
                        onClick: () => navigate(`assessments/${report.id}`),
                      }))
                    : []
                }
                emptyState={{
                  title: t('home:emptyState.taxonomy.title'),
                  description: t('home:emptyState.taxonomy.description'),
                  action: (
                    <HStack spacing="15.33px">
                      <Button
                        leftIcon={!hasTaxonomyAccess ? <LockedIcon /> : undefined}
                        disabled={!hasTaxonomyAccess}
                        variant="secondary"
                        onClick={() => navigate('assessments/new')}
                      >
                        {t('home:emptyState.taxonomy.button')}
                      </Button>
                      {!hasTaxonomyAccess && (
                        <HelpTooltip label={t('home:emptyState.taxonomy.noAccess')} />
                      )}
                    </HStack>
                  ),
                }}
                onAllReportClick={() => navigate('assessments')}
              />
              <ReportsCard
                title={t('home:pai.title')}
                subTitle={t('home:emptyState.pai.subTitle')}
                description={t('home:pai.description')}
                type="pai"
                buttonText={t('home:emptyState.pai.infoButton')}
                onLearnMoreClick={() => onOpen('What are PAI indicators')}
                onDismissClick={() => onTogglePaiModal(true)}
                isContentVisible={!isPaiLearnMoreClosed}
                requestedBy={
                  uniqBy(
                    paiRequesters?.requesters.map((r) => ({
                      id: r.portfolio?.ownerCompany?.id,
                      name: r.portfolio?.ownerCompany?.name,
                      logoUrl: r.portfolio?.ownerCompany?.logoUrl ?? undefined,
                    })),
                    'id'
                  ) ?? []
                }
                reportsList={
                  hasPaiAccess
                    ? paiReports.map((report) => ({
                        ...report,
                        onClick: () => navigate(`pai/${report.title}/${report?.period}`),
                      }))
                    : []
                }
                emptyState={{
                  title: t('home:emptyState.pai.title'),
                  description: t('home:emptyState.pai.description'),
                }}
                onAllReportClick={() => navigate('pai')}
                isLoaded={!isLoadingReports}
              />
            </HStack>
          </VStack>
        </VStack>
      </HStack>
    </ContentLayout>
  );
};
