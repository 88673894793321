const NUM_SYMBOLS = ['', 'k', 'M', 'B', 'T'];

export const formatDigits = (num: number, fractions = 2) => {
  if (num < 0 && num >= -0.1) {
    return 0;
  }
  return num?.toLocaleString(undefined, { maximumFractionDigits: fractions });
};

export const formatNum = (num?: number, fractions?: number) => {
  if (!num) return '0';
  const tier = (Math.log10(Math.abs(num)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return formatDigits(num, fractions);

  // get suffix and determine scale
  const suffix = NUM_SYMBOLS[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = num / scale;

  // format number and add suffix
  return formatDigits(scaled, fractions) + (suffix ?? '');
};

export const isNullish = (value: number | undefined | null) =>
  value === undefined || value === null || isNaN(value);

export const per = (num: number, total: number) => (num * total) / 100;

export const formatCurrency = (value: number, currency: string) =>
  value.toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  });

export const formatNumber = (value: number) =>
  value.toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
