import { ChakraTheme, HStack, useStyleConfig, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { CardStatus, StateStatus } from 'Molecules/CardStatus';
import { InputCardWarning, InputCardWarningProps } from './InputCardWarnings';
import { InputCardDocumentation, InputCardDocumentationProps } from './InputCardDocumentation';

export const InputCardTheme: ChakraTheme['components']['InputCard'] = {
  baseStyle: {
    spacing: '0px',
    borderStyle: 'solid',
    alignItems: 'flex-start',
    width: '100%',
    borderRadius: '10px',
    borderWidth: '1px',
    borderColor: 'border.decorative',
    bg: 'bg.default',
    color: 'text.default',
  },
  variants: {
    default: {},
    selectable: {
      _hover: {
        borderColor: 'border.hover',
      },
    },
    disabled: {
      bg: 'bg.disabled',
      color: 'text.disabled',
    },
  },
};

export const INPUT_CARD_VARIANTS = Object.keys(InputCardTheme.variants ?? {});
export type InputCardVariant = (typeof INPUT_CARD_VARIANTS)[number];

export type InputCardHeaderProps = {
  status: StateStatus;
  title: string;
  extra?: React.ReactNode;
  actions?: React.ReactNode[] | React.ReactNode;
  tooltipContentOverride?: JSX.Element;
};

const InputCardHeader = ({
  status,
  title,
  extra,
  actions,
  tooltipContentOverride,
}: InputCardHeaderProps) => {
  return (
    <HStack
      padding="16px 16px 0px 16px"
      justifyContent="space-between"
      width="100%"
      alignItems="start"
    >
      <HStack spacing="12px" alignItems="start">
        <CardStatus status={status} tooltipContentOverride={tooltipContentOverride} />
        <Typography variant="h3" alignSelf="center">
          {title}
        </Typography>
        {extra}
      </HStack>
      <HStack spacing="12px">{actions}</HStack>
    </HStack>
  );
};

export const InputCard = ({
  header,
  warning = { status: 'none' },
  documentation,
  children,
  variant = 'default',
  tooltipContentOverride,
  ...props
}: {
  header?: InputCardHeaderProps;
  children: React.ReactNode;
  warning?: InputCardWarningProps;
  documentation?: InputCardDocumentationProps;
  variant?: InputCardVariant;
  tooltipContentOverride?: JSX.Element;
}) => {
  const style = useStyleConfig('InputCard', { variant });
  return (
    <VStack __css={style} {...props}>
      {header && <InputCardHeader tooltipContentOverride={tooltipContentOverride} {...header} />}
      <HStack padding="16px" justifyContent="flex-start" width="100%">
        {children}
      </HStack>
      {warning.status != 'none' && <InputCardWarning {...warning} />}
      {documentation && <InputCardDocumentation {...documentation} />}
    </VStack>
  );
};
