import { VStack, Box, HStack, useDisclosure, Textarea } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { ContentLayout, ContentHeader, HelpTooltip } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { useCompanyType, useCurrentCompany, useDebounce } from 'utils/hooks';
import { getBaselineTotal, useAddTarget, useGetTargetData } from './Targets.hooks';
import { KeyResultEnums, MilestoneFields, SELECTED_MILESTONES_ID } from '../Requirement';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { UserAssigner } from 'Organisms';
import { AddIcon, EditIcon } from 'Tokens/Icons/Function';
import { BaseLineModal } from './BaseLineModal';
import { Menu } from 'Molecules/Menu';
import { useUserData } from '@nhost/react';
import { MetricsSelector } from './TargetsUtils';
import { CheckIcon } from 'Tokens/Icons/Status';
import { TargetActionsSection } from './TargetActionsSection';
import { Milestones } from './Milestones';
import { MilestoneCard } from './Milestones/MilestoneCard';
import { getCalculatedCompanyValues } from './Milestones/Milestones.hooks';
import { TargetGraph } from './TargetGraph';
import _ from 'lodash';
import { useLocalStorage } from 'usehooks-ts';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';

export const AddTarget = () => {
  const {
    disclosureRequirementRef = '',
    esrsAssessmentId = '',
    standardRef = '',
    targetId,
  } = useParams();
  const {
    isOpen: isBaselineOpen,
    onOpen: onBaselineOpen,
    onClose: onBaselineClose,
  } = useDisclosure();

  const addTarget = useAddTarget();
  const user = useUserData();
  const { companyType, loading: typeLoading } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const currentUser = useUserData();
  const [localAnswers, setLocalAnswers] = useState<any>({});
  const debouncedAnswers = useDebounce(localAnswers, 500);
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isMilestoneView, setIsMilestoneView] = useState(false);
  const [milestoneId, setMilestoneId] = useState('');
  const [currentTargetId, setCurrentTargetId] = useState(targetId);
  const [milestoneYear, setMilestoneYear] = useState<number>();
  const [targetDirection, setTargetDirection] = useState<string>('');
  const [selectedMilestone, setSelectedMilestone] = useState<MilestoneFields>();
  const navigate = useNavigate();
  const { company } = useCurrentCompany();
  const {
    metrics,
    materialityAssessment,
    targetData,
    reportingUnits,
    subsidiaries,
    actionsData,
    parentActionsData,
    allTargets,
    actionRef,
    loading,
    initialLocalAnswers,
    allSubsidiariesIds,
  } = useGetTargetData(
    disclosureRequirementRef,
    esrsAssessmentId,
    standardRef,
    currentTargetId,
    isGroup
  );

  const metricOptions = useMemo(
    () => metrics?.metrics?.map((m) => ({ value: m?.reference, label: m.title })) ?? [],
    [metrics]
  );

  const isReadOnly = useMemo(
    () => (targetData ? !isGroup && targetData?.parentTargetId !== null : false),
    [targetData, isGroup]
  );

  const [selectedMilestoneId, setSelectedMilestoneId] = useLocalStorage<string | null>(
    SELECTED_MILESTONES_ID,
    null
  );

  const unit: string = useMemo(() => {
    const metric = metrics?.metrics?.find((m) => m.reference === localAnswers.metricRef);
    return mapUnitToCompanyCurrency(metric?.unitOfMeasurement ?? 't', company?.currency ?? '');
  }, [metrics, localAnswers.metricRef]);

  useEffect(() => {
    if (!!selectedMilestoneId) {
      setIsMilestoneView(true);
      setSelectedMilestone(
        initialLocalAnswers?.milestones.find((m) => m.id === selectedMilestoneId)
      );
      setMilestoneId(selectedMilestoneId);
    }
  }, [initialLocalAnswers]);

  useEffect(() => {
    if (!initialLocalAnswers.ownerId) {
      setLocalAnswers({ ...initialLocalAnswers, ownerId: currentUser?.id });
    } else if (initialLoad) setLocalAnswers(initialLocalAnswers);
  }, [initialLocalAnswers]);

  useEffect(() => {
    if (Object.keys(debouncedAnswers).length && !!debouncedAnswers.metricRef) {
      setIsSaving(true);
      addTarget(
        debouncedAnswers,
        targetData,
        disclosureRequirementRef,
        materialityAssessment?.materialityAssessment[0]?.id,
        reportingUnits,
        subsidiaries,
        standardRef,
        currentTargetId ?? targetId,
        setCurrentTargetId,
        setIsSaving,
        milestoneId,
        setMilestoneId
      );
      setInitialLoad(false);
    }
  }, [debouncedAnswers]);

  const baseline = useMemo(
    () =>
      isGroup
        ? getBaselineTotal(subsidiaries ?? [], localAnswers)
        : getBaselineTotal(reportingUnits ?? [], localAnswers),
    [localAnswers, isGroup, subsidiaries, reportingUnits]
  );

  const isBaselineCompleted = useMemo(() => {
    if (
      !!localAnswers[`${KeyResultEnums.baseYear}`] &&
      !!localAnswers[`${KeyResultEnums.baseline}`] &&
      subsidiaries?.every((s) => !!localAnswers[`${KeyResultEnums.baseline}_${s.id}`])
    )
      return true;
    return false;
  }, [isBaselineOpen, localAnswers[`${KeyResultEnums.baseYear}`]]);

  const extraLoading = useMemo(
    () => (window.location.href.includes('edit') ? initialLoad : false),
    [initialLoad]
  );

  const handleTargetDirection = (val: number) => {
    if (val > 0) setTargetDirection('Increase');
    else if (val < 0) setTargetDirection('Reduce');
    else setTargetDirection('Maintain');
  };

  useEffect(() => {
    if (!selectedMilestone && !!localAnswers && !!localAnswers.milestones?.length) {
      const newest: MilestoneFields = localAnswers.milestones.reduce(
        (prev: MilestoneFields, curr: MilestoneFields) => (prev.year > curr.year ? prev : curr)
      );
      setMilestoneYear(newest.year);
      handleTargetDirection(
        isGroup
          ? getCalculatedCompanyValues(
              true,
              getBaselineTotal(subsidiaries ?? [], localAnswers),
              newest?.value,
              newest.subsidiaries,
              localAnswers
            ).percentage
          : newest.value
      );
    } else
      handleTargetDirection(
        (isGroup
          ? getCalculatedCompanyValues(
              true,
              getBaselineTotal(subsidiaries ?? [], localAnswers),
              selectedMilestone?.value ?? 0,
              selectedMilestone?.subsidiaries ?? [],
              localAnswers
            ).percentage
          : selectedMilestone?.value) ?? 0
      );
  }, [selectedMilestone, localAnswers]);

  useEffect(() => {
    if (!selectedMilestone && !!localAnswers && !initialLoad)
      setLocalAnswers({
        ...localAnswers,
        direction: targetDirection.charAt(0).toLowerCase() + targetDirection.slice(1),
      });
  }, [targetDirection]);

  const relatedParentActions = useMemo(
    () =>
      parentActionsData?.filter((action) =>
        action.actionSubsidiaries?.some(
          (subsidiary) => subsidiary.subsidiaryAssessment.id === esrsAssessmentId
        )
      ) ?? [],
    [parentActionsData, esrsAssessmentId]
  );

  return (
    <ContentLayout
      isLoading={loading || typeLoading || extraLoading}
      header={
        <ContentHeader
          title={
            selectedMilestone
              ? `${targetDirection} ${targetData?.metric?.title ?? ''} by ${milestoneYear ?? ''}`
              : targetData?.metric
              ? `${targetDirection} ${targetData?.metric?.title ?? ''}`
              : 'Add target'
          }
          actions={
            isSaving ? (
              <HStack width={selectedMilestone ? '8px' : `calc(30vw + 135px)`}>
                <Typography variant="body" color="text.muted">
                  Saving...
                </Typography>
              </HStack>
            ) : (
              localAnswers.metricRef && (
                <HStack width={selectedMilestone ? '8px' : `calc(30vw + 135px)`}>
                  <CheckIcon boxSize="16px" color="text.muted" />
                  <Typography variant="body" color="text.muted">
                    All changes saved
                  </Typography>
                </HStack>
              )
            )
          }
          backButton={true}
          onBackButtonClick={() => {
            if (isMilestoneView) {
              setMilestoneId('');
              setSelectedMilestone(undefined);
              setIsMilestoneView(false);
              setSelectedMilestoneId(null);
            } else navigate(-1);
          }}
        />
      }
      padChildren={false}
    >
      {isMilestoneView ? (
        <Milestones
          isGroup={isGroup}
          subsidiaries={subsidiaries ?? []}
          reportingUnits={reportingUnits}
          localAnswers={localAnswers}
          setLocalAnswers={setLocalAnswers}
          milestoneId={milestoneId}
          setMilestoneId={setMilestoneId}
          actions={actionsData ?? []}
          parentActions={parentActionsData ?? []}
          allTargets={allTargets}
          actionRef={actionRef ?? ''}
          targetId={currentTargetId}
          parentTargetId={targetData?.parentTargetId}
          isReadOnly={isReadOnly}
          setMilestoneYear={setMilestoneYear}
          setIsMilestoneView={setIsMilestoneView}
          allSubsidiariesIds={allSubsidiariesIds}
          unit={unit}
          setSelectedMilestoneId={setSelectedMilestoneId}
        />
      ) : (
        <HStack alignItems="start" w="100%">
          <VStack alignItems="flex-start" p="24px" width={`calc(70vw - 30px)`}>
            <VStack
              width="100%"
              alignItems="flex-start"
              pl="0px"
              pr="16px"
              ml="16px"
              mb="32px"
              spacing="0px"
            >
              <Box
                position="relative"
                borderLeft="1px dashed"
                pl="32px"
                borderColor="border.hover"
                pb="40px"
              >
                <StepLabels
                  title="Select metric"
                  description="Selecting a metric on a company level means using the top-down approach. This means, you will need to set targets for your subsidiaries, and they will not be able to set their own goals for the chosen metric"
                  descriptionColor="text.default"
                  ml="33px"
                />
                <HStack>
                  <MetricsSelector
                    metricOptions={metricOptions}
                    onChange={setLocalAnswers}
                    value={localAnswers.metricRef ?? null}
                    isGroup={isGroup}
                    allTargets={allTargets}
                    localAnswers={localAnswers}
                    targetId={targetId}
                    isDisabled={isReadOnly}
                  />
                </HStack>
              </Box>
              <Box
                position="relative"
                borderLeft="1px dashed"
                pl="32px"
                borderColor="border.hover"
                pb="40px"
              >
                <StepLabels
                  title="Owner"
                  description="Select a person responsible for this target"
                  descriptionColor="text.default"
                  ml="33px"
                />
                <Box width="450px">
                  <UserAssigner
                    assignedTo={localAnswers.ownerId ?? currentUser?.id}
                    setAssignedTo={(id) => {
                      setLocalAnswers({ ...localAnswers, ownerId: id ?? currentUser?.id });
                    }}
                    placeholder="Select owner"
                    isDisabled={isReadOnly}
                  />
                </Box>
              </Box>
              <Box
                position="relative"
                borderLeft="1px dashed"
                pl="32px"
                borderColor="border.hover"
                pb="40px"
              >
                <StepLabels title="Set baseline" ml="33px" />
                {isBaselineCompleted ? (
                  <HStack
                    w="235px"
                    px="16px"
                    py="10px"
                    borderRadius="8px"
                    boxShadow="0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 0px 6px -1px rgba(0, 0, 0, 0.10);"
                    justifyContent="space-between"
                    onClick={onBaselineOpen}
                    _hover={{ cursor: 'pointer' }}
                  >
                    <VStack alignItems="start" spacing="0px">
                      <Typography variant="h3">
                        {Number.isNaN(baseline) ? 0 : baseline} {unit === 't' ? 'tonnes' : unit}
                      </Typography>
                      <Typography variant="detail" color="text.muted">
                        Company baseline for{' '}
                        {new Date(localAnswers[`${KeyResultEnums.baseYear}`]).getFullYear()}
                      </Typography>
                    </VStack>
                    <Menu
                      sections={[
                        {
                          actions: [
                            {
                              id: 'edit',
                              title: 'Edit',
                              onClick: () => onBaselineOpen(),
                              leftElement: <EditIcon color="inherit" />,
                            },
                          ],
                        },
                      ]}
                      size="sm"
                    />
                  </HStack>
                ) : (
                  <Button
                    variant="ghost"
                    leftIcon={<AddIcon />}
                    alignVertically
                    onClick={onBaselineOpen}
                  >
                    Add baseline
                  </Button>
                )}
              </Box>
              <Box
                position="relative"
                borderLeft="1px dashed"
                pl="32px"
                borderColor="border.hover"
                pb="40px"
              >
                <StepLabels title="Set milestones" ml="33px" />
                <HStack spacing="12px" mb={isReadOnly ? '0' : '12px'} wrap="wrap">
                  {_.sortBy(localAnswers.milestones, ['year'])?.map((m: MilestoneFields) => (
                    <MilestoneCard
                      onClick={() => {
                        setMilestoneId(m.id);
                        setSelectedMilestone(m);
                        setIsMilestoneView(true);
                        setSelectedMilestoneId(m.id);
                      }}
                      year={m.year}
                      target={
                        isGroup
                          ? getCalculatedCompanyValues(
                              true,
                              baseline,
                              m?.value,
                              m.subsidiaries,
                              localAnswers
                            ).percentage
                          : m.value
                      }
                    />
                  ))}
                </HStack>
                {!isReadOnly && (
                  <Button
                    variant="ghost"
                    leftIcon={<AddIcon />}
                    alignVertically
                    onClick={() => setIsMilestoneView(true)}
                  >
                    Add milestones
                  </Button>
                )}
              </Box>
              <Box
                position="relative"
                borderLeft="1px dashed"
                pl="32px"
                borderColor="border.hover"
                pb="40px"
              >
                <StepLabels title="Actions overview" ml="33px" />
                <TargetActionsSection
                  actions={actionsData ?? []}
                  parentActions={targetData?.parentTargetId ? parentActionsData ?? [] : []}
                  onChange={(val) => {
                    setLocalAnswers({ ...localAnswers, actions: val });
                  }}
                  watchedActions={localAnswers.actions}
                  actionRef={actionRef ?? ''}
                  reportingUnits={reportingUnits}
                  targetId={currentTargetId}
                  parentTargetId={targetData?.parentTargetId}
                  isGroup={isGroup}
                  isReadOnly={isReadOnly}
                  allSubsidiariesIds={allSubsidiariesIds}
                  unit={unit}
                />
              </Box>
              <Box position="relative" pl="32px">
                <StepLabels title="Provide more information" ml="33px" />
                <HStack>
                  <Typography variant="bodyStrong">Target Description</Typography>
                  <HelpTooltip />
                </HStack>
                <Typography variant="body" mb="8px">
                  Describe how to address its material pollution-related impacts, risks and
                  opportunities
                </Typography>
                <Textarea
                  placeholder="Placeholder"
                  borderColor="border.default"
                  borderRadius="8px"
                  width="560px"
                  height="80px"
                  value={localAnswers.description ?? ''}
                  onChange={(e) => {
                    setLocalAnswers({ ...localAnswers, description: e.target.value });
                  }}
                  isDisabled={isReadOnly}
                />
              </Box>
            </VStack>
            <BaseLineModal
              isOpen={isBaselineOpen}
              onClose={onBaselineClose}
              isGroup={isGroup}
              subsidiaries={subsidiaries}
              reportingUnits={reportingUnits}
              localAnswers={localAnswers}
              setLocalAnswers={setLocalAnswers}
              isReadOnly={isReadOnly}
              unit={unit}
            />
            <Button variant="primary" type="submit" onClick={() => navigate(-1)}>
              Done
            </Button>
          </VStack>
          <VStack
            alignItems="start"
            p="16px"
            h={`calc(100vh - 78px)`}
            bg="bg.muted"
            border="1px solid"
            borderColor="border.decorative"
            borderRadius="8px"
            position="fixed"
            width={`calc(29vw - 15px)`}
            right="8px"
            bottom="8px"
            top="72px"
          >
            <VStack alignItems="start" spacing="16px" w="100%">
              <VStack alignItems="start" spacing="4px">
                <Typography variant="h3">Target forecast</Typography>
                <Typography variant="body" color="text.muted">
                  Start adding target's milestones and actions and you will see. This graph will
                  also be available later in your dashboard.
                </Typography>
              </VStack>
              <TargetGraph
                localAnswers={localAnswers}
                baseline={baseline}
                isGroup={isGroup}
                actions={actionsData ?? []}
                parentActions={relatedParentActions}
                targetId={currentTargetId ?? ''}
                parentTargetId={targetData?.parentTargetId}
              />
            </VStack>
          </VStack>
        </HStack>
      )}
    </ContentLayout>
  );
};
