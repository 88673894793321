import { Popover, PopoverTrigger, PopoverContent, HStack, VStack } from '@chakra-ui/react';
import { Checkbox } from 'Atoms';
import { PortfolioCompany } from 'models';
import { SearchInput } from 'Molecules';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { ChevronRightIcon } from 'Tokens/Icons/Direction';

export const CompanySelector = ({
  companies,
  checked,
  setChecked,
  isSelected,
}: {
  companies: PortfolioCompany[];
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
  isSelected: boolean;
}) => {
  const [search, setSearch] = useState<string>('');

  const searchedCompanies = useMemo(() => {
    return companies.filter((c) => c.company?.name.toLowerCase().includes(search.toLowerCase()));
  }, [companies, search]);

  const displayedSelectedCompanies = useMemo(() => {
    return checked.map((companyId) => {
      return companies.find((company) => company?.id === companyId)?.company?.name;
    });
  }, [checked]);

  return (
    <Popover placement="right" trigger="hover" closeOnBlur={false} id="company-selector">
      <PopoverTrigger>
        <HStack width="100%" justifyContent="space-between" padding="8px">
          <VStack alignItems="flex-start" spacing="0px">
            <Typography variant="bodyStrong" color={isSelected ? 'text.selected' : ''}>
              Select companies
            </Typography>
            <Typography noOfLines={1} variant="detail">
              {checked.length === 0 ? 'None Selected' : displayedSelectedCompanies.join(', ')}
            </Typography>
          </VStack>
          <ChevronRightIcon color="inherit" />
        </HStack>
      </PopoverTrigger>

      <PopoverContent as={VStack} spacing="8px" padding="8px" width="200px" alignItems="stretch">
        <SearchInput
          search={search}
          setSearch={setSearch}
          placeholder="Search"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.key === ' ' && e.target !== e.currentTarget) {
              e.preventDefault();
            }
          }}
        />
        {searchedCompanies.length ? (
          <>
            {searchedCompanies.map((company: PortfolioCompany) => (
              <HStack
                key={`menu-${company?.id}`}
                as={HStack}
                spacing="8px"
                height="36px"
                paddingX="8px"
                _hover={{
                  bg: 'bg.hover',
                  borderRadius: '8px',
                }}
                bg={checked.includes(company.id ?? '') ? 'bg.selected' : 'transparent'}
                onClick={() =>
                  setChecked((prev) =>
                    prev.includes(company?.id)
                      ? prev.filter((i) => i !== company?.id)
                      : [...prev, company?.id]
                  )
                }
              >
                <Checkbox
                  isChecked={checked.includes(company.id)}
                  onChange={() => {
                    setChecked((prev) =>
                      prev.includes(company.id)
                        ? prev.filter((i) => i !== company.id)
                        : [...prev, company.id]
                    );
                  }}
                />
                <Typography variant="bodyStrong">{company.company?.name}</Typography>
              </HStack>
            ))}
          </>
        ) : (
          <VStack alignContent="center" paddingY="20px" textAlign="center">
            <Typography variant="body">No companies found</Typography>
          </VStack>
        )}
        {!companies.length && (
          <VStack
            alignContent="center"
            paddingX="28px"
            paddingY="20px"
            width="202px"
            textAlign="center"
          >
            <Typography variant="bodyStrong">No companies</Typography>
          </VStack>
        )}
      </PopoverContent>
    </Popover>
  );
};
