import { RefetchQueriesInclude } from '@apollo/client';
import {
  Skeleton,
  VStack,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  HStack,
  Menu,
} from '@chakra-ui/react';
import { Button, Infobox, TruncatableText } from 'Atoms';
import { Tooltip } from 'Atoms/Tooltip';
import { AttachmentBox, NoteHistory, ResolvedQuestion, ShortUser } from 'models';
import { AttachmentContainer } from 'Molecules/Attachment';
import { Note } from 'Molecules/Note';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'utils/translation';
import { useSaveNote } from './InputCardDocumentation.hooks';
import { useFeatureFlags } from 'containers/Navigation';
import { useCurrentCompany } from 'utils/hooks';
import { AIIcon } from 'Tokens/Icons/Function';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { indexToRoman } from 'containers/Assessments/VersioningOfQuestions.hooks';
import { FileWidth } from 'Molecules/File';

export type InputCardDocumentationProps = {
  currentAuthor?: ShortUser;
  documentationInfo?: string;
  noteHistory?: NoteHistory | null;
  onNoteChanged?: (newNote: string | undefined | null) => void;
  attachmentBox?: AttachmentBox | null;
  openAttachmentDrawer: () => void;
  refetchQueries?: RefetchQueriesInclude;
  isDisabled?: boolean;
  isAuditor?: boolean;
  isLoading?: boolean;
  isInputCard?: boolean;
  isVertical?: boolean;
  isHidden?: boolean;
  showSingleFile?: boolean;
  isGeneratingNote?: boolean;
  generatedNote?: string;
  width?: string;
  placeholder?: string;
  flexDirection?: 'column' | 'column-reverse';
  hideAttachments?: boolean;
  isReusable?: boolean;
  questionsToCopyFrom?: Array<ResolvedQuestion>;
  copyNotes?: (oldQuestion: ResolvedQuestion) => void;
  copyAttachments?: (oldQuestion: ResolvedQuestion) => void;
  fileWidth?: FileWidth;
};

export const InputCardDocumentation = ({
  currentAuthor,
  documentationInfo,
  noteHistory,
  attachmentBox,
  onNoteChanged,
  openAttachmentDrawer,
  refetchQueries,
  isDisabled = false,
  isAuditor = false,
  isLoading = false,
  isInputCard = true,
  isVertical = false,
  isGeneratingNote = false,
  isHidden = false,
  showSingleFile = false,
  generatedNote,
  placeholder = 'Document your choice (optional)',
  width,
  flexDirection = 'column',
  hideAttachments = false,
  isReusable = false,
  questionsToCopyFrom = [],
  copyNotes = () => {},
  copyAttachments = () => {},
  fileWidth,
}: InputCardDocumentationProps) => {
  const saveNote = useSaveNote();
  const { t } = useTranslation(['question']);
  const { company } = useCurrentCompany();

  const defaultOnNoteChanged = useCallback(
    (note: string | undefined | null) => {
      return saveNote(note ?? '', noteHistory?.id, refetchQueries);
    },
    [saveNote, noteHistory, refetchQueries]
  );

  const { drive: isFileUploadEnabled } = useFeatureFlags({ company });

  useEffect(() => {
    if (generatedNote) {
      saveNote(generatedNote, noteHistory?.id, refetchQueries, true);
    }
  }, [generatedNote]);

  return (
    <Tooltip label={isDisabled && t('question:blockDocumentationTooltip')}>
      <VStack
        borderTop={isInputCard ? '1px' : ''}
        borderColor="border.decorative"
        alignItems="start"
        width="100%"
        padding={isInputCard ? '16px' : ''}
        onClick={(clickEvent) => clickEvent.stopPropagation()}
        flexDirection={flexDirection}
      >
        {documentationInfo && <Infobox status={'neutral'} description={documentationInfo} />}
        {(!!noteHistory || !!onNoteChanged) && (
          <Skeleton isLoaded={!isLoading} width="100%">
            <Note
              noteHistory={noteHistory ?? undefined}
              currentAuthor={currentAuthor}
              handleInputChange={(newNote) =>
                onNoteChanged ? onNoteChanged(newNote) : defaultOnNoteChanged(newNote)
              }
              isDisabled={isDisabled || isHidden}
              isAuditor={isAuditor}
              isGenerating={isGeneratingNote}
              placeholder={isAuditor ? 'No documentation added' : placeholder}
            />
            {isReusable &&
              !noteHistory?.notes.length &&
              questionsToCopyFrom.some((q) => !!q.answer?.noteHistory?.notes.length) && (
                <>
                  {questionsToCopyFrom.length === 1 ? (
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => copyNotes(questionsToCopyFrom[0])}
                      color={'text.info'}
                      leftIcon={<AIIcon color="inherit" />}
                      _hover={{ bg: 'bg.selected' }}
                      _active={{
                        bg: 'bg.pressed',
                      }}
                    >
                      Reuse old note
                    </Button>
                  ) : (
                    <Menu gutter={2} closeOnSelect={false}>
                      <MenuButton
                        as={Button}
                        size="sm"
                        variant="ghost"
                        border="none"
                        color={'text.info'}
                        leftIcon={<AIIcon color="inherit" />}
                        rightIcon={<ChevronDownIcon color="inherit" />}
                        _hover={{ bg: 'bg.selected' }}
                        _active={{
                          bg: 'bg.pressed',
                        }}
                      >
                        Reuse old note
                      </MenuButton>
                      <MenuList p="8px" w="100%">
                        <MenuGroup p="8px">
                          {questionsToCopyFrom.map((q, index) => {
                            return (
                              <MenuItem p="8px" m="0px" w="100%" onClick={() => copyNotes(q)}>
                                <HStack
                                  w="100%"
                                  justifyContent="space-between"
                                  color={'text.selected'}
                                >
                                  <HStack>
                                    <TruncatableText
                                      text={`Reuse from question ${indexToRoman(index)}`}
                                      variant="bodyStrong"
                                    />
                                  </HStack>
                                </HStack>
                              </MenuItem>
                            );
                          })}
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                  )}
                </>
              )}
          </Skeleton>
        )}
        {!hideAttachments && (
          <Skeleton isLoaded={!isLoading} pb={isInputCard ? '' : '8px'} w={width}>
            <AttachmentContainer
              attachmentBox={attachmentBox ?? undefined}
              openAttachmentDrawer={openAttachmentDrawer}
              refetchQueries={refetchQueries}
              isDisabled={isDisabled || !isFileUploadEnabled}
              isVertical={isVertical}
              isLocked={isHidden}
              showSingleFile={showSingleFile}
              fileWidth={fileWidth}
            />
            {isReusable &&
              !attachmentBox?.attachments.length &&
              questionsToCopyFrom.some((q) => !!q.answer?.attachmentBox?.attachments.length) && (
                <>
                  {questionsToCopyFrom.length === 1 ? (
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => copyAttachments(questionsToCopyFrom[0])}
                      color={'text.info'}
                      leftIcon={<AIIcon color="inherit" />}
                      _hover={{ bg: 'bg.selected' }}
                      _active={{
                        bg: 'bg.pressed',
                      }}
                    >
                      Reuse old attachments
                    </Button>
                  ) : (
                    <Menu gutter={2} closeOnSelect={false}>
                      <MenuButton
                        as={Button}
                        size="sm"
                        variant="ghost"
                        border="none"
                        color={'text.info'}
                        leftIcon={<AIIcon color="inherit" />}
                        rightIcon={<ChevronDownIcon color="inherit" />}
                        _hover={{ bg: 'bg.selected' }}
                        _active={{
                          bg: 'bg.pressed',
                        }}
                      >
                        Reuse old attachments
                      </MenuButton>
                      <MenuList p="8px" w="100%">
                        <MenuGroup p="8px">
                          {questionsToCopyFrom.map((q, index) => {
                            return (
                              <MenuItem p="8px" m="0px" w="100%" onClick={() => copyAttachments(q)}>
                                <HStack
                                  w="100%"
                                  justifyContent="space-between"
                                  color={'text.selected'}
                                >
                                  <HStack>
                                    <TruncatableText
                                      text={`Reuse from question ${indexToRoman(index)}`}
                                      variant="bodyStrong"
                                    />
                                  </HStack>
                                </HStack>
                              </MenuItem>
                            );
                          })}
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                  )}
                </>
              )}
          </Skeleton>
        )}
      </VStack>
    </Tooltip>
  );
};
