import { useMemo } from 'react';
import { MetricsTableData, isFrequencyYearly, useAggregatedRowValue } from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import { QuestionType_Enum_ } from 'models';

export const useLastEdited = (
  row: MetricsTableData,
  companyStandardId: string,
  selectedQuarter: string,
  metricLevel?: boolean,
  reportingUnitId?: string
) => {
  const {
    dataPointsPerQuarter,
    dataPointPerYear,
    datapointText,
    isAggregated,
    qualitativeChildrenDatapoints,
  } = useGetDatapointValues(row, reportingUnitId);
  const isYearly = useMemo(
    () => isFrequencyYearly(row, companyStandardId),
    [row, companyStandardId]
  );
  const { result: aggregatedValues, isLoading } = useAggregatedRowValue(row, reportingUnitId);

  const getLastEditedData = (date?: Date, name?: string) => {
    if (!date) return { date: '', name: '' };
    const formattedDate = new Date(date)
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '.');
    return { date: formattedDate, name: name || '' };
  };

  if (row.metric.metricType === QuestionType_Enum_.LongText_) {
    if (isAggregated && !!qualitativeChildrenDatapoints.length) {
      const qualitativeChildrenLatestDate = useMemo(() => {
        return new Date(
          Math.max(
            ...qualitativeChildrenDatapoints.map((dp) => new Date(dp[0]?.updatedAt).getTime())
          )
        );
      }, [qualitativeChildrenDatapoints]);

      const qualitativeChildrenLatestAuthor = useMemo(() => {
        return qualitativeChildrenDatapoints.find(
          (dp) => new Date(dp[0]?.updatedAt).getTime() === qualitativeChildrenLatestDate?.getTime()
        )?.[0]?.author?.displayName;
      }, [qualitativeChildrenDatapoints, qualitativeChildrenLatestDate]);

      return getLastEditedData(qualitativeChildrenLatestDate, qualitativeChildrenLatestAuthor);
    }

    return getLastEditedData(datapointText?.updatedAt, datapointText?.author?.displayName);
  }

  if ((!isAggregated && aggregatedValues.latestAuthor) || isAggregated) {
    return getLastEditedData(aggregatedValues.latestDate, aggregatedValues.latestAuthor);
  }

  const getQuarterlyData = () => {
    if (metricLevel) {
      return dataPointsPerQuarter.reduce((a, b) =>
        a?.value?.updatedAt > b?.value?.updatedAt ? a : b
      );
    }
    return dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter);
  };

  const date = isAggregated
    ? aggregatedValues.latestDate
    : isYearly
    ? dataPointPerYear?.updatedAt
    : getQuarterlyData()?.value?.updatedAt;

  return getLastEditedData(date, getQuarterlyData()?.value?.author?.displayName);
};
