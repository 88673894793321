export const KEY_RESULTS = [
  {
    key: 'baseline',
    header: 'Baseline',
    subHeader: 'If available',
  },
  {
    key: 'shortTerm',
    header: 'Short term',
    subHeader: '1 year',
  },
  {
    key: 'mediumTerm',
    header: 'Medium term',
    subHeader: '5 year',
  },
  {
    key: 'longTerm',
    header: 'Long term',
    subHeader: '10 year',
  },
] as const;

export type LocalMilestoneFields = {
  id: string | undefined;
  year: number;
  prevYear: number | undefined;
  isBottomUp: boolean | undefined;
  value: number | undefined;
  subsidiaries: { [key: string]: any };
  reportingUnits: { [key: string]: any };
};

export type MilestoneFields = {
  id: string;
  year: number;
  prevYear: number;
  isBottomUp: boolean;
  value: number;
  subsidiaries: { [key: string]: any };
  reportingUnits: { [key: string]: any };
};

export type TargetFields = {
  [key: string]: Date | string | string[] | { [key: string]: any }[];
  direction: string;
  metricRef: string;
  owner: string;
  baseYear: Date;
  unit: string;
  baseline: string;
  shortTerm: string;
  mediumTerm: string;
  longTerm: string;
  description: string;
  actions: string[];
  milestones: MilestoneFields[];
};

export enum KeyResultEnums {
  baseYear = 'baseYear',
  baseline = 'baseline',
}

export const SELECTED_MILESTONES_ID = 'selectedMilestoneId';
export const COMPANY = 'company';
export const REPORTING_UNITS = 'reportingUnits';

export enum Direction {
  reduce = 'reduce',
  maintain = 'maintain',
  increase = 'increase',
}

export enum TargetUnit {
  relative = 'relative',
  absolute = 'absolute',
}

export const METRICS_HEADER_FIELDS = ['Q1', 'Q2', 'Q3', 'Q4', 'Year', '', ''] as const;

export const QUARTERS_FIELDS = ['Q1', 'Q2', 'Q3', 'Q4'] as const;

export const QUARTERS_ARRAY = ['Q1', 'Q2', 'Q3', 'Q4'];

export const TIME_FRAMES_ARRAY: TimePeriods[] = [...QUARTERS_FIELDS, 'Year'];

export enum FrequencyEnums {
  yearly = 'Yearly',
  quarterly = 'Quarterly',
}
export const TOTAL = 'Total';

export enum QuartersEnums {
  q1 = 'Q1',
  q2 = 'Q2',
  q3 = 'Q3',
  q4 = 'Q4',
}

export enum TimePeriodsEnums {
  q1 = 'Q1',
  q2 = 'Q2',
  q3 = 'Q3',
  q4 = 'Q4',
  year = 'Year',
}
export type QuartersType = 'Q1' | 'Q2' | 'Q3' | 'Q4';
export type TimePeriods = 'Q1' | 'Q2' | 'Q3' | 'Q4' | 'Year';

export const relativeOptions = [
  { value: TargetUnit.relative, label: 'Use relative values' },
  { value: TargetUnit.absolute, label: 'Use absolute values' },
];

export type QuartersObject = {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
};

export type YearObject = {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
  Year: number;
};

export type DRTypes = 'policy' | 'target' | 'metric' | 'action';

export enum DREnums {
  policy = 'policy',
  target = 'target',
  metric = 'metric',
  action = 'action',
}

export const UNKONWN_ROUTE = 'first';

export enum MetricViewEnums {
  overview = 'overview',
  dataInput = 'data-input',
}
