export const VisibleIf = ({
  condition,
  children,
}: {
  condition: boolean;
  children: React.ReactNode;
}) => {
  if (condition) return <>{children}</>;
  return <></>;
};
