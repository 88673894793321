import { Circle, createIcon, IconProps } from '@chakra-ui/react';

export type IconComponentType = ReturnType<typeof createIcon>;
export function SquareIconWrapper({
  icon: IconComponent,
  color = 'white',
  bg = '#161625;',
  scale = 12 / 22,
}: {
  icon: IconComponentType;
  size: string;
  color?: IconProps['color'];
  bg?: IconProps['bg'];
  scale?: number;
}) {
  return (
    <Circle bg={bg} color={color} borderRadius="6px" size="28px">
      <IconComponent boxSize={`${scale * 100}%`} color="white" />
    </Circle>
  );
}
