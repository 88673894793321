import {
  EsrsAssessmentActionsQuery_,
  useEsrsAssessmentQuery,
  useEsrsAssessmentTargetsQuery,
  useGetEsrsCategoriesQuery,
  useGetMaterialStandardsForSubsidiariesQuery,
} from 'models';
import { useMemo } from 'react';
import { MaterialMetric } from '../MaterialityAssessment';
import { Action } from '../DisclosureRequirements/Actions/Actions.hooks';

export type MaterialStandard = {
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterialForGroup: boolean;
  isMaterialForSubsidiary: boolean;
  isMaterialityMatchingSubsidiaries?: boolean;
  materialMetrics?: MaterialMetric[];
  parentMaterialMetrics?: MaterialMetric[];
};

export type EsrsCategoryMap = {
  categoryName: string;
  categoryRef: string;
  materialStandards: MaterialStandard[];
};

export type StandardsOverviewData = {
  categories: EsrsCategoryMap[];
  loading: boolean;
};

export const getGroupStandardsOverview = (filter: string, esrsAssessmentId?: string) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const {
    data: subsidiaryMaterialityAssessmentData,
    loading: loadingSubsidiaryMaterialityAssessment,
  } = useGetMaterialStandardsForSubsidiariesQuery({
    variables: { groupAssessmentId: esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return esrsCategoriesData?.EsrsCategory ?? [];
  }, [esrsCategoriesData]);

  const groupMaterialityMap: EsrsCategoryMap[] = useMemo(() => {
    const groupMaterialityAssessments = data?.esrsAssessment?.materialStandards;
    const subsidiaryMaterialityAssessments =
      subsidiaryMaterialityAssessmentData?.EsrsAssessment_by_pk?.subsidiaryAssessments;

    const allSubsidiaryAssessments =
      subsidiaryMaterialityAssessmentData?.EsrsAssessment_by_pk?.allAssessments;

    if (groupMaterialityAssessments && subsidiaryMaterialityAssessments) {
      return categories.map((category) => {
        return {
          categoryName: category.title,
          categoryRef: category.reference,
          materialStandards:
            category.standards
              ?.map((standard) => {
                return {
                  standardRef: standard.reference,
                  standardName: standard.title,
                  description: standard.description,
                  isMaterialForGroup:
                    groupMaterialityAssessments.find((materialStandard) => {
                      return materialStandard.standardRef === standard.reference;
                    })?.isMaterial === true,
                  isMaterialForSubsidiary: !!subsidiaryMaterialityAssessments?.find(
                    (subsidiaryAssessment) => {
                      return subsidiaryAssessment.materialStandards.find(
                        (materialStandard) => materialStandard.standardRef === standard.reference
                      )?.isMaterial;
                    }
                  ),
                  isMaterialityMatchingSubsidiaries: allSubsidiaryAssessments
                    ?.map((sub) =>
                      sub.materialStandards.find((ma) => ma.standardRef === standard.reference)
                    )
                    .every(
                      (subsidiaryAssessment) =>
                        subsidiaryAssessment?.isMaterial ===
                        groupMaterialityAssessments.find((materialStandard) => {
                          return materialStandard.standardRef === standard.reference;
                        })?.isMaterial
                    ),
                };
              })
              .filter((standard) => {
                if (filter === 'all') {
                  return (
                    standard.isMaterialForGroup == true || standard.isMaterialForSubsidiary == true
                  );
                } else if (filter === 'material') {
                  return standard.isMaterialForGroup == true;
                } else if (filter === 'not-material') {
                  return (
                    standard.isMaterialForSubsidiary == true && standard.isMaterialForGroup == false
                  );
                }
              }) ?? [],
        };
      });
    } else return [];
  }, [data, subsidiaryMaterialityAssessmentData, esrsCategoriesData, filter]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingSubsidiaryMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingSubsidiaryMaterialityAssessment]);

  const groupStandardsOverviewData: StandardsOverviewData = {
    categories: groupMaterialityMap,
    loading,
  };

  return groupStandardsOverviewData;
};

export const useEsrsTargets = (esrsAssessmentId?: string) => {
  const { data, loading } = useEsrsAssessmentTargetsQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const esrsTargets = useMemo(() => {
    return data?.esrs_Target.map((esrsTarget) => {
      return {
        ...esrsTarget,
        category: {
          reference: esrsTarget.disclosureRequirement.group.standard.category.reference,
          title: esrsTarget.disclosureRequirement.group.standard.category.title,
        },
        standard: {
          reference: esrsTarget.disclosureRequirement.group.standard.reference,
          title: esrsTarget.disclosureRequirement.group.standard.title,
        },
        isSubOrParent: !esrsTarget.materialStandard.esrsAssessment.company.isGroupOwner,
        companyName: esrsTarget.materialStandard.esrsAssessment.company.name,
        baseline: Number(esrsTarget?.keyResults?.[0].baseline),
        baseYear: esrsTarget.keyResults?.[0].baseYear,
        actions: esrsTarget.actionTargets.map((action: any) => ({
          title: action.action.title,
          id: action.actionId,
        })),
        numberOfActions: esrsTarget?.actionTargets.length,
        targetId: esrsTarget.id,
        milestones: esrsTarget.milestones.map((ml: any) => ({
          year: ml.year,
          value: ml.milestoneResults[0].value,
        })),
        owner: esrsTarget.owner?.displayName || '',
        title: esrsTarget.direction
          ? esrsTarget.direction.charAt(0).toUpperCase() +
            esrsTarget.direction.slice(1) +
            ' ' +
            esrsTarget?.metric?.title.toLowerCase()
          : esrsTarget?.metric?.title ?? '',
      };
    });
  }, [data]);

  return { esrsTargets, loading };
};

export const useEsrsActions = (actionsData?: EsrsAssessmentActionsQuery_) => {
  const actions = useMemo(() => {
    const mapTargets = (action: Action) => {
      return action.actionTargets.map((target: any) => ({
        title: target.target.direction
          ? target.target.direction.charAt(0).toUpperCase() +
            target.target.direction.slice(1) +
            ' ' +
            target.target.metric?.title.toLowerCase()
          : target.target.metric?.title ?? '',
        id: target.target.id,
      }));
    };
    return actionsData?.esrs_Actions.map((esrsAction) => {
      return {
        ...esrsAction,
        category: {
          reference: esrsAction.disclosureRequirement.group.standard.category.reference,
          title: esrsAction.disclosureRequirement.group.standard.category.title,
        },
        standard: {
          reference: esrsAction.disclosureRequirement.group.standard.reference,
          title: esrsAction.disclosureRequirement.group.standard.title,
        },
        isSubOrParent: !esrsAction.materialStandard.esrsAssessment.company.isGroupOwner,
        targets: mapTargets(esrsAction),
        numberOfTargets: esrsAction.actionTargets.length,
        companyName: esrsAction.materialStandard.esrsAssessment.company.name,
        actionId: esrsAction.id,
        owner: esrsAction.owner?.displayName ?? '',
        action: esrsAction,
      };
    });
  }, [actionsData]);
  return actions;
};
