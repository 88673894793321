import { Modal, Table } from 'Molecules';
import { useUserSessions } from './UserSessions.hooks';
import { ColumnDef } from '@tanstack/react-table';
import { UserSessionMetadata_ } from 'models';
import { Button } from 'Atoms';
import { useMemo, useState } from 'react';
import { Typography } from 'Tokens';

export const UserSessionsTable = () => {
  const [sessionIdToLogOut, setSessionIdToLogOut] = useState<string>();
  const { activeSessions, logOutFromSession, isLoadingLogOut } = useUserSessions();

  const columns: ColumnDef<UserSessionMetadata_>[] = useMemo(
    () => [
      {
        header: 'Browser',
        accessorKey: 'browser',
      },
      {
        header: 'OS',
        accessorKey: 'operatingSystem',
      },
      {
        header: 'Device',
        accessorKey: 'device',
      },
      {
        header: 'IP address',
        accessorKey: 'ipAddress',
      },
      {
        header: 'Login time',
        accessorKey: 'createdAt',
        cell: ({ row }) => new Date(row.original.createdAt).toLocaleString(),
      },
      {
        header: ' ',
        cell: ({ row }) => (
          <Button variant="destructive" onClick={() => setSessionIdToLogOut(row.original.id)}>
            Log out
          </Button>
        ),
      },
    ],
    [setSessionIdToLogOut]
  );

  return (
    <>
      <Table<UserSessionMetadata_> columns={columns} data={activeSessions} />
      <Modal
        isOpen={!!sessionIdToLogOut}
        onClose={() => setSessionIdToLogOut(undefined)}
        onCancel={() => setSessionIdToLogOut(undefined)}
        onConfirm={() => {
          logOutFromSession({ variables: { sessionId: sessionIdToLogOut } }).then((res) => {
            if (res.data?.deleteAuthRefreshToken?.id === sessionIdToLogOut) {
              setSessionIdToLogOut(undefined);
            }
          });
        }}
        loading={isLoadingLogOut}
        confirmVariant="destructive"
        title="Log out from session"
      >
        <br />
        <Typography variant="body">Are you sure you want to log out from this session?</Typography>
        <br />
        <Typography variant="bodyStrong">
          Note that it may take up to 15 minutes for the effect to take place.
        </Typography>
      </Modal>
    </>
  );
};
