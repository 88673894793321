import React, { useMemo } from 'react';
import { Box, ChakraTheme, HStack, useStyleConfig } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { AutoResizeTextarea, Avatar } from 'Atoms';
import { useTranslation } from 'utils/translation';
import { timeSinceDate } from 'utils/date';
import { NoteHistory, ShortUser } from 'models';
import { AvatarGroup } from 'Molecules/AvatarGroup';
import { uniq } from 'lodash';

export const NoteTheme: ChakraTheme['components']['Note'] = {
  baseStyle: {
    bg: 'bg.muted',
    minHeight: '44px',
    paddingX: '16px',
    borderRadius: '8px',
    border: '2px',
    width: '100%',
    borderColor: 'transparent',
    _focusWithin: {
      border: '2px',
      borderColor: 'border.selected.accent',
      bg: 'bg.default',
      _hover: {
        bg: 'bg.default',
      },
    },
    _hover: {
      bg: 'bg.hover',
    },
  },
};

export type NoteProps = {
  noteHistory?: NoteHistory;
  placeholder?: string;
  currentAuthor?: ShortUser;
  handleInputChange: (newNote: string | undefined | null) => void;
  isDisabled?: boolean;
  isAuditor?: boolean;
  isGenerating?: boolean;
  width?: string;
  opacity?: number;
};

const NoteStatus = ({
  authorList,
  updateText,
  isGenerating,
  isAIGenerated,
}: {
  authorList: string[];
  updateText: string;
  isGenerating: boolean;
  isAIGenerated: boolean;
}) => {
  const { t } = useTranslation('common');
  if (isGenerating) {
    return (
      <HStack paddingBottom="12px" paddingTop="8px" justifyContent="start">
        <Avatar name="AI" size="sm" />
        <Typography variant="detail" color="text.muted">
          {t('common:note.aiWriting')}
        </Typography>
      </HStack>
    );
  }
  return (
    <HStack paddingBottom="12px" paddingTop="8px" justifyContent="start">
      {authorList &&
        (isAIGenerated ? (
          <AvatarGroup size="sm" names={[...authorList, 'AI']} />
        ) : (
          <AvatarGroup size="sm" names={authorList} />
        ))}
      <Typography variant="detail" color="text.muted">
        {updateText}
      </Typography>
    </HStack>
  );
};

export const Note = ({
  noteHistory,
  placeholder,
  handleInputChange,
  currentAuthor,
  isDisabled = false,
  isAuditor = false,
  isGenerating = false,
  width,
  opacity,
}: NoteProps) => {
  const translations = useTranslation('common');
  const { t } = translations;
  const NOTE_SAVE_DELAY_MS = 1000;
  const boxStyle = useStyleConfig('Note', {});

  const [text, setText] = React.useState<string>();
  const [lastUpdated, setLastUpdated] = React.useState<Date>();
  const [authorList, setAuthorList] = React.useState<string[]>();
  const [hasFocus, setFocus] = React.useState(false);
  const [initialLoad, setInitialLoad] = React.useState(true);

  const latestNote = useMemo(() => noteHistory?.notes[0], [noteHistory]);

  React.useEffect(() => {
    if (initialLoad) {
      if (!!latestNote) {
        setText(latestNote?.body ?? '');
        setInitialLoad(false);
      }
    }
    setLastUpdated(new Date(latestNote?.updatedAt));
    setAuthorList(uniq(noteHistory?.notes.map((note) => note.author?.displayName)).slice(0, 3));
  }, [latestNote, noteHistory]);

  React.useEffect(() => {
    if (text !== latestNote?.body) {
      const saveNote = setTimeout(() => {
        handleInputChange(text);
      }, NOTE_SAVE_DELAY_MS);

      return () => clearTimeout(saveNote);
    }
  }, [text]);

  const setTextOnChange = (textArea: { target: { value: any } }) => {
    const inputValue = textArea.target.value;
    setLastUpdated(new Date());
    if (authorList?.[0] !== currentAuthor?.displayName) {
      const currentAuthorList = authorList;
      if (currentAuthorList?.length === 3) {
        currentAuthorList?.pop();
      }
      currentAuthorList?.unshift(currentAuthor?.displayName ?? '');
      setAuthorList(currentAuthorList);
    }
    setText(inputValue);
  };

  const updateText = useMemo(() => {
    if (hasFocus) {
      return t('common:note.changesSaved');
    } else if (lastUpdated) {
      const time = timeSinceDate(lastUpdated, translations);
      if (latestNote?.isAIGenerated) return t('common:note.aiGenerated', { time: time });
      return t('common:note.lastUpdated', { time: time });
    }
  }, [hasFocus, lastUpdated]);

  const hasAIGeneratedNotes = useMemo(() => {
    return noteHistory?.notes.some((note) => note.isAIGenerated);
  }, [noteHistory]);

  return (
    <Box __css={boxStyle} w={width} opacity={opacity}>
      <AutoResizeTextarea
        isDisabled={isDisabled || isAuditor || isGenerating}
        placeholder={placeholder ? placeholder : t('common:note.documentOptional')}
        value={text ?? undefined}
        noOfLines={isDisabled ? undefined : 5}
        onChange={(textArea) => setTextOnChange(textArea)}
        onClick={() => {
          setFocus(true);
        }}
        onBlur={() => {
          if (text !== latestNote?.body) {
            handleInputChange(text);
          }
          setFocus(false);
        }}
        paddingTop="12px"
        variant="unstyled"
        maxHeight={isDisabled ? '100%' : '112px'}
        textOverflow="ellipsis"
        textColor="text.muted"
        _placeholder={{
          opacity: '1',
          color: 'text.muted',
        }}
        _focus={{
          maxHeight: '400px',
          noOfLines: -1,
          _placeholder: {
            opacity: '1',
            color: 'text.hint',
          },
        }}
      />
      {updateText && text && (
        <NoteStatus
          authorList={authorList ?? []}
          isGenerating={isGenerating}
          isAIGenerated={(latestNote?.isAIGenerated || hasAIGeneratedNotes) ?? false}
          updateText={updateText}
        />
      )}
    </Box>
  );
};
