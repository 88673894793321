import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

export const useSwitchCompany = () => {
  const { companyId: urlCompanyId } = useParams();
  const navigate = useNavigate();
  const [, setLocalStorageCompanyId] = useLocalStorage<string | null>('currentCompanyId', null);

  const switchCompany = useCallback(
    (newCompanyId: string) => {
      if (newCompanyId === urlCompanyId) {
        return;
      }
      navigate(`/${newCompanyId}`);
    },
    [urlCompanyId, setLocalStorageCompanyId]
  );

  return switchCompany;
};
