import { Box, HStack } from '@chakra-ui/react';
import { uniqBy } from 'lodash';
import { SearchInput, Select } from 'Molecules';
import { useState, useMemo, useEffect } from 'react';
import { GetActionsQuery_ } from 'models';
import { getIsWithinYearRange } from 'containers/Esrs/utils';

type Date = { value: number | string; label: string };
type Option = { value: string; label: string };

const allYearsOption = {
  value: 'All',
  label: 'All years',
};

const allSubsidiariesOption = {
  value: 'All',
  label: 'All subsidiaries',
};

const allReportingUnitsOption = {
  value: 'All',
  label: 'All business units',
};

const filterActions = (
  actions: GetActionsQuery_['actions'],
  selectedYear: number | string,
  searchValue: string,
  selectedSubsidiary?: Option,
  selectedReportingUnit?: Option
) => {
  return actions.filter((action) => {
    const searchFilter = action.title.toLowerCase().includes(searchValue.toLowerCase());

    const subsidiaryFilter =
      !selectedSubsidiary ||
      selectedSubsidiary?.value === 'All' ||
      action.actionSubsidiaries.some(
        (s) => s.subsidiaryAssessment?.id === selectedSubsidiary?.value
      );

    const ruFilter =
      !selectedReportingUnit ||
      selectedReportingUnit?.value === 'All' ||
      action.actionReportingUnits.some(
        (ru) => ru.reportingUnit?.id === selectedReportingUnit?.value
      );

    const yearFilter =
      selectedYear === 'All' ||
      getIsWithinYearRange(action.startDate, action.deadline, Number(selectedYear));

    return searchFilter && yearFilter && subsidiaryFilter && ruFilter;
  });
};

export const ActionFilters = ({
  actions,
  setFilteredActions,
  parentActions,
  setFilteredParentActions,
  isGroup,
}: {
  actions: GetActionsQuery_['actions'];
  setFilteredActions: (action: GetActionsQuery_['actions']) => void;
  parentActions: GetActionsQuery_['actions'];
  setFilteredParentActions: (action: GetActionsQuery_['actions']) => void;
  isGroup: boolean;
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [date, setDate] = useState<Date>(allYearsOption);
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<Option>(allSubsidiariesOption);
  const [selectedReportingUnit, setSelectedReportingUnits] =
    useState<Option>(allReportingUnitsOption);

  const yearsOptions = useMemo(() => {
    const years = new Set([
      ...[...actions, ...parentActions].map((a) => new Date(a.startDate).getFullYear()),
      ...[...actions, ...parentActions].map((a) => new Date(a.deadline).getFullYear()),
    ]);

    const minYear = Math.min(...years);
    const maxYear = Math.max(...years);
    const allYears: Date[] = [allYearsOption];
    for (let year = minYear; year <= maxYear; year++) {
      allYears.push({
        value: year,
        label: String(year),
      });
    }
    return allYears;
  }, [actions, parentActions]);

  const subsidiaryOptions = useMemo(
    () => [
      allSubsidiariesOption,
      ...uniqBy(
        actions.flatMap((action) =>
          action.actionSubsidiaries.flatMap((s) => ({
            value: s.subsidiaryAssessment?.id,
            label: s.subsidiaryAssessment?.company?.name,
          }))
        ),
        'value'
      ),
    ],
    [actions]
  );

  const reportingUnitsOptions = useMemo(
    () => [
      allReportingUnitsOption,
      ...uniqBy(
        actions.flatMap((action) =>
          action.actionReportingUnits.flatMap((ru) => ({
            value: ru.reportingUnit.id,
            label: ru.reportingUnit.name ?? '',
          }))
        ),
        'value'
      ),
    ],
    [actions]
  );

  const filteredActionsArray = useMemo(() => {
    if (
      !searchValue &&
      date.value === 'All' &&
      selectedSubsidiary.value === 'All' &&
      selectedReportingUnit.value === 'All'
    )
      return actions;
    const selectedYear = date.value === 'All' ? 0 : Number(date.value);
    return filterActions(
      actions,
      selectedYear,
      searchValue,
      selectedSubsidiary,
      selectedReportingUnit
    );
  }, [searchValue, date.value, selectedSubsidiary, selectedReportingUnit, actions]);

  const filteredParentActionsArray = useMemo(() => {
    if (!searchValue && date.value === 'All') return parentActions;
    const selectedYear = date.value === 'All' ? 0 : Number(date.value);
    return filterActions(parentActions, selectedYear, searchValue);
  }, [searchValue, date.value, parentActions]);

  useEffect(() => setFilteredActions(filteredActionsArray), [filteredActionsArray]);

  useEffect(
    () => setFilteredParentActions(filteredParentActionsArray),
    [filteredParentActionsArray]
  );

  return (
    <HStack justifyContent="space-between" w="100%">
      <SearchInput
        placeholder="Search action"
        search={searchValue}
        setSearch={setSearchValue}
        minW="175pxpx"
      />
      <HStack spacing="8px">
        <Box w="175px">
          <Select<Date>
            defaultValue={allYearsOption}
            options={yearsOptions}
            value={date}
            onChange={(value) => setDate(value ?? allYearsOption)}
            placeholder="Select year"
          />
        </Box>
        <Box w="175px">
          {isGroup ? (
            <Select<Option>
              defaultValue={allSubsidiariesOption}
              options={subsidiaryOptions}
              value={selectedSubsidiary}
              onChange={(value) => setSelectedSubsidiary(value ?? allSubsidiariesOption)}
            />
          ) : (
            <Select<Option>
              defaultValue={allReportingUnitsOption}
              options={reportingUnitsOptions}
              value={selectedReportingUnit}
              onChange={(value) => {
                setSelectedReportingUnits(value ?? allReportingUnitsOption);
              }}
            />
          )}
        </Box>
      </HStack>
    </HStack>
  );
};
