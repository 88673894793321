import { QuartersEnums } from './pieces/DisclosureRequirements/Requirement';

export const getIsWithinYearRange = (startYear: number, endYear: number, selectedYear: number) => {
  const start = new Date(startYear).getFullYear();
  const end = new Date(endYear).getFullYear();

  const isWithinSelectedYearRange =
    selectedYear === 0 ||
    (start >= selectedYear && start <= selectedYear) ||
    (end >= selectedYear && end <= selectedYear) ||
    (start < selectedYear && end > selectedYear);
  return isWithinSelectedYearRange;
};

export const mapUnitToCompanyCurrency = (unit: string, companyCurrency: string) => {
  if (unit === 'currency') {
    return companyCurrency;
  } else if (unit.includes('Monetary unit')) {
    return unit.replace(/Monetary unit/g, companyCurrency);
  } else if (unit.includes('€')) {
    return unit.replace(/€/g, companyCurrency);
  } else return unit;
};

export const createGraphObject = (
  isYearly: boolean,
  q1: number,
  q2: number,
  q3: number,
  q4: number,
  year: number
) => {
  return isYearly
    ? {
        [QuartersEnums.q1]: 0,
        [QuartersEnums.q2]: 0,
        [QuartersEnums.q3]: 0,
        [QuartersEnums.q4]: 0,
        Year: year,
      }
    : {
        [QuartersEnums.q1]: q1,
        [QuartersEnums.q2]: q2,
        [QuartersEnums.q3]: q3,
        [QuartersEnums.q4]: q4,
      };
};

export const stringToNumber = (value: string | null | undefined) => {
  if (value == null && value == undefined) {
    return value;
  }
  return Number(value);
};
