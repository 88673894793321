import { Box } from '@chakra-ui/react';
import { Button, Tooltip } from 'Atoms';
import { useFeatureFlags } from 'containers/Navigation';
import { DocsGenerator } from 'Features/DocsGenerator';
import { ExcelGenerator } from 'Features/ExcelGenerator';
import { CompanyAssessment, CompanyAssessmentResults, CompanyDetails } from 'models';
import { Menu } from 'Molecules/Menu';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { sendHotjarEvent } from 'utils/hotjar';
import { useTranslation } from 'utils/translation';

export const TaxonomyExportMenu = ({
  company,
  cAssessmentResults,
  assessment,
  isGroup = false,
}: {
  company?: CompanyDetails;
  cAssessmentResults?: CompanyAssessmentResults;
  assessment?: CompanyAssessment;
  isGroup?: boolean;
}) => {
  const { t } = useTranslation('assessment');
  const { isPayingCustomer } = useFeatureFlags({ company });

  if (!isPayingCustomer)
    return (
      <Tooltip
        label={t('common:trial.lockedFeatureMessage')}
        onOpen={() => sendHotjarEvent('hover_report_download')}
      >
        <Box>
          <Button
            rightIcon={<ChevronDownIcon color="inherit" />}
            variant="primary"
            disabled={!isPayingCustomer}
          >
            {t('export.download')}
          </Button>
        </Box>
      </Tooltip>
    );

  return (
    <Menu
      {...{
        gutter: 2,
      }}
      size="lg"
      onMenuButtonClick={() => sendHotjarEvent('click_report_download')}
      menuButton={
        <Button rightIcon={<ChevronDownIcon color="inherit" />} variant="primary">
          {t('export.download')}
        </Button>
      }
      sections={[
        {
          actions: [
            {
              id: 'generate-docs',
              render: () => (
                <DocsGenerator
                  assessment={assessment}
                  cAssessmentResults={cAssessmentResults}
                  company={company}
                />
              ),
            },
            {
              id: 'generate-table',
              render: () => <ExcelGenerator assessment={assessment} isGroup={isGroup} />,
            },
          ],
        },
      ]}
    />
  );
};
