import {
  Box,
  MenuButton,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverContent,
} from '@chakra-ui/react';
import { Button, IconButton } from 'Atoms';
import { Menu } from 'Molecules/Menu';
import { DescriptionIcon, ResourceIcon, TeamMembersIcon } from 'Tokens/Icons/Data';
import { AIIcon, CommentIcon } from 'Tokens/Icons/Function';
import { QuestionMarkIcon } from 'Tokens/Icons/Status';
import { GlobalContext } from 'containers/Navigation';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { sendHotjarEvent } from 'utils/hotjar';
import { useTranslation } from 'utils/translation';
import * as freshchat from 'utils/freshchat';
import { Typography } from 'Tokens';

const esrsUrlPattern = /^\/[a-f0-9-]+(\/esrs\/[a-f0-9-]+)(\?.*)?$/;
const homeUrlPattern = /^\/[a-f0-9-]+(\?.*)?$/;

export const HelpMenu = () => {
  const { t } = useTranslation('common');
  const [chatOpen, setChatOpen] = useState(false);
  const { companyId } = useParams();
  const navigate = useNavigate();
  const {
    showWalktourTooltip,
    setWalktourActive,
    setHelpCenterOpen,
    setShowInfoBoxes,
    setShowWalktourTooltip,
  } = useContext(GlobalContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuHovered, setMenuHovered] = useState(false);

  freshchat.setOnClose(() => setChatOpen(false));

  const isEsrsMatch = useMemo(() => {
    const currentURL = window.location.href.replace(/^https?:\/\/[^/]+/, '');
    return esrsUrlPattern.test(currentURL);
  }, [window.location.href]);

  const isHomeMatch = useMemo(() => {
    const currentURL = window.location.href.replace(/^https?:\/\/[^/]+/, '');
    return homeUrlPattern.test(currentURL);
  }, [window.location.href]);

  const hasWalktour = useMemo(() => isEsrsMatch || isHomeMatch, [isHomeMatch, isEsrsMatch]);

  useEffect(() => {
    showWalktourTooltip &&
      setTimeout(() => {
        setShowWalktourTooltip(false);
      }, 5000);
  }, [showWalktourTooltip]);

  return (
    <Popover
      placement="left-start"
      isOpen={showWalktourTooltip}
      onClose={() => {
        setShowWalktourTooltip(false);
      }}
      closeOnBlur={true}
      isLazy={true}
    >
      <PopoverAnchor>
        <Box position="fixed" bottom="20px" right="20px">
          <Menu
            customMenuButton={
              !chatOpen ? (
                <MenuButton
                  as={IconButton}
                  borderRadius="50%"
                  bgColor="bg.accent"
                  border="none"
                  icon={<QuestionMarkIcon color="text.onAccent" />}
                  disableActive={true}
                  tooltipPlacement={'top-end'}
                  onMouseLeave={() => {
                    setMenuHovered(false);
                    setShowWalktourTooltip(false);
                  }}
                  onMouseEnter={() => setMenuHovered(true)}
                  tooltipHidden={!menuHovered}
                  tooltipOpen={menuHovered && !showWalktourTooltip}
                  tooltipLabel={!menuOpen ? t('common:helpMenu.tooltip') : ''}
                />
              ) : (
                <></>
              )
            }
            setMenuOpen={setMenuOpen}
            size="md"
            sections={[
              {
                actions: [
                  {
                    id: 'helpCenter',
                    title: t('common:helpMenu.helpCenter'),
                    leftElement: <ResourceIcon color="inherit" />,
                    onClick: () => setHelpCenterOpen(true),
                  },
                  {
                    id: 'logout',
                    title: t('common:helpMenu.messageUs'),
                    leftElement: <CommentIcon color="inherit" />,
                    onClick: () => {
                      freshchat.open();
                      setChatOpen(true);
                    },
                  },
                  {
                    id: 'community',
                    title: t('common:helpMenu.community'),
                    leftElement: <TeamMembersIcon color="inherit" />,
                    onClick: () => navigate(`/${companyId}/community`),
                  },
                ],
              },
              {
                actions: [
                  {
                    id: 'feedback',
                    title: t('common:helpMenu.feedback'),
                    leftElement: <DescriptionIcon color="inherit" />,
                    onClick: () => sendHotjarEvent('click_submit_feedback'),
                  },
                ],
              },

              {
                actions: [
                  {
                    id: 'startTour',
                    title: t('common:helpMenu.startTour'),
                    leftElement: <AIIcon color="inherit" />,
                    onClick: () => {
                      isEsrsMatch ? setWalktourActive(true) : setShowInfoBoxes(true);
                    },
                  },
                ],
              },
            ].filter((action) =>
              hasWalktour ? true : action.actions.some((act) => act.id !== 'startTour')
            )}
          />
        </Box>
      </PopoverAnchor>

      <PopoverContent bg="text.info" border="none" w="220px">
        <PopoverArrow bg="text.info" border="none" />
        <Typography variant="body" padding="8px" color="text.onAccent">
          {t('common:helpMenu.walktourTooltip')}
        </Typography>
      </PopoverContent>
    </Popover>
  );
};
