import { Box, HStack, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { IconButton, Button } from 'Atoms';
import {
  Activity,
  AttachmentBox,
  GetActivityDocumentationDocument_,
  NoteHistory,
  ShortUser,
  useGetActivityDocumentationQuery,
} from 'models';
import { AlertDialog } from 'Molecules';
import { InputCard, InputCardVariant } from 'Molecules/InputCard';
import { InputCardDocumentationProps } from 'Molecules/InputCard/InputCardDocumentation';
import { TruncatedTagList } from 'Molecules/TruncatedTagList';
import React, { useMemo } from 'react';
import { Typography } from 'Tokens';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { CheckIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';

export function ActivityNaceCodes({
  activity,
  truncate = false,
}: {
  activity: Activity;
  truncate?: boolean;
}) {
  const codes = activity.naceCodes.map((code) => code.code);
  return <TruncatedTagList tags={codes} lines={truncate ? 1 : 3} />;
}

export function ActivityCard({
  activity,
  activityReportId,
  isSelected = false,
  onChange,
  onLearnClicked,
  variant = 'default',
  removeActivity,
  openAttachmentDrawer,
}: React.PropsWithChildren<{
  activity: Activity;
  activityReportId?: string;
  isSelected?: boolean;
  onChange?: (checked: boolean) => void;
  onLearnClicked: () => void;
  removeActivity?: () => void;
  variant?: InputCardVariant;
  openAttachmentDrawer: (box?: AttachmentBox) => void;
}>) {
  const { t } = useTranslation(['bUnits', 'common']);
  const user: ShortUser | null = useUserData();

  const [selected, setSelected] = React.useState(isSelected);
  const [showAlert, setShowAlert] = React.useState(false);

  const { data: documentationData } = useGetActivityDocumentationQuery({
    variables: {
      activityReportId: activityReportId,
    },
    skip: !activityReportId,
  });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.ActivityReport_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.ActivityReport_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );

  function toggleSelected() {
    if (variant === 'selectable') {
      const selectedDebounce = setTimeout(() => {
        setSelected(!selected);
        if (onChange !== undefined) {
          onChange(!selected);
        }
      }, 10);

      return () => clearTimeout(selectedDebounce);
    }
  }

  const showDocumentation: InputCardDocumentationProps | undefined = useMemo(() => {
    if (((variant === 'selectable' && selected) || variant === 'default') && activityReportId) {
      return {
        currentAuthor: user ?? undefined,
        attachmentBox: attachmentBox,
        noteHistory: noteHistory,
        openAttachmentDrawer: () => openAttachmentDrawer(attachmentBox),
        refetchQueries: [GetActivityDocumentationDocument_],
      };
    }
  }, [variant, selected, documentationData, attachmentBox, noteHistory, activityReportId]);

  return (
    <InputCard
      documentation={showDocumentation}
      variant={variant}
      {...{
        onClick: () => toggleSelected(),
        borderColor: selected ? 'border.selected.accent' : 'border.default-decorative',
        borderWidth: selected ? '2px' : '1px',
        _hover: {
          borderColor: selected
            ? 'border.selected.accent'
            : variant === 'selectable'
            ? 'border.hover'
            : 'border.default',
        },
        cursor: 'pointer',
      }}
    >
      <HStack alignItems="start" justifyContent="space-between" width="100%">
        {variant == 'selectable' && (
          <CheckIcon
            color={selected ? 'white' : 'transparent'}
            boxSize="16px"
            padding="2px"
            bg={selected ? 'bg.selected.accent' : 'bg.default'}
            borderRadius="3px"
            borderWidth="2px"
            borderColor="border.default"
            border={selected ? 'none' : ''}
            _hover={
              selected
                ? {
                    bg: 'bg.selected.accent',
                  }
                : {
                    bg: 'bg.default',
                    borderColor: 'border.hover',
                  }
            }
          />
        )}
        <VStack spacing="16px" width="100%" alignItems="start">
          <Typography>{activity.name}</Typography>
          <ActivityNaceCodes activity={activity} />
          <Button
            aria-label={''}
            onClick={(clickEvent) => {
              onLearnClicked();
              clickEvent.stopPropagation();
            }}
            size="sm"
            variant="secondary"
          >
            {t('common:showDescription')}
          </Button>
        </VStack>
        {variant == 'default' && (
          <IconButton
            icon={<DeleteIcon color="inherit" />}
            aria-label={''}
            onClick={(clickEvent) => {
              setShowAlert(true);
              clickEvent.stopPropagation();
            }}
            tooltipLabel={t('common:actions.removeActivity')}
            variant="ghost"
          />
        )}
        <AlertDialog
          title={<Box> {t('bUnits:activities.removeActivityConfirm')} </Box>}
          onConfirm={() => {
            if (!!removeActivity) {
              removeActivity();
            }
            setShowAlert(false);
          }}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
          confirmLabel={t('bUnits:activities.confirm')}
        >
          <VStack spacing="md" alignItems="flex-start">
            <Typography>
              {t('bUnits:activities.removeConfirmation')}
              <b>&quot;{activity.name}&quot;</b>?
            </Typography>
            <Typography>{t('bUnits:activities.cantUndo')}</Typography>
          </VStack>
        </AlertDialog>
      </HStack>
    </InputCard>
  );
}
