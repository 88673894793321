import {
  Alert,
  AlertIcon,
  AlertProps,
  AlertTitle,
  CloseButton,
  AlertDescription,
  HStack,
  VStack,
  UseToastOptions,
  AlertDialogProps,
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { Button } from 'Atoms';
import React from 'react';
import { FocusableElement } from '@chakra-ui/utils';
import { useTranslation } from 'utils/translation';

export type AlertWithActionsProps = {
  actions?: { name: string; onClick: () => void; icon?: React.ReactElement }[];
  onClose?: () => void;
} & Pick<UseToastOptions, 'title' | 'description' | 'id'> &
  Pick<AlertProps, 'variant' | 'status'>;

export function AlertWithActions({
  title,
  id,
  description,
  actions,
  onClose,
  ...props
}: AlertWithActionsProps) {
  return (
    <Alert
      alignItems="flex-start"
      id={String(id)}
      borderRadius="base"
      {...props}
      shadow="0px 7px 16px rgba(0, 0, 0, 0.16);"
    >
      <AlertIcon />
      <VStack alignItems="flex-start" flexGrow={1}>
        <AlertTitle>{title}</AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
        {actions && actions.length && (
          <HStack>
            {actions.map(({ name, onClick, icon }) => (
              <Button size="sm" key={name} onClick={onClick} leftIcon={icon}>
                {name}
              </Button>
            ))}
          </HStack>
        )}
      </VStack>
      <CloseButton onClick={onClose} color="primary.main" marginRight="-8px" fontSize="16px" />
    </Alert>
  );
}

export function AlertDialog({
  isOpen,
  onClose,
  title,
  children,
  confirmLabel,
  onConfirm,
}: Pick<AlertDialogProps, 'isOpen' | 'onClose'> & {
  title: React.ReactNode;
  children: React.ReactNode;
  confirmLabel: string;
  onConfirm: () => Promise<any> | void;
}) {
  const cancelRef = React.useRef<FocusableElement>(null);
  const [isBlocked, setIsBlocked] = React.useState(false);
  const handleConfirm = () => {
    const promise = onConfirm();
    if (promise?.then) {
      setIsBlocked(true);
      promise.then(() => {
        setIsBlocked(false);
        onClose();
      });
    } else onClose();
  };
  const { t } = useTranslation();
  return (
    <ChakraAlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnEsc={!isBlocked}
      closeOnOverlayClick={!isBlocked}
      autoFocus
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius="2xl">
          <AlertDialogHeader fontSize="h2" fontWeight="bold">
            {title}
            <AlertDialogCloseButton isDisabled={isBlocked} color="primary.main" fontSize="0.6em" />
          </AlertDialogHeader>
          <AlertDialogBody>{children}</AlertDialogBody>
          <AlertDialogFooter justifyContent="flex-start">
            <HStack>
              <Button onClick={handleConfirm} size="lg" variant="destructive">
                {confirmLabel}
              </Button>
              <Button
                // ref={cancelRef as React.LegacyRef<HTMLButtonElement>}
                onClick={onClose}
                isDisabled={isBlocked}
                size="lg"
                variant="ghost"
              >
                {t('common:button.cancel')}
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
}
