import { TFunction as I18NTFunction } from 'i18next';
import { useTranslation as useNextTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { getLangNameFromCode } from 'language-name-map';
import './index.d';

const ALL_LANGUAGES = ['en', 'no', 'de'];

export type TFunction = I18NTFunction;

export const escapeKey = (key: string) => key.replace('.', '$');

export { Trans } from 'react-i18next';

export function useTranslation(
  namespaces?: Parameters<typeof useNextTranslation>[0],
  options?: Parameters<typeof useNextTranslation>[1]
) {
  return useNextTranslation(namespaces, { useSuspense: true, ...options });
}

export function getLanguageMap() {
  return Object.fromEntries(
    ALL_LANGUAGES.map((locale) => {
      const lang = getLangNameFromCode(locale);
      return [locale, `${lang.name} (${lang.native})`];
    })
  );
}

export function useENBasedTranslation(namespaces: string | string[], options?: any) {
  const { t, i18n, ...other } = useTranslation(namespaces, {
    useSuspense: true,
    ...options,
  });
  const translateMaybe = useCallback(
    (key: string, opts?: any) => {
      return opts?.defaultValue && i18n.language == 'en' ? opts.defaultValue : t(key, opts);
    },
    [t, i18n.language]
  );
  return { t: translateMaybe as TFunction, i18n, ...other };
}

export const EU_DATA_NAMESPACE = 'db/eu_data';

export function useEUDataTranslation(type: string) {
  return useENBasedTranslation(EU_DATA_NAMESPACE, { keyPrefix: type });
}

export function translateFields(obj: any, fields: string[], t: TFunction, prefix: string) {
  const translated = Object.fromEntries(
    fields.map((f) => [f, obj[f] ? t(prefix + '.' + f, { defaultValue: obj[f] }) : obj[f]])
  );
  return { ...obj, ...translated };
}

export function translateField(obj: any, field: string, t: TFunction, key: string) {
  return { ...obj, [field]: obj[field] ? t(key, { defaultValue: obj[field] }) : obj[field] };
}

export function tnoop<T>(key: T): T {
  return key;
}

type LastSplit<S extends string> = string extends S
  ? string
  : S extends ''
  ? ''
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  S extends `${infer _T}.${infer U}`
  ? LastSplit<U>
  : S;

export function tenum<T extends string>(key: T): LastSplit<T> {
  return key.split('.').pop() as any;
}
