import { CheckIcon } from '@chakra-ui/icons';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { BusinessUnitsIcon, CompanyIcon, TargetIcon } from 'Tokens/Icons/Data';
import { CommentIcon } from 'Tokens/Icons/Function';

const Step = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: JSX.Element;
}) => (
  <HStack spacing="16px">
    <Box p="16px 16px 12px" bg="bg.info.muted" borderRadius="12px" height="fit-content">
      {icon}
    </Box>
    <VStack spacing="2px" alignItems="start">
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body" color="text.muted">
        {description}
      </Typography>
    </VStack>
  </HStack>
);

export const AboutEsrs = () => {
  return (
    <VStack spacing="0px">
      <VStack pr="88px" spacing="32px" alignItems="start">
        <Typography variant="h1">Learn about ESRS</Typography>
        <VStack alignItems="start">
          <Typography variant="h2">General info</Typography>
          <Typography variant="body" color="text.muted">
            Welcome to the Celsia ESRS reporting process. The onboarding flow will take you through
            the first steps of setting up your company structure and choosing material topics.
          </Typography>
        </VStack>
        <VStack spacing="18px" alignItems="start">
          <Typography variant="h2">Steps</Typography>
          <Step
            title="Group materiality assessment"
            description="It is possible to perform a materiality assessment on group level, on subsidiary levels or both. If the group is to perform a materiality assessment, we normally advise this to be the first step of the process."
            icon={<CompanyIcon />}
          />
          <Step
            title="Set targets"
            description="Define targets and milestones using relevant sustainability indicators to track progress. "
            icon={<TargetIcon />}
          />
          <Step
            title="Subsidiaries assessment"
            description="It is possible to perform a materiality assessment on group level, on subsidiary levels or both. If the group is to perform a materiality assessment, we normally advise this to be the first step of the process."
            icon={<BusinessUnitsIcon />}
          />
          <Step
            title="Collect data"
            description="Decide which data points to collect from different parts of the company and choose the data collection frequency."
            icon={<CommentIcon />}
          />
          <Step
            title="Use results"
            description="Download your report following the CSRD requirements to use in external reporting. "
            icon={<CheckIcon />}
          />
        </VStack>
      </VStack>
    </VStack>
  );
};
