import { VStack, useDisclosure } from '@chakra-ui/react';
import { DocumentationFile, ResolvedQuestion } from 'models';
import { QuestionMessage } from 'Molecules';
import { isSCAutoAligned } from 'utils/scores/questions';
import { QuestionRow } from './QuestionRow';
import { useTranslation } from '../../utils/translation/translation';
import { useState, useCallback } from 'react';
import { AttachmentDrawer } from './AttachmentsDrawer';
import { useSaveAnswerAttachmentMutation } from './Screening.hooks';

type AttachmentQuestion = Pick<ResolvedQuestion, 'id' | 'answer'>;

export const QuestionsList = function AnimatedQuestionList({
  questions,
  prefix,
  offset = 0,
  activityAssessmentId,
  openUpdatedQuestionModal,
  showVersionAlert,
  isLocked,
}: {
  questions: ResolvedQuestion[];
  prefix: string;
  offset?: number;
  activityAssessmentId: string;
  openUpdatedQuestionModal: (q: ResolvedQuestion) => void;
  showVersionAlert?: boolean;
  isLocked: boolean;
}) {
  const { t } = useTranslation('question');
  const [attachmentQuestion, setAttachmentQuestion] = useState<AttachmentQuestion>();

  const {
    isOpen: isAttachmentsOpen,
    onOpen: onAttachmentsOpen,
    onClose: onAttachmentsClose,
  } = useDisclosure();

  const openAttachmentDrawer = useCallback(
    (question?: AttachmentQuestion) => {
      setAttachmentQuestion(question);
      onAttachmentsOpen();
    },
    [attachmentQuestion]
  );

  const saveAttachments = useSaveAnswerAttachmentMutation();

  const onAttachmentsChanged = useCallback(
    (filesToAttach: DocumentationFile[] | undefined | null) => {
      // if (readOnly) return Promise.resolve();
      return saveAttachments(
        filesToAttach ?? [],
        activityAssessmentId,
        attachmentQuestion?.id,
        attachmentQuestion?.answer?.attachmentBox ?? undefined
      );
    },
    [saveAttachments, attachmentQuestion]
  );

  return (
    <AttachmentDrawer
      onAttachClicked={onAttachmentsChanged}
      isOpen={isAttachmentsOpen}
      onClose={onAttachmentsClose}
      attachmentBox={attachmentQuestion?.answer?.attachmentBox ?? undefined}
    >
      <VStack width="100%" paddingTop="24px" paddingBottom="8px" alignItems="center" spacing="16px">
        {isSCAutoAligned(questions) && (
          <QuestionMessage>{t('question:autoSCObjective')}</QuestionMessage>
        )}

        {questions
          .filter((q) => q.isVisible)
          .map((q, qIndex) => (
            <QuestionRow
              key={q.id}
              question={q}
              index={`${prefix}.${offset + qIndex + 1}`}
              activityAssessmentId={activityAssessmentId}
              openAttachmentDrawer={openAttachmentDrawer}
              openUpdatedQuestionModal={openUpdatedQuestionModal}
              showVersionAlert={showVersionAlert}
              isLocked={isLocked}
            />
          ))}
      </VStack>
    </AttachmentDrawer>
  );
};
