import { useMemo } from 'react';
import { MaterialityStatus } from './MaterialityRadioGroup';
import { Alert, Infobox, Tag } from 'Atoms';
import { HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { LockedIcon } from 'Tokens/Icons/Function';
import { DR_STATES, Materiality, MaterialityState } from './MaterialityAssessment.d';

export const BOX_STATES = {
  [MaterialityState.toAssess]: {
    textColor: 'text.warning',
    text: 'To assess',
    bgColor: 'bg.warning',
    width: '84px',
  },
  defaultToAssess: {
    textColor: 'text.muted',
    text: 'To assess',
    bgColor: 'bg.unknown',
    width: '84px',
  },
  [MaterialityState.materialMandatory]: {
    textColor: 'text.info',
    text: 'Material',
    bgColor: 'bg.info',
    width: '95px',
    iconColor: 'text.info',
  },
  [MaterialityState.mandatory]: {
    textColor: 'text.muted',
    text: 'Mandatory',
    bgColor: 'bg.unknown',
    width: '115px',
    iconColor: 'text.muted',
  },
  [MaterialityState.material]: {
    textColor: 'text.info',
    text: 'Material',
    bgColor: 'bg.info',
    width: '77px',
  },
  [MaterialityState.notMaterial]: {
    textColor: 'text.muted',
    text: 'Not material',
    bgColor: 'bg.unknown',
    width: '106px',
  },
  [MaterialityState.gatherData]: {
    textColor: 'text.muted',
    text: 'Gather data',
    bgColor: 'bg.unknown',
    width: '106px',
  },
};

const TAG_STATES = {
  material: {
    variant: 'selected',
    text: MaterialityStatus.material,
  },
  notMaterial: {
    variant: 'default',
    text: MaterialityStatus.notMaterial,
  },
  mandatory: {
    variant: 'default',
    text: MaterialityState.mandatory,
  },
  toAssess: {
    variant: 'warning',
    text: 'To assess',
  },
  gatherData: {
    variant: 'default',
    text: 'Gather data',
  },
};

const PARENT_INFO_STATES = {
  [MaterialityState.toAssess]: 'Not assessed by a parent company',
  [MaterialityState.notMaterial]: 'Not material for a parent company',
  [MaterialityState.material]: 'Material for a parent company',
  [MaterialityState.mandatory]: 'Material for a parent company',
  [MaterialityState.gatherData]: 'Not material for a parent company but gathering data',
  [MaterialityState.materialMandatory]: 'Material for a parent company',
};

export const MaterialityStatusTag = ({
  isAssessed,
  isMaterial,
  isDataGather,
  isMandatory,
}: {
  isAssessed: boolean;
  isMaterial?: boolean | null;
  isDataGather: boolean;
  isMandatory: boolean;
}) => {
  const state = useMemo(() => {
    if (isMandatory) return MaterialityState.mandatory;
    if (isDataGather) return 'gatherData';
    if (!isAssessed || isMaterial === null) return 'toAssess';
    if (isMaterial) return 'material';
    return 'notMaterial';
  }, [isAssessed, isMaterial, isDataGather]);
  return (
    <Tag variant={TAG_STATES[state].variant} borderRadius="4px" p="4px 10px">
      {TAG_STATES[state].text}
    </Tag>
  );
};

export const MaterialityStatusBox = ({
  materiality,
}: {
  materiality: Materiality | 'defaultToAssess';
}) => {
  return (
    <HStack
      backgroundColor={BOX_STATES[materiality].bgColor}
      p="4px 10px 4px 10px"
      width="fit-content"
      spacing="6px"
      borderRadius="6px"
    >
      {(materiality === MaterialityState.mandatory ||
        materiality === MaterialityState.materialMandatory) && (
        <LockedIcon color={BOX_STATES[materiality].iconColor} />
      )}
      <Typography variant="bodyStrong" color={BOX_STATES[materiality].textColor}>
        {BOX_STATES[materiality].text}
      </Typography>
    </HStack>
  );
};

export const DisclosureRequirementInfo = ({ status }: { status: MaterialityState }) => {
  return (
    <Infobox
      status={DR_STATES[status].status}
      titleProps={{ variant: DR_STATES[status].status === 'info' ? 'h3' : 'h2' }}
      title={DR_STATES[status].title}
      description={DR_STATES[status].description}
      closable={false}
      textVariant="h3"
      icon={DR_STATES[status].icon}
    />
  );
};

export const ParentInfoBox = ({ status }: { status: MaterialityState }) => {
  return (
    <Alert
      status="neutral"
      title={PARENT_INFO_STATES[status]}
      textColor="text.default"
      closable={false}
    />
  );
};
