import { VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { FormField } from 'Atoms';
import { useCreateInitialCompany } from 'containers/Navigation';
import {
  EsrsAssessmentDocument_,
  GetSubsidiariesWithAssessmentsDocument_,
  useAddEsrsSubsidaryMutation,
  useEsrsAssessmentQuery,
  useUpdateEsrsSubsidaryMutation,
  useUpsertReportingUnitLineageMutation,
  useUserCompaniesQuery,
} from 'models';
import { CreatableSelect, Modal, SearchInput } from 'Molecules';
import { useMemo, useState } from 'react';
import { useForm, useFormState, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCurrentCompany, useCurrentCompanyId, useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';
import { CompanyFields, useAddSubsidiaryCompanyBU } from './EsrsConfig.hooks';
import { Typography } from 'Tokens';
import { ProjectLeaderSelect } from './ProjectLeaderSelect';

export const AddSubsidaryModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation('group');
  const user = useUserData();
  const toast = useToast();
  const [addSubsidiary] = useAddEsrsSubsidaryMutation({
    refetchQueries: [EsrsAssessmentDocument_, GetSubsidiariesWithAssessmentsDocument_],
  });
  const [updateSubsidiary] = useUpdateEsrsSubsidaryMutation({
    refetchQueries: [EsrsAssessmentDocument_, GetSubsidiariesWithAssessmentsDocument_],
  });

  const [loading, setLoading] = useState(false);
  const { companyId } = useCurrentCompanyId();
  const { company: currentCompany } = useCurrentCompany();
  const { esrsAssessmentId } = useParams();
  const createInitialCompany = useCreateInitialCompany();
  const [upsertReportingUnitLineage] = useUpsertReportingUnitLineageMutation();
  const createCompanyLevelBU = useAddSubsidiaryCompanyBU();
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<CompanyFields['company']>();

  const { data: parentAssessmentData } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user?.id,
  });

  const selectedCompanyAssessmentProjectLeaderId = useMemo(() => {
    const esrsAssessment = data?.data?.companies.find(
      (c) => c.company.id === selectedSubsidiary?.id
    )?.company.esrsAssessments;
    const selectedYearAssessment = esrsAssessment?.find(
      (a) => a.reportingYear === parentAssessmentData?.esrsAssessment?.reportingYear
    );

    return selectedYearAssessment?.projectLeaderId;
  }, [selectedSubsidiary]);

  const userCompanies = useMemo(() => {
    return data?.data?.companies.filter(
      (c) => c.company.id !== companyId && c.company.isPortfolioOwner !== true
    );
  }, [data]);

  const { control, handleSubmit, reset, setValue } = useForm<CompanyFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });
  const { isValid, isDirty, errors } = useFormState({ control });

  const handleAddSubsidiary = async (subsidiaryData: CompanyFields) => {
    const { company } = subsidiaryData;
    setLoading(true);
    if (!company.id) {
      try {
        const createCompId = await createInitialCompany(
          user?.id ?? '',
          company?.name,
          false,
          false,
          currentCompany?.currency ?? 'NOK'
        );
        const res = await addSubsidiary({
          variables: {
            esrsAssessment: {
              companyId: createCompId ?? '',
              reportingYear:
                parentAssessmentData?.esrsAssessment?.reportingYear ?? new Date().getFullYear(),
              parentAssessmentId: esrsAssessmentId,
              projectLeaderId: subsidiaryData.company?.projectLeaderId,
            },
          },
          refetchQueries: [EsrsAssessmentDocument_, GetSubsidiariesWithAssessmentsDocument_],
          awaitRefetchQueries: true,
        });

        await nhost.auth.refreshSession();

        await upsertReportingUnitLineage({
          variables: {
            reportingUnitLineage: {
              companyId: createCompId,
              isCompanyLevel: true,
              reportingUnits: {
                data: [
                  {
                    name: 'Company level',
                    assessmentId: res.data?.insert_EsrsAssessment_one?.id,
                    isCompanyLevel: true,
                  },
                ],
              },
            },
          },
          refetchQueries: [EsrsAssessmentDocument_],
        }).then(() => setLoading(false));
        onClose();
        toast({
          text: t('toast.added'),
        });
        reset({
          company: undefined,
        });
      } catch (e) {
        onClose();
        setLoading(false);
        reset({
          company: undefined,
        });
        if (String(e).includes('Subsidiary_companyId_groupAssessmentId_key')) {
          toast({
            variant: 'danger',
            text: company.name + t('toast.exists'),
          });
        } else {
          toast({
            variant: 'danger',
            text: t('toast.failed'),
          });
        }
      }
    } else {
      const selectedCompnay = userCompanies?.find((c) => c.company.id === company.id)?.company
        .esrsAssessments;
      const selectedYearAssessment = selectedCompnay?.find(
        (a) => a.reportingYear === parentAssessmentData?.esrsAssessment?.reportingYear
      );
      if (selectedYearAssessment?.reportingYear) {
        const res = await updateSubsidiary({
          variables: {
            companyId: company.id ?? '',
            reportingYear:
              parentAssessmentData?.esrsAssessment?.reportingYear ?? new Date().getFullYear(),
            parentAssessmentId: esrsAssessmentId,
            projectLeaderId: selectedCompanyAssessmentProjectLeaderId ?? company.projectLeaderId,
          },
          refetchQueries: [EsrsAssessmentDocument_],
        });
        createCompanyLevelBU(
          company,
          userCompanies,
          parentAssessmentData?.esrsAssessment?.reportingYear,
          res.data?.update_EsrsAssessment?.returning?.[0].id
        );
      } else {
        const res = await addSubsidiary({
          variables: {
            esrsAssessment: {
              companyId: company.id ?? '',
              reportingYear:
                parentAssessmentData?.esrsAssessment?.reportingYear ?? new Date().getFullYear(),
              parentAssessmentId: esrsAssessmentId,
              projectLeaderId: subsidiaryData.company?.projectLeaderId,
            },
          },
          refetchQueries: [EsrsAssessmentDocument_, GetSubsidiariesWithAssessmentsDocument_],
          awaitRefetchQueries: true,
        });
        createCompanyLevelBU(
          company,
          userCompanies,
          parentAssessmentData?.esrsAssessment?.reportingYear,
          res.data?.insert_EsrsAssessment_one?.id
        );
      }
      onClose();
      setLoading(false);
      toast({
        text: t('toast.added'),
      });
      reset({
        company: undefined,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('companies.addSubsidiary')}
      confirmButtonProps={{
        type: 'submit',
        form: 'subsidiary-form',
        isDisabled: !isValid || !isDirty,
      }}
      loading={loading}
    >
      <form onSubmit={handleSubmit(handleAddSubsidiary)} id="subsidiary-form">
        <VStack alignItems="stretch" spacing="16px">
          <Controller
            name="company"
            control={control}
            rules={{ required: t('companies.required.name') }}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <FormField
                    label={t('companies.name')}
                    id="name"
                    isRequired
                    isInvalid={!!errors.company}
                    error={t('companies.required.name')}
                  >
                    <CreatableSelect
                      value={value}
                      isClearable={true}
                      options={userCompanies?.map((c) => {
                        return c.company;
                      })}
                      createOptionPosition="first"
                      placeholder={
                        <Typography color={'text.hint'} variant="body">
                          {t('companies.fields.company.placeholder')}
                        </Typography>
                      }
                      getOptionValue={(company) => company.id ?? ''}
                      getOptionLabel={(company) => company.name}
                      getNewOptionData={(_input, label) => ({
                        id: undefined,
                        name: label as string,
                      })}
                      onChange={(company) => {
                        onChange(company ? { ...company } : undefined);
                        setSelectedSubsidiary(company ?? undefined);
                      }}
                      formatCreateLabel={(input) => t('companies.createNew', { name: input })}
                    />
                  </FormField>
                  <FormField
                    label={"Subsidiary's project leader"}
                    id="projectLeader"
                    isInvalid={!!errors.company}
                    error={'Please add a project leader to your subsidiary'}
                  >
                    <ProjectLeaderSelect
                      selectedProjectLeaderId={selectedCompanyAssessmentProjectLeaderId}
                      onChange={(member) => {
                        setValue('company.projectLeaderId', member.id);
                      }}
                      isDisabled={
                        !value ||
                        !!value.projectLeaderId ||
                        !!selectedCompanyAssessmentProjectLeaderId
                      }
                    />
                  </FormField>
                </>
              );
            }}
          />
        </VStack>
      </form>
    </Modal>
  );
};
