import { useCompanyType } from 'utils/hooks';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetDisclosureRequirementGroupsQuery_,
  useGetDisclosureRequirementGroupsQuery,
  useGetEsrsStandardQuery,
} from 'models';
import { DREnums, useMaterialStandardId } from 'containers/Esrs';

const sortRequirementGroups = (
  requirementGroups: GetDisclosureRequirementGroupsQuery_['requirementGroup']
) => {
  const drGroupOrder = ['Strategy', 'Impact, risk and opportunity management', 'Metrics'];

  const groupSort = [...requirementGroups].sort((a, b) => {
    const indexA = drGroupOrder.indexOf(a.title);
    const indexB = drGroupOrder.indexOf(b.title);

    return indexA - indexB;
  });

  const keyTitleArray = [
    ...groupSort?.map((d) => {
      if (d.title === 'Metrics') {
        if (d.requirements.every((r) => r.metrics.length === 0)) return [];
        return [
          { title: d.title, key: d.reference, counter: 0 },
          ...d.requirements
            .filter((r) => !!r.metrics.length)
            .map((r) => {
              return {
                title: r.title,
                key: `${r?.type}_${r.reference}`,
                reference: r.reference,
                counter: 0,
              };
            }),
        ];
      } else
        return [
          { title: d.title, key: d.reference, counter: 0 },
          ...d.requirements.map((r) => {
            return {
              title: r.title,
              key: `${r?.type}_${r.reference}`,
              reference: r.reference,
              counter: 0,
            };
          }),
        ];
    }),
  ];

  return keyTitleArray;
};

export const useGetDRGroupData = () => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const { data: standardData, loading: standardLoading } = useGetEsrsStandardQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });
  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);

  const {
    companyAssessmentId,
    parentAssessmentId,
    isDataGatheringOnly,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data, loading } = useGetDisclosureRequirementGroupsQuery({
    variables: {
      standardRef,
      materialStandardId: companyAssessmentId,
      parentStandardId: parentAssessmentId || companyAssessmentId,
      esrsAssessmentId,
    },
    skip: !standardRef || !companyAssessmentId || !esrsAssessmentId,
  });

  const requirementGroups = useMemo(() => {
    if (isGroup)
      return (
        data?.requirementGroup.filter(
          (rg) => rg.title.toLowerCase() !== 'strategy' && rg.title.toLowerCase() !== 'processes'
        ) ?? []
      );

    return data?.requirementGroup ?? [];
  }, [data]);

  const sortedRequirements = useMemo(() => {
    const sortedData = sortRequirementGroups(requirementGroups);
    return sortedData;
  }, [requirementGroups]);

  const allRequirements = useMemo(
    () => data?.requirementGroup.flatMap((rg) => rg.requirements) ?? [],
    [data]
  );

  const allSubsidiariesStandard = useMemo(
    () => data?.subsidiariesAssessments?.subsidiaries.flatMap((s) => s.materialStandards) ?? [],
    [data]
  );

  const targetsCount = useMemo(
    () =>
      (allRequirements.find((req) => req.type === DREnums.target)?.targets_aggregate.aggregate
        ?.count ?? 0) +
      (allSubsidiariesStandard
        .flatMap((ms) => ms.targets_aggregate.aggregate?.count ?? 0)
        ?.reduce((a, b) => a + b, 0) ?? 0),
    [allRequirements, allSubsidiariesStandard]
  );

  const actionsCount = useMemo(
    () =>
      (allRequirements.find((req) => req.type === DREnums.action)?.actions_aggregate.aggregate
        ?.count ?? 0) +
      (allSubsidiariesStandard
        .flatMap((ms) => ms.actions_aggregate.aggregate?.count ?? 0)
        ?.reduce((a, b) => a + b, 0) ?? 0),
    [allRequirements, allSubsidiariesStandard]
  );

  const policiesCount = useMemo(
    () =>
      (allRequirements.find((req) => req.type === DREnums.policy)?.policies?.[0]?.attachmentBox
        ?.attachments?.length ?? 0) +
      (allSubsidiariesStandard
        .flatMap((ms) => ms.policies?.[0]?.attachmentBox?.attachments?.length ?? 0)
        ?.reduce((a, b) => a + b, 0) ?? 0),
    [allRequirements, allSubsidiariesStandard]
  );

  return {
    standard,
    isDataGatheringOnly,
    sortedRequirements,
    targetsCount,
    actionsCount,
    policiesCount,
    loading: loading || standardLoading || materialLoading,
  };
};
