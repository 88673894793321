import {
  Button as ChakraButton,
  IconButton as ChakraIconButton,
  ButtonProps as ChakraButtonProps,
  ChakraTheme,
  IconButtonProps,
  Box,
  Spinner,
  PlacementWithLogical,
  HStack,
} from '@chakra-ui/react';
import { Link, LinkProps } from '../Link';
import React from 'react';
import { Tooltip } from 'Atoms/Tooltip';
import { Menu, MenuProps, MenuSection } from 'Molecules/Menu';
import { ChevronDownIcon } from '@chakra-ui/icons';

export const BUTTON_STATES = ['default', 'isDisabled', 'selected', 'isLoading'];
export type ButtonState = (typeof BUTTON_STATES)[number];

export const ButtonTheme: ChakraTheme['components']['Button'] = {
  defaultProps: {
    variant: 'secondary',
  },
  baseStyle: {
    fontWeight: 'medium',
    fontFamily: 'Inter',
    lineHeight: '20px',
    _focusVisible: {
      shadows: 'outline',
    },
  },
  sizes: {
    md: {
      h: '36px',
      minW: '36px',
      fontSize: 'md',
      px: '16px',
      py: '8px',
      borderRadius: '8px',
    },
    sm: {
      h: '28px',
      minW: '28px',
      fontSize: 'md',
      px: '8px',
      py: '4px',
      borderRadius: '6px',
    },
  },
  variants: {
    primary: {
      bg: 'bg.brand.accent',
      color: 'text.onAccent',
      borderWidth: '0px',
      _hover: {
        bg: 'bg.brand.accent.hover',
        color: 'text.onAccent',
        _disabled: {
          bg: 'bg.disabled',
          color: 'text.disabled',
        },
      },
      _active: {
        bg: 'bg.brand.accent.pressed',
      },
      _disabled: {
        opacity: 1,
        bg: 'bg.disabled',
        color: 'text.disabled',
        _hover: {
          bg: 'bg.disabled',
          color: 'text.disabled',
        },
      },
    },
    secondary: {
      bg: 'bg.default',
      borderWidth: '1px',
      borderColor: 'border.default',
      color: 'text.brand',
      _hover: {
        bg: 'bg.hover',
        color: 'text.brand',
        _disabled: {
          bg: 'bg.disabled',
          borderWidth: '1px',
          borderColor: 'border.default',
          color: 'text.disabled',
        },
      },
      _active: {
        bg: 'bg.pressed',
      },
      _disabled: {
        opacity: 1,
        border: 'none',
        bg: 'bg.disabled',
        color: 'text.disabled',
      },
    },
    ghost: {
      bg: 'transparent',
      color: 'text.muted',
      fontWeight: 'semibold',
      px: '8px',
      _hover: {
        bg: 'bg.hover',
        color: 'text.muted',
      },
      _active: {
        bg: 'bg.pressed',
      },
      _disabled: {
        opacity: 1,
        bg: 'transparent',
        color: 'text.disabled',
        _hover: {
          bg: 'transparent',
          color: 'text.disabled',
        },
      },
    },
    destructive: {
      bg: 'transparent',
      color: 'text.critical',
      px: '8px',
      _hover: {
        bg: 'bg.hover',
        color: 'text.critical',
      },
      _active: {
        bg: 'bg.pressed',
      },
      _disabled: {
        opacity: 1,
        bg: 'transparent',
        color: 'text.disabled',
        _hover: {
          bg: 'transparent',
          color: 'text.disabled',
        },
      },
    },
  },
};

export const IconButtonSizes: { [key: string]: ChakraButtonProps } = {
  md: {
    h: '36px',
    minW: '36px',
    fontSize: 'md',
    borderRadius: '8px',
  },
  sm: {
    h: '28px',
    minW: '28px',
    fontSize: 'md',
    px: '6px',
    py: '6px',
    borderRadius: '6px',
    paddingInline: '0px',
  },
  xs: {
    h: '20px',
    minW: '20px',
    fontSize: 'md',
    px: '2px',
    py: '2px',
    borderRadius: '4px',
  },
  xxs: {
    h: '16px',
    minW: '16px',
    fontSize: 'md',
    px: '2px',
    py: '2px',
    borderRadius: '4px',
  },
};

export const ICON_BUTTON_SIZES = Object.keys(IconButtonSizes ?? {});
export type IconButtonSize = (typeof ICON_BUTTON_SIZES)[number];

export const BUTTON_SIZES = Object.keys(ButtonTheme.sizes ?? {});
export type ButtonSize = (typeof BUTTON_SIZES)[number];

export const BUTTON_VARIANTS = Object.keys(ButtonTheme.variants ?? {});
export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

export type ButtonProps = ChakraButtonProps & {
  size?: ButtonSize;
  variant?: ButtonVariant;
  selected?: boolean;
  menuProps?: MenuProps;
  alignVertically?: boolean;
};

export const Button = React.forwardRef(
  (
    {
      isLoading = false,
      alignVertically = false,
      leftIcon,
      rightIcon,
      children,
      selected = false,
      ...props
    }: ButtonProps,
    ref: React.LegacyRef<HTMLButtonElement>
  ) => {
    const handleLeftElement = () => {
      if (isLoading) {
        return (
          <Box display="flex" paddingRight={props.size === 'sm' ? '4px' : '8px'}>
            <Spinner boxSize="16px" />
          </Box>
        );
      }
      if (leftIcon) {
        return (
          <Box display="flex" paddingRight={props.size === 'sm' ? '4px' : '8px'} fontSize="md">
            {leftIcon}
          </Box>
        );
      }
      return null;
    };
    const handleRightElement = () => {
      if (!isLoading && rightIcon) {
        return (
          <Box display="flex" paddingLeft={props.size === 'sm' ? '4px' : '8px'} fontSize="md">
            {rightIcon}
          </Box>
        );
      }
      return null;
    };

    return (
      <ChakraButton
        {...props}
        {...(selected
          ? {
              bg: 'bg.selected',
              color: 'text.selected',
              borderWidth: 'none',
              _hover: { bg: 'bg.selected.hover' },
              _active: { bg: 'bg.selected.pressed' },
            }
          : {})}
        {...(isLoading
          ? {
              opacity: 0.5,
              _hover: {
                opacity: 0.5,
              },
              _active: {
                opacity: 0.5,
              },
            }
          : {})}
        {...(alignVertically && (props.variant === 'ghost' || props.variant === 'destructive')
          ? {
              transform: `translateX(-${props.size === 'sm' ? 4 : 8}px)`,
            }
          : {})}
        ref={ref}
      >
        <>
          {handleLeftElement()}
          {children}
          {handleRightElement()}
        </>
      </ChakraButton>
    );
  }
);

export const ButtonWithMenu = React.forwardRef(
  (
    {
      isLoading = false,
      alignVertically = false,
      leftIcon,
      rightIcon,
      children,
      selected = false,
      menuProps,
      isDisabled = false,
      ...props
    }: ButtonProps,
    ref: React.LegacyRef<HTMLButtonElement>
  ) => {
    const handleLeftElement = () => {
      if (isLoading) {
        return (
          <Box display="flex" paddingRight={props.size === 'sm' ? '4px' : '8px'}>
            <Spinner boxSize="16px" />
          </Box>
        );
      }
      if (leftIcon) {
        return (
          <Box display="flex" paddingRight={props.size === 'sm' ? '4px' : '8px'} fontSize="md">
            {leftIcon}
          </Box>
        );
      }
      return null;
    };
    const handleRightElement = () => {
      if (!isLoading && rightIcon) {
        return (
          <Box display="flex" paddingLeft={props.size === 'sm' ? '4px' : '8px'} fontSize="md">
            {rightIcon}
          </Box>
        );
      }
      return null;
    };
    return (
      <HStack spacing="1px">
        <ChakraButton
          borderRadius={menuProps && !isLoading && !isDisabled ? '8px 0px 0px 8px' : '8px'}
          isDisabled={isDisabled}
          {...props}
          {...(selected && !isDisabled
            ? {
                bg: 'bg.selected',
                color: 'text.selected',
                borderWidth: 'none',
                _hover: { bg: 'bg.selected.hover' },
                _active: { bg: 'bg.selected.pressed' },
              }
            : {})}
          {...(isLoading && !isDisabled
            ? {
                opacity: 0.5,
                _hover: {
                  opacity: 0.5,
                },
                _active: {
                  opacity: 0.5,
                },
              }
            : {})}
          {...(isDisabled
            ? {
                bg: 'bg.disabled.accent',
                cursor: 'not-allowed',
                _hover: { bg: 'bg.disabled.accent' },
              }
            : {})}
          {...(alignVertically && (props.variant === 'ghost' || props.variant === 'destructive')
            ? {
                transform: `translateX(-${props.size === 'sm' ? 4 : 8}px)`,
              }
            : {})}
          ref={ref}
        >
          <>
            {handleLeftElement()}
            {children}
            {handleRightElement()}
          </>
        </ChakraButton>
        {menuProps && !isLoading && !isDisabled && (
          <Menu
            {...menuProps}
            menuButton={
              <ChakraButton
                variant="primary"
                w="36px"
                borderRadius="0px 8px 8px 0px"
                as={IconButton}
                icon={<ChevronDownIcon w="16px" />}
              />
            }
          />
        )}
      </HStack>
    );
  }
);

export const IconButton = React.forwardRef(
  (
    {
      icon,
      isLoading,
      alignVertically = false,
      selected,
      size = 'md',
      tooltipLabel,
      tooltipPlacement,
      tooltipHidden,
      disableActive,
      tooltipOpen,
      tooltipMaxWidth,
      ...props
    }: Omit<IconButtonProps, 'size'> & {
      size?: IconButtonSize;
      selected?: boolean;
      isLoading?: boolean;
      alignVertically?: boolean;
      tooltipLabel?: string;
      tooltipPlacement?: PlacementWithLogical;
      tooltipHidden?: boolean;
      disableActive?: boolean;
      tooltipOpen?: boolean;
      tooltipMaxWidth?: string;
    },
    ref: React.LegacyRef<HTMLButtonElement>
  ) => {
    const handleIconLoadingElement = () => {
      if (isLoading) {
        return (
          <Box display="flex">
            <Spinner boxSize="16px" />
          </Box>
        );
      }
      if (icon) {
        return <Box display="flex">{icon}</Box>;
      }
      return null;
    };
    return (
      <Tooltip
        label={tooltipLabel}
        hasArrow={false}
        hidden={tooltipHidden}
        placement={tooltipPlacement}
        maxW={tooltipMaxWidth}
        isOpen={tooltipOpen ?? undefined}
      >
        <ChakraIconButton
          {...IconButtonSizes[size]}
          {...props}
          {...(selected
            ? {
                bg: 'bg.selected',
                color: 'text.selected',
                borderWidth: 'none',
                _hover: { bg: 'bg.selected.hover' },
                _active: { bg: 'bg.selected.pressed' },
              }
            : {})}
          {...(isLoading
            ? {
                opacity: 0.5,
                _hover: {
                  opacity: 0.5,
                },
                _active: {
                  opacity: 0.5,
                },
              }
            : {})}
          {...(disableActive && {
            _active: { bg: '' },
            _hover: { bg: '' },
          })}
          {...(alignVertically && (props.variant === 'ghost' || props.variant === 'destructive')
            ? {
                transform: `translateX(-${props.size === 'sm' ? 4 : 8}px)`,
              }
            : {})}
          ref={ref}
        >
          {handleIconLoadingElement()}
        </ChakraIconButton>
      </Tooltip>
    );
  }
);

type ButtonLinkProps = ButtonProps & LinkProps;

export const ButtonLink = (props: ButtonLinkProps) => {
  return <Button as={Link} {...props} textDecoration="none" />;
};
