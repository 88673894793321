import { VStack, useDisclosure, HStack, Grid, Box, GridItem, Skeleton } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetAggregatedMetrics,
  getGraphObject,
  getNestedMetrics,
} from './AggregatedMetrics.hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconButton, Infobox, TruncatableText } from 'Atoms';
import { HelpIcon } from 'Tokens/Icons/Status';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import { GraphCard, MetricsGraphModal } from '../MetricsGraphModal';
import {
  GetAnswersForMetricsOnCompanyLevelDocument_,
  GetAnswersForMetricsOnGroupLevelDocument_,
  QuestionType_Enum_,
  GetEsrsAssessmentMetadataDocument_,
  useUpdateEsrsAssessmentMetaDataMutation,
  useEsrsAssessmentQuery,
} from 'models';
import { HistogramIcon, SumIcon, TableIcon } from 'Tokens/Icons/Data';
import {
  FrequencyEnums,
  QuartersObject,
  TimePeriods,
  TimePeriodsEnums,
  YearObject,
} from '../../Requirement';
import { MetricsTableFilters } from '../MetricsTableFilters';
import { AggregatedMetricsTable } from './AggregatedMetricsTable';
import { useCompanyType, useCurrentCompany } from 'utils/hooks';
import { MaximizeIcon } from 'Tokens/Icons/Function';
import {
  SelectedMetric,
  getNestedRows,
  useGetAggregatedMetricsData,
  MetricViewEnums,
  DREnums,
  MetricsTableData,
} from 'containers/Esrs';
import { MetricsInputTable } from '../InputTable';
import { OptOutModal } from '../OptOutModal';
import { useApolloClient } from '@apollo/client';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { NarrativeMetricsTable } from '../InputTable/NarrativeMetricsTable';
import { MetricSidebar } from '../MetricSidebar';

export const TableViewSwitcher = ({
  setIsChartsView,
  isChartsView,
}: {
  setIsChartsView: (param: boolean) => void;
  isChartsView: boolean;
}) => (
  <HStack
    spacing="0px"
    w="fit-content"
    border="1px solid"
    borderRadius="8px"
    borderColor="border.decorative"
    height="36px"
  >
    <IconButton
      aria-label="table-icon"
      variant="ghost"
      icon={<TableIcon color={isChartsView ? 'text.muted' : 'text.selected'} />}
      borderRightRadius="0px"
      onClick={() => setIsChartsView(false)}
      bg={isChartsView ? 'bg.decorative' : 'bg.selected'}
    />
    <IconButton
      aria-label="histogram"
      variant="ghost"
      icon={<HistogramIcon color={isChartsView ? 'text.selected' : 'text.muted'} />}
      borderLeft="1px solid"
      borderColor="border.decorative"
      borderLeftRadius="0px"
      onClick={() => setIsChartsView(true)}
      bg={isChartsView ? 'bg.selected' : 'bg.decorative'}
    />
  </HStack>
);

export const AggregatedMetrics = ({
  extraHeaderContent,
  isGeneratingAnswers = false,
}: {
  extraHeaderContent?: React.ReactNode;
  isGeneratingAnswers?: boolean;
}) => {
  const client = useApolloClient();
  const {
    companyId,
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef,
    drType,
    view,
  } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isGraphOpen, onOpen: onGraphOpen, onClose: onGraphClose } = useDisclosure();
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.q1);
  const [isOverview, setIsOverview] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<QuartersObject | YearObject>({
    Q1: 0,
    Q2: 0,
    Q3: 0,
    Q4: 0,
  });
  const [isChartsView, setIsChartsView] = useState(false);
  const [rowData, setRowData] = useState<MetricsTableData>();
  const { companyType, loading: loadingType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { company } = useCurrentCompany();
  const [updateMetadata] = useUpdateEsrsAssessmentMetaDataMutation();

  const {
    metricDR,
    metrics,
    filteredMetrics,
    metaData,
    metadata,
    metaDataLoading,
    companyAssessmentId,
    parentAssessmentId,
    isCompanyLevel,
    companyLevelReportingUnitId,
    dataLoading,
  } = useGetAggregatedMetricsData();

  const { aggregatedMetrics, loading } = useGetAggregatedMetrics(
    esrsAssessmentId,
    metrics ?? [],
    standardRef,
    companyAssessmentId
  );

  const { title = '', reference = '', description = '' } = metricDR || {};
  const isMetricDisclosure = useMemo(() => drType === DREnums.metric, [drType]);
  const isImpactRiskDisclosure = useMemo(
    () => drType === DREnums.action || drType === DREnums.target || drType === DREnums.policy,
    [drType]
  );

  const { data } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const esrsAssessment = useMemo(() => data?.esrsAssessment, [data]);

  useEffect(() => {
    if (metadata?.isChartView) setIsChartsView(metadata?.isChartView);
  }, [metaData]);

  useEffect(() => {
    if (isChartsView !== metadata?.isChartView && !metaDataLoading) {
      updateMetadata({
        variables: {
          id: esrsAssessmentId,
          metadata: { ...(metadata ?? {}), isChartView: isChartsView },
        },
        refetchQueries: [GetEsrsAssessmentMetadataDocument_],
      });
    }
  }, [isChartsView]);

  useEffect(() => {
    const element = document.getElementById(hash.slice(1));
    if (element && hash.slice(1) === element.id) {
      setTimeout(
        () => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        },
        isOverview ? 0 : 300
      );
    }
  }, [hash, aggregatedMetrics, filteredMetrics]);

  useEffect(() => {
    setSelectedQuarter(TimePeriodsEnums.q1);
  }, [aggregatedMetrics]);

  useEffect(() => {
    if (isGroup && !isOverview) setIsOverview(true);
  }, [isGroup, isOverview]);

  useEffect(() => {
    if (isOverview) {
      if (drType !== DREnums.metric) {
        setIsOverview(false);
      } else
        client.refetchQueries({
          include: [
            GetAnswersForMetricsOnCompanyLevelDocument_,
            GetAnswersForMetricsOnGroupLevelDocument_,
          ],
        });
    }
  }, [isOverview, drType]);

  const handleViewChange = (isView: boolean) => {
    const section = isView ? MetricViewEnums.overview : MetricViewEnums.dataInput;
    navigate(
      `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/${drType}/${disclosureRequirementRef}/${section}`
    );
    setIsOverview(isView);
  };

  useEffect(() => {
    const isView = view === MetricViewEnums.dataInput ? false : true;
    const isRouteNotMatchingState = isView !== isOverview;
    if (isRouteNotMatchingState) {
      if (view === MetricViewEnums.dataInput) setIsOverview(false);
      else setIsOverview(true);
    }
  }, [view, isCompanyLevel]);

  const areMetricsYearly = useMemo(
    () =>
      aggregatedMetrics.every(
        ({ metric }) => metric.materialMetrics[0]?.frequency === FrequencyEnums.yearly
      ) &&
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.yearly
      ),
    [aggregatedMetrics]
  );
  const areMetricsQuarterly = useMemo(
    () =>
      aggregatedMetrics.every(
        ({ metric }) => metric.materialMetrics[0]?.frequency === FrequencyEnums.quarterly
      ) &&
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.quarterly
      ),
    [aggregatedMetrics]
  );
  const nestedMetrics = useMemo(
    () =>
      filteredMetrics
        .filter((metric) => metric.metricType !== QuestionType_Enum_.LongText_)
        .map((metric) => {
          const materialStandardId = metric.materialMetrics.find(
            (mm) => mm.materialStandardId === companyAssessmentId
          )?.isMaterial
            ? companyAssessmentId
            : parentAssessmentId;
          return getNestedRows(metric, materialStandardId);
        }),
    [filteredMetrics]
  );

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter('Year');
  }, [areMetricsYearly, aggregatedMetrics]);

  const longTextMetrics = useMemo(
    () => filteredMetrics.filter((metric) => metric.metricType === QuestionType_Enum_.LongText_),
    [filteredMetrics]
  );

  const [standAloneMetrics, groupedMetrics] = useMemo(
    () => [
      nestedMetrics.filter((metric) => !metric?.subRows),
      nestedMetrics.filter((metric) => metric?.subRows),
    ],
    [nestedMetrics]
  );

  const [aggregatedStandAloneMetrics, aggregatedGroupedMetrics] = useMemo(
    () => [
      aggregatedMetrics.filter((metric) => !metric?.subRows),
      aggregatedMetrics.filter((metric) => !!metric?.subRows),
    ],
    [aggregatedMetrics]
  );

  const allAggregatedMetrics = useMemo(
    () => getNestedMetrics(aggregatedMetrics),
    [aggregatedMetrics]
  );

  const shouldHideOverview = useMemo(
    () => metrics?.every((m) => m.metricType === QuestionType_Enum_.LongText_),
    [metrics]
  );

  const isLoading = useMemo(
    () => loading || loadingType || dataLoading,
    [loading, loadingType, dataLoading]
  );

  if (isLoading && isMetricDisclosure) return <Loader />;
  return (
    <HStack w="100%" alignItems="start" spacing="16px">
      <VStack
        spacing={isMetricDisclosure ? '32px' : '12px'}
        alignItems="start"
        w="100%"
        marginRight={!!rowData ? '352' : '0px'}
      >
        {isImpactRiskDisclosure ? (
          <>
            {longTextMetrics.length && (
              <Typography variant="h3">Disclosure Requirements</Typography>
            )}
            {extraHeaderContent}
          </>
        ) : (
          <VStack spacing="8px" alignItems="start" w="100%">
            <HStack w="100%" justifyContent="space-between">
              <HStack spacing="6px">
                <Typography variant="bodyLarge" as="span" color="text.hint">
                  {reference}
                </Typography>
                <Typography variant="h3" as="span">
                  {title}
                </Typography>
                <IconButton
                  aria-label="learn more"
                  variant="ghost"
                  icon={<HelpIcon />}
                  size="sm"
                  onClick={() => {
                    setSelectedMetric({
                      reference: reference,
                      title: title,
                      description: description ?? '',
                      isDisclosure: true,
                    });
                    onDrawerOpen();
                  }}
                />
              </HStack>
              {isMetricDisclosure && (
                <TableViewSwitcher setIsChartsView={setIsChartsView} isChartsView={isChartsView} />
              )}
            </HStack>
            {!isGroup && isCompanyLevel && isMetricDisclosure && !shouldHideOverview && (
              <MetricsTableFilters
                isOverview={isOverview}
                setIsOverview={handleViewChange}
                selectedQuarter={selectedQuarter}
                setSelectedQuarter={setSelectedQuarter}
                areMetricsQuarterly={areMetricsQuarterly}
                areMetricsYearly={areMetricsYearly}
              />
            )}
            {isMetricDisclosure && isOverview && (
              <Box w="100%">
                <Infobox
                  status="info"
                  bgColor="bg.info.muted"
                  title="Aggregated metrics"
                  icon={<SumIcon />}
                  closable={false}
                  w="100%"
                  description={
                    <Typography variant="body" verticalAlign="baseline">
                      Some of the metrics here are aggregated from subsidiaries. They are marked
                      with <SumIcon color="text.critical" margin={' 0 2px -3px -2px'} />
                      icon. You can click on each metric to see details.
                    </Typography>
                  }
                />
              </Box>
            )}
          </VStack>
        )}

        {isChartsView ? (
          <Grid templateColumns="repeat(3, 1fr)" gap={2} w="100%">
            {allAggregatedMetrics.map((metric, index) => (
              <GridItem w="99%">
                <VStack
                  key={index}
                  alignItems="start"
                  border="1px solid"
                  borderColor="border.decorative"
                  borderRadius="8px"
                  w="100%"
                  h="100%"
                  pt="8px"
                >
                  <HStack
                    p="6px 16px"
                    justifyContent="space-between"
                    borderBottom="1px solid"
                    borderColor="border.decorative"
                    width="100%"
                  >
                    <TruncatableText variant="bodyStrong" text={metric.metric.title} />
                    <IconButton
                      aria-label="maximize"
                      variant="ghost"
                      icon={<MaximizeIcon />}
                      size="sm"
                      onClick={() =>
                        navigate(
                          `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/chart/${metric.metric.reference}`
                        )
                      }
                    />
                  </HStack>
                  <GraphCard data={getGraphObject(metric)} height={200} />
                </VStack>
              </GridItem>
            ))}
          </Grid>
        ) : (
          <VStack spacing="32px" alignItems="stretch" w="100%" minH="fit-content" mb="40px">
            {aggregatedStandAloneMetrics?.length && isOverview ? (
              <VStack
                alignItems="start"
                id={aggregatedStandAloneMetrics[0].metric.reference}
                spacing="6px"
              >
                <LinkAnchor
                  id={aggregatedStandAloneMetrics[0].metric.reference}
                  text="Various metrics"
                  marginLeft="-20px"
                />
                <AggregatedMetricsTable
                  metrics={aggregatedStandAloneMetrics}
                  selectedQuarter={selectedQuarter}
                  onGraphOpen={onGraphOpen}
                  setSelectedMetric={setSelectedMetric}
                  onDrawerOpen={onDrawerOpen}
                  setGraphData={setGraphData}
                  isOverview={isOverview}
                  areMetricsYearly={areMetricsYearly}
                  isGroup={isGroup}
                  currency={company?.currency ?? ''}
                  companyAssessmentId={companyAssessmentId}
                />
              </VStack>
            ) : (
              standAloneMetrics?.length && (
                <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading}>
                  <VStack
                    alignItems="start"
                    id={standAloneMetrics[0].metric.reference}
                    spacing="6px"
                  >
                    <LinkAnchor
                      id={standAloneMetrics[0].metric.reference}
                      text="Various metrics"
                      marginLeft="-20px"
                    />
                    <MetricsInputTable
                      metrics={standAloneMetrics}
                      selectedQuarter={selectedQuarter}
                      onGraphOpen={onGraphOpen}
                      onOpen={onOpen}
                      setSelectedMetric={setSelectedMetric}
                      onDrawerOpen={onDrawerOpen}
                      setGraphData={setGraphData}
                      isOverview={isOverview}
                      companyStandardId={companyAssessmentId}
                      areMetricsYearly={areMetricsYearly}
                      companyReportingUnit={companyLevelReportingUnitId}
                      currency={company?.currency ?? ''}
                      isCompanyLevel={isCompanyLevel}
                      isGroup={isGroup}
                      esrsAssessmentProjectLeader={esrsAssessment?.projectLeaderId}
                      rowData={rowData}
                      setRowData={setRowData}
                    />
                  </VStack>
                </Skeleton>
              )
            )}

            {isOverview ? (
              aggregatedGroupedMetrics?.map((metric) => (
                <VStack alignItems="start" id={metric.metric.reference} spacing="6px">
                  <LinkAnchor
                    id={metric.metric.reference}
                    text={`${metric.metric.title} ${(metric.tagType ?? '')?.toLowerCase()}`}
                    marginLeft="-20px"
                  />
                  <AggregatedMetricsTable
                    metrics={[metric]}
                    selectedQuarter={selectedQuarter}
                    onGraphOpen={onGraphOpen}
                    setSelectedMetric={setSelectedMetric}
                    onDrawerOpen={onDrawerOpen}
                    setGraphData={setGraphData}
                    isOverview={isOverview}
                    areMetricsYearly={areMetricsYearly}
                    isGroup={isGroup}
                    currency={company?.currency ?? ''}
                    companyAssessmentId={companyAssessmentId}
                  />
                </VStack>
              ))
            ) : (
              <>
                {groupedMetrics?.map((metric) => (
                  <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading}>
                    <VStack alignItems="start" id={metric.metric.reference} spacing="6px">
                      <LinkAnchor
                        id={metric.metric.reference}
                        text={`${metric.metric.title} ${(metric.tagType ?? '')?.toLowerCase()}`}
                        marginLeft="-20px"
                      />
                      <MetricsInputTable
                        metrics={[metric]}
                        selectedQuarter={selectedQuarter}
                        onGraphOpen={onGraphOpen}
                        onOpen={onOpen}
                        setSelectedMetric={setSelectedMetric}
                        onDrawerOpen={onDrawerOpen}
                        setGraphData={setGraphData}
                        isOverview={isOverview}
                        companyStandardId={companyAssessmentId}
                        areMetricsYearly={areMetricsYearly}
                        companyReportingUnit={companyLevelReportingUnitId}
                        currency={company?.currency ?? ''}
                        isCompanyLevel={isCompanyLevel}
                        isGroup={isGroup}
                        esrsAssessmentProjectLeader={esrsAssessment?.projectLeaderId}
                        rowData={rowData}
                        setRowData={setRowData}
                      />
                    </VStack>
                  </Skeleton>
                ))}
                {longTextMetrics?.length !== 0 && (
                  <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading}>
                    <VStack alignItems="start" spacing="6px">
                      <LinkAnchor
                        id={standAloneMetrics?.[0]?.metric.reference}
                        text="Narrative data points"
                        marginLeft="-20px"
                      />
                      <NarrativeMetricsTable
                        metrics={longTextMetrics}
                        selectedQuarter={selectedQuarter}
                        esrsAssessmentProjectLeader={esrsAssessment?.projectLeaderId}
                        rowData={rowData}
                        setRowData={setRowData}
                        isGeneratingAnswers={isGeneratingAnswers}
                        companyReportingUnit={companyLevelReportingUnitId}
                      />
                    </VStack>
                  </Skeleton>
                )}
              </>
            )}
          </VStack>
        )}
        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description ?? ''}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />
        <MetricsGraphModal
          isOpen={isGraphOpen}
          onClose={onGraphClose}
          metricName={selectedMetric?.title ?? ''}
          data={graphData}
        />
        {!isOverview && (
          <OptOutModal
            isOpen={isOpen}
            onClose={onClose}
            assessmentId={esrsAssessmentId}
            reportingUnitId={companyLevelReportingUnitId}
            metricRef={selectedMetric?.reference ?? ''}
            isCompany={false}
          />
        )}
      </VStack>
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={companyLevelReportingUnitId}
          esrsAssessmentProjectLeader={esrsAssessment?.projectLeaderId}
          setSelectedMetric={setSelectedMetric}
          setGraphData={setGraphData}
          onGraphOpen={onGraphOpen}
          onDrawerOpen={onDrawerOpen}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
        />
      )}
    </HStack>
  );
};
