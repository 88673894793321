import { GridItem, VStack, HStack, IconButton } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';
import { SelectedMetric } from 'containers/Esrs';
import { createGraphObject } from 'containers/Esrs/utils';
import React, { useMemo } from 'react';
import { MaximizeIcon } from 'Tokens/Icons/Function';
import { QuartersObject, QuartersEnums, YearObject } from '../../Requirement';
import { isFrequencyYearly, MetricsTableData, useAggregatedRowValue } from '../MetricAnswers.hooks';
import { GraphCard } from '../MetricsGraphModal';
import { useGetDatapointValues } from './QuarterInput';

export const ChartView = ({
  metric,
  index,
  companyStandardId,
  onGraphOpen,
  setSelectedMetric,
  setGraphData,
}: {
  metric: MetricsTableData;
  index: number;
  companyStandardId: string;
  onGraphOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  setGraphData: (param: QuartersObject | YearObject) => void;
}) => {
  {
    const { dataPointsPerQuarter, dataPointPerYear, isAggregated } = useGetDatapointValues(metric);
    const isYearly = isFrequencyYearly(metric, companyStandardId);
    const { result: aggregatedValues } = useAggregatedRowValue(metric);

    const getValuePerQuarter = (quarter: string) =>
      Number(dataPointsPerQuarter.find((dp) => dp.field === quarter)?.value?.value ?? 0);

    const metricGraphData: QuartersObject = useMemo(
      () =>
        createGraphObject(
          isYearly,
          getValuePerQuarter(QuartersEnums.q1),
          getValuePerQuarter(QuartersEnums.q2),
          getValuePerQuarter(QuartersEnums.q3),
          getValuePerQuarter(QuartersEnums.q4),
          Number(dataPointPerYear?.value) ?? 0
        ),
      [dataPointsPerQuarter]
    );

    const aggregatedGraphData: QuartersObject = useMemo(
      () =>
        createGraphObject(
          isYearly,
          aggregatedValues[QuartersEnums.q1],
          aggregatedValues[QuartersEnums.q2],
          aggregatedValues[QuartersEnums.q3],
          aggregatedValues[QuartersEnums.q4],
          aggregatedValues.Year
        ),
      [aggregatedValues]
    );

    return (
      <GridItem w="99%" key={index}>
        <VStack
          alignItems="start"
          border="1px solid"
          borderColor="border.decorative"
          borderRadius="8px"
          w="100%"
          h="100%"
          pt="8px"
        >
          <HStack
            p="6px 16px"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="border.decorative"
            width="100%"
          >
            <TruncatableText variant="bodyStrong" text={metric.metric.title} />
            <IconButton
              aria-label="maximize"
              variant="ghost"
              icon={<MaximizeIcon />}
              size="sm"
              onClick={() => {
                setSelectedMetric({ title: metric.metric?.title });
                setGraphData(isAggregated ? aggregatedGraphData : metricGraphData);
                onGraphOpen();
              }}
            />
          </HStack>
          <GraphCard data={isAggregated ? aggregatedGraphData : metricGraphData} height={200} />
        </VStack>
      </GridItem>
    );
  }
};
