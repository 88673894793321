export const getActivityQuestionLink = ({
  companyId,
  assessmentId,
  businessUnitId,
  activityReference,
  questionId,
}: {
  companyId: string;
  assessmentId: string;
  businessUnitId: string;
  activityReference?: string;
  questionId?: string;
}) => {
  return `/${companyId}/assessments/${assessmentId}/screening/${businessUnitId}/${activityReference}/${questionId}`;
};

export const getDisclosureRequirementLink = ({
  companyId,
  assessmentId,
  standardRef,
  disclosureRequirement,
}: {
  companyId: string;
  assessmentId: string;
  standardRef?: string;
  disclosureRequirement?: {
    reference: string;
    type: string;
    group: {
      standardRef: string;
    };
  };
}) => {
  return `/${companyId}/esrs/${assessmentId}/standard/${standardRef}/disclosure-requirement/${disclosureRequirement?.type}/${disclosureRequirement?.reference}`;
};

export const getBUDisclosureRequirementLink = ({
  companyId,
  assessmentId,
  businessUnitId,
  standardRef,
  disclosureRequirement,
}: {
  companyId: string;
  assessmentId: string;
  businessUnitId: string;
  standardRef?: string;
  disclosureRequirement?: {
    reference: string;
    type: string;
    group: {
      standardRef: string;
    };
  };
}) => {
  return `/${companyId}/esrs/${assessmentId}/bu-standard/${standardRef}/bu/${businessUnitId}/disclosure-requirement/${disclosureRequirement?.reference}`;
};
