import { useDisclosure } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  useGetMetricAnswerDocumentationByIdQuery,
  AttachmentBox,
  GetMetricAnswerDocumentationByIdDocument_,
  useUpsertMetricsAnswerMutation,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  NoteHistory,
} from 'models';
import {
  InputCardDocumentation,
  InputCardDocumentationProps,
} from 'Molecules/InputCard/InputCardDocumentation';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const MetricsDocumentation = ({
  answerId,
  metricRef,
  companyReportingUnit,
}: {
  answerId: string;
  isDisabled?: boolean;
  metricRef: string;
  companyReportingUnit?: string;
}) => {
  const user = useUserData();
  const [upsertAnswer] = useUpsertMetricsAnswerMutation();
  const { reportingUnitId = companyReportingUnit, esrsAssessmentId = '' } = useParams();
  const { data: documentationData, loading: documentationLoading } =
    useGetMetricAnswerDocumentationByIdQuery({
      variables: {
        metricAnswerId: answerId,
      },
      skip: !answerId,
    });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_Answer_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.esrs_Answer_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );

  useEffect(() => {
    if (!answerId) {
      upsertAnswer({
        variables: {
          objects: {
            reportingUnitId,
            assessmentId: esrsAssessmentId,
            metricRef,
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
              },
            },
          },
        },
      });
    }
  }, []);

  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      currentAuthor: user ?? undefined,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetMetricAnswerDocumentationByIdDocument_],
      isInputCard: false,
      placeholder: 'Add notes for the reviewer',
      showSingleFile: true,
      fileWidth: {
        boxWidth: '183px',
        titleMinWidth: '50px',
        titleMaxWidth: '50px',
      },
    };
  }, [attachmentBox, noteHistory, user, documentationLoading]);

  return (
    <AttachmentDrawer
      isOpen={isAttachmentDrawerOpen}
      refetch={[GetMetricAnswerDocumentationByIdDocument_]}
      onClose={onAttachmentDrawerClose}
      attachmentBox={attachmentBox}
    >
      <InputCardDocumentation {...showDocumentation} />
    </AttachmentDrawer>
  );
};
