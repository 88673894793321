import { Typography } from 'Tokens';
import { DocumentationFile, ResolvedQuestion } from 'models';
import { Modal, ModalProps } from 'Molecules';
import { Stack, StackProps, useDisclosure, VStack } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { QuestionRow } from 'Features/Screening/QuestionRow';
import {
  indexToRoman,
  useActivityVersionComparison,
  useCopyDocumentation,
} from '../VersioningOfQuestions.hooks';
import { useState } from 'react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import { useSaveAnswerAttachmentMutation } from 'Features/Screening/Screening.hooks';

const Frame = (props: StackProps) => (
  <VStack
    width="100%"
    height="auto"
    p="16px"
    alignItems="start"
    bg="bg.muted"
    borderRadius="8px"
    border="1px solid"
    borderColor="border.decorative"
    {...props}
  >
    {props.children}
  </VStack>
);
type AttachmentQuestion = Pick<ResolvedQuestion, 'id' | 'answer'>;

export const UpdatedQuestionModal = ({
  isOpen,
  onClose,
  updatedQuestion,
  activityAssessmentId,
}: {
  updatedQuestion: ResolvedQuestion | undefined;
  activityAssessmentId: string;
} & Pick<ModalProps, 'isOpen' | 'onClose'>) => {
  const { t } = useTranslation(['assessment', 'common']);
  const {
    isOpen: isAttachmentsOpen,
    onOpen: onAttachmentsOpen,
    onClose: onAttachmentsClose,
  } = useDisclosure();
  const [attachmentQuestion, setAttachmentQuestion] = useState<AttachmentQuestion>();
  const saveAttachments = useSaveAnswerAttachmentMutation();

  const { getRevision } = useActivityVersionComparison(activityAssessmentId);
  const { copyAttachments, copyNotes } = useCopyDocumentation(activityAssessmentId);

  if (!updatedQuestion) return null;

  const { oldQuestions, revisedQuestions, message } = getRevision(
    updatedQuestion.id,
    updatedQuestion.olderRevisions
  );

  const openAttachmentDrawer = (question?: AttachmentQuestion) => {
    setAttachmentQuestion(question);
    onAttachmentsOpen();
  };

  const onAttachmentsChanged = (filesToAttach: DocumentationFile[] | undefined | null) => {
    return saveAttachments(
      filesToAttach ?? [],
      activityAssessmentId,
      attachmentQuestion?.id,
      attachmentQuestion?.answer?.attachmentBox ?? undefined
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onClose}
      size="xl"
      title="Compare versions"
      hasCancelButton={false}
      confirmText={t('common:buttons.done')}
    >
      <AttachmentDrawer
        onAttachClicked={onAttachmentsChanged}
        isOpen={isAttachmentsOpen}
        onClose={onAttachmentsClose}
        attachmentBox={attachmentQuestion?.answer?.attachmentBox ?? undefined}
      >
        <VStack alignItems="start" spacing="16px">
          <Frame>
            <Typography variant="body">
              <strong>What changed:</strong> {message}
            </Typography>
          </Frame>
          <Stack spacing="0px" height="100%" alignItems="stretch" direction="row" width="100%">
            <Frame borderRightRadius="0px" borderRight="none" width="50%">
              <Typography variant="h4">Old version</Typography>
              <Typography variant="body">
                On this side you can see old related questions, your answers, notes and
                documentation
              </Typography>
              <VStack spacing="6px" width="100%">
                {oldQuestions.map((question, index) => (
                  <QuestionRow
                    key={question.id}
                    question={question}
                    index={oldQuestions.length > 1 ? indexToRoman(index) : ''}
                    activityAssessmentId={activityAssessmentId}
                    showVersionAlert={false}
                    readOnly={true}
                    learnMoreIconButton={true}
                    inModal={true}
                    openAttachmentDrawer={() => {}}
                    openUpdatedQuestionModal={() => {}}
                  />
                ))}
              </VStack>
            </Frame>
            <Frame bg="bg.info" borderLeftRadius="0px" borderLeftColor="bg.info" width="50%">
              <Typography variant="h4">New version</Typography>
              <Typography variant="body">
                Compare your old questions with new ones, and update or add new answers
              </Typography>
              <VStack spacing="6px" width="100%">
                {revisedQuestions.map((question, index) => (
                  <QuestionRow
                    key={question.id}
                    question={question}
                    index={oldQuestions.length > 1 ? indexToRoman(index) : ''}
                    inModal={true}
                    learnMoreIconButton={true}
                    activityAssessmentId={activityAssessmentId}
                    showVersionAlert={false}
                    openAttachmentDrawer={openAttachmentDrawer}
                    openUpdatedQuestionModal={() => {}}
                    isReusable={true}
                    questionsToCopyFrom={oldQuestions}
                    copyNotes={(oldQuestion) => copyNotes(oldQuestion, question)}
                    copyAttachments={(oldQuestion) => copyAttachments(oldQuestion, question)}
                  />
                ))}
              </VStack>
            </Frame>
          </Stack>
        </VStack>
      </AttachmentDrawer>
    </Modal>
  );
};
