import { Modal } from 'Molecules';
import { Suspense, useState } from 'react';
import { useTranslation } from 'utils/translation';
import { useUploadConsolidatedCsv } from './ConsolidatedFinancials.Hooks';
import { Uploader } from 'Features/CSVUploader/Uploader';

interface ConsolidatedFinancialsUploaderProps {
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const ConsolidatedFinancialsUploader = ({
  isOpen,
  onClose,
}: ConsolidatedFinancialsUploaderProps) => {
  const { t } = useTranslation(['group', 'csv']);

  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const uploadCsv = useUploadConsolidatedCsv();

  const handleOnClose = () => {
    setFile(null);
    setIsFileUploaded(false);
    onClose();
  };

  const DEFAULT_DESCRIPTIONS = [
    {
      title: t('csv:steps.uploadConsolidatedStep1'),
      description: t('csv:steps.uploadConsolidatedDesc1'),
    },
    {
      title: t('csv:steps.uploadConsolidatedStep2'),
      description: t('csv:steps.uploadConsolidatedDesc2'),
    },
    {
      title: t('csv:steps.uploadConsolidatedStep3'),
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      title={t('financials.upload.modalTitle')}
      onConfirm={async () => {
        if (file) {
          uploadCsv(file);
        }
        handleOnClose();
      }}
    >
      <Suspense>
        <Uploader
          onUpload={(uploadedFile: File) => {
            setIsFileUploaded(true);
            setFile(uploadedFile);
          }}
          isFileUploaded={isFileUploaded}
          setIsFileUploaded={setIsFileUploaded}
          rejectionMessages={[]}
          description={DEFAULT_DESCRIPTIONS}
        ></Uploader>
      </Suspense>
    </Modal>
  );
};
