import { Menu, MenuButton, MenuGroup, MenuList, MenuItem, HStack, Divider } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { ReportingFrequency } from './PaiIndicatorsModal';
import { useEffect, useState } from 'react';

export const PaiDataCollectionFrequencySelector = ({
  selectedFrequency = ReportingFrequency.yearly,
  setSelectedFrequency,
}: {
  selectedFrequency: ReportingFrequency;
  setSelectedFrequency: (selectedFrequency: ReportingFrequency) => void;
}) => {
  const [localFrequency, setLocalFrequency] = useState<ReportingFrequency>(
    selectedFrequency ?? ReportingFrequency.yearly
  );

  useEffect(() => {
    setLocalFrequency(selectedFrequency);
  }, [selectedFrequency]);

  useEffect(() => {
    setSelectedFrequency(localFrequency);
  }, [localFrequency]);

  return (
    <Menu gutter={2} size="md">
      <MenuButton
        as={Button}
        width="100%"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        color="text.default"
      >
        <Typography variant="body">{localFrequency}</Typography>
      </MenuButton>
      <MenuList p="8px">
        <MenuGroup p="8px">
          <MenuItem
            p="8px"
            m="0px"
            w="100%"
            onClick={() => setLocalFrequency(ReportingFrequency.yearly)}
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              color={localFrequency === ReportingFrequency.yearly ? 'text.selected' : ''}
            >
              <HStack>
                <Typography color={'inherit'} variant="bodyStrong">
                  {ReportingFrequency.yearly}
                </Typography>
              </HStack>
              {selectedFrequency === ReportingFrequency.yearly && <CheckIcon color="inherit" />}
            </HStack>
          </MenuItem>
          <MenuItem
            p="8px"
            m="0px"
            w="100%"
            onClick={() => setLocalFrequency(ReportingFrequency.quarterly)}
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              color={selectedFrequency === ReportingFrequency.quarterly ? 'text.selected' : ''}
            >
              <HStack>
                <Typography color="inherit" variant="bodyStrong">
                  {ReportingFrequency.quarterly}
                </Typography>
              </HStack>
              {localFrequency === ReportingFrequency.quarterly && <CheckIcon color="inherit" />}
            </HStack>
          </MenuItem>
        </MenuGroup>
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <MenuGroup p="8px">
          <MenuItem
            p="8px"
            m="0px"
            w="100%"
            onClick={() => setLocalFrequency(ReportingFrequency.customPerIndicator)}
          >
            <HStack
              w="100%"
              justifyContent="space-between"
              color={
                localFrequency === ReportingFrequency.customPerIndicator ? 'text.selected' : ''
              }
            >
              <HStack>
                <Typography color="inherit" variant="bodyStrong">
                  Custom per indicator
                </Typography>
              </HStack>
              {localFrequency === ReportingFrequency.customPerIndicator && (
                <CheckIcon color="inherit" />
              )}
            </HStack>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
