import { Box, HStack, VStack } from '@chakra-ui/react';
import saveAs from 'file-saver';
import {
  DocumentationFile,
  GetPoliciesSubsidiariesQuery_,
  useGetPoliciesSubsidiariesQuery,
} from 'models';
import { Loader, SearchInput, Select, Table } from 'Molecules';
import { Menu } from 'Molecules/Menu';
import { useMemo, useState } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { DownloadIcon } from 'Tokens/Icons/Function';
import { getNameExtension } from 'utils/files';
import { nhost } from 'utils/nhost';

type SubsidiariesData = NonNullable<
  NonNullable<
    GetPoliciesSubsidiariesQuery_['esrs']
  >['subsidiaries'][number]['materialStandards'][number]['policies'][number]['attachmentBox']
>['attachments'][number] & {
  company: {
    id: any;
    name: string;
  };
};
const ALL_SUBSIDIARIES = 'All subsidiaries';

function getFilter(searchTerm: string, selectedCompany: string | undefined) {
  searchTerm = searchTerm.toLocaleLowerCase().trim();
  if (
    searchTerm.length === 0 &&
    (selectedCompany === ALL_SUBSIDIARIES || selectedCompany === undefined)
  )
    return () => true;
  return (s: SubsidiariesData | undefined) =>
    (searchTerm.length && s?.file.title.toLocaleLowerCase().includes(searchTerm)) ||
    s?.company.id === selectedCompany;
}

export const PoliciesSubsidiaries = () => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<string>();

  const { data, loading } = useGetPoliciesSubsidiariesQuery({
    variables: {
      esrsAssessmentId,
      standardRef,
    },
    skip: !esrsAssessmentId || !standardRef,
  });

  const subsidiaries = useMemo(() => data?.esrs?.subsidiaries, [data]);

  const subsidiariesOptions = useMemo(
    () =>
      subsidiaries?.map((a) => ({
        value: String(a.company.id),
        label: a.company.name,
      })) ?? [],
    [subsidiaries]
  );

  const subsidiariesData = useMemo(
    () =>
      subsidiaries
        ?.flatMap((sub) =>
          sub.materialStandards[0]?.policies.flatMap((policy) =>
            policy?.attachmentBox?.attachments.map((a) => ({ ...a, company: sub.company }))
          )
        )
        .filter((s) => s !== undefined) ?? [],
    [subsidiaries]
  );

  const filteredSubsidiariesData = useMemo(() => {
    return subsidiariesData.filter(getFilter(searchValue, selectedSubsidiary));
  }, [subsidiariesData, searchValue, selectedSubsidiary]);

  const downloadAttachedFile = async (file: DocumentationFile) => {
    return nhost.storage
      .getPresignedUrl({ fileId: file?.storageFile?.id })
      .then(({ presignedUrl }) => {
        if (presignedUrl?.url) saveAs(presignedUrl.url, file.title);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {subsidiariesData.length !== 0 && (
        <VStack spacing="16px" alignItems="start" w="100%">
          <Typography variant="h3">Subsidiaries</Typography>
          <HStack width="100%" justifyContent="space-between">
            <HStack spacing="8px" width="408px">
              <SearchInput
                search={searchValue}
                setSearch={setSearchValue}
                placeholder="Filter"
                minW="200px"
              />
              <Select<{ value: string; label: string }>
                value={
                  selectedSubsidiary
                    ? subsidiariesOptions?.find((s) => s.value === selectedSubsidiary)
                    : { value: ALL_SUBSIDIARIES, label: ALL_SUBSIDIARIES }
                }
                onChange={(val) => setSelectedSubsidiary(val?.value ? val.value : ALL_SUBSIDIARIES)}
                options={
                  [
                    ...subsidiariesOptions,
                    { value: ALL_SUBSIDIARIES, label: ALL_SUBSIDIARIES },
                  ] as {
                    value: string;
                    label: string;
                  }[]
                }
                size="md"
              />
            </HStack>
          </HStack>
          <Table<(typeof subsidiariesData)[number]>
            rowProps={{
              _hover: {
                bg: 'bg.hover',
              },
            }}
            cellPadding="10px 8px"
            headerPadding="14px 8px"
            columns={[
              {
                header: 'Title',
                accessorKey: 'title',
                meta: {
                  colSpan: 1,
                },
                cell: ({ row }) => (
                  <HStack>
                    <Box width="32px" padding="4px">
                      <FileIcon
                        extension={
                          getNameExtension(row.original?.file.storageFile?.name ?? '').extension
                        }
                        {...defaultStyles[
                          row.original?.file.storageFile?.name as keyof typeof defaultStyles
                        ]}
                        labelUppercase
                      />
                    </Box>
                    <Typography>{row.original?.file.title}</Typography>
                  </HStack>
                ),
              },
              {
                header: 'Subsidiary',
                accessorKey: 'subsidiary',
                meta: {
                  colSpan: 1,
                },
                cell: ({ row }) => (
                  <Box
                    border="1px solid"
                    borderColor="border.default"
                    width="fit-content"
                    p="4px 10px"
                    borderRadius="6px"
                  >
                    <Typography variant="bodyStrong"> {row.original?.company.name}</Typography>
                  </Box>
                ),
              },
              {
                header: '',
                accessorKey: 'actions',
                meta: {
                  colSpan: 1,
                },
                cell: ({ row }) => (
                  <Box textAlign="right">
                    <Menu
                      sections={[
                        {
                          actions: [
                            {
                              id: 'download',
                              title: 'Download',
                              leftElement: <DownloadIcon color="inherit" />,

                              onClick: () => {
                                if (row.original?.file) downloadAttachedFile(row.original?.file);
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                ),
              },
            ]}
            data={filteredSubsidiariesData}
          />
        </VStack>
      )}
    </>
  );
};
