import { Box, Circle, HStack, VStack } from '@chakra-ui/react';
import {
  EsrsReportData,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
} from './Report.types';
import { Typography, colors } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { useCurrentCompany } from 'utils/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  GetReportMaterialStandardsDocument_,
  useEsrsAssessmentQuery,
  useUpsertMaterialMetricsMutation,
} from 'models';
import { CompletionStatus, ReportStatusBar, ReportStatusIcon, toRomanNumeral } from './ReportUtils';
import { Button, IconButton } from 'Atoms';
import { PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { QuestionType } from 'utils/scores/questions';
import { useEffect, useMemo, useState } from 'react';
import { ReportMetricTable } from './ReportMetricTable';
import { PieChart } from 'react-minimal-pie-chart';
import { COMPANY, MetricViewEnums } from '../DisclosureRequirements';

const MetricCard = ({
  metric,
  standardId,
  standardRef,
  standardName,
  isDrHidden,
  drRef,
}: {
  metric: EsrsReportMetric;
  standardId: string;
  isDrHidden?: boolean;
  standardName: string;
  standardRef: string;
  drRef: string;
}) => {
  const [hidden, setHidden] = useState(metric.metric.isHidden);
  const { esrsAssessmentId = '' } = useParams();
  const company = useCurrentCompany();
  const navigate = useNavigate();
  const metricDetails = metric.metric;

  useEffect(() => {
    if (isDrHidden === true) {
      setHidden(true);
    } else if (isDrHidden === false) setHidden(false);
  }, [isDrHidden]);
  useEffect(() => setHidden(metric.metric.isHidden), [metric]);

  const provideAnswer = () => {
    navigate(
      metric.metric.dataCollection === COMPANY
        ? `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drRef}/${MetricViewEnums.dataInput}#${metric.metric.metric.reference}`
        : `/${company?.company?.id}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${metric.reportingUnitId}/disclosure-requirement/${drRef}/${MetricViewEnums.dataInput}#${metric.metric.metric.reference}`
    );
  };
  const status = useMemo(() => {
    if (hidden) {
      return CompletionStatus.hidden;
    }
    if (metric.completed) {
      return CompletionStatus.complete;
    }
    return CompletionStatus.incomplete;
  }, [metric, hidden]);

  return (
    <VStack
      alignItems="start"
      borderTop="1px solid"
      w="100%"
      borderColor="border.decorative"
      pt="6px"
      spacing="6px"
      pb="16px"
      id={`${standardRef}-${metric.metric.metricRef}`}
    >
      <ReportStatusBar
        standardId={standardId}
        metric={metric.tableData}
        reportingUnitId={metric.tableData.reportingUnitId ?? metric.reportingUnitId}
        status={status}
        isMetricHidden={hidden}
        standardName={standardName}
        standardRef={standardRef}
        drRef={drRef}
        dataCollection={metric.metric.dataCollection ?? ''}
        completed={metric.completed ?? false}
        setHidden={setHidden}
      />
      <VStack alignItems="start" opacity={hidden ? 0.3 : 1} w="100%" spacing="4px">
        <Typography variant="bodyStrong">{metricDetails.metric.title}</Typography>
        {metric.completed ? (
          metricDetails.metric.metricType === QuestionType.LongText_ ? (
            <Typography variant="body">{metric?.textAnswer}</Typography>
          ) : (
            <ReportMetricTable
              metrics={[metric.tableData]}
              currency={company.company?.currency ?? ''}
              standardId={standardId}
            />
          )
        ) : (
          <VStack gap="12px" alignItems="start">
            <Typography variant="body" color="text.hint">
              No answer has been provided yet
            </Typography>
            <Button variant="secondary" size="sm" onClick={provideAnswer}>
              Provide answer
            </Button>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

const DrCard = ({
  drData,
  standardId,
  standardName,
  standardRef,
}: {
  drData: EsrsReportDisclosureRequirement;
  standardId: string;
  standardName: string;
  standardRef: string;
}) => {
  const [isDrHidden, setIsDrHidden] = useState<boolean>(drData.isHidden);
  const [metricsHidden, setMetricsHidden] = useState<boolean>();
  const [upsertMetric, { loading }] = useUpsertMaterialMetricsMutation();
  const { esrsAssessmentId = '' } = useParams();
  const company = useCurrentCompany();
  const editAnswer = () => {
    window.open(
      `/${company?.company?.id}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${drData.reference}`
    );
  };
  const isCompleted = useMemo(() => drData.metrics.every((metric) => metric.completed), [drData]);
  const numberOfAnswers = useMemo(
    () => drData.metrics.filter((metric) => metric.completed).length,
    [drData]
  );

  useEffect(
    () => setIsDrHidden(drData.metrics.every((metric) => metric.metric.isHidden)),
    [drData]
  );

  const hideShowDr = () => {
    setIsDrHidden(!isDrHidden);
    setMetricsHidden(!isDrHidden);
    upsertMetric({
      variables: {
        objects: drData.metrics.map((metric) => {
          return {
            isHidden: !isDrHidden,
            id: metric.metric.id,
            materialStandardId: standardId,
            metricRef: metric.metric.metricRef,
          };
        }),
      },
      refetchQueries: [GetReportMaterialStandardsDocument_],
    });
  };

  const status = useMemo(() => {
    if (isDrHidden) {
      return CompletionStatus.hidden;
    }
    if (isCompleted) {
      return CompletionStatus.complete;
    }
    return CompletionStatus.incomplete;
  }, [isDrHidden, isCompleted]);

  return (
    <VStack
      id={`${drData.reference}-Prepare`}
      p="20px"
      border="1px solid"
      borderColor="border.decorative"
      borderRadius="8px"
      w="100%"
      alignItems="start"
      gap="4px"
    >
      <HStack justifyContent="space-between" w="100%">
        <HStack>
          {status !== CompletionStatus.incomplete ? (
            <ReportStatusIcon status={status} />
          ) : (
            <Circle size="16px" alignItems="center">
              <PieChart
                lineWidth={16}
                data={[
                  {
                    title: 'Collected',
                    value: (numberOfAnswers / drData.metrics.length) * 100,
                    color: colors.bg.progress,
                  },
                  {
                    title: 'Missing',
                    value: 100 - (numberOfAnswers / drData.metrics.length) * 100,
                    color: colors.bg.unknown,
                  },
                ]}
              />
            </Circle>
          )}
          <Typography variant="bodyStrong">
            {numberOfAnswers}/{drData.metrics.length}
          </Typography>
        </HStack>
        <HStack gap="2px">
          <IconButton
            aria-label="hide"
            variant="ghost"
            size="sm"
            onClick={hideShowDr}
            isLoading={loading}
            icon={isDrHidden ? <PrivateIcon /> : <PublicIcon />}
          />
          <IconButton
            aria-label="edit"
            size="sm"
            variant="ghost"
            icon={<ArrowUpRightIcon />}
            onClick={editAnswer}
            tooltipLabel="Open the source"
          />
        </HStack>
      </HStack>
      <VStack alignItems="stretch" gap="20px" w="100%">
        <Typography variant="h3" color="text.default">
          {drData.reference} {drData.title}
        </Typography>
        <VStack alignItems="stretch" gap="20px" w="100%">
          {drData.metrics.map((metric) => (
            <MetricCard
              metric={metric}
              standardId={standardId}
              isDrHidden={metricsHidden}
              standardRef={standardRef}
              standardName={standardName}
              drRef={drData.reference}
            />
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
};

const StandardCard = ({ standard }: { standard: EsrsReportStandard }) => {
  return (
    <VStack alignItems="start" w="100%" gap="16px" id={`${standard.reference}-Prepare`}>
      <Typography variant="h2">{standard.title}</Typography>
      {standard.disclosureRequirements.map((dr) => (
        <VStack alignItems="start" w="100%" gap="8px">
          <DrCard
            drData={dr}
            standardId={standard.id}
            standardRef={standard.reference}
            standardName={standard.title}
          />
        </VStack>
      ))}
    </VStack>
  );
};

export const ReportContent = ({ data }: { data: EsrsReportData }) => {
  const { t } = useTranslation('esrs');
  const { esrsAssessmentId } = useParams();
  const { hash } = useLocation();
  const { data: assessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  useEffect(() => {
    const element = document.getElementById(hash.slice(1));
    if (element && hash.slice(1) === element.id) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, [hash, assessment]);

  return (
    <Box
      overflowY="scroll"
      css={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
        scrollbarWidth: 'none',
      }}
      padding="2px 10px"
      height="calc(100vh - 128px)"
    >
      <VStack
        maxW="1088px"
        p="72px 48px"
        border="1px solid"
        borderColor="border.decorative"
        boxShadow="0px 4px 6px 5px rgba(0, 0, 0, 0.05)"
        alignItems="start"
        borderRadius="12px"
      >
        <VStack maxW="800px" gap="32px" alignItems="start">
          <Typography variant="d3" whiteSpace="normal">
            {t('assessment.report.reportTitle') +
              ' for ' +
              assessment?.esrsAssessment?.company.name +
              ' ' +
              assessment?.esrsAssessment?.reportingYear}
          </Typography>

          <VStack alignItems="start" w="100%" gap="32px">
            {data.categories
              .filter((category) => category.standards.length)
              .map((cat, index) => (
                <VStack alignItems="start" w="100%" gap="24px" id={`${cat.reference}-Prepare`}>
                  <Typography variant="h1">
                    {toRomanNumeral(index + 1) + '. ' + cat.title}
                  </Typography>
                  {cat.standards.map((std) => (
                    <StandardCard standard={std} />
                  ))}
                </VStack>
              ))}
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
