import { useForm } from 'react-hook-form';
import { Portfolio } from 'models';
import { VStack } from '@chakra-ui/react';
import { FormField, Input } from 'Atoms';
import { useTranslation } from 'utils/translation';
import { useUpsertPortfolio } from '../Portfolios.hooks';
import { Modal } from 'Molecules';
import { useEffect } from 'react';

type PortfolioFields = {
  name: string;
  description: string;
};

export const PortfolioEditorModal = ({
  portfolio,
  isOpen,
  onClose,
}: {
  portfolio?: Portfolio;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation('portfolio');
  const upsertPortfolio = useUpsertPortfolio();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<PortfolioFields>({
    defaultValues: {
      name: portfolio?.name,
      description: portfolio?.description,
    },
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    reset({
      name: portfolio?.name,
      description: portfolio?.description,
    });
  }, [portfolio]);

  const onSubmit = (data: PortfolioFields) => {
    upsertPortfolio({
      name: data.name,
      description: data.description,
      id: portfolio?.id,
    }).then(() => handleClose());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={portfolio ? t('portfolio:updateButtonLabel') : t('portfolio:createButtonLabel')}
      confirmText={portfolio ? t('portfolio:updateButton') : t('portfolio:create')}
      confirmButtonProps={{
        type: 'submit',
        form: 'portfolio-editor-form',
        isDisabled: !isValid || !isDirty,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="portfolio-editor-form">
        <VStack spacing="sm">
          <FormField id="name" isRequired label={t('portfolio:fields.name.label')}>
            <Input width="100%" {...register('name')} />
          </FormField>
          <FormField id="description" label={t('portfolio:fields.description.label')}>
            <Input width="100%" {...register('description')} />
          </FormField>
        </VStack>
      </form>
    </Modal>
  );
};
