import { useDisclosure, Button, VStack, Box, ButtonProps, ModalProps } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { IconButton, Tabs } from 'Atoms';
import { Typography } from 'Tokens';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { ArticleBox } from 'containers/Article';
import { Modal } from 'Molecules';
import { useCurrentCompanyId } from 'utils/hooks';
import { ResolvedQuestion } from 'models/types';
import { useTranslation } from 'utils/translation';
import { QuestionContextProvider } from './QuestionRow';
import { useCurrentQuestion } from 'context';
import { ResourceIcon } from 'Tokens/Icons/Data';
import { HelpIcon } from 'Tokens/Icons/Status';

type TabType = {
  tab: { icon: React.ReactNode; label: string };
  panel: React.ReactNode;
  selected?: boolean;
};
export const LearnMoreContext = React.createContext(
  (_data: { tabs: TabType[]; header: string }) => {}
);

export function LearnMoreModal({ isOpen, onClose }: Pick<ModalProps, 'isOpen' | 'onClose'>) {
  const question = useCurrentQuestion();
  const { dependencyDescription, learnMoreArticleSlug, extendedDescription, description } =
    question;
  const hasDescription = extendedDescription || description;
  const { t } = useTranslation('question');

  const tabs = useMemo(() => {
    const content = [];
    if (hasDescription) {
      content.push({
        id: 'description',
        title: t('question:learnMore.description'),
        content: (
          <VStack paddingY="24px" spacing="16px">
            {description && <Typography variant="body">{description}</Typography>}
            {extendedDescription && (
              <Box
                className="extended-description-table custom-html"
                dangerouslySetInnerHTML={{ __html: extendedDescription }}
              />
            )}
          </VStack>
        ),
      });
    }
    if (dependencyDescription) {
      content.push({
        id: 'dependencies',
        title: t('question:learnMore.dependencies'),
        content: (
          <VStack paddingY="24px" spacing="16px">
            <Typography variant="body">{dependencyDescription}</Typography>{' '}
          </VStack>
        ),
      });
    }
    if (learnMoreArticleSlug) {
      content.push({
        id: 'resources',
        title: t('question:learnMore.resources'),
        leftElement: (
          <Box mt="0.5em">
            <ResourceIcon color="inherit" />
          </Box>
        ),
        content: <ArticleBox slug={learnMoreArticleSlug} padding={2} paddingTop="24px" />,
      });
    }
    return content;
  }, [dependencyDescription, learnMoreArticleSlug, hasDescription]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      title={`${question.number} ${t('question:learnMore.button')}`}
      hasFooter={false}
    >
      <Suspense>
        <VStack spacing="32px" width="100%">
          {question && (
            <QuestionContextProvider question={question} actions={false} showVersionAlert={false} />
          )}

          {tabs?.length ? (
            <Tabs items={tabs} />
          ) : (
            <VStack width="100%" textAlign="center" spacing="16px">
              <Typography variant="bodyStrong">{t('question:learnMore.coming')}</Typography>
              <Typography variant="body">{t('question:learnMore.support')}</Typography>
            </VStack>
          )}
        </VStack>
      </Suspense>
    </Modal>
  );
}

export function LearnMoreButton({
  question,
  buttonType = 'text',
  ...props
}: ButtonProps & { question: ResolvedQuestion; buttonType?: 'text' | 'icon' }) {
  const { questionSetRef, uniqueId: questionId } = question;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation('question');
  const { companyId } = useCurrentCompanyId();

  return (
    <>
      {buttonType === 'text' && (
        <Button
          variant="secondary"
          size="md"
          leftIcon={<HelpIcon color="inherit" />}
          onClick={() => {
            onOpen();
            mixpanel.track(TRACKING_EVENTS.LEARN_MORE.OPEN, {
              companyId,
              questionSetRef,
              questionId,
            });
          }}
          {...props}
        >
          {t('question:learnMore.button') as string}
        </Button>
      )}
      {buttonType === 'icon' && (
        <IconButton
          aria-label="LearnMoreButton"
          variant="secondary"
          size="md"
          icon={<HelpIcon color="inherit" />}
          onClick={() => {
            onOpen();
            mixpanel.track(TRACKING_EVENTS.LEARN_MORE.OPEN, {
              companyId,
              questionSetRef,
              questionId,
            });
          }}
        />
      )}
      <LearnMoreModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
