import { Box, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { MetricsDocumentation } from '../MetricsDocumentation';

export const MetricSource = ({
  answerId,
  reference,
  hasOptedOut,
  companyReportingUnit,
}: {
  answerId: string;
  reference: string;
  hasOptedOut?: boolean;
  companyReportingUnit?: string;
}) => {
  return (
    <Box padding="24px 20px">
      <VStack alignItems="start" width="100%" spacing="12px">
        <Typography variant="h3">Documentation</Typography>
        <MetricsDocumentation
          answerId={answerId}
          metricRef={reference}
          isDisabled={hasOptedOut}
          companyReportingUnit={companyReportingUnit}
        />
      </VStack>
    </Box>
  );
};
