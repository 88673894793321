import { FileUpload } from 'Molecules';
import React from 'react';
import { nhost } from 'utils/nhost';
import { useToast } from 'utils/hooks';
import { LogoPreview } from './LogoPreview';
import { useTranslation } from 'utils/translation';

const LOGO_MAX_SIZE = 1310720;
export const LogoUploader = ({
  url,
  setUrl,
}: {
  url: string;
  setUrl: (value?: string) => void;
}) => {
  const { t } = useTranslation('common');
  const toast = useToast();

  const uploadFile = async (file: File) => {
    if (file?.size > LOGO_MAX_SIZE) {
      return toast({
        text: "Logo file size can't be greater than 10MB. Please upload a smaller file.",
        variant: 'danger',
      });
    }
    const { fileMetadata, error } = await nhost.storage.upload({ file, bucketId: 'logos' });
    if (error) {
      return toast({
        text: `${t('common:toast.error')}, ${error?.message}`,
        variant: 'danger',
      });
    }
    const publicUrl = nhost.storage.getPublicUrl({ fileId: fileMetadata.id });
    setUrl(publicUrl);
  };

  if (url && url !== '') {
    return <LogoPreview url={url} onDelete={() => setUrl(undefined)} />;
  }
  return (
    <FileUpload
      accept={{ image: ['.jpg', '.png', '.bmp'] }}
      addFiles={([newFile]) => {
        uploadFile(newFile);
      }}
    />
  );
};
