import { Thead, Td, HStack, Tr, Table as ChakraTable, Box } from '@chakra-ui/react';
import { TruncatableText } from 'Atoms';
import { HelpTooltip } from 'Molecules';
import { Typography } from 'Tokens';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { BOX_STATES, MaterialityStatusBox } from './MaterialityUtils';
import { MappedDisclosureRequirements } from './MaterialityAssessment.d';
import { GetParentMaterialityAssessmentQuery_ } from 'models';
import { useTranslation } from 'react-i18next';

export const DisclosureRequirementsTable = ({
  isGroupOwner,
  hasParent,
  DisclosureRequirements,
  handleOpenModal,
  isStandardMandatory,
  parentData,
}: {
  isGroupOwner: boolean;
  hasParent: boolean;
  DisclosureRequirements: MappedDisclosureRequirements;
  parentData?: GetParentMaterialityAssessmentQuery_;
  handleOpenModal: (reference: string) => void;
  isStandardMandatory: boolean;
}) => {
  const { t } = useTranslation('esrs');
  return (
    <Box width="100%" border="1px solid" borderColor="border.decorative" borderRadius="8px">
      <ChakraTable
        width="100%"
        maxWidth={isStandardMandatory ? '100%' : '740px'}
        sx={{ tableLayout: 'fixed' }}
        variant="unstyled"
      >
        <Thead borderBottom="1px solid" borderColor="border.decorative">
          <Td alignSelf="flex-start" p="13px 16px 8px">
            <Typography variant="bodyStrong">Disclosure requirement</Typography>
          </Td>
          <Td p="13px 8px" width="14%">
            <Typography variant="bodyStrong" align="left">
              Ref.
            </Typography>
          </Td>
          {!isGroupOwner && hasParent && !isStandardMandatory && (
            <Td p="13px 8px" width="122px">
              <HStack>
                <Typography variant="bodyStrong">Group</Typography>
                <HelpTooltip label={t('assessment.materialityAssessment.table.tooltip.group')} />
              </HStack>
            </Td>
          )}
          <Td p="13px 8px" width={isGroupOwner ? '18%' : '150px'}>
            <HStack>
              <Typography variant="bodyStrong">
                {isGroupOwner || isStandardMandatory ? 'Status' : 'Your company'}
              </Typography>
              {!isStandardMandatory && (
                <HelpTooltip
                  label={
                    isGroupOwner || !hasParent
                      ? t('assessment.materialityAssessment.table.tooltip.company')
                      : t('assessment.materialityAssessment.table.tooltip.subsidiary')
                  }
                />
              )}
            </HStack>
          </Td>
          <Td width="48px"></Td>
        </Thead>
        {DisclosureRequirements.sort((a, b) => a.order - b.order).map((requirement, key) => (
          <Tr
            key={key}
            borderBottom={key < DisclosureRequirements.length - 1 ? '1px solid' : 'none'}
            cursor="pointer"
            _hover={{ backgroundColor: 'bg.hover' }}
            onClick={() => handleOpenModal(requirement.drRef)}
            borderColor="border.decorative"
          >
            <Td pl="16px" w="400px">
              <TruncatableText variant="body" tooltipMaxWidth="400px" text={requirement.title} />
            </Td>
            <Td p="13px 8px">
              <TruncatableText variant="body" text={requirement.drRef} />
            </Td>
            {!isGroupOwner &&
              parentData?.EsrsAssessment_by_pk?.parentAssessment &&
              !isStandardMandatory && (
                <Td p="13px 8px" width="122px">
                  <Typography variant="body">
                    {BOX_STATES[requirement.parentMateriality].text}
                  </Typography>
                </Td>
              )}
            <Td width="18%" align="left" p="0px">
              <MaterialityStatusBox materiality={requirement.materialityStatus} />
            </Td>
            <Td textAlign="right" p="0px 24px 0px 0px">
              <ArrowNarrowRightIcon color="text.muted" width="16px" />
            </Td>
          </Tr>
        ))}
      </ChakraTable>
    </Box>
  );
};
