import { AvatarGroup as ChakraAvatarGroup } from '@chakra-ui/react';
import { Avatar, AvatarSize } from 'Atoms';
import { TeamMember } from 'containers/TeamMembers';

export const AvatarGroup = ({
  names,
  size = 'md',
  namesOrLogos,
  members,
  isRounded = true,
}: {
  names?: string[];
  size?: AvatarSize;
  namesOrLogos?: { name: string; logoUrl?: string }[];
  members?: TeamMember[];
  isRounded?: boolean;
}) => {
  return (
    <ChakraAvatarGroup size={size} max={3} spacing="-6px">
      {names?.map((name) => (
        <Avatar name={name} size={size} />
      ))}
      {namesOrLogos?.map((avatar) => (
        <Avatar
          name={avatar.name}
          size={size}
          src={avatar.logoUrl}
          borderRadius={isRounded ? '50%' : '6px'}
        />
      ))}
      {members?.map((member) => {
        const active = member.id && !member.disabled;
        return (
          <Avatar
            size="sm"
            name={member?.name ?? member.email}
            color={active ? 'text.onAccent' : 'text.default'}
            backgroundColor={active ? 'bg.accent' : 'bg.disabled.accent'}
          />
        );
      })}
    </ChakraAvatarGroup>
  );
};
