import { uniqBy } from 'lodash';
import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetric_Constraint_,
  Esrs_MaterialMetric_Update_Column_,
  GetMaterialStandardDocument_,
  GetDisclosureRequirementMaterialityDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useEsrsAssessmentQuery,
  useGetEsrsCategoriesQuery,
  useGetParentMaterialityAssessmentQuery,
  useUpsertMaterialStandardMutation,
  useUpsertDisclosureRequirementMaterialityMutation,
  useUpsertPolicyMutation,
  MaterialStandardMetricRequirementsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetDisclosureRequirementMetricsDocument_,
  CompanyLevelMetricsPerDisclosureDocument_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  GetTargetsDrDocument_,
  GetActionsDrDocument_,
  GetPoliciesDrDocument_,
  GetMetricsDrDocument_,
  GetEsrsStandardQuery_,
  GetMaterialStandardQuery_,
  Esrs_MaterialDisclosureRequirement_Constraint_,
  Esrs_MaterialDisclosureRequirement_Update_Column_,
  useUpsertMultipleMaterialStandardsMutation,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import {
  DisclosureRequirementGroup,
  MaterialDisclosureRequirement,
  Materiality,
  MaterialityFields,
  MaterialityState,
  MaterialMetric,
  ParentMaterialDrs,
  Requirement,
  MaterialMap,
} from './MaterialityAssessment.d';
import { MaterialityStatus } from './MaterialityRadioGroup';

export const getMaterialityAssessment = (esrsAssessmentId?: string) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: parentMaterialityAssessmentData, loading: loadingParentMaterialityAssessment } =
    useGetParentMaterialityAssessmentQuery({
      variables: { childAssessmentId: esrsAssessmentId },
      skip: !esrsAssessmentId,
    });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return esrsCategoriesData?.EsrsCategory ?? [];
  }, [esrsCategoriesData]);

  const materialityAssessments = useMemo(() => {
    const hasParentMaterialityAssessment =
      !!parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment
        ?.materialStandards &&
      parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards
        ?.length > 0;
    if (data?.esrsAssessment?.materialStandards) {
      const mappedCategories: MaterialMap[] = categories.map((category) => {
        return {
          categoryName: category.title,
          categoryRef: category.reference,
          materialStandards:
            category.standards
              ?.map((standard) => {
                const materialStandard = data?.esrsAssessment?.materialStandards?.find((ms) => {
                  return ms.standardRef === standard.reference;
                });

                return {
                  standardRef: standard.reference,
                  standardName: standard.title,
                  description: standard.description,
                  isTopical: standard.isTopical,
                  disclosureRequirementGroups: standard.disclosureRequirementGroups,
                  materialDisclosureRequirements: materialStandard?.materialDisclosureRequirements,
                  isMaterial: materialStandard?.isMaterial,
                  isDataGatheringOnly: materialStandard?.isDataGatheringOnly,
                  isParentMaterial:
                    parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                      (ms) => {
                        return ms.standardRef === standard.reference;
                      }
                    )?.isMaterial,
                  metrics: materialStandard?.materialMetrics ?? [],
                  parentCompanyMetrics:
                    parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                      (ms) => {
                        return ms.standardRef === standard.reference;
                      }
                    )?.materialMetrics ?? [],
                  isDataCollected: materialStandard?.isDataCollected ?? false,
                };
              })
              ?.sort((a, b) => a.standardRef.localeCompare(b.standardRef)) ?? [],
          hasParentMaterialityAssessment,
        };
      });
      return mappedCategories;
    } else return [];
  }, [data, parentMaterialityAssessmentData, esrsCategoriesData]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingParentMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingParentMaterialityAssessment]);

  return {
    materialityAssessments,
    loading,
  };
};

export const useAddMaterialityAssessment = () => {
  const { standardRef, esrsAssessmentId } = useParams();
  const [addAssessment] = useUpsertMaterialStandardMutation();
  const [upsertPolicy] = useUpsertPolicyMutation();
  const [upsertMateriality] = useUpsertDisclosureRequirementMaterialityMutation();
  const toast = useToast();

  return useCallback(
    (
      values: MaterialityFields,
      material: boolean,
      materialMetrics: MaterialMetric[],
      parentMetrics: {
        metricRef: string;
        isMaterial?: boolean | null;
        dataCollection?: string | null | undefined;
      }[]
    ) => {
      const defaultSubsidiariesMetrics = parentMetrics
        .filter((metric) => metric.isMaterial)
        .map((metric) => ({
          isMaterial: false,
          metricRef: metric.metricRef,
        }));

      addAssessment({
        variables: {
          materialStandard: {
            assessmentId: esrsAssessmentId,
            standardRef,
            isDataGatheringOnly: values.material === MaterialityState.gatherData,
            isMaterial:
              values.material === null
                ? null
                : values.material === MaterialityState.material ||
                  values.material === MaterialityState.gatherData,
            materialMetrics: {
              data: materialMetrics.length
                ? materialMetrics.map(({ isMaterial, isDataGatheringOnly, metricRef }) => ({
                    isMaterial,
                    isDataGatheringOnly,
                    metricRef,
                  }))
                : defaultSubsidiariesMetrics,
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.Metadata_,
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                ],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          },
        },
        refetchQueries: [
          EsrsAssessmentDocument_,
          GetMaterialStandardDocument_,
          MaterialStandardMetricRequirementsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          GetTargetsDrDocument_,
          GetActionsDrDocument_,
          GetPoliciesDrDocument_,
          GetMetricsDrDocument_,
          GetDisclosureRequirementMetricsDocument_,
          CompanyLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
        ],
      })
        .then((res) => {
          toast({
            text: material ? 'Materiality assessment updated!' : 'Materiality assessment added!',
          });
          const result = res.data?.insert_esrs_MaterialStandard_one;

          if (values.material === MaterialityState.material) {
            const requirementsWithPolicies = result?.standard.disclosureRequirementGroups.filter(
              (g) => g.requirements.filter((r) => r.type === 'policy').length
            );
            upsertPolicy({
              variables: {
                input:
                  requirementsWithPolicies?.map((group) => {
                    return {
                      assessmentId: result?.id,
                      disclosureRequirementRef: group.requirements[0].reference,
                      noteHistory: {
                        data: {},
                        on_conflict: {
                          constraint: NoteHistory_Constraint_.NoteHistoryPolicyIdKey_,
                          update_columns: [],
                        },
                      },
                      attachmentBox: {
                        data: {},
                        on_conflict: {
                          constraint: AttachmentBox_Constraint_.AttachmentBoxPoliciesIdKey_,
                          update_columns: [],
                        },
                      },
                    };
                  }) ?? [],
              },
            });
          }
        })
        .catch(() => {
          toast({ text: 'Failed to add materiality assessment', variant: 'danger' });
        });
    },
    [addAssessment, upsertPolicy, upsertMateriality]
  );
};

export const useAddMandatoryMaterialityAssessment = () => {
  const { esrsAssessmentId } = useParams();
  const [addAssessment] = useUpsertMultipleMaterialStandardsMutation();

  return useCallback(
    (
      mandatoryStandards: {
        standardRef: string;
        disclosureRequirementsRefs: string[];
        metrics: string[];
      }[]
    ) => {
      addAssessment({
        variables: {
          materialStandards: mandatoryStandards.map((standard) => ({
            assessmentId: esrsAssessmentId,
            standardRef: standard.standardRef,
            isMaterial: true,
            materialMetrics: {
              data: standard.metrics?.map((metricRef) => ({
                isMaterial: true,
                metricRef,
              })),
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.Metadata_,
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                ],
              },
            },
            materialDisclosureRequirements: {
              data: standard.disclosureRequirementsRefs.map((dr) => ({
                isMaterial: true,
                disclosureRequirementRef: dr,
                standardRef: standard.standardRef,
              })),
              on_conflict: {
                constraint:
                  Esrs_MaterialDisclosureRequirement_Constraint_.MaterialDisclosureRequirementCompanyMaterialityAssessmentIKey_,
                update_columns: [Esrs_MaterialDisclosureRequirement_Update_Column_.IsMaterial_],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          })),
        },
        refetchQueries: [
          EsrsAssessmentDocument_,
          GetMaterialStandardDocument_,
          MaterialStandardMetricRequirementsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          GetMetricsDrDocument_,
          GetDisclosureRequirementMetricsDocument_,
          CompanyLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
        ],
      });
    },
    [addAssessment]
  );
};

const useGetDRState = () => {
  return useCallback(
    (
      requirement: Requirement,
      materialDisclosureRequirements: MaterialDisclosureRequirement[] | undefined,
      isStandardMaterial: boolean
    ): MaterialityState => {
      if (isStandardMaterial) {
        return MaterialityState.mandatory;
      }
      if (requirement.isMandatory) {
        return MaterialityState.materialMandatory;
      }
      const isDRAssessed = materialDisclosureRequirements?.some(
        (req) => req.disclosureRequirementRef === requirement.reference && req.isMaterial !== null
      );
      if (!isDRAssessed) return MaterialityState.toAssess;
      const isDataGathering = materialDisclosureRequirements?.find(
        (req) => req.disclosureRequirementRef === requirement.reference
      )?.isDataGatheringOnly;
      if (isDataGathering) return MaterialityState.gatherData;
      const isMaterial = materialDisclosureRequirements?.some(
        (req) => req.disclosureRequirementRef === requirement.reference && req.isMaterial === true
      );
      return isMaterial ? MaterialityState.material : MaterialityState.notMaterial;
    },
    []
  );
};

const useGetParentDRstate = () => {
  return useCallback(
    (
      requirement: Requirement,
      parentMaterialDrs: ParentMaterialDrs[],
      isStandardMaterial: boolean
    ): MaterialityState => {
      if (!isStandardMaterial) {
        return MaterialityState.notMaterial;
      }
      if (requirement.isMandatory) {
        return MaterialityState.mandatory;
      }
      const isDRAssessed = parentMaterialDrs?.some(
        (dr) => dr.disclosureRequirementRef === requirement.reference
      );
      const isDataGathering = parentMaterialDrs?.find(
        (req) => req.disclosureRequirementRef === requirement.reference
      )?.isDataGatheringOnly;
      if (isDataGathering) return MaterialityState.gatherData;
      if (isDRAssessed) {
        const isMaterial = parentMaterialDrs.some(
          (dr) => dr.disclosureRequirementRef === requirement.reference && dr.isMaterial
        );
        return isMaterial ? MaterialityState.material : MaterialityState.notMaterial;
      }
      return MaterialityState.toAssess;
    },
    []
  );
};

const getMetricTags = (metric?: MaterialMetric) => {
  const tags = !!metric?.materialMetricTags?.length
    ? metric?.materialMetricTags?.map((tag) => ({ type: tag.tagType }))
    : metric?.metric?.tags;

  return tags;
};

export const useMapDisclosureRequirements = () => {
  const getDrState = useGetDRState();
  const getParentDRState = useGetParentDRstate();
  return useCallback(
    (
      materialMetrics: MaterialMetric[],
      isGroupOwner: boolean,
      parentMaterialDrs: ParentMaterialDrs[],
      isStandardMaterial: boolean,
      requirementGroups?: DisclosureRequirementGroup[],
      materialDisclosureRequirements?: MaterialDisclosureRequirement[],
      isParentStandardMaterial?: boolean | null
    ) => {
      const allRequirements = Object.values(requirementGroups ?? {}).flatMap(
        (group) => group.requirements
      );

      const requirements = allRequirements.slice().sort((a, b) => {
        return a.order - b.order || a.reference.localeCompare(b.reference);
      });

      const mappedRequirements = requirements.map((req) => ({
        title: req.title,
        drRef: req.reference,
        materialityStatus: getDrState(req, materialDisclosureRequirements, isStandardMaterial),
        order: req.order,
        parentMateriality: !isGroupOwner
          ? getParentDRState(req, parentMaterialDrs, isParentStandardMaterial ?? false)
          : MaterialityState.toAssess,
        metrics: req.metrics.map((metric) => ({
          ...metric,
          tags:
            getMetricTags((materialMetrics ?? []).find((m) => m.metricRef === metric.reference)) ??
            metric.tags,
        })),
        description: req.description,
        additionalTypes: req.additionalTypes.map((at) => at.additionalType),
      }));
      return mappedRequirements;
    },
    []
  );
};

export const addMandatoryMetrics = (
  standard: GetEsrsStandardQuery_['esrsStandard'],
  materialMetrics: MaterialMetric[],
  isMaterial: boolean
) => {
  const mandatoryMaterialMetrics =
    standard?.disclosureRequirementGroups
      .flatMap((disclosure) =>
        disclosure.requirements
          .filter((requirement) => requirement.isMandatory)
          ?.flatMap((requirement) =>
            requirement.metrics?.map((m) => ({
              metricRef: m.reference,
              isMaterial: isMaterial,
              isDataGatheringOnly: false,
            }))
          )
      )
      ?.filter((m) => m !== undefined) ?? [];

  const filteredMaterialMetrics = materialMetrics.filter(
    (m) => !mandatoryMaterialMetrics.some((mm) => mm.metricRef === m.metricRef)
  );

  const allMaterialMetrics = [...filteredMaterialMetrics, ...mandatoryMaterialMetrics];
  return allMaterialMetrics;
};

export const getUnassessedMandatoryStandardData = (materialityAssessments: MaterialMap[]) => {
  const mandatoryStandards =
    materialityAssessments?.flatMap((assessment) =>
      assessment.materialStandards?.filter((standard) => !standard.isTopical)
    ) ?? [];
  const standardDisclosures = mandatoryStandards?.map((standard) => {
    const disclosureRequirements = standard?.disclosureRequirementGroups?.flatMap(
      (drg) => drg?.requirements
    );
    const currentMaterialDisclosures = materialityAssessments
      .flatMap((assessment) => assessment.materialStandards)
      .find((s) => s.standardRef === standard.standardRef)?.materialDisclosureRequirements;
    const unAssessedDisclosureRequirementsRefs =
      disclosureRequirements
        ?.map((req) => req.reference)
        .filter(
          (reference) =>
            !currentMaterialDisclosures?.find((md) => md.disclosureRequirementRef === reference)
        ) ?? [];

    const currentMaterialMetrics = materialityAssessments
      .flatMap((assessment) => assessment.materialStandards)
      .find((s) => s.standardRef === standard.standardRef)?.metrics;
    const unassessedMetrics =
      disclosureRequirements
        ?.flatMap((req) => req.metrics?.map((metric) => metric.reference))
        ?.filter(
          (reference) => !currentMaterialMetrics?.find((mm) => mm.metricRef === reference)
        ) ?? [];

    return {
      standardRef: standard.standardRef,
      disclosureRequirementsRefs: unAssessedDisclosureRequirementsRefs,
      metrics: unassessedMetrics,
    };
  });
  return standardDisclosures;
};
