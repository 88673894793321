import { VStack, TableContainer, Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyState, Tag } from 'Atoms';
import { ContentHeader, ContentLayout, Table } from 'Molecules';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { MaterialStandard } from './MaterialityAssessment.d';
import { getMaterialityAssessment } from './MaterialityAssessment.hooks';
import { MaterialityAssessmentHeader } from './MaterialitySettingsHeader';

export const DataCollectionSetup = ({
  isOnboarding,
  onChangeTab,
  setStep,
}: {
  isOnboarding: boolean;
  onChangeTab?: ((tabId: string) => void) | null;
  setStep?: (step: number) => void;
}) => {
  const { esrsAssessmentId } = useParams();
  const { materialityAssessments, loading } = getMaterialityAssessment(esrsAssessmentId);

  const navigate = useNavigate();

  const materialStandards = useMemo(() => {
    return materialityAssessments.flatMap((ma) =>
      ma.materialStandards.filter(
        (ms) =>
          ms.isMaterial &&
          (ms.metrics.some((materialMetric) => materialMetric.isMaterial) ||
            ms.parentCompanyMetrics.some((materialMetric) => materialMetric.isMaterial))
      )
    );
  }, [materialityAssessments]);

  const columns: ColumnDef<MaterialStandard>[] = [
    {
      header: 'Standard',
      accessorKey: 'standardName',
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <VStack spacing={0} alignItems="start">
            <Typography variant="bodyStrong">{row.original.standardName}</Typography>
            <Typography variant="micro">{row.original.standardRef}</Typography>
          </VStack>
        );
      },
      meta: {
        width: '80%',
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      enableSorting: false,
      cell: ({ row }) => {
        const isDataCollected = row.original.isDataCollected;
        return (
          <Tag
            title={isDataCollected ? 'Done' : 'To setup'}
            variant={isDataCollected ? 'success' : 'warning'}
            borderRadius="6px"
          />
        );
      },
      meta: {
        width: '20%',
      },
    },
  ];

  return (
    <ContentLayout
      header={
        isOnboarding && (
          <ContentHeader
            title="Data collection setup"
            subtitle="Select which metrics you want to track and how"
            backButton={false}
            props={{ paddingTop: '0', paddingInline: '0', height: 'unset' }}
          />
        )
      }
      height={isOnboarding ? 'fit-content' : undefined}
      padChildren={false}
      isLoading={loading}
    >
      {!!materialStandards.length ? (
        <VStack mt={isOnboarding ? '32px' : '28px'} w="100%" spacing="24px">
          {!isOnboarding && (
            <MaterialityAssessmentHeader title="Manage data collection setup" noDownload={true} />
          )}
          <TableContainer
            border="1px solid"
            borderRadius="8px"
            borderColor="border.decorative"
            w="100%"
          >
            <Typography pt="12px" pl="1.5rem" variant="h3">
              Your material topics
            </Typography>
            <Table
              columns={columns}
              data={materialStandards}
              onRowClick={(row) => {
                navigate(`data-collection/${row.standardRef}`);
              }}
              rowProps={{
                _hover: {
                  cursor: 'pointer',
                  bg: 'bg.hover',
                },
              }}
              rowIcon={<ArrowNarrowRightIcon />}
              bottomRowBorder={false}
            ></Table>
          </TableContainer>
        </VStack>
      ) : (
        <Box my="16px" w="100%" h={`calc(100vh - 170px)`}>
          <EmptyState
            title="Set up your data collection"
            description="There are no material metrics that could be set up available. Assess some metrics as material, and you will be able to configure here how they should be gathered "
            callToAction={{
              text: 'Go to materiality assessment',
              variant: 'secondary',
              onClick: () => (isOnboarding ? setStep?.(3) : onChangeTab?.('materiality')),
            }}
            component={true}
          />
        </Box>
      )}
    </ContentLayout>
  );
};
