import { Box, ChakraProps, VStack } from '@chakra-ui/react';
import { Tooltip } from 'Atoms';
import { DescriptionIcon, HashIcon, SumIcon } from 'Tokens/Icons/Data';

export const MetricTypeIcon = ({ type }: { type: string }) => {
  return (
    <Tooltip label={type === 'number' ? 'Number' : type === 'text' ? 'Text' : 'Aggregated value'}>
      <VStack alignItems="center" justifyContent="center" h="20px">
        {type === 'number' ? <HashIcon /> : type === 'text' ? <DescriptionIcon /> : <SumIcon />}
      </VStack>
    </Tooltip>
  );
};
