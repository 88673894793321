import { useUserData } from '@nhost/react';
import { useUserCompaniesQuery, useCompanyDetailsQuery } from 'models';
import { useCallback, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

export enum CompanyType {
  portfolio = 'portfolio-company',
  regular = 'regular-company',
  group = 'group-company',
}

export const useUserCompanyCheck = () => {
  const user = useUserData();
  const { data } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });
  const hasCompany = useCallback(
    (companyId?: string) =>
      companyId
        ? data?.data?.companies.map(({ company }) => company.id).includes(companyId)
        : false,
    [data]
  );
  return hasCompany;
};

export const useCurrentCompanyId = (): { companyId: string | null; loading: boolean } => {
  const user = useUserData();
  const { companyId: urlCompanyId } = useParams();
  const checkAccess = useUserCompanyCheck();

  const [localStorageCompanyId, setLocalStorageCompanyId] = useLocalStorage<string | null>(
    'currentCompanyId',
    null
  );
  const { data, loading } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });

  const currentCompanyId = useMemo(() => {
    if (urlCompanyId && checkAccess(urlCompanyId)) return urlCompanyId;
    if (localStorageCompanyId && checkAccess(localStorageCompanyId)) return localStorageCompanyId;
    const firstOnList = data?.data?.companies[0]?.company.id;
    return firstOnList;
  }, [data, urlCompanyId, localStorageCompanyId]);

  useEffect(() => {
    if (currentCompanyId && currentCompanyId !== localStorageCompanyId) {
      setLocalStorageCompanyId(currentCompanyId);
    }
  }, [currentCompanyId]);

  return { companyId: currentCompanyId, loading };
};

export const useCurrentCompany = () => {
  const { companyId: currentCompanyId } = useCurrentCompanyId();
  const { data, loading } = useCompanyDetailsQuery({
    variables: {
      id: currentCompanyId,
    },
    skip: !currentCompanyId,
  });
  return { company: data?.company[0], loading };
};

export const useCompanyType = (): {
  companyType: CompanyType;
  loading: boolean;
  isInGroups: boolean;
} => {
  const { company, loading } = useCurrentCompany();
  return {
    companyType: company?.isPortfolioOwner
      ? CompanyType.portfolio
      : company?.isGroupOwner
      ? CompanyType.group
      : CompanyType.regular,
    loading,
    isInGroups: !!company?.subsidiaries?.length ?? false,
  };
};
