import { VStack } from '@chakra-ui/react';
import { FormField, TextareaWithCharCounter } from 'Atoms';
import { LabelText } from 'Tokens';
import { DateInput } from 'Atoms/DateInput';
import { uniq } from 'lodash';
import { DocumentationFile } from 'models';
import { Modal, ModalProps, TagSelector } from 'Molecules';
import { useEffect, useMemo } from 'react';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useTranslation } from 'utils/translation';
import { UpsertFileProps } from '../Drive.hooks';
import { FileNameController } from './FileNameController';

export type FileDescription = {
  url?: string;
  name?: string;
  extension?: string;
  uploadedById?: DocumentationFile['uploadedBy']['id'];
};

export type FileDescriptionWithUpload = FileDescription & {
  toUpload?: File;
};
const MAX_DESCRIPTION_LEN = 1000;

type FileFormField = {
  title: string;
  file: FileDescription;
  source: string;
  creationDate?: Date | null;
  description: string;
  tags: string[];
};

export function FileEditModal({
  file: editedFile,
  isOpen,
  onClose,
  saveFile,
  aboutDocumentation,
  allTags,
}: {
  file?: DocumentationFile;
  aboutDocumentation?: string | null;
  saveFile: (file: UpsertFileProps) => void;
  allTags: string[];
} & Pick<ModalProps, 'isOpen' | 'onClose'>) {
  const { t } = useTranslation('files');
  const isEditing = editedFile?.id != null;
  const {
    reset,
    formState: { isDirty, isSubmitting, isValidating },
    handleSubmit,
    control,
  } = useForm<FileFormField>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      title: editedFile?.title ?? '',
      source: editedFile?.source ?? '',
      creationDate: editedFile?.creationDate ? new Date(editedFile?.creationDate) : null,
      description: editedFile?.description ?? '',
      tags: editedFile?.tags ?? [],
    },
  });
  const { errors, isValid } = useFormState({ control });
  useEffect(() => {
    reset({
      title: editedFile?.title ?? '',
      source: editedFile?.source ?? '',
      creationDate: editedFile?.creationDate ? new Date(editedFile?.creationDate) : null,
      description: editedFile?.description ?? '',
      tags: editedFile?.tags ?? [],
    });
  }, [editedFile, isOpen, onClose]);

  const tagOptions = useMemo(
    () => uniq(allTags).map((tag) => ({ value: tag, label: tag })),
    [allTags]
  );
  const handleUploadDocument = (data: FileFormField) => {
    return saveFile({
      title: data.title,
      source: data.source,
      creationDate: data?.creationDate ?? new Date(),
      description: data.description,
      tags: data.tags,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => (!isSubmitting ? onClose() : undefined)}
      title={t('files:modalHeader')}
      size="md"
      confirmText={isEditing ? t('files:updateFile') : t('files:uploadFile')}
      confirmButtonProps={{
        isDisabled: !isDirty || !isValid || Object.keys(errors).length > 0,
        type: 'submit',
        form: 'file-form',
        isLoading: isValidating || isSubmitting,
      }}
    >
      <LabelText>{aboutDocumentation}</LabelText>
      <form onSubmit={handleSubmit(handleUploadDocument)} id="file-form">
        <VStack spacing="12px" alignItems="stretch">
          <FileNameController control={control} />

          <Controller
            control={control}
            name="creationDate"
            render={({ field: { onChange, value } }) => (
              <FormField
                id="creationDate"
                label={t('files:creationDate.label')}
                helpText={t('files:creationDate.helpText')}
                isInvalid={errors?.creationDate != null}
                error={errors?.creationDate?.message}
              >
                <DateInput
                  maxDate={new Date()}
                  value={value || new Date()}
                  setValue={(newDate) => onChange(newDate)}
                  attachToId="file-form"
                />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name="tags"
            render={({ field: { onChange, value } }) => (
              <FormField
                id="source"
                label={t('files:source.label')}
                helpText={t('files:source.helpText')}
                isInvalid={errors?.source != null}
                error={errors?.source?.message}
              >
                <TagSelector
                  values={value ?? []}
                  options={tagOptions.map((v) => v.label)}
                  createOptionPosition="first"
                  placeholder={t('files:selectTags')}
                  getNewOptionData={(input: any, label: any) => ({
                    label: label as string,
                    value: input as string,
                    isNew: true,
                  })}
                  onChange={(tags: any) => onChange(tags ?? [])}
                  formatCreateLabel={(input: any) => t('files:createNewTag', { name: input })}
                  size="md"
                />
              </FormField>
            )}
          />

          <Controller
            control={control}
            name="description"
            rules={{
              maxLength: MAX_DESCRIPTION_LEN,
            }}
            render={({ field: { onChange, value } }) => (
              <FormField
                id="description"
                label={t('files:description.label')}
                isInvalid={errors?.description != null}
                error={errors?.description?.message}
              >
                <TextareaWithCharCounter
                  maxLength={MAX_DESCRIPTION_LEN}
                  value={value}
                  onChange={(val) => onChange(val)}
                />
              </FormField>
            )}
          />
        </VStack>
      </form>
    </Modal>
  );
}
