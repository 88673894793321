import React, { useEffect, useRef, useState } from 'react';
import { TimePeriods } from '../../Requirement';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { User } from 'models';
import { Box, Table as ChakraTable, HStack, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { LongTextMetricInput } from '../LongTextMetricInput';
import { MetricOwnerSelect } from './MetricOwnerSelect';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { isEqual } from 'lodash';
import { IconButton } from 'Atoms';

export const NarrativeMetricsTable = ({
  metrics,
  selectedQuarter,
  companyReportingUnit,
  esrsAssessmentProjectLeader,
  isGeneratingAnswers = false,
  rowData,
  setRowData,
}: {
  metrics: MetricsTableData['metric'][];
  selectedQuarter: TimePeriods;
  companyReportingUnit?: string;
  esrsAssessmentProjectLeader?: Partial<User>;
  isGeneratingAnswers?: boolean;
  rowData?: MetricsTableData;
  setRowData: (param: MetricsTableData | undefined) => void;
}) => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const [selectedMetricBox, setSelectedMetricBox] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const theadStyle = {
    letterSpacing: 'normal',
    borderColor: 'border.decorative',
    px: '8px',
    height: '48px',
  };
  const bodyRef = useRef(null);

  useEffect(() => {
    const handleDeselect = (event: any) => {
      if (bodyRef.current && !bodyRef.current.contains(event.target)) {
        setSelectedRow('');
        setSelectedMetricBox('');
      }
    };
    document.addEventListener('click', handleDeselect);
    return () => {
      document.addEventListener('click', handleDeselect);
    };
  }, []);

  const mapMetrics = (
    metric:
      | MetricsTableData['metric']
      | MetricsTableData['metric']['childrenMetrics'][number]['childMetric'],
    padding: number,
    metricId: string,
    borderTop: boolean
  ): React.ReactNode => {
    return metric ? (
      <>
        <Tr
          borderTop={borderTop ? '1px solid' : 'none'}
          borderColor="border.decorative"
          onClick={() => {
            setSelectedMetricBox(metricId);
            setSelectedRow(metric.reference);
            if (openDrawer && !!hash) navigate(pathname.split('?')[0]);
            if (isEqual(rowData, { metric: metric })) {
              setRowData(undefined);
            } else setRowData({ metric: metric });
          }}
          cursor="pointer"
          _hover={{
            bg: 'bg.hover',
          }}
          backgroundColor={
            selectedRow === metric.reference
              ? 'bg.base'
              : selectedMetricBox === metricId
              ? 'bg.selected.muted'
              : 'none'
          }
          transition="0.15s"
        >
          <Td
            p="0px"
            border="none"
            colSpan={metric?.childrenMetrics.length ? 2 : 1}
            pl={`${padding}px`}
            verticalAlign="top"
          >
            <HStack
              p="14px 8px"
              spacing="8px"
              alignItems="start"
              height="100%"
              id={metric?.reference}
            >
              <MetricTypeIcon type="text" />
              <Typography id={metric?.reference} variant="body">
                {metric?.shortTitle ?? metric?.title}
              </Typography>
            </HStack>
          </Td>
          {!metric.childrenMetrics.length && (
            <Td p="0px" border="none" verticalAlign="top" px="8px" py="6px">
              <LongTextMetricInput
                metric={metric}
                companyReportingUnit={companyReportingUnit}
                isGeneratingAnswers={isGeneratingAnswers}
                setRowData={setRowData}
              />
            </Td>
          )}
          <Td p="0px" border="none" verticalAlign="top">
            <Box my="10px">
              <MetricOwnerSelect
                row={{ metric: metric, tags: [] }}
                selectedQuarter={selectedQuarter}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeader={esrsAssessmentProjectLeader}
              />
            </Box>
          </Td>
          <Td p="0px 8px" border="none" textAlign="right">
            <IconButton aria-label="side-bar" variant="ghost" icon={<ArrowNarrowRightIcon />} />
          </Td>
        </Tr>
        {metric?.childrenMetrics.length > 0 &&
          metric.childrenMetrics.map((child) =>
            mapMetrics(child.childMetric, padding + 24, metricId, false)
          )}
      </>
    ) : (
      <></>
    );
  };
  return (
    <ChakraTable>
      <Thead w="100%" borderY="1px solid" borderColor="border.decorative">
        <Tr w="100%">
          <Th {...theadStyle} textTransform="none" w="35%">
            <Typography variant="bodyStrong">Narrative data point</Typography>
          </Th>
          <Th {...theadStyle} textTransform="none" w="35%">
            <Typography variant="bodyStrong">Answer</Typography>
          </Th>
          <Th {...theadStyle} textTransform="none" w="8%">
            <Typography variant="bodyStrong">Owner</Typography>
          </Th>
          <Th {...theadStyle} w="12%"></Th>
        </Tr>
      </Thead>
      <Tbody ref={bodyRef}>
        {metrics.map((metric) => (
          <React.Fragment key={metric.reference}>
            {mapMetrics(metric, 0, metric.reference, true)}
          </React.Fragment>
        ))}
      </Tbody>
    </ChakraTable>
  );
};
