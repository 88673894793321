import { ContentHeader, ContentLayout } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { FileList } from './pieces';
import { useCompanyFiles } from './Drive.hooks';
import { DocumentationFile } from 'models';
import { useState } from 'react';

export function Drive() {
  const { files, loading } = useCompanyFiles();
  const { t } = useTranslation('files');
  const [selectedFiles, setSelectedFiles] = useState([] as DocumentationFile[]);

  return (
    <ContentLayout header={<ContentHeader title={t('files:myDrive')} />} isLoading={loading}>
      <FileList
        files={files}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        drive={true}
      />
    </ContentLayout>
  );
}
