import { HStack, VStack } from '@chakra-ui/react';
import { User } from 'models';
import { useMemo } from 'react';
import { Typography } from 'Tokens';
import { LastEditedBy } from '../InputTable/LastEditedBy';
import { MetricOwnerSelect } from '../InputTable/MetricOwnerSelect';
import { MetricsTableData } from '../MetricAnswers.hooks';

export const MetricOverview = ({
  row,
  metric,
  selectedQuarter,
  companyReportingUnit,
  esrsAssessmentProjectLeader,
  materialStandardId,
}: {
  row?: MetricsTableData;
  metric: MetricsTableData['metric'];
  selectedQuarter: string;
  companyReportingUnit?: string;
  esrsAssessmentProjectLeader?: Partial<User>;
  materialStandardId: string;
}) => {
  const frequency = useMemo(
    () =>
      metric.materialMetrics.find((mm) => mm.materialStandardId === materialStandardId)?.frequency,
    [metric, materialStandardId]
  );

  return (
    <VStack alignItems="start" width="100%" p="24px 20px" spacing="12px">
      <HStack w="100%" py="4px">
        <Typography variant="body" width="120px">
          Owner
        </Typography>
        <MetricOwnerSelect
          row={row ?? { metric: metric, tags: [] }}
          selectedQuarter={selectedQuarter}
          companyReportingUnit={companyReportingUnit}
          assessmentProjectLeader={esrsAssessmentProjectLeader}
          isBorderless={true}
          size="xs"
        />
      </HStack>
      <HStack w="100%" py="4px">
        <Typography variant="body" width="120px">
          Last edited
        </Typography>
        <LastEditedBy
          row={row ?? { metric: metric, tags: [] }}
          companyStandardId={materialStandardId}
          selectedQuarter={selectedQuarter}
          reportingUnitId={companyReportingUnit ? companyReportingUnit : undefined}
        />
      </HStack>
      <HStack w="100%" py="4px">
        <Typography variant="body" width="120px">
          Collected
        </Typography>
        <Typography variant="body" width="120px">
          {frequency}
        </Typography>
      </HStack>
      {row && (
        <HStack w="100%" py="4px">
          <Typography variant="body" width="120px">
            Unit
          </Typography>
          <Typography variant="body" width="120px">
            {metric.unitOfMeasurement}
          </Typography>
        </HStack>
      )}
      <HStack w="100%" py="4px">
        <Typography variant="body" width="120px">
          ESRS name
        </Typography>
        <Typography variant="body" width="120px">
          {metric.title}
        </Typography>
      </HStack>
      {row?.parentMetric && (
        <HStack w="100%" py="4px">
          <Typography variant="body" width="120px">
            Parent metric
          </Typography>
          <Typography variant="body" width="120px">
            {row?.parentMetric?.title}
          </Typography>
        </HStack>
      )}
    </VStack>
  );
};
