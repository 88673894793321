import {
  COMPANY,
  FrequencyEnums,
  TimePeriodsEnums,
  TIME_FRAMES_ARRAY,
  TOTAL,
  YearObject,
} from './../../Requirement';
import {
  getAggregatedDatapoints,
  getMetricAnswers,
} from './../AggregatedMetrics/AggregatedMetrics.hooks';
import {
  GetMetricWithChildrenQuery_,
  useGetAnswersForMetricsOnCompanyLevelQuery,
  useGetAnswersForMetricsOnGroupLevelQuery,
  useGetMetricWithChildrenQuery,
} from 'models';
import { useMemo } from 'react';
import { getFullMetricWithChildren } from '../AggregatedMetrics';
import { getTagsCombination } from '../MetricAnswers.hooks';
import { useCompanyType } from 'utils/hooks';

export type ChartTableType = {
  name: string;
  unit: string;
  value: number;
};

export type FiltersType = {
  ruFilter?: string[];
  subFilter?: string;
  tagBreakdown?: string;
};

type AllTagsArrayType = {
  allTagsArray: {
    tagType: string;
    tagValue: string;
  }[][];
};

export const useMetricBreakdown = (
  esrsAssessmentId: string,
  materialStandardId: string,
  metricRef: string,
  standardRef: string,
  filters?: FiltersType
) => {
  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const { data: metricData, loading } = useGetMetricWithChildrenQuery({
    variables: {
      metricRef,
      materialStandardId,
    },
  });

  const metric = useMemo(
    () => metricData?.EsrsMetric[0] ?? ({} as GetMetricWithChildrenQuery_['EsrsMetric'][number]),
    [metricData]
  );

  const materialMetric = useMemo(
    () => metric.materialMetrics?.find((mm) => mm.materialStandardId === materialStandardId),
    [metric]
  );

  const { data: ruData, loading: ruLoading } = useGetAnswersForMetricsOnCompanyLevelQuery({
    variables: { esrsAssessmentId },
    skip: isGroup,
  });

  const reportingUnitsAnswers = useMemo(() => ruData?.EsrsAssessment_by_pk, [ruData]);

  const { data: groupRuData, loading: loadingGroup } = useGetAnswersForMetricsOnGroupLevelQuery({
    variables: { esrsAssessmentId },
    skip: !isGroup,
  });

  const groupReportingUnitsAnswers = useMemo(
    () => groupRuData?.EsrsAssessment_by_pk,
    [groupRuData]
  );

  const tags = useMemo(() => {
    if (!!materialMetric) {
      const tagOptions = materialMetric?.materialMetricTags ?? [];
      const { allTagsArray } = tagOptions?.length
        ? getTagsCombination(tagOptions)
        : ({} as AllTagsArrayType);
      return allTagsArray;
    }
    return [];
  }, [materialMetric]);

  const tagOptions = useMemo(
    () => materialMetric?.materialMetricTags?.flatMap((mt) => mt?.tagType) ?? [],
    [materialMetric]
  );

  const tagValues = useMemo(
    () =>
      materialMetric?.materialMetricTags
        ?.find((tag) => tag.tagType === filters?.tagBreakdown)
        ?.valueOptions?.map((tag) => tag.tagValue) ?? [],
    [materialMetric, filters]
  );

  const businessUnitOptions = useMemo(
    () =>
      reportingUnitsAnswers?.reportingUnits
        .filter((ru) =>
          materialMetric?.dataCollection === COMPANY ? ru.isCompanyLevel : !ru.isCompanyLevel
        )
        .map((ru) => ({
          id: ru.id,
          name: ru.name,
        })),
    [reportingUnitsAnswers, materialMetric]
  );

  const subsidiaryOptions = useMemo(
    () =>
      groupReportingUnitsAnswers?.subsidiaryAssessments.map((assessment) => ({
        id: assessment.id,
        name: assessment.company.name,
      })),
    [groupReportingUnitsAnswers]
  );

  const filteredSubsidiaries = useMemo(
    () =>
      groupReportingUnitsAnswers?.subsidiaryAssessments.filter((su) =>
        filters?.subFilter?.length ? filters?.subFilter?.includes(su.id) : true
      ) ?? [],
    [groupReportingUnitsAnswers, filters]
  );

  const filteredBusinessUnits = useMemo(
    () =>
      reportingUnitsAnswers?.reportingUnits.filter((ru) =>
        filters?.ruFilter?.length ? filters?.ruFilter?.includes(ru.id) : true
      ) ?? [],
    [reportingUnitsAnswers, filters]
  );

  const requiredTimeFrames = useMemo(
    () =>
      materialMetric?.frequency === FrequencyEnums.yearly
        ? [TimePeriodsEnums.year]
        : TIME_FRAMES_ARRAY,
    [materialMetric]
  );

  const metricWithChildren = useMemo(() => getFullMetricWithChildren(metric), [metric]);

  const allCompanyMaterialMetrics = useMemo(
    () => metricWithChildren.map((m) => m.materialMetrics?.[0]),
    [metricWithChildren]
  );

  const answers = useMemo(() => {
    if (isGroup) {
      return (
        filteredSubsidiaries?.flatMap((subsidiary) => {
          const allMaterialMetrics =
            subsidiary.materialStandards
              .find((standard) => standard.standard.reference === standardRef)
              ?.materialMetrics.filter((m) =>
                metricWithChildren.map((child) => child.reference).includes(m.metric.reference)
              ) ?? [];
          return subsidiary.reportingUnits?.flatMap((ru) =>
            getMetricAnswers(
              ru,
              true,
              metricWithChildren,
              materialMetric,
              allMaterialMetrics,
              subsidiary,
              standardRef
            )
          );
        }) ?? []
      );
    }
    return (
      filteredBusinessUnits.flatMap((ru) =>
        getMetricAnswers(ru, false, metricWithChildren, materialMetric, allCompanyMaterialMetrics)
      ) ?? []
    );
  }, [
    filteredSubsidiaries,
    filteredBusinessUnits,
    allCompanyMaterialMetrics,
    metricWithChildren,
    materialMetric,
    standardRef,
  ]);

  const metricResult = useMemo(() => {
    return getAggregatedDatapoints({
      metric,
      answers,
      tags,
      filterAnswers: false,
    });
  }, [filters, answers, tags]);

  const tagValuesResults = useMemo(() => {
    const result: { [tag: string]: YearObject } = {};
    tagValues.forEach((tagValue) => {
      const filteredTags = tags?.filter((tag) => tag.some((value) => value.tagValue === tagValue));
      result[tagValue] = getAggregatedDatapoints({
        metric,
        answers,
        tags: filteredTags,
        filterAnswers: false,
      });
    });
    return result;
  }, [metric, answers, tags, tagValues]);

  const [result, tagFilterResult] = useMemo(() => {
    if (filters?.tagBreakdown) {
      const tagFilterRes = tagValues.map((tagValue) => ({
        name: tagValue,
        value: tagValuesResults[tagValue].Year,
        unit: metric.unitOfMeasurement ?? 'N/A',
      }));
      return [undefined, tagFilterRes];
    }
    const res = [
      {
        name: metric.shortTitle ?? metric.title,
        value: metricResult.Year,
        unit: metric.unitOfMeasurement ?? 'N/A',
      },
    ];
    return [res, undefined];
  }, [metricResult, filters, tagValues, tagValuesResults, metric]);

  const chartData = useMemo(() => {
    if (filters?.tagBreakdown) {
      return requiredTimeFrames.map((timeframe) => {
        const bar: { [a: string]: number | string } = {
          name: timeframe === TimePeriodsEnums.year ? TOTAL : timeframe,
          unit: metric.unitOfMeasurement ?? 'N/A',
        };
        tagValues.forEach((tagValue) => {
          bar[tagValue] = tagValuesResults[tagValue][timeframe];
        });
        return bar;
      });
    }
    return requiredTimeFrames.map((timeframe) => ({
      name: timeframe === TimePeriodsEnums.year ? TOTAL : timeframe,
      value: metricResult[timeframe],
      unit: metric.unitOfMeasurement ?? 'N/A',
    }));
  }, [metricResult, tagValues, metric, requiredTimeFrames, filters, tagValuesResults]);

  return {
    result,
    tagFilterResult,
    chartData,
    metric,
    tagValues,
    tagOptions,
    businessUnitOptions,
    subsidiaryOptions,
    isGroup,
    isYearly: materialMetric?.frequency === FrequencyEnums.yearly,
    loading: ruLoading || loadingGroup || loading,
  };
};
