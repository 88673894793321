export function stringToColor(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hexColor = (hash & 0x00ffffff).toString(16).toUpperCase();

  const paddedHexColor = '00000'.substring(0, 6 - hexColor.length) + hexColor;

  return '#' + paddedHexColor;
}
