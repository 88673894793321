import { useUserData } from '@nhost/react';
import { useCallback } from 'react';
import { validate } from 'uuid';
import {
  useUpsertThreadMutation,
  useRemoveCommentMutation,
  useThreadSubscription,
  Comment,
  GetEsrsMetricAnswerDocument_,
  User,
} from 'models';
import { useCurrentCompanyId } from 'utils/hooks';
import { TimePeriods } from '../../Requirement';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { useGetDatapointValues } from '../InputTable/QuarterInput';

export function useCommentThread({
  id,
  esrsDatapointId,
  timeframe,
  assessmentProjectLeaderId,
  row,
  companyReportingUnit,
}: {
  id: string;
  esrsDatapointId: string;
  timeframe: TimePeriods;
  assessmentProjectLeaderId?: Partial<User>;
  row: MetricsTableData;
  companyReportingUnit?: string;
}) {
  const user = useUserData();
  const { companyId } = useCurrentCompanyId();
  const [upsertThread] = useUpsertThreadMutation();
  const [deleteCommentMutation] = useRemoveCommentMutation();
  const { data: threadResult, loading } = useThreadSubscription({
    skip: !validate(id),
    variables: { id },
  });
  const { answer, onDatapointChange } = useGetDatapointValues(row, companyReportingUnit);
  const thread = threadResult?.thread;
  const addComment = useCallback(
    (comment: string) => {
      if (!esrsDatapointId) {
        return onDatapointChange(
          null,
          answer?.hasOptedOut ?? false,
          answer?.optOutReason ?? '',
          { timeframe: timeframe },
          row?.tags,
          assessmentProjectLeaderId?.id
        ).then((res) => {
          const dpId: string = res.data?.insert_esrs_Datapoint?.returning[0].id;
          if (dpId)
            upsertThread({
              variables: {
                thread: {
                  id,
                  companyId,
                  esrsDatapointId: dpId,
                  comments: {
                    data: [
                      {
                        data: comment,
                        authorId: user?.id,
                      },
                    ],
                  },
                  subscribers: undefined,
                },
              },
              refetchQueries: [GetEsrsMetricAnswerDocument_],
            });
        });
      }
      return upsertThread({
        variables: {
          thread: {
            id,
            companyId,
            esrsDatapointId,
            comments: {
              data: [
                {
                  data: comment,
                  authorId: user?.id,
                },
              ],
            },
            subscribers: undefined,
          },
        },
        refetchQueries: [GetEsrsMetricAnswerDocument_],
      });
    },
    [upsertThread, thread]
  );

  const deleteComment = useCallback(
    (commentId: Comment['id']) => deleteCommentMutation({ variables: { id: commentId } }),
    [deleteCommentMutation]
  );

  return { addComment, deleteComment, thread: threadResult?.thread, loading };
}
