import { HStack } from '@chakra-ui/react';
import { useCurrentCompany } from 'utils/hooks';
import { CompanyStructure, NodeType } from 'Features/CompanyStructure';
import { CompanyAssessment, useReportingGroupsQuery } from 'models';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { CompanyFinancials } from './CompanyFinancials';
import { useElementSize } from 'usehooks-ts';

export const Financials = ({ cAssessment }: { cAssessment: CompanyAssessment }) => {
  const { company } = useCurrentCompany();
  const [pageRef, { height }] = useElementSize();

  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useReportingGroupsQuery({
    variables: {
      cAssessmentId: cAssessment?.id,
    },
    skip: !cAssessment?.id,
  });

  const reportingGroups = useMemo(() => {
    return data?.reportingGroups ?? [];
  }, [data]);

  const handleSelection = (selected: { nodeType: NodeType; nodeId: string }) => {
    setSearchParams(selected);
  };

  return (
    <HStack
      spacing="0px"
      alignItems="flex-start"
      justifyContent="space-between"
      width="100%"
      ref={pageRef}
    >
      {company && (
        <CompanyStructure
          reportingGroups={reportingGroups}
          bAssessments={cAssessment?.bAssessments ?? []}
          company={company}
          cAssessment={cAssessment}
          selectedNode={searchParams.get('nodeId') ?? 'company'}
          onSelect={handleSelection}
          selectableNodes={[NodeType.businessUnit, NodeType.company]}
          height={height ? `${height}px` : undefined}
        />
      )}
      <CompanyFinancials cAssessment={cAssessment} reportingGroups={reportingGroups} />
    </HStack>
  );
};
