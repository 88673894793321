import {
  Tag as ChakraTag,
  ChakraTheme,
  TagLeftIcon,
  TagRightIcon,
  TagLabel,
  TagProps as ChakraTagProps,
  Box,
} from '@chakra-ui/react';
import { TruncatableText } from 'Atoms/TruncatableText';
import { HelpTooltip } from 'Molecules';
import { Typography } from 'Tokens';
import React from 'react';

export const TagTheme: ChakraTheme['components']['Tag'] = {
  baseStyle: {
    container: {
      borderRadius: '16px',
    },
  },
  sizes: {
    md: {
      container: {
        minH: '28px',
        px: '8px',
        py: '4px',
      },
    },
    sm: {
      container: {
        px: '8px',
        py: '2px',
      },
    },
    xs: {
      container: {
        px: '4px',
        py: '2px',
      },
    },
  },
  variants: {
    warning: {
      container: {
        color: 'text.warning',
        bg: 'bg.warning',
      },
      color: 'text.warning',
    },
    default: {
      container: {
        color: 'text.muted',
        bg: 'bg.unknown',
      },
      color: 'text.muted',
    },
    selected: {
      container: {
        color: 'text.selected',
        bg: 'bg.selected',
      },
      color: 'text.selected',
    },
    compliant: {
      container: {
        color: 'text.compliant',
        bg: 'bg.compliant',
      },
      color: 'text.compliant',
    },
    danger: {
      container: {
        color: 'text.notCompliant',
        bg: 'bg.notCompliant',
      },
      color: 'text.notCompliant',
    },
    success: {
      container: {
        color: 'text.success',
        bg: 'bg.success',
      },
      color: 'text.success',
    },
    button: {
      container: {
        border: '1px solid',
        borderColor: 'border.decorative',
      },
    },
    outlined: {
      container: {
        border: '1px solid',
        borderColor: 'border.default',
        backgroundColor: 'white',
      },
    },
    info: {
      container: {
        color: 'text.info',
        bg: 'bg.info',
      },
      color: 'text.info',
    },
  },
};

export const TAG_VARIANTS = Object.keys(TagTheme.variants ?? {});
export type TagVariant = (typeof TAG_VARIANTS)[number];

export const TAG_SIZES = Object.keys(TagTheme.sizes ?? {});
export type TagSize = (typeof TAG_SIZES)[number];

export type TagProps = Omit<ChakraTagProps, 'variant'> & {
  variant?: TagVariant;
  leftIcon?: ChakraTagProps['children'];
  rightIcon?: ChakraTagProps['children'];
  title?: ChakraTagProps['children'];
  size?: TagSize;
  isRounded?: boolean;
  rightIconMargin?: string;
  leftIconMargin?: string;
  helpLabel?: string;
  clickable?: boolean;
  truncatable?: boolean;
  maxWidthInPixels?: number;
};

export const Tag = React.forwardRef(
  (
    {
      isRounded = true,
      rightIconMargin = '0px',
      leftIconMargin = '0px',
      leftIcon,
      rightIcon,
      maxWidthInPixels,
      ...props
    }: TagProps,
    ref: React.LegacyRef<HTMLSpanElement>
  ) => {
    return (
      <ChakraTag
        ref={ref}
        borderRadius={isRounded ? '16px' : '4px'}
        {...props}
        _hover={props.clickable ? { bg: 'bg.hover', cursor: 'pointer' } : {}}
        _active={props.clickable ? { bg: 'bg.pressed', cursor: 'pointer' } : {}}
      >
        <>
          {leftIcon && (
            <TagLeftIcon
              children={leftIcon}
              boxSize={props.size === 'md' ? '16px' : '12px'}
              marginRight={leftIconMargin}
              color={props.color}
            />
          )}
          {props.title ? (
            <TagLabel paddingLeft={leftIcon ? '2px' : '0px'} maxWidth={maxWidthInPixels}>
              {props.truncatable ? (
                <TruncatableText
                  variant={props.size === 'md' ? 'bodyStrong' : 'detailStrong'}
                  color="currentColor"
                  text={props.title}
                  maxTextWidth={maxWidthInPixels}
                />
              ) : (
                <Typography
                  variant={props.size === 'md' ? 'bodyStrong' : 'detailStrong'}
                  color="currentColor"
                >
                  {props.title}
                </Typography>
              )}
            </TagLabel>
          ) : (
            props.children
          )}
          {rightIcon && (
            <TagRightIcon
              children={rightIcon}
              boxSize={props.size === 'md' ? '16px' : '12px'}
              marginLeft={rightIconMargin}
              color={props.color}
            />
          )}
          {props.helpLabel && (
            <Box marginLeft={rightIconMargin}>
              <HelpTooltip label={props.helpLabel} />
            </Box>
          )}
        </>
      </ChakraTag>
    );
  }
);

export const AdditionalTypeTag = ({
  label,
  helpText,
  maxWidthInPixels,
}: {
  label: string;
  helpText: string;
  maxWidthInPixels?: number;
}) => {
  return (
    <Tag
      pl="10px"
      pr="8px"
      py="4px"
      h="28px"
      variant="outlined"
      borderRadius="4px"
      helpLabel={helpText}
      rightIconMargin="4px"
      maxWidth={maxWidthInPixels}
    >
      <TruncatableText
        variant="bodyStrong"
        text={label}
        maxTextWidth={maxWidthInPixels}
      ></TruncatableText>
    </Tag>
  );
};
