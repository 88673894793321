import { Box, HStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { EmptyState, Tag, VisibleIf } from 'Atoms';
import { useCurrentCompanyId } from 'utils/hooks';
import { ContentLayout, AvatarGroup, Table } from 'Molecules';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaiIndicatorsIllustration, Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import {
  usePaiRequests,
  PaiCompanyRequest,
  useCompanyPaiReport,
  PaiCompanyReportWithMetrics,
} from '../CompanyPai.hooks';
import { uniq, uniqBy } from 'lodash';
import { ReportPeriods } from 'Features/PortfolioView';

const StatusTag = ({ year, period }: { year: number; period: ReportPeriods }) => {
  const { companyId } = useCurrentCompanyId();
  const { t } = useTranslation('pai');
  const { report } = useCompanyPaiReport(year, period, companyId ?? '');

  const categories: Array<{
    isDone: boolean;
  }> = useMemo(() => {
    const allCategories =
      report?.indicators.reduce((aggregate, currentIndicator) => {
        if (aggregate[currentIndicator.category.reference]) {
          aggregate[currentIndicator.category.reference].push(currentIndicator);
        } else {
          aggregate[currentIndicator.category.reference] = [currentIndicator];
        }
        return aggregate ?? {};
      }, {} as Record<string, PaiCompanyReportWithMetrics['indicators']>) ?? {};

    return Object.entries(allCategories).map(([_, indicators]) => {
      const investeeMetrics = uniqBy(
        indicators
          .map((i) =>
            i.investorMetrics.map((im) => im.investorMetric.investeeMetrics.map((m) => m.metric))
          )
          .flat(3),
        'reference'
      );
      const answers =
        report?.answers.filter((a) =>
          investeeMetrics.some((m) => m.reference === a.metric.reference)
        ) ?? [];

      const isDone = investeeMetrics.every((metric) =>
        answers.some(
          (a) =>
            a.metric.reference === metric.reference && a?.data !== null && a?.data !== undefined
        )
      );
      return {
        isDone,
      };
    });
  }, [report]);
  return (
    <>
      {categories.every((c) => c.isDone === true) ? (
        <Tag variant="success">{t('status.done')}</Tag>
      ) : (
        <Tag variant="default">{t('table.status.started')}</Tag>
      )}
    </>
  );
};

export const PaiReportTable = ({ requests }: { requests: PaiCompanyRequest[] }) => {
  const { t } = useTranslation('pai');
  const navigate = useNavigate();

  const columns: ColumnDef<PaiCompanyRequest>[] = useMemo(
    () => [
      {
        header: t('table.name.header'),
        id: 'name',
        cell: ({ row }) => (
          <HStack spacing="8px">
            <Typography variant="bodyStrong">{t('report')}</Typography>
            <Tag
              bg="white"
              border="1px solid"
              borderColor="border.default"
              color="text.default"
              padding="2px 8px"
              fontSize="detailStrong"
            >
              {row?.original?.year}{' '}
              {row?.original?.period !== ReportPeriods.year &&
                `${row?.original?.period?.toLocaleUpperCase()}`}
            </Tag>
          </HStack>
        ),
      },
      {
        header: t('table.status.header'),
        id: 'status',
        cell: ({ row }) => {
          const reportExists =
            (row.original.report !== null &&
              row?.original?.report?.answers?.some((answer) => answer.data !== null)) ??
            0 > 0;
          if (reportExists) {
            return <StatusTag year={row.original.year} period={row?.original?.period} />;
          }
          return <Tag variant="default">{t('table.status.reported')}</Tag>;
        },
      },
      {
        header: t('table.investors.header'),
        id: 'investors',
        cell: ({ row }) => (
          <AvatarGroup
            size="sm"
            names={uniq(row?.original?.requests.map((r) => r.portfolio?.ownerCompany?.name))}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <Table<PaiCompanyRequest>
      data={requests}
      columns={columns}
      rowProps={{ _hover: { bg: 'bg.hover' } }}
      onRowClick={(row) => {
        navigate(`${row?.year}/${row?.period}`);
      }}
    />
  );
};

export const InvestorsEmptyState = () => {
  const { t } = useTranslation('pai');
  return (
    <Box w="100%" h={`calc(100vh - 152px)`}>
      <EmptyState
        title={t('table.empty.title')}
        description={t('table.empty.description')}
        icon={<PaiIndicatorsIllustration boxSize="120px" />}
      />
    </Box>
  );
};

export const PaiReportList = () => {
  const { companyId } = useCurrentCompanyId();
  const { requests } = usePaiRequests(companyId ?? undefined);
  return (
    <ContentLayout header={'PAI'}>
      <VisibleIf condition={requests.length >= 1}>
        <PaiReportTable requests={requests} />
      </VisibleIf>
      <VisibleIf condition={requests.length < 1}>
        <InvestorsEmptyState />
      </VisibleIf>
    </ContentLayout>
  );
};
