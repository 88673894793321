import { useTranslation } from 'utils/translation';
import { Image, HStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { DeleteIcon } from 'Tokens/Icons/Function';

export function LogoPreview({ url, onDelete }: { url: string; onDelete: () => void }) {
  const { t } = useTranslation('common');
  return (
    <HStack spacing="lg">
      <Image src={url} height="64px" width="64px" />
      <IconButton
        aria-label={t('common:fields.file.deleteFile')}
        size="md"
        variant="ghost"
        icon={<DeleteIcon color="inherit" />}
        onClick={() => onDelete()}
      />
    </HStack>
  );
}
