import { TFunction } from 'utils/translation';
import React, { useContext } from 'react';
import { ResolvedQuestion } from 'models';

export type CurrentQuestion = ResolvedQuestion & {
  // TODO: Channge any to AnswerUpdate
  onAnswerChange: (newValue: any, blockUnlockAssessment?: boolean) => any;
  t: TFunction;
  number: string;
  activityAssessmentId: string;
};
export const CurrentQuestionContext = React.createContext<CurrentQuestion>({} as any);

export function useCurrentQuestion() {
  return useContext(CurrentQuestionContext);
}
