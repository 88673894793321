import { useDisclosure } from '@chakra-ui/react';
import React, { useState, useMemo } from 'react';
import { useToast } from './useToast';

import { MenuAction } from 'Molecules/Menu';
import { useTranslation } from 'utils/translation';
import { useLatest } from 'react-use';

export type EditorAction<T> =
  | (Omit<MenuAction, 'onClick'> & {
      onClick?: 'edit' | 'delete' | ((obj: T) => void);
    })
  | ((obj: T) => MenuAction);
export type EditorContextType<T> = {
  create: () => void;
  actions: (obj: T) => MenuAction[];
  edit: (obj: T) => void;
  delete: (obj: T) => void;
};

export function createObjEditorContext<T>() {
  return React.createContext<EditorContextType<T>>({
    create: () => {},
    actions: () => [],
    edit: () => {},
    delete: () => {},
  });
}
export type EditorContextProviderProps<T> = {
  contextRef?: React.MutableRefObject<EditorContextType<T> | undefined>;
};
export function useObjectEditor<T>({
  deleteObject,
  contextRef,
  actions,
}: {
  deleteObject: (obj: T) => Promise<T | undefined | null>;
  contextRef?: React.MutableRefObject<EditorContextType<T> | undefined>;
  actions: EditorAction<T>[];
}) {
  const { isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose } = useDisclosure();
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: onDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose,
  } = useDisclosure();
  const [currentObject, setCurrentObject] = useState<T>();
  const toast = useToast();
  const { t } = useTranslation(['files', 'common']);
  const actionsRef = useLatest(actions);

  const context = useMemo<EditorContextType<T>>(() => {
    const editAction = (obj: T) => {
      setCurrentObject(obj);
      onEditorOpen();
    };
    const deleteAction = (obj: T) => {
      setCurrentObject(obj);
      onDeleteConfirmationOpen();
    };
    return {
      create: () => {
        setCurrentObject(undefined);
        onEditorOpen();
      },
      edit: editAction,
      delete: deleteAction,
      actions: (obj) =>
        (actionsRef.current ?? []).map((a) => {
          if (typeof a == 'function') return a(obj);
          const { onClick } = a;
          let wrapped;
          if (onClick === 'edit') wrapped = () => editAction(obj);
          else if (onClick === 'delete') wrapped = () => deleteAction(obj);
          else wrapped = () => onClick?.(obj);
          return { ...a, onClick: wrapped };
        }),
    };
  }, [setCurrentObject, onEditorOpen]);
  const handleDelete = () => {
    if (currentObject)
      deleteObject(currentObject).then(() => {
        toast({ text: t('files:fileDeleted') });
      });
  };
  if (contextRef) contextRef.current = context;
  const deleteDialogProps = {
    onConfirm: handleDelete,
    confirmLabel: t('common:actions.delete'),
    isOpen: isDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose,
  };
  const editorProps = {
    isOpen: isEditorOpen,
    onClose: onEditorClose,
    currentObject,
  };
  return {
    editorProps,
    currentObject,
    context,
    handleDelete: handleDelete,
    deleteDialogProps,
  };
}
