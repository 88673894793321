import { useCompanyAssessmentResults } from 'Features/Results/Results.hooks';
import { useAllActivities } from 'Features/Screening/Screening.hooks';
import { groupBy, padStart, sortBy, sumBy } from 'lodash';
import { ActivityResults, CompanyAssessmentResults, ObjectiveKeyEnum } from 'models';
import { useMemo } from 'react';
import { useCurrentCompany } from 'utils/hooks';
import { RowFormat, SheetFormat, TaxonomyTableData } from './table-generator';

type ResultsByActivity = { [key: string]: ActivityResults[] };
type ResultsByTag = { [key: string]: ActivityResults[] };
type ResultsByActivityAndTag = { [key: string]: ResultsByTag };

type FinancialsAttribute =
  | 'aligned'
  | 'eligible'
  | 'inProgress'
  | 'notAligned'
  | 'notEligible'
  | 'total';

type Financials = {
  [attribute in FinancialsAttribute]: number;
};

type FinancialsType = 'capex' | 'opex' | 'revenue';

type FinancialsPerType = {
  [type in FinancialsType]: Financials;
};

const SCObjectivesToAbbreviationsMap: { [key: string]: string } = {
  mitigation: 'CCM',
  adaptation: 'CCA',
  water: 'WTR',
  circular: 'CE',
  pollution: 'PPC',
  biodiversity: 'BIO',
};
interface SubstantialContribution {
  mitigation: number;
  adaptation: number;
  water: number;
  circular: number;
  pollution: number;
  biodiversity: number;
}

interface NoSignificantHarm {
  mitigation?: boolean;
  adaptation?: boolean;
  water?: boolean;
  circular?: boolean;
  pollution?: boolean;
  biodiversity?: boolean;
}

const defaultFinancialsPerType: Financials = {
  aligned: 0,
  eligible: 0,
  inProgress: 0,
  notAligned: 0,
  notEligible: 0,
  total: 0,
};

const defaultFinancials: FinancialsPerType = {
  capex: defaultFinancialsPerType,
  opex: defaultFinancialsPerType,
  revenue: defaultFinancialsPerType,
};

const getResultsByActivity = (
  cachedResults: CompanyAssessmentResults | undefined
): ResultsByActivity => {
  if (!cachedResults) return {};

  const allActivityResults: ActivityResults[] = [];

  cachedResults?.businessUnitResults.forEach((businessUnitResult) => {
    const activityResultsWithMSSG = businessUnitResult.activityResults.map((ar) => ({
      ...ar,
      isMSSGAligned: businessUnitResult.generalAssessmentResult?.cachedResult?.isAligned,
    }));
    allActivityResults.push(...activityResultsWithMSSG);
  });

  return groupBy(allActivityResults, 'activityRef');
};

const splitResultsByTag = (results: ActivityResults[]): ResultsByTag => {
  return groupBy(results, 'cachedResult.activityTag');
};

const getResultsByActivityAndTag = (
  results?: CompanyAssessmentResults
): ResultsByActivityAndTag => {
  const resultsByActivity = getResultsByActivity(results);
  const resultsByActivityAndTag: ResultsByActivityAndTag = {};

  Object.entries(resultsByActivity).forEach(([activityRef, activityResult]) => {
    resultsByActivityAndTag[activityRef] = splitResultsByTag(activityResult);
  });

  return resultsByActivityAndTag;
};

const getEligibleAligned = (activityResult: ActivityResults, type: string): number => {
  const financials = activityResult?.cachedResult?.financials[type];
  if (financials?.eligible > 0 && activityResult.isMSSGAligned && financials.aligned > 0) {
    return financials.aligned;
  }
  return 0;
};

const getEligibleNotAligned = (activityResult: ActivityResults, type: string): number => {
  const financials = activityResult?.cachedResult?.financials[type];
  if (financials?.eligible > 0) {
    if (!activityResult.isMSSGAligned) {
      return financials.total;
    }
    return financials.inProgress + financials.notAligned;
  }
  return 0;
};

const getRefNumbers = (input?: string): number[] | null => {
  if (!input) return null;
  const re = /(\d+)\.(\d+)(?:\.(\d+))?/;
  const matches = input.match(re);
  if (matches) {
    return [parseInt(matches[1] ?? '0'), parseInt(matches[2] ?? '0'), parseInt(matches[3] ?? '0')];
  } else {
    return null;
  }
};

const getActivityName = (activityRef: string, activities: any): string => {
  let activityName = activities[activityRef]?.name ?? '';
  const activityNumber = activities[activityRef]?.referenceNumber;
  if (!activityName.startsWith(activityNumber) && !!activityNumber) {
    activityName = `${activityNumber}. ${activityName}`;
  }
  return activityName;
};

const getCode = (activityRef: string, activities: any, results?: ActivityResults): string => {
  const activityNumber = activities[activityRef]?.referenceNumber;
  const substantialContributionObjectives =
    results?.cachedResult?.objectivesState?.substantialContributionObjectives;
  if (substantialContributionObjectives && substantialContributionObjectives.length > 0) {
    const objectivesString: string[] = substantialContributionObjectives.map(
      (objective: string) => {
        if (!!SCObjectivesToAbbreviationsMap[objective] && !!activityNumber) {
          return `${SCObjectivesToAbbreviationsMap[objective]}+${activityNumber}`;
        }
      }
    );
    if (objectivesString.length > 1) {
      return objectivesString.join(', ');
    } else if (objectivesString.length === 1) {
      return objectivesString[0];
    }
  }
  return '';
};

const getEligibleAlignedAbsolute = (results: ActivityResults[], type: string): number => {
  let total = 0;
  results.forEach((result) => {
    total += getEligibleAligned(result, type);
  });
  return total;
};

const getEligibleNotAlignedAbsolute = (results: ActivityResults[], type: string): number => {
  let total = 0;
  results.forEach((result) => {
    total += getEligibleNotAligned(result, type);
  });
  return total;
};

const getProportion = (absolute: number, total?: number): number => {
  if (total && total > 0) {
    return absolute / total;
  } else {
    return 0;
  }
};

const calculateSubstantialContributions = (
  results: ActivityResults[],
  type: string,
  total: number
): SubstantialContribution => {
  const scFinancial: SubstantialContribution = {
    adaptation: 0,
    mitigation: 0,
    water: 0,
    biodiversity: 0,
    circular: 0,
    pollution: 0,
  };

  results.forEach((result) => {
    const substantialContributions: (keyof SubstantialContribution)[] =
      result?.cachedResult?.objectivesState.substantialContributionObjectives;
    const financial = getEligibleAligned(result, type);

    if (substantialContributions.length === 1) {
      const substantialContribution = substantialContributions[0];
      scFinancial[substantialContribution] += financial;
    } else {
      const adaptation = result?.cachedResult?.financials[type].adaptation;
      scFinancial.adaptation += adaptation;
      const allOtherObjectives = substantialContributions.filter(
        (objective) => objective !== ObjectiveKeyEnum.adaptation
      );
      allOtherObjectives.forEach((objective) => {
        scFinancial[objective] += financial - adaptation;
      });
    }
  });

  if (total === 0) {
    return {
      adaptation: 0,
      mitigation: 0,
      water: 0,
      biodiversity: 0,
      circular: 0,
      pollution: 0,
    };
  }

  return {
    adaptation: scFinancial.adaptation / total,
    mitigation: scFinancial.mitigation / total,
    water: scFinancial.water / total,
    biodiversity: scFinancial.biodiversity / total,
    circular: scFinancial.circular / total,
    pollution: scFinancial.pollution / total,
  };
};

const getNoSignificantHarm = (
  substantialContribution: SubstantialContribution
): NoSignificantHarm => {
  return {
    mitigation: substantialContribution.mitigation === 1 ? undefined : true,
    adaptation: substantialContribution.adaptation === 1 ? undefined : true,
    water: substantialContribution.water === 1 ? undefined : true,
    circular: substantialContribution.circular === 1 ? undefined : true,
    pollution: substantialContribution.pollution === 1 ? undefined : true,
    biodiversity: substantialContribution.biodiversity === 1 ? undefined : true,
  };
};

const getEligibleAlignedActivities = (
  results: ResultsByActivityAndTag,
  type: string,
  activities: any,
  total?: number
): RowFormat[] => {
  const eligibleAlignedActivities: RowFormat[] = [];

  Object.entries(results).forEach(([activityRef, resultsByTag]) => {
    Object.entries(resultsByTag).forEach(([activityTag, activityResults]) => {
      const absolute = getEligibleAlignedAbsolute(activityResults, type);
      const proportion = getProportion(absolute, total);
      if (absolute > 0) {
        const substantialContributions = calculateSubstantialContributions(
          activityResults,
          type,
          total ?? 0
        );
        const noSignificantHarm = getNoSignificantHarm(substantialContributions);
        const newRow: RowFormat = {
          activityName: getActivityName(activityRef, activities),
          code: getCode(
            activityRef,
            activities,
            activityResults.find((result) => result.activityRef === activityRef)
          ),
          absolute,
          proportion,
          mitigationSC: substantialContributions.mitigation,
          adaptationSC: substantialContributions.adaptation,
          waterSC: substantialContributions.water,
          circularSC: substantialContributions.circular,
          pollutionSC: substantialContributions.pollution,
          biodiversitySC: substantialContributions.biodiversity,
          mitigationDNSH: noSignificantHarm.mitigation,
          adaptationDNSH: noSignificantHarm.adaptation,
          waterDNSH: noSignificantHarm.water,
          circularDNSH: noSignificantHarm.circular,
          pollutionDNSH: noSignificantHarm.pollution,
          biodiversityDNSH: noSignificantHarm.biodiversity,
          minimumSafeguards: true,
          alignedN: proportion,
          enabling: activityTag === 'ENABLING' ? 'E' : undefined,
          transitional: activityTag === 'TRANSITIONAL' ? 'T' : undefined,
        };
        eligibleAlignedActivities.push(newRow);
      }
    });
  });
  return eligibleAlignedActivities;
};

const getEligibleNotAlignedActivities = (
  results: ResultsByActivityAndTag,
  type: string,
  activities: any,
  total?: number
): RowFormat[] => {
  const eligibleNotAlignedActivities: RowFormat[] = [];
  Object.entries(results).forEach(([activityRef, resultsByTag]) => {
    Object.entries(resultsByTag).forEach(([activityTag, activityResults]) => {
      const absolute = getEligibleNotAlignedAbsolute(activityResults, type);
      if (absolute > 0) {
        const newRow: RowFormat = {
          activityName: getActivityName(activityRef, activities),
          code: getCode(
            activityRef,
            activities,
            activityResults.find((result) => result.activityRef === activityRef)
          ),
          absolute,
          proportion: getProportion(absolute, total),
          enabling: activityTag === 'ENABLING' ? 'E' : undefined,
          transitional: activityTag === 'TRANSITIONAL' ? 'T' : undefined,
        };
        eligibleNotAlignedActivities.push(newRow);
      }
    });
  });
  return eligibleNotAlignedActivities;
};

const getTotalEligibleAligned = (eligibleAlignedRows: RowFormat[], total: number): RowFormat => {
  const alignedSum = sumBy(eligibleAlignedRows, 'absolute');
  const proportion = getProportion(alignedSum, total);

  const getTotalSubstantialContribution = (objectiveKey: string): number => {
    return sumBy(eligibleAlignedRows, (row) => row[objectiveKey]);
  };

  const getTotalEnabling = (): number => {
    return sumBy(eligibleAlignedRows, (row) => (row.enabling === 'E' ? row.alignedN ?? 0 : 0));
  };

  const getTotalTransitional = (): number => {
    return sumBy(eligibleAlignedRows, (row) => (row.transitional === 'T' ? row.alignedN ?? 0 : 0));
  };

  const row: RowFormat = {
    absolute: alignedSum,
    proportion,
    mitigationSC: getTotalSubstantialContribution('mitigationSC'),
    adaptationSC: getTotalSubstantialContribution('adaptationSC'),
    waterSC: getTotalSubstantialContribution('waterSC'),
    circularSC: getTotalSubstantialContribution('circularSC'),
    pollutionSC: getTotalSubstantialContribution('pollutionSC'),
    biodiversitySC: getTotalSubstantialContribution('biodiversitySC'),
    alignedN: proportion,
    enabling: getTotalEnabling(),
    transitional: getTotalTransitional(),
  };
  return row;
};

const getTotalEligibleNotAligned = (
  eligibleNotAlignedRows: RowFormat[],
  total?: number
): RowFormat => {
  const notAlignedSum = sumBy(eligibleNotAlignedRows, 'absolute');
  const row: RowFormat = {
    absolute: notAlignedSum,
    proportion: getProportion(notAlignedSum, total),
  };
  return row;
};

const getNonEligible = (totalFinancials: FinancialsPerType, type: FinancialsType): RowFormat => {
  const absolute = totalFinancials[type].notEligible;
  const proportion = getProportion(absolute, totalFinancials[type].total);
  return {
    absolute,
    proportion,
  };
};

const sortByRef = (row: RowFormat): string => {
  const refNumbers = getRefNumbers(row.activityName);
  if (!refNumbers) return row.activityName ?? '';
  const firstNumber = padStart(`${refNumbers[0]}`, 3, '0');
  const secondNumber = padStart(`${refNumbers[1]}`, 3, '0');
  const thirdNumber = padStart(`${refNumbers[2]}`, 3, '0');
  return `${firstNumber}.${secondNumber}.${thirdNumber}`;
};

const calculateSheet = (
  resultsByActivityAndTag: ResultsByActivityAndTag,
  activities: any,
  totalFinancials: FinancialsPerType,
  type: FinancialsType
): SheetFormat => {
  const eligibleAlignedActivities = getEligibleAlignedActivities(
    resultsByActivityAndTag,
    type,
    activities,
    totalFinancials[type].total
  );
  const sortedEligibleAlignedActivities = sortBy(eligibleAlignedActivities, sortByRef);
  const eligibleNotAlignedActivities = getEligibleNotAlignedActivities(
    resultsByActivityAndTag,
    type,
    activities,
    totalFinancials[type].total
  );
  const sortedEligibleNotAlignedActivities = sortBy(eligibleNotAlignedActivities, sortByRef);
  const totalEligibleAligned = getTotalEligibleAligned(
    eligibleAlignedActivities,
    totalFinancials[type].total
  );
  const totalEligibleNotAligned = getTotalEligibleNotAligned(
    eligibleNotAlignedActivities,
    totalFinancials[type].total
  );
  const totalEligible: RowFormat = {
    absolute: totalEligibleAligned.absolute + totalEligibleNotAligned.absolute,
    proportion: totalEligibleAligned.proportion + totalEligibleNotAligned.proportion,
    alignedN: (totalEligibleAligned?.alignedN ?? 0) + (totalEligibleNotAligned?.alignedN ?? 0),
    enabling:
      Number(totalEligibleAligned?.enabling ?? 0) + Number(totalEligibleNotAligned?.enabling ?? 0),
    transitional:
      Number(totalEligibleAligned?.transitional ?? 0) +
      Number(totalEligibleNotAligned?.transitional ?? 0),
  };
  const nonEligible = getNonEligible(totalFinancials, type);
  const total: RowFormat = {
    absolute: totalFinancials[type].total,
    proportion: 1,
  };
  return {
    eligibleAlignedActivities: sortedEligibleAlignedActivities,
    eligibleNotAlignedActivities: sortedEligibleNotAlignedActivities,
    totalEligibleAligned,
    totalEligibleNotAligned,
    totalEligible,
    nonEligible,
    total,
  };
};

export const useTaxonomyTableData = (
  cAssessmentId: string,
  isGroup = false
): { data: TaxonomyTableData } => {
  const { company } = useCurrentCompany();
  const { data: results } = useCompanyAssessmentResults(cAssessmentId, false, isGroup);

  const resultsByActivityAndTag = useMemo(() => getResultsByActivityAndTag(results), [results]);

  const activities = useAllActivities();
  const activityMap = useMemo(
    () =>
      activities.reduce(
        (nameMap, activity) => ({
          ...nameMap,
          [activity.reference]: {
            name: activity.name,
            referenceNumber: activity.referenceNumber,
          },
        }),
        {}
      ),
    [activities]
  );

  const reportingYear = new Date(results?.startDate ?? '').getFullYear();

  const data = useMemo(() => {
    const totalFinancials: FinancialsPerType =
      results?.cachedResult?.financials ?? defaultFinancials;
    return {
      companyName: company?.name ?? '',
      reportingYear,
      currency: company?.currency ?? '',
      turnover: calculateSheet(resultsByActivityAndTag, activityMap, totalFinancials, 'revenue'),
      capex: calculateSheet(resultsByActivityAndTag, activityMap, totalFinancials, 'capex'),
      opex: calculateSheet(resultsByActivityAndTag, activityMap, totalFinancials, 'opex'),
    };
  }, [company, results, resultsByActivityAndTag, activityMap]);

  return { data };
};
