import React, { useCallback, useMemo, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { Activity, AttachmentBox, BAssessment } from 'models';
import { useTranslation } from 'utils/translation';
import { useDebounce, useToast } from 'utils/hooks';
import { useToggleActivity } from '../Screening.hooks';
import { ActivityDetails } from './ActivityDetails';
import { ActivityCard } from 'Features/Screening/ManageActivities/ActivityCard/ActivityCard';
import { useActivitySearch } from 'utils/hooks/useActivitySearch';
import { ActivitiesScrollViewWithSearchHeader } from './ActivitiesScrollView';
import { EmptyState } from 'Atoms';

const EmptyActivitiesList = ({ goToBrowseTab }: { goToBrowseTab: () => void }) => {
  const { t } = useTranslation('bUnits');

  return (
    <Box w="100%" height="100%" pb="16px">
      <EmptyState
        title={t('bUnits:activities.noActivities')}
        description={t('bUnits:noSelectedActivities')}
        callToAction={{
          text: t('bUnits:browseActivities'),
          variant: 'secondary',
          onClick: () => goToBrowseTab(),
        }}
        component={true}
      />
    </Box>
  );
};

const SelectedActivitiesList = ({
  activities,
  removeActivity,
  setSelectedPreviewActivity,
  bAssessment,
  openAttachmentDrawer,
}: {
  activities: Activity[];
  removeActivity: (a: Activity) => void;
  setSelectedPreviewActivity: (activity: Activity) => void;
  bAssessment: BAssessment;
  openAttachmentDrawer: (box?: AttachmentBox) => void;
}) => {
  return (
    <VStack paddingBottom="16px" width="100%">
      {activities.map((activity) => (
        <ActivityCard
          key={activity.reference}
          activity={activity}
          activityReportId={
            bAssessment.activityReports.find(
              (report) => report.activity.reference == activity.reference
            )?.id ?? undefined
          }
          openAttachmentDrawer={openAttachmentDrawer}
          onLearnClicked={() => setSelectedPreviewActivity(activity)}
          removeActivity={() => removeActivity(activity)}
        />
      ))}
    </VStack>
  );
};

export const SelectedActivities = ({
  bAssessment,
  goToBrowseTab,
  openAttachmentDrawer,
}: {
  bAssessment: BAssessment;
  goToBrowseTab: () => void;
  openAttachmentDrawer: (box?: AttachmentBox) => void;
}) => {
  const toast = useToast();
  const { t } = useTranslation('bUnits');
  const [searchValue, setSearchValue] = useState('');
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const debouncedQuery = useDebounce(searchValue, 1000);

  const { searchResults = [], naceCodeList } = useActivitySearch(debouncedQuery, selectedCodes);

  const [selectedPreviewActivity, setSelectedPreviewActivity] = useState<Activity | undefined>();
  const [toggleActivity] = useToggleActivity(bAssessment);

  const activitiesToShow = useMemo(() => {
    const activities = bAssessment.activityReports.map((ar) => ar.activity);
    if (activities.length > 0) {
      if (selectedCodes.length === 0 && searchValue === '') {
        return activities;
      } else if (selectedCodes.length === 0 && searchValue !== '') {
        return searchResults.filter((a) => activities.find((a2) => a2.reference === a.reference));
      } else if (selectedCodes.length > 0) {
        const filteredResults = activities.filter((a) =>
          a.naceCodes.some((code) => selectedCodes.includes(code.code))
        );
        if (searchValue !== '') {
          return searchResults.filter((searchResultActivity) =>
            filteredResults.find(
              (naceFilteredActivity) =>
                naceFilteredActivity.reference === searchResultActivity.reference
            )
          );
        } else return filteredResults;
      }
    }
    return [];
  }, [bAssessment, searchResults, searchValue, selectedCodes]);

  const handleRemoveActivity = useCallback(
    (activity: Activity) => {
      toggleActivity(false, activity.reference)
        .then(() => {
          toast({
            text: t('bUnits:toast.activities.remove'),
          });
        })
        .catch(() => {
          toast({
            text: t('bUnits:toast.activities.failed.remove'),
            variant: 'danger',
          });
        });
    },
    [toggleActivity]
  );

  return (
    <ActivitiesScrollViewWithSearchHeader
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      naceCodeList={naceCodeList}
      selectedCodes={selectedCodes}
      setSelectedCodes={setSelectedCodes}
    >
      {activitiesToShow.length > 0 ? (
        <SelectedActivitiesList
          activities={activitiesToShow}
          bAssessment={bAssessment}
          removeActivity={handleRemoveActivity}
          openAttachmentDrawer={openAttachmentDrawer}
          setSelectedPreviewActivity={setSelectedPreviewActivity}
        />
      ) : (
        <EmptyActivitiesList goToBrowseTab={goToBrowseTab} />
      )}
      <ActivityDetails
        isOpen={!!selectedPreviewActivity}
        activity={selectedPreviewActivity}
        onClose={() => setSelectedPreviewActivity(undefined)}
      />
    </ActivitiesScrollViewWithSearchHeader>
  );
};
