import { FormField } from 'Atoms';
import { LabelText } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { Heading, VStack, HStack } from '@chakra-ui/react';
import { CompanyIcon, FundIcon, GroupIcon } from 'Tokens/Icons/Data';
import { CircleIconWrapper, IconComponentType } from 'Tokens/Icons/IconWrapper';
import { CompanyType } from 'utils/hooks';
import { useEffect, useState } from 'react';
import { RadioCardGroup } from 'Molecules';

export type CompanyTypeObject = {
  isPortfolioOwner: boolean;
  isGroupOwner: boolean;
};

export function CompanyTypeRadio({
  icon,
  header,
  text,
}: {
  icon: IconComponentType;
  header: string;
  text: string;
}) {
  return (
    <VStack textAlign="center" spacing="sm">
      <CircleIconWrapper icon={icon} size="56px" scale={24 / 56} color="white" />
      <Heading fontSize="24px">{header}</Heading>
      <LabelText size="md">{text}</LabelText>
    </VStack>
  );
}

export function CompanyTypeSelector({
  setValue,
}: {
  setValue: (newVal: CompanyTypeObject) => void;
}) {
  const { t } = useTranslation('common');
  const [selectedCompany, setSelectedCompany] = useState(CompanyType.regular);
  useEffect(() => {
    setValue({
      isPortfolioOwner: selectedCompany === CompanyType.portfolio ? true : false,
      isGroupOwner: selectedCompany === CompanyType.group ? true : false,
    });
  }, [selectedCompany]);

  return (
    <FormField id="companyTypeSelector" label={t('common:fields.companyType.label')}>
      <HStack width="100%">
        <RadioCardGroup
          value={selectedCompany}
          onChange={(val) => {
            setSelectedCompany(val as CompanyType);
          }}
          direction="horizontal"
          cardWidth="312px"
          options={[
            {
              title: t('common:fields.companyType.regularLabel'),
              subtitle: t('common:fields.companyType.regularDescription'),
              icon: CompanyIcon,
              value: CompanyType.regular,
            },
            {
              title: t('common:fields.companyType.portfolioLabel'),
              subtitle: t('common:fields.companyType.portfolioDescription'),
              icon: FundIcon,
              value: CompanyType.portfolio,
            },
            {
              title: t('common:fields.companyType.groupLabel'),
              subtitle: t('common:fields.companyType.groupDescription'),
              icon: GroupIcon,
              value: CompanyType.group,
            },
          ]}
        />
      </HStack>
    </FormField>
  );
}
