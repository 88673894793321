import { Button, Checkbox, TruncatableText } from 'Atoms';
import { ContentHeader } from 'Molecules/ContentHeader';
import { ContentLayout } from 'Molecules/ContentLayout';
import { DownloadIcon } from 'Tokens/Icons/Function';
import { useParams } from 'react-router-dom';
import { ChartTableType, FiltersType, useMetricBreakdown } from './MetricChart.hooks';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { colors, Typography } from 'Tokens';
import { Loader, Select, Table } from 'Molecules';
import { ColumnDef } from '@tanstack/react-table';
import { stringToColor } from 'utils/generateRandomColors';
import { CustomTooltip } from './CustomTooltip';

const MenuBox: FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  return (
    <VStack
      spacing="8px"
      p="16px"
      alignItems="flex-start"
      w="100%"
      bgColor="bg.muted"
      border="1px solid"
      borderColor="border.decorative"
      borderRadius="8px"
    >
      <Typography variant="bodyStrong">{title}</Typography>
      {children}
    </VStack>
  );
};

export const MetricChart = () => {
  const {
    esrsAssessmentId = '',
    standardRef = '',
    metricRef = '',
    materialStandardId = '',
  } = useParams();
  const [filters, setFilters] = useState<FiltersType>({
    ruFilter: undefined,
    subFilter: undefined,
  });
  const [selectedTagValues, setSelectedTagValues] = useState<string[]>([]);

  const {
    result,
    tagFilterResult,
    chartData,
    tagValues,
    businessUnitOptions,
    subsidiaryOptions,
    tagOptions,
    metric,
    isGroup,
    isYearly,
    loading,
  } = useMetricBreakdown(esrsAssessmentId, materialStandardId, metricRef, standardRef, filters);

  useEffect(() => setSelectedTagValues(tagValues), [tagValues]);

  const tableData = useMemo(() => {
    if (filters.tagBreakdown) return tagFilterResult;
    else return result;
  }, [filters, tagFilterResult, result]);

  const handleFilterChange = (
    key: keyof FiltersType,
    value?:
      | string
      | string[]
      | {
          tagType: string;
          tagValue: string;
        }
  ) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleSelectCheckbox = (isSelected: boolean, item: string) => {
    if (isSelected) setSelectedTagValues((cur) => [...cur, item]);
    else setSelectedTagValues((cur) => cur.filter((tag) => tag !== item));
  };

  if (loading) return <Loader />;

  return (
    <ContentLayout
      header={
        <ContentHeader
          title={metric?.shortTitle ?? metric?.title ?? ''}
          subtitle={metric.unitOfMeasurement ?? ''}
          size="md"
          backButton
          actions={
            <Button variant="ghost" leftIcon={<DownloadIcon color="inherit" />} isDisabled>
              Export as CSV
            </Button>
          }
        />
      }
    >
      <HStack width="100%" h="500px" p="16px" spacing="16px" alignItems="flex-start">
        <Box flex="1" w="100%" h="100%">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{
                top: 10,
                right: 0,
                left: -15,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} stroke={colors.bg['interactive.accent']} />
              <XAxis
                dataKey="name"
                fontSize="12px"
                stroke="transparent"
                tick={{ fill: colors.text.hint }}
              />
              <YAxis fontSize="12px" stroke="transparent" tick={{ fill: colors.text.hint }} />
              <Tooltip
                content={
                  <CustomTooltip metricTitle={metric?.title} isBreakdown={!!filters.tagBreakdown} />
                }
                shared={false}
              />
              {filters.tagBreakdown ? (
                selectedTagValues?.map((tagValue) => (
                  <Bar
                    dataKey={tagValue}
                    barSize={isYearly ? 100 : 36}
                    stackId="a"
                    fill={stringToColor(tagValue)}
                  />
                ))
              ) : (
                <Bar dataKey="value" barSize={isYearly ? 100 : 36} stackId="a" fill="#9999FF" />
              )}
            </BarChart>
          </ResponsiveContainer>
          <Table
            columns={
              [
                {
                  header: () => (
                    <HStack>
                      {filters.tagBreakdown && (
                        <Checkbox
                          isChecked={selectedTagValues.length === tagValues?.length}
                          onChange={() => {
                            if (selectedTagValues.length === tagValues?.length)
                              setSelectedTagValues([]);
                            else setSelectedTagValues(tagValues);
                          }}
                        />
                      )}
                      <Typography variant="bodyStrong">Name</Typography>
                    </HStack>
                  ),
                  accessorKey: 'name',
                  cell: ({ row }) => (
                    <HStack>
                      {filters.tagBreakdown && (
                        <Checkbox
                          isChecked={!!selectedTagValues.find((tag) => row.original.name === tag)}
                          onChange={(e) =>
                            handleSelectCheckbox(e.currentTarget.checked, row.original.name)
                          }
                          variant={stringToColor(row.original.name)}
                        />
                      )}
                      <TruncatableText variant="bodyStrong" text={row.original.name} w="100%" />
                    </HStack>
                  ),
                },
                {
                  header: 'unit',
                  accessorKey: 'unit',
                  cell: ({ row }) => <Typography variant="body">{row.original.unit}</Typography>,
                },
                {
                  header: 'total',
                  accessorKey: 'value',
                  cell: ({ row }) => <Typography variant="body">{row.original.value}</Typography>,
                },
              ] as ColumnDef<ChartTableType>[]
            }
            data={tableData ?? []}
            headerPadding="8px"
            cellPadding="12px 8px"
            mt="16px"
          />
        </Box>
        <VStack w="320px">
          <MenuBox title="Breakdown">
            {tagOptions.length ? (
              <Select
                isMulti={false}
                isClearable={true}
                options={
                  tagOptions?.map((tag) => ({
                    label: tag,
                    value: tag,
                  })) ?? []
                }
                onChange={(option) => handleFilterChange('tagBreakdown', option?.value)}
              />
            ) : (
              <Center w="100%" h="36px" alignItems="start">
                <Typography variant="body" color="text.hint">
                  No breakdown available
                </Typography>
              </Center>
            )}
          </MenuBox>
          <MenuBox title="Filters">
            {isGroup ? (
              <Select
                isMulti={true}
                isClearable={true}
                options={subsidiaryOptions?.map((subOption) => ({
                  label: subOption.name,
                  value: subOption.id,
                }))}
                onChange={(option) =>
                  handleFilterChange(
                    'subFilter',
                    option.map((opt) => opt.value)
                  )
                }
              />
            ) : (
              <Select
                isMulti={true}
                isClearable={true}
                options={businessUnitOptions?.map((buOption) => ({
                  label: buOption.name,
                  value: buOption.id,
                }))}
                onChange={(option) =>
                  handleFilterChange(
                    'ruFilter',
                    option.map((opt) => opt.value)
                  )
                }
              />
            )}
          </MenuBox>
        </VStack>
      </HStack>
    </ContentLayout>
  );
};
