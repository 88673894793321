import { Flex, Stack, HStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { NotificationsMenu, KnowledgeBase } from 'Features';
import CompanySwitcher from 'Features/CompanySwitcher/CompanySwitcher';
import { AvatarMenu } from 'Organisms';

export const TOP_MENU_HEIGHT = '64px';
export const TopMenu = () => {
  const user = useUserData();
  return (
    <Stack
      direction="row"
      padding="0px 16px 0px 16px"
      borderBottomWidth="1px"
      bg="bg.default"
      borderBottomColor="border.decorative"
      borderBottomStyle="solid"
      width="100%"
      justifyContent="space-between"
      minHeight={TOP_MENU_HEIGHT}
    >
      <Flex alignItems="center" justifyContent="space-between" minWidth="128px" minH="40px">
        {user ? <CompanySwitcher user={user} /> : undefined}
      </Flex>
      <Flex display="flex" gridGap="16px">
        <HStack spacing="8px">
          {user ? <NotificationsMenu /> : undefined}
          {user ? <KnowledgeBase /> : undefined}
        </HStack>
        <HStack spacing="0px">{user ? <AvatarMenu user={user} /> : undefined}</HStack>
      </Flex>
    </Stack>
  );
};
