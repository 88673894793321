import { Box, useDisclosure, VStack, HStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Button, Infobox, Tag, TruncatableText } from 'Atoms';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  MappedDisclosureRequirements,
  AdditionalType,
  MaterialDr,
  MaterialityFields,
  MaterialityState,
  MaterialMetric,
} from './MaterialityAssessment.d';
import {
  AttachmentBox,
  GetMaterialStandardDocument_,
  NoteHistory,
  ShortUser,
  useGetMaterialStandardQuery,
  useGetDisclosureRequirementMaterialityQuery,
  useGetEsrsStandardQuery,
  useGetParentMaterialityAssessmentQuery,
  useUpdateAssessmentOnboardingMutation,
  GetParentMaterialityAssessmentQuery_,
  useUpsertDisclosureRequirementMaterialityMutation,
  GetDisclosureRequirementMaterialityDocument_,
} from 'models';
import { ContentHeader, ContentLayout, Loader } from 'Molecules';
import {
  InputCardDocumentation,
  InputCardDocumentationProps,
} from 'Molecules/InputCard/InputCardDocumentation';
import { useEffect, useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import {
  addMandatoryMetrics,
  useAddMaterialityAssessment,
  useMapDisclosureRequirements,
} from './MaterialityAssessment.hooks';
import { MetricsMaterialityModal } from './MetricsMaterialityModal';
import { ArrowLeftIcon, ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { MaterialityRadioGroup, MaterialityStatus } from './MaterialityRadioGroup';
import { useTranslation } from 'utils/translation';
import { MaterialityStatusBox, MaterialityStatusTag, ParentInfoBox } from './MaterialityUtils';
import { DisclosureRequirementsTable } from './DisclosureRequirementsTable';
import { AdditionalTypesGroup } from 'Molecules/MetricTagsList';
import { Typography } from 'Tokens';
import { LockedIcon } from 'Tokens/Icons/Function';

const AssessMetrics = ({
  currentStep,
  isGroupOwner,
  hasParent,
  DisclosureRequirements,
  parentData,
  handleOpenModal,
  isStandardMandatory,
}: {
  currentStep: number;
  isGroupOwner: boolean;
  hasParent: boolean;
  DisclosureRequirements: MappedDisclosureRequirements;
  parentData?: GetParentMaterialityAssessmentQuery_;
  handleOpenModal: (reference: string) => void;
  isStandardMandatory: boolean;
}) => {
  return (
    <Box position="relative">
      <Box pl={isStandardMandatory ? '0px' : '32px'} pb="24px">
        {isStandardMandatory ? (
          <>
            <Typography variant="h3" mb="2px">
              Review and assess disclosure requirements and metric
            </Typography>
            <Typography variant="body" color="text.muted" mb="16px">
              Review and assess what is material for your company
            </Typography>
          </>
        ) : (
          <ActiveStepLabels
            title="Review and assess disclosure requirements and metrics"
            description="Review and assess what is material for your company"
            active={currentStep === 2}
            done={false}
            ml="33px"
          />
        )}
        {(currentStep === 2 || isStandardMandatory) && (
          <VStack spacing="8px" alignItems="start">
            <DisclosureRequirementsTable
              DisclosureRequirements={DisclosureRequirements}
              handleOpenModal={handleOpenModal}
              hasParent={hasParent}
              isStandardMandatory={isStandardMandatory}
              parentData={parentData}
              isGroupOwner={isGroupOwner}
            />
            <Button
              variant="primary"
              type="submit"
              form="add-materiality"
              alignSelf="start"
              mt="16px"
              rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
            >
              Done
            </Button>
          </VStack>
        )}
      </Box>
    </Box>
  );
};

const AddReasoning = ({
  currentStep,
  setCurrentStep,
  isAttachmentDrawerOpen,
  onAttachmentDrawerClose,
  attachmentBox,
  showDocumentation,
  allowReviewMetrics,
}: {
  currentStep: number;
  setCurrentStep: (val: number) => void;
  isAttachmentDrawerOpen: boolean;
  onAttachmentDrawerClose: () => void;
  attachmentBox: AttachmentBox | undefined;
  showDocumentation: InputCardDocumentationProps;
  allowReviewMetrics: boolean;
}) => {
  return (
    <Box position="relative">
      <Box
        borderLeft={allowReviewMetrics ? '1px dashed' : 'none'}
        pl="32px"
        borderColor="border.hover"
        pb="24px"
      >
        <ActiveStepLabels
          title="Reasoning for choice"
          description="Provide reasoning for why the the topic is material or not. If such reasoning is already documented elsewhere (e.g in an uploaded materiality assessment), please write a short note referring to this documentation."
          active={currentStep === 1}
          mb={currentStep === 1 ? '24px' : '0px'}
          done={currentStep > 1}
          ml="33px"
          goBack={() => setCurrentStep(1)}
          descriptionWidth="490px"
        />

        {currentStep === 1 && (
          <Box w="490px">
            <AttachmentDrawer
              isOpen={isAttachmentDrawerOpen}
              refetch={[GetMaterialStandardDocument_]}
              onClose={onAttachmentDrawerClose}
              attachmentBox={attachmentBox}
            >
              <InputCardDocumentation {...showDocumentation} flexDirection="column" width="490px" />
            </AttachmentDrawer>
            {allowReviewMetrics ? (
              <Button
                variant="primary"
                alignSelf="start"
                rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
                mb="24px"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                Next step
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                form="add-materiality"
                alignSelf="start"
                rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
              >
                Done
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const StandardMateriality = ({
  currentStep,
  control,
  defaultValue,
  saveMateriality,
  isMaterial,
  setCurrentStep,
}: {
  currentStep: number;
  control: Control<MaterialityFields, any>;
  saveMateriality: (value: MaterialityState | null) => void;
  defaultValue: MaterialityState | null;
  isMaterial?: boolean | null;
  setCurrentStep: (val: number) => void;
}) => {
  return (
    <Box>
      <Controller
        name="material"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Box borderLeft="1px dashed" pl="32px" borderColor="border.hover" pb="24px">
              <ActiveStepLabels
                title="Assess materiality for this topic"
                description="Based on your materiality assessment, is this topic material for your company?"
                mb={currentStep === 0 ? '24px' : '0px'}
                active={currentStep === 0}
                done={currentStep > 0}
                ml="33px"
                goBack={() => setCurrentStep(0)}
              />
              {currentStep === 0 && (
                <>
                  <MaterialityRadioGroup
                    value={value ?? defaultValue}
                    onChange={(val) => {
                      onChange(val);
                      saveMateriality(val);
                    }}
                    defaultValue={defaultValue}
                  />
                  <Button
                    variant="primary"
                    alignSelf="start"
                    rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    isDisabled={isMaterial == null}
                    mb="24px"
                    mt="24px"
                  >
                    Next step
                  </Button>
                </>
              )}
            </Box>
          );
        }}
      />
    </Box>
  );
};

export const AddMaterialityAssessment = ({ isOnboarding = false }: { isOnboarding?: boolean }) => {
  const { standardRef, esrsAssessmentId } = useParams();
  const { t } = useTranslation('esrs');
  const [initialLoad, setInitialLoad] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { handleSubmit, control, reset, watch } = useForm<MaterialityFields>();
  const navigate = useNavigate();
  const { companyType } = useCompanyType();
  const user: ShortUser | null = useUserData();
  const addMateriality = useAddMaterialityAssessment();
  const mapDisclosureRequirements = useMapDisclosureRequirements();
  const [updateOnboardingStep] = useUpdateAssessmentOnboardingMutation();

  const isMaterialSelected = watch('material') === MaterialityState.material;

  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [materialMetrics, setMaterialMetrics] = useState<MaterialMetric[]>([]);
  const [assessmentAdded, setAssessmentAdded] = useState(false);
  const [drRef, setDrRef] = useState<string>('');
  const [upsertMateriality] = useUpsertDisclosureRequirementMaterialityMutation();

  const { data: standardData, loading } = useGetEsrsStandardQuery({
    variables: { reference: standardRef ?? '' },
    skip: !standardRef,
  });
  const { data: materialData, loading: materialLoading } = useGetMaterialStandardQuery({
    variables: { standardRef: standardRef ?? '', assessmentId: esrsAssessmentId },
  });
  const { data: parentData, loading: parentDataLoading } = useGetParentMaterialityAssessmentQuery({
    variables: {
      childAssessmentId: esrsAssessmentId ?? '',
    },
    skip: !esrsAssessmentId,
  });
  const { data: isMaterialData, loading: isMaterialDataLoading } =
    useGetDisclosureRequirementMaterialityQuery({
      variables: {
        standardRef: standardRef ?? '',
        standardAssessmentId: materialData?.materialityAssessment[0]?.id,
      },
      skip: !materialData?.materialityAssessment[0]?.id,
    });

  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);
  const material = useMemo(() => materialData?.materialityAssessment[0], [materialData]);
  const parentStandardMaterialityData = useMemo(
    () =>
      parentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
        (mA) => mA.standardRef === standardRef
      ),
    [parentData, standardRef]
  );
  const isGroupOwner = useMemo(() => companyType === 'group-company', [companyType]);
  const hasParent = useMemo(
    () => parentData?.EsrsAssessment_by_pk?.parentAssessment !== null,
    [parentData]
  );

  const isStandardMandatory = useMemo(() => !(standard?.isTopical ?? true), [standard]);

  const allowReviewMetrics = useMemo(() => {
    const isParentMaterial = parentStandardMaterialityData?.isMaterial;

    if ((isParentMaterial && !isMaterialSelected) || isStandardMandatory) return true;
    return materialData?.materialityAssessment[0]?.isMaterial;
  }, [parentStandardMaterialityData, isMaterialSelected, materialData]);

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => material?.attachmentBox ?? undefined,
    [material]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => material?.noteHistory ?? undefined,
    [material]
  );

  const parentMaterialMetrics = parentStandardMaterialityData?.materialMetrics ?? [];

  const DisclosureRequirements = useMemo(
    () =>
      mapDisclosureRequirements(
        materialMetrics,
        isGroupOwner,
        parentStandardMaterialityData?.materialDisclosureRequirements ?? [],
        isStandardMandatory,
        standard?.disclosureRequirementGroups ?? [],
        isMaterialData?.MaterialDisclosureRequirement,
        parentStandardMaterialityData?.isMaterial
      ),
    [standard, materialMetrics, isMaterialData]
  );

  const standardAdditionalTypes: AdditionalType[] = useMemo(() => {
    return (
      standard?.additionalTypes
        .map((aType) => aType.additionalType)
        .map((type) => ({
          reference: type.reference,
          title: type.title ?? '',
          description: type.description ?? '',
          learnMore: type.learnMore ?? '',
        })) ?? []
    );
  }, [standard]);

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      currentAuthor: user ?? undefined,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetMaterialStandardDocument_],
      isInputCard: false,
      flexDirection: 'column-reverse',
    };
  }, [attachmentBox, noteHistory, user, materialLoading]);

  useEffect(() => {
    if (materialMetrics.length > 0 && !initialLoad)
      handleSubmit((values) => handleAddMateriality(values, true))();
  }, [materialMetrics]);

  useEffect(() => {
    if (materialData) {
      if (materialMetrics.length === 0) setMaterialMetrics(material?.materialMetrics ?? []);
    }
    if ((materialData?.materialityAssessment ?? []).length > 0) {
      setAssessmentAdded(true);
    }
    if (
      initialLoad &&
      materialData?.materialityAssessment[0] &&
      materialData?.materialityAssessment[0].isMaterial
    ) {
      setCurrentStep(2);
    }
  }, [materialData]);

  const saveMateriality = (value: MaterialityState | null) => {
    handleAddMateriality({ material: value }, true);
    setInitialLoad(false);
    setAssessmentAdded(true);
    if (value === MaterialityState.gatherData) {
      upsertMateriality({
        variables: {
          objects: DisclosureRequirements.filter(
            (dr) => dr.materialityStatus !== MaterialityState.mandatory
          ).map((dr) => ({
            standardAssessmentId: materialData?.materialityAssessment[0]?.id,
            standardRef: standardRef,
            isMaterial: true,
            isDataGatheringOnly: true,
            disclosureRequirementRef: dr.drRef,
          })),
        },
        refetchQueries: [GetDisclosureRequirementMaterialityDocument_],
      });
    }
  };

  const defaultValue = useMemo(() => {
    if (material?.isDataGatheringOnly) return MaterialityState.gatherData;
    if (material?.isMaterial === null) return null;
    if (material?.isMaterial) return MaterialityState.material;
    if (material?.isMaterial === false) return MaterialityState.notMaterial;
    return null;
  }, [material]);

  const handleAddMateriality = (values: MaterialityFields, isAutosave: boolean) => {
    const allMaterialMetrics = addMandatoryMetrics(
      standard,
      materialMetrics,
      values.material === null ||
        values.material === MaterialityState.material ||
        values.material === MaterialityState.gatherData
    );
    addMateriality(
      values.material !== undefined ? values : { material: defaultValue },
      !!material,
      allMaterialMetrics,
      parentStandardMaterialityData?.materialMetrics ?? []
    );
    if (!isAutosave) {
      navigate(-1);
      if (isOnboarding)
        updateOnboardingStep({
          variables: {
            id: esrsAssessmentId,
            onboardingStep: 3,
          },
        });
    }
  };
  const handleOpenModal = (reference: string) => {
    setDrRef(reference);
    onOpen();
  };

  const submitMateriality = () => {
    handleSubmit((values) => handleAddMateriality(values, true))();
  };

  if (loading || materialLoading || parentDataLoading) return <Loader />;

  return (
    <VStack bg="bg.muted" h="fit-content" alignItems="start" p="8px" justifyContent="start">
      <Button variant="ghost" onClick={() => navigate(-1)} leftIcon={<ArrowLeftIcon />}>
        Back
      </Button>
      <Box
        width="60%"
        bg="bg.default"
        border="1px solid"
        borderColor="border.decorative"
        borderRadius="8px"
        p="24px"
        margin="auto"
      >
        <ContentLayout
          isLoading={loading}
          header={
            <HStack alignItems="flex-start" p="0px" justifyContent="space-between">
              <ContentHeader
                title={standard?.title ?? ''}
                subtitle={standard?.reference}
                props={{ padding: '0px 0px 8px', width: 'unset' }}
                height="fit-content"
              />
              {isStandardMandatory ? (
                <MaterialityStatusBox materiality={MaterialityState.mandatory} />
              ) : (
                <MaterialityStatusTag
                  isAssessed={assessmentAdded}
                  isMaterial={material?.isMaterial}
                  isDataGather={material?.isDataGatheringOnly ?? false}
                  isMandatory={isStandardMandatory}
                />
              )}
            </HStack>
          }
          padChildren={false}
        >
          {!!standardAdditionalTypes.length && (
            <Box mt="12px">
              <AdditionalTypesGroup
                tagsWithHelp={standardAdditionalTypes.map((aType) => {
                  return {
                    reference: aType.reference,
                    title: aType.title,
                    helpText: aType.description,
                  };
                })}
              />
            </Box>
          )}

          {hasParent && !isStandardMandatory && (
            <Box mt="20px">
              <ParentInfoBox
                status={
                  parentStandardMaterialityData
                    ? parentStandardMaterialityData?.isMaterial
                      ? MaterialityState.material
                      : MaterialityState.notMaterial
                    : MaterialityState.toAssess
                }
              />
            </Box>
          )}
          {isStandardMandatory && (
            <Infobox
              status="neutral"
              title="Mandatory"
              description="This topic is mandatory for all companies"
              icon={<LockedIcon color="text.muted" mt="3px" />}
              closable={false}
            />
          )}

          <VStack alignItems="flex-start" pt={isStandardMandatory ? '32px' : '28px'}>
            <VStack
              width="100%"
              alignItems="flex-start"
              pl="0px"
              pr={isStandardMandatory ? '0px' : '16px'}
              ml={isStandardMandatory ? '0px' : '16px'}
              mb="32px"
              spacing="24px"
            >
              <Box position="relative">
                <form
                  onSubmit={handleSubmit((values) => handleAddMateriality(values, false))}
                  id="add-materiality"
                >
                  <VStack alignItems="start" gap="0px">
                    {standard?.isTopical && (
                      <>
                        <StandardMateriality
                          control={control}
                          currentStep={currentStep}
                          defaultValue={defaultValue}
                          isMaterial={material?.isMaterial}
                          setCurrentStep={setCurrentStep}
                          saveMateriality={saveMateriality}
                        />
                        <AddReasoning
                          allowReviewMetrics={allowReviewMetrics ?? false}
                          attachmentBox={attachmentBox}
                          currentStep={currentStep}
                          isAttachmentDrawerOpen={isAttachmentDrawerOpen}
                          onAttachmentDrawerClose={onAttachmentDrawerClose}
                          setCurrentStep={setCurrentStep}
                          showDocumentation={showDocumentation}
                        />
                      </>
                    )}
                    {allowReviewMetrics && (
                      <AssessMetrics
                        DisclosureRequirements={DisclosureRequirements}
                        currentStep={currentStep}
                        isStandardMandatory={isStandardMandatory}
                        handleOpenModal={handleOpenModal}
                        hasParent={hasParent}
                        isGroupOwner={isGroupOwner}
                        parentData={parentData}
                      />
                    )}
                  </VStack>
                </form>
              </Box>
            </VStack>
          </VStack>
          {isOpen && (
            <MetricsMaterialityModal
              materialMetrics={materialMetrics}
              setMaterialMetrics={setMaterialMetrics}
              isOpen={isOpen}
              setInitialLoad={setInitialLoad}
              disclosureRequirement={DisclosureRequirements.find((dr) => dr.drRef === drRef)}
              isGroupOwner={isGroupOwner}
              submitMateriality={submitMateriality}
              parentMetrics={parentMaterialMetrics}
              assessmentId={materialData?.materialityAssessment[0]?.id}
              hasParent={hasParent}
              disableMaterial={defaultValue === MaterialityState.gatherData}
              onClose={onClose}
            />
          )}
        </ContentLayout>
      </Box>
    </VStack>
  );
};
