import { useGetReportingUnitsQuery, useGetMaterialStandardQuery } from 'models';
import { useMemo } from 'react';
export const COMPANY_LEVEL = 'Company level';

export type SelectedMetric = {
  title?: string;
  description?: string;
  reference?: string;
  shortTitle?: string;
  tags?: { type: string }[];
  isDisclosure?: boolean;
};

export const useAssessmentReportingUnits = (assessmentId: string) => {
  const { data, loading } = useGetReportingUnitsQuery({
    variables: {
      assessmentId,
    },
  });

  const reportingUnitAssessments = useMemo(() => data?.reportingUnits ?? [], [data]);

  return { reportingUnitAssessments, loading };
};

export const useMaterialStandardId = (standardRef: string, esrsAssessmentId: string) => {
  const { data, loading } = useGetMaterialStandardQuery({
    variables: { standardRef: standardRef ?? '', assessmentId: esrsAssessmentId },
    skip: !standardRef || !esrsAssessmentId,
  });
  const companyAssessmentId = useMemo(
    () => data?.materialityAssessment?.[0]?.id ?? undefined,
    [data]
  );
  const parentAssessmentId = useMemo(
    () =>
      data?.materialityAssessment?.[0]?.esrsAssessment.parentData?.materialStandards?.find(
        (ms) => ms.standardRef === standardRef
      )?.id ?? undefined,
    [data, standardRef]
  );
  const isDataGatheringOnly = useMemo(
    () => data?.materialityAssessment?.[0]?.isDataGatheringOnly,
    [data]
  );
  return { companyAssessmentId, parentAssessmentId, isDataGatheringOnly, loading };
};
