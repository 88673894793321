import { FormField, Input } from 'Atoms';
import { useCompanyFilesQuery } from 'models';
import { useMemo } from 'react';
import { Control, Controller, FieldValues, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentCompanyId } from 'utils/hooks';

export const FileNameController = <T extends FieldValues>({
  control,
}: {
  control: Control<T, any>;
}) => {
  const { companyId } = useCurrentCompanyId();
  const { data: companyFileData } = useCompanyFilesQuery({
    variables: {
      companyId: companyId,
    },
  });
  const companyFiles = useMemo(() => {
    return companyFileData?.files;
  }, [companyFileData]);
  const { errors, dirtyFields } = useFormState({ control });
  const { t } = useTranslation('files');

  const checkDuplicateFileName = (val: string) => {
    if ('title' in dirtyFields)
      return (
        !companyFiles?.find((file) => file.title === val.trim()) ||
        t('files:fileRenameModal.existingNameValidation', { name: val })
      );
    return true;
  };

  return (
    <Controller<T, any>
      name="title"
      control={control}
      rules={{
        required: t('files:title.required'),
        validate: {
          isValid: (val) => !!val && val !== '' && val.trim().length !== 0,
          nameIsUnique: (val) => checkDuplicateFileName(val),
        },
      }}
      render={({ field }) => (
        <FormField
          label={t('files:title.rename')}
          id="title"
          isRequired
          isInvalid={!!errors.title}
          error={errors.title?.message?.toString()}
        >
          <Input
            onBlur={field.onBlur}
            width="100%"
            ref={field.ref}
            value={field.value}
            onChange={field.onChange}
          />
        </FormField>
      )}
    />
  );
};
