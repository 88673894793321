import { useGetEsrsMetricQuery } from 'models';
import { useMemo } from 'react';
import { AdditionalType } from '../..';

export const useEsrsMetricData = (metricRef: string) => {
  const { data: esrsMetricData, loading: metricDataLoading } = useGetEsrsMetricQuery({
    variables: {
      metricRef,
    },
    skip: !metricRef,
  });

  const metric = useMemo(() => esrsMetricData?.metric, [esrsMetricData]);

  const metricAdditionalTypes: AdditionalType[] = useMemo(() => {
    return (
      metric?.additionalTypes
        .map((type) => type.additionalType)
        .map((aType) => ({
          reference: aType.reference,
          title: aType.title ?? '',
          description: aType.description ?? '',
          learnMore: aType.learnMore ?? '',
        })) ?? []
    );
  }, [metric]);

  const metricRefNumber = useMemo(() => {
    const firstLevel = metric?.firstLevel ?? '';
    const secondLevel = metric?.secondLevel ?? '';
    const order = metric?.order;

    if (metric?.isAR) return `AR ${order} ${firstLevel} ${secondLevel}`.trim();

    return `${order} ${firstLevel} ${secondLevel}`.trim();
  }, [metric]);

  return { metric, metricRefNumber, metricAdditionalTypes, metricDataLoading };
};
