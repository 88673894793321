import { Box, HStack } from '@chakra-ui/react';
import { GetTargetsQuery_, useGetTargetsSubsidiariesQuery } from 'models';
import { HelpTooltip, Select } from 'Molecules';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { components } from 'react-select';
import { Typography } from 'Tokens';

export const MetricsSelector = ({
  metricOptions,
  onChange,
  value,
  isGroup,
  allTargets,
  localAnswers,
  targetId,
  isDisabled,
}: {
  metricOptions: { value: string; label: string }[];
  onChange: (p: any) => void;
  value: any;
  isGroup: boolean;
  allTargets?: GetTargetsQuery_['targets'];
  localAnswers: any;
  targetId?: string;
  isDisabled: boolean;
}) => {
  const { standardRef = '', esrsAssessmentId = '', disclosureRequirement = '' } = useParams();

  const { data: subsidiariesData } = useGetTargetsSubsidiariesQuery({
    variables: { standardRef, esrsAssessmentId },
    skip: !isGroup,
  });

  const allSubTargetsMetrics = useMemo(
    () =>
      isGroup
        ? subsidiariesData?.esrs?.subsidiaries
            .flatMap((s) =>
              s.materialStandards.flatMap((m) =>
                m.targets.filter(
                  (d) =>
                    d.disclosureRequirementRef === disclosureRequirement &&
                    d.parentTargetId !== targetId
                )
              )
            )
            .flatMap((m) => m.metricRef)
        : [],
    [isGroup, subsidiariesData]
  );

  const Option = (props: any) => {
    const isOptionDisabled =
      (allTargets?.some((target) => target.metricRef === props.value) ||
        allSubTargetsMetrics?.includes(props.value)) &&
      !props.isSelected;

    const isDisabledBySub =
      isOptionDisabled &&
      allSubTargetsMetrics?.includes(props.value) &&
      !allTargets?.some((target) => target.metricRef === props.value);

    return (
      <components.Option {...props}>
        {isOptionDisabled ? (
          <HStack justifyContent="space-between">
            <Typography variant="bodyStrong" color="text.hint">
              {props.label}
            </Typography>
            <HelpTooltip
              label={
                isDisabledBySub
                  ? 'This metric is already used by at least 1 subsidiary'
                  : 'This metric is already used for another target'
              }
            />
          </HStack>
        ) : (
          <Typography variant="bodyStrong" color={props.isSelected ? 'white' : 'text.muted'}>
            {props.label}
          </Typography>
        )}
      </components.Option>
    );
  };

  return (
    <Box width="450px">
      <Select<{ value: string; label: string }>
        value={metricOptions.filter((m) => value === m.value)}
        options={metricOptions}
        onChange={(val) => {
          if (val?.value) onChange({ ...localAnswers, metricRef: val?.value });
        }}
        placeholder="Select metric"
        isDisabled={isDisabled}
        components={{
          Option: Option,
        }}
        isOptionDisabled={(option) =>
          ((allTargets?.some((target) => target.metricRef === option.value) ||
            allSubTargetsMetrics?.includes(option.value)) &&
            option.value !== localAnswers.metricRef) ??
          false
        }
      />
    </Box>
  );
};
