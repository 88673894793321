import { VStack } from '@chakra-ui/react';
import { useToast, useCurrentCompanyId } from 'utils/hooks';
import { DocumentationFile, useCompanyFilesQuery } from 'models';
import { Modal } from 'Molecules';
import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Tokens';
import { getNameExtension } from 'utils/files';
import { useUpsertFile } from '../Drive.hooks';
import { FileNameController } from './FileNameController';

export const FileRenameModal = ({
  onClose,
  isOpen,
  fileData,
  selectedFiles,
  setSelectedFiles,
}: {
  onClose: () => void;
  isOpen: boolean;
  fileData: File;
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
}) => {
  const toast = useToast();
  const { companyId } = useCurrentCompanyId();

  const fileTitle = getNameExtension(fileData?.name).name;

  const { data: companyFileData } = useCompanyFilesQuery({
    variables: {
      companyId: companyId,
    },
  });
  const companyFiles = useMemo(() => {
    return companyFileData?.files;
  }, [companyFileData]);

  const countCopies = (fileName: string): number => {
    const count =
      companyFiles?.filter((file) => file.title.endsWith(`[Copy] ${fileName}`)).length ?? 0;
    return count;
  };

  const { control, reset, handleSubmit } = useForm<{ title: string }>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      title: `[Copy] ${getNameExtension(fileData?.name).name}` ?? '',
    },
  });
  const { isValid } = useFormState({ control });
  const upsertFile = useUpsertFile();
  const { t } = useTranslation('files');
  const uploadFile = (data: { title: string }) => {
    upsertFile({
      file: fileData,
      description: '',
      source: '',
      title: data.title ?? '',
    })
      .then((file) => {
        if (file) {
          setSelectedFiles(selectedFiles.concat(file));
        }
        toast({ text: t('files:uploaded'), destroyAll: true });
      })
      .catch((error) => {
        console.error(error);
        toast({
          text: t('files:uploadFailedTryAgain'),
          variant: 'danger',
          destroyAll: true,
        });
      });
    onClose();
  };

  useEffect(() => {
    reset({
      title: `${'[Copy]'.repeat(countCopies(fileTitle ?? '') + 1)} ${fileTitle}` ?? '',
    });
  }, [isOpen, fileData]);

  return (
    <Modal
      onConfirm={handleSubmit(uploadFile)}
      isOpen={isOpen}
      onClose={onClose}
      title={t('files:fileRenameModal.title')}
      confirmButtonProps={{ disabled: !isValid, type: 'submit' }}
    >
      <VStack spacing={'16px'}>
        <Typography variant="body">
          {t('files:fileRenameModal.existingName', { name: getNameExtension(fileData?.name).name })}
        </Typography>
        <FileNameController control={control} />
      </VStack>
    </Modal>
  );
};
