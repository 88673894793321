import React, { useMemo } from 'react';
import { CreatableProps } from 'react-select/creatable';
import { Select, CreatableSelect } from 'Molecules';

type TagSelectorProps = {
  options?: string[];
  onChange: (value: string[]) => void;
  values: string[];
  placeholder: string;
  createNewLable?: string;
  size: 'md' | 'sm';
  width?: string;
};

export const TagSelector = ({
  options = [],
  onChange,
  values = [],
  placeholder,
  createNewLable,
  size = 'md',
  width,
  ...rest
}: TagSelectorProps & CreatableProps<{ value: string; label: string }, true, any>) => {
  return (
    <CreatableSelect<{ value: string; label: string }, true>
      isMulti
      value={values.map((v) => ({ value: v, label: v }))}
      isClearable={true}
      options={options.map((v) => ({ value: v, label: v }))}
      createOptionPosition="last"
      placeholder={placeholder}
      getNewOptionData={(input, label) => ({
        label: label as string,
        value: input,
        isNew: true,
      })}
      onChange={(tags) => onChange((tags ?? []).map(({ value: v }) => v))}
      formatCreateLabel={(input) => `${createNewLable}: ${input}`}
      size={size}
      {...rest}
    />
  );
};

export const SingleTagSelector = ({
  options = [],
  onChange,
  value,
  placeholder,
  isLoading = false,
  isClearable = true,
  isDisabled = false,
}: {
  options: { value: string; label: string }[];
  onChange: (value?: string) => void;
  value?: string;
  placeholder: string;
  isLoading?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
}) => {
  const currentValue = useMemo(() => {
    return options.find((o) => o.value === value);
  }, [value]);
  return (
    <Select<{ value: string; label: string }>
      value={currentValue}
      onChange={(tag) => onChange(tag?.value)}
      options={options}
      isClearable={isClearable}
      placeholder={placeholder}
      isLoading={isLoading}
      isDisabled={isDisabled}
    />
  );
};

export const NaceCodeSelector = ({
  options = [],
  onChange,
  values = [],
  placeholder,
  size = 'md',
  width,
  ...rest
}: TagSelectorProps) => {
  return (
    <Select<{ value: string; label: string }, true>
      isMulti
      value={values.map((v) => ({ value: v, label: v }))}
      isClearable={true}
      options={options.map((v) => ({ value: v, label: v }))}
      placeholder={placeholder}
      onChange={(tags) => onChange((tags ?? []).map(({ value: v }) => v))}
      size={size}
      {...rest}
    />
  );
};
