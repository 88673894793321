import { Box, HStack, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Avatar, EmptyState, Tag } from 'Atoms';
import { NothingFoundIllustration, Typography } from 'Tokens';
import { Table } from 'Molecules';
import { TFunction, useTranslation } from 'utils/translation';
import { useMemo } from 'react';
import { useUserData } from '@nhost/react';
import {
  CELSIA_SUPPORT_TAG,
  isCelsiaSupport,
  isServiceAccount,
  SERVICE_ACCCOUNT_TAG,
  PENDING_USER_TAG,
} from 'utils/users';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { TeamMember, TeamMembersFilterOption, UserStatus } from '..';

const getUserRole = (member: TeamMember, t: TFunction) => {
  const userExists = !!member.id;
  const celsiaSupport = isCelsiaSupport(member.email);
  const serviceAccount = isServiceAccount(member.email);

  if (userExists && celsiaSupport) {
    return CELSIA_SUPPORT_TAG;
  }
  if (userExists && serviceAccount) {
    return SERVICE_ACCCOUNT_TAG;
  }
  if (userExists) {
    return t('common:words.admin');
  }

  return PENDING_USER_TAG;
};

const getUserStatus = (member: TeamMember): UserStatus => {
  const isUserDisabled = !!member.disabled;
  const isUserPending = !member.id;
  if (isUserDisabled) {
    return UserStatus.Deactivated;
  }
  if (isUserPending) {
    return UserStatus.Pending;
  }
  return UserStatus.Active;
};

export const TeamMembersTable = ({
  members,
  setFilterBy,
}: {
  members: TeamMember[];
  setFilterBy: (s: TeamMembersFilterOption) => void;
}) => {
  const user = useUserData();
  const { t } = useTranslation('common');

  const columns = useMemo(() => {
    const cols: ColumnDef<TeamMember>[] = [
      {
        header: t('common:fields.userName.label'),
        accessorKey: 'name',
        cell: ({ row }) => {
          const active = row.original.id && !row.original.disabled;
          return (
            <HStack spacing="16px">
              <Avatar
                size="sm"
                name={row.original?.name ?? row.original?.email}
                color={active ? 'text.onAccent' : 'text.default'}
                backgroundColor={active ? 'bg.accent' : 'bg.disabled.accent'}
              />
              {active ? (
                <VStack width="100%" alignItems="flex-start">
                  <Typography variant="bodyStrong">
                    {row.original?.name} {user?.email === row.original.email ? '(You)' : ''}
                  </Typography>
                  <Typography variant="micro">{row.original?.email}</Typography>
                </VStack>
              ) : (
                <VStack width="100%" alignItems="flex-start" justifyContent="center">
                  <Typography variant="bodyStrong" color="text.hint">
                    {row.original?.email}
                  </Typography>
                </VStack>
              )}
            </HStack>
          );
        },
      },
      {
        header: t('common:fields.userRole.label'),
        id: 'role',
        cell: ({ row }) => (
          <Typography variant="bodyStrong" color="hint">
            {getUserRole(row.original, t)}
          </Typography>
        ),
      },
      {
        header: t('common:status'),
        id: 'status',
        cell: ({ row }) => (
          <Tag bg="white" border="1px solid" borderColor="border.default" color="text.default">
            {t(`common:memberStatus.${getUserStatus(row.original)}`)}
          </Tag>
        ),
      },
    ];
    return cols;
  }, [t]);

  if (!members.length) {
    return (
      <Box w="100%" h={`calc(100vh - ${TOP_MENU_HEIGHT})`} mt="16px">
        <EmptyState
          title={t('common:search.filter.emptyTitle')}
          description={t('common:search.filter.emptyDescription')}
          callToAction={{
            text: t('common:search.filter.emptyBtn'),
            variant: 'secondary',
            onClick: () => {
              setFilterBy(TeamMembersFilterOption.All);
            },
            leftIcon: <RefreshIcon color="inherit" />,
          }}
          icon={<NothingFoundIllustration boxSize="120px" />}
        />
      </Box>
    );
  }

  return <Table<TeamMember> columns={columns} data={members} />;
};
