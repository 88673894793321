import { RadioCardGroup } from 'Molecules';
import { Typography } from 'Tokens';
import { MaterialityState } from './MaterialityAssessment.d';

export enum MaterialityStatus {
  material = 'Material',
  notMaterial = 'Not material',
  toAssess = 'To assess',
  gatherData = 'Not material but gather data',
}

export const MaterialityRadioGroup = ({
  value,
  defaultValue,
  onChange,
  materialSubtitle,
  notMaterialSubtitle,
  isMaterialDisabled,
  isDr,
  width,
}: {
  value?: MaterialityState | null;
  defaultValue?: MaterialityState | null;
  onChange: (value: MaterialityState | null) => void;
  materialSubtitle?: React.ReactNode;
  notMaterialSubtitle?: React.ReactNode;
  isMaterialDisabled?: boolean;
  isDr?: boolean;
  width?: string;
}) => {
  return (
    <RadioCardGroup
      value={value ?? ''}
      defaultValue={defaultValue ?? undefined}
      direction="vertical"
      cardWidth={width ?? '490px'}
      withRadioBtn={true}
      isNullable={true}
      onChange={(val) => onChange(val as MaterialityState)}
      options={[
        {
          title: MaterialityStatus.material,
          disabled: isMaterialDisabled,
          subtitle:
            materialSubtitle ??
            (isDr ? (
              <Typography
                variant="body"
                color={isMaterialDisabled ? 'text.disabled' : 'text.muted'}
              >
                Select if this DR is <b> material </b>for your company
              </Typography>
            ) : (
              <Typography variant="body" color="text.muted">
                Select if this standard is <b> material </b>for the group company
              </Typography>
            )),
          value: MaterialityState.material,
        },
        {
          title: MaterialityStatus.notMaterial,
          subtitle:
            notMaterialSubtitle ??
            (isDr ? (
              <Typography variant="body" color="text.muted">
                Select if this DR is <b> not material</b>, and you <b>don’t</b> want to collect data
                in the tool
              </Typography>
            ) : (
              <Typography variant="body" color="text.muted">
                Select if this standard is <b>not material</b>, and you <b>don’t</b> want to collect
                data in the tool
              </Typography>
            )),
          value: MaterialityState.notMaterial,
        },
        {
          title: MaterialityStatus.gatherData,
          subtitle:
            materialSubtitle ??
            (isDr ? (
              <Typography variant="body" color="text.muted">
                Select if this DR is <b> not material </b>but you want to collect data in the tool
              </Typography>
            ) : (
              <Typography variant="body" color="text.muted">
                Select if this standard is <b> not material </b>but you want to collect data in the
                tool
              </Typography>
            )),
          value: MaterialityState.gatherData,
        },
      ]}
    />
  );
};
