import {
  HStack,
  VStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import { Tag, IconButton, Button, Checkbox, VisibleIf } from 'Atoms';
import { Typography } from 'Tokens';
import { useCurrentCompany } from 'utils/hooks';
import {
  CompanyAssessment,
  ReportingGroup,
  useReportingGroupsQuery,
  BAssessment,
  CalculatedActivityResult,
  BusinessUnitCachedResult,
  useFlagCompanyAssessmentAsDirtyMutation,
  TaxonomyScore,
} from 'models';
import { ContentLayout } from 'Molecules';
import { TaxonomyScoreChart } from 'Organisms';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { scoreSections } from 'utils/financials';
import { aggregateCachedResults } from 'utils/scores/taxonomyScore';
import { useCompanyAssessmentResults } from './Results.hooks';
import { useTranslation } from 'utils/translation';
import { TaxonomyResultsTableContainer } from 'Features/TaxonomyResultsTable/TaxonomyResultsTableContainer';
import { useOnClickOutside } from 'usehooks-ts';
import { isEqual } from 'lodash';
import { AdjustIcon, RefreshIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { HelpIcon } from 'Tokens/Icons/Status';
import { ChevronRightIcon } from 'Tokens/Icons/Direction';
import { TaxonomyExportMenu } from './TaxonomyExportMenu';

const GroupMenu = ({
  group,
  checked,
  setChecked,
}: {
  group: ReportingGroup;
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation('common');
  const isEmpty = group.businessUnits.length === 0;
  const isGroupSelected =
    group.businessUnits.every((bu) => checked.includes(bu?.businessUnit?.id)) && !isEmpty;

  return (
    <Popover placement="left" trigger="hover" returnFocusOnClose closeOnBlur id={group.id}>
      <PopoverTrigger>
        <HStack
          width="100%"
          spacing="8px"
          height="36px"
          justifyContent="space-between"
          _hover={{
            bg: 'bg.hover',
            cursor: 'pointer',
          }}
          bg={isGroupSelected ? 'bg.selected' : 'bg.default'}
        >
          <HStack spacing="8px" paddingX="4px">
            <Checkbox
              isDisabled={isEmpty}
              isChecked={isGroupSelected}
              onChange={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
                setChecked((prev) => {
                  if (isGroupSelected) {
                    return prev.filter(
                      (c) => !group.businessUnits.find((b) => b?.businessUnit?.id === c)
                    );
                  } else {
                    return [
                      ...prev,
                      ...group.businessUnits.map((bu) => bu?.businessUnit?.id ?? ''),
                    ];
                  }
                });
              }}
            />
            <Typography variant="bodyStrong">{group.name} </Typography>{' '}
          </HStack>
          <ChevronRightIcon color="inherit" />
        </HStack>
      </PopoverTrigger>
      <PopoverContent as={VStack} spacing="8px" padding="4px" width="200px" alignItems="stretch">
        {group.businessUnits.map((bu) => (
          <HStack
            key={`menu-${bu?.businessUnit?.id}`}
            as={HStack}
            spacing="8px"
            height="36px"
            paddingX="4px"
            _hover={{
              bg: 'bg.hover',
            }}
            bg={checked.includes(bu?.businessUnit?.id ?? '') ? 'bg.selected' : 'transparent'}
            onClick={() =>
              setChecked((prev) =>
                prev.includes(bu?.businessUnit?.id)
                  ? prev.filter((i) => i !== bu?.businessUnit?.id)
                  : [...prev, bu?.businessUnit?.id]
              )
            }
          >
            <Checkbox
              isChecked={checked.includes(bu?.businessUnit?.id)}
              onChange={() => {
                setChecked((prev) =>
                  prev.includes(bu?.businessUnit?.id)
                    ? prev.filter((i) => i !== bu?.businessUnit?.id)
                    : [...prev, bu?.businessUnit?.id]
                );
              }}
            />{' '}
            <Typography variant="bodyStrong">{bu?.businessUnit?.name}</Typography>
          </HStack>
        ))}
        {!group.businessUnits.length && (
          <VStack
            alignContent="center"
            paddingX="28px"
            paddingY="20px"
            width="202px"
            textAlign="center"
          >
            <Typography variant="bodyStrong">{t('common:words.empty')}</Typography>
            <Typography variant="detail">{t('common:results.reportingUnits')}</Typography>
          </VStack>
        )}
      </PopoverContent>
    </Popover>
  );
};

const FilterSelector = ({
  selected,
  onConfirm,
  groups,
  orphans,
}: {
  selected: string[];
  onConfirm: (vals: string[]) => void;
  groups: ReportingGroup[];
  orphans: BAssessment[];
}) => {
  const { t } = useTranslation('common');
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [checked, setChecked] = useState<string[]>([]);
  const ref = useRef<HTMLElement | null>(null);

  useOnClickOutside(ref, onClose);

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  return (
    <Popover closeOnBlur={true} placement="bottom-start" isOpen={isOpen} id="filter-popover">
      <PopoverTrigger>
        <Button
          leftIcon={<AdjustIcon color="inherit" />}
          variant="secondary"
          size="md"
          onClick={onToggle}
        >
          {t('common:words.filter')}
        </Button>
      </PopoverTrigger>
      <PopoverContent as={VStack} spacing="8px" width="276px" padding="12px 8px" ref={ref}>
        <HStack width="100%" justifyContent="space-between">
          <Typography variant="h4">{t('common:results.resultsFor')} </Typography>
          <Button variant="ghost" size="sm" onClick={() => setChecked([])}>
            {t('common:button.clearAll')}
          </Button>
        </HStack>
        {groups.map((group) => (
          <GroupMenu key={group.id} group={group} checked={checked} setChecked={setChecked} />
        ))}
        {orphans.map((bu) => (
          <HStack
            key={bu.id}
            width="100%"
            spacing="8px"
            paddingX="4px"
            height="36px"
            _hover={{
              bg: 'bg.hover',
            }}
            bg={checked.includes(bu?.businessUnit?.id ?? '') ? 'bg.selected' : 'transparent'}
            onClick={() => {
              setChecked((prev) =>
                prev.includes(bu?.businessUnit?.id)
                  ? prev.filter((i) => i !== bu?.businessUnit?.id)
                  : [...prev, bu?.businessUnit?.id]
              );
            }}
          >
            <Checkbox
              isChecked={checked.includes(bu?.businessUnit?.id)}
              onChange={() => {
                setChecked((prev) =>
                  prev.includes(bu?.businessUnit?.id)
                    ? prev.filter((i) => i !== bu?.businessUnit?.id)
                    : [...prev, bu?.businessUnit?.id]
                );
              }}
            />{' '}
            <Typography variant="bodyStrong">{bu?.businessUnit?.name}</Typography>
          </HStack>
        ))}
        <Button
          variant="primary"
          width="full"
          size="md"
          isDisabled={isEqual(checked, selected)}
          onClick={() => {
            onConfirm(checked);
            onClose();
          }}
        >
          {t('common:button.confirm')}
        </Button>
      </PopoverContent>
    </Popover>
  );
};
const EMPTY_SCORE: CalculatedActivityResult['score'] = scoreSections.reduce((agg, section) => {
  agg[section] = {
    total: 100,
    eligible: 0,
    aligned: 0,
    inProgress: 100,
  };
  return agg;
}, {} as { [key: string]: TaxonomyScore }) as CalculatedActivityResult['score'];

const ScoreTotal = ({ results }: { results: BusinessUnitCachedResult[] }) => {
  const { score, progress } = useMemo(() => {
    return aggregateCachedResults(results);
  }, [results]);

  return (
    <HStack
      justifyContent="center"
      width="100%"
      spacing="16px"
      borderWidth="1px"
      borderColor="border.decorative"
      borderRadius="8px"
      padding="16px"
    >
      {scoreSections.map((section) => {
        return (
          <TaxonomyScoreChart
            title={section}
            size="lg"
            progress={progress}
            score={score[section]}
            key={section}
          />
        );
      })}
    </HStack>
  );
};

export const Results = ({
  cAssessment,
  isGroup = false,
}: {
  cAssessment: CompanyAssessment;
  isGroup?: boolean;
}) => {
  const { t } = useTranslation('common');
  const { company } = useCurrentCompany();
  const [searchParams, setSearchParams] = useSearchParams();
  const [flagAssessment] = useFlagCompanyAssessmentAsDirtyMutation();

  const { data: groups } = useReportingGroupsQuery({
    variables: {
      cAssessmentId: cAssessment?.id,
    },
    skip: !cAssessment?.id,
  });

  const reportingGroups = useMemo(() => {
    return groups?.reportingGroups ?? [];
  }, [groups]);

  const { data: cAssessmentResults, loading } = useCompanyAssessmentResults(
    cAssessment?.id ?? '',
    false,
    isGroup
  );

  const allBusinessUnits = useMemo(() => {
    return (
      cAssessmentResults?.businessUnitResults.map((bu) => {
        const bAssessment = cAssessment?.bAssessments.find(
          (b) => b?.businessUnit?.id === bu?.businessUnit?.id
        );

        return {
          ...bAssessment,
          results: bu,
          group: reportingGroups.find((g) =>
            g.businessUnits.find((businessUnit) => businessUnit.bAssessmentId === bAssessment?.id)
          ),
        };
      }) ?? []
    );
  }, [cAssessmentResults]);
  const { selectedBusinessUnits, selectedIds } = useMemo(() => {
    const selectedBUs = searchParams.get('businessUnits')?.split('_') ?? [];
    const businessUnits = cAssessment?.bAssessments
      .filter((b) => selectedBUs?.includes(b.businessUnit?.id))
      .map((bA) => ({
        ...bA,
        group: reportingGroups.find((g) =>
          g.businessUnits.find((bu) => bu.bAssessmentId === bA.id)
        ),
        results: cAssessmentResults?.businessUnitResults.find((buR) => buR.bAssessmentId === bA.id),
      }));
    return { selectedBusinessUnits: businessUnits ?? [], selectedIds: selectedBUs ?? [] };
  }, [searchParams, cAssessmentResults]);

  const selectedBusinessUnitIds = useMemo(() => {
    return selectedIds.filter((id) => id);
  }, [selectedBusinessUnits]);

  const onSelectionChange = (businessUnitIds: string[]) => {
    setSearchParams({ businessUnits: businessUnitIds.length ? businessUnitIds.join('_') : '' });
  };

  return (
    <ContentLayout
      header={t('common:taxonomyScore')}
      isLoading={loading}
      loadingText={t('common:calculating')}
    >
      <VStack width="100%" alignItems="stretch" spacing="32px">
        <HStack justifyContent="space-between">
          <HStack>
            <Typography variant="body">{t('common:resultsFor')} </Typography>
            {selectedBusinessUnits.length ? (
              <HStack>
                {selectedBusinessUnits.map((bu) => (
                  <Tag
                    key={bu.id}
                    borderRadius="32px"
                    padding="8px 16px"
                    borderWidth="1px"
                    borderColor="border.default"
                    bg="bg.default"
                  >
                    <HStack justifyContent="space-between">
                      <HStack spacing="4px">
                        {bu.group && <Typography variant="detail">{bu.group.name}:</Typography>}
                        <Typography variant="bodyStrong">{bu?.businessUnit?.name}</Typography>
                      </HStack>
                      <IconButton
                        aria-label="remove-filter"
                        variant="ghost"
                        size="xs"
                        icon={<RemoveIcon color="inherit" />}
                        onClick={() =>
                          onSelectionChange(
                            selectedBusinessUnits
                              .filter((b) => b.businessUnit?.id !== bu.businessUnit?.id)
                              .map((b) => b.businessUnit?.id)
                          )
                        }
                      />
                    </HStack>
                  </Tag>
                ))}
                <Button
                  variant="ghost"
                  size="md"
                  leftIcon={<RemoveIcon color="inherit" />}
                  onClick={() => onSelectionChange([])}
                >
                  {t('common:button.clearAll')}
                </Button>
              </HStack>
            ) : (
              <HStack>
                <CompanyIcon boxSize="13.3px" />
                <Typography variant="bodyStrong">{company?.name}</Typography>
              </HStack>
            )}
          </HStack>
          <HStack spacing="8px">
            <VisibleIf condition={!isGroup}>
              <Button
                variant="secondary"
                leftIcon={<RefreshIcon color="inherit" />}
                onClick={() => {
                  flagAssessment({ variables: { cachedResultId: cAssessment?.cachedResultId } });
                }}
              >
                {t('common:results.refresh')}
              </Button>
              <IconButton
                aria-label="re-calculate-help"
                variant="ghost"
                icon={<HelpIcon color="inherit" />}
                size="sm"
                tooltipLabel={t('common:results.refreshTooltip')}
                tooltipPlacement={'bottom'}
              />
              <Divider
                orientation="vertical"
                width="2px"
                borderRadius="3px"
                background="border.decorative"
                height="20px"
              />
              <FilterSelector
                selected={selectedIds}
                groups={reportingGroups}
                orphans={cAssessment?.bAssessments.filter((b) => !b?.reportingGroup?.id) ?? []}
                onConfirm={(values) => onSelectionChange(values)}
              />
            </VisibleIf>
            <TaxonomyExportMenu
              company={company}
              cAssessmentResults={cAssessmentResults}
              assessment={cAssessment}
              isGroup={isGroup}
            />
          </HStack>
        </HStack>
        {selectedBusinessUnits && cAssessmentResults?.cachedResult && (
          <ScoreTotal
            results={
              selectedBusinessUnits?.length
                ? (selectedBusinessUnits
                    ?.map((bu) => bu?.results?.cachedResult)
                    .filter(Boolean) as BusinessUnitCachedResult[])
                : ([cAssessmentResults?.cachedResult] as BusinessUnitCachedResult[])
            }
          />
        )}
        <TaxonomyResultsTableContainer
          selectedBusinessUnitIds={selectedBusinessUnitIds}
          cAssessmentResults={cAssessmentResults}
        />
        {!isGroup && (
          <VStack spacing="16px" width="100%" alignItems="stretch">
            <Typography variant="h2">{t('common:results.breakdown')}</Typography>
            {(selectedBusinessUnits?.length ? selectedBusinessUnits : allBusinessUnits).map(
              (bu) => (
                <VStack
                  key={`${bu.id}-results`}
                  padding="16px"
                  spacing="16px"
                  borderRadius="8px"
                  borderWidth="1px"
                  borderColor="border.decorative"
                >
                  <VStack alignItems="flex-start" width="100%" spacing="4px">
                    <Typography variant="h3">{bu?.businessUnit?.name}</Typography>
                    <Typography variant="body">{bu.group?.name}</Typography>
                  </VStack>
                  <HStack justifyContent="center" width="100%" spacing="16px">
                    {scoreSections.map((section) => {
                      const score = bu.results?.cachedResult?.score ?? EMPTY_SCORE;
                      const progress = bu.results?.cachedResult?.progress;
                      return (
                        <TaxonomyScoreChart
                          progress={progress}
                          title={section}
                          size="lg"
                          score={{
                            total: 100,
                            ...score[section],
                          }}
                          key={`${section}-${bu.id}`}
                        />
                      );
                    })}
                  </HStack>
                </VStack>
              )
            )}
          </VStack>
        )}
      </VStack>
    </ContentLayout>
  );
};
