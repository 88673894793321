import { Checkbox as ChakraCheckbox, ChakraTheme, CheckboxProps } from '@chakra-ui/react';
import { CheckIcon } from 'Tokens/Icons/Status';

export const CHECKBOX_STATES = [
  'default',
  'defaultChecked',
  'isDisabled',
  'defaultChecked isDisabled',
];
export type CheckboxState = (typeof CHECKBOX_STATES)[number];

export const CheckboxTheme: ChakraTheme['components']['Checkbox'] = {
  baseStyle: {
    control: {
      bg: 'bg.default',
      borderRadius: '4px',
      borderWidth: '2px',
      borderColor: 'border.default',
      _hover: {
        bg: 'bg.default',
        borderColor: 'border.hover',
      },
      _checked: {
        bg: 'bg.selected.accent',
        border: 'none',
        _hover: {
          bg: 'bg.selected.accent',
        },
      },
      _indeterminate: {
        bg: 'bg.selected.accent',
        border: 'none',
        _hover: {
          bg: 'bg.selected.accent',
        },
      },
      _disabled: {
        border: 'none',
        bg: 'bg.disabled.accent',
        cursor: 'not-allowed',
        opacity: 0.7,
        _hover: {
          bg: 'bg.disabled.accent',
        },
        _checked: {
          border: 'none',
          bg: 'bg.disabled.accent',
          color: 'text.disabled',
          opacity: 0.7,
          _hover: {
            bg: 'bg.disabled.accent',
          },
        },
      },
    },
    label: {
      _disabled: {
        opacity: 1,
      },
    },
  },
  sizes: {
    md: {
      control: {
        h: '16px',
        w: '16px',
      },
      label: {
        lineHeight: '16px',
      },
      icon: { boxSize: '16px' },
    },
  },
};

export const Checkbox = ({
  variant,
  ...props
}: {
  variant?: string;
} & CheckboxProps) => {
  const { isChecked } = props;

  return !variant ? (
    <ChakraCheckbox {...props} icon={<CheckIcon p="1px" borderRadius="4px" />} />
  ) : (
    <ChakraCheckbox
      icon={
        isChecked ? (
          <CheckIcon bg={variant} p="0.5px" borderRadius="4px" />
        ) : (
          <CheckIcon p="0.5px" borderRadius="4px" />
        )
      }
      {...props}
    />
  );
};
