import { useUserId } from '@nhost/react';
import { useLogOutFromSessionMutation, useUserSessionsQuery } from 'models';
import { useMemo } from 'react';

export const useUserSessions = () => {
  const userId = useUserId();

  const [logOutFromSession, { error: errorLogOut, loading: isLoadingLogOut }] =
    useLogOutFromSessionMutation();
  const {
    data: dataSessions,
    error: errorSessions,
    loading: isLoadingSessions,
  } = useUserSessionsQuery({ variables: { userId }, skip: !userId });

  const activeSessions = useMemo(() => dataSessions?.activeSessions ?? [], [dataSessions]); // Todo: Show which one is the current session
  const inactiveSessions = useMemo(() => dataSessions?.inactiveSessions ?? [], [dataSessions]);

  return {
    activeSessions,
    inactiveSessions,
    isLoadingSessions,
    errorSessions,
    logOutFromSession,
    isLoadingLogOut,
    errorLogOut,
  };
};
