import { Box, HStack, VStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox, EmptyState, Tag } from 'Atoms';
import { useCurrentCompanyId } from 'utils/hooks';
import { orderBy } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { DocumentationFile } from 'models';
import { FileUploader, SearchInput, Table } from 'Molecules';
import { Menu } from 'Molecules/Menu';
import { UserAvatar } from 'Organisms';
import React, { useContext, useMemo, useState } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { NothingFoundIllustration, Typography } from 'Tokens';
import { formatDateTime } from 'utils/date';
import { getFileFilter, getNameExtension } from 'utils/files';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { useTranslation } from 'utils/translation';
import { AddNewFileButton } from './AddNewFileButton';
import { FileEditorContext, FileEditorContextProvider } from './FileEditor';
import { RefreshIcon } from 'Tokens/Icons/Function';

export type FileIds = DocumentationFile['id'][];

export const SearchBar = React.forwardRef(function SearchBar(
  {
    search,
    setSearch,
  }: {
    search: string;
    setSearch: (s: string) => void;
  },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { t } = useTranslation('files');
  const { companyId } = useCurrentCompanyId();
  return (
    <SearchInput
      width="100%"
      search={search}
      setSearch={(val) => {
        setSearch(val);
        mixpanel.track(TRACKING_EVENTS.DRIVE.SEARCH, { companyId, search: val });
      }}
      placeholder={t('files:searchPlaceholder')}
      ref={ref}
    />
  );
});

const FileListHeader = ({
  search,
  setSearch,
  selectedFiles,
  setSelectedFiles,
}: {
  search: string;
  setSearch: (s: string) => void;
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
}) => {
  // Need to add sort bar
  return (
    <HStack width="100%" justifyContent="space-between">
      <SearchBar search={search} setSearch={setSearch} ref={(node) => node?.focus()} />
      <AddNewFileButton selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
    </HStack>
  );
};

const FileListTable = ({
  search,
  setSearch,
  files,
  selectedFiles,
  setSelectedFiles,
  toggleFileSelected,
  drive,
}: {
  search: string;
  setSearch: (s: string) => void;
  files: DocumentationFile[];
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
  toggleFileSelected: (file: DocumentationFile) => void;
  drive: boolean;
}) => {
  const filter = useMemo(() => getFileFilter(search), [search]);
  const filteredFiles = useMemo(() => {
    return orderBy(files, ['title'], ['asc']).filter(filter);
  }, [filter, files]);
  const { t } = useTranslation('common');

  const allFilesChecked = useMemo(
    () => filteredFiles.length === selectedFiles.length,
    [filteredFiles, selectedFiles]
  );
  const someFilesChecked = useMemo(
    () => selectedFiles.length > 0 && !allFilesChecked,
    [selectedFiles, allFilesChecked]
  );

  const columns = useMemo(() => {
    const generalColumns: ColumnDef<DocumentationFile>[] = [
      {
        header: 'Title',
        id: 'title',
        accessorFn: (file) => file.title,
        cell: ({ row }) => {
          const { extension } = getNameExtension(row.original.storageFile?.name ?? '');

          return (
            <HStack>
              <Box boxSize="24px">
                <FileIcon
                  extension={extension}
                  {...defaultStyles[extension as keyof typeof defaultStyles]}
                  labelUppercase
                />
              </Box>

              <Typography noOfLines={1} textOverflow="ellipsis" variant="bodyStrong">
                {row.original.title}
              </Typography>
            </HStack>
          );
        },
      },
      {
        header: 'Labels',
        id: 'labels',
        accessorFn: (file) => file.tags,
        cell: ({ row }) => {
          return (
            <HStack>
              {row.original.tags.map((tag: string) => (
                <Tag key={tag} as={search ? 'button' : undefined}>
                  {tag}
                </Tag>
              ))}
            </HStack>
          );
        },
      },
      {
        header: 'Uploaded by',
        id: 'uploadedBy',
        accessorFn: (file) => file.uploadedBy,
        cell: ({ row }) => {
          return (
            <HStack>
              <UserAvatar user={row.original.uploadedBy} size="sm" />
              <Typography noOfLines={1} textOverflow="ellipsis" variant="bodyStrong">
                {row.original.uploadedBy?.displayName}
              </Typography>
            </HStack>
          );
        },
      },
      {
        header: 'Last updated',
        id: 'lastUpdated',
        accessorFn: (file) => file.updatedAt,
        cell: ({ row }) => {
          return (
            <Typography noOfLines={1} textOverflow="ellipsis" variant="bodyStrong">
              {formatDateTime(row.original.updatedAt)}
            </Typography>
          );
        },
      },
      {
        header: '',
        id: 'actions',
        cell: ({ row }) => {
          const Editor = useContext(FileEditorContext);
          const actions = Editor.actions(row.original);

          return (
            <Menu
              sections={[
                {
                  actions: actions,
                },
              ]}
            />
          );
        },
      },
    ];
    return [...generalColumns];
  }, [files, filteredFiles, selectedFiles, someFilesChecked, allFilesChecked]);

  if (!drive) {
    columns.unshift({
      header: () => {
        return (
          <Checkbox
            isChecked={allFilesChecked}
            isIndeterminate={someFilesChecked}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedFiles(files);
              } else {
                setSelectedFiles([]);
              }
            }}
          />
        );
      },
      id: 'checkbox',
      cell: ({ row }) => {
        return (
          <Checkbox
            isChecked={selectedFiles.find((val) => val.id === row.original.id) !== undefined}
            onChange={() => {
              return toggleFileSelected(row.original);
            }}
          />
        );
      },
    });
  }

  return filteredFiles.length ? (
    <Table columns={columns} data={filteredFiles} />
  ) : (
    <Box w="100%" h={`calc(100vh - 196px)`} mt="16px">
      <EmptyState
        title={t('common:search.filter.emptyTitle')}
        description={t('common:search.filter.emptyDescription')}
        callToAction={{
          text: t('common:search.filter.emptyBtn'),
          variant: 'secondary',
          onClick: () => {
            setSearch('');
          },
          leftIcon: <RefreshIcon color="inherit" />,
        }}
        icon={<NothingFoundIllustration boxSize="120px" />}
      />
    </Box>
  );
};

export const FileList = ({
  files,
  selectedFiles,
  setSelectedFiles,
  drive = false,
}: {
  files: DocumentationFile[];
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
  drive?: boolean;
}) => {
  const [search, setSearch] = useState('');
  const { companyId } = useCurrentCompanyId();

  function toggleFileSelected(file: DocumentationFile) {
    if (selectedFiles.find((val) => val.id === file.id) !== undefined) {
      return setSelectedFiles(selectedFiles.filter((val) => val.id !== file.id));
    } else {
      return setSelectedFiles(selectedFiles.concat(file));
    }
  }

  if (files.length > 0) {
    return (
      <FileEditorContextProvider>
        <VStack>
          <FileListHeader
            search={search}
            setSearch={(val) => {
              setSearch(val);
              mixpanel.track(TRACKING_EVENTS.DRIVE.SEARCH, {
                companyId,
                query: val,
              });
            }}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
          <FileListTable
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            files={files}
            search={search}
            setSearch={(val) => {
              setSearch(val);
              mixpanel.track(TRACKING_EVENTS.DRIVE.SEARCH, {
                companyId,
                query: val,
              });
            }}
            toggleFileSelected={toggleFileSelected}
            drive={drive}
          />
        </VStack>
      </FileEditorContextProvider>
    );
  }
  return (
    <Box w="100%" h={drive ? `calc(100vh - 152px)` : '100%'}>
      <FileUploader multiple={true} displayToast />
    </Box>
  );
};
