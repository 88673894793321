import {
  EsrsAssessmentDocument_,
  UserCompaniesQuery_,
  useUpsertEsrsReportingUnitMutation,
  useUpsertReportingUnitLineageMutation,
} from 'models';
import { useCallback } from 'react';

export type CompanyFields = {
  company: { id?: string; name: string; projectLeaderId?: string };
};

type NonNullableUserCompaniesType = NonNullable<UserCompaniesQuery_['data']>;
type UserCompaniesType = NonNullableUserCompaniesType['companies'];

export const useAddSubsidiaryCompanyBU = () => {
  const [upsertReportingUnitAssessment] = useUpsertEsrsReportingUnitMutation();
  const [upsertReportingUnitLineage] = useUpsertReportingUnitLineageMutation();

  return useCallback(
    (
      company: CompanyFields['company'],
      userCompanies: UserCompaniesType | undefined,
      reportingYear: number | undefined,
      assessmentId: string | undefined
    ) => {
      const subsidiaryEsrsAssessments = userCompanies?.find((c) => c.company.id === company?.id)
        ?.company.esrsAssessments;
      const currentSubsidiaryAssessment = subsidiaryEsrsAssessments?.find(
        (assessment) => assessment.reportingYear === reportingYear
      );
      if (subsidiaryEsrsAssessments?.length && !currentSubsidiaryAssessment) {
        upsertReportingUnitAssessment({
          variables: {
            reportingUnit: {
              name: 'Company level',
              lineageId: subsidiaryEsrsAssessments[0].reportingUnits.find((ru) => ru.isCompanyLevel)
                ?.reportingUnitLineage.id,
              assessmentId,
              isCompanyLevel: true,
            },
          },
          refetchQueries: [EsrsAssessmentDocument_],
        });
      }
      if (!subsidiaryEsrsAssessments?.length) {
        upsertReportingUnitLineage({
          variables: {
            reportingUnitLineage: {
              companyId: company.id,
              isCompanyLevel: true,
              reportingUnits: {
                data: [
                  {
                    name: 'Company level',
                    assessmentId,
                    isCompanyLevel: true,
                  },
                ],
              },
            },
          },
          refetchQueries: [EsrsAssessmentDocument_],
        });
      }
    },
    [upsertReportingUnitAssessment, upsertReportingUnitLineage]
  );
};
