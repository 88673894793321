import { uniqBy } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { useEffect } from 'react';
import { useCurrentCompanyId } from 'utils/hooks';
import {
  ArticlesQuery_,
  ArticleFieldsFragment_,
  TagFieldsFragment_,
  TagsWithArticlesQuery_,
  useArticlesQuery,
  useTagsWithArticlesQuery,
  useArticleBodySearchQuery,
  useArticleTitleSearchQuery,
} from 'models';

export type ArticlesQuery = ArticlesQuery_;
export type Article = ArticleFieldsFragment_;

export type Tag = TagFieldsFragment_;

export type TagsWithArticles = TagsWithArticlesQuery_;

export function getArticles() {
  const { data } = useArticlesQuery();
  return data?.Article;
}

export function useArticles() {
  const { data, loading } = useArticlesQuery();

  return { articles: data && data.Article ? data.Article : [], articlesAreLoading: loading };
}

export function useArticlesWithTags() {
  const { data, loading } = useTagsWithArticlesQuery();
  const result = data?.Tag ? data.Tag : [];
  return { tags: result, tagsAreLoading: loading };
}

export function useArticleSearch(query: string) {
  const { companyId } = useCurrentCompanyId();
  const bodySearch = useArticleBodySearchQuery({ variables: { q: `%${query}%` } });

  const bodySearchData = bodySearch.data && bodySearch.data.Article ? bodySearch.data.Article : [];

  const titleSearch = useArticleTitleSearchQuery({ variables: { q: `%${query}%` } });
  const isLoading = bodySearch.loading || titleSearch.loading;

  const titleSearchData =
    titleSearch.data && titleSearch.data.Article ? titleSearch.data.Article : [];

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.KNOWLEDGE_BASE.search, { companyId, query });
  }, [query]);
  return {
    searched: uniqBy(bodySearchData.concat(titleSearchData), (item) => item.id),
    searchIsLoading: isLoading,
  };
}
