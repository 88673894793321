import {
  Avatar,
  Box,
  Text,
  IconButton,
  HStack,
  createIcon,
  Collapse,
  IconButtonProps,
} from '@chakra-ui/react';
import { useUserSetting } from 'containers/Navigation';
import React from 'react';
import { RemoveIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { tnoop, useTranslation } from 'utils/translation';

export type JourneyHeroPureProps = {
  title: string;
  description: string;
  onClose: () => void;
  isOpen?: boolean;
};
const SpeechBubblePointer = createIcon({
  displayName: 'SpeechBubblePointer',
  viewBox: '0 0 16 16',
  d: 'M16 0C13.5768 4.84643 8.48201 7.76547 3.07554 7.40504L0 7.2L16 16L16 8V0Z',
});
export function JourneyHeroPure({
  title,
  description,
  onClose,
  isOpen = true,
}: JourneyHeroPureProps) {
  const { t } = useTranslation('common');
  return (
    <Collapse in={isOpen}>
      <Box
        bg="purple.light"
        px={['md', 'lg']}
        pb="md"
        pt="40px"
        position="relative"
        borderRadius="lg"
        mb="md"
      >
        <IconButton
          aria-label={t('common:journeyHero.hideHelp')}
          icon={<RemoveIcon color="inherit" />}
          onClick={onClose}
          size="sm"
          variant="ghost"
          position="absolute"
          top="md"
          color="black"
          right={['md', '30px']}
        />
        <HStack spacing={['md', '26px']} alignItems="flex-start">
          <Box pt={['sm', 0]}>
            <Avatar
              src="/images/hero.png"
              boxSize={['40px', '60px']}
              loading="eager"
              bg="transparent"
            />
          </Box>
          <Box flexGrow={1}>
            <Text fontSize="md" fontWeight="600" color="purple.main" mb="xs">
              {t('common:journeyHero.name')}
            </Text>
            <Box bg="white" borderRadius="lg" p="md" position="relative">
              <Text fontSize="lg" fontWeight="700" mb="xs">
                {t(title)}
              </Text>
              <Box
                className="custom-html"
                dangerouslySetInnerHTML={{ __html: t(description) }}
                sx={{ p: { my: 'xs' } }}
              />
              <SpeechBubblePointer position="absolute" top="8px" left="-16px" color="white" />
            </Box>
          </Box>
        </HStack>
      </Box>
    </Collapse>
  );
}
export const JOURNEY_HERO_CONTENT = {
  newBusinessUnit: {
    title: tnoop('common:journeyHero.content.newBusinessUnit.title'),
    description: tnoop('common:journeyHero.content.newBusinessUnit.description'),
  },
  activities: {
    title: tnoop('common:journeyHero.content.activities.title'),
    description: tnoop('common:journeyHero.content.activities.description'),
  },
  assessment: {
    title: tnoop('common:journeyHero.content.assessment.title'),
    description: tnoop('common:journeyHero.content.assessment.description'),
  },
  financials: {
    title: tnoop('common:journeyHero.content.financials.title'),
    description: tnoop('common:journeyHero.content.financials.description'),
  },
} as { [x: string]: { title: string; description: string } };

export function OpenHeroButton({
  section,
  ...props
}: Omit<IconButtonProps, 'aria-label'> & { section: string }) {
  const [closed, setClosed] = useUserSetting(`journey-${section}-closed`, false);
  const { t } = useTranslation('common');
  if (closed && JOURNEY_HERO_CONTENT[section])
    return (
      <IconButton
        icon={<HelpIcon color="inherit" />}
        onClick={() => setClosed(false)}
        variant="ghost"
        aria-label={t('common:journeyHero.showHelp')}
        {...props}
      />
    );
  return null;
}

export function JourneyHero({ section }: { section: string }) {
  const [closed, setClosed] = useUserSetting(`journey-${section}-closed`, false);
  if (JOURNEY_HERO_CONTENT[section] == null) return null;

  return (
    <JourneyHeroPure
      onClose={() => {
        setClosed(true);
      }}
      {...JOURNEY_HERO_CONTENT[section]}
      isOpen={!closed}
    />
  );
}
