import { Divider, HStack, VStack } from '@chakra-ui/react';
import { FilterTag } from 'Atoms';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { TaxonomyResultsTable } from './TaxonomyResultsTable';
import { CompanyAssessmentResults, ScoreSectionsEnum } from 'models';
import { useMemo } from 'react';
import { useResultsTableWithFilter } from './TaxonomyResultsTable.hooks';
import { FilteredTaxonomyResultTableRows } from './TaxonomyResultsTableTypes';

export const TaxonomyResultsTableContainer = ({
  cAssessmentResults,
  selectedBusinessUnitIds,
}: {
  cAssessmentResults: CompanyAssessmentResults | undefined;
  selectedBusinessUnitIds: string[];
}) => {
  const { t } = useTranslation('eu_table');
  const filters = Object.values(ScoreSectionsEnum);
  const {
    data: tableData,
    scoreSection,
    setScoreSection,
  } = useResultsTableWithFilter(cAssessmentResults, selectedBusinessUnitIds);
  const rows = useMemo(
    () => tableData?.rows ?? ([] as FilteredTaxonomyResultTableRows),
    [tableData, selectedBusinessUnitIds]
  );
  const substaintialContributionColumns = useMemo(
    () => tableData.substantialContributionColumnsToShow,
    [tableData, selectedBusinessUnitIds]
  );

  return (
    <VStack width="100%">
      <HStack alignContent="start" width="100%">
        <Typography variant="h2">{t('eu_table:detailsFor')}</Typography>
        {filters.map((filter) => (
          <FilterTag
            key={filter}
            size="sm"
            selected={filter == scoreSection}
            onClick={() => setScoreSection(filter)}
          >
            {t(`common:financials.${filter}`)}
          </FilterTag>
        ))}
      </HStack>
      <Divider />
      <TaxonomyResultsTable
        rows={rows}
        substantialContributionsColumns={substaintialContributionColumns}
        scoreSection={scoreSection}
      />
    </VStack>
  );
};
