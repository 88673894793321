import { VStack } from '@chakra-ui/react';
import { DateInput } from 'Atoms';
import {
  EsrsAssessmentDocument_,
  EsrsAssessmentsDocument_,
  useUpsertEsrsAssessmentMutation,
  useUpsertReportingUnitLineageMutation,
} from 'models';
import { Modal } from 'Molecules';
import { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'Tokens';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { EsrsAssessmentEditFields } from './EsrsAssessmentList';
import { useUserData } from '@nhost/react';

type EsrsAssessmentFormFields = {
  reportingYear: Date;
};

export const EsrsAssessmentModal = ({
  isOpen,
  onClose,
  editFields,
}: {
  isOpen: boolean;
  onClose: () => void;
  editFields?: EsrsAssessmentEditFields;
}) => {
  const { t } = useTranslation(['common', 'esrs']);
  const toast = useToast();
  const { companyId } = useCurrentCompanyId();
  const currentUser = useUserData();
  const navigate = useNavigate();
  const [upsertEsrsAssessment] = useUpsertEsrsAssessmentMutation();
  const [upsertReportingUnitLineage] = useUpsertReportingUnitLineageMutation();
  const { control, handleSubmit, reset } = useForm<EsrsAssessmentFormFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const { isValid } = useFormState({ control });

  useEffect(() => {
    reset({
      reportingYear: editFields ? editFields.reportingYear ?? new Date() : new Date(),
    });
  }, [isOpen]);

  const onSubmit = (values: EsrsAssessmentFormFields) => {
    upsertEsrsAssessment({
      variables: {
        esrsAssessment: {
          id: editFields?.id,
          reportingYear: new Date(values.reportingYear).getFullYear(),
          companyId: companyId,
          companySize: 'small',
          projectLeaderId: currentUser?.id,
        },
      },
      onError: (error) => {
        if (error.message.includes('Uniqueness violation')) {
          toast({
            text: 'An assessment for this year already exists',
            variant: 'danger',
          });
        } else {
          toast({
            text: 'Failed to create assessment',
            variant: 'danger',
          });
        }
      },
      onCompleted: (data) => {
        navigate(`${data?.insert_EsrsAssessment_one?.id}/onboarding`);
        onClose();
      },
      refetchQueries: [EsrsAssessmentsDocument_],
    }).then((res) => {
      upsertReportingUnitLineage({
        variables: {
          reportingUnitLineage: {
            companyId,
            isCompanyLevel: true,
            reportingUnits: {
              data: [
                {
                  name: 'Company level',
                  assessmentId: res.data?.insert_EsrsAssessment_one?.id,
                  isCompanyLevel: true,
                },
              ],
            },
          },
        },
        refetchQueries: [EsrsAssessmentDocument_],
      });
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('common:assessment.createAssessment')}
      confirmButtonProps={{
        type: 'submit',
        form: 'esrs-assessment-form',
        isDisabled: !isValid,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="esrs-assessment-form">
        <VStack spacing="16px">
          <VStack alignItems="stretch" width="100%">
            <Typography variant="bodyStrong">{t('esrs:assessment.form.reportingDate')}</Typography>
            <Controller
              name="reportingYear"
              control={control}
              render={({ field }) => (
                <DateInput
                  width="100%"
                  value={field.value}
                  setValue={(date) => field.onChange(date ?? new Date())}
                  showYearPicker
                  dateFormat="yyyy"
                />
              )}
            />
          </VStack>
        </VStack>
      </form>
    </Modal>
  );
};
