import { Box, HStack, MenuButton, useDisclosure, VStack } from '@chakra-ui/react';
import { AdditionalTypeTag, Alert, Button, Infobox } from 'Atoms';
import {
  GetDisclosureRequirementMaterialityDocument_,
  GetStandardMetricsQuery_,
  useGetStandardMetricsQuery,
  useUpsertDisclosureRequirementMaterialityMutation,
} from 'models';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader, Modal } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import {
  DR_STATES,
  DisclosureRequirement,
  MaterialMetric,
  MaterialMetricMapping,
  MaterialityFields,
  MaterialityState,
  ParentMaterialMetrics,
  AdditionalType,
} from './MaterialityAssessment.d';
import { ActiveStepLabels, StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { MaterialityRadioGroup, MaterialityStatus } from './MaterialityRadioGroup';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuSection } from 'Molecules/Menu';
import { Controller, useForm } from 'react-hook-form';
import { MetricsMaterialityTable } from './MetricsMaterialityTable';
import { WarningIcon } from 'Tokens/Icons/Status';
import { DisclosureRequirementInfo, ParentInfoBox } from './MaterialityUtils';

export const AssessAllBtn = ({
  updateMaterialityForAll,
  isDisabled,
}: {
  updateMaterialityForAll: (value: { isMaterial: boolean | null; dataGathering: boolean }) => void;
  isDisabled?: boolean;
}) => {
  const menuBtn = (
    <MenuButton
      as={Button}
      variant="ghost"
      size="md"
      isDisabled={isDisabled}
      rightIcon={<ChevronDownIcon boxSize="16px" />}
    >
      Assess all
    </MenuButton>
  );

  const sections: MenuSection[] = [
    {
      actions: [
        {
          id: 'material',
          title: MaterialityStatus.material,
          onClick: () => updateMaterialityForAll({ isMaterial: true, dataGathering: false }),
        },
        {
          id: 'notMaterial',
          title: MaterialityStatus.notMaterial,
          onClick: () => updateMaterialityForAll({ isMaterial: false, dataGathering: false }),
        },
        {
          id: 'gatherData',
          title: MaterialityStatus.gatherData,
          onClick: () => updateMaterialityForAll({ isMaterial: true, dataGathering: true }),
        },
      ],
    },
  ];
  return <Menu customMenuButton={menuBtn} sections={sections} />;
};

const LearnMoreStep = ({
  onDrawerOpen,
  noTitle,
  additionalTypes,
  setSelectedMetric,
}: {
  onDrawerOpen: () => void;
  noTitle?: boolean;
  additionalTypes?: AdditionalType[];
  setSelectedMetric: (metric: any) => void;
}) => {
  return (
    <VStack w="100%" spacing="8px" alignItems="start">
      {!noTitle && <Typography variant="h3">Understand Disclosure Requirement</Typography>}
      <VStack alignItems="start" spacing="20px">
        {!!additionalTypes?.length && (
          <HStack spacing="4px">
            {additionalTypes?.map((aType) => {
              return (
                <AdditionalTypeTag
                  key={aType.reference}
                  label={aType.title ?? aType.reference}
                  helpText={aType.description}
                />
              );
            })}
          </HStack>
        )}
        <Button
          w="111px"
          size="md"
          variant="secondary"
          onClick={() => {
            setSelectedMetric(undefined);
            onDrawerOpen();
          }}
        >
          Learn more
        </Button>
      </VStack>
    </VStack>
  );
};

export const MetricsMaterialityModal = ({
  isOpen,
  onClose,
  materialMetrics,
  isGroupOwner,
  setMaterialMetrics,
  parentMetrics,
  disclosureRequirement,
  setInitialLoad,
  assessmentId,
  disableMaterial,
  hasParent,
  submitMateriality,
}: {
  isOpen: boolean;
  onClose: () => void;
  materialMetrics: MaterialMetric[];
  isGroupOwner?: boolean;
  setInitialLoad: (val: boolean) => void;
  parentMetrics?: ParentMaterialMetrics[];
  disclosureRequirement: DisclosureRequirement | undefined;
  disableMaterial: boolean;
  setMaterialMetrics: (materialMetrics: MaterialMetric[]) => void;
  hasParent: boolean;
  assessmentId: string;
  submitMateriality: () => void;
}) => {
  const { standardRef } = useParams();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const [selectedMetric, setSelectedMetric] =
    useState<GetStandardMetricsQuery_['metrics'][number]>();
  const [upsertMateriality] = useUpsertDisclosureRequirementMaterialityMutation();
  const { control, setValue } = useForm<MaterialityFields>();
  const [isDRAssessed, setIsDRAssessed] = useState<boolean>(false);

  const hasMetrics = (disclosureRequirement?.metrics ?? []).length > 0;
  const status = useMemo(() => disclosureRequirement?.materialityStatus, [disclosureRequirement]);

  const [metricMapping, setMetricMapping] = useState<MaterialMetricMapping>();

  const { data: standardMetrics, loading: standardsLoading } = useGetStandardMetricsQuery({
    variables: { standard: standardRef ?? '' },
    skip: !standardRef,
  });
  const metrics = useMemo(
    () =>
      standardMetrics?.metrics.filter((metric) =>
        disclosureRequirement?.metrics.find((drMetric) => metric.reference === drMetric.reference)
      ) ?? [],
    [standardMetrics]
  );

  const selectedMetricFromDR = useMemo(() => {
    return disclosureRequirement?.metrics.find(
      (metric) => metric.reference === selectedMetric?.reference
    );
  }, [selectedMetric]);

  const metricAdditionalTypes: AdditionalType[] = useMemo(() => {
    return (
      selectedMetric?.additionalTypes
        .map((aType) => aType.additionalType)
        .map((type) => ({
          reference: type.reference,
          title: type.title ?? '',
          description: type.description ?? '',
          learnMore: type.learnMore ?? '',
        })) ?? []
    );
  }, [selectedMetric]);

  const defaultMapping: MaterialMetricMapping = metrics.reduce(
    (acc: MaterialMetricMapping, curr) => {
      return {
        ...acc,
        [curr.reference]: {
          dataCollection: null,
          isMaterial: true,
          isDataGatheringOnly: false,
        },
      };
    },
    {}
  );

  const transformData = (data: MaterialMetricMapping): MaterialMetric[] => {
    const transformedData: MaterialMetric[] = Object.entries(data).map((selection) => {
      return {
        metricRef: selection[0],
        dataCollection: null,
        isMaterial: selection[1].isMaterial ?? null,
        isDataGatheringOnly: selection[1].isDataGatheringOnly,
      };
    });
    return transformedData;
  };

  const mapMetrics = (data: MaterialMetric[]): MaterialMetricMapping => {
    return data.reduce((acc: MaterialMetricMapping, materialMetric: MaterialMetric) => {
      acc[materialMetric.metricRef] = {
        isMaterial: materialMetric.isMaterial ?? null,
        isDataGatheringOnly: materialMetric.isDataGatheringOnly,
      };
      return acc;
    }, {});
  };

  const updateMaterialityForAll = (value: {
    isMaterial: boolean | null;
    dataGathering: boolean;
  }) => {
    setMetricMapping((prev) => {
      const updatedMapping: MaterialMetricMapping = {
        ...defaultMapping,
        ...mapMetrics(materialMetrics),
      };

      const newmetrics = transformData({ ...defaultMapping, ...metricMapping } ?? {});

      newmetrics
        .filter((metric) =>
          disclosureRequirement?.metrics.find((m) => m.reference === metric.metricRef)
        )
        .forEach((metric) => {
          updatedMapping[metric.metricRef] = {
            ...prev?.[metric.metricRef],
            isMaterial: value.isMaterial,
            isDataGatheringOnly: value.dataGathering ?? false,
          };
        });
      return {
        ...prev,
        ...updatedMapping,
      };
    });
  };

  useEffect(() => {
    if (status !== null && status !== MaterialityState.toAssess) {
      setValue('material', status ?? null);
      setIsDRAssessed(true);
    }
  }, [status]);

  useEffect(() => {
    setMetricMapping({ ...mapMetrics(materialMetrics) });
  }, [materialMetrics]);

  const noMetricsMaterial = useMemo(() => {
    return disclosureRequirement?.metrics.every(
      (metric) => metricMapping && metricMapping[metric.reference]?.isMaterial === false
    );
  }, [metricMapping]);

  const showTable = useMemo(
    () =>
      control._formValues.material === MaterialityState.material ||
      control._formValues.material === MaterialityState.gatherData,
    [control._formValues.material]
  );
  const defaultValue = useMemo(() => {
    if (status === MaterialityState.gatherData) return MaterialityStatus.gatherData;
    if (status === null) return null;
    if (status === MaterialityState.material) return MaterialityStatus.material;
    if (status === MaterialityState.notMaterial) return MaterialityStatus.notMaterial;
    return null;
  }, [status]);

  const handleUpdateMateriality = (value: MaterialityState | null) => {
    const isMaterial =
      value === null
        ? null
        : value === MaterialityState.material || value === MaterialityState.gatherData;
    upsertMateriality({
      variables: {
        objects: {
          standardAssessmentId: assessmentId,
          standardRef: standardRef,
          isMaterial: isMaterial,
          isDataGatheringOnly: value === MaterialityState.gatherData,
          disclosureRequirementRef: disclosureRequirement?.drRef,
        },
      },
      refetchQueries: [GetDisclosureRequirementMaterialityDocument_],
    });
    setIsDRAssessed(true);
    updateMaterialityForAll({ isMaterial, dataGathering: value === MaterialityState.gatherData });
  };

  const handleConfirm = () => {
    setInitialLoad(false);

    if (status !== MaterialityState.mandatory && hasMetrics && control._formValues.material) {
      setMaterialMetrics(transformData(metricMapping ?? {}));
    }

    if (
      hasMetrics &&
      status === MaterialityState.mandatory &&
      disclosureRequirement?.metrics.length
    ) {
      // map all metrics to be material
      const data: MaterialMetricMapping = disclosureRequirement?.metrics.reduce(
        (acc: MaterialMetricMapping, curr) => {
          return {
            ...acc,
            [curr.reference]: {
              isMaterial: true,
              isDataGatheringOnly: false,
            },
          };
        },
        {}
      );
      setMaterialMetrics(transformData(data));
      submitMateriality();
    }
    onClose();
  };

  if (standardsLoading) return <Loader height="100%" />;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={disclosureRequirement?.title}
      size="xl"
      subtitle={'Disclosure requirement ' + disclosureRequirement?.drRef}
      onConfirm={handleConfirm}
      onCancel={onClose}
      hasFooter={
        status !== MaterialityState.mandatory && status !== MaterialityState.materialMandatory
      }
    >
      <VStack spacing="16px" alignItems="strech" width="100%">
        <VStack alignItems="strech" gap="8px">
          {disclosureRequirement?.parentMateriality &&
            hasParent &&
            status !== MaterialityState.mandatory && (
              <ParentInfoBox
                status={disclosureRequirement?.parentMateriality as MaterialityState}
              />
            )}
          <DisclosureRequirementInfo status={control._formValues?.material ?? status} />
        </VStack>
        {!hasMetrics &&
        status !== MaterialityState.mandatory &&
        status !== MaterialityState.materialMandatory ? (
          <VStack spacing="0px" alignItems="stretch">
            <Box borderLeft="1px dashed" pl="32px" ml="16px" borderColor="border.hover" pb="32px">
              <ActiveStepLabels
                title={'Understand Disclosure Requirement'}
                hasSteps={false}
                ml="63px"
              />
              <LearnMoreStep
                onDrawerOpen={onDrawerOpen}
                noTitle={true}
                setSelectedMetric={setSelectedMetric}
                additionalTypes={disclosureRequirement?.additionalTypes}
              />
            </Box>
            <Box pl="32px" ml="16px" borderColor="border.hover" pb="32px">
              <ActiveStepLabels
                title={'Assess Disclosure Requirement'}
                hasSteps={false}
                ml="63px"
              />
              <Controller
                name="material"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MaterialityRadioGroup
                      value={value}
                      isMaterialDisabled={disableMaterial}
                      onChange={(val) => {
                        onChange(val);
                        handleUpdateMateriality(val);
                      }}
                      isDr={true}
                      width="613px"
                    />
                  );
                }}
              />
            </Box>
          </VStack>
        ) : status === MaterialityState.mandatory ||
          status === MaterialityState.materialMandatory ? (
          <VStack gap="0px" alignItems="stretch" mb="16px">
            {!!disclosureRequirement?.metrics?.length ? (
              <Box borderLeft="1px dashed" pl="32px" ml="16px" borderColor="border.hover" pb="32px">
                <StepLabels title={'Understand Disclosure Requirement'} ml="63px" />
                <LearnMoreStep
                  onDrawerOpen={onDrawerOpen}
                  noTitle={true}
                  setSelectedMetric={setSelectedMetric}
                  additionalTypes={disclosureRequirement?.additionalTypes}
                />
              </Box>
            ) : (
              <LearnMoreStep onDrawerOpen={onDrawerOpen} setSelectedMetric={setSelectedMetric} />
            )}
            {!!disclosureRequirement?.metrics?.length && (
              <Box pl="32px" ml="16px">
                <StepLabels title={'Review metrics'} ml="63px" />
                <Box
                  width="100%"
                  border="1px solid"
                  borderColor="border.decorative"
                  borderRadius="8px"
                >
                  <MetricsMaterialityTable
                    parentMetrics={parentMetrics}
                    setSelectedMetric={setSelectedMetric}
                    onDrawerOpen={onDrawerOpen}
                    metricMapping={metricMapping}
                    isGroupOwner={isGroupOwner}
                    hasParent={hasParent}
                    setMetricMapping={setMetricMapping}
                    isDRAssessed={isDRAssessed}
                    disclosureRequirement={disclosureRequirement}
                    readOnly={true}
                  />
                </Box>
              </Box>
            )}
          </VStack>
        ) : (
          <VStack gap="0px" alignItems="stretch">
            <Box borderLeft="1px dashed" pl="32px" ml="16px" borderColor="border.hover" pb="32px">
              <ActiveStepLabels
                title={'Understand Disclosure Requirement'}
                hasSteps={false}
                ml="63px"
              />
              <LearnMoreStep
                onDrawerOpen={onDrawerOpen}
                noTitle={true}
                setSelectedMetric={setSelectedMetric}
                additionalTypes={disclosureRequirement?.additionalTypes}
              />
            </Box>

            <Box
              borderLeft={showTable || !control._formValues.material ? '1px dashed' : ''}
              pl="32px"
              ml="16px"
              borderColor="border.hover"
              pb="24px"
              gap="8px"
              alignItems="stretch"
            >
              <ActiveStepLabels
                title={'Assess Disclosure Requirement'}
                hasSteps={false}
                ml="63px"
              />
              <Controller
                name="material"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MaterialityRadioGroup
                      value={value ?? status}
                      defaultValue={status}
                      isMaterialDisabled={disableMaterial}
                      isDr={true}
                      onChange={(val) => {
                        onChange(val);
                        handleUpdateMateriality(val);
                      }}
                      width="613px"
                    />
                  );
                }}
              />
            </Box>
            {(showTable || !isDRAssessed) && (
              <VStack
                pl="32px"
                ml="16px"
                borderColor="border.hover"
                pb="24px"
                gap="8px"
                alignItems="stretch"
              >
                <ActiveStepLabels title={'Assess metrics'} hasSteps={false} ml="63px" />
                <MetricsMaterialityTable
                  parentMetrics={parentMetrics}
                  setSelectedMetric={setSelectedMetric}
                  onDrawerOpen={onDrawerOpen}
                  metricMapping={metricMapping}
                  isGroupOwner={isGroupOwner}
                  hasParent={hasParent}
                  setMetricMapping={setMetricMapping}
                  isDRAssessed={isDRAssessed}
                  disclosureRequirement={disclosureRequirement}
                />
              </VStack>
            )}
          </VStack>
        )}
      </VStack>
      <LearnMoreDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        description={
          (selectedMetric ? selectedMetric?.description : disclosureRequirement?.description) ?? ''
        }
        header={!selectedMetric ? disclosureRequirement?.title : undefined}
        customHeader={
          selectedMetric ? (
            <MetricLearnMoreHeader
              metricRef={selectedMetric?.reference ?? ''}
              tags={selectedMetricFromDR?.tags ?? []}
            />
          ) : undefined
        }
      />
    </Modal>
  );
};
