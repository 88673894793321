import React, { createContext, useMemo, useState } from 'react';
import {
  NavigationProps,
  GENERAL_MODULES,
  PORTFOLIO_COMPANY_MODULES_SECTION_1,
  PORTFOLIO_COMPANY_MODULES_SECTION_2,
  REGULAR_COMPANY_MODULES_SECTION_1,
  REGULAR_COMPANY_MODULES_SECTION_2,
  GROUP_COMPANY_MODULES_SECTION_1,
  GROUP_COMPANY_MODULES_SECTION_2,
} from '.';
import { useCompanyType, CompanyType } from 'utils/hooks';
import { VStack, HStack, StackProps, Stack } from '@chakra-ui/react';
import { SideMenu, TopMenu } from './pieces';
import { useUserActions } from './Navigation.hooks';
import { HelpMenu } from 'Organisms/HelpMenu';

const LayoutWrapper = (props: StackProps) => (
  <VStack
    {...props}
    height="100%"
    width="100%"
    marginInlineStart="0px !important"
    marginStart="0px !important"
    gap="0px"
  />
);
const PageWrapper = (props: StackProps) => (
  <HStack {...props} height="100vh" width="100vw" gap="0px" />
);

export const GlobalContext = createContext({
  walktourActive: false,
  showWalktourTooltip: false,
  helpCenterOpen: false,
  showInfoBoxes: false,
  setWalktourActive: (val: boolean) => {
    val;
  },
  setHelpCenterOpen: (val: boolean) => {
    val;
  },
  setShowInfoBoxes: (val: boolean) => {
    val;
  },
  setShowWalktourTooltip: (val: boolean) => {
    val;
  },
});

export const Navigation = ({ children }: NavigationProps) => {
  const { languageEditor } = useUserActions();
  const { companyType, isInGroups } = useCompanyType();
  const [helpCenterOpen, setHelpCenterOpen] = useState(false);
  const [walktourActive, setWalktourActive] = useState(false);
  const [showInfoBoxes, setShowInfoBoxes] = useState(false);
  const [showWalktourTooltip, setShowWalktourTooltip] = useState(false);

  const sideMenuItems = useMemo(() => {
    if (companyType === CompanyType.regular) {
      return [
        ...REGULAR_COMPANY_MODULES_SECTION_1,
        ...REGULAR_COMPANY_MODULES_SECTION_2.filter((section) =>
          section.key === 'groups' ? isInGroups : true
        ),
        ...GENERAL_MODULES,
      ];
    } else if (companyType === CompanyType.portfolio) {
      return [
        ...PORTFOLIO_COMPANY_MODULES_SECTION_1,
        ...PORTFOLIO_COMPANY_MODULES_SECTION_2,
        ...GENERAL_MODULES,
      ];
    }
    return [
      ...GROUP_COMPANY_MODULES_SECTION_1,
      ...GROUP_COMPANY_MODULES_SECTION_2.filter((section) =>
        section.key === 'groups' ? isInGroups : true
      ),
      ...GENERAL_MODULES,
    ];
  }, [companyType, isInGroups]);

  const context = {
    walktourActive: walktourActive,
    setWalktourActive: setWalktourActive,
    helpCenterOpen: helpCenterOpen,
    setHelpCenterOpen: setHelpCenterOpen,
    showInfoBoxes: showInfoBoxes,
    setShowInfoBoxes: setShowInfoBoxes,
    showWalktourTooltip: showWalktourTooltip,
    setShowWalktourTooltip: setShowWalktourTooltip,
  };

  return (
    <PageWrapper>
      <GlobalContext.Provider value={context}>
        <SideMenu items={sideMenuItems} />
        <LayoutWrapper>
          <TopMenu />
          <Stack
            mt="0px !important" // LayoutWrapper should be a box: https://github.com/chakra-ui/chakra-ui/issues/2578
            width="100%"
            overflow="hidden auto"
          >
            {children}
          </Stack>
          {languageEditor}
        </LayoutWrapper>
        <HelpMenu />
      </GlobalContext.Provider>
    </PageWrapper>
  );
};
