import { useTranslation } from 'utils/translation';
import { ContentHeader, ContentLayout } from 'Molecules';
import { Button, EmptyState } from 'Atoms';
import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { PaiIndicatorsIllustration, Typography } from 'Tokens';
import { useMemo, useState } from 'react';
import { EsrsAssessmentModal } from './EsrsAssessmentModal';
import { EsrsGroupAssessmentTable } from './EsrsGroupAssessmentTable';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { useGetEsrsAssessmentList } from './EsrsAssessment.hooks';
import {
  CompanyMetricAnswersDataType,
  EsrsDashboard,
  GroupMetricAnswersDataType,
  MaterialMetricType,
  MetricChartCard,
  useDashboardMetricsByStandards,
} from './EsrsDashboard';
import {
  GetDashboardMetricAnswersOnCompanyLevelQuery_,
  GetDashboardMetricAnswersOnGroupLevelQuery_,
} from 'models';

export type EsrsAssessmentEditFields = {
  id: string;
  reportingYear: Date;
};
export type CompanyDashboardDataType =
  GetDashboardMetricAnswersOnCompanyLevelQuery_['EsrsAssessment'];
export type GroupDashboardDataType = GetDashboardMetricAnswersOnGroupLevelQuery_['EsrsAssessment'];

export type EsrsAssessmentListProps = {
  name: string;
  materialMetric?: MaterialMetricType;
  companyMetricAnswersData?: CompanyMetricAnswersDataType;
  groupMetricAnswersData?: GroupMetricAnswersDataType;
  standardRef?: string;
  isMaterialForSubsidiary?: boolean;
  isMaterialForGroup?: boolean;
  assessmentId: string;
  allMaterialMetrics: MaterialMetricType[];
};

const EsrsAssessmentsEmptyState = ({
  onCallToActionClick,
}: {
  onCallToActionClick: () => void;
}) => {
  const { t } = useTranslation(['common', 'esrs']);
  return (
    <Box w="100%" h={`calc(100vh - 152px)`}>
      <EmptyState
        title={t('esrs:assessment.noReport')}
        description={t('esrs:assessment.noEsrsAssessmenttDescription')}
        callToAction={{
          text: t('common:assessment.createAssessment'),
          variant: 'primary',
          onClick: () => {
            onCallToActionClick();
          },
        }}
        icon={<PaiIndicatorsIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};

const DashboardSnippet = ({
  companyDashboardData,
  groupDashboardData,
  setIsDashboard,
  isGroup,
}: {
  companyDashboardData: CompanyDashboardDataType;
  groupDashboardData: GroupDashboardDataType;
  setIsDashboard: (param: boolean) => void;
  isGroup: boolean;
}) => {
  const dashboardDataMap = useDashboardMetricsByStandards(
    companyDashboardData,
    groupDashboardData,
    isGroup
  );

  const dashboardSnippetData = useMemo(
    () =>
      Array.from(dashboardDataMap.values())
        .map((assessmentDataMap) => Array.from(assessmentDataMap.values()).flat())
        .flat()
        .slice(0, 3),
    [dashboardDataMap]
  );
  if (!dashboardSnippetData.length) return null;

  return (
    <VStack w="100%">
      <HStack width="100%" justifyContent="space-between">
        <Typography variant="h2">Dashboard</Typography>
        <Button
          variant="ghost"
          rightIcon={<ArrowNarrowRightIcon />}
          onClick={() => {
            setIsDashboard(true);
          }}
        >
          Full dashboard
        </Button>
      </HStack>
      <HStack spacing="0px" wrap="wrap" w="100%" alignItems="start">
        {dashboardSnippetData.map((metric) => (
          <MetricChartCard
            name={metric.name}
            materialMetric={metric.materialMetric}
            isGroup={isGroup}
            companyMetricAnswersData={metric.companyMetricAnswersData}
            groupMetricAnswersData={metric.groupMetricAnswersData}
            standardRef={metric.standardRef}
            isMaterialForGroup={!!metric.isMaterialForGroup}
            isMaterialForSubsidiary={!!metric.isMaterialForSubsidiary}
            assessmentId={metric.assessmentId}
            allMaterialMetrics={metric.allMaterialMetrics}
          />
        ))}
      </HStack>
    </VStack>
  );
};

export const EsrsAssessmentList = () => {
  const { t } = useTranslation(['common', 'esrs', 'assessment']);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isDashboard, setIsDashboard] = useState(false);

  const { esrsAssessments, companyDashboardData, groupDashboardData, isGroup, loading } =
    useGetEsrsAssessmentList();

  if (isDashboard) {
    return (
      <EsrsDashboard
        companyDashboardData={companyDashboardData}
        groupDashboardData={groupDashboardData}
        setIsDashboard={setIsDashboard}
        isGroup={isGroup}
      />
    );
  }

  return (
    <ContentLayout
      header={<ContentHeader title={t('esrs:assessment.header')} />}
      isLoading={loading}
    >
      {esrsAssessments.length ? (
        <VStack alignItems="start" spacing="24px">
          <DashboardSnippet
            companyDashboardData={companyDashboardData}
            groupDashboardData={groupDashboardData}
            setIsDashboard={setIsDashboard}
            isGroup={isGroup}
          />
          <VStack alignItems="start" w="100%" spacing="4px">
            <HStack width="100%" justifyContent="space-between">
              <Typography variant="h2">Assessments</Typography>
              <Button onClick={onOpen}>{t('common:assessment.createAssessment')}</Button>
            </HStack>
            <EsrsGroupAssessmentTable esrsGroupAssessments={esrsAssessments} isGroup={isGroup} />
          </VStack>
        </VStack>
      ) : (
        <EsrsAssessmentsEmptyState onCallToActionClick={onOpen} />
      )}
      <EsrsAssessmentModal isOpen={isOpen} onClose={onClose} />
    </ContentLayout>
  );
};
