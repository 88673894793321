import { Box, HStack, VStack } from '@chakra-ui/react';
import { Alert, Button } from 'Atoms';
import { ContentLayout, Loader, TOP_MENU_HEIGHT_PLUS_PADDING } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import { Structure } from '../Assessment/Structure';
import { Actions } from './Actions/Actions';
import { Policies } from './Policies/Policies';
import { Targets } from './Targets/Targets';
import { useGetDRGroupData } from './DisclosureRequirements.hooks';
import { DREnums, DRTypes, MetricViewEnums } from './Requirement';
import { AggregatedMetrics } from './Metrics';
import { CompanyTopDownIcon } from 'Tokens/Icons/Custom';

const DisclosureRequirement = ({ type }: { type: DRTypes }) => {
  if (type === DREnums.policy) {
    return <Policies />;
  }
  if (type === DREnums.target) {
    return <Targets />;
  }
  if (type === DREnums.metric) {
    return <AggregatedMetrics />;
  }
  if (type === DREnums.action) return <Actions />;
  return <AggregatedMetrics />;
};

export const DisclosureRequirementGroup = () => {
  const navigate = useNavigate();

  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    drType,
    disclosureRequirementRef = '',
    view,
  } = useParams();
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>();
  const {
    standard,
    isDataGatheringOnly,
    sortedRequirements,
    targetsCount,
    actionsCount,
    policiesCount,
    loading,
  } = useGetDRGroupData();

  const handleNodeSelect = (nodeKey: string) => {
    const [type, selectedDRRef] = nodeKey?.split('_');
    if (type === DREnums.metric) {
      navigate(
        `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/${type}/${selectedDRRef}/${
          view ?? MetricViewEnums.overview
        }`
      );
    } else
      navigate(
        `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/${type}/${selectedDRRef}`
      );
    setSelectedNodeKey(nodeKey);
  };

  useEffect(() => {
    if (!drType && sortedRequirements[0]?.length > 1) {
      const firstNodeKey = sortedRequirements[0][1].key;
      handleNodeSelect(firstNodeKey);
    }
    const type = selectedNodeKey?.split('_')?.[0];

    const isNodeNotSelected = !!drType && !!disclosureRequirementRef && type !== drType;
    if (isNodeNotSelected) {
      setSelectedNodeKey(`${drType}_${disclosureRequirementRef}`);
    }
  }, [sortedRequirements, drType]);

  const requirements = useMemo(() => {
    return sortedRequirements.map((reqArray) => {
      return reqArray.map((req) => {
        let counter;
        const key = req.key.split('_')[0] as DREnums;
        if (key === DREnums.target) {
          counter = targetsCount;
        } else if (key === DREnums.policy) {
          counter = policiesCount;
        } else {
          counter = actionsCount;
        }
        if (Object.values(DREnums).includes(key) && key !== DREnums.metric) {
          return {
            ...req,
            counter,
          };
        }
        return req;
      });
    });
  }, [targetsCount, actionsCount, policiesCount, sortedRequirements]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box
      minH={`calc(100vh - ${TOP_MENU_HEIGHT_PLUS_PADDING})`}
      width="100%"
      alignItems="flex-start"
    >
      <HStack borderTopColor="border.decorative" height={`calc(100vh - 64px)`} spacing="16px">
        <VStack
          height="100%"
          borderRight="1px solid"
          borderRightColor="border.decorative"
          overflow="hidden auto"
        >
          <Structure
            width="320px"
            header={
              <VStack p="10px 16px 8px" spacing="24px" alignItems="start">
                <Button
                  variant="ghost"
                  onClick={() => navigate(`/${companyId}/esrs/${esrsAssessmentId}`)}
                  leftIcon={<ArrowLeftIcon />}
                >
                  Back
                </Button>
                <VStack spacing="16px" alignItems="stretch" w="100%">
                  <HStack alignItems="start" justifyContent="space-between">
                    <VStack spacing="4px" alignItems="start">
                      <Typography variant="h2">{standard?.title}</Typography>
                      <Typography variant="body" color="text.muted">
                        {standard?.reference}
                      </Typography>
                    </VStack>
                  </HStack>
                  <Alert
                    status="neutral"
                    title={
                      isDataGatheringOnly ? 'Not material but gathering data' : 'Material topic'
                    }
                    textColor="text.default"
                    closable={false}
                    customIcon={<CompanyTopDownIcon />}
                    width="100%"
                  />
                </VStack>
                <Typography variant="overline" color="text.hint" textTransform="uppercase">
                  {'Disclosure requirements'}
                </Typography>
              </VStack>
            }
            nodes={requirements}
            selectedNodeKey={selectedNodeKey ?? ''}
            onSelectNode={handleNodeSelect}
            size="sm"
          />
        </VStack>
        <VStack
          height="100%"
          width="calc(100% - 300px)"
          p={drType === DREnums.metric ? '12px 16px 16px 18px' : '12px 16px 16px'}
          overflow="hidden auto"
        >
          <ContentLayout header={false} padChildren={false} contentHeight="100%">
            <DisclosureRequirement type={drType as DRTypes} />
          </ContentLayout>
        </VStack>
      </HStack>
    </Box>
  );
};
