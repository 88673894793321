import React, { Fragment, useMemo, useRef, useState } from 'react';
import { BreakdownStatus, TableMetricData, TagStatus } from './DataCollection.d';
import { Box, HStack } from '@chakra-ui/react';
import { Tag, Tooltip } from 'Atoms';
import { CornerDownRightIcon } from 'Tokens/Icons/Direction';
import { WarningIcon } from '@chakra-ui/icons';
import { Typography, colors } from 'Tokens';

export const BreakDownTag = ({
  metric,
  isGroupCompany,
}: {
  metric: TableMetricData;
  isGroupCompany: boolean;
}) => {
  const BREAKDOWN_STATES = useMemo(() => {
    return {
      required: {
        text: 'Required',
        variant: isGroupCompany ? 'default' : 'warning',
      },
      added: {
        text: 'Added',
        variant: 'selected',
      },
      requested: {
        text: 'Requested',
        variant: 'selected',
      },
      available: {
        text: 'Available',
        variant: 'default',
      },
      notApplicable: {
        text: 'N/A',
        variant: 'default',
      },
    };
  }, [isGroupCompany]);

  const addedBreakdowns = useMemo(
    () =>
      metric.materialByCompany[0]?.materialMetricTags.filter(
        (tag) => tag.materialTagValues.length > 0
      ) ?? [],
    [metric]
  );
  const requiredBreakdowns = useMemo(
    () => metric.adminPanelTags.filter((tag) => !tag.isOptional) ?? [],
    [metric]
  );
  const remainingBreakdowns = useMemo(
    () =>
      requiredBreakdowns.filter(
        (required) => !addedBreakdowns.find((added) => added.tagType === required.type)
      ),
    [addedBreakdowns, requiredBreakdowns]
  );

  const parentMetricHasTags = useMemo(
    () =>
      !!metric?.parentMetric?.adminPanelTags.length ||
      !!metric?.parentMetric?.materialByCompany[0]?.materialMetricTags.length ||
      !!metric?.parentMetric?.materialByParent[0]?.materialMetricTags.length,
    [metric]
  );

  const getStatus = (row: TableMetricData | undefined): TagStatus => {
    const allTags = row?.adminPanelTags;
    const requiredTags = allTags?.filter((tag) => !tag.isOptional);
    const addedTags = isGroupCompany
      ? row?.materialByCompany[0]?.materialMetricTags?.filter(
          (tag) => !requiredTags?.find((reqTag) => reqTag.type === tag.tagType)
        )
      : row?.materialByCompany[0]?.materialMetricTags?.filter(
          (tag) => tag.materialTagValues.length > 0
        );

    const required = requiredTags?.length;
    const added = addedTags?.length;
    const available = allTags?.filter(
      (tag) => tag.isOptional && !addedTags?.find((y) => y.tagType === tag.type)
    ).length;

    const parentHasTags =
      !!row?.parentMetric?.adminPanelTags.length ||
      !!row?.parentMetric?.materialByCompany[0]?.materialMetricTags.length ||
      !!row?.parentMetric?.materialByParent[0]?.materialMetricTags.length;

    if (row?.isChild && parentHasTags) {
      return getStatus(row.parentMetric as TableMetricData);
    } else if (added) {
      return { state: BreakdownStatus.added, number: added };
    } else if (required) {
      return { state: BreakdownStatus.required, number: required };
    } else if (available) {
      return { state: BreakdownStatus.available, number: 0 };
    }
    return { state: BreakdownStatus.notApplicable, number: 0 };
  };

  const status = useMemo(() => getStatus(metric), [metric]);
  const parentMetricStatus = useMemo(
    () => getStatus(metric.parentMetric as TableMetricData),
    [metric]
  );

  const addedTagsRatio = useMemo(() => {
    const required = requiredBreakdowns.length ?? 0;
    const added = requiredBreakdowns.length - remainingBreakdowns.length ?? 0;
    return `${added}/${required}`;
  }, [requiredBreakdowns, remainingBreakdowns]);

  const showRemainingBreakdowns = useMemo(() => {
    return (
      !isGroupCompany &&
      !parentMetricHasTags &&
      !!remainingBreakdowns.length &&
      !!addedBreakdowns.length
    );
  }, [isGroupCompany, parentMetricHasTags, remainingBreakdowns, addedBreakdowns]);

  return (
    <HStack spacing="4px">
      <Tag
        borderRadius="4px"
        opacity={
          metric.isChild && parentMetricStatus.state !== BreakdownStatus.notApplicable ? 0.3 : 1
        }
        variant={BREAKDOWN_STATES[status.state].variant}
      >
        <HStack>
          {metric.isChild && parentMetricHasTags && (
            <>
              {status.state !== BreakdownStatus.available && (
                <CornerDownRightIcon color="inherit" />
              )}
            </>
          )}
          <>
            {BREAKDOWN_STATES[status.state].text}
            {status.state === BreakdownStatus.available ||
            status.state === BreakdownStatus.notApplicable
              ? ''
              : ` (${status.number})`}
          </>
        </HStack>
      </Tag>
      {showRemainingBreakdowns && (
        <Tooltip
          label={`You have added values to ${addedTagsRatio} required breakdowns. Go to the metrics' settings to add the rest`}
        >
          <Tag opacity={metric.isChild ? 0.3 : 1} borderRadius="4px" variant="warning">
            <HStack spacing="2px">
              <WarningIcon m={0} color="inherit" />
              <Typography variant="detailStrong" color="inherit">
                {remainingBreakdowns.length}
              </Typography>
            </HStack>
          </Tag>
        </Tooltip>
      )}
    </HStack>
  );
};

export const MetricNameWithTag = ({
  name,
  tags,
  rowRef,
}: {
  name: string;
  tags: string[];
  rowRef: React.MutableRefObject<HTMLDivElement>;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const handleShowTooltip = () => {
    if (componentRef.current && rowRef.current) {
      if (componentRef.current.scrollWidth >= rowRef.current.scrollWidth) {
        setShowTooltip(true);
      } else setShowTooltip(false);
    }
  };

  const tagStyle = {
    borderRadius: '4px',
    border: '1px solid',
    borderColor: colors.border.decorative,
    padding: '2px 6px 2px 4px',
  };

  return (
    <Box ref={componentRef} w="fit-content">
      {showTooltip ? (
        <Tooltip
          isDisabled={!showTooltip}
          maxW="400px"
          width="100%"
          placement="bottom-start"
          label={`${name} ${tags.length ? 'by ' + tags.join(', ') : ''}`}
        >
          <HStack onMouseEnter={handleShowTooltip}>
            <Typography noOfLines={1} variant="body" lineHeight="25px">
              {`${name} ${tags.length ? 'by ' : ''}`}
              {tags.map((tag, index) => (
                <Fragment key={index}>
                  {index > 0 && ', '}
                  <Typography display="inline" variant="detailStrong" style={tagStyle}>
                    {tag}
                  </Typography>
                </Fragment>
              ))}
            </Typography>
          </HStack>
        </Tooltip>
      ) : (
        <HStack onMouseEnter={handleShowTooltip}>
          <Typography noOfLines={1} variant="body" lineHeight="25px">
            {`${name} ${tags.length ? 'by ' : ''}`}
            {tags.map((tag, index) => (
              <Fragment key={index}>
                {index > 0 && ', '}
                <Typography display="inline" variant="detailStrong" style={tagStyle}>
                  {tag}
                </Typography>
              </Fragment>
            ))}
          </Typography>
        </HStack>
      )}
    </Box>
  );
};
