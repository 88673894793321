import { Box, CircularProgress, HStack, VStack, Wrap } from '@chakra-ui/react';
import { EmptyState, Tag, Tooltip } from 'Atoms';
import { ContentHeader, ContentLayout, Select, TOP_MENU_HEIGHT_PLUS_PADDING } from 'Molecules';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'Tokens';
import { MATERIAL_TOPIC_FILTER_OPTIONS } from './AssessmentOverview';
import { getBusinessUnitStandardsOverview } from './AssessmentOverview.hooks';
import { CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { MetricDataCollection, UNKONWN_ROUTE } from 'containers/Esrs';

export const BusinessUnitStandardsOverview = ({
  businessUnitAssessmentId,
  companyAssessmentId,
  businessUnitName,
  isBusinessUnitLevel,
}: {
  businessUnitAssessmentId: string;
  companyAssessmentId: string;
  businessUnitName?: string;
  isBusinessUnitLevel: boolean;
}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('all');

  const { categories, loading } = getBusinessUnitStandardsOverview(
    filter,
    companyAssessmentId,
    isBusinessUnitLevel
  );

  const dataCollectionLevel = useMemo(() => {
    return isBusinessUnitLevel
      ? MetricDataCollection.reportingUnitLevel
      : MetricDataCollection.companyLevel;
  }, [isBusinessUnitLevel]);

  const filteredCategories = useMemo(() => {
    return categories.map((category) => {
      return {
        ...category,
        materialStandards: category.materialStandards.filter((standard) => {
          if (
            !!standard.parentMaterialMetrics?.length &&
            standard.materialMetrics?.some(
              (metric) => metric.dataCollection === dataCollectionLevel
            )
          )
            return true;
          else
            return (
              !!standard.materialMetrics?.length &&
              standard.materialMetrics?.some(
                (metric) => metric.dataCollection === dataCollectionLevel
              )
            );
        }),
      };
    });
  }, [categories, dataCollectionLevel]);

  if (filteredCategories.filter((c) => c.materialStandards.length !== 0).length === 0) {
    return (
      <ContentLayout
        isLoading={loading}
        header={<ContentHeader size="md" title={`${businessUnitName} view`} />}
        contentProps={{ minH: `calc(100vh - ${TOP_MENU_HEIGHT_PLUS_PADDING + 64})` }}
      >
        <Box w="100%" h={`calc(100vh - 210px)`}>
          <EmptyState
            title="Start collecting data"
            description="No available data points. Go to settings to select metrics that should be collected from this business unit"
            callToAction={{
              text: 'Go to settings',
              variant: 'secondary',
              onClick: () => navigate('settings'),
            }}
            component={true}
          />
        </Box>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout
      isLoading={loading}
      header={<ContentHeader size="md" title={`${businessUnitName} view`} />}
      contentProps={{ minH: `calc(100vh - ${TOP_MENU_HEIGHT_PLUS_PADDING + 64})` }}
    >
      <VStack alignItems="start" spacing="26px">
        {/* <Box width="280px">
          <Select<{ value: string; label: string }>
            value={{
              label: MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === filter)?.label ?? '',
              value: filter,
            }}
            onChange={(value) => setFilter(value?.value ? value.value : 'all')}
            options={MATERIAL_TOPIC_FILTER_OPTIONS.map((currOption) => {
              return {
                label: currOption?.label ?? '',
                value: currOption.value,
              };
            })}
            size="md"
          />
        </Box> */}
        {filteredCategories.map((category, categoryIndex) => (
          <>
            {category.materialStandards.length > 0 && (
              <VStack key={category.categoryRef} alignItems="start">
                <Typography variant="overline">{category.categoryName}</Typography>
                <Wrap overflow="visible" spacing="16px">
                  {category.materialStandards.map((standard, standardIndex, standardsArr) => (
                    <>
                      <VStack
                        w="300px"
                        shadow="md"
                        borderRadius="10px"
                        p="16px"
                        gap="8px"
                        alignItems="start"
                        minHeight="128px"
                        _hover={{ bg: 'bg.hover' }}
                        cursor="pointer"
                        key={standard.standardRef}
                        onClick={() => {
                          navigate(
                            `bu-standard/${standard.standardRef}/bu/${businessUnitAssessmentId}/disclosure-requirement/${UNKONWN_ROUTE}`
                          );
                        }}
                        justifyContent="space-between"
                        {...(categoryIndex === 0 && standardIndex === 0
                          ? { className: 'walktour-standard-overview' }
                          : {})}
                      >
                        <VStack spacing="2px" alignItems="start">
                          <Typography variant="h4">{standard.standardName}</Typography>
                        </VStack>
                        <HStack spacing="10px">
                          <Tag variant="default" borderRadius="4px">
                            {standard.isMaterialForGroup ? (
                              <Tooltip label="Assessed as material by the group parent">
                                <CompanyTopDownIcon boxSize="16px" />
                              </Tooltip>
                            ) : (
                              <Tooltip label="Assessed as material by this company">
                                <CompanyBottomUpIcon boxSize="16px" />
                              </Tooltip>
                            )}
                          </Tag>
                          <HStack spacing="6px">
                            <CircularProgress size="24px" color="text.info" value={0} />
                            <Typography variant="body">{`0%`}</Typography>
                          </HStack>
                        </HStack>
                      </VStack>
                    </>
                  ))}
                </Wrap>
              </VStack>
            )}
          </>
        ))}
      </VStack>
    </ContentLayout>
  );
};
