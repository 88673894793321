import { CircularProgress, HStack, Spacer, VStack } from '@chakra-ui/react';
import { Tag, IconButton, TruncatableText } from 'Atoms';
import { Tooltip } from 'Atoms/Tooltip';
import { Typography } from 'Tokens';
import { HelpIcon, WarningIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';
import { ResultWithTooltip, Compliance } from './TaxonomyResultsTableTypes';

export const isMainRow = (key: string): boolean => {
  if (key === 'total' || key === 'eligible' || key === 'nonEligible') {
    return true;
  } else return false;
};

export const EmptyCell = ({ align = 'start' }: { align?: 'start' | 'end' }) => (
  <Typography textAlign={align} variant="body" color={'text.hint'}>
    —
  </Typography>
);

export const ActivitiesCell = ({
  activityName,
  mainRow,
}: {
  activityName: string;
  mainRow: boolean;
}) => {
  return (
    <HStack>
      <TruncatableText text={activityName} variant={mainRow ? 'h4' : 'body'} />
    </HStack>
  );
};

export const FinancialsCell = ({
  financials,
  mainRow,
}: {
  financials: ResultWithTooltip | undefined;
  mainRow: boolean;
}) => {
  const { t } = useTranslation('eu_table');
  if (!!financials) {
    return (
      <HStack width="100%" justifyContent="end">
        <Typography textAlign="end" variant={mainRow ? 'h4' : 'body'}>
          {financials.result}
        </Typography>
        {financials.tooltip && (
          <IconButton
            aria-label={''}
            alignSelf="start"
            size="xs"
            icon={<WarningIcon color="text.warning" />}
            borderWidth="0px"
            tooltipLabel={t(financials.tooltip)}
          />
        )}
      </HStack>
    );
  } else {
    return <EmptyCell />;
  }
};

export const AlignmentCell = ({
  alignment,
  mainRow,
}: {
  alignment: ResultWithTooltip | undefined;
  mainRow: boolean;
}) => {
  const { t } = useTranslation('eu_table');
  if (typeof alignment?.result === 'string') {
    return (
      <HStack width="100%" align="end">
        <Spacer></Spacer>
        <Tag isRounded={false}>{t(alignment.result)}</Tag>
      </HStack>
    );
  }
  if (typeof alignment?.result === 'number') {
    return (
      <HStack justifyContent="end">
        <CircularProgress size={'16px'} value={alignment.result} color={'text.compliant'} />
        <Typography variant={mainRow ? 'h4' : 'body'}>{Math.round(alignment.result)}%</Typography>;
      </HStack>
    );
  }
  return <EmptyCell align="end" />;
};

export const ActivityStatusCell = ({
  activityStatus,
  mainRow,
}: {
  activityStatus: string | undefined;
  mainRow: boolean;
}) => {
  const { t } = useTranslation('eu_table');
  if (!!activityStatus && activityStatus !== 'green') {
    return <Typography variant={mainRow ? 'h4' : 'body'}>{t(activityStatus)}</Typography>;
  }
  if (activityStatus === 'green') {
    return <Typography variant={mainRow ? 'h4' : 'body'}>{t('neither')}</Typography>;
  }
  return <EmptyCell />;
};

export const ComplianceCell = ({ compliance }: { compliance: Compliance | null | undefined }) => {
  const { t } = useTranslation('eu_table');
  switch (compliance) {
    case 'compliant': {
      return (
        <Tag isRounded={false} variant="compliant">
          {t(compliance)}
        </Tag>
      );
    }
    case 'notCompliant': {
      return (
        <Tag isRounded={false} variant="danger">
          {t(compliance)}
        </Tag>
      );
    }
    case 'partiallyCompliant': {
      return (
        <Tag isRounded={false} variant="warning">
          <>
            {t(compliance)}
            <Tooltip
              {...{ shouldWrapChildren: true }}
              label={t('eu_table:resultsTable.tooltip.partial')}
            >
              <HelpIcon marginLeft={'10px'} boxSize="10px" color="inherit" />
            </Tooltip>
          </>
        </Tag>
      );
    }
    case 'inProgress': {
      return <Tag isRounded={false}>{t(compliance)}</Tag>;
    }
    default: {
      return <EmptyCell />;
    }
  }
};

export const SubstantialContributionsCell = ({
  score,
  mainRow,
}: {
  score: ResultWithTooltip | undefined;
  mainRow: boolean;
}) => {
  const { t } = useTranslation('eu_table');
  if (typeof score?.result == 'string') {
    return (
      <HStack width="100%" align="end">
        <Spacer></Spacer>
        <Tag isRounded={false}>{t(score.result)}</Tag>
      </HStack>
    );
  }
  if (typeof score?.result == 'number') {
    const scoreToDisplay = isNaN(score.result) ? 0 : score.result.toFixed(1);
    return (
      <Typography textAlign="end" variant={mainRow ? 'h4' : 'body'}>
        {scoreToDisplay}%
      </Typography>
    );
  }
  return <EmptyCell align="end" />;
};

export const ColumnHeader = ({
  title,
  alignRight = false,
  subtitle,
  tooltip,
}: {
  title: string;
  alignRight?: boolean;
  subtitle?: string;
  tooltip?: string;
}) => {
  return (
    <VStack alignItems="start" spacing="0px">
      <HStack width="100%" justifyContent="space-between" letterSpacing="normal">
        <Typography width="100%" variant="bodyStrong" textAlign={alignRight ? 'right' : 'left'}>
          {title}
        </Typography>
        {tooltip && (
          <IconButton
            aria-label={''}
            alignSelf="start"
            size="xxs"
            variant="ghost"
            icon={<HelpIcon boxSize={'12px'} color="inherit" />}
            tooltipLabel={tooltip}
          />
        )}
      </HStack>
      {subtitle && (
        <Typography width="100%" variant="micro" textAlign={alignRight ? 'right' : 'left'}>
          {subtitle}
        </Typography>
      )}
    </VStack>
  );
};
