import { useMaterialStandardId } from 'containers/Esrs';
import {
  GetMaterialStandardDocument_,
  useGetMaterialStandardQuery,
  useMaterialMetricsPerDisclosureQuery,
  useUpdateMaterialStandardDataCollectionMutation,
} from 'models';
import { useMemo } from 'react';

export const useMaterialStandardDataCollection = (
  standardRef: string,
  esrsAssessmentId: string
) => {
  const { companyAssessmentId: companyMaterialStandardId, loading: materialStandardIdLoading } =
    useMaterialStandardId(standardRef, esrsAssessmentId);
  const [updateMaterialStandardDataCollection] = useUpdateMaterialStandardDataCollectionMutation();

  const { data: materialStandardData, loading: materialStandardDataLoading } =
    useGetMaterialStandardQuery({
      variables: {
        assessmentId: esrsAssessmentId,
        standardRef: standardRef,
      },
    });

  const isDataCollected = useMemo(
    () => materialStandardData?.materialityAssessment[0]?.isDataCollected ?? false,
    [materialStandardData]
  );

  const updateDataCollectionStatus = async () => {
    await updateMaterialStandardDataCollection({
      variables: {
        materialStandardId: companyMaterialStandardId,
      },
      refetchQueries: [GetMaterialStandardDocument_],
    });
  };

  return {
    isDataCollected,
    updateDataCollectionStatus,
    loading: materialStandardDataLoading || materialStandardIdLoading,
  };
};

export const useMaterialMetricsPerDisclosure = (
  disclosureRequirementRef: string,
  esrsAssessmentId: string,
  parentAssessmentId?: string
) => {
  const { data: disclosureMetricData, loading: metricDataLoading } =
    useMaterialMetricsPerDisclosureQuery({
      variables: {
        disclosureRequirementRef,
        companyAssessmentId: esrsAssessmentId,
        parentAssessmentId: parentAssessmentId ?? esrsAssessmentId,
      },
      skip: !esrsAssessmentId,
    });

  return {
    disclosureMetricData,
    hasParentCompany: !!parentAssessmentId,
    metricDataLoading,
  };
};
