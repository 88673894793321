import { ModalProps, Box, VStack } from '@chakra-ui/react';
import { Modal, Select } from 'Molecules';
import { useToast } from 'utils/hooks';
import { User, useUpdateUserMutation } from 'models';
import React from 'react';
import { getLanguageMap, useTranslation } from 'utils/translation';
import { Controller, useForm } from 'react-hook-form';

export function LanguageEditor({
  isOpen,
  onClose,
  user,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { user: User }) {
  const { t } = useTranslation('common');
  const langMap = getLanguageMap();
  const languageOptions = Object.keys(langMap).map((key) => ({
    value: key,
    label: `${langMap[key]} ${key !== 'en' ? `(Coming soon!)` : ''}`,
  }));

  const [updateUser] = useUpdateUserMutation();
  const toast = useToast();

  const { handleSubmit, control } = useForm<{ language: string }>({
    defaultValues: {
      language: user.locale,
    },
  });
  const changeUser = ({ language }: any) => {
    const triggerRefresh = user.locale === language ? false : true;
    updateUser({
      variables: {
        id: user.id,
        user: {
          locale: language,
        },
      },
    }).then(() => {
      toast({ text: t('common:changeLanguage.succeeded') });
      onClose();
      if (triggerRefresh) {
        location.reload();
      }
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('common:user.changeLanguage.header')}
      size="sm"
      confirmText={t('common:button.saveChanges')}
      confirmButtonProps={{ type: 'submit', form: 'language-editor-form' }}
    >
      <form onSubmit={handleSubmit(changeUser)} id="language-editor-form">
        <VStack spacing="md" alignItems="flex-start">
          <Box width="26em">
            <Controller
              control={control}
              defaultValue={user.locale}
              name="language"
              render={({ field: { onChange, value } }) => (
                <Select<{ label: string; value: string }>
                  value={languageOptions.filter((c) => value.includes(c.value))}
                  onChange={(val) => val?.value && onChange(val.value)}
                  options={languageOptions}
                  isOptionDisabled={(option) => option.value !== 'en'}
                />
              )}
            />
          </Box>
        </VStack>
      </form>
    </Modal>
  );
}
