import { BorderStyle } from 'docx';

export enum Colors {
  white = '#FFFFFF',
  default = '#000000',
  muted = '#0000B2',
  lightGray = '#E1E1E6',
}

export enum Fonts {
  default = 'Manrope',
}

export enum FontSizes {
  xs = 10,
  sm = 12,
  md = 14,
  lg = 16,
  d1 = 44,
  d3 = 28,
  h1 = 24,
  h2 = 18,
  h3 = 16,
  h4 = 14,
}

export enum TextStyle {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  d1 = 'd1',
  d3 = 'd3',
  bodyStrong = 'bodyStrong',
  bodyLarge = 'bodyLarge',
  body = 'body',
  detail = 'detail',
  detailStrong = 'detailStrong',
  micro = 'micro',
  microStrong = 'microStrong',
  overline = 'overline',
}

export const styles = {
  paragraphStyles: [
    {
      id: TextStyle.h1,
      name: 'Heading 1',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h1,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.h2,
      name: 'Heading 2',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h2,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.h3,
      name: 'Heading 3',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h3,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.h4,
      name: 'Heading 4',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h4,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.d1,
      name: 'D1',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.d1,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.d3,
      name: 'D1',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.d3,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.bodyStrong,
      name: 'Body Strong',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.md,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.bodyLarge,
      name: 'Body Large',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.lg,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.body,
      name: 'Body',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.md,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.body,
      name: 'Body',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.md,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.detail,
      name: 'Detail',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.sm,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.detailStrong,
      name: 'Detail String',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.sm,
        color: Colors.default,
        bold: true,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.micro,
      name: 'Micro',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.xs,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.micro,
      name: 'Micro Strong',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.xs,
        color: Colors.default,
        bold: true,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.overline,
      name: 'Overline',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.sm,
        color: Colors.default,
        bold: true,
        font: Fonts.default,
      },
    },
  ],
};

export const tableBorders = {
  top: {
    style: BorderStyle.NONE,
  },
  left: {
    style: BorderStyle.NONE,
  },
  right: {
    style: BorderStyle.NONE,
  },
  bottom: {
    style: BorderStyle.NONE,
  },
  insideHorizontal: {
    style: BorderStyle.THICK,
    color: Colors.lightGray,
    size: 6,
  },
  insideVertical: {
    style: BorderStyle.THICK,
    color: Colors.lightGray,
    size: 6,
  },
};
