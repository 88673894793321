import { stringToNumber } from 'containers/Esrs/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { uniq } from 'lodash';
import {
  GetEsrsMetricsDatapointsDocument_,
  GetEsrsMetricsDatapointsQueryVariables_,
  GetEsrsMetricsDatapointsQuery_,
} from 'models';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import {
  QUARTERS_ARRAY,
  FrequencyEnums,
  QuartersType,
  TimePeriodsEnums,
  QuartersEnums,
} from '../../Requirement';
import { combinedFiltersForNoTags, combinedFiltersForTags } from '../AggregatedMetrics';
import {
  getFlattenedMetricRows,
  getMetricWithChildren,
  MetricsTableData,
} from '../MetricAnswers.hooks';

type TimePeriodsObject = {
  Q1: number | null | undefined;
  Q2: number | null | undefined;
  Q3: number | null | undefined;
  Q4: number | null | undefined;
  Total: number | null | undefined;
};

type DatapointObjectProps = {
  metricRef: string;
  answers: GetEsrsMetricsDatapointsQuery_['answers'];
  row: MetricsTableData;
  areMetricsQuarterly: boolean;
  areMetricsYearly: boolean;
  companyStandardId: string;
};

type ExcelColumnsType = {
  key: string;
  header: string;
  width: number;
}[];

export enum ExcelColumnsEnums {
  total = 'Total',
  timePeriod = 'timePeriod',
  metricRef = 'metricRef',
  tags = 'tags',
}

export const excelColumns = [
  {
    key: 'businessUnit',
    header: 'Business unit',
    width: 40,
  },
  {
    key: 'disclosureRequirement',
    header: 'Disclosure Requirement',
    width: 40,
  },
  {
    key: 'metric',
    header: 'Metric',
    width: 100,
  },
  {
    key: QuartersEnums.q1,
    header: QuartersEnums.q1,
    width: 20,
  },
  {
    key: QuartersEnums.q2,
    header: QuartersEnums.q2,
    width: 20,
  },
  {
    key: QuartersEnums.q3,
    header: QuartersEnums.q3,
    width: 20,
  },
  {
    key: QuartersEnums.q4,
    header: QuartersEnums.q4,
    width: 20,
  },
  {
    key: ExcelColumnsEnums.total,
    header: ExcelColumnsEnums.total,
    width: 20,
  },
  {
    key: ExcelColumnsEnums.timePeriod,
    header: 'Time period',
    width: 20,
  },
  {
    key: ExcelColumnsEnums.metricRef,
    header: 'Metric Reference',
    width: 30,
  },
  {
    key: ExcelColumnsEnums.tags,
    header: 'Tags',
    width: 30,
  },
];

export const getUniqueMetricsRefs = (metrics: MetricsTableData[]) =>
  uniq(
    metrics.flatMap((metric) => {
      const allMetrics = getMetricWithChildren(metric);
      return allMetrics.map((row) => row.metric.reference);
    })
  );

const getMetricTitle = (row: MetricsTableData) => {
  const title = row.metric.shortTitle ?? row.metric.title;
  const tags = row.tags?.map((tag) => tag.tagValue);
  return `${title}${tags ? ` by ${tags.join(', ')}` : ''}`;
};

const getTagsString = (tags: MetricsTableData['tags']) => {
  const hasTags = !!tags?.length;
  if (!hasTags) return '';
  return tags.map((tag) => `[${tag.tagType}^${tag.tagValue}]`).join(' - ');
};

const getRelevantColumns = (
  columns: ExcelColumnsType,
  flattenedRows: MetricsTableData[],
  areMetricsQuarterly: boolean,
  areMetricsYearly: boolean
) => {
  const hasNoTags = flattenedRows.every((row) => !row.tags?.length);

  return columns.filter((column) => {
    const isTagsColumn = column.key === ExcelColumnsEnums.tags;
    const isTotalColumn = column.key === ExcelColumnsEnums.total;
    const isQuarterlyColumn = QUARTERS_ARRAY.includes(column.key);

    if (hasNoTags && isTagsColumn) {
      return false;
    }
    if (areMetricsQuarterly && isTotalColumn) {
      return false;
    }
    if (areMetricsYearly && isQuarterlyColumn) {
      return false;
    }
    return true;
  });
};

const blockCells = (worksheet: ExcelJS.Worksheet) => {
  const blackCellStyle: {
    type: 'pattern';
    pattern: 'solid';
    bgColor: { argb: '262626' };
  } = {
    type: 'pattern',
    pattern: 'solid',
    bgColor: { argb: '262626' },
  };

  const rowsExcludingHeader = worksheet.getRows(2, worksheet.rowCount);

  rowsExcludingHeader?.forEach((row) => {
    if (row.getCell(ExcelColumnsEnums.timePeriod).value === FrequencyEnums.yearly) {
      QUARTERS_ARRAY.map((quarter) => (row.getCell(quarter).fill = blackCellStyle));
    }
    if (row.getCell(ExcelColumnsEnums.timePeriod).value === FrequencyEnums.quarterly) {
      row.getCell(ExcelColumnsEnums.total).fill = blackCellStyle;
    }
  });
};

const getTimePeriods = ({
  areMetricsQuarterly,
  areMetricsYearly,
}: {
  areMetricsQuarterly: boolean;
  areMetricsYearly: boolean;
}) => {
  if (areMetricsQuarterly) return QUARTERS_ARRAY;
  if (areMetricsYearly) return [ExcelColumnsEnums.total];
  return [...QUARTERS_ARRAY, ExcelColumnsEnums.total];
};

const getDatapointsObject = ({
  metricRef,
  answers,
  row,
  areMetricsQuarterly,
  areMetricsYearly,
  companyStandardId,
}: DatapointObjectProps) => {
  const metricAnswer = answers.find((answer) => answer.metricRef === metricRef);
  const isYearly =
    row.metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
      ?.frequency === FrequencyEnums.yearly;

  const datapoints =
    metricAnswer?.datapoints.filter((dp) => {
      if (!!row.tags?.length) {
        return combinedFiltersForTags(dp, isYearly, [row.tags ?? []]);
      } else {
        return combinedFiltersForNoTags(dp, isYearly);
      }
    }) ?? [];

  const timePeriods = getTimePeriods({ areMetricsQuarterly, areMetricsYearly });

  const result = timePeriods.reduce((object, period) => {
    if (period === ExcelColumnsEnums.total) {
      object[period] = isYearly
        ? stringToNumber(datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year)?.value)
        : null;
    } else
      object[period as QuartersType] = isYearly
        ? null
        : stringToNumber(datapoints.find((dp) => dp.timeframe === period)?.value ?? null);
    return object;
  }, {} as TimePeriodsObject);

  return result;
};

export const useExportEsrsMetricTable = () => {
  const { reportingUnitId = '', esrsAssessmentId = '' } = useParams();
  const toast = useToast();
  return useCallback(
    async (
      metrics: MetricsTableData[],
      disclosureRequirement: string,
      businessUnit: string,
      areMetricsQuarterly: boolean,
      areMetricsYearly: boolean,
      companyStandardId: string
    ) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(disclosureRequirement ?? 'Metrics');
      const fileName = `${
        metrics.length === 1 ? metrics[0].metric.title : 'Various Metrics'
      }-${businessUnit}.xlsx`;

      const allMetricsRefs = getUniqueMetricsRefs(metrics);

      const { data } = await nhost.graphql.request<
        GetEsrsMetricsDatapointsQuery_,
        GetEsrsMetricsDatapointsQueryVariables_
      >(GetEsrsMetricsDatapointsDocument_, {
        reportingUnitId,
        assessmentId: esrsAssessmentId,
        metricRefs: allMetricsRefs,
      });

      const flattenedRows = metrics.flatMap((metric) =>
        getFlattenedMetricRows(metric).filter((row) => !row.subRows?.length)
      );

      const rows = flattenedRows.map((row) => {
        return {
          businessUnit: businessUnit,
          disclosureRequirement: disclosureRequirement,
          metric: getMetricTitle(row),
          metricRef: row.metric.reference,
          timePeriod: row.metric.materialMetrics.find(
            (mm) => mm.materialStandardId === companyStandardId
          )?.frequency,
          tags: getTagsString(row.tags),
          ...getDatapointsObject({
            metricRef: row.metric.reference,
            answers: data?.answers ?? [],
            row,
            areMetricsQuarterly,
            areMetricsYearly,
            companyStandardId,
          }),
        };
      });

      worksheet.columns = getRelevantColumns(
        excelColumns,
        flattenedRows,
        areMetricsQuarterly,
        areMetricsYearly
      );
      worksheet.addRows(rows);

      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      if (!areMetricsQuarterly && !areMetricsYearly) blockCells(worksheet);

      workbook.xlsx.writeBuffer().then(function (buffer) {
        const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
        saveAs(blob, fileName);
        toast({
          text: '',
          duration: 0,
          destroyAll: true,
        });
      });
    },
    [GetEsrsMetricsDatapointsDocument_]
  );
};
