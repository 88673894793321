import { Stack, Box, BoxProps } from '@chakra-ui/react';
import { ContentHeader, Loader } from 'Molecules';
import React, { Suspense } from 'react';

type ContentLayoutProps = {
  children?: React.ReactNode | React.ReactNode[];
  isLoading?: boolean;
  loadingText?: string;
  padChildren?: boolean;
  header: string | React.ReactNode;
  contentProps?: BoxProps;
  height?: string;
  contentHeight?: string;
};

export const TOP_MENU_HEIGHT_PLUS_PADDING = '128px';

export const ContentLayout = ({
  children,
  isLoading = false,
  loadingText,
  padChildren = true,
  header,
  contentProps = {},
  height = `calc(100vh - ${TOP_MENU_HEIGHT_PLUS_PADDING})`,
  contentHeight,
}: ContentLayoutProps) => {
  let head;
  if (typeof header === 'string') {
    head = <ContentHeader title={header} />;
  } else {
    head = header;
  }

  return (
    <Loader isLoaded={!isLoading} label={loadingText}>
      <Stack width="100%" height={contentHeight}>
        <Box width="100%" alignItems="flex-start" height={contentHeight}>
          {head}
          <Box
            minH={height}
            width="100%"
            height={contentHeight}
            {...(padChildren ? { padding: '8px 16px 16px' } : { padding: '0px' })}
            {...contentProps}
          >
            <Suspense>{children}</Suspense>
          </Box>
        </Box>
      </Stack>
    </Loader>
  );
};
