import { Tooltip, Center } from '@chakra-ui/react';
import { ListIcon } from 'Tokens/Icons/Data';
import { LockedIcon } from 'Tokens/Icons/Function';
import {
  AlignedIcon,
  CheckIcon,
  MinimumSocialSafeguardsIcon,
  NotAlignedIcon,
} from 'Tokens/Icons/Status';

export enum StateStatus {
  todo = 'To do',
  done = 'Done',
  compliant = 'Compliant',
  nonCompliant = 'Not compliant',
  MSSCompliant = 'Compliant for minimum safeguards',
  MSSNonCompliant = 'Not compliant for minimum safeguards',
  locked = 'Locked',
}

const STATUS_COLORS = {
  [StateStatus.todo]: {
    background: 'bg.unknown',
    color: 'text.muted',
  },
  [StateStatus.done]: {
    background: 'bg.success',
    color: 'text.success',
  },
  [StateStatus.compliant]: {
    background: 'bg.compliant',
    color: 'text.compliant',
  },
  [StateStatus.nonCompliant]: {
    background: 'bg.notCompliant',
    color: 'text.notCompliant',
  },
  [StateStatus.MSSCompliant]: {
    background: 'bg.compliant',
    color: 'text.compliant',
  },
  [StateStatus.MSSNonCompliant]: {
    background: 'bg.notCompliant',
    color: 'text.notCompliant',
  },
  [StateStatus.locked]: {
    background: 'bg.unknown',
    color: 'text.muted',
  },
};

const STATUS_ICON = {
  [StateStatus.todo]: <ListIcon color="inherit" />,
  [StateStatus.done]: <CheckIcon color="inherit" />,
  [StateStatus.compliant]: <AlignedIcon color="inherit" />,
  [StateStatus.nonCompliant]: <NotAlignedIcon color="inherit" />,
  [StateStatus.MSSCompliant]: <MinimumSocialSafeguardsIcon color="inherit" />,
  [StateStatus.MSSNonCompliant]: <MinimumSocialSafeguardsIcon color="inherit" />,
  [StateStatus.locked]: <LockedIcon color="inherit" />,
};
export const CardStatus = ({
  status,
  tooltipContentOverride,
}: {
  status: StateStatus;
  tooltipContentOverride?: JSX.Element;
}) => {
  return (
    <Tooltip label={tooltipContentOverride !== undefined ? tooltipContentOverride : status}>
      <Center
        height="36px"
        minWidth="36px"
        borderRadius="8px"
        bg={STATUS_COLORS[status].background}
        color={STATUS_COLORS[status].color}
      >
        {STATUS_ICON[status]}
      </Center>
    </Tooltip>
  );
};
