import { HStack, VStack, Box, FormLabel } from '@chakra-ui/react';
import { FormField, IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { HelpTooltip, Modal, Select } from 'Molecules';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Typography } from 'Tokens';
import { useEffect, useMemo, useState } from 'react';
import { IconHelp, IconTrash } from '@tabler/icons-react';
import {
  CompanyLevelMetricsPerDisclosureDocument_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetricTag_Insert_Input_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  MaterialMetricsPerDisclosureDocument_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useDeleteMaterialMetricTagMutation,
  useDeleteMaterialMetricTagValueMutation,
  useUpsertMaterialMetricsMutation,
  useUpsertMaterialMetricTagsMutation,
} from 'models';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { groupBy, uniq } from 'lodash';
import { TagSelectionMenu, TagValuesSelector } from './TagSelectMenus';
import {
  FREQUENCY_OPTIONS,
  MaterialMetricFields,
  MetricConfigModalProps,
  NoSelectedTags,
  TagBreakdown,
  TagStatus,
} from './MetricConfigModalParent';
import { MetricTagsList } from 'Molecules/MetricTagsList';
import { components } from 'react-select';
import { useParams } from 'react-router-dom';
import { useAssessmentReportingUnits } from 'containers/Esrs';
import { TableMetricData } from './DataCollection.d';

export const INSTRUCTION = 'instruction';

enum DataCollectionLevel {
  company = 'company',
  reportingUnits = 'reportingUnits',
}

const DATA_GATHERING_OPTIONS: { label: string; value: string }[] = [
  { label: 'Select data gathering level', value: INSTRUCTION },
  { label: 'Company level', value: DataCollectionLevel.company },
  { label: 'Business units', value: DataCollectionLevel.reportingUnits },
];

export const MetricConfigModal = ({
  isOpen,
  onClose,
  selectedMetricData,
  hasParentCompany,
}: MetricConfigModalProps & { hasParentCompany: boolean }) => {
  const { esrsAssessmentId = '' } = useParams();

  const { reportingUnitAssessments: reportingUnits } =
    useAssessmentReportingUnits(esrsAssessmentId);
  const hasReportingUnits = useMemo(
    () => reportingUnits?.filter((ru) => ru.isCompanyLevel === false)?.length,
    [reportingUnits]
  );

  const { company } = useCurrentCompany();

  const { control, reset, handleSubmit } = useForm<MaterialMetricFields>({
    defaultValues: {
      dataCollection: selectedMetricData?.materialByCompany[0]?.dataCollection ?? '',
      frequency: selectedMetricData?.materialByCompany[0]?.frequency ?? 'Yearly',
    },
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const selectedMaterialMetricId = useMemo(
    () => selectedMetricData?.materialByCompany[0]?.id,
    [selectedMetricData]
  );

  const isEditingDisabled = useMemo(
    () =>
      selectedMetricData?.isChild &&
      selectedMetricData.parentMetric?.materialByCompany[0]?.isMaterial,
    [selectedMetricData]
  );

  const adminPanelTags = useMemo(() => {
    return selectedMetricData?.adminPanelTags;
  }, [selectedMetricData]);

  const tagsFromParent = useMemo(() => {
    if (!hasParentCompany) return [];
    return selectedMetricData?.materialByParent[0]?.materialMetricTags;
  }, [selectedMetricData]);

  const tagsFromCompany = useMemo(() => {
    return selectedMetricData?.materialByCompany[0]?.materialMetricTags;
  }, [selectedMetricData]);

  const { existingTags, optionsToAdd } = useMemo(() => {
    const justFromESRS =
      adminPanelTags
        ?.filter((x) => !x.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: true,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
          alwaysAll: x.values.alwaysAll,
        })) ?? [];
    const justFromParent =
      tagsFromParent
        ?.filter((x) => !justFromESRS?.find((y) => y.type === x.tagType))
        .map((x) => ({
          type: x.tagType,
          isFromESRS: false,
          isFromParent: true,
          values:
            (adminPanelTags ?? [])
              .find((y) => y.type === x.tagType)
              ?.values.tagValues.map((z) => z.value) ?? [],
          alwaysAll: x.metricTagType.alwaysAll,
        })) ?? [];
    const justFromCompany =
      tagsFromCompany
        ?.filter((x) => ![...justFromESRS, ...justFromParent].find((y) => y.type === x.tagType))
        .map((x) => ({
          type: x.tagType,
          isFromESRS: false,
          isFromParent: false,
          values:
            (adminPanelTags ?? [])
              .find((y) => y.type === x.tagType)
              ?.values.tagValues.map((z) => z.value) ?? [],
          alwaysAll: x.metricTagType.alwaysAll,
        })) ?? [];

    const existing: TagBreakdown[] = [...justFromESRS, ...justFromParent, ...justFromCompany];
    const options: TagBreakdown[] =
      (adminPanelTags?.filter((x) => !existing.find((y) => y.type === x.type)) ?? [])
        .filter((t) => !!t.isOptional)
        .map((x) => ({
          type: x.type,
          isFromESRS: !x.isOptional,
          isFromParent: false,
          values: x.values.tagValues.map((y) => y.value),
        })) ?? [];
    return { existingTags: existing, optionsToAdd: options };
  }, [adminPanelTags, tagsFromParent, tagsFromCompany]);

  const getStatus = (tag: TagBreakdown): TagStatus => {
    if (tag.isFromESRS) return TagStatus.required;
    if (tag.isFromParent) return TagStatus.requested;
    return TagStatus.added;
  };

  const { frequency, dataCollection } = useMemo(() => {
    return {
      frequency: selectedMetricData?.materialByCompany[0]?.frequency ?? 'Yearly',
      dataCollection: selectedMetricData?.materialByCompany[0]?.dataCollection ?? '',
    };
  }, [selectedMetricData]);

  const getAllMaterialMetricChildren = (metric?: TableMetricData): any => {
    const childrenMetrics = metric?.childrenMetrics;

    if (!childrenMetrics) {
      return [];
    }

    const data = childrenMetrics.flatMap((childMetric) => {
      const childData = getAllMaterialMetricChildren(childMetric?.childMetric as TableMetricData);
      return [
        {
          id: childMetric.childMetric?.materialByCompany[0]?.id,
          metricRef: childMetric.childMetric?.reference,
        },
        ...childData,
      ];
    });

    return data;
  };

  const toast = useToast();

  const { isValid, errors } = useFormState({ control });
  const [availableTags, setAvailableTags] = useState<TagBreakdown[]>(existingTags);
  const [prevTags, setPrevTags] = useState<TagBreakdown[]>(availableTags);
  const [options, setOptions] = useState<TagBreakdown[]>(optionsToAdd);
  const [configuredTags, setConfiguredTags] = useState<{ tagType: string; tagValue: string }[]>([]);

  const getDataCollectionText = (dataCollectionLevel: string): string => {
    if (dataCollectionLevel === DataCollectionLevel.reportingUnits) return 'business units level';
    if (dataCollectionLevel === DataCollectionLevel.company) return 'company level';
    return '';
  };

  const [upsertMaterialMetricTag] = useUpsertMaterialMetricTagsMutation();
  const [upsertMaterialMetric] = useUpsertMaterialMetricsMutation();
  const [deleteMaterialMetricTagValue] = useDeleteMaterialMetricTagValueMutation();
  const [deleteMaterialMetricTag] = useDeleteMaterialMetricTagMutation();

  const handleConfirm = async (data: MaterialMetricFields) => {
    const childrenMetrics = getAllMaterialMetricChildren(selectedMetricData);
    const metricAndChildren: { id: string; metricRef: string }[] = [
      {
        id: selectedMaterialMetricId,
        metricRef: selectedMetricData?.reference,
      },
      childrenMetrics,
    ].flat();

    try {
      await upsertMaterialMetric({
        variables: {
          objects: metricAndChildren.map((materialMetric) => {
            const materialStandardId = selectedMetricData?.materialByCompany.find(
              (x) => !!x.materialStandardId
            )?.materialStandardId;

            return {
              frequency: data.frequency,
              id: materialMetric.id,
              dataCollection: data.dataCollection ?? DataCollectionLevel.company,
              materialStandardId: materialStandardId,
              metricRef: materialMetric?.metricRef,
            };
          }),
        },
        refetchQueries: [
          CompanyLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
          MaterialMetricsPerDisclosureDocument_,
          EsrsAssessmentDocument_,
        ],
      });
      toast({
        text: 'Configured metric data collection settings',
      });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric data collection settings',
        variant: 'danger',
      });
    }

    const groupedConfiguredTags = groupBy(configuredTags, 'tagType');
    const objects: Esrs_MaterialMetricTag_Insert_Input_[] = metricAndChildren.flatMap(
      (materialMetric) => {
        return Object.keys(groupedConfiguredTags).map((key) => ({
          materialMetricId: materialMetric.id,
          tagType: key,
          materialTagValues: {
            data: groupedConfiguredTags[key],
            on_conflict: {
              constraint:
                Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
              update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
            },
          },
        }));
      }
    );

    const existingMaterialTags =
      selectedMetricData?.materialByCompany[0]?.materialMetricTags.flatMap((materialTag) =>
        materialTag.materialTagValues.map((t) => ({
          tagType: t.tagType,
          tagValue: t.tagValue,
        }))
      );
    const tagValuesYoDelete = existingMaterialTags?.filter((existingTag) => {
      return !configuredTags.some((configuredTag) => {
        return (
          configuredTag.tagType === existingTag.tagType &&
          configuredTag.tagValue === existingTag.tagValue
        );
      });
    });

    const existingMaterialTagTypes =
      selectedMetricData?.materialByCompany[0]?.materialMetricTags.map((t) => t.tagType);
    const configuredMaterialTagTypes = uniq(availableTags.map((t) => t.type));
    const tagTypesToDelete = existingMaterialTagTypes?.filter(
      (tag) => !configuredMaterialTagTypes?.includes(tag)
    );

    try {
      await upsertMaterialMetricTag({
        variables: {
          objects: objects,
        },
        refetchQueries: [
          CompanyLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
          MaterialMetricsPerDisclosureDocument_,
        ],
      });
      toast({
        text: 'Configured metric breakdown settings',
      });

      if (tagTypesToDelete?.length)
        metricAndChildren.forEach((materialMetric) => {
          deleteMaterialMetricTag({
            variables: {
              materialMetricId: materialMetric.id,
              tagTypes: tagTypesToDelete ?? [],
            },
            refetchQueries: [
              CompanyLevelMetricsPerDisclosureDocument_,
              ReportingUnitsMetricsPerDisclosureDocument_,
              MaterialMetricsPerDisclosureDocument_,
            ],
          });
        });

      if (tagValuesYoDelete?.length)
        metricAndChildren.forEach((materialMetric) => {
          tagValuesYoDelete?.forEach((tag) => {
            deleteMaterialMetricTagValue({
              variables: {
                materialMetricId: materialMetric.id,
                tagType: tag.tagType,
                tagValue: tag.tagValue,
              },
              refetchQueries: [
                CompanyLevelMetricsPerDisclosureDocument_,
                ReportingUnitsMetricsPerDisclosureDocument_,
                MaterialMetricsPerDisclosureDocument_,
              ],
            });
          });
        });
    } catch (error) {
      console.log(error);
      toast({
        text: 'Unable to configure metric breakdown settings',
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (selectedMetricData?.materialByCompany[0]) {
      reset({
        dataCollection: dataCollection,
        frequency: frequency,
      });
    }

    if (selectedMetricData?.materialByCompany[0]?.materialMetricTags.length === 0) {
      setConfiguredTags(
        selectedMetricData.adminPanelTags.flatMap((tag) => {
          if (tag.values.alwaysAll) {
            return tag.values.tagValues.map((t) => ({
              tagType: t.type,
              tagValue: t.value,
            }));
          }
          return [];
        }) ?? []
      );
    } else {
      setConfiguredTags(
        selectedMetricData?.materialByCompany[0]?.materialMetricTags.flatMap((materialTag) =>
          materialTag.materialTagValues.map((t) => ({
            tagType: t.tagType,
            tagValue: t.tagValue,
          }))
        ) ?? []
      );
    }
  }, [selectedMetricData]);

  const updatedOptions = useMemo(() => {
    if (!prevTags) return options;

    const removedTags = prevTags.filter(
      (tag) => !availableTags.some((newTag) => newTag.type === tag.type)
    );
    const addedTags = availableTags.filter(
      (newTag) => !prevTags.some((tag) => tag.type === newTag.type)
    );

    return options
      .filter((option) => !addedTags.some((tag) => tag.type === option.type))
      .concat(removedTags);
  }, [options, availableTags, prevTags]);

  useEffect(() => {
    setAvailableTags(existingTags);
    setOptions(optionsToAdd);
    setPrevTags(existingTags);
  }, [existingTags, optionsToAdd]);

  const hasBreakdown = useMemo(() => {
    return !!availableTags?.length || !!updatedOptions.length;
  }, [availableTags, updatedOptions]);

  const { title, shortTitle } = useMemo(() => {
    return { title: selectedMetricData?.title, shortTitle: selectedMetricData?.shortTitle };
  }, [selectedMetricData]);

  const Option = (props: any) => {
    const isInstructions = props.value === INSTRUCTION;

    return isInstructions ? (
      <Box p="10px 16px 8px">
        <Typography variant="detail" color="text.muted">
          {props.label}
        </Typography>
      </Box>
    ) : (
      <components.Option {...props}>
        {props.isDisabled ? (
          <HStack px="4px" justifyContent="space-between">
            <Typography variant="bodyStrong" color="text.hint">
              {props.label}
            </Typography>
            <HelpTooltip label={`${company?.name} doesn't have business units`} />
          </HStack>
        ) : (
          <HStack px="4px" justifyContent="space-between">
            <Typography variant="bodyStrong" color={props.isSelected ? 'white' : 'text.muted'}>
              {props.label}
            </Typography>
          </HStack>
        )}
      </components.Option>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      customHeader={
        <Typography variant="h2" mr="40px">
          {shortTitle ?? title ?? ''}
          {!!adminPanelTags?.length && (
            <>
              {' by '}
              <MetricTagsList
                tags={adminPanelTags.map((tag) => tag.type)}
                isHStack={false}
                maxWidthInPixels={196}
              />
            </>
          )}
        </Typography>
      }
      subtitle="Metric settings"
      size={hasBreakdown ? 'lg' : 'md'}
      confirmButtonProps={{
        type: 'submit',
        form: 'metric-config-form',
        isDisabled: !isValid,
      }}
      onConfirm={onClose}
    >
      <VStack width="100%" alignItems="stretch" spacing="16px">
        <form id="metric-config-form" onSubmit={handleSubmit(handleConfirm)}>
          <VStack alignItems="start" spacing="16px">
            <Controller
              name="frequency"
              control={control}
              render={({ field: { onChange, value: fieldValue } }) => (
                <FormField
                  label="Frequency"
                  id="frequency"
                  isInvalid={!!errors.frequency}
                  error="Required"
                >
                  <VStack width="324px" spacing="6px" alignItems="start">
                    <Select<{ value: string; label: string }>
                      width="287px"
                      placeholder="Select"
                      components={{
                        Option: Option,
                      }}
                      isDisabled={isEditingDisabled}
                      value={{ label: fieldValue, value: fieldValue }}
                      options={FREQUENCY_OPTIONS}
                      onChange={(freq) => {
                        onChange(freq?.value ?? undefined);
                      }}
                    />
                    {isEditingDisabled && (
                      <Typography variant="detail">
                        {`It is not possible to change frequency because the parent metric is collected ${frequency.toLocaleLowerCase()}`}
                      </Typography>
                    )}
                  </VStack>
                </FormField>
              )}
            />
            <Controller
              name="dataCollection"
              control={control}
              render={({ field: { onChange, value: fieldValue } }) => (
                <FormField
                  label="Data gathering level"
                  id="dataLevel"
                  isInvalid={!!errors.dataCollection}
                  error="Required"
                >
                  <VStack width="324px" spacing="6px" alignItems="start">
                    <Select<{ value: string; label: string }>
                      width="287px"
                      placeholder="Select"
                      components={{
                        Option: Option,
                      }}
                      isDisabled={isEditingDisabled}
                      value={{
                        label:
                          DATA_GATHERING_OPTIONS.find((option) => option.value === fieldValue)
                            ?.label ?? '',
                        value: fieldValue ?? '',
                      }}
                      options={DATA_GATHERING_OPTIONS}
                      onChange={(dc) => {
                        onChange(dc?.value ?? undefined);
                      }}
                      isOptionDisabled={(option) =>
                        !hasReportingUnits && option.value === 'reportingUnits'
                      }
                    />
                    {isEditingDisabled && (
                      <Typography variant="detail">
                        {`It is not possible to change data gathering level because the parent metric is collected on ${getDataCollectionText(
                          dataCollection
                        )}`}
                      </Typography>
                    )}
                  </VStack>
                </FormField>
              )}
            />
          </VStack>
        </form>

        {hasBreakdown && (
          <VStack alignItems="start" width="100%" spacing="6px">
            <FormLabel m="0px">Breakdown</FormLabel>
            {availableTags.length > 0 ? (
              <VStack spacing="6px" w="100%">
                {availableTags.map((tag, index) => {
                  return (
                    <HStack
                      key={`${tag.type}_${index}`}
                      bgColor="bg.interactive"
                      h="48px"
                      borderRadius="6px"
                      w="100%"
                      justifyContent="space-between"
                    >
                      <HStack paddingInline="8px" spacing="16px" w="50%">
                        {index > 0 && (
                          <Tag w="47px" variant="info" borderRadius="6px" title="AND" />
                        )}
                        <TruncatableText variant="bodyStrong" text={`by ${tag.type}`} />
                      </HStack>
                      <HStack w="50%" justifyContent="space-between">
                        <Box w="150px">
                          <TruncatableText
                            text={getStatus(tag)}
                            variant="body"
                            paddingInline="8px"
                          />
                        </Box>

                        <TagValuesSelector
                          options={tag.values.map((v) => ({ tagType: tag.type, tagValue: v }))}
                          configured={configuredTags}
                          type={tag.type}
                          setConfigured={setConfiguredTags}
                        />
                        {!tag.isFromESRS && !tag.isFromParent ? (
                          <IconButton
                            aria-label="delete"
                            variant="ghost"
                            icon={<IconTrash size="16px" />}
                            onClick={() => {
                              setAvailableTags((prev) => prev.filter((i) => i.type !== tag.type));
                            }}
                          />
                        ) : (
                          <Tooltip
                            label={
                              tag.isFromESRS
                                ? 'This breakdown is required by the ESRS. You can not delete this breakdown'
                                : 'This breakdown is requested by the parent company. You can not delete this breakdown'
                            }
                            placement="bottom"
                          >
                            <IconButton
                              aria-label="help"
                              variant="ghost"
                              icon={<IconHelp size="16px" />}
                            />
                          </Tooltip>
                        )}
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            ) : (
              <NoSelectedTags
                checkedTags={availableTags}
                setCheckedTags={setAvailableTags}
                tagOptions={updatedOptions ?? []}
              />
            )}
            {!!updatedOptions.length && !!availableTags.length && (
              <TagSelectionMenu
                options={updatedOptions}
                checked={availableTags}
                setChecked={setAvailableTags}
              />
            )}
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
