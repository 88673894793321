import { HStack, Input, Th, Thead, useNumberInput } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { ArrowDownRightIcon, ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { AddIcon, MinusIcon } from 'Tokens/Icons/Function';
import { useEffect } from 'react';

export const TableHeader = ({ unit }: { unit: string }) => (
  <Thead>
    <Th px="8px" textTransform="none" letterSpacing="normal">
      <Typography variant="bodyStrong" as="span">
        Department
      </Typography>
    </Th>
    <Th px="8px" textTransform="none" letterSpacing="normal">
      <Typography variant="bodyStrong" as="span">
        Baseline
      </Typography>
    </Th>
    <Th px="8px" textTransform="none" letterSpacing="normal">
      <Typography variant="bodyStrong" as="span">
        Target, %
      </Typography>
    </Th>
    <Th px="8px" textTransform="none" letterSpacing="normal">
      <Typography variant="bodyStrong" as="span">
        Target, {unit}
      </Typography>
    </Th>
  </Thead>
);

export const MilestoneNumberInput = ({
  defaultValue,
  onChange,
  isDisabled,
  calculatedValue,
  isCompanyLevel = false,
}: {
  defaultValue: number;
  onChange: (param: number) => void;
  isDisabled: boolean;
  calculatedValue?: number;
  isCompanyLevel?: boolean;
}) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 5,
    defaultValue: defaultValue ?? 0,
    min: -100,
    max: 100,
    precision: 1,
    value: isDisabled ? calculatedValue ?? undefined : undefined,
    onChange: (val) => {
      onChange(Number(val));
    },
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  const { value } = getInputProps();

  useEffect(() => {
    if (isDisabled && isCompanyLevel) {
      onChange(Number(value));
    }
  }, [value]);

  return (
    <HStack w="100%">
      {value > 0 ? (
        <ArrowUpRightIcon color="text.hint" />
      ) : (
        <ArrowDownRightIcon color="text.hint" />
      )}
      <Input
        {...input}
        width="50px"
        p="0px"
        border="none"
        bg="white"
        color={isDisabled ? 'text.hint' : 'text.default'}
        fontSize="14px"
        readOnly={isDisabled}
      />
      {!isDisabled && (
        <HStack spacing="4px">
          <IconButton aria-label="minus" size="xs" icon={<MinusIcon />} {...dec} variant="ghost" />
          <IconButton aria-label="add" size="xs" icon={<AddIcon />} {...inc} variant="ghost" />
        </HStack>
      )}
    </HStack>
  );
};
