import { Typography } from 'Tokens';
import { MetricsTableData } from '../MetricAnswers.hooks';

export const MetricFrequency = ({
  row,
  companyStandardId,
}: {
  row: MetricsTableData;
  companyStandardId: string;
}) => {
  const companyMaterialMetric = row.metric.materialMetrics.find(
    (mm) => mm.materialStandardId === companyStandardId
  );
  return <Typography variant="body">{companyMaterialMetric?.frequency}</Typography>;
};
