import { IconProps, Icon } from '@chakra-ui/icons';
import { Icon as TablerIcon } from '@tabler/icons-react';

export const defaultIconProps = {
  color: 'text.muted',
  boxSize: '16px',
};

export const tablerIcon = (icon: TablerIcon) => {
  const component = (props: IconProps) => {
    return <Icon as={icon} {...defaultIconProps} {...props} />;
  };
  component.displayName = icon.name;
  return component;
};
