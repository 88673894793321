import { useCallback } from 'react';
import {
  CompaniesFromAllPortfoliosDocument_,
  CompanyAssessmentDocument_,
  PortfoliosDocument_,
  useToggleShareAssessmentWithPortfolioMutation,
} from 'models';

export const useShareCompanyAssessment = () => {
  const [toggleSharing, { loading }] = useToggleShareAssessmentWithPortfolioMutation();

  const shareCompanyAssessment = useCallback(
    (assessmentId: string, portfolioCompanyId: string, portfolioId: string) => {
      return toggleSharing({
        variables: {
          object: {
            id: portfolioCompanyId,
            sharedCompanyAssessmentId: assessmentId,
            portfolioId: portfolioId,
          },
        },
        refetchQueries: [
          CompaniesFromAllPortfoliosDocument_,
          CompanyAssessmentDocument_,
          PortfoliosDocument_,
        ],
        awaitRefetchQueries: true,
      });
    },
    [toggleSharing]
  );
  return {
    shareCompanyAssessment,
    loading,
  };
};

export const useUnshareCompanyAssessment = () => {
  const [toggleSharing, { loading }] = useToggleShareAssessmentWithPortfolioMutation();

  const unshareCompanyAssessment = useCallback(
    (portfolioCompanyId: string, portfolioId: string) => {
      return toggleSharing({
        variables: {
          object: {
            id: portfolioCompanyId,
            sharedCompanyAssessmentId: null,
            portfolioId: portfolioId,
          },
        },
        refetchQueries: [
          CompaniesFromAllPortfoliosDocument_,
          CompanyAssessmentDocument_,
          PortfoliosDocument_,
        ],
        awaitRefetchQueries: true,
      });
    },
    [toggleSharing]
  );
  return {
    unshareCompanyAssessment,
    loading,
  };
};
