import React, { useEffect, useMemo, useState } from 'react';
import { ReportingGroup, useReportingGroupsQuery } from 'models';
import { HStack, Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { useParams } from 'react-router-dom';
import { Button } from 'Atoms';
import { Typography } from 'Tokens';
import { CompanyIcon, FolderIcon } from 'Tokens/Icons/Data';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { COMPANY_LEVEL } from 'containers/Esrs/EsrsAssessment.hooks';

export type SelectedReportingGroup = {
  id: string | undefined;
  name: string;
};
type GroupWithChildren = ReportingGroup & {
  subGroups: GroupWithChildren[];
};

export const SingleGroupOption = ({
  group,
  level,
  handleSelect,
  selectedGroup,
  groupId,
}: {
  selectedGroup?: string;
  group: GroupWithChildren;
  level: number;
  handleSelect: (v: string) => void;
  groupId?: string | undefined;
}) => {
  return (
    <>
      <MenuItem
        p={'8px'}
        m={'0px'}
        pl={`${24 * level}px`}
        w={'100%'}
        onClick={() => handleSelect(group?.id)}
        isDisabled={group.id === groupId}
      >
        <HStack
          w={'100%'}
          justifyContent={'space-between'}
          color={
            selectedGroup === group.id ? 'text.selected' : group.id === groupId ? 'bg.backdrop' : ''
          }
        >
          <HStack>
            <FolderIcon />
            <Typography color={'inherit'} variant="bodyStrong">
              {group?.name}
            </Typography>
          </HStack>
          {group?.id === selectedGroup && <CheckIcon color="inherit" />}
        </HStack>
      </MenuItem>

      {group?.subGroups?.map((child) => (
        <SingleGroupOption
          key={child.id}
          group={child}
          level={level + 1}
          handleSelect={handleSelect}
          selectedGroup={selectedGroup}
          groupId={groupId}
        />
      ))}
    </>
  );
};

const getGroupChildren = (
  group: ReportingGroup,
  allGroups: ReportingGroup[]
): GroupWithChildren => {
  const children = allGroups.filter((child) => child.parentId === group.id);
  if (!children.length) {
    return {
      ...group,
      subGroups: [],
    };
  }
  return {
    ...group,
    subGroups: children.map((child) => getGroupChildren(child, allGroups)),
  };
};

export const CompanyLevelSelector = ({
  parentReportingGroup,
  setParentReportingGroup,
  currentReportingGroupId,
  groupId,
}: {
  parentReportingGroup?: string;
  setParentReportingGroup: (parentReportingGroup?: string) => void;
  currentReportingGroupId: string | undefined;
  groupId?: string | undefined;
}) => {
  const { cAssessmentId } = useParams();
  const { t } = useTranslation('common');
  const { data } = useReportingGroupsQuery({
    variables: { cAssessmentId },
    skip: !cAssessmentId,
  });
  const firstLevelGroups = useMemo(
    () =>
      data?.reportingGroups
        .filter((grp) => !grp.parentId)
        .map((grp) => getGroupChildren(grp, data?.reportingGroups ?? [])),
    [data]
  );
  const [selectedGroup, setSelectedGroup] = useState<SelectedReportingGroup>();

  const handleSelect = (id: string): void => {
    setSelectedGroup({
      id: id,
      name: (data?.reportingGroups ?? []).find((grp) => grp.id === id)?.name ?? '',
    });
    setParentReportingGroup(id);
  };

  const selectCompanyLevel = (): void => {
    setSelectedGroup({
      id: undefined,
      name: COMPANY_LEVEL,
    });
    setParentReportingGroup(undefined);
  };

  useEffect(() => {
    if (!!currentReportingGroupId) {
      setSelectedGroup({
        id: currentReportingGroupId,
        name:
          (data?.reportingGroups ?? []).find((grp) => grp.id === currentReportingGroupId)?.name ??
          '',
      });
    } else {
      setSelectedGroup({
        id: undefined,
        name: COMPANY_LEVEL,
      });
    }
  }, [data, currentReportingGroupId]);

  return (
    <Menu flip={true} matchWidth={true}>
      <MenuButton
        as={Button}
        width={'100%'}
        h={'36px'}
        bg={'bg.default'}
        borderWidth={'1px'}
        borderColor={'border.default'}
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius={'8px'}
        p={'8px'}
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign={'left'}
        color={'text.default'}
      >
        {parentReportingGroup ? (
          <HStack>
            <FolderIcon />
            <Typography variant="bodyStrong">{selectedGroup?.name}</Typography>
          </HStack>
        ) : (
          <HStack>
            <CompanyIcon />
            <Typography variant="bodyStrong">{t('common:companySelector.companyLevel')}</Typography>
          </HStack>
        )}
      </MenuButton>
      <MenuList
        p={'8px'}
        maxHeight={(data?.reportingGroups?.length ?? 0) > 5 ? '200px' : '350px'}
        overflowY={'scroll'}
      >
        <MenuGroup>
          <Typography variant="detail" p={'8px'}>
            {t('common:companySelector.select')}
          </Typography>
          <MenuItem p={'8px'} m={'0px'} w={'100%'} onClick={selectCompanyLevel}>
            <HStack
              w={'100%'}
              justifyContent={'space-between'}
              color={selectedGroup?.id === undefined ? 'text.selected' : ''}
            >
              <HStack>
                <CompanyIcon />
                <Typography color={'inherit'} variant="bodyStrong">
                  {COMPANY_LEVEL}
                </Typography>
              </HStack>
              {selectedGroup?.id === undefined && <CheckIcon color="inherit" />}
            </HStack>
          </MenuItem>
          {firstLevelGroups?.map((grp) => (
            <SingleGroupOption
              key={grp.id}
              group={grp}
              handleSelect={handleSelect}
              level={1}
              selectedGroup={parentReportingGroup}
              groupId={groupId}
            />
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
