import { Box, Center, Divider, HStack, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { TodoIcon } from 'Tokens/Icons/Custom';
import { useTranslation } from 'utils/translation';
import usePortfolios from './Portfolios.hooks';

const PortfolioDefault = ({
  setIsCreating,
}: {
  setIsCreating: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['portfolio']);
  const { getPortfolio } = usePortfolios();
  const { portfolioId } = useParams();

  const portfolio = getPortfolio(portfolioId ?? '', null);

  const addedCompanies = useMemo(
    () => portfolio?.portfolioCompanies?.filter((c) => c?.year === null || !c.year) ?? [],
    [portfolio]
  );

  return (
    <Box w="100%" h={`calc(100vh - 152px)`} bg="bg.muted" borderRadius="12px">
      <Center width="100%" paddingTop="88px">
        <VStack width="564px" spacing="32px">
          <VStack alignItems="start">
            <Typography variant="h2">{t('portfolio:gettingStarted.title')}</Typography>
            <Typography variant="bodyLarge">{t('portfolio:gettingStarted.description')}</Typography>
          </VStack>

          <VStack>
            <HStack alignItems="start" spacing="18px">
              <VStack spacing="0px">
                <TodoIcon boxSize="32px" color="border.default" />
                <Divider
                  variant="dashed"
                  orientation="vertical"
                  height={addedCompanies?.length > 0 ? '168px' : '110px'}
                  border="1px"
                  borderColor="border.hover"
                  marginY="2px"
                />
              </VStack>
              <VStack alignItems="start" spacing="16px" pt="2px">
                <VStack alignItems="start" spacing="4px">
                  <Typography variant="h3">
                    {t('portfolio:gettingStarted.inviteCompaniesTitle')}
                  </Typography>
                  <Typography variant="body" color="text.muted">
                    {t('portfolio:gettingStarted.inviteCompaniesDesc')}
                  </Typography>
                </VStack>
                {addedCompanies?.length > 0 && (
                  <HStack
                    padding="8px 4px 8px 8px"
                    border="1px"
                    borderColor="bg.unknown"
                    bg="bg.unknown.muted"
                    width="100%"
                    borderRadius="6px"
                  >
                    <Typography variant="body" color="text.muted">
                      Companies added:
                    </Typography>
                    <Typography variant="body" color="text.muted">
                      {addedCompanies.map((company, index) => (
                        <span key={index}>
                          {company.name}
                          {index < addedCompanies.length - 1 && ', '}
                        </span>
                      ))}
                    </Typography>
                  </HStack>
                )}
                <Button size="sm" variant="secondary" onClick={() => setIsCreating(true)}>
                  {t('portfolio:gettingStarted.add')}
                </Button>
              </VStack>
            </HStack>
          </VStack>
          <VStack>
            <HStack alignItems="start" spacing="18px" mt="-34px">
              <VStack spacing="0px">
                <TodoIcon boxSize="32px" color="border.default" />
              </VStack>
              <VStack alignItems="start" spacing="16px" pt="2px">
                <VStack alignItems="start" spacing="4px">
                  <Typography variant="h3"> {t('portfolio:gettingStarted.setupTitle')}</Typography>
                  <Typography variant="body" color="text.muted">
                    {t('portfolio:gettingStarted.setupDescription')}
                  </Typography>
                </VStack>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    navigate(`new-portfolio/create`);
                  }}
                >
                  {t('portfolio:gettingStarted.settings')}
                </Button>
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </Center>
    </Box>
  );
};

export default PortfolioDefault;
