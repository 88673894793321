import { KeyResultEnums } from '../../Requirement';

export const getCalculatedSubsidiaryValues = (
  isBottomUp: boolean,
  subsidiaryBaseline: number,
  subsidiaryPercentage: number,
  companyPercentage?: number
) => {
  let target = 0;
  let percentage = 0;
  if (isBottomUp) {
    target =
      subsidiaryPercentage > 0
        ? Number(subsidiaryBaseline) + Number((subsidiaryBaseline * subsidiaryPercentage) / 100)
        : subsidiaryBaseline - (subsidiaryBaseline * Math.abs(subsidiaryPercentage)) / 100;
  } else {
    percentage = companyPercentage ?? 0;
    target =
      percentage > 0
        ? Number(subsidiaryBaseline) + Number((subsidiaryBaseline * percentage) / 100)
        : subsidiaryBaseline - (subsidiaryBaseline * Math.abs(percentage)) / 100;
  }
  return {
    target: parseFloat(Number(target)?.toFixed(2)),
    percentage: parseFloat(Number(percentage)?.toFixed(2)),
  };
};

export const getCalculatedCompanyValues = (
  isBottomUp: boolean,
  companyBaseline: number,
  companyPercentage: number,
  subsidiariesData: any,
  localAnswers: any
) => {
  let target = 0;
  let percentage = 0;
  if (isBottomUp) {
    const subsidiaryTargets = Object.keys(subsidiariesData ?? {}).map((subsidiary) => {
      const value = getCalculatedSubsidiaryValues(
        true,
        localAnswers[`${KeyResultEnums.baseline}_${subsidiary}`],
        subsidiariesData?.[subsidiary]
      );
      return value.target;
    });

    const BASELINE_PREFIX = 'baseline_';
    const isBaseline = (key: string) => {
      return key.includes(BASELINE_PREFIX);
    };
    const getSubsidiaryIdFromBaseline = (key: string) => {
      return key.split('_')[1];
    };

    const doesSubsidiaryIdExist = (key: string) => {
      return Object.keys(subsidiariesData ?? {}).includes(getSubsidiaryIdFromBaseline(key));
    };

    const missingSubsidiaryTargets: number[] = Object.keys(localAnswers)
      .filter((answerKey) => isBaseline(answerKey) && !doesSubsidiaryIdExist(answerKey))
      .map((answerKey) => localAnswers[answerKey]);
    subsidiaryTargets.push(...missingSubsidiaryTargets);
    target = subsidiaryTargets.reduce((acc, cur) => {
      const value = isNaN(cur) ? 0 : cur ?? 0;
      return Number(acc) + Number(value);
    }, 0);
    percentage =
      companyBaseline > 0 && subsidiaryTargets.length
        ? ((target - companyBaseline) / Math.abs(companyBaseline)) * 100
        : 0;
  } else {
    target =
      companyPercentage > 0
        ? companyBaseline + (companyBaseline * companyPercentage) / 100
        : companyBaseline - (companyBaseline * Math.abs(companyPercentage)) / 100;
  }

  return {
    target: parseFloat(Number(target)?.toFixed(2)),
    percentage: parseFloat(Number(percentage)?.toFixed(2)),
  };
};
