import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { useTranslation } from 'utils/translation';

export const ReportYearSelector = ({
  selectedYear,
  setSelectedYear,
  reportingYears,
}: {
  selectedYear: number | undefined;
  setSelectedYear: (selectedYear: number) => void;
  reportingYears: number[];
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('portfolio');
  return (
    <Menu gutter={2}>
      <MenuButton
        as={Button}
        width="96px"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        color="text.default"
      >
        {selectedYear}
      </MenuButton>
      <MenuList p="8px">
        <MenuGroup p="8px">
          {reportingYears.sort().map((year) => (
            <MenuItem p="8px" m="0px" w="100%" onClick={() => setSelectedYear(year)}>
              <HStack
                w="100%"
                justifyContent="space-between"
                color={selectedYear === year ? 'text.selected' : ''}
              >
                <HStack>
                  <Typography color={'inherit'} variant="bodyStrong">
                    {year}
                  </Typography>
                </HStack>
                {year === selectedYear && <CheckIcon color="inherit" />}
              </HStack>
            </MenuItem>
          ))}
        </MenuGroup>
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <Box w="100%">
          <Button
            w="100%"
            onClick={() => navigate(`${reportingYears[reportingYears.length - 1] + 1}/create`)}
          >
            {t('addPeriod')}
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};
