import { Box, CircularProgress, HStack, VStack, Wrap } from '@chakra-ui/react';
import { EmptyState, Tag, Tooltip } from 'Atoms';
import { Loader, Select } from 'Molecules';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NothingFoundIllustration, Typography } from 'Tokens';
import { CompanyBottomUpIcon } from 'Tokens/Icons/Custom';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';
import { MATERIAL_TOPIC_FILTER_OPTIONS } from '../Assessment/EsrsOverview';
import { getGroupStandardsOverview } from './GroupAssessmentOverview.hooks';

export const GroupTopicsOverview = ({ esrsAssessmentId }: { esrsAssessmentId: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('all');

  const { categories, loading } = getGroupStandardsOverview(filter, esrsAssessmentId);
  const { categories: allCategories } = getGroupStandardsOverview('all', esrsAssessmentId);

  const isMaterialityMatchingForAllSubsidiaries = useMemo(() => {
    const allStandards = allCategories.flatMap((category) => category.materialStandards);
    return allStandards.every((standard) => standard.isMaterialForSubsidiary);
  }, [allCategories]);

  const isEmptyFilter = useMemo(
    () => categories.filter((c) => c.materialStandards.length).length === 0,
    [categories]
  );

  if (loading) return <Loader />;

  return (
    <VStack alignItems="start" spacing="26px" mt="16px">
      {!isMaterialityMatchingForAllSubsidiaries && (
        <Box width="280px">
          <Select<{ value: string; label: string }>
            value={{
              label: MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === filter)?.label ?? '',
              value: filter,
            }}
            onChange={(value) => setFilter(value?.value ? value.value : 'all')}
            options={MATERIAL_TOPIC_FILTER_OPTIONS.map((currOption) => {
              return {
                label: currOption?.label ?? '',
                value: currOption.value,
              };
            })}
            size="md"
          />
        </Box>
      )}

      {isEmptyFilter && (
        <Box w="100%" h={`calc(100vh - 320px)`}>
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            icon={<NothingFoundIllustration boxSize="120px" />}
            callToAction={{
              text: t('common:search.filter.emptyBtn'),
              variant: 'secondary',
              onClick: () => {
                setFilter('all');
              },
              leftIcon: <RefreshIcon color="inherit" />,
            }}
            component={true}
          />
        </Box>
      )}

      {categories.map((category, categoryIndex) => (
        <>
          {category.materialStandards.length > 0 && (
            <VStack key={category.categoryRef} alignItems="start">
              <Typography variant="overline">{category.categoryName}</Typography>
              <Wrap overflow="visible" spacing="16px">
                {category.materialStandards.map((standard, standardIndex, standardsArr) => (
                  <VStack
                    w="300px"
                    shadow="md"
                    borderRadius="10px"
                    p="16px"
                    gap="8px"
                    alignItems="start"
                    minHeight="128px"
                    _hover={{ bg: 'bg.hover' }}
                    cursor="pointer"
                    key={standard.standardRef}
                    onClick={() => {
                      navigate(`standard/${standard.standardRef}`);
                    }}
                    justifyContent="space-between"
                    {...(categoryIndex === 0 && standardIndex === 0
                      ? { className: 'walktour-standard-overview' }
                      : {})}
                  >
                    <VStack spacing="2px" alignItems="start">
                      <Typography variant="h4">{standard.standardName}</Typography>
                    </VStack>
                    <HStack spacing="10px">
                      {!standard.isMaterialForGroup && standard.isMaterialForSubsidiary && (
                        <Tag variant="default" borderRadius="4px">
                          <Tooltip label="Assessed as material by one or several subsidiaries">
                            <CompanyBottomUpIcon boxSize="16px" />
                          </Tooltip>
                        </Tag>
                      )}
                      <HStack spacing="6px">
                        <CircularProgress size="24px" color="text.info" value={0} />
                        <Typography variant="body">{`0%`}</Typography>
                      </HStack>
                    </HStack>
                  </VStack>
                ))}
              </Wrap>
            </VStack>
          )}
        </>
      ))}
    </VStack>
  );
};
