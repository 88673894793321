import { TableData } from 'Molecules/NestedTable';
import { MetricsTableData, isFrequencyYearly } from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import { TeamMember, TeamMembersFilterOption, useTeamMembers } from 'containers/TeamMembers';
import { useMemo } from 'react';
import { HStack } from '@chakra-ui/react';
import { ProjectLeaderSelect } from 'containers/Esrs/pieces/Assessment/EsrsConfig/ProjectLeaderSelect';
import { User } from 'models';
import { AvatarSize } from 'Atoms';

export const MetricOwnerSelect = ({
  row,
  selectedQuarter,
  companyReportingUnit,
  assessmentProjectLeader,
  isBorderless = false,
  size,
}: {
  row: TableData<MetricsTableData>;
  selectedQuarter: string;
  companyReportingUnit?: string;
  assessmentProjectLeader?: Partial<User>;
  isBorderless?: boolean;
  size?: AvatarSize;
}) => {
  const { onDatapointChange, answer } = useGetDatapointValues(row, companyReportingUnit);

  const dataPoint = useMemo(
    () => answer?.datapoints.find((dp) => dp.timeframe === selectedQuarter),
    [answer]
  );

  const { members } = useTeamMembers(TeamMembersFilterOption.All);
  const dpOwner = useMemo(() => {
    return dataPoint?.owner ? dataPoint.owner : assessmentProjectLeader;
  }, [dataPoint, answer, members, selectedQuarter]);

  const updateDatapoint = (member: TeamMember) => {
    onDatapointChange(
      dataPoint?.value ?? null,
      answer?.hasOptedOut ?? false,
      answer?.optOutReason ?? '',
      dataPoint ?? { timeframe: selectedQuarter },
      row.tags,
      member.id
    );
  };

  return (
    <HStack spacing="4px">
      <ProjectLeaderSelect
        onChange={updateDatapoint}
        defaultProjectLeader={dpOwner}
        avatarText={dpOwner?.displayName ?? dpOwner?.email}
        isBorderless={isBorderless}
        rounded
        small
        size={size}
      />
    </HStack>
  );
};
