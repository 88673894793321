import { CompanyPaiReport, PaiReportList } from './pieces';
import { Route, Routes } from 'react-router-dom';
import { ErrorElement } from 'App';

export const Pai = () => {
  return (
    <Routes>
      <Route index element={<PaiReportList />} errorElement={<ErrorElement />} />
      <Route path=":year/:period" element={<CompanyPaiReport />} errorElement={<ErrorElement />} />
    </Routes>
  );
};
