import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import React from 'react';
import ReactSelect, { GroupBase, Props, components } from 'react-select';
import ReactCreatableSelect, { CreatableProps } from 'react-select/creatable';
import { colors, Typography } from 'Tokens';
import { RemoveIcon } from 'Tokens/Icons/Function';

export const Select = React.forwardRef(
  <
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
  >(
    {
      size = 'md',
      width = '100%',
      isInvalid = false,
      deselect,
      ...props
    }: Props<OptionType, IsMulti, GroupType> & {
      size?: 'md' | 'sm';
      width?: string;
      isInvalid?: boolean;
      deselect?: () => void;
    },
    ref: React.Ref<any>
  ) => {
    return (
      <ReactSelect<OptionType, IsMulti, GroupType>
        {...props}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (props) => {
            return (
              <components.DropdownIndicator {...props}>
                <IconButton
                  icon={<ChevronDownIcon boxSize="16px" color="text.muted" />}
                  aria-label="open"
                  height=""
                  minW=""
                  variant="ghost"
                  padding="6px"
                />
              </components.DropdownIndicator>
            );
          },
          ClearIndicator: (props) => {
            return (
              <components.ClearIndicator {...props}>
                <IconButton
                  icon={<RemoveIcon boxSize="16px" color="text.hint" />}
                  aria-label="remove"
                  variant="ghost"
                  height=""
                  minW=""
                  padding="6px"
                />
              </components.ClearIndicator>
            );
          },
          Option: ({ isSelected, children, isDisabled, isMulti, ...props }) => {
            return (
              <components.Option {...props} isSelected isMulti isDisabled>
                <HStack
                  justifyContent={'space-between'}
                  padding="8px"
                  borderRadius="8px"
                  _hover={{
                    backgroundColor: 'bg.hover',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={isSelected ? deselect : undefined}
                >
                  <HStack>
                    <Typography
                      variant="bodyStrong"
                      color={
                        isDisabled ? 'text.disabled' : isSelected ? 'text.selected' : 'text.muted'
                      }
                    >
                      {props.label}
                    </Typography>
                  </HStack>
                  {isSelected && !isMulti && (
                    <CheckIcon boxSize="11px" strokeWidth="1px" color="text.selected" />
                  )}
                </HStack>
              </components.Option>
            );
          },
        }}
        ref={ref}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            padding: '0px',
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: '0px',
          }),
          container: (provided) => ({
            ...provided,
            width: width,
            fontSize: '14px',
          }),
          menuPortal: (base) => ({ ...base, background: 'white', zIndex: 9999 }),
          // input: (base) => ({ ...base, width: '100%' }),
          menuList: (base, { isMulti }) => ({
            ...base,
            maxHeight: isMulti ? '275px' : '300px',
            padding: '0px',
            border: 'none',
          }),
          menu: (base) => ({
            ...base,
            padding: '8px',
            margin: '0px',
            boxShadow:
              '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.10)',
            borderRadius: '10px',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '3px',
          }),
          option: (base, { isFocused, isSelected, isDisabled, isMulti }) => ({
            ...base,
            background: 'white',
            color: isDisabled ? colors.text.disabled : colors.text.default,
            padding: '0px',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            width: '100%',
            '&:active': {
              backgroundColor: 'white',
            },
          }),

          control: (provided, { isFocused, isDisabled }) => ({
            ...provided,
            width: '100%',
            borderRadius: '8px',
            fontSize: 'sm',
            minHeight: size === 'md' ? '36px' : '28px',
            color: colors.text.muted,
            bg: colors.bg.default,
            padding: '0px 3px',
            borderWidth: isDisabled ? '0px' : isFocused ? '2px' : '1px',
            borderColor: isFocused
              ? colors.border['selected.accent']
              : isInvalid
              ? colors.border['critical.accent']
              : colors.border.default,
            boxShadow: isFocused || isDisabled ? 'none' : undefined,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            _hover: {
              borderColor: 'border.hover',
            },
          }),
        }}
      />
    );
  }
) as <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(
  props: Props<OptionType, IsMulti, GroupType> & {
    size?: 'md' | 'sm';
    isInvalid?: boolean;
    width?: string;
    deselect?: () => void;
    ref?: React.Ref<any>;
  }
) => JSX.Element;

export const CreatableSelect = React.forwardRef(
  <
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
  >(
    {
      size = 'md',
      isInvalid = false,
      ...props
    }: CreatableProps<OptionType, IsMulti, GroupType> & {
      size?: 'md' | 'sm';
      isInvalid?: boolean;
    },
    ref: React.Ref<any>
  ) => {
    return (
      <ReactCreatableSelect<OptionType, IsMulti, GroupType>
        {...props}
        ref={ref}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
          menuList: (base) => ({ ...base, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, background: 'white', zIndex: 9999 }),
          // input: (base) => ({ ...base, width: '100%' }),
          option: (base, { isFocused, isSelected }) => ({
            ...base,
            background: isSelected
              ? colors.bg['selected.accent']
              : isFocused
              ? colors.bg.selected
              : 'white',
            color: isSelected ? colors.text.onAccent : colors.text.default,
            width: '100%',
          }),
          control: (provided, { isFocused, isDisabled }) => ({
            ...provided,
            width: '100%',
            borderRadius: '8px',
            fontSize: 'sm',
            height: size === 'md' ? '36px' : '28px',
            color: colors.text.muted,
            bg: colors.bg.default,
            borderWidth: '1px',
            borderColor: isFocused
              ? colors.border['selected.accent']
              : isInvalid
              ? colors.border['critical.accent']
              : colors.border.default,
            boxShadow: isFocused || isDisabled ? 'none' : undefined,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            _hover: {
              borderColor: 'border.hover',
            },
          }),
        }}
      />
    );
  }
) as <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(
  props: CreatableProps<OptionType, IsMulti, GroupType> & {
    size?: 'md' | 'sm';
    isInvalid?: boolean;
    ref?: React.Ref<any>;
  }
) => JSX.Element;
