import { useCallback, useMemo } from 'react';
import {
  CompanyAssessmentsDocument_,
  useDeleteCompanyAssessmentMutation,
  useActivityAssessmentQuery,
  ActivityAssessment,
  useUpsertCompanyAssessmentMutation,
  BusinessUnitAssessment_Constraint_,
  BusinessUnitAssessment_Update_Column_,
  CompanyAssessmentAggregate_Constraint_,
  CompanyAssessmentAggregate_Update_Column_,
  BusinessUnitAssessment_Insert_Input_,
  ActivityReport_Constraint_,
  ActivityReport_Update_Column_,
  Financials_Constraint_,
  ActivityAssessmentQuery_,
  ObjectiveWithQuestions,
  CompanyAssessment,
  CompanyAssessmentDocument_,
  AssessmentForDuplicationDocument_,
  AssessmentForDuplicationQueryVariables_,
  AssessmentForDuplicationQuery_,
  BusinessUnitAssessment_Arr_Rel_Insert_Input_,
  Answer_Arr_Rel_Insert_Input_,
  useUpsertReportingGroupsMutation,
  Financials_Update_Column_,
  Answer_Constraint_,
  Answer_Update_Column_,
  Thread_Constraint_,
  Thread_Update_Column_,
  useActivityAssessmentAnswersQuery,
  Answer,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Attachment_Constraint_,
  Attachment_Update_Column_,
  useFlagCompanyAssessmentAsDirtyMutation,
  useUpdateAssessmentLockMutation,
  useGroupsQuery,
  useInvestorsQuery,
} from 'models';
import { resolveActivity } from 'utils/scores/questions';
import { sortObjectives } from 'utils/objectives/objectivesOrder';
import { DEFAULT_ACTIVITY_TAG } from 'utils/scores/activityTag';
import { stringToDate } from 'utils/date';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { add, endOfYear, startOfYear } from 'date-fns';
import { createFinancials } from 'utils/financials';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';
import { getActivityResult, getGeneralAssessmentResult } from 'utils/scores/taxonomyScore';
import { useUnshareCompanyAssessmentWithGroup } from 'containers/Groups';
import { useUnshareCompanyAssessment } from 'containers/Investors';
import {
  useActivityAssessmentVersion,
  useGetNewestValidActivityVersion,
} from './VersioningOfQuestions.hooks';

export const COMPANY_LEVEL_GENERAL_ASSESSMENT_ID = 'company-general-questions';

export const REPORTING_METHODS = ['yearly', 'quarterly', 'half-yearly'];
export const COMPANY_LEVEL_ASSESSMENT_ID = 'company';
export const GENERAL_ACTIVITY_REF = '0.0';

export function useDeleteCompanyAssessment() {
  const { companyId } = useCurrentCompanyId();
  const [deleteCompanyAssessment] = useDeleteCompanyAssessmentMutation();
  const { unshareCompanyAssessment: unshareAssessmentWithPortfolio } =
    useUnshareCompanyAssessment();
  const { unshareCompanyAssessment: unshareAssessmentWithGroup } =
    useUnshareCompanyAssessmentWithGroup();

  const { data: groups } = useGroupsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const { data: investors } = useInvestorsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  return useCallback(
    (id: string, aggregateId: string) =>
      deleteCompanyAssessment({
        variables: {
          id,
          deletedAt: 'now()',
          companyId,
          endDate: new Date(),
          startDate: new Date(),
          aggregateId: aggregateId,
        },
        refetchQueries: [CompanyAssessmentsDocument_],
        awaitRefetchQueries: true,
      }).then(async () => {
        const portfoliosSharedWith = investors?.investors.filter(
          (p) => p.sharedCompanyAssessmentId === id
        );
        const groupsSharedWith = groups?.groups.filter((g) => g.sharedCompanyAssessmentId === id);
        if (groupsSharedWith?.length) {
          await Promise.all(
            groupsSharedWith.map((g) => unshareAssessmentWithGroup(g.id, g.ownerGroupAssessmentId))
          );
          if (portfoliosSharedWith?.length)
            await Promise.all(
              portfoliosSharedWith.map((p) => unshareAssessmentWithPortfolio(p.id, p.portfolioId))
            );
        }
      }),
    [deleteCompanyAssessment]
  );
}

export const resolveActivityAssessment = (
  activityAssessmentAndQuestions: ActivityAssessmentQuery_ | undefined,
  isMSSAlignedAndCompleted?: boolean,
  isActivityAlignedAndCompleted?: boolean
): { activityAssessment: ActivityAssessment; objectives: ObjectiveWithQuestions[] } | undefined => {
  // TODO: Return loading and error states instead?
  if (
    !activityAssessmentAndQuestions?.activityAssessment ||
    !activityAssessmentAndQuestions.objectives
  )
    return undefined;

  const { resolvedQuestions, resolvedActivityTag } = resolveActivity(
    activityAssessmentAndQuestions.objectives.flatMap((o) => o.questions) ?? [],
    activityAssessmentAndQuestions.activityAssessment?.activityVersion.tagExpression ??
      DEFAULT_ACTIVITY_TAG,
    undefined,
    isMSSAlignedAndCompleted,
    isActivityAlignedAndCompleted
  );

  const questionsSortedByObjective =
    activityAssessmentAndQuestions.objectives
      .filter((o) => o.questions.length > 0)
      .sort(sortObjectives) ?? [];

  const resolvedActivityAssessment = {
    ...activityAssessmentAndQuestions.activityAssessment,
    activityTag: resolvedActivityTag,
  };

  return {
    activityAssessment: resolvedActivityAssessment,
    objectives: questionsSortedByObjective.map((objective) => {
      return {
        ...objective,
        questions: resolvedQuestions
          .filter((rq) => rq.objective.key === objective.key)
          .map((rq) => ({
            ...rq,
            ...objective.questions.find((q) => q.id === rq.id)?.olderRevisions,
          })),
        // oldQuestions: oldResolvedQuestions
        //   .filter(
        //     (rq) =>
        //       rq.objective.key === objective.key &&
        //       objective.oldQuestions.find((q) => q.id === rq.id)
        //   )
        //   .map((rq) => ({
        //     ...rq,
        //     ...objective.oldQuestions.find((q) => q.id === rq.id)?.newerRevisions,
        //   })),
      };
    }),
  };
};

export const useActivityAssessment = (id: ActivityAssessment['id'], version?: number) => {
  const { currentVersion } = useActivityAssessmentVersion(id, { skip: !!version });
  const { data, ...rest } = useActivityAssessmentQuery({
    variables: { activityAssessmentId: id, version: version ?? currentVersion ?? 1 },
    skip: !id || (!version && !currentVersion),
  });

  const resolvedActivityAssessmentAndQuestions = useMemo(() => {
    return resolveActivityAssessment(data);
  }, [id, data]);

  return {
    data: resolvedActivityAssessmentAndQuestions,
    ...rest,
  };
};

export const useActivityAssessmentWithAnswers = (
  id: ActivityAssessment['id'],
  version?: number
) => {
  const { currentVersion } = useActivityAssessmentVersion(id, { skip: !!version });

  // console.log('current version', currentVersion);

  const {
    data: objectiveData,
    loading: objectiveloading = true,
    error: objectiveError,
  } = useActivityAssessment(id, version);

  // objectiveData?.activityAssessment.activityVersion;
  const {
    data: answerData,
    loading: answerLoading = true,
    error: answerError,
  } = useActivityAssessmentAnswersQuery({
    variables: {
      activityAssessmentId: id,
      version: version ?? currentVersion ?? 1,
    },
    skip: !id || (!version && !currentVersion),
  });

  const answerMap = useMemo(
    () =>
      answerData?.answers.reduce(
        (map, answer) => ({ ...map, [answer.id]: answer }),
        {} as Record<string, Answer>
      ) || {},
    [answerData]
  );

  const objectives = useMemo(() => {
    return (
      objectiveData?.objectives.map((o) => ({
        ...o,
        questions: o.questions.map((q) => ({
          ...q,
          answer: { ...q.answer, ...answerMap[q?.answer?.id] },
          subQuestions: q.subQuestions?.map((subQuestion) => ({
            ...subQuestion,
            questions: subQuestion.questions.map((sqQuestion) => ({
              ...sqQuestion,
              answer: {
                ...sqQuestion.answer,
                ...answerMap[sqQuestion.answer?.id],
              },
            })),
          })),
        })),
      })) ?? []
    );
  }, [objectiveData, answerMap]);

  return {
    data: { ...objectiveData, objectives },
    loading: objectiveloading || answerLoading,
    error: objectiveError || answerError,
  };
};

export const useActivityResult = (id: ActivityAssessment['id']) => {
  const { data, ...rest } = useActivityAssessment(id);

  const result = useMemo(() => {
    const { activityAssessment, objectives } = data ?? {};
    if (!activityAssessment || !objectives) {
      return undefined;
    }
    if (activityAssessment.activity.reference === GENERAL_ACTIVITY_REF) {
      return getGeneralAssessmentResult({ activityAssessment, objectives });
    }
    return getActivityResult({ activityAssessment, objectives });
  }, [data, id]);

  return { data: result, ...rest };
};

export const useUpsertCompanyAssessment = () => {
  const { companyId } = useCurrentCompanyId();
  const [upsertCompanyAssessment] = useUpsertCompanyAssessmentMutation();
  const generalFinancials = createFinancials();

  const { getNewestValidActivityVersion } = useGetNewestValidActivityVersion();

  return useCallback(
    async (
      assessment: {
        id?: string;
        title: string;
        contactPersonId: string;
        startDate: string;
        businessUnits: Array<{ id: string; isDeleted: boolean }>;
      },
      cAssessment: CompanyAssessment
    ) => {
      const startDate = assessment.startDate ?? startOfYear(new Date());
      const endDate = endOfYear(stringToDate(assessment.startDate));

      const generalActivityVersionNumber = await getNewestValidActivityVersion(
        GENERAL_ACTIVITY_REF,
        startDate,
        assessment.id ?? '00000000-0000-0000-0000-000000000000'
      );

      return upsertCompanyAssessment({
        variables: {
          cAssessment: {
            id: assessment?.id ?? undefined,
            startDate: startDate,
            endDate: endDate,
            companyId: companyId,
            isLocked: cAssessment?.isLocked ?? false,
            // aggregateId: cAssessment?.aggregate?.id,
            aggregate: {
              data: {
                id: cAssessment?.aggregate?.id,
                title: assessment.title,
                interval: 'yearly',
                contactPersonId: assessment.contactPersonId,
                startDate: assessment.startDate ?? startDate,
                companyId: companyId,
              },
              on_conflict: {
                constraint: CompanyAssessmentAggregate_Constraint_.CompanyAssessmentAggregatePkey_,
                update_columns: [
                  CompanyAssessmentAggregate_Update_Column_.StartDate_,
                  CompanyAssessmentAggregate_Update_Column_.Title_,
                  CompanyAssessmentAggregate_Update_Column_.ContactPersonId_,
                ],
              },
            },
            bAssesssments: {
              data: [
                ...assessment.businessUnits.map(({ id, isDeleted }) => ({
                  businessUnitId: id,
                  deletedAt: isDeleted ? 'now()' : null,
                })),
                {
                  // General activity for the company assessment
                  hasGeneralAssessment: true,
                  activityReports: {
                    data: [
                      {
                        activityRef: GENERAL_ACTIVITY_REF,
                        activityVersionNumber: generalActivityVersionNumber,
                        financials: {
                          data: {
                            ...generalFinancials,
                          },
                          on_conflict: {
                            constraint: Financials_Constraint_.FinancialsReportId_801651ecUniq_,
                            update_columns: [Financials_Update_Column_.UpdatedAt_],
                          },
                        },
                      },
                    ],
                    on_conflict: {
                      constraint:
                        ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
                      update_columns: [ActivityReport_Update_Column_.CachedResultId_],
                    },
                  },
                } as BusinessUnitAssessment_Insert_Input_,
              ],
              on_conflict: {
                constraint:
                  BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
                update_columns: [BusinessUnitAssessment_Update_Column_.DeletedAt_],
              },
            },
          },
        },
        refetchQueries: [CompanyAssessmentDocument_, CompanyAssessmentsDocument_],
        awaitRefetchQueries: true,
      });
    },
    [upsertCompanyAssessment, getNewestValidActivityVersion]
  );
};

export type DuplicateAssessmentOptions = {
  withStructure: boolean;
  withActivities: boolean;
  withAnswers: boolean;
  withDocuments: boolean;
  withNotes: boolean;
  withComments: boolean;
  withFinancials: boolean;
};

export const defaultDuplicationOptions: DuplicateAssessmentOptions = {
  withStructure: true,
  withActivities: true,
  withAnswers: true,
  withDocuments: true,
  withNotes: true,
  withComments: true,
  withFinancials: true,
};

const duplicateAnswer = (
  answer: NonNullable<
    AssessmentForDuplicationQuery_['assessment']
  >['businessUnits'][number]['activityReports'][number]['answers'][number],
  companyId: string,
  options: DuplicateAssessmentOptions
): Answer_Arr_Rel_Insert_Input_['data'][number] => {
  const result: Answer_Arr_Rel_Insert_Input_['data'][number] = {
    questionId: answer.questionId,
    answeredById: answer.answeredById,
    data: options?.withAnswers ? answer.data : undefined,
    thread: options?.withComments
      ? {
          data: {
            companyId: companyId,
            comments: {
              data: (answer.thread?.comments ?? [])?.map((c) => ({
                data: c.data,
                authorId: c.authorId,
                createdAt: c.createdAt,
                updatedAt: c.updatedAt,
                duplicatedFrom: c.id,
              })),
            },
          },
          on_conflict: {
            constraint: Thread_Constraint_.ThreadAnswerIdKey_,
            update_columns: [Thread_Update_Column_.AnswerId_],
          },
        }
      : undefined,
    noteHistory: {
      data: {
        notes: {
          data: options?.withNotes
            ? answer.noteHistory?.notes?.map((note) => ({
                body: note.body,
                authorId: note.authorId,
                updatedAt: note.updatedAt,
              })) ?? []
            : [],
        },
      },
      on_conflict: {
        constraint: NoteHistory_Constraint_.NoteHistoryAnswerIdKey_,
        update_columns: [NoteHistory_Update_Column_.AnswerId_],
      },
    },
    attachmentBox: {
      data: {
        attachments: {
          data: options?.withDocuments
            ? (answer.attachmentBox?.attachments ?? [])?.map((at) => ({
                fileId: at.fileId,
              }))
            : [],
          on_conflict: {
            constraint: Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
            update_columns: [Attachment_Update_Column_.AttachmentBoxId_],
          },
        },
      },
      on_conflict: {
        constraint: AttachmentBox_Constraint_.AttachmentBoxAnswerIdKey_,
        update_columns: [AttachmentBox_Update_Column_.AnswerId_],
      },
    },
  };
  return result;
};

export const useDuplicateAssessment = () => {
  const toast = useToast();
  const [saveCompanyAssessment] = useUpsertCompanyAssessmentMutation();
  const [saveReportingGroups] = useUpsertReportingGroupsMutation();
  const { t } = useTranslation(['assessment']);
  return useCallback(
    async (
      cAssessmentId: string,
      newStartDate: Date,
      options: DuplicateAssessmentOptions = defaultDuplicationOptions
    ) => {
      const newEndDate = add(newStartDate, {
        years: 1,
      });
      const { data } = await nhost.graphql.request<
        AssessmentForDuplicationQuery_,
        AssessmentForDuplicationQueryVariables_
      >(AssessmentForDuplicationDocument_, {
        id: cAssessmentId,
      });

      const assessment = data?.assessment;
      const reportingGroups = data?.reportingGroups ?? [];

      try {
        if (assessment) {
          const assessmentGeneralBA = assessment?.generalAssessment?.[0];

          const bAssessmentsWithoutCompanyGA = assessment?.businessUnits?.filter(
            (bAssessments) => bAssessments.businessUnit !== null
          );
          const bAssessments: BusinessUnitAssessment_Arr_Rel_Insert_Input_ = {
            data: [
              {
                hasGeneralAssessment: assessmentGeneralBA.hasGeneralAssessment,
                businessUnitId: null,
                activityReports: {
                  data: assessmentGeneralBA?.activityReports?.map((ar) => ({
                    activityRef: ar.activityRef,
                    activityVersionNumber: ar.activityVersionNumber,
                    financials: {
                      data: options?.withFinancials
                        ? {
                            revenue: ar.financials?.revenue ?? 0,
                            capex: ar.financials?.capex ?? 0,
                            opex: ar.financials?.opex ?? 0,
                            adaptationCapex: ar.financials?.adaptationCapex ?? 0,
                            adaptationOpex: ar.financials?.adaptationOpex ?? 0,
                            isEstimate: ar.financials?.isEstimate ?? true,
                            noteHistory: {
                              data: {
                                notes: {
                                  data: options?.withNotes
                                    ? ar.financials?.noteHistory?.notes?.map((note) => ({
                                        body: note.body,
                                        authorId: note.authorId,
                                        updatedAt: note.updatedAt,
                                      })) ?? []
                                    : [],
                                },
                              },
                              on_conflict: {
                                constraint: NoteHistory_Constraint_.NoteHistoryFinancialsIdKey_,
                                update_columns: [NoteHistory_Update_Column_.FinancialsId_],
                              },
                            },
                            attachmentBox: {
                              data: {
                                attachments: {
                                  data: options?.withDocuments
                                    ? (ar.financials?.attachmentBox?.attachments ?? [])?.map(
                                        (at) => ({
                                          fileId: at.fileId,
                                        })
                                      )
                                    : [],
                                  on_conflict: {
                                    constraint:
                                      Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                                    update_columns: [Attachment_Update_Column_.AttachmentBoxId_],
                                  },
                                },
                              },
                              on_conflict: {
                                constraint: AttachmentBox_Constraint_.AttachmentBoxFinancialsIdKey_,
                                update_columns: [AttachmentBox_Update_Column_.FinancialsId_],
                              },
                            },
                          }
                        : createFinancials(0, 0, 0, 0),
                      on_conflict: {
                        constraint: Financials_Constraint_.FinancialsReportId_801651ecUniq_,
                        update_columns: [
                          Financials_Update_Column_.Revenue_,
                          Financials_Update_Column_.Capex_,
                          Financials_Update_Column_.Opex_,
                          Financials_Update_Column_.AdaptationCapex_,
                          Financials_Update_Column_.AdaptationOpex_,
                          Financials_Update_Column_.IsEstimate_,
                        ],
                      },
                    },
                    answers: {
                      data:
                        ar.answers?.map((a) => duplicateAnswer(a, assessment.companyId, options)) ??
                        [],
                      on_conflict: {
                        constraint: Answer_Constraint_.AnswerQuestionIdReportId_22331f7fUniq_,
                        update_columns: [Answer_Update_Column_.Data_],
                      },
                    },
                  })),
                  on_conflict: {
                    constraint:
                      ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
                    update_columns: [ActivityReport_Update_Column_.CachedResultId_],
                  },
                },
              },
              ...(options.withStructure ? bAssessmentsWithoutCompanyGA : [])?.map((bu) => ({
                businessUnitId: bu.businessUnit?.id,
                hasGeneralAssessment: bu.hasGeneralAssessment,
                orderIndex: bu?.orderIndex,
                activityReports: {
                  data: options.withActivities
                    ? bu.activityReports.map((act) => ({
                        activityRef: act.activityRef,
                        activityVersionNumber: act.activityVersionNumber,
                        financials: {
                          data: options?.withFinancials
                            ? {
                                revenue: act.financials?.revenue ?? 0,
                                capex: act.financials?.capex ?? 0,
                                opex: act.financials?.opex ?? 0,
                                adaptationCapex: act.financials?.adaptationCapex ?? 0,
                                adaptationOpex: act.financials?.adaptationOpex ?? 0,
                                isEstimate: act.financials?.isEstimate ?? true,
                                noteHistory: {
                                  data: {
                                    notes: {
                                      data: options?.withNotes
                                        ? act.financials?.noteHistory?.notes?.map((note) => ({
                                            body: note.body,
                                            authorId: note.authorId,
                                            updatedAt: note.updatedAt,
                                          })) ?? []
                                        : [],
                                    },
                                  },
                                  on_conflict: {
                                    constraint: NoteHistory_Constraint_.NoteHistoryFinancialsIdKey_,
                                    update_columns: [NoteHistory_Update_Column_.FinancialsId_],
                                  },
                                },
                                attachmentBox: {
                                  data: {
                                    attachments: {
                                      data: options?.withDocuments
                                        ? (act.financials?.attachmentBox?.attachments ?? [])?.map(
                                            (at) => ({
                                              fileId: at.fileId,
                                            })
                                          )
                                        : [],
                                      on_conflict: {
                                        constraint:
                                          Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                                        update_columns: [
                                          Attachment_Update_Column_.AttachmentBoxId_,
                                        ],
                                      },
                                    },
                                  },
                                  on_conflict: {
                                    constraint:
                                      AttachmentBox_Constraint_.AttachmentBoxFinancialsIdKey_,
                                    update_columns: [AttachmentBox_Update_Column_.FinancialsId_],
                                  },
                                },
                              }
                            : createFinancials(0, 0, 0, 0),
                          on_conflict: {
                            constraint: Financials_Constraint_.FinancialsReportId_801651ecUniq_,
                            update_columns: [
                              Financials_Update_Column_.Revenue_,
                              Financials_Update_Column_.Capex_,
                              Financials_Update_Column_.Opex_,
                              Financials_Update_Column_.AdaptationCapex_,
                              Financials_Update_Column_.AdaptationOpex_,
                              Financials_Update_Column_.IsEstimate_,
                            ],
                          },
                        },
                        answers: {
                          data:
                            act.answers?.map((a) =>
                              duplicateAnswer(a, assessment.companyId, options)
                            ) ?? [],
                          on_conflict: {
                            constraint: Answer_Constraint_.AnswerQuestionIdReportId_22331f7fUniq_,
                            update_columns: [Answer_Update_Column_.Data_],
                          },
                        },
                        attachmentBox: {
                          data: {
                            attachments: {
                              data: options?.withDocuments
                                ? (act.attachmentBox?.attachments ?? [])?.map((at) => ({
                                    fileId: at.fileId,
                                  }))
                                : [],
                              on_conflict: {
                                constraint:
                                  Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                                update_columns: [Attachment_Update_Column_.AttachmentBoxId_],
                              },
                            },
                          },
                          on_conflict: {
                            constraint: AttachmentBox_Constraint_.AttachmentBoxActivityReportIdKey_,
                            update_columns: [AttachmentBox_Update_Column_.ActivityReportId_],
                          },
                        },
                        noteHistory: {
                          data: {
                            notes: {
                              data: options?.withNotes
                                ? act.noteHistory?.notes?.map((note) => ({
                                    body: note.body,
                                    authorId: note.authorId,
                                    updatedAt: note.updatedAt,
                                  })) ?? []
                                : [],
                            },
                          },
                          on_conflict: {
                            constraint: NoteHistory_Constraint_.NoteHistoryActivityReportIdKey_,
                            update_columns: [NoteHistory_Update_Column_.ActivityReportId_],
                          },
                        },
                      }))
                    : [],
                  on_conflict: {
                    constraint:
                      ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
                    update_columns: [ActivityReport_Update_Column_.CachedResultId_],
                  },
                },
              })),
            ],
            on_conflict: {
              constraint:
                BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
              update_columns: [BusinessUnitAssessment_Update_Column_.CAssessmentId_],
            },
          };
          const res = await saveCompanyAssessment({
            variables: {
              cAssessment: {
                endDate: newEndDate,
                startDate: newStartDate,
                companyId: assessment?.companyId,
                aggregate: {
                  data: {
                    title: `[COPY] ${assessment?.aggregate?.title}`,
                    interval: assessment?.aggregate?.interval,
                    contactPersonId: assessment?.aggregate?.contactPerson?.id,
                    startDate: newStartDate,
                    companyId: assessment?.companyId,
                  },
                },
                bAssesssments: bAssessments,
              },
            },
            refetchQueries: [CompanyAssessmentsDocument_],
          });
          if (options.withStructure && reportingGroups?.length) {
            const createdBusinessUnits = (res?.data?.cAssessment?.bAssessments ?? [])
              .map((b) => b.businessUnit)
              ?.filter(Boolean);
            await saveReportingGroups({
              variables: {
                input: reportingGroups
                  .filter((gr) => !gr.parentId)
                  .map((gr) => ({
                    companyAssessmentId: res?.data?.cAssessment?.id,
                    ownerId: gr.ownerId,
                    name: gr.name,
                    orderIndex: gr.orderIndex,
                    businessUnits: {
                      data: assessment.businessUnits
                        ?.filter((bu) => bu.reportingGroupId === gr.id)
                        .map((bu) =>
                          createdBusinessUnits.find((cbu) => cbu?.name === bu.businessUnit?.name)
                        )
                        .map((bu) => ({
                          businessUnitId: bu?.id,
                          cAssessmentId: res?.data?.cAssessment?.id,
                        })),
                      on_conflict: {
                        constraint:
                          BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
                        update_columns: [BusinessUnitAssessment_Update_Column_.ReportingGroupId_],
                      },
                    },
                    reportingSubGroups: {
                      data: reportingGroups
                        .filter((sub) => sub.parentId === gr.id)
                        .map((sub) => ({
                          companyAssessmentId: res?.data?.cAssessment?.id,
                          name: sub.name,
                          orderIndex: sub.orderIndex,
                          ownerId: sub.ownerId,
                          businessUnits: {
                            data: assessment.businessUnits
                              ?.filter((bu) => bu.reportingGroupId === sub.id)
                              .map((bu) =>
                                createdBusinessUnits.find(
                                  (cbu) => cbu?.name === bu.businessUnit?.name
                                )
                              )
                              .map((bu) => ({
                                businessUnitId: bu?.id,
                                cAssessmentId: res?.data?.cAssessment?.id,
                              })),
                            on_conflict: {
                              constraint:
                                BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
                              update_columns: [
                                BusinessUnitAssessment_Update_Column_.ReportingGroupId_,
                              ],
                            },
                          },
                        })),
                    },
                  })),
              },
              refetchQueries: [CompanyAssessmentsDocument_],
            });
          }
          toast({
            text: t('assessment:hook.toast.duplicated.successful'),
          });
        }
      } catch (e) {
        console.error(e);
        toast({
          text: t('assessment:hook.toast.duplicated.failed'),
          variant: 'danger',
        });
      }
    },
    [saveCompanyAssessment]
  );
};

export const useFlagAndUnlockAssessment = () => {
  const [flagAssessment] = useFlagCompanyAssessmentAsDirtyMutation();
  const [updateAssessmentLock] = useUpdateAssessmentLockMutation();
  const toast = useToast();
  const { t } = useTranslation(['common']);

  const flagAndUnlockAssessment = async (assessment: Partial<CompanyAssessment>) => {
    if (!assessment) return;

    await flagAssessment({ variables: { cachedResultId: assessment.cachedResultId } });

    if (assessment.isLocked) {
      await updateAssessmentLock({
        variables: { companyAssessmentId: assessment.id, isLocked: false },
      });
      toast({
        text: t('common:assessment.isLocked.toast.backInProgress'),
        duration: null,
      });
    }
  };

  return [flagAndUnlockAssessment];
};
