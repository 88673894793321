import { VStack } from '@chakra-ui/react';
import { Modal } from 'Molecules';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Typography, colors } from 'Tokens';
import { QuartersObject, YearObject } from '../Requirement';
import { HistogramIcon } from 'Tokens/Icons/Data';

export const ChartNoData = () => {
  return (
    <VStack h="100%" w="100%" justifyContent="center" spacing="16px">
      <HistogramIcon fontSize={16} />
      <VStack spacing="2px">
        <Typography variant="detailStrong">No data available</Typography>
        <Typography variant="detail">No datapoints were reported yet</Typography>
      </VStack>
    </VStack>
  );
};

export const YearGraph = ({
  year,
  height,
  margins,
  showXAxis = true,
}: {
  year: number;
  height?: number;
  margins?: { top: number; right: number; left: number; bottom: number };
  showXAxis?: boolean;
}) => {
  if (!year) return <ChartNoData />;
  return (
    <ResponsiveContainer width="100%" height={height ?? '100%'}>
      <LineChart
        width={550}
        height={height}
        data={[
          { x: '0', y: year },
          { x: 'Year', y: year },
        ]}
        margin={
          margins ?? {
            top: 8,
            right: 25,
            left: 0,
            bottom: 8,
          }
        }
      >
        <CartesianGrid strokeDasharray="3 3" />
        {showXAxis && <XAxis dataKey="x" fontSize="14px" />}
        <YAxis fontSize="14px" />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="y"
          stroke={colors.text['info.accent']}
          name="Year"
          activeDot={{ r: 6 }}
          legendType="none"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const GraphCard = ({
  data,
  height,
  margins,
  showXAxis = true,
}: {
  data: QuartersObject | YearObject;
  height: number;
  margins?: { top: number; right: number; left: number; bottom: number };
  showXAxis?: boolean;
}) => {
  const isYearly = Object.keys(data).includes('Year');
  const year = (data as YearObject)?.Year;
  const graphData = Object.entries(data).map(([key, value]) => ({
    quarter: key,
    data: value,
  }));
  if (isYearly)
    return <YearGraph year={year} height={height} margins={margins} showXAxis={showXAxis} />;

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        width={550}
        height={height}
        data={graphData}
        margin={
          margins && {
            top: 8,
            right: 25,
            left: 0,
            bottom: 8,
          }
        }
      >
        <CartesianGrid strokeDasharray="3 3" />
        {showXAxis && <XAxis dataKey="quarter" style={{ fontSize: '14px' }} />}
        <YAxis style={{ fontSize: '14px' }} />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="data"
          stroke={colors.text['info.accent']}
          activeDot={{ r: 6 }}
          legendType="none"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const MetricsGraphModal = ({
  isOpen,
  onClose,
  data,
  metricName,
}: {
  isOpen: boolean;
  onClose: () => void;
  data: QuartersObject | YearObject;
  metricName: string;
}) => (
  <Modal isOpen={isOpen} onClose={onClose} title={metricName} size="md" hasFooter={false}>
    <VStack
      alignItems="start"
      border="1px solid"
      borderColor="border.decorative"
      borderRadius="8px"
      w="100%"
      h="100%"
      pt="8px"
    >
      <GraphCard data={data} height={300} />
    </VStack>
  </Modal>
);
