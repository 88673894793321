import { VStack } from '@chakra-ui/react';
import { TextareaWithCharCounter } from 'Atoms';
import { Typography } from 'Tokens';
import { useCurrentCompany, useToast } from 'utils/hooks';
import {
  GroupAssessmentSubsidiariesFieldsFragment_,
  useNudgePortfolioCompaniesMutation,
} from 'models';
import { Modal, ModalProps } from 'Molecules';
import { useState } from 'react';
import { useTranslation } from 'utils/translation';
import { groupOwnerContext } from 'containers/GroupAssessment';

export const NudgeSubsidiariesModal = ({
  isOpen,
  onClose,
  subsidiaries,
}: { subsidiaries: GroupAssessmentSubsidiariesFieldsFragment_[] } & Omit<
  ModalProps,
  'children'
>) => {
  const { t } = useTranslation('portfolio');
  const toast = useToast();
  const [customMessage, setCustomMessage] = useState<string>(
    t('portfolio:nudge.defaultCustomMessage')
  );
  const { company } = useCurrentCompany();

  const [nudgePortfolioCompany] = useNudgePortfolioCompaniesMutation({
    context: groupOwnerContext,
  });

  const onNudge = async () => {
    return nudgePortfolioCompany({
      variables: {
        recipients: subsidiaries
          .filter((c) => !!c.contactPersonEmail)
          .map((c) => ({
            email: c.contactPersonEmail ?? '',
            displayName: c.contactPersonName ?? '',
            companyName: c.company?.name ?? '',
            companyId: c.company?.id ?? '',
          })),
        data: {
          customMessage,
          portfolioOwner: company?.name ?? '...',
        },
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('portfolio:nudge.modal.title')}
      size="md"
      confirmText={t('portfolio:nudge.modal.submit')}
      onConfirm={() =>
        onNudge()
          .then(onClose)
          .catch((err) =>
            toast({
              text: `${t('portfolio:nudge.modal.error.title')}, 
              ${err.response?.data?.message ?? t('portfolio:nudge.modal.error.message')}`,
              variant: 'danger',
            })
          )
      }
    >
      <VStack spacing="16px" width="100%" alignItems="flext-start">
        <Typography variant="body"> {t('portfolio:nudge.modal.all.description')}</Typography>
        <Typography variant="h3">{t('portfolio:nudge.modal.preview')}</Typography>
        <TextareaWithCharCounter
          maxLength={300}
          value={customMessage}
          onChange={setCustomMessage}
          minHeight="100px"
        />
      </VStack>
    </Modal>
  );
};
