import bm25 from 'wink-bm25-text-search';
import winkNLP, { ItsHelpers, WinkMethods } from 'wink-nlp';
import model from 'wink-eng-lite-web-model';
import ActivitiesSearchJson from 'utils/constants/activitiesSearch.json';
import synonymsArray from 'synonyms-array';
import synonyms from 'synonyms';
import { Activity } from 'models';
import './search.d';

export const getQuerySynonyms = (searchQuery: string | string[]) => {
  const tokens = searchQuery instanceof Array ? searchQuery : searchQuery.split(' ');
  const querySynonyms = tokens.map((token) => {
    // Raw synonyms
    const defaultSynonyms = synonymsArray.get(token);

    // POS (part of speech) synonyms
    const { v = [], n = [], s = [] } = synonyms(token) ?? { v: [], n: [], s: [] };
    const posSynonyms = [...v, ...n, ...s];
    return [...defaultSynonyms, ...posSynonyms] as string[];
  });
  return [...new Set(querySynonyms.flatMap((q) => q))];
};

const extractQueryKeywords = (its: ItsHelpers, nlp: WinkMethods, text: string) => {
  const tokens: Array<string> = [];
  nlp
    .readDoc(text)
    .tokens()
    // Use only words ignoring punctuations etc and from them remove stop words
    .filter((t) => t.out(its.type) === 'word' && !t.out(its.stopWordFlag))
    // Extract stem of the word
    .each((t) => tokens.push(t.out(its.stem)));

  return tokens;
};

export const simpleSearch = (searchQueries: string[], activity: Activity): boolean => {
  try {
    const containsAny = searchQueries.some(
      (query) =>
        activity.name.toLowerCase().search(query.toLowerCase()) !== -1 ||
        activity.naceCodes
          .map(({ code }) => code)
          .join(' ')
          .toLowerCase()
          .search(query.toLowerCase()) !== -1
    );
    return containsAny;
  } catch (e) {
    // silently fail
    return false;
  }
};
const winkNLPSearch = (its: ItsHelpers, nlp: WinkMethods, searchQuery: string) => {
  const engine = bm25();
  engine.importJSON(JSON.stringify(ActivitiesSearchJson ?? {}));
  engine.definePrepTasks([(text: string) => extractQueryKeywords(its, nlp, text)]);
  return engine.search(searchQuery);
};

export const smartSearchActivities = async (searchQuery: string, activities: Array<Activity>) => {
  const nlp = winkNLP(model);
  const its = nlp.its;

  try {
    // Search by raw query
    const winkResults = await winkNLPSearch(its, nlp, searchQuery);

    const rawQueryResults = [...(winkResults as Array<[ref: string, score: number]>)];

    // Search by query semantics
    const tokens = extractQueryKeywords(its, nlp, searchQuery);
    const querySynonyms = getQuerySynonyms(tokens);
    const synonymResults = querySynonyms.reduce((acc: string[], curToken: string) => {
      const synonymSearchResults = winkNLPSearch(its, nlp, curToken);
      return [...acc, ...synonymSearchResults];
    }, []);

    const results = [...rawQueryResults, ...synonymResults];

    if (results.length) return [...new Set(results)].map(([ref, score]) => ({ ref, score }));

    return activities
      .filter((a) => simpleSearch([...tokens, ...querySynonyms], a))
      .map((a) => ({ ref: a.reference, score: 1 }));
  } catch (e) {
    // silently fail
    return (
      activities
        .filter((a) => simpleSearch([searchQuery], a))
        .map((a) => ({ ref: a.reference, score: 1 })) ?? []
    );
  }
};
