import { GroupAssessmentDocument_, useToggleShareCompanyAssessmentWithGroupMutation } from 'models';
import { useCallback } from 'react';

export const useShareCompanyAssessmentWithGroup = () => {
  const [toggleCompanySharing, { loading }] = useToggleShareCompanyAssessmentWithGroupMutation();
  const shareCompanyAssessment = useCallback(
    (subsidiaryId: string, ownerGroupAssessmentId: string, assessmentId: string) => {
      return toggleCompanySharing({
        variables: {
          object: {
            id: subsidiaryId,
            ownerGroupAssessmentId: ownerGroupAssessmentId,
            sharedCompanyAssessmentId: assessmentId,
          },
        },
        refetchQueries: [GroupAssessmentDocument_],
        awaitRefetchQueries: true,
      }).then((res) => res);
    },
    [toggleCompanySharing]
  );
  return { shareCompanyAssessment, loading };
};

export const useUnshareCompanyAssessmentWithGroup = () => {
  const [toggleGroupSharing, { loading }] = useToggleShareCompanyAssessmentWithGroupMutation();

  const unshareCompanyAssessment = useCallback(
    (subsidiaryId: string, ownerGroupAssessmentId: string) => {
      return toggleGroupSharing({
        variables: {
          object: {
            id: subsidiaryId,
            ownerGroupAssessmentId: ownerGroupAssessmentId,
            sharedCompanyAssessmentId: null,
          },
        },
        refetchQueries: [GroupAssessmentDocument_],
        awaitRefetchQueries: true,
      }).then((res) => res);
    },
    [toggleGroupSharing]
  );
  return { unshareCompanyAssessment, loading };
};
