import { useMaterialStandardId } from 'containers/Esrs';
import {
  Esrs_ActionTarget_Constraint_,
  GetActionsDocument_,
  Esrs_ActionReportingUnit_Constraint_,
  Esrs_ActionTarget_Update_Column_,
  useDeleteActionMutation,
  useDeleteActionTargetMutation,
  useUpsertActionMutation,
  ActionFieldsFragment_,
  AttachmentBox_Constraint_,
  NoteHistory_Constraint_,
  AttachmentBox_Update_Column_,
  NoteHistory_Update_Column_,
  DocumentationFileDetailsFragment_,
  useUpsertAttachmentsMutation,
  DocumentationFile,
  useAddNoteMutation,
  useGetActionDocumentationByIdQuery,
  AttachmentBox,
  NoteHistory,
  useGetSubsidiariesQuery,
  Esrs_ActionSubsidiary_Constraint_,
  useDeleteActionSubsidiaryMutation,
  GetTargetsQuery_,
  useDeleteActionReportingsUnitMutation,
  EsrsAssessmentActionsDocument_,
  GetActionsDrDocument_,
  GetActionsQuery_,
  GetActionsDrQuery_,
  GetParentActionsDrQuery_,
  GetActionsSubsidiariesQuery_,
  useGetActionsDrQuery,
  useGetActionsSubsidiariesQuery,
  useGetParentActionsDrQuery,
  useGetTargetsQuery,
  GetDisclosureRequirementGroupsDocument_,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { REMOVE_DELETED } from '../Targets/TargetActionsSection';

export type ActionFields = {
  title: string;
  dueDate: Date;
  startDate: Date;
  ownerId: string;
  capex: string;
  noteReference: string | null;
  reportingUnits: { value: string; label: string }[];
  subsidiaries: { value: string; label: string }[];
  targets: { value: string; label: string }[];
  estimates: { [key: string]: string };
  documentation: string;
};

export type Action =
  | GetActionsQuery_['actions'][number]
  | NonNullable<
      GetActionsSubsidiariesQuery_['esrs']
    >['subsidiaries'][number]['materialStandards'][number]['actions'][number];

export type CombinedActionsData = {
  title: string;
  isSubOrParent: boolean;
  disclosureRequirementRef: string;
  earliestYear?: string;
  latestYear?: string;
  companyName?: string;
  targets: {
    title: string;
    id: number;
  }[];
  numberOfTargets: number;
  owner: string;
  actionId: number;
  action: Action;
};

export const useDeleteAction = () => {
  const [deleteAction] = useDeleteActionMutation();
  const toast = useToast();
  return useCallback(
    (
      onClose: () => void,
      actionToEdit: ActionFieldsFragment_ | undefined,
      setNewActionId: ((param: { value: string; label: string }) => void) | undefined
    ) => {
      deleteAction({
        variables: { actionId: actionToEdit?.id ?? '' },
        refetchQueries: [
          GetActionsDrDocument_,
          GetActionsDocument_,
          GetDisclosureRequirementGroupsDocument_,
        ],
        onCompleted: (res) => {
          setNewActionId?.({
            value: res?.delete_esrs_Actions_by_pk?.id,
            label: REMOVE_DELETED,
          });
        },
      })
        .then(() => {
          toast({ text: 'Action deleted!' });
          onClose();
        })
        .catch(() => toast({ text: 'Failed to delete action', variant: 'danger' }));
    },
    [deleteAction]
  );
};

export const useUpsertAction = () => {
  const [upsertAction] = useUpsertActionMutation();
  const [deleteActionTarget] = useDeleteActionTargetMutation();
  const [deleteActionReportingUnit] = useDeleteActionReportingsUnitMutation();
  const [deleteActionSubsidiary] = useDeleteActionSubsidiaryMutation();
  const [upsertAttachments] = useUpsertAttachmentsMutation();
  const [addNote] = useAddNoteMutation();
  const toast = useToast();
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);

  return useCallback(
    (
      data: ActionFields,
      onClose: () => void,
      actionToEdit: ActionFieldsFragment_ | undefined,
      setNewActionId: ((param: { value: string; label: string }) => void) | undefined,
      disclosureRequirementRef: string,
      userId: string,
      isGroup: boolean,
      note?: string | null,
      filesAttached?: DocumentationFileDetailsFragment_[],
      setNote?: (param: string | null) => void,
      setFilesAttached?: (param: DocumentationFileDetailsFragment_[]) => void,
      materialityAssessmentId?: string
    ) => {
      const toDelete: string[] =
        actionToEdit?.actionTargets
          .filter(
            (actionTarget) =>
              !data.targets.find((target) => target.value === actionTarget.target.id)
          )
          ?.map((actionTarget) => actionTarget.id) ?? [];

      const chosenTargets =
        actionToEdit === undefined
          ? data.targets?.map((target) => ({
              targetId: target.value,
              estimate: Number(data.estimates[`${target.value}`] ?? ''),
            }))
          : data.targets
              .filter(
                (target) =>
                  !actionToEdit?.actionTargets.find(
                    (actionTarget) =>
                      actionTarget.target.id === target.value &&
                      actionTarget.estimate === Number(data.estimates[`${target.value}`])
                  )
              )
              ?.map((target) => ({
                targetId: target.value,
                estimate: Number(data.estimates[`${target.value}`] ?? ''),
              })) ?? [];

      const reportingUnitToDelete: string[] =
        actionToEdit?.actionReportingUnits
          .filter((ru) => !data.reportingUnits.find((ruA) => ruA.value === ru.reportingUnit.id))
          .map((ru) => ru.id) ?? [];

      const chosenReportingUnits =
        actionToEdit === undefined
          ? data.reportingUnits?.map((ru) => ({
              reportingUnitId: ru.value,
            })) ?? []
          : data.reportingUnits
              .filter(
                (ruA) =>
                  !actionToEdit?.actionReportingUnits.find(
                    (ru) => ru.reportingUnit.id === ruA.value
                  )
              )
              ?.map((ru) => ({
                reportingUnitId: ru.value,
              })) ?? [];

      const subsidiariesToDelete: string[] =
        actionToEdit?.actionSubsidiaries
          .filter((as) => !data.subsidiaries.find((s) => s.value === as.id))
          ?.map((s) => s.id) ?? [];

      const chosenSubsidiaries =
        actionToEdit === undefined
          ? data.subsidiaries?.map((s) => ({
              subsidiaryAssessmentId: s.value,
            }))
          : data.subsidiaries
              .filter((s) => !actionToEdit?.actionSubsidiaries.find((as) => as.id === s.value))
              ?.map((s) => ({
                subsidiaryAssessmentId: s.value,
              })) ?? [];

      const noteAttachmentFields: { [key: string]: any } = {};

      if (!actionToEdit?.attachmentBox?.id) {
        noteAttachmentFields.attachmentBox = {
          data: {},
          on_conflict: {
            constraint: AttachmentBox_Constraint_.AttachmentBoxActionIdKey_,
            update_columns: [AttachmentBox_Update_Column_.ActionId_],
          },
        };
      }

      if (!actionToEdit?.noteHistory?.id) {
        noteAttachmentFields.noteHistory = {
          data: {},
          on_conflict: {
            constraint: NoteHistory_Constraint_.NoteHistoryActionIdKey_,
            update_columns: [NoteHistory_Update_Column_.ActionId_],
          },
        };
      }

      const actionRelation = isGroup
        ? {
            actionSubsidiaries: {
              data: chosenSubsidiaries ?? {},
              on_conflict: {
                constraint: Esrs_ActionSubsidiary_Constraint_.ActionSubsidiaryPkey_,
              },
            },
          }
        : {
            actionReportingUnits: {
              data: chosenReportingUnits ?? [],
              on_conflict: {
                constraint: Esrs_ActionReportingUnit_Constraint_.ActionReportingUnitPkey_,
              },
            },
          };

      upsertAction({
        variables: {
          input: {
            id: actionToEdit?.id,
            title: data.title,
            deadline: data.dueDate,
            startDate: data.startDate,
            disclosureRequirementRef,
            assessmentId: materialityAssessmentId ?? companyAssessmentId,
            ownerId: data.ownerId,
            capex: Number(data.capex),
            noteReference: data.noteReference,
            actionTargets: {
              data: chosenTargets,
              on_conflict: {
                constraint: Esrs_ActionTarget_Constraint_.ActionTargetActionIdTargetIdKey_,
                update_columns: [Esrs_ActionTarget_Update_Column_.Estimate_],
              },
            },
            ...actionRelation,
            ...noteAttachmentFields,
          },
        },
        refetchQueries: [
          GetActionsDrDocument_,
          GetActionsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          EsrsAssessmentActionsDocument_,
        ],
        onCompleted: (res) => {
          setNewActionId?.({ value: res?.insert_esrs_Actions_one?.id ?? '', label: data.title });
        },
      })
        .then((res) => {
          if (filesAttached?.length) {
            const fileData =
              filesAttached?.map((file: DocumentationFile) => {
                return {
                  attachmentBoxId: res.data?.insert_esrs_Actions_one?.attachmentBox?.id,
                  fileId: file.id,
                };
              }) ?? [];

            upsertAttachments({
              variables: {
                attachmentInputs: fileData,
              },
            }).then(() => setFilesAttached?.([]));
          }
          if (note) {
            addNote({
              variables: {
                noteInput: {
                  body: note,
                  noteHistoryId: res.data?.insert_esrs_Actions_one?.noteHistory?.id,
                  authorId: userId,
                },
              },
            }).then(() => setNote?.(''));
          }
          deleteActionTarget({
            variables: {
              id: toDelete,
            },
            refetchQueries: [GetActionsDrDocument_, GetActionsDocument_],
          });
          deleteActionReportingUnit({
            variables: {
              id: reportingUnitToDelete,
            },
            refetchQueries: [GetActionsDrDocument_],
          });
          deleteActionSubsidiary({
            variables: {
              id: subsidiariesToDelete,
            },
            refetchQueries: [GetActionsDrDocument_],
          });
          toast({ text: 'Action added' });
          onClose();
        })
        .catch(() => {
          toast({ text: 'Failed to add action', variant: 'danger' });
        });
    },
    [upsertAction]
  );
};

export const useCombineActions = (
  {
    actions,
    parent,
    subsidiaries,
  }: {
    actions?: GetActionsDrQuery_['DisclosureRequirement_by_pk'];
    parent?: GetParentActionsDrQuery_['esrs_Actions'];
    subsidiaries?: NonNullable<GetActionsSubsidiariesQuery_['esrs']>['subsidiaries'];
  },
  companyName?: string
) => {
  const mapTargets = (action: Action) => {
    return action.actionTargets.map((target: any) => ({
      title: target.target.direction
        ? target.target.direction.charAt(0).toUpperCase() +
          target.target.direction.slice(1) +
          ' ' +
          target.target.metric?.title.toLowerCase()
        : target.target.metric?.title ?? '',
      id: target.target.id,
    }));
  };

  const mapAction = (action: Action, isSubOrParent: boolean, company?: string) => ({
    title: action.title,
    isSubOrParent,
    earliestYear: String(action.startDate).split('-')[0],
    latestYear: String(action.deadline).split('-')[0],
    disclosureRequirementRef: actions?.reference ?? '',
    companyName: company,
    targets: mapTargets(action),
    numberOfTargets: action.actionTargets?.length,
    owner: action.owner?.displayName ?? '',
    actionId: action.id,
    action: action,
  });

  const currentData = useMemo(
    () =>
      (actions && actions?.actions?.map((action) => mapAction(action, false, companyName))) ?? [],
    [actions]
  );

  const subsidiariesData = useMemo(
    () =>
      subsidiaries
        ?.flatMap((s) =>
          s.materialStandards[0]?.actions.map((action) => mapAction(action, true, s.company.name))
        )
        .filter((s) => s !== undefined) ?? [],
    [subsidiaries]
  );

  const parentData = useMemo(
    () =>
      parent
        ?.map((action) =>
          mapAction(action, true, parent?.[0]?.materialStandard.esrsAssessment.company.name)
        )
        .filter((p) => p !== undefined) ?? [],
    [parent]
  );

  const combinedData: CombinedActionsData[] = useMemo(
    () => currentData?.concat(subsidiariesData).concat(parentData) ?? [],
    [currentData, subsidiariesData, parentData]
  );

  return combinedData;
};

export const useGetActionsData = () => {
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();

  const { company } = useCurrentCompany();
  const {
    companyAssessmentId,
    parentAssessmentId,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: actionsData, loading: actionsLoading } = useGetActionsDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      esrsAssessmentId,
      standardRef,
    },
    skip: !disclosureRequirementRef || !esrsAssessmentId || !standardRef,
  });

  const actionDR = useMemo(() => actionsData?.DisclosureRequirement_by_pk, [actionsData]);

  const { data: targetsData, loading: targetsLoading } = useGetTargetsQuery({
    variables: {
      assessmentId: companyAssessmentId,
      disclosureRequirementRef,
    },
    skip: !companyAssessmentId || !disclosureRequirementRef,
  });

  const targets = useMemo(() => targetsData?.targets, [targetsData]);

  const { data: subData, loading: subsidiariesLoading } = useGetActionsSubsidiariesQuery({
    variables: {
      esrsAssessmentId,
      standardRef,
    },
    skip: !esrsAssessmentId || !standardRef || !company?.isGroupOwner,
  });

  const subsidiaries = useMemo(() => subData?.esrs?.subsidiaries, [subData]);

  const { data: pData, loading: parentLoading } = useGetParentActionsDrQuery({
    variables: {
      parentStandardId: parentAssessmentId,
    },
    skip: !parentAssessmentId || !standardRef,
  });
  const parentActions = useMemo(() => pData?.esrs_Actions, [pData]);

  const combinedData = useCombineActions(
    { actions: actionDR, parent: parentActions, subsidiaries },
    company?.name
  );

  return {
    actionDR,
    combinedData,
    targets,
    loading:
      actionsLoading || targetsLoading || subsidiariesLoading || parentLoading || materialLoading,
  };
};

export const useGetActionsDocumentation = (
  actionToEdit: ActionFieldsFragment_ | undefined,
  isGroup: boolean
) => {
  const { esrsAssessmentId = '' } = useParams();
  const { data: subsidiariesData } = useGetSubsidiariesQuery({
    variables: { esrsAssessmentId },
    skip: !isGroup || !esrsAssessmentId,
  });
  const { data: documentationData, loading: documentationLoading } =
    useGetActionDocumentationByIdQuery({
      variables: {
        actionId: actionToEdit?.id,
      },
      skip: !actionToEdit?.id,
    });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_Actions_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.esrs_Actions_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );
  return {
    attachmentBox,
    noteHistory,
    documentationData,
    documentationLoading,
    subsidiariesData: subsidiariesData?.esrs?.subsidiaries,
  };
};

export const getSubsidiaries = (action?: ActionFieldsFragment_) => {
  return (
    action?.actionSubsidiaries.map((s) => ({
      value: s.subsidiaryAssessment.id,
      label: s?.subsidiaryAssessment?.company?.name ?? '',
    })) ?? []
  );
};

export const getReportingUnits = (action?: ActionFieldsFragment_) => {
  return (
    action?.actionReportingUnits.map((ru) => ({
      value: ru.reportingUnit.id,
      label: ru.reportingUnit.name ?? '',
    })) ?? []
  );
};

export const getTargets = (
  isNewActionFromTarget: boolean,
  targetsData: GetTargetsQuery_['targets'],
  targetId?: string,
  action?: ActionFieldsFragment_
) => {
  if (isNewActionFromTarget) {
    return [
      {
        value: targetId,
        label: targetsData.find((o) => o.id === targetId)?.metric?.title ?? '',
        estimate: null,
      },
    ];
  } else {
    return (
      action?.actionTargets?.map((actionTarget) => ({
        value: actionTarget.target.id,
        label: `Target: ${actionTarget.target?.metric?.title?.toLowerCase()}`,
        estimate: actionTarget.estimate,
      })) ?? []
    );
  }
};

const getEstimatesObject = (action?: ActionFieldsFragment_) => {
  const estimatesObject: { [key: string]: string } = {};
  action?.actionTargets?.forEach((item) => {
    estimatesObject[item.target.id] = String(item.estimate);
  });
  return estimatesObject;
};

export const getEstimates = (
  isNewActionFromTarget: boolean,
  action?: ActionFieldsFragment_,
  isReadOnly?: boolean
) => {
  if (isNewActionFromTarget && !isReadOnly) {
    return { targetId: '' };
  } else {
    return getEstimatesObject(action);
  }
};
