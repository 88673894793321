import { ActivityTagEnum, AnswerSet, ResolvedQuestion } from 'models';
import { ALL_QUESTIONS, resolveCondition } from './resolveCondition';

export const DEFAULT_ACTIVITY_TAG = ActivityTagEnum.green;

export function resolveActivityTag(
  condition: string,
  answers: AnswerSet,
  allQuestions: ResolvedQuestion[] = []
) {
  const vars = { [ALL_QUESTIONS]: allQuestions };
  return resolveCondition(condition, answers, vars);
}
// delete this
