import { InputGroup, InputLeftElement, NumberInput, NumberInputField } from '@chakra-ui/react';
import { ActionFieldsFragment_, GetTargetsQuery_ } from 'models';
import { useEffect, useRef, useState } from 'react';

export const EstimatenumberInput = ({
  value,
  onChange,
  isReadOnly,
  actionToEdit,
  targetsData,
  target,
  estimates,
  isDisabled,
}: {
  value: { [key: string]: string };
  onChange: (val: { [key: string]: string }[]) => void;
  isReadOnly: boolean;
  actionToEdit: ActionFieldsFragment_ | undefined;
  targetsData: GetTargetsQuery_['targets'];
  target: { value: string; label: string };
  estimates: { [key: string]: string }[];
  isDisabled: boolean;
}) => {
  const leftRef = useRef(null);
  const [leftElementWidth, setLeftElementWidth] = useState('');
  useEffect(() => {
    const leftElement = leftRef.current;

    if (leftElement) {
      const newWidth = window.getComputedStyle(leftElement).width;
      setLeftElementWidth(newWidth);
    }
  }, []);

  return (
    <InputGroup zIndex="0">
      <InputLeftElement
        ref={leftRef}
        zIndex="1"
        pointerEvents="none"
        height="28px"
        width="auto"
        paddingLeft="8px"
        paddingRight="2px"
        fontSize="14px"
      >
        {isReadOnly
          ? actionToEdit?.actionTargets.find((at) => at.target.id === target.value)?.target.metric
              ?.unitOfMeasurement ?? ''
          : targetsData.find((td) => td.id === target.value)?.metric?.unitOfMeasurement ?? ''}
      </InputLeftElement>
      <NumberInput
        minWidth="155px"
        height="28px"
        value={value[`${target.value}`] ?? ''}
        onChange={(val) =>
          onChange({
            ...estimates,
            [`${target.value}`]: val,
          })
        }
      >
        <NumberInputField
          minWidth="155px"
          height="28px"
          textAlign="left"
          placeholder="0"
          paddingLeft={
            leftElementWidth !== null && leftElementWidth !== '' ? leftElementWidth : '8px'
          }
          disabled={isDisabled}
        />
      </NumberInput>
    </InputGroup>
  );
};
