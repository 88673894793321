import { Box, HStack } from '@chakra-ui/react';
import { IconButton, Tag, TruncatableText } from 'Atoms';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import { MetricsTableData, isFrequencyYearly } from '../MetricAnswers.hooks';
import { QuarterInput } from './QuarterInput';
import { QuartersEnums, QuartersObject, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { MetricYear } from './MetricYear';
import { useMemo } from 'react';
import { ColumnDefResolved } from '@tanstack/react-table';
import { MetricFrequency } from './MetricFrequency';
import { MetricProgress } from './MetricProgress';
import { MetricTypeIcon } from 'Molecules/MetricTypeIcon';
import { ArrowNarrowRightIcon, ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { SelectedMetric } from 'containers/Esrs';
import { MetricOwnerSelect } from './MetricOwnerSelect';
import { User } from 'models';
import { LastEditedBy } from './LastEditedBy';
import { isEqual } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

export const MetricsInputTable = ({
  metrics,
  selectedQuarter,
  onGraphOpen,
  onDrawerOpen,
  onOpen,
  setSelectedMetric,
  setGraphData,
  companyStandardId,
  isOverview,
  areMetricsYearly,
  currency,
  companyReportingUnit,
  isCompanyLevel,
  isGroup,
  esrsAssessmentProjectLeader,
  rowData,
  setRowData,
}: {
  metrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  onGraphOpen: () => void;
  onDrawerOpen: () => void;
  onOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  setGraphData: (param: QuartersObject) => void;
  companyStandardId: string;
  isOverview: boolean;
  areMetricsYearly: boolean;
  currency: string;
  companyReportingUnit?: string;
  isCompanyLevel?: boolean;
  isGroup?: boolean;
  esrsAssessmentProjectLeader?: Partial<User>;
  rowData?: MetricsTableData;
  setRowData: (param: MetricsTableData | undefined) => void;
}) => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const nestedColumns: ColumnDefResolved<TableData<MetricsTableData>, any>[] | undefined =
    useMemo(() => {
      const isYearly = isFrequencyYearly(metrics[0], companyStandardId);
      return [
        {
          header: 'Metric',
          meta: {
            width: '40%',
          },
          accessorKey: 'metric',
          cell: ({ row }) => (
            <HStack pl={`${row.depth * 24}px`} spacing="8px">
              {row.getCanExpand() ? (
                <IconButton
                  variant={'ghost'}
                  size="xs"
                  onClick={row.getToggleExpandedHandler()}
                  aria-label="expand"
                  icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                />
              ) : (
                <MetricTypeIcon type="number" />
              )}
              <HStack width="100%">
                <Box
                  w={row.original.tagType ? '' : '100%'}
                  maxWidth={row.original.tagType ? '70%' : '100%'}
                >
                  <TruncatableText
                    w="100%"
                    variant="body"
                    tooltipMaxWidth="400px"
                    text={
                      `${
                        !!row.original.tagName
                          ? row.original.tagName
                          : row.original.metric.shortTitle ?? row.original.metric.title
                      }` + `${row.original.tagType ? ' by ' : ''}`
                    }
                  />
                </Box>
                {row.original.tagType && (
                  <Tag
                    variant="outlined"
                    truncatable={true}
                    title={row.original.tagType}
                    size="xs"
                    borderRadius="4px"
                    px="4px"
                  />
                )}
              </HStack>
            </HStack>
          ),
        },
        {
          header: 'Unit',
          meta: {
            width: '10%',
          },
          accessorKey: 'unit',
          cell: ({ row }) => (
            <TruncatableText
              variant="body"
              text={mapUnitToCompanyCurrency(
                row.original.metric.unitOfMeasurement ?? 'NA',
                currency
              )}
            />
          ),
        },
        {
          header: QuartersEnums.q1,
          accessorKey: 'quarter1',
          cell: ({ row }) => (
            <QuarterInput
              row={row.original}
              isOverview={isOverview}
              selectedQuarter={QuartersEnums.q1}
              companyStandardId={companyStandardId}
              companyReportingUnit={companyReportingUnit}
              assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
            />
          ),
        },
        {
          header: QuartersEnums.q2,
          accessorKey: 'quarter2',
          cell: ({ row }) => (
            <QuarterInput
              row={row.original}
              isOverview={isOverview}
              selectedQuarter={QuartersEnums.q2}
              companyStandardId={companyStandardId}
              companyReportingUnit={companyReportingUnit}
              assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
            />
          ),
        },
        {
          header: QuartersEnums.q3,
          accessorKey: 'quarter3',
          cell: ({ row }) => (
            <QuarterInput
              row={row.original}
              isOverview={isOverview}
              selectedQuarter={QuartersEnums.q3}
              companyStandardId={companyStandardId}
              companyReportingUnit={companyReportingUnit}
              assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
            />
          ),
        },
        {
          header: QuartersEnums.q4,
          accessorKey: 'quarter4',
          cell: ({ row }) => (
            <QuarterInput
              row={row.original}
              isOverview={isOverview}
              selectedQuarter={QuartersEnums.q4}
              companyStandardId={companyStandardId}
              companyReportingUnit={companyReportingUnit}
              assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
            />
          ),
        },
        {
          header: selectedQuarter !== TimePeriodsEnums.year ? selectedQuarter : 'Total',
          accessorKey: 'quarter', // Used as "YEAR" when selectedQuarter is year on input view!
          cell: ({ row }) => (
            <QuarterInput
              row={row.original}
              isOverview={isOverview}
              selectedQuarter={selectedQuarter}
              companyStandardId={companyStandardId}
              companyReportingUnit={companyReportingUnit}
              assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
              setRowData={setRowData}
            />
          ),
        },
        selectedQuarter === TimePeriodsEnums.year || isOverview || isCompanyLevel || isGroup
          ? {
              header: 'Total',
              accessorKey: 'yearly',
              cell: ({ row }) => (
                <MetricYear
                  row={row.original}
                  companyStandardId={companyStandardId}
                  companyReportingUnit={companyReportingUnit}
                />
              ),
            }
          : {},
        isOverview && !isYearly
          ? {
              header: 'Collected',
              meta: {
                width: '8%',
              },
              accessorKey: 'collected',
              cell: ({ row }) => (
                <MetricProgress
                  row={row.original}
                  companyStandardId={companyStandardId}
                  companyReportingUnit={companyReportingUnit}
                />
              ),
            }
          : {},
        {
          header: 'Frequency',
          meta: {
            width: '10%',
          },
          accessorKey: 'frequency',
          cell: ({ row }) => (
            <MetricFrequency row={row.original} companyStandardId={companyStandardId} />
          ),
        },
        !isOverview
          ? {
              header: 'Last edited',
              meta: {
                width: '14%',
              },
              accessorKey: 'lastEdited',
              cell: ({ row }) => (
                <LastEditedBy
                  row={row.original}
                  companyStandardId={companyStandardId}
                  selectedQuarter={selectedQuarter}
                  reportingUnitId={companyReportingUnit ? companyReportingUnit : undefined}
                />
              ),
            }
          : {},
        {
          header: 'Owner',
          meta: {
            width: '10%',
          },
          accessorKey: 'ownerId',
          cell: ({ row }) => (
            <MetricOwnerSelect
              row={row.original}
              selectedQuarter={selectedQuarter}
              companyReportingUnit={companyReportingUnit}
              assessmentProjectLeader={esrsAssessmentProjectLeader}
            />
          ),
        },
        {
          header: '',
          meta: {
            width: 'fit-content',
          },
          accessorKey: 'actions',
          cell: () => {
            return (
              <IconButton aria-label="side-bar" variant="ghost" icon={<ArrowNarrowRightIcon />} />
            );
          },
        },
      ];
    }, [
      metrics,
      selectedQuarter,
      onGraphOpen,
      onDrawerOpen,
      onOpen,
      setSelectedMetric,
      setGraphData,
      companyStandardId,
      isOverview,
      areMetricsYearly,
    ]);

  const filteredColumns = useMemo(() => {
    const quarterFields = ['quarter1', 'quarter2', 'quarter3', 'quarter4'];
    if (isOverview) {
      if (areMetricsYearly) {
        return nestedColumns.filter(
          (col) => col.accessorKey !== 'quarter' && !quarterFields.includes(col.accessorKey ?? '')
        );
      }
      return nestedColumns.filter((col) => col.accessorKey !== 'quarter');
    }
    if (selectedQuarter === TimePeriodsEnums.year)
      return nestedColumns.filter(
        (column) =>
          !quarterFields.includes(column.accessorKey ?? '') && column.accessorKey !== 'yearly'
      );
    return nestedColumns.filter((col) => !quarterFields.includes(col.accessorKey ?? ''));
  }, [nestedColumns, selectedQuarter]);

  return (
    <NestedTable<MetricsTableData>
      columns={filteredColumns}
      data={metrics}
      expanded={true}
      rowProps={{
        cursor: 'pointer',
        _hover: {
          bg: 'bg.hover',
        },
      }}
      onRowClick={(row) => {
        if (openDrawer && !!hash) navigate(pathname.split('?')[0]);
        if (isEqual(rowData, row)) {
          setRowData(undefined);
        } else setRowData(row);
      }}
    />
  );
};
