import { Box, BoxProps } from '@chakra-ui/react';
import { VisibleIf } from 'Atoms';
import { Article } from 'Features';
import { useArticlesBySlugQuery } from 'models';
import { Loader } from 'Molecules';
import { useMemo } from 'react';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';

export function ArticleBox({
  slug = '',
  preLoadedArticle,
  showTitle = true,
  ...props
}: {
  slug?: string | undefined;
  showTitle?: boolean;
  preLoadedArticle?: Article;
} & BoxProps) {
  const { t } = useTranslation();
  const { data, loading, error } = useArticlesBySlugQuery({
    variables: {
      slug,
    },
    skip: !slug || !!preLoadedArticle,
  });

  const article = useMemo(() => preLoadedArticle ?? data?.articles?.[0], [preLoadedArticle, data]);

  if (loading) {
    return <Loader />;
  }

  if (error || !article) {
    return (
      <Box margin={2}>
        <Typography>{t('common:knowledgeBase.moreInfoComingSoon')}</Typography>
      </Box>
    );
  }

  return (
    <Box className="resource-content" {...props}>
      <VisibleIf condition={showTitle}>
        <h1>{article.title}</h1>
      </VisibleIf>
      <div dangerouslySetInnerHTML={{ __html: article.body }}></div>
    </Box>
  );
}
