import {
  ChakraTheme,
  Heading as ChakraHeader,
  Text as ChakraText,
  HeadingProps,
  TextProps,
} from '@chakra-ui/react';

export const HeadingTheme: ChakraTheme['components']['Heading'] = {
  baseStyle: {
    font: 'heading',
    fontWeight: 'bold',
    color: 'grayscale.900',
  },
  sizes: {
    heading1: {
      fontSize: 'h1',
      lineHeight: 'lg',
    },
    heading2: {
      fontSize: 'h2',
      lineHeight: 'md',
    },
    heading3: {
      fontSize: 'h3',
      lineHeight: 'sm',
    },
    heading4: {
      fontSize: 'h4',
      lineHeight: 'xs',
    },
  },
};

export const TextTheme: ChakraTheme['components']['Text'] = {
  baseStyle: {
    font: 'body',
    color: 'grayscale.900',
    fontWeight: 'semibold',
    lineHeight: 'lgP',
  },
  sizes: {
    xxsmall: ({ variant }) => ({
      fontSize: 'xxs',
      lineHeight: variant === 'label' ? 'smP' : 'lgP',
    }),
    xsmall: {
      fontSize: 'xs',
    },
    small: ({ variant }) => ({
      fontSize: 'sm',
      lineHeight: variant === 'label' ? 'xsP' : 'lgP',
    }),
    medium: ({ variant }) => ({
      fontSize: 'md',
      fontWeight: variant === 'body' ? 'normal' : 'semibold',
      lineHeight: variant === 'label' ? 'mdP' : 'lgP',
    }),
    large: ({ variant }) => ({
      fontSize: 'lg',
      lineHeight: 'xsP',
      fontWeight: variant === 'body' ? 'normal' : 'semibold',
    }),
  },
};

export const HEADER_SIZES = Object.keys(HeadingTheme.sizes ?? {});
export type HeaderSize = (typeof HEADER_SIZES)[number];

export const TEXT_SIZES = Object.keys(TextTheme.sizes ?? {});
export type TextSize = (typeof TEXT_SIZES)[number];

export const TEXT_VARIANTS = Object.keys(TextTheme.variants ?? {});
export type TextVariant = (typeof TEXT_VARIANTS)[number];

export const Header = (
  props: Omit<HeadingProps, 'size'> & {
    size?: HeaderSize;
  }
) => {
  return <ChakraHeader {...props} />;
};

export const BodyText = (
  props: Omit<TextProps, 'size' | 'variant'> & {
    size?: TextSize;
    variant?: TextVariant;
  }
) => {
  return <ChakraText variant="body" {...props} />;
};

export const LabelText = (
  props: Omit<TextProps, 'size' | 'variant'> & {
    size?: TextSize;
    variant?: TextVariant;
  }
) => {
  return <ChakraText variant="label" {...props} />;
};
