import React, { useMemo } from 'react';
import { useCompanyAssessmentsQuery, useGroupAssessmentsQuery } from 'models';
import { HStack, Skeleton } from '@chakra-ui/react';
import { SingleTagSelector } from 'Molecules';
import { useCurrentCompanyId } from 'utils/hooks';
import { useTranslation } from 'utils/translation';

export const AssessmentSelector = ({
  assessmentId,
  setAssessmentId,
  placeholder,
  isSaving,
  width,
  isGroup,
}: {
  assessmentId?: string;
  setAssessmentId: (assessmentId?: string) => void;
  placeholder?: string;
  isSaving: boolean;
  width?: string;
  isGroup: boolean;
}) => {
  const { companyId } = useCurrentCompanyId();
  const { data: cAssessmentsData, loading: cAssessmentsLoading } = useCompanyAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId && isGroup,
  });
  const { data: gAssessmentsData, loading: gAssessmentLoading } = useGroupAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId && !isGroup,
  });

  const cAssessments = useMemo(() => {
    return cAssessmentsData?.assessments ?? [];
  }, [cAssessmentsData, assessmentId]);

  const gAssessments = useMemo(() => {
    return gAssessmentsData?.assessments ?? [];
  }, [gAssessmentsData, assessmentId]);
  const { t } = useTranslation('assessment');

  const options = useMemo(() => {
    if (isGroup) {
      return gAssessments.map((assessment) => ({
        label: assessment.name,
        value: assessment.consolidatedCompanyAssessmentId,
      }));
    } else {
      return cAssessments.map((assessment) => ({
        label: assessment.aggregate.title,
        value: assessment.id,
      }));
    }
  }, [isGroup, cAssessments, gAssessments]);

  return (
    <Skeleton
      isLoaded={!cAssessmentsLoading && !gAssessmentLoading}
      height="32px"
      width={width ?? '100%'}
      justifyContent="stretch"
    >
      <HStack width={width ?? '100%'} justifyContent="stretch">
        <SingleTagSelector
          value={assessmentId ?? undefined}
          options={options}
          onChange={(value) => setAssessmentId(value)}
          placeholder={placeholder ?? t('assessment:form.selectAssessment')}
          isLoading={isSaving}
          isClearable={false}
        />
      </HStack>
    </Skeleton>
  );
};
