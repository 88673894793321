import { HStack, Skeleton, Stack, VStack } from '@chakra-ui/react';
import { Card, ExternalLink } from 'Atoms';
import { Typography } from 'Tokens';
import { LoginForm, LoginPage, SignUpForm } from 'containers/Authentication';
import { useTranslation } from 'utils/translation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useValidateInvitation, InvitationError } from 'Features/CompanyInvitations';
import { useMemo } from 'react';

export const JoinCompany = () => {
  const { t } = useTranslation(['common', 'login']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const { invitation, error, loading } = useValidateInvitation(invitationId ?? undefined);

  const onSuccess = async () => {
    navigate({
      pathname: invitation?.userExists ? '/invited' : '/',
      search: '?invitationId=' + invitationId,
    });
  };

  const errorMessage = useMemo(() => {
    if (error === InvitationError.expired) {
      return (
        <VStack>
          <Typography variant="h3">
            {t('common:invitations.alreadyAccepted')}
            <br />{' '}
          </Typography>
          <ExternalLink href="/login">{t('common:invitations.loginRedirection')}</ExternalLink>
        </VStack>
      );
    }

    if (error === InvitationError.network) {
      return (
        <Typography variant="h3">{t('common:invitations.invitationError.description')}</Typography>
      );
    }
    return (
      <VStack>
        <Typography variant="h2">{t('common:invitations.invalidInvitation.heading')}</Typography>
        <Typography variant="h3">
          {t('common:invitations.invalidInvitation.description')}
        </Typography>
      </VStack>
    );
  }, [error]);

  if (error) {
    return (
      <Stack height="100%" minHeight="100vh" maxW="xl" m="auto">
        <Card
          alignItems="center"
          pt="8"
          mt="32"
          position="relative"
          width="100%"
          shadow="0px 0px 57px rgba(0, 0, 0, 0.07)"
        >
          {errorMessage}
        </Card>
      </Stack>
    );
  }

  return (
    <Skeleton isLoaded={!loading}>
      <LoginPage
        heading={`
        ${t('common:words.join')} 
        ${invitation?.company?.name ?? t('common:words.company')}
        `}
      >
        <VStack width="100%">
          {!invitation?.userExists ? (
            <>
              <VStack width="100%" mt={4}>
                <SignUpForm email={invitation?.userEmail} onSuccess={onSuccess} />
              </VStack>
              <HStack width="300px">
                <Typography variant="detail" textAlign="center" mt="4">
                  {t('login:confirmation.text')}{' '}
                  <a
                    style={{ color: '#0D2DDF' }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.celsia.io/terms-of-use"
                  >
                    {t('login:confirmation.terms')}
                  </a>{' '}
                  {t('common:words.and')}{' '}
                  <a
                    style={{ color: '#0D2DDF' }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.celsia.io/privacy-policy"
                  >
                    {t('login:confirmation.policy')}
                  </a>
                </Typography>
              </HStack>
            </>
          ) : (
            <LoginForm email={invitation?.userEmail} onSuccess={onSuccess} />
          )}
        </VStack>
      </LoginPage>
    </Skeleton>
  );
};
