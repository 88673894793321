import { DocumentationFile, useCompanyFilesQuery } from 'models';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { useUpsertFile } from '../Drive.hooks';
import { useDropzone } from 'react-dropzone';
import { getNameExtension } from 'utils/files';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import { Button } from 'Atoms';
import { Flex, VStack, useDisclosure } from '@chakra-ui/react';
import { Suspense, useMemo, useState } from 'react';
import { FileRenameModal } from './FileRenameModal';
import { AddIcon } from 'Tokens/Icons/Function';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';

const VirusFoundModal = ({
  isOpen,
  onClose,
  fileName,
}: {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
}) => {
  const { t } = useTranslation('files');
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('files:virus.title')} hasFooter={false}>
      <VStack spacing={'16px'}>
        <Typography variant="h2"></Typography>
        <Typography variant="body">{t('files:virus.body1', { fileName })}</Typography>
        <Typography variant="body">{t('files:virus.body2')}</Typography>
      </VStack>
    </Modal>
  );
};

export const AddNewFileButton = ({
  selectedFiles,
  setSelectedFiles,
}: {
  selectedFiles: DocumentationFile[];
  setSelectedFiles: (files: DocumentationFile[]) => void;
}) => {
  const upsertFile = useUpsertFile();
  const toast = useToast();
  const { companyId } = useCurrentCompanyId();
  const { t } = useTranslation('files');
  const { data: companyFileData } = useCompanyFilesQuery({
    variables: {
      companyId: companyId,
    },
  });
  const companyFiles = useMemo(() => {
    return companyFileData?.files;
  }, [companyFileData]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isVirusModalOpen,
    onOpen: onVirusModalOpen,
    onClose: onVirusModalClose,
  } = useDisclosure();

  const [currentFileData, setCurrentFileData] = useState<File>();

  function containsVirusError(error: any) {
    return error?.error?.message?.includes('virus found');
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (newFiles) => {
      const size = newFiles.reduce((s, f) => s + f.size, 0);
      const toastId = toast({
        text: t('files:uploading', {
          count: newFiles.length,
          size: (size / 1024 / 1024).toFixed(1) + 'MB',
        }),
      });
      Promise.all(
        newFiles.map((f) => {
          if (companyFiles?.find((file) => file.title === getNameExtension(f.name).name)) {
            setCurrentFileData(f);
            return onOpen();
          }
          const results = upsertFile({
            file: f,
            description: '',
            source: '',
            title: getNameExtension(f.name).name,
          })
            .then((file) => {
              return { error: null, file: file };
            })
            .catch((e) => ({ error: e, file: f }));
          return results;
        })
      ).then((results) => {
        const files: DocumentationFile[] = results
          .filter((result) => (result?.file as DocumentationFile) !== undefined)
          .map((filteredResult) => filteredResult?.file as DocumentationFile);
        setSelectedFiles(selectedFiles.concat(files));
        mixpanel.track(TRACKING_EVENTS.DRIVE.UPLOAD, {
          companyId: companyId,
          count: results.length,
        });
        const errors = results?.filter((r) => !!r?.error);
        if (toastId && results.every((e) => e !== undefined))
          if (errors.length) {
            if (errors.some((e) => containsVirusError(e))) {
              setCurrentFileData(
                (errors.find((e) => containsVirusError(e))?.file as File) ?? undefined
              );
              onVirusModalOpen();
            } else {
              toast({
                text: t('files:uploadFailed', { count: errors.length }),
                variant: 'danger',
                destroyAll: true,
              });
            }
          } else {
            return toast({ text: t('files:uploaded'), destroyAll: true });
          }
      });
    },
  });

  return (
    <>
      <Flex
        {...getRootProps({
          backgroundColor: isDragActive ? 'primary.light' : '',
          flexGrow: 1,
          justifyContent: 'flex-end',
        })}
      >
        <Suspense>
          <Button leftIcon={<AddIcon color={'white'} />} size="md" variant="primary">
            {t('files:addNewFile')}
          </Button>
          <input {...getInputProps()} />
        </Suspense>
      </Flex>

      <FileRenameModal
        fileData={currentFileData as File}
        isOpen={isOpen}
        onClose={onClose}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
      <VirusFoundModal
        isOpen={isVirusModalOpen}
        onClose={onVirusModalClose}
        fileName={currentFileData?.name ?? 'unknown'}
      />
    </>
  );
};
