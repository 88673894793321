import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
  useNavigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { NhostReactProvider, useAuthenticationStatus, useUserData } from '@nhost/react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { theme } from 'theme';
import { NhostApolloProvider } from '@nhost/react-apollo';
import {
  GENERAL_MODULES,
  GROUP_COMPANY_MODULES_SECTION_1,
  GROUP_COMPANY_MODULES_SECTION_2,
  Navigation,
  PORTFOLIO_COMPANY_MODULES_SECTION_1,
  PORTFOLIO_COMPANY_MODULES_SECTION_2,
  REGULAR_COMPANY_MODULES_SECTION_1,
  REGULAR_COMPANY_MODULES_SECTION_2,
} from 'containers/Navigation';
import { nhost, setUpNhostSessionManagement } from 'utils/nhost';
import { hotjar } from 'react-hotjar';
import mixpanel from 'mixpanel-browser';
import { isProduction } from 'utils/env';
import TagManager from 'react-gtm-module';
import {
  CookieConsentModal,
  useAcceptAllInvitations,
  useCookieConsent,
  usePendingInvitations,
} from 'Features';
import { Login } from 'containers/Authentication';
import {
  CompanyType,
  useCompanyType,
  useCurrentCompanyId,
  useUserCompanyCheck,
  useToast,
} from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import 'utils/translation/i18n';
import { ContentLayout, Loader } from 'Molecules';
import { SignUp } from 'containers/Authentication/SignUp';
import { CompanyRegisterModal } from 'Features/CompanyForm';
import ChangePassword from 'containers/Authentication/ChangePassword';
import { JoinCompany } from 'containers/JoinCompany';
// import { Maintenance } from 'containers/Maintenance';
import { SSOLogin, NoCompanyWelcomeModal } from 'containers/Authentication/SSOLogin';
import { isCelsiaSupport } from 'utils/users';
import { Home } from 'containers/Home';

import { createApolloCache } from 'utils/cache';
import { CachePersistor, SessionStorageWrapper } from 'apollo3-cache-persist';
import { InMemoryCache } from '@apollo/client';
import { EmptyState } from 'Atoms';
import { ErrorIllustration, NotFoundIllustration } from 'Tokens';

// const DOWN_FOR_MAINTENANCE = false;

const hotjarId = import.meta.env?.REACT_APP_HOTJAR_ID;
const hotjarSv = import.meta.env?.REACT_APP_HOTJAR_SV;
const mixpanelId = import.meta.env?.REACT_APP_MIXPANEL_ID;

const tagManagerArgs = {
  gtmId: import.meta.env?.REACT_APP_GOOGLE_TAG_MANAGER_ID ?? '',
};

const Orientation = () => {
  const { t } = useTranslation(['common']);
  const { companyId } = useCurrentCompanyId();
  const user = useUserData();

  if (companyId) return <Navigate to={`/${companyId}`} />;

  return (
    <>
      {isCelsiaSupport(user?.email) ? (
        <ContentLayout header="">
          <CompanyRegisterModal isOpen={true} onClose={() => {}} header={t('common:app.setup')} />
        </ContentLayout>
      ) : (
        <NoCompanyWelcomeModal />
      )}
    </>
  );
};

const CompanyController = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation(['login', 'common']);
  const { companyId: urlCompanyId } = useParams();
  const checkAccess = useUserCompanyCheck();
  const hasAccess = useMemo(() => checkAccess(urlCompanyId), [checkAccess, urlCompanyId]);
  const user = useUserData();
  const toast = useToast();

  const { invitations } = usePendingInvitations({ userEmail: user?.email ?? '' });

  const pendingInvitations = useMemo(() => {
    return invitations?.filter((invitation) => invitation.status === 'pending') ?? [];
  }, [invitations]);

  const [acceptInvitations] = useAcceptAllInvitations();
  useEffect(() => {
    if (pendingInvitations.length && user) {
      acceptInvitations(invitations ?? [], user).then((res) => {
        if (res.res?.data.companiesAdded) {
          toast({
            text: t('login:joiningCompanies.success', {
              count: res.res?.data.companiesAdded,
            }),
          });
        }
      });
    }
  }, [pendingInvitations, user]);

  if (invitations === undefined || invitations === null || (pendingInvitations.length && user)) {
    return <Loader label={t('login:joiningCompanies')} />;
  }

  if (!hasAccess) {
    return <Orientation />;
  }

  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

const ProtectedRoute = ({ children }: { children: any }) => {
  const { loading } = useCurrentCompanyId();

  const { isAuthenticated, isLoading } = useAuthenticationStatus();
  const location = useLocation();

  if (loading || isLoading) {
    return <Loader height="100vh" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <CompanyController>{children}</CompanyController>;
};

const PageNotFound = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  return (
    <Box w="100%" h={`calc(100vh - 80px)`} p="16px 16px 0px">
      <EmptyState
        title={t('common:notFound.title')}
        description={t('common:notFound.description')}
        callToAction={{
          text: t('common:notFound.button'),
          variant: 'primary',
          onClick: () => {
            navigate('/');
          },
        }}
        icon={<NotFoundIllustration boxSize="120px" />}
      />
    </Box>
  );
};

export const ErrorElement = () => {
  const { t } = useTranslation('common');
  return (
    <Box w="100%" h="100vh" p="16px">
      <EmptyState
        title={t('common:errorElement.title')}
        description={t('common:errorElement.description')}
        callToAction={{
          text: t('common:errorElement.button'),
          variant: 'secondary',
          onClick: () => {
            window.location.reload();
          },
        }}
        icon={<ErrorIllustration boxSize="120px" />}
      />
    </Box>
  );
};

const Layout = () => {
  const { companyType, loading } = useCompanyType();
  const moduleRoutes = useMemo(() => {
    if (companyType === CompanyType.regular) {
      return [
        ...REGULAR_COMPANY_MODULES_SECTION_1,
        ...REGULAR_COMPANY_MODULES_SECTION_2,
        ...GENERAL_MODULES,
      ];
    } else if (companyType === CompanyType.portfolio) {
      return [
        ...PORTFOLIO_COMPANY_MODULES_SECTION_1,
        ...PORTFOLIO_COMPANY_MODULES_SECTION_2,
        ...GENERAL_MODULES,
      ];
    }
    return [
      ...GROUP_COMPANY_MODULES_SECTION_1,
      ...GROUP_COMPANY_MODULES_SECTION_2,
      ...GENERAL_MODULES,
    ];
  }, [companyType]);

  const RouteElement = () => {
    if (companyType === CompanyType.regular) {
      return <Home />;
    } else if (companyType === CompanyType.portfolio) {
      return <Navigate to="portfolios" />;
    } else return <Navigate to="assessments" />;
  };

  return (
    <Navigation>
      <Loader isLoaded={!loading}>
        <Routes>
          <Route path="/" element={<RouteElement />} errorElement={<ErrorElement />} />
          {moduleRoutes.map((module) => (
            <Route
              path={`/${module.path}/*`}
              element={module.component}
              key={module.path}
              errorElement={<ErrorElement />}
            />
          ))}
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </Loader>
    </Navigation>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="hidden-sign-up-page" element={<SignUp />} errorElement={<ErrorElement />} />
      <Route path="login" element={<Login />} errorElement={<ErrorElement />} />
      <Route path="sso-login" element={<SSOLogin />} errorElement={<ErrorElement />} />
      <Route path="changePassword" element={<ChangePassword />} errorElement={<ErrorElement />} />
      <Route path="join-company" element={<JoinCompany />} errorElement={<ErrorElement />} />
      <Route path="invited" element={<Orientation />} errorElement={<ErrorElement />} />
      <Route
        errorElement={<ErrorElement />}
        path="/"
        element={
          <ProtectedRoute>
            <Orientation />
          </ProtectedRoute>
        }
      />
      <Route
        errorElement={<ErrorElement />}
        path="/:companyId/*"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      />
    </>
  )
);

function App() {
  const { consent } = useCookieConsent();
  const [appCache, setAppCache] = useState<InMemoryCache>();

  useEffect(() => {
    try {
      // As long as we are only using hotjar for surveys, and have session recording turned off, consent is not needed.
      hotjar.initialize(Number(hotjarId), Number(hotjarSv));
    } catch (e) {
      console.log('Could not initialize hotjar');
    }

    if (consent.analytics && isProduction()) {
      TagManager.initialize(tagManagerArgs);
    }

    mixpanel.init(mixpanelId ?? '');
    if (consent.analytics) {
      mixpanel.opt_in_tracking();
    } else {
      mixpanel.opt_out_tracking();
    }

    setUpNhostSessionManagement();
  }, []);

  useEffect(() => {
    async function init() {
      const cache = createApolloCache({});
      const newPersistor = new CachePersistor({
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
        debug: isProduction() ? false : true,
        trigger: 'write',
      });
      await newPersistor.restore();
      setAppCache(cache);
    }

    init().catch(console.error);
  }, []);

  // Uncomment to set maintenance on
  // if (DOWN_FOR_MAINTENANCE) {
  //   return (
  //     <ChakraProvider theme={theme}>
  //       <Maintenance />
  //     </ChakraProvider>
  //   );
  // }

  return (
    <ChakraProvider theme={theme}>
      <NhostReactProvider nhost={nhost}>
        <NhostApolloProvider cache={appCache} nhost={{ ...nhost, adminSecret: undefined }}>
          <Suspense fallback={<Loader />}>
            <RouterProvider router={router} />
          </Suspense>
          <CookieConsentModal />
        </NhostApolloProvider>
      </NhostReactProvider>
    </ChakraProvider>
  );
}

export default App;
