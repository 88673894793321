import { ChakraProps, HStack, VStack } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';

export type BackButtonProps = {
  onClick?: () => void;
};
export const BackButton = ({ onClick }: BackButtonProps) => {
  const navigate = useNavigate();
  const defaultOnClick = () => navigate(-1);
  const navigateOnClick = onClick || defaultOnClick;
  return (
    <IconButton
      size="md"
      variant="ghost"
      icon={<ArrowLeftIcon />}
      onClick={navigateOnClick}
      aria-label={'Back'}
    />
  );
};

export type ContentHeaderProps = {
  title: string;
  size?: 'md' | 'lg';
  backButton?: boolean | React.ReactNode;
  titleExtra?: React.ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  onBackButtonClick?: () => void;
  props?: ChakraProps;
  subtitle?: string;
  height?: string;
};
export const ContentHeader = ({
  title,
  size = 'lg',
  backButton,
  titleExtra,
  actions,
  onBackButtonClick,
  props,
  subtitle,
  height,
}: ContentHeaderProps) => {
  const backButtonComponent =
    backButton === true ? <BackButton onClick={onBackButtonClick} /> : backButton;
  return (
    <HStack
      width="100%"
      justifyContent="space-between"
      paddingTop="12px"
      paddingBottom="10px"
      paddingInline="16px"
      height={height ? height : size === 'lg' ? '64px' : '56px'}
      {...props}
    >
      <HStack justifyContent="start" height="fit-content">
        {backButtonComponent}
        <VStack spacing="0px" alignItems="flex-start">
          <HStack width="100%" alignItems="start">
            <Typography variant={size === 'md' ? 'h2' : 'h1'}>{title}</Typography>
            {titleExtra}
          </HStack>
          {subtitle && (
            <Typography variant="body" color="text.muted">
              {subtitle}
            </Typography>
          )}
        </VStack>
      </HStack>
      <HStack height="fit-content">{actions}</HStack>
    </HStack>
  );
};
