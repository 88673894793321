import {
  Table as ChakraTable,
  Thead,
  Th,
  VStack,
  Tbody,
  Tr,
  Td,
  HStack,
  Circle,
} from '@chakra-ui/react';
import { PaiPortfolioReport } from 'models';
import { PieChart } from 'react-minimal-pie-chart';
import { colors, Typography } from 'Tokens';
import { formatNum } from 'utils/numbers';
import { calculateMetricResults, getMetricProgress } from 'utils/scores/pai';
import { PaiResults, mapUnitToCompanyCurrencyPAI } from './PortfolioPai.hooks';
import { ReportPeriods } from 'Features/PortfolioView';
import { useCurrentCompany } from 'utils/hooks';

export const AnswersCollectedProgress = ({ progress }: { progress: number }) => {
  return (
    <HStack spacing="8px">
      <Circle size="16px" alignItems="center">
        <PieChart
          data={[
            { title: 'Collected', value: progress, color: colors.bg['decorative.brandState2'] },
            { title: 'Missing', value: 100 - progress, color: colors.bg.unknown },
          ]}
          lineWidth={26}
        />
      </Circle>
      <Typography variant="body" color="text.muted">
        {formatNum(progress)}%
      </Typography>
    </HStack>
  );
};

export const IndicatorRow = ({
  indicator,
  results,
  reportPeriod,
  companyCount,
}: {
  results: PaiResults;
  indicator: PaiPortfolioReport['selectedIndicators'][number]['indicator'];
  reportPeriod: ReportPeriods;
  companyCount: number;
}) => {
  const isSingleMetric = indicator.investorMetrics?.length === 1;
  const { company } = useCurrentCompany();

  if (isSingleMetric) {
    return (
      <Tr>
        <Td>
          <VStack spacing="2px" alignItems="flex-start">
            <Typography variant="bodyStrong">{indicator?.title}</Typography>
            <Typography variant="detail" color="text.muted">
              {indicator?.category.table.isCustom ? (
                <>Other indicators &#8212; </>
              ) : (
                <>PAI &#8212; </>
              )}
              {indicator?.category.table.title}: {indicator?.category.title}
            </Typography>
          </VStack>
        </Td>
        <Td>
          {indicator?.investorMetrics?.length > 1 ? (
            <VStack spacing="24px" alignItems="flex-start" paddingTop="48px">
              {indicator?.investorMetrics.map((metric) => (
                <>
                  {metric.investorMetric.isAggregatable ? (
                    <Typography color="text.muted" variant="body">
                      {calculateMetricResults(metric.investorMetric.reference, results)}{' '}
                      {calculateMetricResults(metric.investorMetric.reference, results) ===
                      'No data'
                        ? ''
                        : mapUnitToCompanyCurrencyPAI(
                            metric.investorMetric?.unit,
                            company?.currency
                          )}
                    </Typography>
                  ) : (
                    <Typography color="text.muted" variant="body">
                      N/A
                    </Typography>
                  )}
                </>
              ))}
            </VStack>
          ) : (
            <>
              {indicator?.investorMetrics?.[0] ? (
                indicator?.investorMetrics?.[0].investorMetric?.isAggregatable ? (
                  <Typography color="text.muted" variant="body">
                    {calculateMetricResults(
                      indicator.investorMetrics?.[0].investorMetric.reference,
                      results
                    )}{' '}
                    {calculateMetricResults(
                      indicator.investorMetrics?.[0].investorMetric.reference,
                      results
                    ) === 'No data'
                      ? ''
                      : mapUnitToCompanyCurrencyPAI(
                          indicator.investorMetrics?.[0].investorMetric?.unit,
                          company?.currency
                        )}
                  </Typography>
                ) : (
                  <Typography color="text.muted" variant="body">
                    N/A
                  </Typography>
                )
              ) : (
                'N/A'
              )}
            </>
          )}
        </Td>
        <Td>
          {indicator.investorMetrics?.length > 1 ? (
            <VStack spacing="24px" alignItems="flex-start" paddingTop="48px">
              {indicator.investorMetrics.map(() => (
                <Typography color="text.muted" variant="body">
                  No data
                </Typography>
              ))}
            </VStack>
          ) : (
            <Typography color="text.muted" variant="body">
              No data
            </Typography>
          )}
        </Td>
        <Td>
          <VStack spacing="24px" alignItems="flex-start">
            {indicator.investorMetrics.map((metric) => (
              <AnswersCollectedProgress
                progress={getMetricProgress(metric.investorMetric.reference, results, companyCount)}
              />
            ))}
          </VStack>
        </Td>
        <Td>
          <Typography color="text.muted" variant="body">
            {reportPeriod === ReportPeriods.year ? 'Yearly' : 'Quarterly'}
          </Typography>
        </Td>
      </Tr>
    );
  }
  return (
    <>
      {indicator.investorMetrics.map((metric, index) => (
        <Tr>
          <Td
            textOverflow="clip"
            border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}
          >
            {index === 0 && (
              <VStack spacing="2px" alignItems="flex-start">
                <Typography variant="bodyStrong">{indicator?.title}</Typography>
                <Typography variant="detail" color="text.muted">
                  {indicator?.category.table.isCustom ? (
                    <>Other indicators &#8212; </>
                  ) : (
                    <>PAI &#8212; </>
                  )}
                  {indicator?.category.table.title}: {indicator?.category.title}
                </Typography>
              </VStack>
            )}
            <HStack paddingLeft="24px" paddingTop="12px">
              <Typography height="100%" whiteSpace="initial" variant="bodyStrong">
                {metric?.investorMetric?.title}
              </Typography>
            </HStack>
          </Td>
          <Td border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}>
            <HStack paddingTop={index === 0 ? '68px' : '12px'}>
              {metric.investorMetric.isAggregatable ? (
                <Typography color="text.muted" variant="body">
                  {calculateMetricResults(metric.investorMetric.reference, results)}{' '}
                  {calculateMetricResults(metric.investorMetric.reference, results) === 'No data'
                    ? ''
                    : mapUnitToCompanyCurrencyPAI(metric.investorMetric?.unit, company?.currency)}
                </Typography>
              ) : (
                <Typography color="text.muted" variant="body">
                  N/A
                </Typography>
              )}
            </HStack>
          </Td>
          <Td border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}>
            <HStack paddingTop={index === 0 ? '68px' : '12px'}>
              <Typography color="text.muted" variant="body">
                No data
              </Typography>
            </HStack>
          </Td>
          <Td border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}>
            <HStack paddingTop={index === 0 ? '68px' : '12px'}>
              <AnswersCollectedProgress
                progress={getMetricProgress(metric.investorMetric.reference, results, companyCount)}
              />
            </HStack>
          </Td>
          <Td border={index !== indicator.investorMetrics?.length - 1 ? '0px' : undefined}>
            <HStack paddingTop={index === 0 ? '68px' : '12px'}>
              <Typography color="text.muted" variant="body" alignContent="flex-end">
                {reportPeriod === ReportPeriods.year ? 'Yearly' : 'Quarterly'}
              </Typography>
            </HStack>
          </Td>
        </Tr>
      ))}
    </>
  );
};

export const IndicatorsResultsTable = ({
  selectedIndicators,
  results,
  reportPeriod,
}: {
  selectedIndicators: PaiPortfolioReport['selectedIndicators'];
  results: PaiResults | undefined;
  reportPeriod: ReportPeriods;
}) => {
  return (
    <VStack width="100%" alignItems="stretch">
      <ChakraTable size="sm">
        <Thead letterSpacing="normal">
          <Th>Indicator</Th>
          <Th>
            {reportPeriod === ReportPeriods.year ? 'Impact this year' : 'Impact this quarter'}
          </Th>
          <Th>
            {reportPeriod === ReportPeriods.year
              ? 'Impact previous year'
              : 'Impact previous quarter'}
          </Th>
          <Th>Answers collected</Th>
          <Th>Data Collected</Th>
        </Thead>

        <Tbody>
          {selectedIndicators.map(
            ({ indicator, isForAllCompanies, paiReportIndicatorPortfolioCompanies }) => {
              const filteredPaiReportIndicatorPortfolioCompanies =
                paiReportIndicatorPortfolioCompanies.filter((pc) =>
                  results
                    ?.map((result) => result.company.id)
                    .includes(pc.portfolioCompany?.company?.id)
                );
              const companyCount: number =
                (isForAllCompanies
                  ? results?.length
                  : filteredPaiReportIndicatorPortfolioCompanies?.length) ?? 1;
              const filteredResults = isForAllCompanies
                ? results
                : results?.filter((result) =>
                    paiReportIndicatorPortfolioCompanies
                      .map((c) => c.portfolioCompany?.company?.id)
                      .includes(result.company.id)
                  );
              return (
                <IndicatorRow
                  indicator={indicator}
                  results={filteredResults ?? []}
                  reportPeriod={reportPeriod}
                  companyCount={companyCount}
                />
              );
            }
          )}
        </Tbody>
      </ChakraTable>
    </VStack>
  );
};
