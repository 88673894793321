import { Box, Textarea, Text, TextareaProps, ChakraTheme } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';
import React from 'react';
export const TextareaWithCharCounter = ({
  maxLength,
  value,
  onChange,
  ...props
}: {
  maxLength: number;
  value: string;
  onChange: (value: string) => void;
} & Omit<TextareaProps, 'onChange' | 'value'>) => (
  <Box>
    <Textarea
      value={value}
      onChange={(e) => e.currentTarget.value.length <= maxLength && onChange(e.currentTarget.value)}
      {...props}
    />
    <Text as="span" fontSize="sm" float="right">
      {value.length}/{maxLength} chars
    </Text>
  </Box>
);

export const TextareaTheme: ChakraTheme['components']['TextArea'] = {
  baseStyle: {
    fontFamily: 'Inter',
    _placeholder: {
      color: 'text.hint',
    },
  },
  variants: {
    outline: {
      px: '8px',
      bg: 'bg.default',
      border: '1px solid',
      borderColor: 'border.decorative',
      _hover: {
        borderColor: 'border.hover',
      },
      _focus: {
        borderColor: 'border.selected.accent',
        boxShadow: 'none',
      },
      _disabled: {
        borderColor: 'border.disabled',
      },
    },
    ghost: {
      px: '4px',
      bg: 'none',
      boxShadow: 'none',
      _hover: {
        bg: 'bg.hover',
      },
      _focus: {
        bg: 'bg.pressed',
      },
      _disabled: {
        bg: 'bg.disabled',
      },
    },
  },
};

export const AutoResizeTextarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    return (
      <Textarea
        minH="36px"
        overflow="hidden"
        w="100%"
        resize="none"
        ref={ref}
        minRows={1}
        as={ResizeTextarea}
        {...props}
      />
    );
  }
);
