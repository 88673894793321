import { useEffect, useState } from 'react';
import {
  Box,
  CloseButton,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useUserSetting } from 'containers/Navigation/Navigation.hooks';
import { CompanyAssessment } from 'models';
import { RadioCard } from 'Molecules/RadioCardGroup';
import { AssessmentStatusTag } from 'Organisms/AssessmentStatusTag';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';
import { InfoIcon } from 'Tokens/Icons/Status';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { sendHotjarEvent } from 'utils/hotjar';
import { LockConfirmationModal } from './LockConfirmationModal';
import { ListIcon } from 'Tokens/Icons/Data';
import { LockedIcon } from 'Tokens/Icons/Function';
import { ApolloError } from '@apollo/client';
import { useToast } from 'utils/hooks';

export const StatusSelector = ({
  companyAssessment,
  lockAssessment,
  lockLoading,
  lockError,
  unlockAssessment,
}: {
  companyAssessment: CompanyAssessment | undefined;
  lockAssessment: () => void;
  lockLoading: boolean;
  lockError?: ApolloError;
  unlockAssessment: () => void;
}) => {
  if (!companyAssessment) {
    return <></>;
  }

  const { t } = useTranslation(['assessment', 'common']);
  const toast = useToast();

  const {
    isOpen: isLockModalOpen,
    onClose: onLockModalClose,
    onOpen: onLockModalOpen,
  } = useDisclosure();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const openPopover = () => setIsPopoverOpen(true);
  const closePopover = () => setIsPopoverOpen(false);

  useEffect(() => {
    if (loadingState && !lockLoading) {
      setLoadingState(false);
      closePopover();
      onLockModalClose();
    }
  }, [loadingState, lockLoading, setLoadingState, closePopover, onLockModalClose]);

  useEffect(() => {
    if (lockError) {
      setLoadingState(false);
      toast({
        text: t('common:assessment.isLocked.toast.error'),
        variant: 'danger',
      });
    }
  }, [lockError, setLoadingState]);

  const onLockModalConfirm = () => {
    sendHotjarEvent('click_report_completed');
    setLoadingState(true);
    lockAssessment();
  };

  const [infoDismissed, setInfoDismissed] = useUserSetting(
    'assessment-status-selector-info-dismissed',
    false
  );
  const dismissInfo = () => setInfoDismissed(true);

  const infoMessage = (
    <Box padding={4} position="relative" bg="bg.unknown" borderRadius="8px" marginTop={4}>
      <HStack align="flex-start" spacing={4}>
        <Box>
          <InfoIcon color="inherit" />
        </Box>
        <Box>
          <Typography variant="body">{t('common:assessment.isLocked.info')}</Typography>
        </Box>
      </HStack>
      <CloseButton size="sm" onClick={dismissInfo} position="absolute" right={2} top={2} />
    </Box>
  );

  return (
    <HStack>
      <Popover isOpen={isPopoverOpen} onClose={closePopover}>
        <PopoverTrigger>
          <Box
            role="button"
            onClick={companyAssessment.isLocked ? () => {} : openPopover}
            cursor={companyAssessment.isLocked ? 'not-allowed' : 'pointer'}
            display="flex"
            justifyContent="center"
            mr="4px"
          >
            <AssessmentStatusTag
              isLocked={companyAssessment.isLocked}
              rightIcon={<ChevronDownIcon fontSize="24px" color="inherit" />}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent _focusVisible={{ outline: 'none' }}>
          <VStack padding={2}>
            <RadioCard
              title={t('common:assessment.isLocked.inProgress')}
              subtitle={t('common:assessment.isLocked.inProgressDescription')}
              titleLeftIcon={ListIcon}
              isSelected={!companyAssessment.isLocked}
              value="false"
              onClick={() => {
                unlockAssessment();
                closePopover();
              }}
            />
            <RadioCard
              title={t('common:assessment.isLocked.completedAndLocked')}
              subtitle={t('common:assessment.isLocked.lockedDescription')}
              isSelected={companyAssessment.isLocked}
              titleLeftIcon={LockedIcon}
              value="true"
              onClick={() => {
                onLockModalOpen();
              }}
            />
            {!infoDismissed && (
              <>
                <Spacer />
                {infoMessage}
              </>
            )}
          </VStack>
        </PopoverContent>
      </Popover>
      <LockConfirmationModal
        isOpen={isLockModalOpen}
        onClose={onLockModalClose}
        isLockLoading={lockLoading}
        onConfirm={onLockModalConfirm}
      />
    </HStack>
  );
};
