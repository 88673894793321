import { Box, VStack, Wrap } from '@chakra-ui/react';
import { Activity } from 'models';
import { Modal, TruncatedTagList } from 'Molecules';
import React from 'react';
import { useTranslation } from 'utils/translation';

type ActivityDetailsProps = {
  activity?: Activity;
  onClose: () => void;
  isOpen: boolean;
};

export function ActivityNaceCodes({
  activity,
  truncate = false,
}: {
  activity: Activity;
  truncate?: boolean;
}) {
  const codes = activity.naceCodes.map((code) => code.code);
  return (
    <Wrap p="1" mb={1}>
      <TruncatedTagList tags={codes} lines={truncate ? 1 : 3} />
    </Wrap>
  );
}
export function ActivityDescription({ activity }: { activity: Activity }) {
  const { t } = useTranslation('common');
  return (
    <VStack spacing="lg" alignItems="left">
      <Box
        dangerouslySetInnerHTML={{
          __html: activity.description || '',
        }}
      />
      <Box>
        <strong style={{ marginRight: 8 }}>{t('common:naceCodes')}</strong>
        <ActivityNaceCodes activity={activity} />
      </Box>
    </VStack>
  );
}

export const ActivityDetails = ({ activity, isOpen, onClose }: ActivityDetailsProps) => {
  return (
    <Modal title={activity?.name} isOpen={isOpen} onClose={onClose} size="sm" hasFooter={false}>
      {activity && <ActivityDescription activity={activity} />}
    </Modal>
  );
};
