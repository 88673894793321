import { hotjar } from 'react-hotjar';

export const sendHotjarEvent = (eventName: string) => {
  try {
    if (hotjar.initialized()) {
      hotjar.event(eventName);
    } else {
      console.log('Hotjar not initilaized');
    }
  } catch (e) {
    console.log('Error sending event', e);
  }
};
