import { Box, CloseButton, HStack, VStack } from '@chakra-ui/react';
import { Button, IconButton, Tabs, Tag, TruncatableText } from 'Atoms';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { createGraphObject } from 'containers/Esrs/utils';
import { QuestionType_Enum_, User } from 'models';
import { Loader } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DesignIllustration, Typography } from 'Tokens';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { MaximizeIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import {
  MetricViewEnums,
  QuartersEnums,
  QuartersObject,
  TimePeriods,
  TimePeriodsEnums,
} from '../../Requirement';
import { MetricMenu } from '../InputTable/MetricMenu';
import { useGetDatapointValues } from '../InputTable/QuarterInput';
import { isFrequencyYearly, MetricsTableData, useAggregatedRowValue } from '../MetricAnswers.hooks';
import { GraphCard } from '../MetricsGraphModal';
import { MetricComments } from './MetricComments';
import { MetricOverview } from './MetricOverview';
import { MetricSource } from './MetricSource';

type PartialYearObject = QuartersObject & { Year?: number };

export const MetricSidebar = ({
  row,
  metric,
  materialStandardId,
  companyReportingUnit,
  hasGraph = true,
  setGraphData,
  setSelectedMetric,
  onGraphOpen,
  onDrawerOpen,
  onMenuOpen,
  selectedQuarter,
  esrsAssessmentProjectLeader,
  setRowData,
}: {
  row?: MetricsTableData;
  metric: MetricsTableData['metric'];
  materialStandardId: string;
  companyReportingUnit?: string;
  hasGraph?: boolean;
  setSelectedMetric: (param: SelectedMetric) => void;
  setGraphData?: (param: QuartersObject) => void;
  onGraphOpen?: () => void;
  onDrawerOpen: () => void;
  onMenuOpen: () => void;
  selectedQuarter: TimePeriods;
  esrsAssessmentProjectLeader?: Partial<User>;
  setRowData: (param: MetricsTableData | undefined) => void;
}) => {
  const { companyId, esrsAssessmentId, standardRef, view } = useParams();
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const [selectedTab, setSelectedTab] = useState<string | undefined>();

  const { answer, dataPointsPerQuarter, dataPointPerYear, isAggregated, loading } =
    useGetDatapointValues(row ?? { metric: metric }, companyReportingUnit);

  const isYearly = useMemo(
    () => isFrequencyYearly(row ?? { metric: metric }, materialStandardId),
    [row, metric, materialStandardId]
  );
  const isParentMetric = useMemo(() => !!row?.subRows?.length, [row]);
  const isLongText = useMemo(() => metric.metricType === QuestionType_Enum_.LongText_, [metric]);
  const isOverview = useMemo(() => view === MetricViewEnums.overview, [view]);

  const { result: aggregatedValues } = useAggregatedRowValue(
    row ?? { metric: metric },
    companyReportingUnit
  );

  const datapoint = useMemo(
    () =>
      isParentMetric || isLongText || isYearly
        ? dataPointPerYear
        : dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter)?.value,
    [isYearly, dataPointPerYear, dataPointsPerQuarter, selectedQuarter]
  );

  useEffect(() => {
    if (openDrawer) {
      setSelectedTab('comment');
    }
  }, [openDrawer]);
  const getValuePerQuarter = (quarter: string) =>
    Number(dataPointsPerQuarter.find((dp) => dp.field === quarter)?.value?.value ?? 0);

  const graphData: PartialYearObject = useMemo(
    () =>
      createGraphObject(
        isYearly,
        getValuePerQuarter(QuartersEnums.q1),
        getValuePerQuarter(QuartersEnums.q2),
        getValuePerQuarter(QuartersEnums.q3),
        getValuePerQuarter(QuartersEnums.q4),
        Number(dataPointPerYear?.value) ?? 0
      ),
    [dataPointsPerQuarter, isYearly]
  );

  const aggregatedGraphData: PartialYearObject = useMemo(
    () =>
      createGraphObject(
        isYearly,
        aggregatedValues[QuartersEnums.q1],
        aggregatedValues[QuartersEnums.q2],
        aggregatedValues[QuartersEnums.q3],
        aggregatedValues[QuartersEnums.q4],
        aggregatedValues.Year
      ),
    [aggregatedValues, isYearly]
  );

  const graph = useMemo(
    () => (isAggregated ? aggregatedGraphData : graphData),
    [aggregatedGraphData, graphData, isAggregated]
  );

  const subTitle = useMemo(() => {
    const isTag = !!row?.tagName;
    const isParent = !!row?.parentMetric;
    if (isTag) return row.metric.shortTitle ?? row.metric.title;
    if (isParent) return row?.parentMetric?.title;
  }, [row]);

  const mainTitle = useMemo(() => {
    if (!row) return metric.shortTitle ?? metric.title;
    return !!row?.tagName ? row?.tagName : row?.metric.shortTitle ?? row?.metric.title;
  }, [row]);

  const hideComments = useMemo(() => {
    const quarterlyMetricInYearView =
      !isYearly && selectedQuarter === TimePeriodsEnums.year && !isParentMetric && !isLongText;
    const groupedTagsRow = !!(row?.tagName && row?.tagType);
    if (isOverview || groupedTagsRow || quarterlyMetricInYearView) {
      return true;
    }
    return false;
  }, [isYearly, selectedQuarter, isParentMetric, isLongText, row]);

  const commentsCount = useMemo(
    () => datapoint?.thread?.comments_aggregate.aggregate?.count,
    [datapoint]
  );

  return (
    <VStack
      minWidth="352px"
      maxWidth="352px"
      minHeight={`calc(100vh - 64px)`}
      pb="24px"
      mt="-12px"
      spacing="0px"
      alignItems="flex-start"
      borderLeft="1px solid"
      borderColor="border.decorative"
      overflowX="auto"
      position="fixed"
      right="0"
      bg="bg.default"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <HStack padding="8px 12px 8px 20px" justifyContent="space-between" w="100%">
            <Typography color="text.hint" variant="bodyStrong" py="8px">
              Data point
            </Typography>
            <CloseButton
              onClick={() => {
                if (openDrawer && !!hash) navigate(pathname.split('?')[0]);
                setRowData(undefined);
              }}
            />
          </HStack>
          {hasGraph ? (
            <Box padding="16px 8px" w="100%" h="fit-content" position="relative">
              <GraphCard
                data={graph}
                height={124}
                margins={{
                  top: 5,
                  right: 8,
                  left: -20,
                  bottom: 0,
                }}
                showXAxis={false}
              />
              <IconButton
                aria-label="maximize"
                icon={<MaximizeIcon />}
                size={'sm'}
                onClick={() => {
                  setSelectedMetric({ title: metric.title });
                  setGraphData?.(graph);
                  onGraphOpen?.();
                }}
                position="absolute"
                right={4}
                top={5}
              />
            </Box>
          ) : (
            <Box h="100px">
              <DesignIllustration
                boxSize="244px 145px"
                w="224px"
                position="absolute"
                top={0}
                right={0}
              />
            </Box>
          )}
          <VStack alignItems="start" width="100%" spacing="16px" p="0px 20px 16px">
            <VStack justifyContent="end" width="100%" spacing="4px" height="44px">
              {subTitle && <TruncatableText variant="detail" color="text.detail" text={subTitle} />}
              <TruncatableText variant="h3" text={mainTitle} />
            </VStack>
            <HStack width="100%">
              <Button
                variant="secondary"
                leftIcon={<DocumentIcon color="text.action" />}
                rightIcon={<ArrowUpRightIcon color="text.action" opacity={0.4} />}
                onClick={() =>
                  window.open(
                    `/${companyId}/esrs/${esrsAssessmentId}/report#${standardRef}-${metric.reference}`
                  )
                }
              >
                <Typography variant="bodyStrong" color="text.action" pr="44px">
                  Open in report
                </Typography>
              </Button>
              <IconButton
                aria-label="learn more"
                icon={<HelpIcon color="text.action" />}
                size="md"
                onClick={() => {
                  setSelectedMetric({
                    reference: metric.reference,
                    description: metric.description ?? '',
                    tags: metric.materialMetrics
                      .find((mm) => mm.materialStandardId === materialStandardId)
                      ?.materialMetricTags?.map((tag) => ({
                        type: tag.tagType,
                      })),
                  });
                  onDrawerOpen();
                }}
              />
              <MetricMenu
                metric={metric}
                onOpen={onMenuOpen}
                setSelectedMetric={setSelectedMetric}
                companyReportingUnit={companyReportingUnit}
              />
            </HStack>
          </VStack>
          <Tabs
            defaultIndex={0}
            currentTab={selectedTab}
            onChange={(tabId) => {
              setSelectedTab(String(tabId));
            }}
            padList="20px"
            tabsGap="20px"
            tabPadding="8px 0px"
            items={
              [
                {
                  title: 'Overview',
                  id: 'overview',
                  content: (
                    <MetricOverview
                      row={row}
                      metric={metric}
                      selectedQuarter={selectedQuarter}
                      companyReportingUnit={companyReportingUnit}
                      esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
                      materialStandardId={materialStandardId}
                    />
                  ),
                },
                {
                  title: 'Source',
                  id: 'source',
                  content: (
                    <MetricSource
                      answerId={answer?.id}
                      reference={metric.reference}
                      hasOptedOut={answer?.hasOptedOut}
                      companyReportingUnit={companyReportingUnit}
                    />
                  ),
                },
                {
                  title: (
                    <HStack spacing="6px">
                      <Typography variant="bodyStrong" color="currentColor">
                        Comments
                      </Typography>
                      {commentsCount && (
                        <Tag variant="selected" borderRadius="4px" size="xs">
                          {commentsCount}
                        </Tag>
                      )}
                    </HStack>
                  ),
                  id: 'comment',
                  content: (
                    <MetricComments
                      datapoint={datapoint}
                      companyReportingUnit={companyReportingUnit}
                      selectedQuarter={selectedQuarter}
                      isYearly={isYearly || isParentMetric || isLongText}
                      row={row ?? { metric: metric, tags: [] }}
                    />
                  ),
                },
              ].filter((tab) => (hideComments ? tab.id !== 'comment' : true)) ?? []
            }
          />
        </>
      )}
    </VStack>
  );
};
