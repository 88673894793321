import { Box } from '@chakra-ui/react';
import { FileIcon } from 'react-file-icon';

export const DocumentFileIcon = () => {
  return (
    <Box width="23.33px" height="28px">
      <FileIcon
        color="#2C5898"
        labelColor="#2C5898"
        type="document"
        glyphColor="rgba(255,255,255,0.4)"
        extension="doc"
      />
    </Box>
  );
};

export const ExcelFileIcon = () => {
  return (
    <Box width="23.33px" height="28px">
      <FileIcon
        color="#1A754C"
        labelColor="#1A754C"
        type="spreadsheet"
        glyphColor="rgba(255,255,255,0.4)"
        extension="xls"
      />
    </Box>
  );
};

export const CSVFileIcon = () => {
  return (
    <Box width="23.33px" height="28px">
      <FileIcon type="spreadsheet" extension="csv" />
    </Box>
  );
};
