import { VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { FormField, Input } from 'Atoms';
import {
  GroupAssessmentQuery_,
  useUpdateGroupAssessmentLockMutation,
  useUserCompaniesQuery,
} from 'models';
import { CreatableSelect, Modal } from 'Molecules';
import { useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';
import { useAddSubsidiary } from '../../GroupAssessment.hooks';

type CompanyFields = {
  company: { id?: string; name: string };
  contactPersonName: string;
  contactPersonEmail: string;
};

export const SelectCompaniesModal = ({
  isOpen,
  onClose,
  assessment,
}: {
  isOpen: boolean;
  onClose: () => void;
  assessment: GroupAssessmentQuery_['assessment'];
}) => {
  const { t } = useTranslation('group');
  const user = useUserData();
  const toast = useToast();
  const addSubsidiary = useAddSubsidiary();
  const { companyId } = useCurrentCompanyId();
  const [updateAssessmentLock] = useUpdateGroupAssessmentLockMutation();
  const [loading, setLoading] = useState(false);
  const { data } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user?.id,
  });

  const userCompanies = useMemo(() => {
    return data?.data?.companies.filter(
      (c) =>
        c.company.id !== companyId &&
        c.company.isPortfolioOwner !== true &&
        !assessment?.subsidiaries.find((sub) => sub.company?.id === c.company.id)
    );
  }, [data, assessment]);

  const { register, control, handleSubmit, reset } = useForm<CompanyFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });
  const { isValid, isDirty, errors } = useFormState({ control });

  const handleOnClose = () => {
    onClose();
    reset({
      company: undefined,
      contactPersonName: '',
      contactPersonEmail: '',
    });
  };

  const handleAddSubsidiary = (subsidiaryData: CompanyFields) => {
    setLoading(true);
    const { company, contactPersonName, contactPersonEmail } = subsidiaryData;
    addSubsidiary(company, contactPersonName, contactPersonEmail)
      ?.then(async () => {
        if (company.id === undefined) {
          await nhost.auth.refreshSession();
        }
        toast({
          text: t('toast.added'),
        });

        if (assessment?.consolidatedCompanyAssessment?.isLocked) {
          await updateAssessmentLock({
            variables: {
              cAssessmentId: assessment?.consolidatedCompanyAssessmentId,
              isLocked: false,
            },
          });
          toast({
            text: t('common:assessment.isLocked.toast.backInProgress'),
            duration: null,
          });
        }
        handleOnClose();
        setLoading(false);
      })
      .catch((e) => {
        if (String(e).includes('Subsidiary_companyId_groupAssessmentId_key')) {
          toast({
            variant: 'danger',
            text: company.name + t('toast.exists'),
          });
        } else
          toast({
            variant: 'danger',
            text: t('toast.failed'),
          });
        handleOnClose();
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      title={t('companies.addSubsidiary')}
      confirmButtonProps={{
        type: 'submit',
        form: 'subsidiary-form',
        isDisabled: !isValid || !isDirty || loading,
        isLoading: loading,
      }}
    >
      <form onSubmit={handleSubmit(handleAddSubsidiary)} id="subsidiary-form">
        <VStack alignItems="stretch">
          <Controller
            name="company"
            control={control}
            rules={{ required: t('companies.required.name') }}
            render={({ field: { onChange, value } }) => (
              <FormField
                label={t('companies.name')}
                id="name"
                isRequired
                isInvalid={!!errors.company}
                error={t('companies.required.name')}
              >
                <CreatableSelect
                  value={value}
                  isClearable={true}
                  options={userCompanies?.map((c) => {
                    return { id: c.company.id, name: c.company.name };
                  })}
                  createOptionPosition="first"
                  placeholder={t('companies.fields.company.placeholder')}
                  getOptionValue={(company) => company.id ?? ''}
                  getOptionLabel={(company) => company.name}
                  getNewOptionData={(_input, label) => ({
                    id: undefined,
                    name: label as string,
                  })}
                  onChange={(company) => company && onChange(company)}
                  formatCreateLabel={(input) => t('companies.createNew', { name: input })}
                />
              </FormField>
            )}
          />
          <Controller
            name="contactPersonName"
            control={control}
            rules={{ required: t('companies.required.contactName') }}
            render={({ field: { onChange, value } }) => (
              <FormField
                label={t('companies.fields.contactPerson.name.label')}
                id="contactPersonName"
                isRequired
                isInvalid={!!errors.contactPersonName}
                error={errors.contactPersonName?.message}
              >
                <Input
                  width="100%"
                  placeholder="John Doe"
                  {...register('contactPersonName')}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              </FormField>
            )}
          />
          <Controller
            name="contactPersonEmail"
            control={control}
            rules={{ required: t('companies.required.contactEmail') }}
            render={({ field: { onChange, value } }) => (
              <FormField
                label={t('companies.fields.contactPerson.email.label')}
                id="contactPersonEmail"
                isRequired
                isInvalid={!!errors.contactPersonEmail}
                error={errors.contactPersonEmail?.message}
              >
                <Input
                  width="100%"
                  placeholder="John.doe@celsia.io"
                  {...register('contactPersonEmail')}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              </FormField>
            )}
          />
        </VStack>
      </form>
    </Modal>
  );
};
