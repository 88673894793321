import {
  HStack,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Tooltip,
} from '@chakra-ui/react';
import { Button } from 'Atoms';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { AIIcon, AttachIcon } from 'Tokens/Icons/Function';
import { keyBy } from 'lodash';
import {
  useAttachPaiDocumentMutation,
  GetPaiAnswerDocumentationByIdDocument_,
  useReportInsightsSubscription,
} from 'models';
import { useMemo, useState, useCallback } from 'react';
import { nhost } from 'utils/nhost';
import { usePaiAnswer } from '../CompanyPai.hooks';
import axios from 'axios';

export const InsightPreview = ({
  investeeMetricRef,
  paiReportId = '',
  investeeMetricTitle,
  companyId,
}: {
  investeeMetricRef: string;
  investeeMetricTitle: string;
  paiReportId?: string;
  companyId: string;
}) => {
  const {
    answer,
    loading: isAnswerChanging,
    onAnswerChange,
  } = usePaiAnswer(companyId, paiReportId, investeeMetricRef);

  const [attachFile, { loading: isAttaching }] = useAttachPaiDocumentMutation();
  const [isExactAnswerLoading, setIsExactAnswerLoading] = useState<boolean>(false);
  const { data, loading } = useReportInsightsSubscription({
    variables: {
      reportId: paiReportId,
    },
    skip: !paiReportId,
  });

  const insight = useMemo(() => {
    const insightsMap = keyBy(data?.insights, 'investeeMetricRef');
    return insightsMap[investeeMetricRef];
  }, [data, investeeMetricRef, paiReportId]);

  const getExactAnswer = useCallback(async () => {
    setIsExactAnswerLoading(true);
    const result = await axios.post(
      `${import.meta.env.REACT_APP_AI_BACKEND_URL}/extract/answer`,
      {
        question: investeeMetricTitle,
        context: insight?.insight?.insight,
      },
      {
        headers: {
          'secret-key': 'secretKey',
          authorization: `Bearer ${nhost.auth.getAccessToken()}`,
        },
      }
    );
    const extractedAnswer = (result?.data as { result?: string })?.result ?? '';
    if (
      extractedAnswer.toLocaleLowerCase().includes('yes') ||
      extractedAnswer.toLocaleLowerCase().includes('no')
    ) {
      if (extractedAnswer.toLocaleLowerCase().includes('yes')) {
        onAnswerChange('YES');
      } else {
        onAnswerChange('NO');
      }
    } else {
      onAnswerChange(extractedAnswer);
    }
    setIsExactAnswerLoading(false);
  }, [insight]);

  const onAttachFile = useCallback(async () => {
    await attachFile({
      variables: {
        documentId: insight?.insight?.extractionJob?.documentationFile?.id,
        paiAnswerId: answer?.id ?? '',
      },
      refetchQueries: [GetPaiAnswerDocumentationByIdDocument_],
    });
  }, [insight, answer]);

  if (insight && !loading) {
    return (
      <Popover>
        <PopoverTrigger>
          <Button
            variant="ghost"
            rightIcon={<ChevronDownIcon color="text.info" />}
            color="text.info"
            leftIcon={
              <HStack
                bg="text.info"
                justifyContent="center"
                alignItems="center"
                borderRadius="4px"
                height="20px"
                width="20px"
              >
                <AIIcon color="white" boxSize="16px" />
              </HStack>
            }
          >
            Insights found
          </Button>
        </PopoverTrigger>
        <PopoverContent width="500px" borderRadius="10px">
          <PopoverArrow />
          <PopoverBody padding="0px">
            <VStack
              spacing="8px"
              justifyContent="flex-start"
              alignItems="flex-start"
              padding="16px"
            >
              <HStack bg="bg.info" padding="12px" borderRadius="8px">
                <Typography variant="body">
                  Based on the document{' '}
                  <b>{insight?.insight?.extractionJob?.documentationFile?.storageFile?.name}</b>, we
                  found the following insight: <br />
                  <i style={{ marginTop: '8px' }}>{insight?.insight?.insight}</i>
                </Typography>
              </HStack>
              <Typography variant="body">
                You can use this answer and attach the screened file as documentation:
              </Typography>
            </VStack>
            <HStack
              spacing="16px"
              width="100%"
              padding="16px"
              paddingY="8px"
              borderTop="1px solid"
              borderColor="border.decorative"
            >
              <Button
                variant="ghost"
                leftIcon={<AIIcon color="inherit" />}
                onClick={getExactAnswer}
                isLoading={isExactAnswerLoading || isAnswerChanging || isAttaching}
                isDisabled={isExactAnswerLoading || isAnswerChanging || isAttaching}
              >
                Use answer
              </Button>
              <Tooltip
                label={
                  !answer
                    ? 'Please use answer or add answer to attach the document as documentation'
                    : undefined
                }
              >
                <Button
                  variant="ghost"
                  leftIcon={<AttachIcon color="inherit" />}
                  onClick={onAttachFile}
                  isLoading={isExactAnswerLoading || isAnswerChanging || isAttaching}
                  isDisabled={isExactAnswerLoading || isAnswerChanging || !answer || isAttaching}
                >
                  Attach file
                </Button>
              </Tooltip>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
  return <></>;
};
