import { tablerIcon } from './Icons';
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconBulb,
  IconCheck,
  IconHelp,
  IconInfoSquare,
  IconLeaf,
  IconLeafOff,
  IconHeartHandshake,
  IconLoader,
  IconQuestionMark,
} from '@tabler/icons-react';

export const CheckIcon = tablerIcon(IconCheck);
export const LoaderIcon = tablerIcon(IconLoader);
export const IdeaIcon = tablerIcon(IconBulb);

export const DangerIcon = tablerIcon(IconAlertCircle);
export const WarningIcon = tablerIcon(IconAlertTriangle);
export const HelpIcon = tablerIcon(IconHelp);
export const InfoIcon = tablerIcon(IconInfoSquare);
export const QuestionMarkIcon = tablerIcon(IconQuestionMark);

export const AlignedIcon = tablerIcon(IconLeaf);
export const NotAlignedIcon = tablerIcon(IconLeafOff);
export const MinimumSocialSafeguardsIcon = tablerIcon(IconHeartHandshake);
