import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  Table as ChakraTable,
  Td,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Button, DateInput, EmptyState, Tag } from 'Atoms';
import {
  IndicatorConfiguration,
  PaiIndicatorsModal,
} from 'Features/PortfolioPai/PaiIndicatorsModal';
import { useTranslation } from 'utils/translation';
import {
  PortfolioCompany,
  useGetPaiPortfolioReportQuery,
  useGetTaxonomyPortfolioReportQuery,
} from 'models';
import { BackButton, ContentHeader, ContentLayout } from 'Molecules';
import { UserAssigner } from 'Organisms';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { EUTaxonomyIllustration, PaiIndicatorsIllustration, Typography } from 'Tokens';
import { useCurrentCompany, useCurrentCompanyId, useToast } from 'utils/hooks';
import usePortfolios, { portfolioOwnerContext, useUpsertPortfolioReport } from './Portfolios.hooks';
import { EligibilityStatus } from 'Features/PortfolioView';
import { Month } from 'utils/date';

type ReportSettingsForm = {
  reportingPeriod: Date;
  euContactPersonId: string;
  paiContactPersonId: string;
  euYearlyDateToSendOut: Date;
  euYearlyDeadlineDate: Date;
  paiQ1DateToSendOut: Date;
  paiQ1DeadlineDate: Date;
  paiQ2DateToSendOut: Date;
  paiQ2DeadlineDate: Date;
  paiQ3DateToSendOut: Date;
  paiQ3DeadlineDate: Date;
  paiQ4DateToSendOut: Date;
  paiQ4DeadlineDate: Date;
  paiYearlyDateToSendOut: Date;
  paiYearlyDeadlineDate: Date;
  indicators: IndicatorConfiguration[];
};

const thisOrNewDate = (date: Date | undefined) => {
  return date ? new Date(date) : new Date();
};

const DEFAULT_PORTFOLIO_DATES = {
  taxonomy: {
    annual: {
      dueDate: { month: Month.Jan, day: 31 },
    },
  },
  pai: {
    q1: {
      sendOutDate: { month: Month.Mar, day: 31 },
      dueDate: {
        month: Month.Apr,
        day: 14,
      },
    },
    q2: {
      sendOutDate: {
        month: Month.Jun,
        day: 30,
      },
      dueDate: { month: Month.Jul, day: 14 },
    },
    q3: {
      sendOutDate: {
        month: Month.Sep,
        day: 30,
      },
      dueDate: { month: Month.Oct, day: 14 },
    },
    q4: {
      sendOutDate: {
        month: Month.Dec,
        day: 31,
      },
      dueDate: { month: Month.Jan, day: 14 },
    },
    annual: {
      sendOutDate: {
        month: Month.Dec,
        day: 15,
      },
      dueDate: { month: Month.Jan, day: 31 },
    },
  },
};

const PortfolioSettings = ({ isEditing = false }: { isEditing: boolean }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation('pai');

  const { portfolioId, reportingYear } = useParams();

  const { getPortfolio } = usePortfolios();

  const isNewPortfolioSetup = useMemo(() => reportingYear === 'new-portfolio', [reportingYear]);

  const reportYearNumber = useMemo(() => {
    if (isNewPortfolioSetup) {
      return new Date().getFullYear();
    }
    return Number(reportingYear);
  }, [reportingYear]);

  const portfolio = getPortfolio(portfolioId ?? '', isNewPortfolioSetup ? null : reportYearNumber);
  const { company } = useCurrentCompany();

  const { data: reportPai, loading: isLoadingPai } = useGetPaiPortfolioReportQuery({
    variables: {
      portfolioId,
      year: reportYearNumber,
    },
    skip: !portfolioId || isNewPortfolioSetup,
    context: portfolioOwnerContext,
  });
  const paiReport = useMemo(() => {
    return reportPai?.report?.[0];
  }, [reportPai]);

  const { data: reportTaxonomyData } = useGetTaxonomyPortfolioReportQuery({
    variables: {
      portfolioId,
      year: reportYearNumber,
    },
    skip: isNewPortfolioSetup,
    context: portfolioOwnerContext,
  });
  const taxonomyReport = useMemo(() => {
    return reportTaxonomyData?.TaxonomyPortfolioReport?.[0];
  }, [reportTaxonomyData]);

  const {
    resetField,
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    watch,
  } = useForm<ReportSettingsForm>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  const { companyId } = useCurrentCompanyId();

  useEffect(() => {
    if (reportYearNumber) {
      resetField('reportingPeriod', { defaultValue: new Date(reportYearNumber, 1, 1) });
    }
  }, [reportYearNumber]);

  const selectedYear = watch('reportingPeriod')?.getFullYear();
  const indicators = watch('indicators');

  const isQuarterlySelected = useMemo(
    () => Array.isArray(indicators) && indicators.some((i) => i.periods.q1 === true),
    [indicators]
  );

  useEffect(() => {
    if (taxonomyReport) {
      resetField('euContactPersonId', { defaultValue: taxonomyReport?.contactPersonId });
    }
  }, [taxonomyReport]);

  useEffect(() => {
    resetField('paiContactPersonId', {
      defaultValue: paiReport?.contactPerson?.id ?? '',
    });
    resetField('indicators', {
      defaultValue:
        paiReport?.selectedIndicators.map((x) => ({
          ...x,
          selectedPortfolioCompanyIds:
            x.paiReportIndicatorPortfolioCompanies.map((c) => c?.portfolioCompanyId) ?? [],
        })) ?? [],
    });
  }, [paiReport, selectedYear, isEditing, reportYearNumber]);

  const upsertReport = useUpsertPortfolioReport();

  const onSubmit = (data: ReportSettingsForm) => {
    const hasYears = portfolio?.allCompaniesInPortfolio?.some((c) => !!c?.year);

    const filteredCompanies =
      portfolio?.allCompaniesInPortfolio
        ?.filter((c: PortfolioCompany) => {
          if (!hasYears) {
            return true;
          }
          if (isEditing) {
            return c?.year === reportYearNumber;
          }
          return c?.year === data.reportingPeriod.getFullYear() - 1;
        })
        .filter((c: PortfolioCompany) => !!c.company?.id)
        .map((comp: PortfolioCompany) => ({
          id: comp?.year === null ? comp.id : undefined,
          portfolioId: portfolioId ?? '',
          companyId: comp.company?.id ?? '',
          year: data.reportingPeriod.getFullYear(),
          valueOfInvestments: {
            q1: comp?.valueOfInvestments?.q4 ?? 1,
            q2: comp?.valueOfInvestments?.q4 ?? 1,
            q3: comp?.valueOfInvestments?.q4 ?? 1,
            q4: comp?.valueOfInvestments?.q4 ?? 1,
          },
          marketValue: comp?.marketValue ?? 1,
          contactPersonName: comp?.contactPersonName ?? '',
          contactPersonEmail: comp?.contactPersonEmail ?? '',
          eligibilityStatus: comp?.eligibilityStatus ?? EligibilityStatus.eligible,
        })) ?? [];

    upsertReport(
      filteredCompanies,
      {
        portfolioId: portfolioId ?? '',
        contactPersonId: data?.euContactPersonId ?? undefined,
        sendOutDate: data.euYearlyDateToSendOut,
        dueDate: data.euYearlyDeadlineDate,
        year: data.reportingPeriod.getFullYear(),
      },
      {
        portfolioId: portfolioId ?? '',
        contactPersonId: data?.paiContactPersonId ?? undefined,
        indicators: data.indicators,
        reportingPeriod: thisOrNewDate(data.reportingPeriod),
        sendOutDates: {
          Q1: data.paiQ1DateToSendOut,
          Q2: data.paiQ2DateToSendOut,
          Q3: data.paiQ3DateToSendOut,
          Q4: data.paiQ4DateToSendOut,
          year: data.paiYearlyDateToSendOut,
        },
        dueDates: {
          Q1: data.paiQ1DeadlineDate,
          Q2: data.paiQ2DeadlineDate,
          Q3: data.paiQ3DeadlineDate,
          Q4: data.paiQ4DeadlineDate,
          year: data.paiYearlyDeadlineDate,
        },
        year: data.reportingPeriod.getFullYear(),
      }
    ).then(() => {
      toast({
        text: 'Report settings saved!',
      });
      navigate(
        `/${companyId}/portfolios/${portfolioId}/${data.reportingPeriod.getFullYear()}/companies`
      );
    });
  };

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContentLayout
        header={
          <ContentHeader
            title={'Data collection settings'}
            backButton={
              <BackButton
                onClick={() => navigate(`portfolios/${portfolio?.id}/${reportYearNumber ?? ''}`)}
              />
            }
          />
        }
      >
        <Center width="100%">
          <VStack
            padding="16px"
            height="100vh"
            width="744px"
            alignItems="flex-start"
            spacing="56px"
          >
            <HStack justifyContent="space-between" width="100%">
              <VStack spacing="0px" alignItems="start">
                <Typography variant="h3">Reporting period</Typography>
                <Typography variant="body" color="text.muted">
                  Select a year
                </Typography>
              </VStack>
              <Controller
                name="reportingPeriod"
                control={control}
                defaultValue={new Date()}
                render={({ field }) =>
                  isEditing ? (
                    <Tag>{reportYearNumber}</Tag>
                  ) : (
                    <DateInput
                      isDisabled={isEditing}
                      isReadOnly={isEditing}
                      width="220px"
                      value={field.value}
                      setValue={(date) => date && field.onChange(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  )
                }
              />
            </HStack>
            <VStack alignItems="flex-start" width="100%" spacing="24px">
              <VStack alignItems="flex-start" spacing="8px" width="100%">
                <Typography variant="h2">EU Taxonomy assessment</Typography>
                <Divider border="1px" borderColor="border.decorative" />
              </VStack>
              {company?.hasTaxonomyAccess ? (
                <>
                  <HStack justifyContent="space-between" width="100%">
                    <VStack spacing="0px" alignItems="start">
                      <Typography variant="h3">Responsible</Typography>
                      <Typography variant="body" color="text.muted">
                        Report owner
                      </Typography>
                    </VStack>
                    <Stack width="220px">
                      <Controller
                        name="euContactPersonId"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Box zIndex="2">
                            <UserAssigner
                              assignedTo={field.value}
                              setAssignedTo={(id) => field.onChange(id)}
                              placeholder="Select user"
                            />
                          </Box>
                        )}
                      />
                    </Stack>
                  </HStack>
                  <VStack alignItems="start">
                    <Typography variant="h3">Setup timeline </Typography>
                    <Stack>
                      <Typography variant="body" color="text.muted" mb="4px">
                        EU Taxonomy assessment is completed once in a year. Select when companies
                        should receive requests, and when they should complete the assessment.
                      </Typography>
                    </Stack>
                    <Box
                      width="100%"
                      border="1px solid"
                      borderColor="border.decorative"
                      borderRadius="8px"
                    >
                      <ChakraTable width="100%" sx={{ tableLayout: 'fixed' }} variant="unstyled">
                        <Thead borderBottom="1px solid" borderColor="border.decorative">
                          <Td alignSelf="flex-start" p="13px 8px">
                            <Typography variant="bodyStrong">Period</Typography>
                          </Td>
                          <Td p="13px 8px">
                            <Typography variant="bodyStrong">Date to send out</Typography>
                          </Td>
                          <Td p="13px 8px">
                            <Typography variant="bodyStrong">Deadline</Typography>
                          </Td>
                        </Thead>
                        <Tr>
                          <Td p="13px 8px">
                            <Typography variant="body">Year</Typography>
                          </Td>
                          <Td p="5px 8px">
                            <Controller
                              name="euYearlyDateToSendOut"
                              control={control}
                              defaultValue={thisOrNewDate(taxonomyReport?.sendOutDate)}
                              render={({ field }) => (
                                <Box zIndex="1">
                                  <DateInput
                                    width="100%"
                                    value={field.value}
                                    setValue={(date) => field.onChange(date?.toDateString())}
                                  />
                                </Box>
                              )}
                            />
                          </Td>
                          <Td p="5px 8px">
                            <Controller
                              name="euYearlyDeadlineDate"
                              control={control}
                              defaultValue={
                                taxonomyReport?.dueDate
                                  ? thisOrNewDate(taxonomyReport?.dueDate)
                                  : new Date() >
                                    new Date(
                                      Number(selectedYear ?? reportYearNumber),
                                      DEFAULT_PORTFOLIO_DATES.taxonomy.annual.dueDate.month,
                                      DEFAULT_PORTFOLIO_DATES.taxonomy.annual.dueDate.day
                                    )
                                  ? new Date(
                                      Number(selectedYear ?? reportYearNumber) + 1,
                                      DEFAULT_PORTFOLIO_DATES.taxonomy.annual.dueDate.month,
                                      DEFAULT_PORTFOLIO_DATES.taxonomy.annual.dueDate.day
                                    )
                                  : new Date(
                                      Number(selectedYear ?? reportYearNumber),
                                      DEFAULT_PORTFOLIO_DATES.taxonomy.annual.dueDate.month,
                                      DEFAULT_PORTFOLIO_DATES.taxonomy.annual.dueDate.day
                                    )
                              }
                              render={({ field }) => (
                                <DateInput
                                  width="100%"
                                  value={field.value}
                                  setValue={(date) => field.onChange(date?.toDateString())}
                                />
                              )}
                            />
                          </Td>
                        </Tr>
                      </ChakraTable>
                    </Box>
                  </VStack>
                </>
              ) : (
                <Box w="100%" h="100%">
                  <EmptyState
                    title="Get started with the EU Taxonomy reporting"
                    description={
                      <Typography pb="96px">
                        Get access to the EU Taxonomy module to start reporting. Contact us to learn
                        more.
                      </Typography>
                    }
                    icon={<EUTaxonomyIllustration boxSize="80px" />}
                    component={true}
                  />
                </Box>
              )}
            </VStack>

            <VStack align="flex-start" width="100%" spacing="24px">
              <VStack alignItems="flex-start" spacing="8px" width="100%">
                <Typography variant="h2">PAI and other indicators assessment</Typography>
                <Divider border="1px" borderColor="border.decorative" />
              </VStack>
              {company?.hasPaiAccess ? (
                <>
                  <HStack justifyContent="space-between" width="100%">
                    <VStack spacing="0px" alignItems="start">
                      <Typography variant="h3">Responsible</Typography>
                      <Typography variant="body" color="text.muted">
                        Report owner
                      </Typography>
                    </VStack>
                    <Stack width="220px">
                      <Controller
                        name="paiContactPersonId"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <UserAssigner
                            assignedTo={field.value}
                            setAssignedTo={(id) => field.onChange(id)}
                            placeholder="Select user"
                          />
                        )}
                      />
                    </Stack>
                  </HStack>
                  <HStack justifyContent="space-between" width="100%">
                    <VStack spacing="0px" alignItems="start">
                      <Typography variant="h3">Select indicators</Typography>
                      <Typography variant="body" color="text.muted">
                        Which indicators you want to report on
                      </Typography>
                    </VStack>
                    <Button size="md" variant="secondary" onClick={onOpen}>
                      Select indicators
                    </Button>
                    <Controller
                      name="indicators"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <PaiIndicatorsModal
                          isOpen={isOpen}
                          onClose={onClose}
                          selected={field.value}
                          setSelected={field.onChange}
                          reportingYear={reportYearNumber}
                        />
                      )}
                    />
                  </HStack>
                  <VStack alignItems="start">
                    {indicators && indicators.length > 0 && (
                      <HStack
                        padding="8px 4px 8px 8px"
                        border="1px"
                        borderColor="bg.unknown"
                        bg="bg.unknown.muted"
                        width="100%"
                        borderRadius="6px"
                        mb="14px"
                        mt="-12px"
                      >
                        <Typography variant="body" color="text.muted">
                          {indicators.length === 1
                            ? 'Selected 1 indicator'
                            : `Selected ${indicators.length} indicators`}
                        </Typography>
                      </HStack>
                    )}
                    <Typography variant="h3">Setup timeline </Typography>
                    <Stack>
                      <Typography variant="body" color="text.muted" mb="4px">
                        Select when companies should receive requests, and when they should complete
                        the assessment.
                      </Typography>
                    </Stack>
                    <Box
                      width="100%"
                      border="1px solid"
                      borderColor="border.decorative"
                      borderRadius="8px"
                    >
                      {!isLoadingPai && (
                        <ChakraTable width="100%" sx={{ tableLayout: 'fixed' }} variant="unstyled">
                          <Thead borderBottom="1px solid" borderColor="border.decorative">
                            <Td alignSelf="flex-start" p="13px 8px">
                              <Typography variant="bodyStrong">Period</Typography>
                            </Td>
                            <Td p="13px 8px">
                              <Typography variant="bodyStrong">Date to send out</Typography>
                            </Td>
                            <Td p="13px 8px">
                              <Typography variant="bodyStrong">Deadline</Typography>
                            </Td>
                          </Thead>
                          {isQuarterlySelected && (
                            <>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">Q1</Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ1DateToSendOut"
                                    control={control}
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q1)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.sendOutDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ1DeadlineDate"
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q1)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q1.dueDate.day
                                          )
                                    }
                                    control={control}
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">Q2</Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ2DateToSendOut"
                                    control={control}
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q2)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.sendOutDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ2DeadlineDate"
                                    control={control}
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q2)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q2.dueDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">Q3</Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ3DateToSendOut"
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q3)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.sendOutDate.day
                                          )
                                    }
                                    control={control}
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ3DeadlineDate"
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q3)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q3.dueDate.day
                                          )
                                    }
                                    control={control}
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                              <Tr borderBottom="1px solid" borderColor="border.decorative">
                                <Td p="13px 8px">
                                  <Typography variant="body">Q4</Typography>
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ4DateToSendOut"
                                    control={control}
                                    defaultValue={
                                      paiReport?.sendOutDates
                                        ? thisOrNewDate(paiReport?.sendOutDates?.Q4)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber),
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.sendOutDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.sendOutDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                                <Td p="5px 8px">
                                  <Controller
                                    name="paiQ4DeadlineDate"
                                    control={control}
                                    defaultValue={
                                      paiReport?.dueDates
                                        ? thisOrNewDate(paiReport?.dueDates?.Q4)
                                        : new Date(
                                            Number(selectedYear ?? reportYearNumber) + 1,
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.dueDate.month,
                                            DEFAULT_PORTFOLIO_DATES.pai.q4.dueDate.day
                                          )
                                    }
                                    render={({ field }) => (
                                      <DateInput
                                        width="100%"
                                        value={field.value}
                                        setValue={(date) => date && field.onChange(date)}
                                      />
                                    )}
                                  />
                                </Td>
                              </Tr>
                            </>
                          )}
                          <Tr>
                            <Td p="13px 8px">
                              <Typography variant="body">Year</Typography>
                            </Td>
                            <Td p="5px 8px">
                              <Controller
                                name="paiYearlyDateToSendOut"
                                control={control}
                                defaultValue={
                                  paiReport?.sendOutDates
                                    ? thisOrNewDate(paiReport?.sendOutDates?.year)
                                    : new Date(
                                        Number(selectedYear ?? reportYearNumber),
                                        DEFAULT_PORTFOLIO_DATES.pai.annual.sendOutDate.month,
                                        DEFAULT_PORTFOLIO_DATES.pai.annual.sendOutDate.day
                                      )
                                }
                                render={({ field }) => (
                                  <DateInput
                                    width="100%"
                                    value={field.value}
                                    setValue={(date) => date && field.onChange(date)}
                                  />
                                )}
                              />
                            </Td>
                            <Td p="5px 8px">
                              <Controller
                                name="paiYearlyDeadlineDate"
                                control={control}
                                defaultValue={
                                  paiReport?.dueDates
                                    ? thisOrNewDate(paiReport?.dueDates?.year)
                                    : new Date(
                                        Number(selectedYear ?? reportYearNumber) + 1,
                                        DEFAULT_PORTFOLIO_DATES.pai.annual.dueDate.month,
                                        DEFAULT_PORTFOLIO_DATES.pai.annual.dueDate.day
                                      )
                                }
                                render={({ field }) => (
                                  <DateInput
                                    width="100%"
                                    value={field.value}
                                    setValue={(date) => date && field.onChange(date)}
                                  />
                                )}
                              />
                            </Td>
                          </Tr>
                        </ChakraTable>
                      )}
                    </Box>
                  </VStack>
                </>
              ) : (
                <Box w="100%" h="100%">
                  <EmptyState
                    title={t('pai:portfolioNoAccess.title')}
                    description={
                      <Typography pb="96px">{t('pai:portfolioNoAccess.description')}</Typography>
                    }
                    icon={<PaiIndicatorsIllustration boxSize="80px" />}
                    component={true}
                  />
                </Box>
              )}
            </VStack>
            <VStack pb="76px">
              <Button variant="primary" size="md" type="submit" isDisabled={!isValid || !isDirty}>
                {portfolio?.portfolioCompanies?.length === 0 ? 'Done' : 'Send out'}
              </Button>
            </VStack>
          </VStack>
        </Center>
      </ContentLayout>
    </form>
  );
};

export default PortfolioSettings;
