import { VStack } from '@chakra-ui/react';
import { FormField, Input } from 'Atoms';
import {
  EsrsAssessmentDocument_,
  useUpsertEsrsReportingUnitMutation,
  useUpsertReportingUnitLineageMutation,
  useReportingUnitLineageQuery,
  ReportingUnitLineageQuery_,
  GetReportingUnitsDocument_,
} from 'models';
import { CreatableSelect, Modal } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';
import { ProjectLeaderSelect } from './ProjectLeaderSelect';
import { BusinessUnit } from './EsrsAssessmentConfig';
import { User } from '@nhost/react';

export type ReportingUnitEsrsFields = {
  reportingUnit: {
    id?: string;
    name: string;
    lineageId?: string;
    projectLeader?: Partial<User>;
  };
};

const getLatestReportingUnit = (
  reportingUnits: ReportingUnitLineageQuery_['esrs_ReportingUnitLineage'][number]['reportingUnits']
) => {
  if (reportingUnits.length === 1) {
    return reportingUnits[0];
  }

  let latestReportingUnit = reportingUnits[0];
  reportingUnits.forEach((reportingUnit) => {
    const currentYear = reportingUnit.esrsAssessment.reportingYear;
    const highestYear = latestReportingUnit.esrsAssessment.reportingYear;
    if (currentYear > highestYear) {
      latestReportingUnit = reportingUnit;
    }
  });
  return latestReportingUnit;
};

export const AddBusinessUnitEsrsModal = ({
  isOpen,
  onClose,
  reportingUnitsEsrs,
  selectedBusinessUnit,
}: {
  isOpen: boolean;
  onClose: () => void;
  reportingUnitsEsrs?: ({ id: any; name: string; lineageId: any } | null | undefined)[];
  selectedBusinessUnit?: BusinessUnit;
}) => {
  const { t } = useTranslation(['group', 'bUnits', 'assessment']);
  const { companyId } = useCurrentCompanyId();
  const { esrsAssessmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [upsertReportingUnitAssessment] = useUpsertEsrsReportingUnitMutation();
  const [upsertReportingUnitLineage] = useUpsertReportingUnitLineageMutation();

  const { data: reportingUnitsLineages } = useReportingUnitLineageQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const reportingUnits = useMemo(() => {
    return (
      reportingUnitsLineages?.esrs_ReportingUnitLineage?.map((lineage) =>
        getLatestReportingUnit(lineage.reportingUnits)
      ) ?? []
    );
  }, [reportingUnitsLineages]);

  const filteredRUs = useMemo(() => {
    return reportingUnits
      ?.filter((ru) => {
        const reportingUnitExists = reportingUnitsEsrs?.find((ruE) => ruE?.id === ru?.id);
        const sameLineageExists = reportingUnitsEsrs?.find(
          (ruE) => ruE?.lineageId === ru?.lineageId
        );
        return !reportingUnitExists && !sameLineageExists;
      })
      .filter((ru) => ru !== undefined);
  }, [reportingUnitsEsrs, reportingUnits]);

  const { control, handleSubmit, reset, setValue } = useForm<ReportingUnitEsrsFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
  });

  useEffect(() => {
    reset({
      reportingUnit: undefined,
    });
  }, [isOpen, onClose]);

  const { isValid, isDirty, errors } = useFormState({ control });

  const handleAddReportingUnit = (businessUnitData: ReportingUnitEsrsFields) => {
    const { reportingUnit } = businessUnitData;
    setLoading(true);
    if (!reportingUnit?.lineageId && !selectedBusinessUnit) {
      upsertReportingUnitLineage({
        variables: {
          reportingUnitLineage: {
            companyId,
            isCompanyLevel: false,
            reportingUnits: {
              data: [
                {
                  id: reportingUnit?.id,
                  name: reportingUnit?.name,
                  assessmentId: esrsAssessmentId,
                  isCompanyLevel: false,
                  projectLeaderId: reportingUnit.projectLeader?.id,
                },
              ],
            },
          },
        },
        refetchQueries: [EsrsAssessmentDocument_, GetReportingUnitsDocument_],
      })
        .then(async () => {
          await nhost.auth.refreshSession();
          onClose();
          setLoading(false);
          toast({
            text: t('Business unit added'),
          });
          reset({
            reportingUnit: undefined,
          });
        })
        .catch((_e) => {
          onClose();
          setLoading(false);
          toast({
            variant: 'danger',
            text: t('Failed to add business unit. Please try again'),
          });
        });
    } else {
      upsertReportingUnitAssessment({
        variables: {
          reportingUnit: {
            id: selectedBusinessUnit?.id,
            name: reportingUnit?.name,
            lineageId: selectedBusinessUnit?.lineageId ?? reportingUnit.lineageId,
            assessmentId: esrsAssessmentId,
            projectLeaderId: reportingUnit.projectLeader?.id,
          },
        },
        refetchQueries: [EsrsAssessmentDocument_, GetReportingUnitsDocument_],
      })
        .then(async () => {
          await nhost.auth.refreshSession();
          onClose();
          setLoading(false);
          toast({
            text: t('Business unit added'),
          });
          reset({
            reportingUnit: undefined,
          });
        })
        .catch((_e) => {
          onClose();
          setLoading(false);
          toast({
            variant: 'danger',
            text: t('Failed to add business unit. Please try again'),
          });
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={selectedBusinessUnit ? 'Edit business unit' : 'Add business unit'}
      confirmButtonProps={{
        type: 'submit',
        form: 'subsidiary-form',
        isDisabled: !selectedBusinessUnit && (!isValid || !isDirty),
      }}
      loading={loading}
    >
      <form onSubmit={handleSubmit(handleAddReportingUnit)} id="subsidiary-form">
        <VStack alignItems="stretch">
          <Controller
            name="reportingUnit"
            control={control}
            defaultValue={selectedBusinessUnit}
            rules={{ required: 'Please add a business unit' }}
            render={({ field: { onChange, value } }) => (
              <>
                <FormField
                  label={'Business unit name'}
                  id="name"
                  isRequired
                  isInvalid={!!errors.reportingUnit}
                  error="Please add a business unit"
                >
                  {!selectedBusinessUnit && (
                    <CreatableSelect
                      value={value}
                      isClearable={true}
                      options={filteredRUs?.map((ru) => {
                        return {
                          id: ru?.id,
                          name: ru?.name,
                          lineageId: ru?.lineageId,
                          projectLeader: ru?.projectLeader ?? undefined,
                        };
                      })}
                      createOptionPosition="first"
                      placeholder={'Select or enter a new business unit name'}
                      getOptionValue={(ru) => ru?.id ?? ''}
                      getOptionLabel={(ru) => ru.name}
                      getNewOptionData={(_input, label) => ({
                        id: undefined,
                        name: label as string,
                        lineageId: undefined,
                      })}
                      onChange={(ru) => {
                        onChange(ru ?? undefined);
                      }}
                      formatCreateLabel={(input) => `Create "${input}"`}
                    />
                  )}
                  {selectedBusinessUnit && (
                    <Input
                      borderColor="border.default"
                      w="100%"
                      value={value?.name}
                      onChange={(e) => {
                        onChange({ ...value, name: e.target.value });
                      }}
                    />
                  )}
                </FormField>
                <FormField
                  label={"Business unit's project leader"}
                  id="projectLeader"
                  isInvalid={!!errors.reportingUnit}
                  error={'Please add a project leader to your business unit'}
                >
                  <ProjectLeaderSelect
                    isDisabled={!selectedBusinessUnit && !!value?.projectLeader}
                    defaultProjectLeader={value?.projectLeader}
                    onChange={(member) => {
                      setValue('reportingUnit.projectLeader', member);
                    }}
                  />
                </FormField>
              </>
            )}
          />
        </VStack>
      </form>
    </Modal>
  );
};
