import {
  useEsrsAssessmentQuery,
  useGetAnswersForMetricsOnCompanyLevelQuery,
  useGetAnswersForMetricsOnGroupLevelQuery,
  useGetMetricAnswersQuery,
  useGetReportMaterialStandardsQuery,
} from 'models';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  CategoriesType,
  EsrsReportCategory,
  EsrsReportData,
  EsrsReportDisclosureRequirement,
  EsrsReportMetric,
  EsrsReportStandard,
  MaterialReportDisclosure,
  MaterialReportMetric,
  MaterialReportStandard,
} from './Report.types';
import { useAssessmentReportingUnits } from 'containers/Esrs/EsrsAssessment.hooks';
import { COMPANY, DREnums, getNestedRows } from '../DisclosureRequirements';
import { getMetricResult } from './MetricAnswerCalculation';
import { QuestionType } from 'utils/scores/questions';
import { useCurrentCompany } from 'utils/hooks';

export const useEsrsReportData = () => {
  const { esrsAssessmentId = '' } = useParams();
  const { reportingUnitAssessments } = useAssessmentReportingUnits(esrsAssessmentId);
  const CompanyReportingUnitId = useMemo(
    () => reportingUnitAssessments.find((ru) => ru.isCompanyLevel)?.id ?? '',
    [reportingUnitAssessments]
  );
  const BUReportingUnitId = useMemo(
    () => reportingUnitAssessments.filter((ru) => !ru.isCompanyLevel)?.[0]?.id ?? '',
    [reportingUnitAssessments]
  );
  const { company } = useCurrentCompany();

  const { data: esrsAssessmentData, loading: assessmentLoading } = useEsrsAssessmentQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const { data: reportingUnitsAnswers, loading: reportingUnitsAnswersLoading } =
    useGetAnswersForMetricsOnCompanyLevelQuery({
      variables: {
        esrsAssessmentId,
      },
      skip: !esrsAssessmentId,
    });

  const { data: groupAnswers } = useGetAnswersForMetricsOnGroupLevelQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const { data, loading: materialStandardsLoading } = useGetReportMaterialStandardsQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const { data: answers, loading: answersLoading } = useGetMetricAnswersQuery({
    variables: {
      reportingUnitId: CompanyReportingUnitId,
    },
    skip: !CompanyReportingUnitId,
  });

  const { companyName, reportingYear } = useMemo(() => {
    const assessmentData = esrsAssessmentData?.esrsAssessment;
    return {
      companyName: assessmentData?.company.name,
      reportingYear: assessmentData?.reportingYear,
    };
  }, [esrsAssessmentData]);

  const materialStandards = useMemo(
    () => data?.EsrsAssessment_by_pk?.materialStandards ?? [],
    [data]
  );

  const categories: CategoriesType = useMemo(() => {
    const set = [
      ...new Set(
        materialStandards.map((std) =>
          JSON.stringify({
            title: std.standard.category.title,
            ref: std.standard.categoryRef,
          })
        )
      ),
    ].map((s) => JSON.parse(s));

    const generalDisclosures = set.find((category) => category.ref === 'general');
    const otherCategories = set.filter((category) => category.ref !== 'general');
    otherCategories.sort((a, b) => a.title.localeCompare(b.title));
    return generalDisclosures ? [generalDisclosures, ...otherCategories] : otherCategories;
  }, [materialStandards]);

  const getMetricData = (
    metric: MaterialReportMetric,
    standardId: string,
    standardRef: string
  ): EsrsReportMetric => {
    const metricObject = {
      ...metric.metric,
      childrenMetrics: metric.childrenMetrics,
      materialMetrics: [metric],
    };

    const textAnswer = answers?.esrs_Answer.find((ans) => ans.metricRef === metric.metricRef)
      ?.datapoints?.[0]?.value;

    const nestedMetrics = getNestedRows(metricObject, standardId);
    const result = getMetricResult(
      metricObject,
      nestedMetrics,
      reportingUnitsAnswers,
      groupAnswers,
      company?.isGroupOwner,
      standardRef
    );

    return {
      metric: metric,
      textAnswer:
        metric.metric.metricType === QuestionType.LongText_ && !company?.isGroupOwner
          ? textAnswer
          : undefined,
      tableData: result,
      reportingUnitId:
        metric.dataCollection === COMPANY ? CompanyReportingUnitId : BUReportingUnitId,
      completed: !!(
        (textAnswer && !company?.isGroupOwner) ||
        (result.result?.Year && metric.metric.metricType !== QuestionType.LongText_)
      ),
    };
  };

  const getDisclosureData = (
    dr: MaterialReportDisclosure,
    category: string,
    standardTitle: string,
    standardId: string,
    standardRef: string
  ): EsrsReportDisclosureRequirement => {
    const filteredDrs = materialStandards.find(
      (stand) =>
        stand.standard.category.title === category && stand.standard.title === standardTitle
    );
    const req = dr.disclosureRequirement;
    return {
      title: req.title,
      type: req.type,
      reference: req.reference,
      description: req.description,
      isHidden:
        filteredDrs?.materialMetrics
          .filter((metric) => req.metrics.find((met) => met.reference === metric.metricRef))
          .every((metric) => metric.isHidden) ?? false,
      metrics:
        filteredDrs?.materialMetrics
          .filter((met) => req.metrics.find((rq) => met.metricRef === rq.reference))
          .map((metric) => getMetricData(metric, standardId, standardRef)) ?? [],
    };
  };

  const getStandardsData = (
    standard: MaterialReportStandard,
    category: string
  ): EsrsReportStandard => {
    return {
      id: standard.id,
      title: standard.standard.title,
      reference: standard.standardRef,
      disclosureRequirements: standard.materialDisclosureRequirements
        .filter(
          (dr) =>
            dr.disclosureRequirement.type !== DREnums.target &&
            dr.disclosureRequirement.type !== DREnums.action &&
            materialStandards
              .find(
                (stand) =>
                  stand.standard.category.title === category &&
                  stand.standard.title === standard.standard.title
              )
              ?.materialMetrics.some((metric) =>
                dr.disclosureRequirement.metrics.find((mt) => mt.reference === metric.metricRef)
              )
        )
        .map((dr) =>
          getDisclosureData(
            dr,
            category,
            standard.standard.title,
            standard.id,
            standard.standardRef
          )
        ),
    };
  };

  const getCategoryData = (category: CategoriesType[number]): EsrsReportCategory => {
    const filteredStandards = materialStandards.filter(
      (std) =>
        std.standard.category.title === category.title &&
        std.materialDisclosureRequirements.some((dr) => dr.disclosureRequirement.metrics.length)
    );

    return {
      title: category.title,
      reference: category.ref,
      standards: filteredStandards
        .map((std) => getStandardsData(std, category.title))
        .sort((a, b) => a.reference.localeCompare(b.reference)),
    };
  };

  const reportData: EsrsReportData = useMemo(
    () => ({
      categories: categories?.map((category) => getCategoryData(category)) ?? [],
      companyName: companyName ?? '',
      reportingYear: reportingYear ?? new Date().getFullYear(),
    }),
    [categories, answers, data, companyName, reportingYear, reportingUnitsAnswers]
  );

  const loading = useMemo(() => {
    return (
      materialStandardsLoading ||
      answersLoading ||
      reportingUnitsAnswersLoading ||
      assessmentLoading
    );
  }, [materialStandardsLoading, answersLoading, reportingUnitsAnswersLoading, assessmentLoading]);
  return { data: reportData, loading };
};

export const structureReportData = (data: EsrsReportData) => {
  return data.categories
    .filter((category) => category.standards.length)
    .map((category) => {
      const numberOfAnswers =
        category.standards.filter((std) =>
          std.disclosureRequirements.every((dr) => dr.metrics.every((metric) => metric.completed))
        ).length ?? 0;
      return [
        [
          {
            title: category.title,
            key: category.reference,
            description: null,
            numberOfAnswers: numberOfAnswers,
            totalAnswers: category.standards.length,
            isHidden: category.standards.every((std) =>
              std.disclosureRequirements.every((dr) => dr.isHidden)
            ),
          },
        ],
        ...category.standards.map((std) => {
          const numberOfDrAnswers =
            std.disclosureRequirements.filter((dr) =>
              dr.metrics.every((metric) => metric.completed)
            ).length ?? 0;
          return [
            {
              title: `${std.reference} ${std.title}`,
              key: std.reference,
              description: null,
              numberOfAnswers: numberOfDrAnswers,
              totalAnswers: std.disclosureRequirements.length,
              isHidden: std.disclosureRequirements.every((dr) => dr.isHidden),
            },
            ...std.disclosureRequirements.map((dr) => {
              const numberOfMetricAnswers =
                dr.metrics.filter((metric) => metric.completed).length ?? 0;
              return {
                title: `${dr.reference} ${dr.title}`,
                key: dr.reference,
                description: dr.description,
                numberOfAnswers: numberOfMetricAnswers,
                totalAnswers: dr.metrics.length,
                isHidden: dr.isHidden,
              };
            }),
          ];
        }),
      ];
    });
};
