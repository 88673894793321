import { Esrs_DatapointTag_Constraint_, GetEsrsMetricsDatapointsQuery_, ShortUser } from 'models';
import { Row } from 'read-excel-file';
import { FrequencyEnums, TimePeriodsEnums, QUARTERS_FIELDS } from '../../Requirement';
import { combinedFiltersForNoTags, combinedFiltersForTags } from '../AggregatedMetrics';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { excelColumns } from './MetricTableExport.hooks';

export type ExcelDataType = {
  businessUnit: string;
  disclosureRequirement: string;
  metric: string;
  metricRef: string;
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
  Total: number;
  timePeriod: string;
  tags: string;
}[];
type Datapoints = GetEsrsMetricsDatapointsQuery_['answers'][number]['datapoints'];
type Tags = {
  tagType: string;
  tagValue: string;
}[];

export const matchHeaderToColumnKeys = (fileHeader: Row) => {
  const keys = fileHeader.map(
    (header) =>
      excelColumns.find((col) => col.header.toLowerCase() === String(header).toLowerCase())?.key
  );
  return keys.filter((header) => header !== undefined) as string[];
};

const getTagsFromString = (tags: string) => {
  const regex = /\[(.*?)\^(.*?)\]/g;
  const matches = tags?.length ? [...tags.matchAll(regex)] : [];
  return matches?.map((match) => ({
    tagType: match[1],
    tagValue: match[2],
  }));
};

const getCorrectStringFormat = (number: number | null) => {
  const value = number ? String(number) : null;
  if (value === null) return value;
  if (/^[0-9,.-]*$/.test(value)) return value.replaceAll(',', '');
  if (/^[0-9.-]*$/.test(value)) return value;
  return null;
};

const findDataPoint = (datapoints: Datapoints, tags: Tags, period: string) => {
  const datapoint = datapoints.find(
    (dp) =>
      dp.timeframe === period &&
      (!!tags.length
        ? combinedFiltersForTags(dp, period === TimePeriodsEnums.year, [tags])
        : combinedFiltersForNoTags(dp, period === TimePeriodsEnums.year))
  );
  return datapoint?.id;
};

export const getDatapoints = (
  row: ExcelDataType[number],
  user: ShortUser | null,
  datapoints: Datapoints,
  metrics: MetricsTableData[],
  companyStandardId: string
) => {
  const isYearly =
    metrics
      .find((m) => m.metric.reference === row.metricRef)
      ?.metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
      ?.frequency === FrequencyEnums.yearly;
  const tags = getTagsFromString(row.tags);
  if (isYearly) {
    return [
      {
        id: findDataPoint(datapoints, tags, TimePeriodsEnums.year),
        value: getCorrectStringFormat(row.Total),
        timeframe: TimePeriodsEnums.year,
        authorId: user?.id,
        datapointTags: {
          data: tags ?? [],
          on_conflict: {
            constraint: Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
          },
        },
      },
    ];
  } else
    return QUARTERS_FIELDS.map((quarter) => ({
      id: findDataPoint(datapoints, tags, quarter),
      value: getCorrectStringFormat(row[quarter]),
      timeframe: quarter,
      authorId: user?.id,
      datapointTags: {
        data: tags ?? [],
        on_conflict: {
          constraint: Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
        },
      },
    }));
};
