import {
  CompanyLevelMetricsPerDisclosureQuery_,
  GetDisclosureRequirementMetricsQuery_,
  GetEsrsAssessmentMetadataDocument_,
  QuestionType_Enum_,
  useEsrsAssessmentQuery,
  useGetEsrsAssessmentMetadataQuery,
  useGetReportingUnitQuery,
  useReportingUnitsMetricsPerDisclosureQuery,
  useUpdateEsrsAssessmentMetaDataMutation,
} from 'models';
import { VStack, useDisclosure, HStack, Grid } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { Button, IconButton } from 'Atoms';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HelpIcon } from 'Tokens/Icons/Status';
import { OptOutModal } from './OptOutModal';
import { MetricsGraphModal } from './MetricsGraphModal';
import {
  MetricsTableData,
  MetricDataCollection,
  useMaterialStandardId,
  SelectedMetric,
  TableViewSwitcher,
  MetricViewEnums,
} from 'containers/Esrs';
import {
  FrequencyEnums,
  QuartersObject,
  TimePeriods,
  TimePeriodsEnums,
  YearObject,
} from '../Requirement';
import { MetricsInputTable } from './InputTable';
import { getMetricWithChildren, getNestedRows } from './MetricAnswers.hooks';
import { MetricsTableFilters } from './MetricsTableFilters';
import { ChartView } from './InputTable/ChartView';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { DownloadIcon, UploadIcon } from 'Tokens/Icons/Function';
import { Menu } from 'Molecules/Menu';
import { useExportEsrsMetricTable } from './MetricsExcel/MetricTableExport.hooks';
import { MetricsExcelUploadModal } from './MetricsExcel/MetricsExcelUploadModal';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { NarrativeMetricsTable } from './InputTable/NarrativeMetricsTable';
import { MetricSidebar } from './MetricSidebar';

export type AssessableMetrics = CompanyLevelMetricsPerDisclosureQuery_['assessableMetrics'];

export const filterMaterialityMetrics = (
  metrics: AssessableMetrics,
  parentStandardId: string,
  companyStandardId: string,
  dataCollectionLevel: MetricDataCollection
): AssessableMetrics => {
  return metrics.filter((metric) => {
    const materialMetrics = metric.materialMetrics;

    const isMaterialForParent = materialMetrics.find(
      (materialMetric) => materialMetric.materialStandardId === parentStandardId
    )?.isMaterial;

    const existsInCompany = (
      materialMetric: AssessableMetrics[number]['materialMetrics'][number]
    ) =>
      materialMetric.materialStandardId === companyStandardId &&
      materialMetric.dataCollection === dataCollectionLevel;

    const isMaterial = (materialMetric: AssessableMetrics[number]['materialMetrics'][number]) =>
      materialMetric.isMaterial || isMaterialForParent;

    return materialMetrics.some(
      (materialMetric) => existsInCompany(materialMetric) && isMaterial(materialMetric)
    );
  });
};
const TableTitle = ({
  title,
  metrics,
  disclosureRequirement,
  businessUnit,
  companyStandardId,
}: {
  title: string;
  metrics: MetricsTableData[];
  disclosureRequirement: string;
  businessUnit: string;
  companyStandardId: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const areTableMetricsYearly = useMemo(
    () =>
      metrics.every(
        ({ metric }) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
            ?.frequency === FrequencyEnums.yearly
      ),
    [metrics]
  );

  const areTableMetricsQuarterly = useMemo(
    () =>
      metrics.every(
        ({ metric }) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
            ?.frequency === FrequencyEnums.quarterly
      ),
    [metrics]
  );

  const exportMetrics = useExportEsrsMetricTable();

  return (
    <HStack w="100%" justifyContent="space-between">
      <LinkAnchor id={metrics[0].metric.reference} text={title} marginLeft="-20px" />
      <HStack spacing="4px">
        <Button variant="ghost" leftIcon={<UploadIcon />} onClick={onOpen}>
          Import
        </Button>
        <Menu
          size="md"
          sections={[
            {
              actions: [
                {
                  id: 'download',
                  title: 'Download Excel file',
                  variant: 'ghost',
                  onClick: () => {
                    toast({
                      text: 'Downloading file...',
                      closable: true,
                      duration: null,
                    });
                    exportMetrics(
                      metrics,
                      disclosureRequirement,
                      businessUnit,
                      areTableMetricsQuarterly,
                      areTableMetricsYearly,
                      companyStandardId
                    );
                  },
                  leftElement: <DownloadIcon />,
                },
              ],
            },
          ]}
        />
      </HStack>
      <Suspense>
        <MetricsExcelUploadModal
          isOpen={isOpen}
          onClose={onClose}
          metrics={metrics}
          disclosureRequirement={disclosureRequirement}
          businessUnit={businessUnit}
          areTableMetricsQuarterly={areTableMetricsQuarterly}
          areTableMetricsYearly={areTableMetricsYearly}
          companyStandardId={companyStandardId}
        />
      </Suspense>
    </HStack>
  );
};

export const Metrics = ({
  disclosureRequirementRef,
  disclosureRequirement,
}: {
  disclosureRequirementRef: string;
  disclosureRequirement: GetDisclosureRequirementMetricsQuery_['requirements'][number] | undefined;
}) => {
  const {
    companyId,
    reportingUnitId = '',
    standardRef = '',
    esrsAssessmentId = '',
    view,
  } = useParams();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isGraphOpen, onOpen: onGraphOpen, onClose: onGraphClose } = useDisclosure();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.q1);
  const [isOverview, setIsOverview] = useState<boolean>(false);
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [isChartsView, setIsChartsView] = useState(false);
  const [graphData, setGraphData] = useState<QuartersObject | YearObject>({
    Q1: 0,
    Q2: 0,
    Q3: 0,
    Q4: 0,
  });
  const [rowData, setRowData] = useState<MetricsTableData>();
  const [updateMetadata] = useUpdateEsrsAssessmentMetaDataMutation();
  const { company } = useCurrentCompany();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const { companyAssessmentId, parentAssessmentId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  const { data } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const esrsAssessment = useMemo(() => data?.esrsAssessment, [data]);

  const { data: buData, loading } = useReportingUnitsMetricsPerDisclosureQuery({
    variables: {
      disclosureRequirementRef,
      companyAssessmentId,
      parentAssessmentId: parentAssessmentId || companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId,
  });

  const metrics = useMemo(() => buData?.assessableMetrics ?? ([] as AssessableMetrics), [buData]);

  const { data: metaData, loading: metaDataLoading } = useGetEsrsAssessmentMetadataQuery({
    variables: {
      id: esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const metadata = useMemo(() => metaData?.EsrsAssessment_by_pk?.metadata, [metaData]);

  useEffect(() => {
    if (metadata?.isChartView) setIsChartsView(metadata?.isChartView);
  }, [metaData]);

  useEffect(() => {
    if (isChartsView !== metadata?.isChartView && !metaDataLoading) {
      updateMetadata({
        variables: {
          id: esrsAssessmentId,
          metadata: { ...(metadata ?? {}), isChartView: isChartsView },
        },
        refetchQueries: [GetEsrsAssessmentMetadataDocument_],
      });
    }
  }, [isChartsView]);

  useEffect(() => {
    const element = document.getElementById(hash.slice(1));
    if (element && hash.slice(1) === element.id) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }, 300);
    }
  }, [hash, metrics]);

  const handleViewChange = (isView: boolean) => {
    const section = isView ? MetricViewEnums.overview : MetricViewEnums.dataInput;
    navigate(
      `/${companyId}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${reportingUnitId}/disclosure-requirement/${disclosureRequirementRef}/${section}`
    );
    setIsOverview(isView);
  };

  useEffect(() => {
    const isView = view === MetricViewEnums.overview ? true : false;
    const isRouteNotMatchingState = isView !== isOverview;
    if (isRouteNotMatchingState) {
      if (view === MetricViewEnums.overview) setIsOverview(true);
      else setIsOverview(false);
    }
  }, [view]);

  const { data: ruData, loading: ruLoading } = useGetReportingUnitQuery({
    variables: { reportingUnitId },
    skip: !reportingUnitId,
  });

  useEffect(() => {
    setSelectedQuarter(TimePeriodsEnums.q1);
  }, [buData]);

  const dataCollectionLevel = MetricDataCollection.reportingUnitLevel;

  const materialOnlyMetrics: AssessableMetrics = useMemo(
    () =>
      filterMaterialityMetrics(
        metrics,
        parentAssessmentId,
        companyAssessmentId,
        dataCollectionLevel
      ).filter((metric) => !!metric.materialMetrics.length) ?? [],
    [metrics]
  );

  const filteredMetrics: AssessableMetrics = useMemo(
    () =>
      materialOnlyMetrics.filter((metric) => {
        return !(
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.isMaterial &&
          metric.parentMetrics.some((parentMetric) =>
            materialOnlyMetrics.some((m) => m.reference === parentMetric.parentMetricRef)
          )
        );
      }) ?? [],
    [materialOnlyMetrics]
  );

  const areMetricsYearly = useMemo(
    () =>
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.yearly
      ),
    [filteredMetrics]
  );

  const areMetricsQuarterly = useMemo(
    () =>
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.quarterly
      ),
    [filteredMetrics]
  );

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter('Year');
  }, [areMetricsYearly, buData]);

  const nestedMetrics = useMemo(
    () =>
      filteredMetrics
        .filter((metric) => metric.metricType !== QuestionType_Enum_.LongText_)
        .map((metric) => {
          const materialStandardId = metric.materialMetrics.find(
            (mm) => mm.materialStandardId === companyAssessmentId
          )?.isMaterial
            ? companyAssessmentId
            : parentAssessmentId;
          return getNestedRows(metric, materialStandardId);
        }),
    [filteredMetrics]
  );

  const longTextMetrics = useMemo(
    () => filteredMetrics.filter((metric) => metric.metricType === QuestionType_Enum_.LongText_),
    [filteredMetrics]
  );

  const standAloneMetrics = useMemo(
    () => nestedMetrics.filter((metric) => !metric?.subRows),
    [nestedMetrics]
  );

  const groupedMetrics = useMemo(
    () => nestedMetrics.filter((metric) => metric?.subRows).map((metric) => metric),
    [nestedMetrics]
  );

  const flatNestedMetrics = useMemo(
    () => nestedMetrics.flatMap((metric) => getMetricWithChildren(metric)),
    [filteredMetrics]
  );

  if (loading || metaDataLoading || ruLoading) return <Loader />;

  return (
    <HStack w="100%" alignItems="start" spacing="24px">
      <VStack spacing="24px" alignItems="start" marginRight={!!rowData ? '376px' : '0px'}>
        <HStack justifyContent="space-between" w="100%">
          <HStack>
            <Typography variant="bodyLarge" as="span" color="text.hint">
              {disclosureRequirement?.reference}
            </Typography>
            <Typography variant="h3" as="span">
              {disclosureRequirement?.title}
            </Typography>
          </HStack>
          <HStack>
            <IconButton
              aria-label="learn more"
              variant="ghost"
              icon={<HelpIcon />}
              size="sm"
              onClick={() => {
                setSelectedMetric({
                  title: disclosureRequirement?.title,
                  description: disclosureRequirement?.description ?? '',
                  isDisclosure: true,
                });
                onDrawerOpen();
              }}
            />
            <TableViewSwitcher setIsChartsView={setIsChartsView} isChartsView={isChartsView} />
          </HStack>
        </HStack>

        {isChartsView ? (
          <Grid templateColumns="repeat(3, 1fr)" gap={2} w="100%">
            {flatNestedMetrics.map((metric, index) => (
              <ChartView
                metric={metric}
                index={index}
                companyStandardId={companyAssessmentId}
                onGraphOpen={onGraphOpen}
                setSelectedMetric={setSelectedMetric}
                setGraphData={setGraphData}
              />
            ))}
          </Grid>
        ) : (
          <>
            <MetricsTableFilters
              isOverview={isOverview}
              setIsOverview={handleViewChange}
              selectedQuarter={selectedQuarter}
              setSelectedQuarter={setSelectedQuarter}
              areMetricsQuarterly={areMetricsQuarterly}
              areMetricsYearly={areMetricsYearly}
            />

            <VStack spacing="32px" alignItems="stretch" w="100%" minH="fit-content" mb="40px">
              {standAloneMetrics.length && (
                <VStack alignItems="start" id={standAloneMetrics[0].metric.reference}>
                  <TableTitle
                    title="Various Metrics"
                    metrics={standAloneMetrics}
                    disclosureRequirement={disclosureRequirement?.title ?? ''}
                    businessUnit={ruData?.bu?.name ?? ''}
                    companyStandardId={companyAssessmentId}
                  />
                  <MetricsInputTable
                    metrics={standAloneMetrics}
                    selectedQuarter={selectedQuarter}
                    onGraphOpen={onGraphOpen}
                    onOpen={onOpen}
                    setSelectedMetric={setSelectedMetric}
                    onDrawerOpen={onDrawerOpen}
                    setGraphData={setGraphData}
                    isOverview={isOverview}
                    companyStandardId={companyAssessmentId}
                    areMetricsYearly={areMetricsYearly}
                    currency={company?.currency ?? ''}
                    esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                    rowData={rowData}
                    setRowData={setRowData}
                  />
                </VStack>
              )}
              {groupedMetrics.map((metric) => (
                <VStack alignItems="start" id={metric.metric.reference}>
                  <TableTitle
                    title={metric.metric.title + '' + (metric.tagType?.toLowerCase() ?? '')}
                    metrics={[metric]}
                    disclosureRequirement={disclosureRequirement?.title ?? ''}
                    businessUnit={ruData?.bu?.name ?? ''}
                    companyStandardId={companyAssessmentId}
                  />
                  <MetricsInputTable
                    metrics={[metric]}
                    selectedQuarter={selectedQuarter}
                    onGraphOpen={onGraphOpen}
                    onOpen={onOpen}
                    setSelectedMetric={setSelectedMetric}
                    onDrawerOpen={onDrawerOpen}
                    setGraphData={setGraphData}
                    isOverview={isOverview}
                    companyStandardId={companyAssessmentId}
                    areMetricsYearly={areMetricsYearly}
                    currency={company?.currency ?? ''}
                    esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                    rowData={rowData}
                    setRowData={setRowData}
                  />
                </VStack>
              ))}
              {!isOverview && longTextMetrics.length !== 0 && (
                <NarrativeMetricsTable
                  metrics={longTextMetrics}
                  selectedQuarter={selectedQuarter}
                  esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                  rowData={rowData}
                  setRowData={setRowData}
                />
              )}
            </VStack>
          </>
        )}

        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />
        <OptOutModal
          isOpen={isOpen}
          onClose={onClose}
          assessmentId={esrsAssessmentId}
          reportingUnitId={reportingUnitId}
          metricRef={selectedMetric?.reference ?? ''}
          isCompany={false}
        />
        <MetricsGraphModal
          isOpen={isGraphOpen}
          onClose={onGraphClose}
          metricName={selectedMetric?.title ?? ''}
          data={graphData}
        />
      </VStack>
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={reportingUnitId}
          esrsAssessmentProjectLeader={esrsAssessment?.projectLeaderId}
          setSelectedMetric={setSelectedMetric}
          setGraphData={setGraphData}
          onGraphOpen={onGraphOpen}
          onDrawerOpen={onDrawerOpen}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
        />
      )}
    </HStack>
  );
};
