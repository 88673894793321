import { AxiosResponse } from 'axios';

export async function axiosRequestWithRetries<T>(
  requestFunction: () => Promise<AxiosResponse<T>>,
  maxAttempts: number,
  timeout: number
): Promise<T> {
  let lastError: any;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const response = await requestFunction();
      return response.data;
    } catch (error) {
      lastError = error;
      if (attempt < maxAttempts) {
        console.error(`Attempt ${attempt} failed. Retrying in ${timeout / 1000} seconds.`);

        await new Promise((resolve) => setTimeout(resolve, timeout));
      }
    }
  }
  console.error('All attempts failed.');

  throw lastError;
}
