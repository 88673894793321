import { Box, ChakraStyledOptions } from '@chakra-ui/react';
import { CSSProperties, useMemo } from 'react';
import { Typography } from 'Tokens';
import { CheckIcon } from 'Tokens/Icons/Status';

const STEPS_STATES = {
  active: {
    border: 'none',
    backgroundColor: 'bg.selected.accent',
    checkColor: 'text.onAccent',
  },
  done: {
    border: 'none',
    backgroundColor: 'bg.selected',
    checkColor: 'text.selected',
  },
  default: {
    border: '1px dashed',
    backgroundColor: 'bg.default',
    checkColor: 'border.hover',
  },
};

export const ActiveStepLabels = ({
  title,
  description,
  descriptionColor = 'text.muted',
  mb = '16px',
  hasSteps = true,
  active = false,
  done = false,
  ml,
  left,
  descriptionWidth,
  bgColor,
  goBack,
}: {
  title: string;
  description?: string;
  descriptionColor?: string;
  mb?: string;
  active?: boolean;
  hasSteps?: boolean;
  done?: boolean;
  ml?: string;
  left?: CSSProperties['left'];
  descriptionWidth?: string;
  bgColor?: string;
  goBack?: () => void;
}) => {
  const stepState = useMemo(() => (done ? 'done' : active ? 'active' : 'default'), [active, done]);
  return (
    <Box cursor={done ? 'pointer' : 'default'} onClick={done ? goBack : undefined} height="100%">
      <Box position="absolute" bg="bg.default" borderRadius="50%" left={left ?? '-47px'} ml={ml}>
        <Box
          border={STEPS_STATES[stepState].border}
          borderColor="border.hover"
          borderRadius="50%"
          w="fit-content"
          px="5px"
          backgroundColor={
            bgColor && stepState === 'default' ? bgColor : STEPS_STATES[stepState].backgroundColor
          }
        >
          <CheckIcon boxSize="18px" mt="5px" color={STEPS_STATES[stepState].checkColor} />
        </Box>
      </Box>
      <Typography variant="h3" mb="2px" opacity={!active && !done && hasSteps ? 0.5 : 1}>
        {title}
      </Typography>
      <Typography
        variant="body"
        color={descriptionColor}
        mb={mb}
        opacity={!active && !done ? 0.5 : 1}
        width={descriptionWidth ?? ''}
      >
        {description}
      </Typography>
    </Box>
  );
};

export const StepLabels = ({
  title,
  description,
  descriptionColor = 'text.muted',
  mb = '16px',
  ml = '0px',
}: {
  title: string;
  description?: string;
  descriptionColor?: string;
  mb?: string;
  ml?: string;
}) => {
  return (
    <>
      <Box
        border="1px dashed"
        borderColor="border.hover"
        borderRadius="50%"
        w="fit-content"
        px="5px"
        position="absolute"
        bg="white"
        left="-47px"
        ml={ml}
      >
        <CheckIcon boxSize="18px" mt="5px" color="border.hover" />
      </Box>
      <Typography variant="h3" mb="2px">
        {title}
      </Typography>
      <Typography variant="body" color={descriptionColor} mb={mb}>
        {description}
      </Typography>
    </>
  );
};
