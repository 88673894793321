import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { Alert, Button, IconButton, Tag } from 'Atoms';
import { SelectedMetric, useMaterialStandardId } from 'containers/Esrs';
import { useGetEsrsStandardQuery, useMaterialStandardMetricRequirementsQuery } from 'models';
import { ContentHeader, ContentLayout, LearnMoreDrawer, MetricLearnMoreHeader } from 'Molecules';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowLeftIcon, ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { useMaterialStandardDataCollection } from './DataCollection.hooks';
import { DisclosureRequirementMetricsTable } from './DisclosureRequirementMetricsTable';
import { HelpIcon } from 'Tokens/Icons/Status';

export const StandardMetricsDataCollection = () => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const navigate = useNavigate();
  const {
    isDataCollected,
    updateDataCollectionStatus,
    loading: dataCollectionStatusLoading,
  } = useMaterialStandardDataCollection(standardRef, esrsAssessmentId);

  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  const { data: standardData, loading: standardLoading } = useGetEsrsStandardQuery({
    variables: { reference: standardRef ?? '' },
    skip: !standardRef,
  });

  const { companyAssessmentId, parentAssessmentId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  const { data, loading } = useMaterialStandardMetricRequirementsQuery({
    variables: {
      standardRef,
      companyStandardId: companyAssessmentId,
      parentStandardId: parentAssessmentId || companyAssessmentId,
    },
  });

  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);
  const [showAlert, setShowAlert] = useState(false);

  const requirements: {
    title: string;
    reference: string;
  }[] = useMemo(() => {
    const uniqueRequirements = new Map();
    data?.esrs_MaterialStandard
      .flatMap((ms) => ms.materialMetrics)
      .forEach((metric) => {
        if (!uniqueRequirements.has(metric.metric.requirement.reference)) {
          uniqueRequirements.set(metric.metric.requirement.reference, metric.metric.requirement);
        }
      });
    return Array.from(uniqueRequirements.values());
  }, [data]);

  return (
    <VStack bg="bg.muted" h="fit-content" alignItems="start" p="8px" justifyContent="start">
      <Button variant="ghost" onClick={() => navigate(-1)} leftIcon={<ArrowLeftIcon />}>
        Back
      </Button>
      <Box
        width="88%"
        bg="bg.default"
        border="1px solid"
        borderColor="border.decorative"
        borderRadius="8px"
        p="24px"
        margin="auto"
      >
        <ContentLayout
          header={
            <VStack spacing="16px">
              <HStack alignItems="flex-start" w="100%">
                <ContentHeader
                  title={standard?.title ?? ''}
                  subtitle={standard?.reference}
                  props={{ padding: '0px' }}
                />
                <Box display="flex" justifyContent="flex-end" width="15%">
                  <Tag
                    variant={isDataCollected ? 'success' : 'warning'}
                    borderRadius="4px"
                    p="4px 10px"
                  >
                    {isDataCollected ? 'Done' : 'To setup'}
                  </Tag>
                </Box>
              </HStack>
              {showAlert && (
                <Alert status="neutral" closable={false} withIcon={true}>
                  <HStack w="100%" spacing="6px">
                    <Typography variant="body" size="14px">
                      Some metrics require the configuration of the breakdown
                    </Typography>
                  </HStack>
                </Alert>
              )}

              <Alert
                status="neutral"
                closable={false}
                title="Setup your data collection. You can change these settings later"
              />
            </VStack>
          }
          isLoading={loading || standardLoading || dataCollectionStatusLoading}
          padChildren={false}
        >
          <VStack mt="32px" spacing="32px" width="100%" alignItems="start">
            {requirements.map((dr) => {
              return (
                <VStack w="100%" alignItems="start" gap="0px">
                  <HStack
                    gap="8px"
                    borderBottom="1px solid"
                    w="100%"
                    pb="10px"
                    borderColor="border.decorative"
                  >
                    <Typography variant="bodyLarge">{dr.reference}</Typography>
                    <Typography variant="h2">{dr.title}</Typography>
                  </HStack>
                  <DisclosureRequirementMetricsTable
                    key={dr.reference}
                    disclosureRequirementRef={dr.reference}
                    setSelectedMetric={setSelectedMetric}
                    onDrawerOpen={onDrawerOpen}
                    setShowAlert={setShowAlert}
                  />
                </VStack>
              );
            })}
            <Button
              variant="primary"
              rightIcon={<ArrowNarrowRightIcon color="text.onAccent" />}
              onClick={() => {
                updateDataCollectionStatus();
                navigate(-1);
              }}
            >
              Done
            </Button>
          </VStack>
        </ContentLayout>
      </Box>
      <LearnMoreDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        description={selectedMetric?.description}
        customHeader={
          <MetricLearnMoreHeader
            metricRef={selectedMetric?.reference ?? ''}
            tags={selectedMetric?.tags ?? []}
          />
        }
      />
    </VStack>
  );
};
