import {
  EsrsAssessmentFieldsFragment_,
  GetEsrsCategoriesQuery_,
  GetMaterialStandardQuery_,
  MetricDependency_,
  QuestionType_Enum_,
} from 'models';
import { MaterialityStatus } from './MaterialityRadioGroup';
import { AlertStatus } from 'Atoms';
import { LockedIcon } from 'Tokens/Icons/Function';
import { CheckIcon, WarningIcon } from 'Tokens/Icons/Status';

export enum MaterialityState {
  materialMandatory = 'materialMandatory',
  mandatory = 'mandatory',
  toAssess = 'toAssess',
  material = 'material',
  notMaterial = 'notMaterial',
  gatherData = 'gatherData',
}

export const DR_STATES = {
  [MaterialityState.mandatory]: {
    status: 'neutral' as AlertStatus,
    title: 'Mandatory',
    description: 'This disclosure requirement is mandatory for all companies',
    icon: <LockedIcon color="text.muted" />,
  },
  [MaterialityState.materialMandatory]: {
    status: 'info' as AlertStatus,
    title: 'Material',
    description: 'This disclosure requirement is material because topic was assessed as material',
    icon: <LockedIcon color="text.info" />,
  },
  [MaterialityState.material]: {
    status: 'info' as AlertStatus,
    title: MaterialityStatus.material,
    description: 'You have assessed this disclosure requirement as material',
    icon: <CheckIcon color="text.info" />,
  },
  [MaterialityState.notMaterial]: {
    status: 'neutral' as AlertStatus,
    title: MaterialityStatus.notMaterial,
    description: 'You have assessed this disclosure requirement as not material',
    icon: <CheckIcon color="inherit" />,
  },
  [MaterialityState.toAssess]: {
    status: 'warning' as AlertStatus,
    title: 'To assess',
    description: 'Learn more about the DR and assess if it is material for your company or not',
    icon: <WarningIcon color="text.warning" />,
  },
  [MaterialityState.gatherData]: {
    status: 'neutral' as AlertStatus,
    title: MaterialityStatus.gatherData,
    description:
      'You have assessed this disclosure requirement as not material; however, you will still be able to gather data for it.',
    icon: <CheckIcon color="inherit" />,
  },
};

export type MaterialStandard = {
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterial?: boolean | null;
  isTopical?: boolean;
  isParentMaterial?: boolean | null;
  isDataGatheringOnly?: boolean;
  metrics: MaterialMetric[];
  parentCompanyMetrics: MaterialMetric[];
  isDataCollected: boolean;
  disclosureRequirementGroups: GetEsrsCategoriesQuery_['EsrsCategory'][number]['standards'][number]['disclosureRequirementGroups'];
  materialDisclosureRequirements: EsrsAssessmentFieldsFragment_['materialStandards'][number]['materialDisclosureRequirements'];
};

export type MaterialMap = {
  categoryName: string;
  categoryRef: string;
  hasParentMaterialityAssessment: boolean;
  materialStandards: MaterialStandard[];
};

export type BusinessUnitMaterialStandard = {
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterialForBu?: boolean;
  isMaterialForSubsidiary?: boolean;
  isMaterialForGroupParent?: boolean;
};

export type BusinessUnitMaterialMap = {
  categoryName: string;
  categoryRef: string;
  materialStandards: BusinessUnitMaterialStandard[];
};

export type Materiality =
  | 'material'
  | 'notMaterial'
  | 'materialMandatory'
  | 'mandatory'
  | 'toAssess'
  | 'gatherData';

export type MaterialMetric = {
  metricRef: string;
  dataCollection?: string | null;
  isDataGatheringOnly: boolean;
  isMaterial?: boolean | null;
  materialMetricTags?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['materialMetricTags'];
  metric?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['metric'];
};

export type MaterialDr = {
  drRef: string;
  isMaterial: boolean | null;
  isDataGatheringOnly: boolean;
};

export type MaterialityFields = {
  material: MaterialityState | null;
};

export type ParentMaterialMetrics = {
  metricRef: string;
  isMaterial?: boolean | null;
  dataCollection?: string | null | undefined;
  metric: {
    disclosureRequirementRef: string;
  };
};

export type Metric = {
  reference: string;
  description?: string | null;
  title: string;
  shortTitle?: string | null;
  tags: Array<{ type: string }>;
  metricType?: QuestionType_Enum_ | null;
  calculation?: string | null;
  childrenMetrics?: MetricDependency_[];
  isAssessable?: boolean;
  additionalTypes: { additionalType: AdditionalType }[];
};

export type AdditionalType = {
  reference: string;
  title: string;
  description: string;
  learnMore?: string;
};

export type Requirement = {
  title: string;
  reference: string;
  isMandatory: boolean;
  metrics: Metric[];
  order: number;
  description?: string | null | undefined;
  additionalTypes: { additionalType: AdditionalType }[];
};

export type DisclosureRequirementGroup = {
  title: string;
  requirements: Requirement[];
};

export type MaterialMetricMapping = {
  [metricRef: string]: {
    isMaterial?: boolean | null;
    isDataGatheringOnly: boolean;
  };
};

export type MaterialDisclosureRequirement = {
  standardRef: string;
  disclosureRequirementRef: string;
  isMaterial?: boolean | null;
  isDataGatheringOnly: boolean;
};

export type DisclosureRequirementMateriality = {
  drRef: string;
  materialityStatus: Materiality;
  parentMaterialityStatus?: Materiality;
};

export type DisclosureRequirement = {
  title: string;
  drRef: string;
  materialityStatus: MaterialityState;
  parentMateriality: string;
  metrics: Metric[];
  description: string | null | undefined;
  additionalTypes: AdditionalType[];
};

export enum MetricDataCollection {
  companyLevel = 'company',
  reportingUnitLevel = 'reportingUnits',
}

export type ParentMaterialDrs = {
  disclosureRequirementRef: string;
  isMaterial?: boolean | null;
  isDataGatheringOnly: boolean;
};

export type MappedDisclosureRequirements = {
  title: string;
  drRef: string;
  materialityStatus: Materiality;
  order: number;
  parentMateriality: Materiality | MaterialityState;
  metrics: {
    tags: {
      type: string;
    }[];
    reference: string;
    description?: string | null | undefined;
    title: string;
    shortTitle?: string | null | undefined;
    metricType?: QuestionType_Enum_ | null | undefined;
    calculation?: string | null | undefined;
    childrenMetrics?: MetricDependency_[] | undefined;
    isAssessable?: boolean | undefined;
    additionalTypes: {
      additionalType: AdditionalType;
    }[];
  }[];
  description: string | null | undefined;
  additionalTypes: AdditionalType[];
}[];
