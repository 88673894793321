import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { IconButton, Tag, Tooltip } from 'Atoms';
import { ContentHeader, ContentLayout, Select } from 'Molecules';
import { useCallback, useMemo, useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Typography } from 'Tokens';
import { ArrowLeftIcon, ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { MaximizeIcon } from 'Tokens/Icons/Function';
import { ChartNoData, MetricsGraphModal, YearGraph } from '../../DisclosureRequirements/Metrics';
import {
  CompanyDashboardDataType,
  EsrsAssessmentListProps,
  GroupDashboardDataType,
} from '../EsrsAssessmentList';
import { useAggregatedMetrics, useDashboardMetricsByStandards } from './EsrsDashboard.hooks';
import { CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { HelpIcon } from 'Tokens/Icons/Status';
import { useCurrentCompany } from 'utils/hooks';
import { useNavigate } from 'react-router-dom';
import { MaterialTopicFilter, MATERIAL_TOPIC_FILTER_OPTIONS } from '../EsrsOverview';
import { DREnums, YearObject } from '../../DisclosureRequirements';

export const MetricChartCard: React.FC<EsrsAssessmentListProps & { isGroup: boolean }> = ({
  name,
  companyMetricAnswersData,
  groupMetricAnswersData,
  materialMetric,
  isGroup,
  standardRef,
  isMaterialForSubsidiary,
  isMaterialForGroup,
  assessmentId,
  allMaterialMetrics,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const company = useCurrentCompany();
  const navigate = useNavigate();
  const { aggregatedMetrics } = useAggregatedMetrics(
    isGroup,
    materialMetric,
    companyMetricAnswersData,
    groupMetricAnswersData,
    standardRef,
    allMaterialMetrics
  );

  const isYearly = Object.keys(aggregatedMetrics ?? {}).includes('Year');
  const year = (aggregatedMetrics as YearObject)?.Year;
  const metricDataPoints = useMemo(
    () =>
      Object.entries(aggregatedMetrics ?? {}).map(([key, value]) => ({
        quarter: key,
        data: value,
      })),
    [aggregatedMetrics]
  );

  const metricType = useMemo(() => {
    if (isGroup && isMaterialForSubsidiary) return 'subsidairyOnly';
    if (!isGroup && isMaterialForGroup) return 'groupOnly';
    return 'default';
  }, [isGroup, isMaterialForGroup, isMaterialForSubsidiary]);

  const METRIC_TOOLTIPS = {
    subsidairyOnly: `This metric wasn’t assessed as material for ${company.company?.name}, but there’s data available as it is material for one or several subsidiaries`,
    groupOnly: `This metric wasn’t assessed as material for ${company.company?.name}, but it is material for a parent company, so data was collected and reported`,
    default: null,
  };

  const METRIC_ICONS = {
    subsidairyOnly: <CompanyBottomUpIcon boxSize="16px" />,
    groupOnly: <CompanyTopDownIcon boxSize="16px" />,
    default: null,
  };

  const METRIC_TITLE = {
    subsidairyOnly: 'Material for subsidiaries',
    groupOnly: 'Material for parent company',
    default: 'Material metric',
  };

  return (
    <VStack
      alignItems="start"
      border="1px solid"
      borderColor="border.decorative"
      borderRadius="8px"
      w="32.5%"
      h="270px"
      mr="8px !important"
      mb="12px"
    >
      <HStack
        paddingBlock="12px"
        paddingInline="16px"
        borderBottom="1px solid"
        borderColor="border.decorative"
        w="100%"
        justifyContent="space-between"
        gap="8px"
      >
        <VStack alignItems="start" gap="2px" width="100%">
          <Typography variant="bodyStrong" noOfLines={1} title={name}>
            {name}
          </Typography>

          <HStack gap="4px" alignItems="center" color="text.muted">
            {(isGroup && isMaterialForSubsidiary) || (!isGroup && isMaterialForGroup) ? (
              <Tag variant="" borderRadius="4px" padding="0px" minHeight="unset" minWidth="unset">
                <Tooltip>{METRIC_ICONS[metricType]}</Tooltip>
              </Tag>
            ) : null}

            <Typography fontSize="12px">{METRIC_TITLE[metricType]}</Typography>
            {(isGroup && isMaterialForSubsidiary) || (!isGroup && isMaterialForGroup) ? (
              <Tooltip label={METRIC_TOOLTIPS[metricType]}>
                <HStack alignItems="end" paddingTop="2px">
                  <HelpIcon boxSize="16px" />
                </HStack>
              </Tooltip>
            ) : null}
          </HStack>
        </VStack>
        <HStack alignItems="end" gap="4px">
          <Tooltip label="Open location">
            <IconButton
              aria-label="open"
              icon={<ArrowUpRightIcon />}
              variant="ghost"
              onClick={() =>
                navigate(
                  `${assessmentId}/standard/${standardRef}/disclosure-requirement/${DREnums.metric}/${materialMetric?.metric.requirement.reference}`
                )
              }
              size="sm"
            />
          </Tooltip>
          <Tooltip label="Full screen">
            <IconButton
              aria-label="maximize"
              icon={<MaximizeIcon />}
              variant="ghost"
              onClick={onOpen}
              size="sm"
            />
          </Tooltip>
        </HStack>
      </HStack>
      {isYearly ? (
        <YearGraph year={year} />
      ) : !!aggregatedMetrics ? (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={350}
              data={metricDataPoints}
              margin={{
                top: 8,
                right: 25,
                left: 0,
                bottom: 8,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="quarter" />
              <YAxis />
              <RechartsTooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="data"
                stroke="#8787FF"
                activeDot={{ r: 6 }}
                legendType="none"
              />
            </LineChart>
          </ResponsiveContainer>
          <MetricsGraphModal
            isOpen={isOpen}
            onClose={onClose}
            metricName={name}
            data={aggregatedMetrics}
          />
        </>
      ) : (
        <ChartNoData />
      )}
    </VStack>
  );
};

export const EsrsDashboard = ({
  companyDashboardData,
  groupDashboardData,
  setIsDashboard,
  isGroup,
}: {
  companyDashboardData: CompanyDashboardDataType;
  groupDashboardData: GroupDashboardDataType;
  setIsDashboard: (param: boolean) => void;
  isGroup: boolean;
}) => {
  const [filter, setFilter] = useState<MaterialTopicFilter>(MaterialTopicFilter.ALL);

  const dashboardDataMap = useDashboardMetricsByStandards(
    companyDashboardData,
    groupDashboardData,
    isGroup
  );

  const handleFiltering = useCallback(
    (metric: EsrsAssessmentListProps) => {
      if (filter === MaterialTopicFilter.ALL) {
        return true;
      }
      if (filter === MaterialTopicFilter.MATERIAL) {
        if (isGroup) {
          return metric.isMaterialForGroup;
        }
        return !metric.isMaterialForGroup;
      }
      if (filter === MaterialTopicFilter.NOT_MATERIAL) {
        if (isGroup) {
          return !metric.isMaterialForGroup;
        }
        return metric.isMaterialForGroup;
      }
    },
    [filter, isGroup]
  );

  return (
    <ContentLayout
      header={
        <ContentHeader
          title="Dashboard"
          backButton={
            <IconButton
              size="md"
              variant="ghost"
              icon={<ArrowLeftIcon />}
              onClick={() => setIsDashboard(false)}
              aria-label={'Back'}
            />
          }
        />
      }
    >
      <VStack spacing="32px" alignItems="start" w="100%">
        <Box width="280px">
          <Select<{ value: MaterialTopicFilter; label: string }>
            value={{
              label: MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === filter)?.label ?? '',
              value: filter,
            }}
            onChange={(value) => setFilter(value?.value ? value.value : MaterialTopicFilter.ALL)}
            options={MATERIAL_TOPIC_FILTER_OPTIONS.map((currOption) => {
              return {
                label: currOption?.label ?? '',
                value: currOption.value,
              };
            })}
            size="md"
          />
        </Box>

        <VStack spacing="32px" alignItems="start" w="100%">
          {Array.from(dashboardDataMap).map(([assessmentId, assessmentDataMap]) =>
            Array.from(assessmentDataMap).map(([title, assessments]) => (
              <VStack spacing="8px" alignItems="start" w="100%">
                <VStack spacing="8px" alignItems="start" w="100%">
                  <Typography variant="h2">{title}</Typography>
                  <HStack spacing="0px" wrap="wrap" w="100%" alignItems="start">
                    {assessments.filter(handleFiltering).map((metric) => (
                      <MetricChartCard
                        name={metric.name}
                        groupMetricAnswersData={metric.groupMetricAnswersData}
                        companyMetricAnswersData={metric.companyMetricAnswersData}
                        materialMetric={metric.materialMetric}
                        isGroup={isGroup}
                        standardRef={metric.standardRef}
                        isMaterialForGroup={!!metric.isMaterialForGroup}
                        isMaterialForSubsidiary={!!metric.isMaterialForSubsidiary}
                        assessmentId={metric.assessmentId}
                        allMaterialMetrics={metric.allMaterialMetrics}
                      />
                    ))}
                  </HStack>
                </VStack>
              </VStack>
            ))
          )}
        </VStack>
      </VStack>
    </ContentLayout>
  );
};
