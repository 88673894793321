import { CardinalOrientation, Step } from 'walktour';

export const ESRS_ASSESSMENT_WALKTOUR: Step[] = [
  {
    selector: '.walktour-company-structure',
    title: 'Company structure',
    description: 'You can manage subsidiaries and navigate to see their assessments',
  },
  {
    selector: '.walktour-standard-overview',
    title: 'ESRS standards',
    description: 'Report and collect data required by ESRS by diving into each standard',
  },
  {
    selector: '.walktour-materiality-button',
    title: 'Assessment’s settings',
    orientationPreferences: [CardinalOrientation.SOUTHEAST],
    description: 'Edit the materiality assessment or update the data collection setup',
  },
  {
    selector: '.walktour-download-report-button',
    title: 'Create report',
    orientationPreferences: [CardinalOrientation.SOUTHEAST],
    description:
      'Once you gathered all data, review and export automatically generated ESRS report',
  },
];
