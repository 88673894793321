import {
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Box,
  Tooltip,
  VStack,
  HStack,
  PlacementWithLogical,
} from '@chakra-ui/react';
import React, { Fragment, useCallback } from 'react';
import { ButtonProps, IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { MoreIcon } from 'Tokens/Icons/Function';

// Theme:
export const MenuTheme = {
  baseStyle: {
    list: {
      fontFamily: 'Inter',
      margin: '0px',
      padding: '0px',
      borderRadius: '8px',
      border: 'none',
      shadow: 'lg',
      minW: '100px',
    },
    item: {
      minH: '36px',
      px: '8px',
      textAlign: 'left',
      borderRadius: '8px',
      _hover: {
        bg: 'bg.hover',
        _active: {
          bg: 'bg.pressed',
        },
      },
      _focus: {
        bg: 'transparent',
      },
      fontSize: 'md',
      fontWeight: 'medium',
    },
    groupTitle: {
      p: '8px',
      pt: '16px',
      pb: '0px',
      textAlign: 'left',
      margin: '0px 8px',
      fontSize: 'sm',
      lineHeight: 'xs',
      fontWeight: 'normal',
    },
    divider: {
      borderColor: 'border.decorative',
      borderWidth: '1px',
      margin: '0px',
    },
    button: {
      fontWeight: 'medium',
      fontFamily: 'Inter',
    },
  },
  sizes: {
    sm: {
      list: {
        w: '156px',
        maxW: '156px',
      },
    },
    md: {
      list: {
        w: '200px',
        maxW: '200px',
      },
    },
    lg: {
      list: {
        w: '256px',
        maxW: '256px',
      },
    },
  },
};

export type MenuProps = {
  menuButton?: React.ReactNode;
  sections: Array<MenuSection>;
  size?: 'sm' | 'md' | 'lg';
  customMenuButton?: React.ReactNode;
  setMenuOpen?: (val: boolean) => void;
  buttonSize?: string;
  placement?: PlacementWithLogical;
  description?: string;
  padding?: string;
  onMenuButtonClick?: ButtonProps['onClick'];
};

export type MenuSection = {
  title?: string;
  actions: MenuAction[];
};

export type MenuAction = {
  id: string;
  title?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  description?: string;
  variant?: 'ghost' | 'destructive' | 'selected';
  render?: () => React.ReactNode;
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  isDisabled?: boolean;
};

const MENU_ITEM_COLOR = {
  destructive: 'text.critical',
  selected: 'text.selected',
  ghost: 'text.default',
};
const MenuItemWithTruncation = ({ action }: { action: MenuAction }) => {
  const { title, description, leftElement, rightElement, variant, onClick, id, isDisabled } =
    action;
  const [showTooltip, setShowTooltip] = React.useState(false);

  const mouseEnterHandler = useCallback(
    (e: any) => {
      if (e.target.offsetWidth !== e.target.parentElement.offsetWidth && !showTooltip) {
        setShowTooltip(true);
      } else if (e.target.offsetWidth === e.target.scrollWidth) {
        setShowTooltip(false);
      }
    },
    [showTooltip, setShowTooltip]
  );

  return (
    <MenuItem
      color={MENU_ITEM_COLOR[variant || 'ghost']}
      key={id}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      isDisabled={isDisabled}
    >
      <HStack justifyContent="space-between" width="100%">
        <Tooltip label={showTooltip ? title : undefined}>
          <HStack spacing="8px">
            {leftElement && (
              <VStack height="100%" justifyContent={!!description ? 'center' : 'flex-start'}>
                {leftElement}
              </VStack>
            )}
            <VStack spacing="0px" alignItems="flex-start">
              <Box alignItems="flex-start" noOfLines={1} wordBreak="break-all">
                <span onMouseEnter={mouseEnterHandler}>{title}</span>
              </Box>
              {description && (
                <Typography variant="detail" color="text.muted">
                  {description}
                </Typography>
              )}
            </VStack>
          </HStack>
        </Tooltip>
        {rightElement}
      </HStack>
    </MenuItem>
  );
};
export function Menu({
  menuButton,
  sections,
  size = 'sm',
  onMenuButtonClick = () => {},
  customMenuButton,
  setMenuOpen,
  buttonSize,
  placement,
  description,
  ...props
}: MenuProps) {
  const renderAction = (action: MenuAction) => {
    if (!!action?.render) {
      return (
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            action.onClick?.();
          }}
          key={action?.id}
        >
          {action?.render()}
        </MenuItem>
      );
    }
    return <MenuItemWithTruncation action={action} key={action.id} />;
  };
  return (
    <ChakraMenu
      size={size}
      onOpen={() => (setMenuOpen ? setMenuOpen(true) : {})}
      onClose={() => (setMenuOpen ? setMenuOpen(false) : {})}
      placement={placement}
      {...props}
    >
      {customMenuButton}
      {menuButton ? (
        <MenuButton onClick={onMenuButtonClick}>{menuButton}</MenuButton>
      ) : (
        !customMenuButton && (
          <MenuButton
            disabled={!sections.length}
            size={buttonSize}
            onClick={(e) => e.stopPropagation()}
            as={IconButton}
            variant="ghost"
            icon={<MoreIcon />}
          />
        )
      )}
      <MenuList>
        {description && (
          <Typography p="16px" variant="detail" color="text.muted" pb="0px">
            {description}
          </Typography>
        )}
        {sections.map((section, index) => (
          <Fragment key={section.title ?? index}>
            <MenuGroup title={section.title}>
              <VStack spacing={0} m={'0px'} padding={'8px'}>
                {section.actions.map((action) => renderAction(action))}
              </VStack>
            </MenuGroup>
            {index < sections.length - 1 && <MenuDivider />}
          </Fragment>
        ))}
      </MenuList>
    </ChakraMenu>
  );
}
