import { Drawer, File, HelpTooltip, Modal, Select } from 'Molecules';
import {
  VStack,
  HStack,
  Box,
  Table as ChakraTable,
  Textarea,
  Thead,
  Td,
  Tr,
  Th,
  useDisclosure,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'utils/translation';
import { Button, Input, TruncatableText, DateInput } from 'Atoms';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {
  ActionFieldsFragment_,
  DocumentationFileDetailsFragment_,
  GetActionDocumentationByIdDocument_,
  GetReportingUnitsQuery_,
  GetTargetsQuery_,
  ShortUser,
  useGetTargetsQuery,
} from 'models';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { UserAssigner } from 'Organisms';
import { Typography } from 'Tokens';
import {
  ActionFields,
  getReportingUnits,
  getEstimates,
  getSubsidiaries,
  getTargets,
  useDeleteAction,
  useGetActionsDocumentation,
  useUpsertAction,
} from './Actions.hooks';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer';
import {
  InputCardDocumentation,
  InputCardDocumentationProps,
} from 'Molecules/InputCard/InputCardDocumentation';
import { useUserData } from '@nhost/react';
import { EstimatenumberInput } from './EstimatenumberInput';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import { useParams } from 'react-router-dom';

export const AddActionModal = ({
  isOpen,
  onClose,
  reportingUnits,
  actionToEdit,
  disclosureRequirementRef,
  targetId,
  actionName,
  setNewActionId,
  isGroup,
  isTargetView = false,
  isReadOnly = false,
  materialityAssessmentId,
}: {
  onClose: () => void;
  isOpen: boolean;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  actionToEdit?: ActionFieldsFragment_;
  disclosureRequirementRef: string;
  targetId?: string;
  actionName?: string;
  setNewActionId?: (param: { value: string; label: string }) => void;
  isGroup: boolean;
  isTargetView?: boolean;
  isReadOnly?: boolean;
  materialityAssessmentId?: string;
}) => {
  const { t } = useTranslation(['common']);
  const user: ShortUser | null = useUserData();
  const [filesAttached, setFilesAttached] = useState<DocumentationFileDetailsFragment_[]>();
  const { esrsAssessmentId = '', standardRef = '' } = useParams();
  const [note, setNote] = useState<string | null>();
  const { companyAssessmentId } = useMaterialStandardId(
    actionToEdit?.materialStandard.standard.reference ?? standardRef,
    actionToEdit?.materialStandard.assessmentId
  );

  const { data: targets, loading: targetsLoading } = useGetTargetsQuery({
    variables: {
      assessmentId: companyAssessmentId ?? materialityAssessmentId,
      disclosureRequirementRef: actionToEdit?.disclosureRequirementRef ?? disclosureRequirementRef,
    },
  });

  const targetsData = useMemo(() => targets?.targets ?? [], [targets]);

  const {
    isOpen: isAttachmentDrawerOpen,
    onOpen: onAttachmentDrawerOpen,
    onClose: onAttachmentDrawerClose,
  } = useDisclosure();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

  const upsertAction = useUpsertAction();
  const deleteAction = useDeleteAction();
  const { attachmentBox, noteHistory, documentationData, documentationLoading, subsidiariesData } =
    useGetActionsDocumentation(actionToEdit, isGroup);

  const { reset, handleSubmit, control, watch } = useForm<ActionFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      title: actionName ?? '',
      dueDate: new Date(),
      startDate: new Date(),
      reportingUnits: [],
      targets: [],
    },
  });
  const { isValid, isDirty } = useFormState({ control });

  useEffect(() => {
    const isNewActionFromTarget = isTargetView && !actionToEdit;
    reset({
      title: actionToEdit?.title ?? actionName ?? '',
      dueDate: actionToEdit?.deadline ? new Date(actionToEdit.deadline) : new Date(),
      startDate: actionToEdit?.startDate ? new Date(actionToEdit.startDate) : new Date(),
      ownerId: actionToEdit?.ownerId ?? user?.id,
      capex: String(actionToEdit?.capex ?? ''),
      noteReference: actionToEdit?.noteReference,
      reportingUnits: getReportingUnits(actionToEdit),
      subsidiaries: getSubsidiaries(actionToEdit),
      targets: getTargets(isNewActionFromTarget, targetsData, targetId, actionToEdit),
      estimates: getEstimates(isNewActionFromTarget, actionToEdit, isReadOnly),
    });
    if (isModalOpen) onModalClose();
  }, [isOpen]);

  const targetsList = useMemo(
    () =>
      targetsData.map((target) => ({
        value: target.id,
        label: `Target: ${target?.metric?.title?.toLowerCase()}`,
      })),
    [targetsData]
  );

  const filterUnusedTargets = useMemo(() => {
    return targetsList?.filter((target) => {
      return !watch('targets').some((selectedTargets) => selectedTargets.value === target.value);
    });
  }, [targetsList, watch('targets')]);

  const onSubmit = (data: ActionFields) => {
    upsertAction(
      data,
      onClose,
      actionToEdit,
      setNewActionId,
      disclosureRequirementRef,
      user?.id,
      isGroup,
      note,
      filesAttached,
      setNote,
      setFilesAttached,
      materialityAssessmentId
    );
  };

  const handleDeleteAction = () => {
    deleteAction(onClose, actionToEdit, setNewActionId);
  };

  const showDocumentation: InputCardDocumentationProps = useMemo(() => {
    return {
      currentAuthor: user ?? undefined,
      attachmentBox: attachmentBox,
      noteHistory: noteHistory,
      openAttachmentDrawer: onAttachmentDrawerOpen,
      refetchQueries: [GetActionDocumentationByIdDocument_],
      isInputCard: false,
      isLoading: documentationLoading,
      onNoteChanged: !!noteHistory ? undefined : (newNote) => setNote(newNote),
      isDisabled: isReadOnly,
      placeholder: 'Describe your plan',
    };
  }, [documentationData, noteHistory, user, documentationLoading]);

  return (
    <Drawer
      header={<Typography variant="h2">{actionToEdit?.title ?? 'Add action'}</Typography>}
      onClose={onClose}
      isOpen={isOpen}
      size="lg"
    >
      <VStack width="100%" alignItems="stretch">
        <form onSubmit={handleSubmit(onSubmit)} id="actionForm" noValidate>
          <VStack spacing="md" alignItems="flex-start" width="100%">
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <VStack spacing="6px" w="100%" alignItems="start">
                  <Typography variant="bodyStrong">Title</Typography>
                  <Input
                    width="100%"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder="Placeholder"
                    isDisabled={isReadOnly}
                  />
                </VStack>
              )}
            />
            <HStack alignItems="end" w="100%">
              <Box>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <VStack spacing="6px" w="100%" alignItems="start">
                      <Typography variant="bodyStrong">Start date</Typography>
                      <DateInput
                        width="308px"
                        value={field.value}
                        setValue={(date) => field.onChange(date)}
                        disabled={isReadOnly}
                        showMonthDropdown
                        showYearDropdown
                      />
                    </VStack>
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="dueDate"
                  control={control}
                  render={({ field }) => (
                    <VStack spacing="6px" w="100%" alignItems="start">
                      <Typography variant="bodyStrong">Due date</Typography>
                      <DateInput
                        width="308px"
                        value={field.value}
                        setValue={(date) => field.onChange(date)}
                        disabled={isReadOnly}
                        minDate={watch('startDate')}
                        showMonthDropdown
                        showYearDropdown
                      />
                    </VStack>
                  )}
                />
              </Box>
            </HStack>
            <Controller
              control={control}
              name="ownerId"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Box width="100%">
                  <VStack spacing="6px" w="100%" alignItems="start">
                    <Typography variant="bodyStrong">Owner</Typography>
                    <UserAssigner
                      assignedTo={String(value)}
                      setAssignedTo={(id) => onChange(id)}
                      placeholder="Select owner"
                      isDisabled={isReadOnly}
                    />
                  </VStack>
                </Box>
              )}
            />
            {isGroup || isReadOnly ? (
              <Controller
                name="subsidiaries"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <VStack spacing="6px" w="100%" alignItems="start">
                    <Typography variant="bodyStrong">Subsidiaries</Typography>
                    <Select<{ value: string; label: string }, true>
                      value={value}
                      onChange={(val) => {
                        if (val.some((v) => v.value === 'All')) {
                          onChange(
                            subsidiariesData?.map((s) => ({
                              label: s.company.name ?? '',
                              value: s.id,
                            }))
                          );
                        } else onChange(val);
                      }}
                      options={[
                        { label: 'All', value: 'All' },
                        ...(subsidiariesData?.map((s) => ({
                          label: s.company.name ?? '',
                          value: s.id,
                        })) ?? []),
                      ]}
                      size="md"
                      isMulti
                      placeholder="Select subsidiaries"
                      isDisabled={isReadOnly}
                    />
                  </VStack>
                )}
              />
            ) : (
              <Controller
                name="reportingUnits"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <VStack spacing="6px" w="100%" alignItems="start">
                    <Typography variant="bodyStrong">Business units</Typography>
                    <Select<{ value: string; label: string }, true>
                      value={value}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={[
                        ...(reportingUnits?.map((ru) => ({
                          label: ru?.name ?? '',
                          value: ru.id,
                        })) ?? []),
                      ]}
                      size="md"
                      isMulti
                      isDisabled={isReadOnly}
                    />
                  </VStack>
                )}
              />
            )}
            <VStack w="100%" alignItems="start">
              <Controller
                name="targets"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <HStack spacing="4px">
                      <Typography variant="bodyStrong">Related targets and impact</Typography>
                      <HelpTooltip
                        minWidth="300px"
                        placement="bottom"
                        label="Add the target this action is related to and the estimated impact this action will have on reaching the target. For instance, the action of installing solar panels on a company building could be related to the target of reducing the energy consumption from purchased electricity, or the target of reducing Scope 2 emissions."
                      />
                    </HStack>
                    <Select<{ value: string; label: string }, true>
                      value={value}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={filterUnusedTargets}
                      size="md"
                      isMulti
                      placeholder="Select target"
                      isDisabled={isReadOnly}
                    />
                  </>
                )}
              />
              {watch('targets').length && (
                <Controller
                  name="estimates"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
                      <ChakraTable sx={{ tableLayout: 'fixed' }}>
                        <Thead>
                          <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
                            <Typography variant="bodyStrong">Target</Typography>
                          </Th>
                          <Th padding="16px 8px" textTransform="none" letterSpacing="normal">
                            <HStack spacing="4px">
                              <Typography variant="bodyStrong">Add estimate effect</Typography>
                              <HelpTooltip
                                placement="bottom"
                                label="Add the estimated effect the action will have on the target."
                              />
                            </HStack>
                          </Th>
                        </Thead>
                        {watch('targets').map((target, index) => (
                          <Tr key={index}>
                            <Td
                              padding="8px"
                              textAlign="right"
                              border={index === watch('targets').length - 1 ? 'none' : ''}
                            >
                              <TruncatableText
                                text={
                                  isReadOnly
                                    ? actionToEdit?.actionTargets.find(
                                        (at) => at.target.id === target.value
                                      )?.target.metric?.title ?? ''
                                    : targetsData.find((tl) => tl.id === target.value)?.metric
                                        ?.title ?? ''
                                }
                                variant="body"
                              />
                            </Td>
                            <Td
                              padding="8px"
                              textAlign="left"
                              border={index === watch('targets').length - 1 ? 'none' : ''}
                            >
                              <EstimatenumberInput
                                value={value}
                                onChange={onChange}
                                isReadOnly={isReadOnly}
                                actionToEdit={actionToEdit}
                                targetsData={targetsData}
                                target={target}
                                estimates={watch('estimates')}
                                isDisabled={
                                  isReadOnly ? true : isTargetView && target.value !== targetId
                                }
                              />
                            </Td>
                          </Tr>
                        ))}
                      </ChakraTable>
                    </Box>
                  )}
                />
              )}
            </VStack>
            <Controller
              name="documentation"
              control={control}
              render={() => (
                <VStack spacing="6px" w="100%" alignItems="start">
                  <Typography variant="bodyStrong">Documentation</Typography>
                  <AttachmentDrawer
                    isOpen={isAttachmentDrawerOpen}
                    refetch={[GetActionDocumentationByIdDocument_]}
                    onClose={onAttachmentDrawerClose}
                    attachmentBox={attachmentBox}
                    onAttachClicked={
                      !!attachmentBox
                        ? undefined
                        : (files) => {
                            setFilesAttached(files);
                          }
                    }
                  >
                    <InputCardDocumentation {...showDocumentation} />
                  </AttachmentDrawer>
                  {!attachmentBox && (
                    <HStack mt="-16px">
                      {filesAttached?.map((file, index) => (
                        <File file={file} key={index} />
                      ))}
                    </HStack>
                  )}
                </VStack>
              )}
            />
            <Controller
              name="capex"
              control={control}
              render={({ field: { onChange, value } }) => (
                <VStack spacing="6px" w="100%" alignItems="start">
                  <HStack spacing="4px">
                    <Typography variant="bodyStrong">Estimated associated CapEx</Typography>
                    <HelpTooltip
                      placement="bottom"
                      label="Add the estimated total CapEx required to implement the action."
                    />
                  </HStack>
                  <NumberInput
                    minWidth="100%"
                    value={value ?? ''}
                    onChange={(val) => onChange(val)}
                  >
                    <NumberInputField
                      minWidth="100%"
                      textAlign="left"
                      disabled={isReadOnly}
                      placeholder="0"
                    />
                  </NumberInput>
                </VStack>
              )}
            />
            <Controller
              name="noteReference"
              control={control}
              render={({ field: { onChange, value } }) => (
                <VStack spacing="6px" w="100%" alignItems="start">
                  <HStack spacing="4px">
                    <Typography variant="bodyStrong">
                      Reference to associated note in the financial statement
                    </Typography>
                    <HelpTooltip
                      placement="bottom"
                      label="Add references to the relevant line items or notes in the financial statements."
                    />
                  </HStack>
                  <Textarea
                    value={value ?? ''}
                    onChange={onChange}
                    placeholder="Describe your plan"
                    borderColor="border.default"
                    borderRadius="8px"
                    width="624px"
                    height="65px"
                    p="8px"
                    isDisabled={isReadOnly}
                  />
                </VStack>
              )}
            />
            <HStack width="100%" justifyContent="space-between">
              <HStack>
                <Button variant="primary" isDisabled={!isDirty || !isValid} type="submit">
                  {t('common:button.confirm')}
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Cancel
                </Button>
              </HStack>
              {!!actionToEdit && (
                <Button variant="ghost" leftIcon={<DeleteIcon />} onClick={onModalOpen}>
                  Delete
                </Button>
              )}
            </HStack>
          </VStack>
        </form>
      </VStack>
      <Modal
        isOpen={isModalOpen}
        onClose={onModalClose}
        title="Are you sure you want to delete this action?"
        size="xs"
        confirmVariant="destructive"
        confirmText="Delete"
        onConfirm={handleDeleteAction}
      >
        <></>
      </Modal>
    </Drawer>
  );
};
