import { Heading, VStack, Text, HStack, useDisclosure } from '@chakra-ui/react';
import { AttachmentBox, BAssessment, GetActivityDocumentationDocument_ } from 'models';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'utils/translation';
import { Drawer } from 'Molecules';
import { Button, Tabs } from 'Atoms';
import { BrowseActivities } from './BrowseActivities';
import { SelectedActivities } from './SelectedActivities';
import { AttachmentDrawer } from '../AttachmentsDrawer';

const ActivitiesDrawerHeader = () => {
  const { t } = useTranslation('bUnits');

  return (
    <VStack alignItems="flex-start">
      <Heading fontSize="h1">{t('bUnits:activities.activities')}</Heading>
      <Text fontSize="lg" fontWeight="normal">
        {t('bUnits:activities.assignActivities')}
      </Text>
    </VStack>
  );
};

const ActivitiesDrawerFooter = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('common');

  return (
    <HStack justifyContent="left" width="100%">
      <Button variant="secondary" onClick={() => onClose()}>
        {t('common:buttons.done')}
      </Button>
    </HStack>
  );
};

const ActivitiesDrawerBody = ({
  bAssessment,
  openAttachmentDrawer,
}: {
  bAssessment: BAssessment;
  openAttachmentDrawer: (box?: AttachmentBox) => void;
}) => {
  const { t } = useTranslation('bUnits');
  const [currentTab, setCurrentTab] = useState<string>();

  const noOfActivities = useMemo(
    () => bAssessment.activityReports.map((ar) => ar.activity).length,
    [bAssessment]
  );

  const tabItems = useMemo(() => {
    return [
      {
        title: t('bUnits:browseActivities'),
        id: 'browseActivities',
        content: (
          <BrowseActivities bAssessment={bAssessment} openAttachmentDrawer={openAttachmentDrawer} />
        ),
      },
      {
        title: t('bUnits:selectedActivities', { noOfActivities: noOfActivities }),
        id: 'selectedActivities',
        content: (
          <SelectedActivities
            bAssessment={bAssessment}
            goToBrowseTab={() => setCurrentTab('browseActivities')}
            openAttachmentDrawer={openAttachmentDrawer}
          />
        ),
      },
    ];
  }, [bAssessment]);

  return (
    <Tabs
      defaultIndex={0}
      currentTab={currentTab}
      padList="16px"
      onChange={(tabId) => {
        if (typeof tabId == 'string') {
          return setCurrentTab(tabId);
        }
      }}
      items={tabItems}
    />
  );
};

export const ActivitiesDrawer = ({
  isOpen,
  onClose,
  bAssessment,
}: {
  isOpen: boolean;
  onClose: () => void;
  bAssessment: BAssessment;
}) => {
  const [attachmentBox, setAttachmentBox] = useState<AttachmentBox>();
  const {
    isOpen: isAttachmentsOpen,
    onClose: onAttachmentsClose,
    onOpen: onAttachmentsOpen,
  } = useDisclosure();

  const openAttachmentDrawer = useCallback(
    (box?: AttachmentBox) => {
      setAttachmentBox(box);
      onAttachmentsOpen();
    },
    [attachmentBox]
  );

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="xl"
      bodyProps={{
        paddingInlineStart: '0px',
        paddingInlineEnd: '0px',
        overflowY: 'hidden',
      }}
      header={<ActivitiesDrawerHeader />}
      footer={<ActivitiesDrawerFooter onClose={onClose} />}
    >
      <AttachmentDrawer
        attachmentBox={attachmentBox ?? undefined}
        refetch={[GetActivityDocumentationDocument_]}
        isOpen={isAttachmentsOpen}
        onClose={onAttachmentsClose}
      >
        <ActivitiesDrawerBody
          bAssessment={bAssessment}
          openAttachmentDrawer={openAttachmentDrawer}
        />
      </AttachmentDrawer>
    </Drawer>
  );
};
