import { Box, HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { ContentHeader, ContentLayout, Loader } from 'Molecules';
import { Menu } from 'Molecules/Menu';
import { ViewSwitcher } from 'Molecules/ViewSwitcher';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportSideMenu } from './ReportSideMenu';
import { ReportContent } from './ReportContent';
import { useEsrsReportData } from './Report.hooks';
import { ReportPreview } from './ReportPreview';
import { EsrsReportDocsGenerator } from './DocGenerator';

enum ReportViews {
  prepare = 'Prepare',
  preview = 'Preview',
}

export const CreateReport = () => {
  const navigate = useNavigate();
  const [selectedView, setSelectedView] = useState<string>(ReportViews.prepare);
  const { data, loading } = useEsrsReportData();

  if (loading) {
    return <Loader />;
  }

  return (
    <ContentLayout
      padChildren={false}
      header={
        <ContentHeader
          title="Create report"
          size="md"
          backButton
          onBackButtonClick={() => {
            navigate(-1);
          }}
          actions={
            <HStack>
              <ViewSwitcher
                views={Object.values(ReportViews)}
                selected={selectedView}
                setSelected={setSelectedView}
              />
              <Menu
                size="lg"
                menuButton={
                  <Button rightIcon={<ChevronDownIcon color="inherit" />} variant="primary">
                    Download
                  </Button>
                }
                sections={[
                  {
                    actions: [
                      {
                        id: 'esrs-report-word-doc',
                        render: () => <EsrsReportDocsGenerator reportData={data} />,
                      },
                    ],
                  },
                ]}
              />
            </HStack>
          }
        />
      }
    >
      <HStack
        alignItems="start"
        gap="6px"
        overflow="hidden"
        p="0px 16px 0px 16px"
        justifyContent="center"
      >
        <ReportSideMenu data={data} currentView={selectedView} />
        {selectedView === ReportViews.prepare ? (
          <ReportContent data={data} />
        ) : (
          <ReportPreview data={data} />
        )}
        <Box w="21%" h="100%"></Box>
      </HStack>
    </ContentLayout>
  );
};
