import { VStack } from '@chakra-ui/react';
import { Alert } from 'Atoms';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { useTranslation } from 'utils/translation';

export type LockConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLockLoading: boolean;
};

export const LockConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLockLoading,
}: LockConfirmationModalProps) => {
  const { t } = useTranslation(['common']);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => onConfirm()}
      size="sm"
      confirmVariant="destructive"
      title={t('common:assessment.isLocked.lockedModal.title')}
      confirmText={t('common:assessment.isLocked.lockedModal.confirm')}
      loading={isLockLoading}
    >
      <VStack>
        <Typography>{t('common:assessment.isLocked.lockedModal.description1')}</Typography>
        <Alert
          status="critical"
          closable={false}
          title={t('common:assessment.isLocked.lockedModal.alert')}
        />
        <Typography>{t('common:assessment.isLocked.lockedModal.description2')}</Typography>
      </VStack>
    </Modal>
  );
};
