import { useGetFinancialsQuery, GetFinancialsQuery_ } from 'models';
import { useMemo } from 'react';
import { createBareFinancials } from 'utils/financials';

export type CompanyFinancialResults = {
  companyFinancials?: GetFinancialsQuery_['companyFinancials'][0];
  businessUnits: Array<{
    id: string;
    reportingGroupId?: string;
    orderIndex: string;
    businessUnit: GetFinancialsQuery_['businessUnits'][0]['businessUnit'];
    financials: GetFinancialsQuery_['businessUnits'][0]['businessUnitFinancials'][0]['financials'];
    activities: Array<{
      activity?: GetFinancialsQuery_['businessUnits'][0]['activities'][0]['activity'];
      financials?: GetFinancialsQuery_['businessUnits'][0]['activities'][0]['financials'];
      id?: string;
      bAssessmentId?: string;
    }>;
  }>;
};

export const useFinancials = (cAssessmentId?: string) => {
  const { data, ...rest } = useGetFinancialsQuery({
    variables: {
      cId: cAssessmentId,
    },
    skip: !cAssessmentId,
    fetchPolicy: 'no-cache',
  });

  const financials: CompanyFinancialResults = useMemo(() => {
    const companyFinancials = data?.companyFinancials[0];
    const businessUnits =
      data?.businessUnits.map((bu) => ({
        id: bu.id,
        businessUnit: bu?.businessUnit,
        reportingGroupId: bu?.reportingGroupId,
        orderIndex: bu.orderIndex,
        financials:
          bu?.businessUnitFinancials?.[0]?.financials ??
          (createBareFinancials(
            0,
            0,
            0,
            0
          ) as CompanyFinancialResults['businessUnits'][0]['financials']),
        activities: bu?.activities.map((act) => ({
          activity: act.activity,
          financials:
            act.financials ??
            (createBareFinancials(
              0,
              0,
              0,
              0
            ) as CompanyFinancialResults['businessUnits'][0]['activities'][0]['financials']),
          id: act?.id,
          bAssessmentId: act?.bAssessmentId,
        })),
      })) ?? [];

    return {
      companyFinancials,
      businessUnits,
    };
  }, [data]);

  return { financials, ...rest };
};
