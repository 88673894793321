import { Button, Center, InputProps, CenterProps } from '@chakra-ui/react';
import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { UploadIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';

export type FileUploadProps = {
  addFiles: (files: File[]) => void;
  boxProps?: CenterProps;
} & Pick<InputProps, 'isInvalid'> &
  DropzoneOptions;

export const FileUpload = ({ addFiles, isInvalid, boxProps, ...props }: FileUploadProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: addFiles,
    ...props,
  });
  const { t } = useTranslation('common');
  return (
    <Center
      {...getRootProps({
        borderStyle: 'dashed',
        borderColor: isInvalid ? 'error.main' : 'primary.main',
        borderWidth: isInvalid ? '2px' : '1px',
        borderRadius: 'base',
        className: isDragActive ? 'active' : '',
        sx: {
          '&.active': {
            borderWidth: '2px',
          },
        },
      })}
      {...boxProps}
    >
      <Button variant="secondary" m="sm" leftIcon={<UploadIcon color="inherit" />}>
        {t('common:fields.file.addFile')}
      </Button>
      <input {...getInputProps()} />
    </Center>
  );
};
