import { GroupsQuery_ } from 'models';
import { CompanyType, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import {
  useShareCompanyAssessmentWithGroup,
  useUnshareCompanyAssessmentWithGroup,
} from '../Groups.hooks';
import { AssessmentSelector } from 'containers/Investors/pieces';
import { HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useMemo } from 'react';

export const ShareAssessmentCell = ({
  group,
  companyType,
}: {
  group: GroupsQuery_['groups'][number];
  companyType: CompanyType;
}) => {
  const { t } = useTranslation('common');
  const toast = useToast();
  const { shareCompanyAssessment, loading: shareLoading } = useShareCompanyAssessmentWithGroup();

  return (
    <AssessmentSelector
      assessmentId={group.sharedCompanyAssessmentId}
      setAssessmentId={(assessmentId) => {
        if (assessmentId)
          shareCompanyAssessment(group.id, group.ownerGroupAssessmentId, assessmentId)
            .then(() => {
              toast({
                text: t('common:investors.assessment.toast.shared.successful'),
              });
            })
            .catch(() => {
              toast({
                text: t('common:investors.assessment.toast.shared.failed'),
                variant: 'danger',
              });
            });
      }}
      isSaving={shareLoading}
      width="200px"
      isGroup={companyType === CompanyType.group}
    />
  );
};

export const ActionsCell = ({
  group,
  companyType,
}: {
  group: GroupsQuery_['groups'][number];
  companyType: CompanyType;
}) => {
  const { t } = useTranslation('common');
  const toast = useToast();
  const { unshareCompanyAssessment, loading: unshareLoading } =
    useUnshareCompanyAssessmentWithGroup();

  const isShared = useMemo(() => {
    return !!group.sharedCompanyAssessmentId;
  }, [group, companyType]);

  return (
    <HStack>
      {isShared && (
        <Button
          variant="destructive"
          isLoading={unshareLoading}
          onClick={() => {
            unshareCompanyAssessment(group.id, group.ownerGroupAssessmentId)
              .then(() => {
                toast({
                  text: t('common:investors.assessment.toast.unshared.successful'),
                });
              })
              .catch(() => {
                toast({
                  text: t('common:investors.assessment.toast.unshared.failed'),
                  variant: 'danger',
                });
              });
          }}
        >
          {t('common:assessment.sharing.unshare')}
        </Button>
      )}
    </HStack>
  );
};
